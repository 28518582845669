<template>
  <div>
    <div class="location-container">
      <div class="title-content title-top">開催場所</div>
      <div class="title-field">
        <div class="title-field-switch">
          <div class="title-content">オンライン開催をする</div>
          <b-field>
            <b-switch
              type="is-success"
              v-model="isOnline"
            >
            </b-switch>
          </b-field>
        </div>
      </div>

      <div v-if="!isOnline">
        <div class="title-field">
          <div class="title-content">都道府県</div>
          <div class="input-green entry-fee">
            <BField>
              <BSelect
                v-model="prefecture"
                name="prefecture"
                placeholder="選択する"
              >
                <option
                  v-for="(opt,key) in prefectureOptions"
                  :key="key"
                  :value="opt"
                  v-text="opt"
                />
              </BSelect>
            </BField>
          </div>
        </div>
        <div class="title-field">
          <div class="title-content">市区町村</div>
          <div class="input-green">
            <input v-model="address1" placeholder="横浜市中区山下町" class="w-100">
          </div>
        </div>
        <div class="title-field">
          <div class="title-content">番地</div>
          <div class="input-green">
            <input v-model="address2" placeholder="123" class="w-100">
          </div>
          <div class="memo">自宅や参加者のみに住所を共有したい場合は「付近」と 入力</div>
        </div>
        <div class="title-field">
          <div class="title-content">会場・建物の名称</div>
          <div class="input-green">
            <input v-model="place_name" placeholder="青空公園" class="w-100">
          </div>
        </div>
        <div class="title-field">
          <div class="title-content">会場へのアクセス方法</div>
          <div class="input-green">
            <input v-model="access" placeholder="最寄り駅は○○や、駅から5分など" class="w-100">
          </div>
        </div>
        <div class="title-field">
          <div class="title-field-optional">
            <div class="title-content">会場のURL（任意）</div>
            <div class="optional">任意</div>
          </div>
          <div class="input-green">
            <input v-model="place_url" placeholder="www.asmama.com" class="w-100">
          </div>
        </div>
      </div>
      <div>
        <div class="title-field">
          <div class="title-field-optional">
            <div class="title-content">参加費の補足説明</div>
            <div class="optional">任意</div>
          </div>
          <div class="input-green entry-fee">
            <textarea v-model="price_description" placeholder="参加費は無料ですが、飲食代は実費です。等" rows="6"></textarea>
          </div>
        </div>
        <div class="title-field">
          <div class="title-field-optional">
            <div class="title-content">自由入力欄</div>
            <div class="optional">任意</div>
          </div>
          <div class="input-green entry-fee">
            <textarea v-model="free_label1" rows="6"></textarea>
          </div>
        </div>
      </div>
      <div class="title-field">
        <div class="title-content">参加費</div>
        <div class="input-green entry-fee">
          <input v-model="price" placeholder="1000" type="number">
          <div class="yen">円</div>
        </div>
      </div>

      <div class="btn-area">
        <button
          class="btn btn-white"
          @click="back"
        >
          戻る
        </button>
        <button
          class="btn btn-success"
          @click="nextConFirm"
          :disabled="checkDisabled"
        >
          次へ
        </button>
      </div>

      <!-- <div class="btn-area">
        <button @click="back" class="btn btn-white">戻る</button>
        <button @click="nextConFirm" class="btn btn-success is-success-my-commu" :disabled="checkDisabled">入力内容確認</button>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    event: {
      type: Object,
      require: true
    },
    prefectureOptions: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      isOnline: this.event.online,
      price: (this.event.id === null && this.isEmpty(this.event.price)) || this.event.price,
      price_description: this.event.price_description,
      prefecture: this.event.prefecture,
      address1: this.event.address1,
      address2: this.event.address2,
      place_name: this.event.place_name,
      access: this.event.access,
      place_url: this.event.place_url,
      free_label1: this.event.free_label1
    }
  },
  created () {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  },
  computed: {
    checkDisabled () {
      if (this.isOnline) {
        return this.isEmpty(this.price)
      } else {
        return this.isEmpty(this.price) || this.isEmpty(this.prefecture) || this.isEmpty(this.address1) ||
          this.isEmpty(this.address2) || this.isEmpty(this.place_name) || this.isEmpty(this.access)
      }
    }
  },
  methods: {
    isEmpty (value) {
      return (value == null || value === '')
    },
    toggleSwitch () {
      this.isOnline = !this.isOnline
    },
    back () {
      const data = {
        isStep1: true,
        isStep2: false,
        isStep3: false
      }
      this.$emit('backStep1', data)
    },
    nextConFirm () {
      this.event.online = this.isOnline
      this.event.price = this.price
      this.event.price_description = this.price_description
      this.event.prefecture = this.prefecture
      this.event.address1 = this.address1
      this.event.address2 = this.address2
      this.event.place_name = this.place_name
      this.event.access = this.access
      this.event.place_url = this.place_url
      this.event.free_label1 = this.free_label1
      const data = {
        event: this.event,
        isStep1: false,
        isStep2: false,
        isStep3: true
      }
      this.$emit('nextStep2', data)
      // this.$emit('nextConFirm', data)
    }
  }
}
</script>

<style scoped lang="sass">
.location-container
  padding: 32px 32px 58px

  .title-content
    font-style: normal
    font-weight: 300
    font-size: 14px
    line-height: 14px
    color: #273D35

    &.title-top
      font-weight: 700
      font-size: 16px
      line-height: 17px

  .title-field-optional, .title-field-switch
    display: flex
    justify-content: space-between

  .title-field-switch
    align-items: center

    /deep/
    .switch
      cursor: unset

      .control-label
        padding-left: unset

      label
        font-weight: 300
        font-size: 14px
        line-height: 14px
        color: #273D35
        display: flex
        flex-direction: row-reverse

      span.check
        height: 31px
        width: 51px
        padding: 2px
        background: rgba(120, 120, 128, 0.16)

        &:before
          width: 27px
          height: 27px
          background: #FFFFFF
          border: 0.5px solid rgba(0, 0, 0, 0.04)
          box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15), 0px 3px 1px rgba(0, 0, 0, 0.06)

      .switch input[type=checkbox]:checked + .check:before
        transform: translate3d(80%, 0, 0)

      input[type=checkbox]:checked + .check
        background: rgba(45, 186, 135, 0.9) !important

  .title-field
    margin-top: 24px

  .entry-fee
    display: flex
    align-items: center

    .yen
      color: #000000
      margin-left: 8px

  .optional
    background-color: #FFFFFF
    color: #2DBA87
    font-style: normal
    font-weight: 700
    font-size: 10px
    line-height: 9px
    border: 1px solid #2DBA87
    padding: 2px

  .input-green
    margin-top: 8px

    input, textarea, select
      border-radius: 6px
      height: 48px
      padding: 16px
      font-size: 15px
      color: #273D35
      border: 1px solid #CCCCCC
      caret-color: #2DBA87
      outline: none
      background: white
      font-family: 'Hiragino Kaku Gothic ProN'

      &::placeholder
        font-weight: 300
        font-size: 15px
        line-height: 20px
        color: #AAAAAA

      &:focus
        border: 1px solid #2DBA87

      &::-webkit-scrollbar
        display: none

    input, select
      width: 50%

      &.w-100
        width: 100%

    textarea
      width: 100%
      resize: none
      height: unset

    select
      padding: 13px 16px

  .memo
    font-size: 12px
    margin-top: 8px

  .btn-area
    display: inline-flex
    width: 100%

    .btn
      font-family: 'Hiragino Kaku Gothic ProN'
      padding: 16px
      border-radius: 6px
      margin-top: 40px
      font-weight: 700
      font-size: 15px
      line-height: 15px
      background: white
      color: #2DBA87
      border: none
      width: 100%
      text-align: center
      box-shadow: 0 2px 8px #F2EFDE

      &.btn-success
        color: white
        background: #2DBA87
        margin-left: 5px

      &.btn-white
        margin-right: 5px

  /deep/
  .field
    height: 48px
    display: flex

    .select
      select
        height: 48px
        font-family: 'Hiragino Kaku Gothic ProN'

    .select:not(.is-multiple):not(.is-loading)::after
      border: 2px solid #273D35
      border-right: 0
      border-top: 0
      margin-top: -0.1375em
</style>
