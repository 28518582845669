<template>
  <div class="shared-card-container">
    <div class="shared-card" @click="redirect(sharePath)" :class="{'border-radius-12': isDetailChat}">
      <div class="image-preview">
        <img v-if="share.imagePaths && share.imagePaths.length > 0" :src="share.imagePaths[0].original" />
      </div>
      <div class="avatar-area" :class="{ 'no-share-img': !share.imagePaths || share.imagePaths.length === 0 }" @click="goToProfile(share.user.id)">
        <img :src="share.user.communityAvatarPath" alt="" class="avatar">
        <div class="name ml-8">{{ fullName }}</div>
      </div>
      <div class="padding-contain" @click="goToDetail(sharePath)">
        <div class="share-title" v-if="share.subject !== ''">
          <span :class="sharingTextColor"> {{ share.subject }}{{ subTitle }}</span>
        </div>
        <div class="share-content" v-html="share.body" ></div>
        <div class="share-footer">
          <div class="share-date">{{ share.createdAtFormatDot }}</div>
          <MyCommuGroupStatus :value="shareStatus" :isGray="true" v-if="shareStatus" class="ml-24"/>
          <div class="d-flex">
            <div v-if="price" class="price">
              <img src="../../images/icons/coin.svg" alt="coin">
              <div>{{ price }}</div>
            </div>
          </div>
        </div>
        <div v-if="!isDetailChat" class="mt-24">
          <div class="icon-count">
            <div class="count-likes">
              <svg width="16" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.26652 11.9398L1.54651 7.21315C0.858378 6.54912 0.44021 5.65401 0.372516 4.70013C0.304821 3.74625 0.592394 2.80104 1.17985 2.04648C1.51345 1.63931 1.9287 1.30659 2.3988 1.06976C2.86891 0.832928 3.38342 0.697258 3.90918 0.671504C4.43494 0.64575 4.96029 0.730471 5.45129 0.920222C5.94229 1.10997 6.38804 1.40054 6.75984 1.77317L6.9932 2.00648L7.1132 1.88649C7.77723 1.19836 8.67234 0.780193 9.62622 0.712499C10.5801 0.644804 11.5253 0.932377 12.2799 1.51983C12.687 1.85344 13.0198 2.26868 13.2566 2.73878C13.4934 3.20889 13.6291 3.7234 13.6549 4.24916C13.6806 4.77492 13.5959 5.30026 13.4061 5.79125C13.2164 6.28225 12.9258 6.72803 12.5532 7.09983L7.71985 11.9398C7.52639 12.1312 7.2653 12.2385 6.9932 12.2385C6.7211 12.2385 6.45997 12.1312 6.26652 11.9398Z" fill="#d3d3d3"/>
              </svg>
              <span v-if="share.likesCount > 0">{{share.likesCount}}</span>
            </div>
            <div class="count-comments">
              <svg width="14" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.54683 0.873047C7.48637 0.874344 6.44438 1.15058 5.52254 1.67478C4.6007 2.19899 3.83052 2.95325 3.28719 3.86395C2.74386 4.77465 2.44596 5.81067 2.42253 6.87087C2.3991 7.93107 2.65096 8.97923 3.15352 9.91305C3.76686 11.033 1.4935 12.6664 1.3335 12.9264C1.97193 13.0602 2.6311 13.0612 3.26994 12.9293C3.90878 12.7974 4.51361 12.5355 5.04683 12.1597C6.07671 12.8548 7.30647 13.192 8.54683 13.1197C10.1359 13.0673 11.6423 12.3992 12.7478 11.2565C13.8534 10.1139 14.4714 8.58628 14.4714 6.99638C14.4714 5.40648 13.8534 3.87883 12.7478 2.73621C11.6423 1.59358 10.1359 0.925486 8.54683 0.873047Z" fill="#d3d3d3"/>
              </svg>
              <span v-if="share.commentsCount > 0">{{share.commentsCount}}</span>
            </div>
          </div>
        </div>
        <div class="share-status" :class="this.sharingBgColor">{{ sharingType }}</div>
      </div>
    </div>
    <div v-if="!isDetailChat" class="comments-actions">
      <a @click="like">
        <div class="action-items active-heart">
          <span class="action-icon icon-heart">
            <img width="14" height="14" src="../../images/for_community/heart_in_active_icon.png" v-if="!isLiked">
            <img width="14" height="14" src="../../images/for_community/heart_active_icon.png" v-if="isLiked">
          </span>
          <span>いいね</span>
        </div>
      </a>
      <div class="action-items active-comment"
           @click="gotoComment"
      >
        <span class="action-icon icon-comment">
          <img width="14" height="14" src="../../images/for_community/comment_icon.png">
        </span>
        <span>コメント</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import { commuError } from 'lib/app-toast'

export default {
  props: {
    share: {
      type: Object,
      required: true
    },
    shareGroup: {
      type: Boolean,
      default: false
    },
    joined: {
      type: Boolean,
      default: false
    },
    sharePath: {
      type: String,
      default: '#'
    },
    isDetailChat: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isBookmark: false,
      sharingType: '',
      sharingBgColor: '',
      sharingTextColor: '',
      expired: this.share.isExpired || false,
      price: this.share.priceText || false,
      isLiked: this.share.liked
    }
  },
  computed: {
    ...mapState('session', ['communityId']),
    ...mapState('mycommu', ['communityId']),
    fullName () {
      if (this.share.user.commuNickname) {
        return this.share.user.commuNickname
      } else {
        return this.share.user.fullName
      }
    },
    subTitle () {
      if (this.share.sharingType === 'entry') {
        return 'お願いします'
      } else if (this.share.sharingType === 'borrowing') {
        return 'を貸してください'
      } else if (this.share.sharingType === 'lending') {
        return 'を貸します'
      } else if (this.share.sharingType === 'taking') {
        return 'を譲ってください'
      } else if (this.share.sharingType === 'giving') {
        return 'を譲ります'
      } else {
        return '預かるよ'
      }
    },
    shareStatus () {
      if (this.share.sharingType === 'entry') {
        const finished = new Date(this.share.requestFinishedAt)
        if (finished > Date.now()) {
          if (this.share.requestState === 'solved') {
            return 'シェア成立'
          } else {
            return '募集中'
          }
        } else {
          return '募集終了'
        }
      } else if (this.share.sharingType === 'taking' || this.share.sharingType === 'giving' || this.share.sharingType === 'lending' || this.share.sharingType === 'borrowing') {
        if (this.share.isClosed || this.share.isExpired) {
          return '募集終了'
        }
      } else if (this.share.sharingType === 'living') {
        const finished = new Date(this.share.requestFinishedAt).getTime()
        const today = new Date(Date.now()).getTime()
        if (finished < today || this.share.requestState === 'solved' || this.share.requestState === 'canceled') {
          return '募集終了'
        }
      } else {
        if (this.share.isClosed) {
          return '募集終了'
        } else {
          const deadlineOn = new Date(this.share.deadlineOn).getTime()
          const today = new Date(Date.now()).getTime()
          if (deadlineOn < today) {
            return '募集終了'
          }
        }
      }
    }
  },
  created () {
  },
  mounted () {
    switch (this.share.sharingType) {
      case 'borrowing':
        this.sharingType = '貸して'
        this.sharingBgColor = 'bg-green'
        this.sharingTextColor = 'green'
        break
      case 'entry':
        this.sharingType = '預かって'
        this.sharingBgColor = 'bg-blue'
        this.sharingTextColor = 'blue'
        break
      case 'lending':
        this.sharingType = '貸すよ'
        this.sharingBgColor = 'bg-sky-blue'
        this.sharingTextColor = 'sky-blue'
        break
      case 'giving':
        this.sharingType = '譲るよ'
        this.sharingBgColor = 'bg-blue'
        this.sharingTextColor = 'blue'
        break
      case 'hand_over':
        this.sharingType = '譲ります'
        this.sharingBgColor = 'bg-blue'
        this.sharingTextColor = 'blue'
        break
      case 'living':
        this.sharingType = 'くらしのサポート'
        this.sharingBgColor = 'bg-yellow'
        this.sharingTextColor = 'yellow'
        break
      case 'taking':
        this.sharingType = '譲って'
        this.sharingBgColor = 'bg-pink'
        this.sharingTextColor = 'pink'
        break
    }
  },
  methods: {
    bookmark () {
      this.isBookmark = !this.isBookmark
    },
    like () {
      if (this.shareGroup && !this.joined) {
        this.$emit('confirmJoin', true)
      } else {
        const data = {
          share: {
            type: this.share.sharingType,
            id: this.share.id
          }
        }
        axios.post(`/communities/${this.communityId}/share_posts/handle_like`, data).then((response) => {
          if (response.data.action === 'like') {
            this.isLiked = true
            this.share.likesCount++
          } else if (response.data.action === 'unlike') {
            this.isLiked = false
            this.share.likesCount--
          } else {
            commuError(response.data.message)
          }
        })
      }
    },
    gotoComment () {
      if (this.shareGroup && !this.joined) {
        this.$emit('confirmJoin', true)
      } else {
        location.href = `${this.sharePath}?action=comment`
      }
    },
    redirect (url) {
      window.location.href = url
    }
  }
}
</script>

<style scoped lang="sass">
  .shared-card-container
    border-radius: 12px
  .shared-card
    position: relative
    background: #FFFFFF
    border-radius: 12px 12px 0 0
    &.border-radius-12
      border-radius: 12px !important
    .padding-contain
      padding: 0 32px 5px 32px
    .avatar-area
      display: flex
      padding-top: 3px
      padding-left: 32px
      align-items: center
      .avatar
        width: 48px
        height: 48px
        object-fit: cover
        border-radius: 50%
        flex-shrink: 0
      .name
        color: #273D35
        font-weight: 300
        font-size: 12px
        line-height: 12px
        word-break: break-all
    .no-share-img
      padding-top: 40px
    .share-title
      margin-top: 10px
      margin-bottom: 7px
      font-style: normal
      font-weight: bold
      font-size: 17px
      line-height: 17px
      color: #6F8FE3
      padding-right: 30px
    .share-content
      margin-top: 7px
      margin-bottom: 3px
      font-style: normal
      font-weight: normal
      font-size: 12px
      color: #273D35
      padding-right: 30px
      word-break: break-all
    .share-footer
      padding-bottom: 3px
      display: flex
      justify-content: space-between
      align-items: center
      .price
        font-weight: bold
        font-size: 12px
        line-height: 12px
        display: flex
        align-items: center
        color: #273D35
        margin-right: 1.5rem
      .share-date
        font-style: normal
        font-weight: normal
        font-size: 12px
        color: #AAAAAA
    .share-status
      padding: 17px 24px
      background: #6F8FE3
      border-radius: 16px 16px 16px 0
      font-weight: bold
      font-size: 14px
      line-height: 14px
      text-align: center
      color: #FFFFFF
      position: absolute
      top: -10px
      right: 8px
  .green
    color: #2DBA87 !important
    word-break: break-all
  .bg-green
    background: #2DBA87 !important
  .pink
    color: #F9A6BF !important
    word-break: break-all
  .bg-pink
    background: #F9A6BF !important
  .yellow
    color: #E8B85D !important
    word-break: break-all
  .bg-yellow
    background: #E8B85D !important
  .blue
    color: #6F8FE3 !important
    word-break: break-all
  .bg-blue
    background: #6F8FE3 !important
  .sky-blue
    color: #7ECAE9 !important
    word-break: break-all
  .bg-sky-blue
    background: #7ECAE9 !important
  .ml-8
    margin-left: 8px
  .mt-24
    margin-bottom: 3px
  .ml-24
    margin-left: 24px
  .mr-16
    margin-right: 16px
  .d-flex
    display: flex
  .comments-actions
    display: grid
    grid-template-columns: auto auto
    align-items: center
    text-align: center
    background: #ffffff
    color: #333333
    font-weight: 700
    font-size: 12px
    margin: 0px
    border-top: 1px solid #d3d3d3
    border-radius: 0 0 15px 15px
    .action-items
      display: flex
      justify-content: center
      color: #333333
      .action-icon
        margin-right: 5px
        align-items: center
    .action-items:nth-child(1)
      padding: 10px 27px 10px
      border-right: 1px solid white
    .action-items:nth-child(2)
      padding: 10px 27px 10px
    span
      img
        margin-top: 2px
  .icon-count
    display: flex
    svg
      margin-right: 5px
    div
      display: flex
      align-items: center
      margin-right: 15px
      span
        font-size: 13px
        margin-top: 3px
        color: #273D35
  .image-preview
    width: 100%
    img
      width: 100%
      border-radius: 12px 12px 0px 0px
</style>
