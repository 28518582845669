<template>
  <div class="list-users">
    <div
      v-for="(user, index) in users"
      :key="index"
      class="d-flex"
    >
      <div
        v-if="index < limitedNumberOfImages - 1"
        class="user-info"
        @click="goToProfile(user.id)"
        :style="`z-index: ${100 - index}`"
      >
        <img
          v-if="index < limitedNumberOfImages - 1"
          :src="user.avatarPath"
          class="avatar"
        >
      </div>
      <svg
        v-if="index === limitedNumberOfImages || (index + 1 === users.length && ( isAllowOnlyPeople || isPrivateMode))"
        class="see-more"
        width="50"
        height="50"
        viewBox="0 0 50 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        :style="`z-index: 1`"
        @click="goToUrl"
      >
        <circle cx="25" cy="25" r="25" fill="#7C7C7C"/>
        <path d="M16.9595 26.9401C18.031 26.9401 18.8995 26.0715 18.8995 25.0001C18.8995 23.9286 18.031 23.0601 16.9595 23.0601C15.8881 23.0601 15.0195 23.9286 15.0195 25.0001C15.0195 26.0715 15.8881 26.9401 16.9595 26.9401Z" fill="white"/>
        <path d="M25.0005 26.9401C26.072 26.9401 26.9405 26.0715 26.9405 25.0001C26.9405 23.9286 26.072 23.0601 25.0005 23.0601C23.9291 23.0601 23.0605 23.9286 23.0605 25.0001C23.0605 26.0715 23.9291 26.9401 25.0005 26.9401Z" fill="white"/>
        <path d="M33.0396 26.9401C34.111 26.9401 34.9796 26.0715 34.9796 25.0001C34.9796 23.9286 34.111 23.0601 33.0396 23.0601C31.9682 23.0601 31.0996 23.9286 31.0996 25.0001C31.0996 26.0715 31.9682 26.9401 33.0396 26.9401Z" fill="white"/>
      </svg>
    </div>
  </div>
</template>

<script>

import { mapState } from 'vuex'

export default {
  props: {
    users: {
      type: Array,
      default: () => []
    },
    url: {
      type: String,
      default: ''
    },
    isAllowOnlyPeople: {
      type: Boolean,
      default: false
    },
    isPrivateMode: {
      type: Boolean,
      default: false
    },
    applied: {
      type: Boolean,
      default: false
    },
    waiting: {
      type: Boolean,
      default: false
    },
    typeEvent: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('session', ['communityId']),
    limitedNumberOfImages () {
      return Math.floor((window.innerWidth - 66) / 37)
    }
  },
  methods: {
    goToProfile (id) {
      if (this.typeEvent) {
        window.location.href = `${this.url}`
      } else if (!this.isAllowOnlyPeople && !this.isPrivateMode) {
        window.location.href = `/communities/${this.communityId}/users/${id}`
      }
    },
    goToUrl () {
      if (this.typeEvent) {
        window.location.href = `${this.url}`
      } else if (this.url !== '' && !this.isAllowOnlyPeople && !this.isPrivateMode) {
        window.location.href = `${this.url}`
      }
    }
  }
}
</script>
<style scoped lang="sass">
.d-flex
  display: flex
.list-users
  display: flex
  margin-left: 10px
  .user-info
    width: 50px
    height: 50px
    border-radius: 50%
    border: 1px solid #ffffff
    margin-left: -10px
    .avatar
      border-radius: 50%
      object-fit: cover
      flex-shrink: 0
      width: 48px
      height: 48px
  .see-more
    background: #7C7C7C
    width: 50px
    height: 50px
    border-radius: 50%
    border: 1px solid #ffffff
    margin-left: -10px
</style>
