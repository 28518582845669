<template>
  <div class="outer">
    <div class="inner">
      <div class="logo">
        <a href="/home">
          <div v-if="isMyCommuDomain">
            <img src="../images/header-logo-mycommu.png" alt="マイコミュ" >
          </div>
          <div v-else>
            <img src="../images/header-logo-orange.svg" alt="子育てシェア" >
          </div>
        </a>
      </div>
      <div class="card-outer">
        <div :class="cardPhotoClass">
          <img
            v-if="isPhoto"
            :src="photoPath"
          >
        </div>
        <div class="card-inner">
          <div
            v-if="isIcon"
            class="card-icon"
          >
            <img :src="iconPath">
          </div>
          <div
            v-if="isIcon"
            class="card-icontext"
          >
            <slot name="icontext" />
          </div>
          <div
            class="card-contents"
            :class="{'is-icon': isIcon}"
          >
            <slot name="contents" />
          </div>
        </div>
      </div>
      <div class="signin-link">
        <h2 v-if="isMyCommuDomain" class="signin-link-h2">
          マイコミュに参加しよう
        </h2>
        <h2 v-else class="signin-link-h2">
          子育てシェアに参加しよう
        </h2>
        <p class="signin-link-p">
          アカウントをお持ちの方
        </p>
        <div>
          <slot name="signin">
            <a
              href="/users/sign_in"
              class="button is-primary is-fullwidth is-rounded"
            >
              ログインして参加
            </a>
          </slot>
        </div>
        <p class="signin-link-p">
          アカウントをお持ちでない方
        </p>
        <slot name="signup">
          <a
            href="/users/sign_up"
            class="button is-primary is-fullwidth is-rounded"
          >
            ユーザー登録をして参加
          </a>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>

const BACKGROUND_IMAGE_COUNT = 4

export default {
  props: {
    photoPath: {
      type: String,
      default: ''
    },
    iconPath: {
      type: String,
      default: ''
    },
    isMyCommuDomain: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isPhoto () {
      return this.photoPath !== ''
    },
    isIcon () {
      return this.iconPath !== ''
    },
    cardPhotoClass () {
      return `card-photo is-bg${this.backgroundImageType}`
    },
    backgroundImageType () {
      return this.isPhoto
        ? 0
        : Math.floor(Math.random() * BACKGROUND_IMAGE_COUNT) + 1
    }
  }
}
</script>

<style lang="sass" scoped>
@import '~stylesheets/resources'
=app-mobile-520
  @media screen and (max-width: 520px)
    @content

.outer
  min-height: 100vh
  width: 100%
  background-color: #f6f6f6
  display: flex
  justify-content: center
  align-items: center
.inner
  width: 500px
  padding: 26px 0
  text-align: center
  +app-mobile
    padding: 12px 0
  +app-mobile-520
    width: 100%
.logo
  margin-bottom: 26px
  +app-mobile
    margin-bottom: 12px
  a
    display: inline-block
  img
    width: 198px
    height: auto
    +app-mobile
      width: 174px
.card-outer
  border-radius: 10px
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.23)
  min-height: 370px
  background-color: #fff
  +app-mobile
    min-height: 270px
  +app-mobile-520
    border-radius: 0
    box-shadow: none
.card-photo
  height: 274px
  border-top-left-radius: 10px
  border-top-right-radius: 10px
  background-color: #fff
  background-size: cover
  background-position: bottom center
  background-repeat: no-repeat
  overflow: hidden
  position: relative
  img
    display: inline-block
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%,-50%)
  +app-mobile
    height: 174px
  +app-mobile-520
    border-radius: 0
  &.is-bg0
    background-image: none
  &.is-bg1
    background-image: url(../images/search-result-zero/bg-cyan.svg)
  &.is-bg2
    background-image: url(../images/search-result-zero/bg-green.svg)
  &.is-bg3
    background-image: url(../images/search-result-zero/bg-orange.svg)
  &.is-bg4
    background-image: url(../images/search-result-zero/bg-purple.svg)
.card-inner
  min-height: 96px
  display: flex
  justify-content: space-between
  align-items: flex-start
  position: relative
.card-contents
  width: 100%
  align-self: center
  padding: 16px 1.6rem
  font-weight: bold
  font-size: 18px
  line-height: 1.4
  color: #333333
  text-align: left
  &.is-icon
    width: calc(100% - 140px)
    +app-mobile
      width: calc(100% - 100px)
  h1,h2,h3,h4
    font-size: 18px
    font-weight: bold
  h1 + *,h2 + *,h3 + *,h4 + *,p + *
    margin-top: 16px
  p
    font-size: 16px
    font-weight: normal
.card-icon
  position: absolute
  top: -35px
  left: 40px
  +app-mobile
    top: -32px
    left: 20px
  img
    display: block
    height: 70px
    width: 70px
    border-radius: 50%
    margin-bottom: 10px
    +app-mobile
      height: 64px
      width: 64px
.card-icontext
  width: 140px
  padding: 45px 0 10px 10px
  font-weight: bold
  font-size: 14px
  color: #333333
  +app-mobile
    width: 100px
    padding-top: 42px
  .font-normal
    font-weight: normal
.signin-link
  margin-top: 30px
  .signin-link-h2
    font-size: 18px
    color: #4a4a4a
    font-weight: bold
  .signin-link-p
    margin-top: 24px
    margin-bottom: 12px
    font-size: 12px
    color: #4a4a4a
    +app-mobile
      margin-top: 20px
      margin-bottom: 10px
      font-size: 14px
  .button
    width: 240px
    display: inline-block
    font-weight: bold
</style>
