<template>
  <div>
    <MyCommuHiddenButtonFooter
      :is-my-commu-domain="true"
    />
    <MyCommuHiddenFooterMenu
      :is-my-commu-domain="true"
    />
    <HiddenHeader/>
    <div class="topbar">
      <div class="topbar-icon-back" @click="back()">
        <svg width="21" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M16.0697 3.8501L7.92969 12.0001L16.0697 20.1501" stroke="#2DBA87" stroke-width="2"
                stroke-linecap="round" stroke-linejoin="round"
          >
          </path>
        </svg>
      </div>
      <div class="topbar-title">
        <span>コミュニティ・グループ編集</span>
      </div>
      <div class="fake-block"></div>
    </div>
    <MyCommuChatTap
      first-tap="コミュニティ"
      second-tap="グループ"
      @changeTab="changeTab"
    />
    <div v-if="tab === 'first-tab'">
      <div class="justify-content-between">
        <div class="joined-title">参加中のコミュニティ</div>
        <img src="../images/icons/back.svg" alt="back" class="back" @click="back()">
      </div>
      <div class="joined">
        <div v-for="community in communities" :key="community.community_id">
          <MyCommuCommunityCard
            :id="community.community_id"
            :community="community"
            :name="community.community_name"
            :avatar-path="community.community_url"
            button-type="error"
            button-text="退出する"
            :has-button="communities.length!==1"
            @clickButton="outMyCommunity"
          />
        </div>
      </div>
      <div v-if="otherCommunities.length > 0">
        <div class="other-title">近くのコミュニティ</div>
        <div class="other">
          <div v-for="community in otherCommunities" :key="community.community_id">
            <MyCommuCommunityCard
              :id="community.community_id"
              :community="community"
              :name="community.community_name"
              :avatar-path="community.community_url"
              button-type="success"
              button-text="参加する"
              @clickButton="joinOtherCommunity"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="justify-content-between">
        <div class="joined-title">参加中のグループ</div>
        <img src="../images/icons/back.svg" alt="back" class="back" @click="back()">
      </div>
      <div class="joined">
        <div v-for="community in communities" :key="community.community_id">
          <div v-for="communityGroup in community.community_groups" :key="communityGroup.id">
            <MyCommuCommunityCard
              :id="communityGroup.id"
              :community="communityGroup"
              :name="communityGroup.name"
              :avatar-path="communityGroup.image1.thumb.url"
              button-type="error"
              button-text="退出する"
              :has-button="!communityGroup.is_admin"
              :min-text="community.community_name"
              @clickButton="outCommuGroups"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import Back from '../components/my-commu/Back'

export default {
  components: { Back },
  props: {},
  computed: {
    ...mapState('mycommu', ['communities', 'otherCommunities']),
    iconBack: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
      '<g clip-path="url(#clip0_10847_11145)">\n' +
      '<path d="M5.43018 5.42999L18.5702 18.57" stroke="#273D35" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>\n' +
      '<path d="M18.5702 5.42999L5.43018 18.57" stroke="#273D35" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>\n' +
      '</g>\n' +
      '<defs>\n' +
      '<clipPath id="clip0_10847_11145">\n' +
      '<rect width="24" height="24" fill="white"/>\n' +
      '</clipPath>\n' +
      '</defs>\n' +
      '</svg>\n'
  },
  data () {
    return {
      tab: 'first-tab'
    }
  },
  mounted () {
  },
  methods: {
    ...mapActions('mycommu', ['joinCommunity', 'outCommunity', 'communityOutAllGroups', 'communityOutGroups']),
    filterCommu (e) {
      this.chatIndex(e)
    },
    changeTab (tab) {
      this.tab = tab
    },
    joinOtherCommunity (community) {
      this.joinCommunity(community)
    },
    outMyCommunity (community) {
      this.outCommunity(community)
    },
    outCommuGroups (community) {
      this.communityOutGroups({ communityId: community.organization_id, groupId: community.id })
    },
    countGroups () {
      let count = 0
      this.communities.forEach((e) => {
        count += e.community_groups.length
      })
      return count
    },
    back () {
      window.location.href = '/communities/profile'
    }
  }
}

</script>

<style scoped lang="sass">
.back
  padding-right: 12px
  padding-top: 3px

.justify-content-between
  display: flex
  justify-content: space-between

.no-message
  text-align: center
  position: absolute
  left: 50%
  top: 50%
  transform: translate(-50%, -50%)
  min-width: 310px

.joined-title
  margin-top: 24px
  margin-bottom: 16px
  margin-left: 16px
  font-size: 14px
  font-weight: bold

.joined
  border-bottom: 1px solid #CCCCCC

.other
  border-bottom: 1px solid #CCCCCC

.other-title
  margin-top: 40px
  margin-bottom: 16px
  margin-left: 16px
  font-size: 14px
  font-weight: bold

.topbar
  padding: 0px 24px
  display: flex
  justify-content: space-between
  height: 41px
  align-items: center
  background: #FFFFFF

  .topbar-title
    span
      font-size: 16px
      font-weight: bold

  .fake-block
    width: 21px

  .topbar-icon-back
    display: flex
</style>
