<template>
  <div class="card-detail">
    <HiddenHeader/>
    <Back title='交流イベント' />
    <Slider
      :images="slider_images"
      :event="event"
      :isEvent="true"
    />

    <div class="topic-header">
      <div class="topic-users">
        <span>{{event.createdAtFormatDot}}</span>
      </div>
      <div class="topic-actions">
        <div
          class="event-actions-favories"
          @click="bookmark()"
        >
          <span>
            <svg v-if="this.isBookmark" width="16" height="22" viewBox="0 0 16 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12.9999 19.97L8.63989 16.2C8.46096 16.0529 8.23651 15.9725 8.00488 15.9725C7.77326 15.9725 7.54887 16.0529 7.36993 16.2L2.99994 19.97C2.85613 20.0785 2.68562 20.1462 2.50653 20.1658C2.32744 20.1855 2.14639 20.1563 1.98248 20.0815C1.81858 20.0068 1.67793 19.8891 1.57538 19.741C1.47283 19.5928 1.4122 19.4198 1.3999 19.2401V2.76001C1.3999 2.49479 1.50527 2.24045 1.69281 2.05292C1.88035 1.86538 2.13469 1.76001 2.3999 1.76001H13.6699C13.9351 1.76001 14.1895 1.86538 14.377 2.05292C14.5646 2.24045 14.6699 2.49479 14.6699 2.76001V19.23C14.6695 19.4233 14.613 19.6122 14.5074 19.774C14.4019 19.9359 14.2517 20.0636 14.075 20.1419C13.8984 20.2202 13.7028 20.2457 13.512 20.2151C13.3212 20.1846 13.1434 20.0995 12.9999 19.97Z" fill="#F78F54" stroke="#F78F54" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <svg v-else width="16" height="22" viewBox="0 0 16 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M13.0004 19.97L8.64038 16.2C8.46145 16.0529 8.237 15.9725 8.00537 15.9725C7.77375 15.9725 7.54936 16.0529 7.37042 16.2L3.00043 19.97C2.85661 20.0785 2.68611 20.1462 2.50702 20.1658C2.32793 20.1855 2.14688 20.1563 1.98297 20.0815C1.81906 20.0068 1.67841 19.8891 1.57587 19.741C1.47332 19.5928 1.41269 19.4198 1.40039 19.2401V2.76001C1.40039 2.49479 1.50576 2.24045 1.6933 2.05292C1.88083 1.86538 2.13517 1.76001 2.40039 1.76001H13.6704C13.9356 1.76001 14.19 1.86538 14.3775 2.05292C14.565 2.24045 14.6704 2.49479 14.6704 2.76001V19.23C14.67 19.4233 14.6135 19.6122 14.5079 19.774C14.4024 19.9359 14.2522 20.0636 14.0755 20.1419C13.8988 20.2202 13.7033 20.2457 13.5125 20.2151C13.3217 20.1846 13.1439 20.0995 13.0004 19.97Z"
                stroke="#CCCCCC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </span>
        </div>
        <div
          v-if="actionEvent" class="event-modal-action"
        >

          <BDropdown :mobile-modal="false" position="is-bottom-left">
            <button slot="trigger" class="option-button option-button-horizontal" type="button">
              <BIcon pack="far" icon="ellipsis-v" />
            </button>
            <div v-if="event.isPublished && event.ticketUsers.length > 0"  class="co-dropdown-item edit">
              <a data-confirm="申し込み者がいます。非公開にしますか？" rel="nofollow" data-method="post" :href="event.urlCommuUnPublish" >非公開にする</a>
            </div>

            <div v-if="event.isPublished && event.ticketUsers.length === 0"  class="co-dropdown-item edit">
              <a rel="nofollow" data-method="post" :href="event.urlCommuUnPublish" >非公開にする</a>
            </div>

            <div v-if="!event.isPublished" class="co-dropdown-item edit"><a rel="nofollow" data-method="post" :href="event.urlCommuPublish" >公開する</a></div>
            <div
              class="co-dropdown-item edit"
              @click.prevent="redirectEdit"
            >
              <span>編集する</span>
            </div>
            <div class="co-dropdown-item edit"><a :href="event.urlCommuCopy">複製する</a></div>
            <div class="co-dropdown-item edit"><a :href="event.urlCommuEventTickets">予約者一覧・管理</a></div>
            <div class="co-dropdown-item edit"><a :href="event.urlCommuEventSchedules">予約者への連絡</a></div>
            <div
              class="co-dropdown-item remove"
              @click.prevent="openModalDelete = true"
            >
              <span>削除する</span>
            </div>
          </BDropdown>
        </div>
      </div>
    </div>

    <div class="cart-title">
      <span>{{event.title}}</span>
    </div>
    <div class="events-info-header">
      <div v-if="event.address !== ''" class="events-info-header-element">
        <div class="events-info-header-element-logo">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12" cy="12" r="12" fill="#D5F1E7" />
            <path
              d="M15.6406 13.309C16.1761 12.6419 16.5216 11.8428 16.6407 10.9958C16.7597 10.1489 16.6479 9.28551 16.317 8.49679C15.9861 7.70806 15.4483 7.02323 14.7605 6.51451C14.0727 6.0058 13.2603 5.69205 12.4089 5.60639C12.0986 5.57521 11.7859 5.57521 11.4756 5.60639C10.4157 5.71789 9.42336 6.18004 8.65613 6.91948C7.8889 7.65893 7.39071 8.63331 7.24059 9.68802C7.15368 10.3244 7.19783 10.9718 7.37037 11.5905C7.54291 12.2091 7.84018 12.786 8.24391 13.2857L11.9423 18.0437L15.6406 13.309Z"
              fill="#D5F1E7" stroke="#2DBA87" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
            <path
              d="M11.9596 12.3177C12.852 12.3177 13.5754 11.5946 13.5754 10.7026C13.5754 9.81053 12.852 9.0874 11.9596 9.0874C11.0672 9.0874 10.3438 9.81053 10.3438 10.7026C10.3438 11.5946 11.0672 12.3177 11.9596 12.3177Z"
              stroke="#2DBA87" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
          </svg>

        </div>
        <div class="events-info-header-element-label">
          <span>開催場所：</span>
        </div>
        <div class="events-info-header-element-label">
          <span>{{event.shortAddress}}</span>
          <br>
          <span>{{event.placeAddress}}</span>
        </div>
      </div>
      <div class="events-info-header-element">
        <div class="events-info-header-element-logo">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12" cy="12" r="12" fill="#D5F1E7" />
            <path
              d="M12.0007 18.6667C15.6825 18.6667 18.6673 15.6819 18.6673 12C18.6673 8.31814 15.6825 5.33337 12.0007 5.33337C8.31875 5.33337 5.33398 8.31814 5.33398 12C5.33398 15.6819 8.31875 18.6667 12.0007 18.6667Z"
              fill="#D5F1E7" stroke="#2DBA87" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M12 8.04004V12L14.1333 13.74" stroke="#2DBA87" stroke-width="1.3" stroke-linecap="round"
              stroke-linejoin="round" />
          </svg>
        </div>
        <div class="events-info-header-element-label">
          <span>開催日時：</span>
        </div>
        <div class="events-info-header-element-label">
          <div :class="{'flex-column': event.schedules_time.length !== 1}">
            <span>
              {{event.schedules_date_no_year_and_times}}
            </span>
            <span v-for="(time, index) in event.schedules_time" :key="index">
              {{ time }}
            </span>
          </div>
        </div>
      </div>
      <div class="events-info-header-element" v-if="event.price">
        <div class="events-info-header-element-logo events-info-header-element-logo-coin">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1043_9710)">
              <path
                d="M8.00004 14.7891C11.7496 14.7891 14.7891 11.7496 14.7891 8.00004C14.7891 4.25052 11.7496 1.21094 8.00004 1.21094C4.25052 1.21094 1.21094 4.25052 1.21094 8.00004C1.21094 11.7496 4.25052 14.7891 8.00004 14.7891Z"
                fill="#FFC400" />
              <path
                d="M8.00002 15.992C6.41776 15.992 4.87102 15.5228 3.55542 14.6437C2.23983 13.7647 1.21449 12.5152 0.608985 11.0534C0.00348356 9.59162 -0.154966 7.98309 0.153715 6.43124C0.462397 4.87939 1.22433 3.45392 2.34315 2.3351C3.46197 1.21628 4.88744 0.454347 6.43929 0.145665C7.99114 -0.163017 9.59965 -0.00459132 11.0615 0.60091C12.5233 1.20641 13.7727 2.23181 14.6518 3.5474C15.5308 4.86299 16 6.40972 16 7.99197C15.9979 10.113 15.1543 12.1466 13.6545 13.6464C12.1547 15.1463 10.1211 15.9898 8.00002 15.992ZM8.00002 2.42181C6.89641 2.42182 5.81755 2.74918 4.90005 3.36248C3.98254 3.97578 3.26758 4.84745 2.84562 5.8672C2.42366 6.88696 2.3137 8.00898 2.52959 9.09126C2.74548 10.1735 3.27755 11.1675 4.05849 11.9473C4.83942 12.7271 5.83413 13.2577 6.91673 13.472C7.99933 13.6863 9.12113 13.5747 10.1403 13.1513C11.1594 12.7278 12.03 12.0116 12.642 11.0932C13.254 10.1748 13.5798 9.09557 13.5782 7.99197C13.5761 6.51393 12.9874 5.09715 11.9415 4.05278C10.8957 3.00841 9.47805 2.42181 8.00002 2.42181Z"
                fill="#FFC400" />
              <path
                d="M7.99937 15.1847C6.57678 15.1847 5.18609 14.7628 4.00325 13.9725C2.82041 13.1821 1.89852 12.0588 1.35412 10.7445C0.809714 9.43017 0.667332 7.98396 0.944865 6.58871C1.2224 5.19345 1.90744 3.91183 2.91336 2.9059C3.91929 1.89998 5.20086 1.21494 6.59612 0.937406C7.99137 0.659873 9.43758 0.802304 10.7519 1.34671C12.0662 1.89111 13.1895 2.81303 13.9799 3.99586C14.7702 5.1787 15.1921 6.56935 15.1921 7.99194C15.19 9.89891 14.4315 11.7272 13.083 13.0756C11.7346 14.424 9.90635 15.1825 7.99937 15.1847ZM7.99937 1.61452C6.7361 1.61452 5.50119 1.98925 4.45093 2.69125C3.40067 3.39325 2.58226 4.391 2.0992 5.55827C1.61614 6.72553 1.49011 8.00983 1.73715 9.24871C1.98419 10.4876 2.59315 11.6254 3.48698 12.5181C4.38081 13.4108 5.51942 14.0183 6.75862 14.2638C7.99781 14.5092 9.28191 14.3816 10.4486 13.8971C11.6152 13.4125 12.6119 12.5929 13.3126 11.5417C14.0133 10.4906 14.3865 9.25521 14.3849 7.99194C14.3827 6.2998 13.709 4.6777 12.5118 3.48194C11.3145 2.28618 9.69151 1.61452 7.99937 1.61452Z"
                fill="#FFFF4A" />
              <path
                d="M9.08099 12.1897H6.90949C6.80309 12.1877 6.70163 12.1445 6.62638 12.0692C6.55113 11.994 6.50793 11.8925 6.50586 11.7861V4.20588C6.50586 4.09883 6.54832 3.99617 6.62401 3.92047C6.69971 3.84478 6.80244 3.80225 6.90949 3.80225H9.08099C9.18804 3.80225 9.29067 3.84478 9.36637 3.92047C9.44207 3.99617 9.48462 4.09883 9.48462 4.20588V11.8184C9.47649 11.9197 9.43036 12.0143 9.35553 12.0831C9.2807 12.152 9.18267 12.19 9.08099 12.1897ZM7.31312 11.3825H8.67736V4.60951H7.31312V11.3825Z"
                fill="#FFFF4A" />
            </g>
            <defs>
              <clipPath id="clip0_1043_9710">
                <rect width="16" height="16" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
        <div class="events-info-header-element-value">
          <span>参加費：</span>
        </div>
        <div class="events-info-header-element-value">
          <span>{{event.price}}</span>
        </div>
      </div>
      <div class="event-host" >
        <div class="event-host-title">
          <span>主催者</span>
        </div>
        <div class="host-info">
          <img :src="event.eventHost.communityAvatarPath" class="host-avatar" @click="hostProfile">
          <div class="host-full-name">{{ hostName(event.eventHost) }}</div>
        </div>
      </div>
      <div v-if="adminOrEventOfShareSupporter">
        <div  class="joined-list" >
          <div class="joined-list-title">
            <span>{{ `予約者　 ${event.ticketUserApplied.length}人 ` }}</span>
          </div>
          <div class="joined-list-user">
            <MyCommuJoinedUser :users="event.ticketUserApplied" :type-event="true" :applied="true" :url="conditionUrlTicketApplied()"/>
          </div>
        </div>
        <div v-if="event.ticketUserWaiting.length > 0" class="joined-list" >
          <div class="joined-list-title">
            <span>{{ `キャンセル待ち　 ${event.ticketUserWaiting.length}人 ` }}</span>
          </div>
          <div class="joined-list-user">
            <MyCommuJoinedUser :users="event.ticketUserWaiting" :type-event="true" :waiting="true" :url="conditionUrlTicketWaiting()"/>
          </div>
        </div>
      </div>
      <div v-else-if="event.ticketUserWaiting.length > 0" >
        <div  class="joined-list" >
          <div class="joined-list-title">
            <span>{{ `キャンセル待ち　 ${event.ticketUserWaiting.length}人 ` }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="cart-container" v-html="event.body">
    </div>
    <div class="card-detail-comments-btn" v-if="mayOut">
      <button class="button" @click="cancelEvent = true">
        予約をキャンセルする
      </button>
    </div>
    <div class="card-detail-comments">
      <div class="card-coments-title">
        <span>交流イベント詳細</span>
      </div>

      <div class="card-coments-contents">
        <div class="card-coments-content-element first"
           v-for="comment in event.comments"
           :key="comment.id"
        >
          <div class="card-coments-title">
            <span>何かの項目名</span>
          </div>
          <span>{{comment.body}}</span>
        </div>
      </div>
    </div>

    <!-- <div class="card-sns-share">
      <div class="sns-share-content">
        <p class="sns-share-title">
          SNSで交流イベントをシェアリング
        </p>
        <div class="sns-share-btn-group">
          <a href="#" type="button" class="sns-share-btn">
            <img class="sns-icon" src="../../../images/icons/facebook.svg">
          </a>
          <a href="#" type="button" class="sns-share-btn">
            <img class="sns-icon" src="../../../images/icons/twitter.svg">
          </a>
          <a href="#" type="button" class="sns-share-btn">
            <img class="sns-icon" src="../../../images/icons/line.svg">
          </a>
        </div>
      </div>
    </div> -->
    <div class="btn-copy-area">
      <button class="btn-copy" @click="copyUrl">この交流イベントのURLをコピー</button>
    </div>
    <div class="create-ticket" v-if="mayJoin">
      <button class="button" @click="goToCreateTicket">
        予約へ進む
      </button>
    </div>
    <BModal
      :active.sync="isHelpModalActive"
      has-modal-card
      :width="270"
      :can-cancel="['outside']"
    >
      <div class="modal-card">
        <section class="modal-card-body">
          <div class="content">
            <span>キャンセルするには主催者に連絡する必要があります。主催者にメッセージを送りますか？</span>
          </div>
        </section>
        <section class="modal-card-footer">
          <button
            class="button cancel"
            @click="isHelpModalActive = false"
          >
            キャンセル
          </button>
          <a
            type="button"
            class="button ok"
            :href="cancelPath"
          >
            はい
          </a>
        </section>
      </div>
    </BModal>
    <MyCommuEventCancel
      v-if="cancelEvent"
      :event="event"
      @closeModalClose="closeCloseModal"
    />
    <BaseModalDelete
      :deletePath="eventPath"
      :open-modal="openModalDelete"
      @close="closeModal"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Slider from 'components/my-commu/Slider'
import Back from '../Back'
import axios from 'axios'
import BaseModalDelete from '../BaseModalDelete.vue';
import { commuSuccess } from '../../../lib/app-toast'

export default {
  components: {
    BaseModalDelete,
    Slider,
    Back
  },
  props: {
    event: {
      type: Object,
      require: true
    },
    cancelPath: {
      type: String,
      require: false,
      default: '#'
    },
    eventPath: {
      type: String,
      require: false
    },
    actionEvent: {
      type: Boolean,
      default: false
    },
    groupId: {
      type: Number,
      default: null
    },
    adminOrEventOfShareSupporter: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isHelpModalActive: false,
      isBookmark: this.event.bookmark,
      cancelEvent: false,
      openModalDelete: false
    }
  },
  computed: {
    ...mapState('space', ['unsetMargin', 'unsetPadding']),
    ...mapState('mycommu', ['currentCommunity']),
    ...mapState('session', ['communityId']),
    slider_images () {
      const images = []
      if (this.event.images !== undefined) {
        this.event.images.forEach((e) => {
          if (e.url !== this.event.default_image) {
            images.push(e.url)
          }
        })
        return images
      } else {
        return []
      }
    },
    mayJoin () {
      return !this.event.is_request_expired && this.event.user_request_status === 'no_joined' && this.event.status !== 'finished'
    },
    mayOut () {
      return this.event.user_request_status !== 'no_joined' && this.event.status !== 'finished'
    },
    urlTicketApplied () {
      return `/communities/${this.communityId}/managements/events/${this.event.id}/tickets/ticket_applied`
    },
    urlTicketWaiting () {
      return `/communities/${this.communityId}/managements/events/${this.event.id}/tickets/ticket_waiting`
    },
    urlTicketGroup () {
      return `/communities/${this.communityId}/community_groups/${this.groupId}/events/${this.event.id}/tickets`
    }
  },
  mounted () {
    // this.unsetMargin.mainContainer = true
    this.unsetPadding.mainContainer = true
  },
  methods: {
    conditionUrlTicketApplied () {
      const currentPath = location.pathname
      if (currentPath.includes('community_groups')) {
        return this.urlTicketGroup
      }
      return this.urlTicketApplied
    },
    conditionUrlTicketWaiting () {
      const currentPath = location.pathname
      if (currentPath.includes('community_groups')) {
        return this.urlTicketGroup
      }
      return this.urlTicketWaiting
    },
    bookmark () {
      if (this.isBookmark) {
        axios.delete(`${window.location.pathname}/un_bookmark`).then((res) => {
          if (res.status) {
            this.isBookmark = !this.isBookmark
          }
        })
      } else {
        axios.post(`${window.location.pathname}/bookmark`).then((res) => {
          if (res.status) {
            this.isBookmark = !this.isBookmark
          }
        })
      }
    },
    copyUrl () {
      navigator.clipboard.writeText(window.location.href)
      commuSuccess('URLをコピーしました')
    },
    hostProfile () {
      window.location.href = `/communities/${this.communityId}/users/${this.event.eventHost.id}`
    },
    closeCloseModal () {
      this.cancelEvent = false
    },
    closeModal () {
      this.openModalDelete = false
    },
    hostName (user) {
      if (user.commuNickname !== null && user.commuNickname !== '') {
        return user.commuNickname
      } else {
        return user.fullName
      }
    },
    redirectEdit () {
      window.location.href = `/communities/${this.communityId}/managements/events/${this.event.id}/edit`
    },
    goToCreateTicket () {
      if (this.groupId !== null) {
        window.location.href = `/communities/${this.communityId}/community_groups/${this.groupId}/events/${this.event.id}/tickets/new`
      } else {
        window.location.href = `/communities/${this.communityId}/events/${this.event.id}/tickets/new`
      }
    }
  }
}
</script>
<style scoped lang="sass">

  .card-detail
    background: #ffffff
    .topbar
      height: 41px
      background: #FFFFFF
      display: flex
      display: grid
      grid-template-columns: auto auto auto
      align-items: center

      .topbar-icon-back
        margin-left: 23px
      .topbar-title
        color: #273D35
        font-weight: bold
        font-size: 16px
        text-align: center
      .topbar-avatar
        margin-left: auto
        margin-right: 23px
        img
          width: 32px
          height: 32px
          border-radius: 4px

    .topic-header
      margin: 15px 23px 16px 23px
      display: grid
      grid-template-columns: auto auto
      align-items: end
      .topic-users
        display: flex
        align-items: center
        span
          font-weight: 400
          font-size: 12px
          line-height: 12px
          text-align: right
          color: #AAAAAA
        .topic-users-avatar
          margin-right: 10px

          img
            width: 32px
            height: 32px
            border-radius: 50%
            object-fit: cover
            flex-shrink: 0
        .topic-usrs-name
          color: #273D35
          font-weight: 400
          font-size: 12px

      .topic-actions
        display: flex
        margin-left: auto
        .option-button
          background-color: transparent
          border: none
          cursor: pointer
          padding: 0
          transform: rotate(90deg)
          .option-text
            font-size: 0.7rem
            margin-top: -5px
        .option-button-horizontal
          .icon
            margin-left: 18px
            color: #2DBA87
            margin-right: 0
            margin-top: 0
            width: unset

        .event-actions-favories
          margin-right: 18px
          display: flex
          span
            display: flex
            align-items: center
        .event-modal-action
          span.icon
            display: flex
            align-items: center
          /deep/
            .dropdown-menu
              min-width: 9.5rem
              .dropdown-content
                padding: 3px 0
                .co-dropdown-item
                  padding: 15px 0px 0px 18px
                  align-items: center
                  display: flex
                  .icon-edit,
                  .icon-remove
                    padding-right: 15px
                  .remove
                    color: red
                .remove
                  padding-bottom: 15px
                .dropdown-content
                  div
                    color: #273D35
                    font-size: 14px
                    font-weight: 400
    .cart-title
      color: #424242
      font-weight: bold
      font-size: 16px
      margin: 0px 23px 32px 23px

    .events-info-header
      margin: 0px 23px 12px 23px
      display: flex
      flex-direction: column
      .events-info-header-element
        display: flex
        .events-info-header-element-logo
          margin-right: 10px
          &-coin
            padding: 0 4px
        .events-info-header-element-label
          color: #2DBA87
          font-weight: bold
          font-size: 14px
          .flex-column
            display: flex
            flex-direction: column
        .events-info-header-element-value
          color: #273D35
          font-weight: bold
          font-size: 14px
    .cart-container
      margin: 0px 23px 32px 23px
      color: #273D35
      font-size: 15px
      font-weight: 300
      word-break: break-word
    .card-detail-comments-btn
      padding: 32px 23px 32px 23px
      .button
        width: 100%
        background: #FFFFFF
        border: none
        box-shadow: 0px 2px 8px #f2efde
        border-radius: 6px
        padding: 25px
        color: #EE5353
        font-weight: bold
        font-size: 15px
    .card-detail-comments
      background: #F8F7F2
      padding: 32px 23px 32px 23px

      .card-coments-title
        color: #273D35
        font-weight: bold
        font-size: 17px
        margin-bottom: 15px

      .card-coments-contents
        .card-coments-content-element
          border-top: 1px solid #CCCCCC
          padding: 24px 0px
          word-wrap: break-word
          &.firts
            color: #273D35
            font-weight: 400
            font-size: 12px

          .author
            margin-top: 10px
            display: flex
            .author-container
              display: flex
              .avatar
                margin-right: 10px
                img
                  width: 52px
                  height: 52px
                  border-radius: 50%
                  object-fit: cover
                  flex-shrink: 0
              .name
                display: flex
                flex-direction: column
                color: #273D35
                font-size: 12px
                font-weight: 300
                .infor-name
                  color: #273D35

                .infor-name-2
                  color: #7C7C7C
            .author-reply
              margin-left: auto
              .button
                color: #2DBA87
                border-radius: 6px
                font-weight: bold
                font-size: 14px
                padding: 9px
    .card-sns-share
      width: 100%
      aspect-ratio: 2 / 1
      display: flex
      justify-content: space-between
      .sns-share-content
        width: 100%
        height: 60%
        display: flex
        flex-direction: column
        margin: 40px auto
        .sns-share-title
          font-size: 17px
          font-weight: bold
          width: 88%
          margin: auto
        .sns-share-btn-group
          display: flex
          justify-content: space-evenly
          .sns-share-btn
            display: inline-flex
            width: 25%
            aspect-ratio: 2 / 1
            border-radius: 6px
            background-color: #FFFFFF
            box-shadow: 0 2px 8px #F2EFDE
            .sns-icon
              margin: auto
    .modal
      /deep/ .modal-background
        background: #273D35
        opacity: 0.9

  .animation-content
    width: 80%
    background: #FFFFFF
    .modal-card
      border-radius: 20px
      .content
        text-align: center
        font-size: 14px
        font-weight: 300
      .modal-card-footer
        line-height: 0
        display: inline-flex
        grid-template-columns: auto auto
        .button
          height: 48px
          min-width: 50%
          background: #fff
          color: #2DBA87
          border-radius: 0
          font-size: 15px
          &:focus-visible
            border: none
        .ok
          font-weight: bold
          border-left: none
  .create-ticket
    background: linear-gradient(360deg, #CBC8AB 0%, rgba(196, 196, 196, 0) 100%)
    padding: 9px 16px 8px 16px
    position: sticky
    bottom: 81px
    left: 0
    display: flex
    align-items: self-end
    height: 85px
    z-index: 100
    .button
      width: 100%
      background: #FFFFFF
      border: none
      box-shadow: 0 2px 8px #f2efde
      border-radius: 6px
      padding: 25px
      color: #2DBA87
      font-weight: bold
      font-size: 15px
  .btn-copy-area
    padding: 24px 24px 16px
    .btn-copy
      width: 100%
      background: #FFFFFF
      border: none
      box-shadow: 0 2px 8px #f2efde
      border-radius: 6px
      padding: 16px
      color: #2DBA87
      font-weight: bold
      font-size: 14px
  .event-host
    margin-top: 33px
    .event-host-title
      font-size: 14px
      font-weight: bold
    .host-info
      margin-top: 7px
      display: flex
      align-items: center
      .host-avatar
        width: 48px
        height: 48px
        border-radius: 50%
        object-fit: cover
        flex-shrink: 0
      .host-full-name
        font-size: 12px
        margin-left: 8px
        word-break: break-all

  .joined-list
    margin-top: 16px
    .joined-list-title
      font-size: 14px
      font-weight: bold
    .joined-list-title
      margin-top: 7px
</style>
