import { getCommunities, fetchUsers, searchUsers, searchSupporters, searchFriends, chatIndex, fetchUnseenChatCount } from '../../../apis/co'
import axios from '../../../utils/axios'

export default {
  toggerSidebar ({ commit }, value) {
    commit('toggerSidebar', value)
  },
  toggerTopbar ({ commit }, value) {
    commit('toggerTopbar', value)
  },
  setLoading ({ commit }, value) {
    commit('setLoading', value)
  },
  getCommunities ({ commit }, value) {
    getCommunities(value).then((res) => {
      commit('communities', res.data.data)
      commit('currentCommunity', res.data.currentCommunity)
      commit('otherCommunities', res.data.otherCommunities)
    })
  },
  toggerHeader ({ commit }, value) {
    commit('toggerHeader', value)
  },
  toggerMyCommuButton ({ commit }, value) {
    commit('toggerMyCommuButton', value)
  },
  toggerMyCommuFooterMenu ({ commit }, value) {
    commit('toggerMyCommuFooterMenu', value)
  },
  async fetchUsers ({ commit }, value) {
    await fetchUsers(value).then((res) => {
      commit('users', res.data.data)
    })
  },
  setSearch ({ commit }, value) {
    commit('setSearch', value)
  },
  toggerIsSearch ({ commit }, value) {
    commit('toggerIsSearch', value)
  },
  toggleShowSearch ({ commit }, value) {
    commit('toggleShowSearch', value)
  },
  toggleSearched ({ commit }, value) {
    commit('toggleSearched', value)
  },
  isTutorial ({ commit }, value) {
    commit('isTutorial', value)
  },
  toggleShowIconHeader ({ commit }, value) {
    commit('isShowIconHeader', value)
  },
  toggleShowChangeCommu ({ commit }, value) {
    commit('isShowChangeCommu', value)
  },
  toggleShowSchedule ({ commit }, value) {
    commit('isShowSchedule', value)
  },
  toggleIsShowingSearchResult ({ commit }, value) {
    commit('toggleIsShowingSearchResult', value)
  },
  toggleIsForcedShowingFooter ({ commit }, value) {
    commit('toggleIsForcedShowingFooter', value)
  },
  toggleIsFindUser ({ commit }, value) {
    commit('toggleIsFindUser', value)
  },
  toggleIsChat ({ commit }, value) {
    commit('toggleIsChat', value)
  },
  searchUsers ({ commit }, value) {
    const params = {
      q: value
    }
    searchUsers(params).then((res) => {
      commit('searchUsersResult', res.data)
      commit('toggleSearched', true)
    })
  },
  clearSearchUsersResult ({ commit }) {
    commit('searchUsersResult', {users: [], count_supporter: null, count_user: null})
  },
  searchSupporters ({ commit }, { currentCommunity, params, withUsers }) {
    let value_check = ['tutaeru', 'tunagu', 'azukaru', 'all']
    searchSupporters(currentCommunity, params, withUsers).then((res) => {
      commit('setSupporters', res.data.supporters)
      if (params && !value_check.includes(params)) {
        commit('toggleSearched', true)
      } else {
        commit('toggleSearched', false)
      }
    })
  },
  setHiddenHeader ({ commit }, value) {
    commit('setHiddenHeader', value)
  },
  setTitleHeader ({ commit }, value) {
    commit('setTitleHeader', value)
  },
  searchFriends ({ commit }, value = 'all') {
    searchFriends(value).then((res) => {
      commit('setFriends', res.data.friends)
      commit('toggleIsNewMessage', true)
    })
  },
  chatIndex ({ commit }, value = 'all') {
    commit('setLoadingChat', true)
    chatIndex(value).then((res) => {
      commit('setLoadingChat', false)
      commit('setChats', res.data.chats)
      commit('toggleIsChatIndex', true)
    })
  },
  
  fetchUnseenChatCount ({ commit }, value) {
    fetchUnseenChatCount(value).then((res) => {
    commit('setUnseenChatCount', res.data.count)
  })
  },

  setChats ({ commit }, value) {
    commit('setChats', value)
  },

  toggleIsNewMessage ({ commit }, value) {
    commit('toggleIsNewMessage', value)
  },
  isCommuChatDetail ({ commit }, value) {
    commit('isCommuChatDetail', value)
  },
  // joinCommunity ({ state, commit }, value) {
  //   axios.post(`/api/v1/co/communities/${value.community_id}/join_community`).then((res) => {
  //     if (res.data.status === 'success') {
  //       var foundIndex = state.otherCommunities.findIndex(item => item.community_id === value.community_id)
  //       state.otherCommunities.splice(foundIndex, 1)
  //       state.communities.push(value)
  //       window.location.href = `/communities/${res.data.community_id}/join_communities/new`
  //     }
  //   })
  // },
  outCommunity ({ state, commit }, value) {
    axios.delete(`/api/v1/co/communities/${value.community_id}/out_community`).then((res) => {
      if (res.data.status === 'success') {
        var foundIndex = state.communities.findIndex(item => item.community_id === value.community_id)
        state.communities.splice(foundIndex, 1)
        state.otherCommunities.push(value)
      }
    })
  },
  communityOutAllGroups ({ state, commit }, value) {
    axios.delete(`/api/v1/co/communities/${value.community_id}/community_out_all_groups`).then((res) => {
      if (res.data.status === 'success') {
        value.community_groups = []
        var foundIndex = state.communities.findIndex(item => item.community_id === value.community_id)
        state.communities[foundIndex] = value
      }
    })
  },
  communityOutGroups ({ state, commit }, { communityId, groupId }) {
    console.log(communityId, groupId)
    axios.delete(`/communities/communities_groups/${groupId}`).then((res) => {
      if (res.data.status === 'success') {
        var foundIndex = state.communities.findIndex(item => item.community_id === communityId)
        var groupsIndex = state.communities[foundIndex].community_groups.findIndex(item => item.id === groupId)
        state.communities[foundIndex].community_groups.splice(groupsIndex, 1)
      }
    })
  },
  setCurrentCommunity ({ commit }, value) {
    commit('currentCommunity', value)
  },
  setCurrentTab ({ commit }, value) {
    commit('setCurrentTab', value)
  }
}
