<template>
  <div
    ref="map"
    class="the-map"
    :style="inlineStyle"
  />
</template>

<script>
import 'leaflet/dist/leaflet.css'
import 'leaflet.markercluster/dist/MarkerCluster.css'
import 'leaflet.markercluster/dist/MarkerCluster.Default.css'
import 'leaflet.fullscreen/Control.FullScreen.css'
import 'leaflet-loading/src/Control.Loading.css'
import GoogleMapsLoader from 'google-maps'
import axios from 'axios'
import L from 'leaflet'
import 'leaflet.vectorgrid'
import 'leaflet.markercluster'
import 'leaflet.gridlayer.googlemutant'
import 'leaflet.fullscreen'
import 'leaflet-loading'
import { isProduction } from 'lib/env'
import { isAndroid, isChrome } from 'lib/browser'
import { KEY } from 'google-maps'
import { mapState } from 'vuex'
import { fetchAvatar } from "../apis/co"

const ICON_SIZE = {
  'cluster-community': [59, 59],
  'cluster-event': [55, 61],
  'cluster-online-event-text': [59, 59],
  'cluster-online-event-video': [59, 59],
  'cluster-supporter': [52, 69],
  'cluster-organization': [59, 86],
  'pin-community': [59, 57],
  'pin-event': [61, 60],
  'pin-online-event-text': [59, 53],
  'pin-online-event-video': [59, 42],
  'pin-supporter-azukaru': [55, 61],
  'pin-supporter': [56, 62],
  'pin-supporter-tunagu': [56, 62],
  'pin-organization': [59, 57],
  'pin-event-for-my-commu': [49, 55],
  'pin-supporter-for-my-commu': [42, 62],
  'pin-organization-for-my-commu': [49, 79]
}

const CLUSTERED_MARKER_COUNT_UNIT = {
  supporter: '人',
  community: '件',
  event: '件',
  'online-event-text': '件',
  'online-event-video': '件',
  organization: '件'
}

export default {
  props: {
    mapHeight: {
      type: Number,
      default: 450
    },
    lat: {
      type: Number,
      default: 35.65858
    },
    lng: {
      type: Number,
      default: 139.745433
    },
    zoom: {
      type: Number,
      default: 12
    },
    maxZoom: {
      type: Number,
      default: 13
    },
    minZoom: {
      type: Number,
      default: 5
    },
    radius: {
      type: Number,
      default: null
    },
    markSupporter: {
      type: Boolean,
      default: false
    },
    markCommunity: {
      type: Boolean,
      default: false
    },
    markEvent: {
      type: Boolean,
      default: false
    },
    markOrganization: {
      type: Boolean,
      default: false
    },
    markUser: {
      type: Boolean,
      default: false
    },
    markSupporterForMyCommu: {
      type: Boolean,
      default: false
    },
    markEventForMyCommu: {
      type: Boolean,
      default: false
    },
    markOrganizationForMyCommu: {
      type: Boolean,
      default: false
    },
    markUserForMyCommu: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      map: null,
      centerCircle: null,
      supporterCluster: null,
      communityCluster: null,
      eventCluster: null,
      onlineEventTextCluster: null,
      onlineEventVideoCluster: null,
      organizationCluster: null
    }
  },
  computed: {
    ...mapState('session', ['communityId']),
    inlineStyle () {
      return { height: `${this.mapHeight}px` }
    }
  },
  watch: {
    radius () {
      this.$_drawCenterCircle()
    },
    lat () {
      this.$_moveCenter()
    },
    lng () {
      this.$_moveCenter()
    }
  },
  mounted () {
    GoogleMapsLoader.load(() => {
      this.$_buildMap()
      this.$_buildCluster()
      this.$_drawCenterCircle()
      this.$_fetchMarks()
    })
  },
  methods: {
    $_buildMap () {
      this.map = this.$_newMap()
      this.map.setView([this.lat, this.lng], this.zoom)
      this.map.setMinZoom(this.minZoom)
      this.map.setMaxZoom(this.maxZoom)
      this.map.addLayer(L.gridLayer.googleMutant({ type: 'roadmap' }))
      this.map.addControl(L.Control.loading({ separate: true }))
      this.centerCircle = L.circle([this.lat, this.lng], {
        color: '#bd10e0',
        weight: 1,
        fillColor: 'rgb(189, 16, 224)',
        fillOpacity: 0.1
      })
    },
    $_buildCluster () {
      this.supporterCluster = this.$_newMarkerClusterGroup('supporter')
      this.communityCluster = this.$_newMarkerClusterGroup('community')
      this.eventCluster = this.$_newMarkerClusterGroup('event')
      this.onlineEventTextCluster = this.$_newMarkerClusterGroup('online-event-text')
      this.onlineEventVideoCluster = this.$_newMarkerClusterGroup('online-event-video')
      this.organizationCluster = this.$_newMarkerClusterGroup('organization')
    },
    $_drawCenterCircle () {
      if (!this.radius) return
      if (!this.centerCircle) return

      this.centerCircle.setRadius(this.radius).addTo(this.map)
      this.map.fitBounds(this.centerCircle.getBounds())
    },
    $_moveCenter () {
      const latlng = { lat: this.lat, lng: this.lng }
      this.map.panTo(latlng)
      if (this.centerCircle) {
        this.centerCircle.setLatLng(latlng)
      }
    },
    $_fetchMarks () {
      if (this.markUser) {
        this.$_markUser()
      }
      if (this.markSupporter) {
        this.$_loadGeoJson('/markers/supporters.json', this.supporterCluster)
      }
      // NOTE: コミュニティが多くて重いので, 本番環境では表示しない
      if (this.markCommunity && !isProduction()) {
        this.$_loadGeoJson('/markers/communities.json', this.communityCluster)
      }
      if (this.markEvent) {
        this.$_loadGeoJson('/markers/events.json', this.eventCluster)
        this.$_loadGeoJson('/markers/online_events.json', (feature) => {
          const { properties: { onlineEventType } } = feature
          return onlineEventType === 'text' ? this.onlineEventTextCluster : this.onlineEventVideoCluster
        })
      }
      if (this.markOrganization) {
        this.$_loadGeoJson('/markers/organizations.json', this.organizationCluster)
      }

      if (this.markUserForMyCommu) {
        this.$_markUserForMyCommu()
      }
      if (this.markSupporterForMyCommu) {
        this.$_loadGeoJson('/markers/supporters_for_my_commu.json', this.supporterCluster)
      }
      if (this.markEventForMyCommu) {
        this.$_loadGeoJson('/markers/events_for_my_commu.json', this.eventCluster)
      }
      if (this.markOrganizationForMyCommu) {
        this.$_loadGeoJson('/markers/organizations_for_my_commu.json', this.organizationCluster)
      }
    },
    $_isVisible (target, communityId, all_data) {
      //地図上に表示する条件
      //同一IDのものが1つだけの場合は表示する。
      //同一IDのものが複数ある場合は、現在のコミュニティと同じコミュニティのものを表示する。
      //同一IDのものが複数あり、かつ、現在のコミュニティのものはない場合、ソートして最初のものを表示する。
      var same_id_list = all_data.filter(function (data) {return data.properties.id === target.properties.id})
      if (same_id_list.length == 1) {
        return true
      } else {
        var current_community_same_id_list = same_id_list.filter(function (data) {return data.properties.communityId === communityId})
        if (current_community_same_id_list.length == 1) {
          if (target.properties.communityId == communityId) {
            return true
          } else {
            return false
          }
        } else {
          same_id_list.sort(function(a,b){if(a.properties.communityId < b.properties.communityId){return -1} else {return 1}})
          if (same_id_list[0].properties.communityId == target.properties.communityId) {
            return true
          }
        }
      }
      return false
    },
    async $_markUser () {
      const { data } = await axios.get('/markers/users.json')
      const userLayer = L.vectorGrid.slicer(data, {
        rendererFactory: L.canvas.tile,
        vectorTileLayerStyles: {
          sliced: {
            radius: 4,
            weight: 1,
            stroke: true,
            color: 'black',
            fillOpacity: 1,
            fillColor: '#ffff99',
            fill: true
          }
        }
      })
      userLayer.addTo(this.map)
    },
    async $_markUserForMyCommu () {
      const { data } = await axios.get('/markers/users_for_my_commu.json')
      const userLayer = L.vectorGrid.slicer(data, {
        rendererFactory: L.canvas.tile,
        vectorTileLayerStyles: {
          sliced: {
            radius: 4,
            weight: 1,
            stroke: true,
            color: 'black',
            fillOpacity: 1,
            fillColor: '#ffff99',
            fill: true
          }
        }
      })
      userLayer.addTo(this.map)
    },
    async $_loadGeoJson (path, clusterOrFunction) {
      const { data: { features } } = await axios.get(path)
      features.forEach(async (feature) => {
        if (this.$_isVisible(feature, this.communityId, features)) {
          const { geometry: { coordinates }, properties: { icon, type, communityId, id} } = feature
          const [lng, lat] = coordinates
          const [width, height] = ICON_SIZE[icon]

          var tmp_marker = null
          if (type == 'supporter_for_my_commu') {
            const {data: { avatarPath} } = await fetchAvatar(communityId, id)
            console.log("avatarPath:" + avatarPath)
            tmp_marker = L.marker([lat, lng], {
              icon: L.divIcon({
                html: `<div class="marker-supporter-for-my-commu-icon" style="background-image:url(/mapmarkers/${icon}.png);"><img src="${avatarPath}" class="avatar-user"/></div>`,
              })
            }).on('click', () => {
              const { properties: { type, id } } = feature
              this.$emit('click-marker', { type, id })
            })
          } else if (type == 'event_for_my_commu') {
            const { properties: { year, date } } = feature
            tmp_marker = L.marker([lat, lng], {
              icon: L.divIcon({
                html: `<div class="marker-event-for-my-commu-icon" style="background-image:url(/mapmarkers/${icon}.png);"><div class="marker-event-for-my-commu-year">${year}</div><div class="marker-event-for-my-commu-date">${date}</div></div>`,
              })
            }).on('click', () => {
              const { properties: { type, id } } = feature
              this.$emit('click-marker', { type, id })
            })
          } else {
            tmp_marker = L.marker([lat, lng], {
              icon: L.icon({
                iconUrl: `/mapmarkers/${icon}.png`,
                iconSize: [width, height]
              })
            }).on('click', () => {
              const { properties: { type, id } } = feature
              this.$emit('click-marker', { type, id })
            })
          }
          const marker = tmp_marker     
          const cluster = typeof clusterOrFunction === 'function' ? clusterOrFunction(feature) : clusterOrFunction
          cluster.addLayer(marker)
        }
      })
    },
    $_newMarkerClusterGroup (featureType) {
      return L.markerClusterGroup({
        iconCreateFunction: cluster => L.divIcon({
          html: `<div class="marker-${featureType}-cluster-icon" style="${this.$_markerClusterGroupStyle(featureType)}"><span class="marker-cluster-count">${cluster.getChildCount()}</span></div>`
        })
      }).addTo(this.map)
    },
    $_markerClusterGroupStyle (featureType) {
      return `background-image:url(/mapmarkers/cluster-${featureType}.png)`
    },
    $_newMap () {
      const map = L.map(this.$refs.map, {
        attributionControl: false,
        fullscreenControl: true,
        fullscreenControlOptions: {
          position: 'topright',
          forceSeparateButton: true
        }
      })
      if (isAndroid() && isChrome()) {
        map.on('zoomstart', () => { window.scrollTo({ top: 0 }) })
      }
      return map
    },
  }
}
</script>

<style scoped lang="sass">
@import '~stylesheets/resources'

/deep/
  .marker-supporter-cluster-icon
    display: flex
    justify-content: right
    align-items: start
    color: white
    font-weight: bold
    width: 51px
    height: 69px
    margin-top: -34.5px
    margin-left: -27.5px
    background-size: contain
    background-repeat: no-repeat

  .marker-event-cluster-icon
    display: flex
    justify-content: right
    align-items: start
    color: white
    font-weight: bold
    width: 55px
    height: 61px
    margin-top: -33px
    margin-left: -30.5px
    background-size: contain
    background-repeat: no-repeat

  .marker-organization-cluster-icon
    display: flex
    justify-content: right
    align-items: start
    color: white
    font-weight: bold
    width: 59px
    height: 86px
    margin-top: -43px
    margin-left: -29.5px

  .marker-cluster-count
    margin-right: 8px
    margin-top: 2px
    font-size: 1.2em

  .marker-event-for-my-commu-icon 
    display: flex
    flex-flow: column
    justify-content: space-around
    align-items: center
    color: white
    font-weight: bold
    width: 49px
    height: 55px
    margin-top: -27.5px
    margin-left: -24.5px
    background-size: contain
    background-repeat: no-repeat

  .marker-event-for-my-commu-year
    margin-top: +1px

  .marker-event-for-my-commu-date
    color: black
    margin-bottom: +15px

  .marker-supporter-for-my-commu-icon 
    display: flex
    justify-content: center
    align-items: start
    color: white
    font-weight: bold
    width: 42px
    height: 62px
    margin-top: -20px
    margin-left: -14px
    padding-top: 7px
    padding-left: 2px
    background-size: cover
    background-repeat: no-repeat

  .avatar-user
    border-radius: 50%
    object-fit: cover
    flex-shrink: 0
    width: 36px
    height: 36px
    z-index: 501

+app-mobile
  /deep/
    .leaflet-control-container .leaflet-top.leaflet-right
      display: none
</style>
