<template>
  <BField
    :label="label"
    :message="errorMessage"
    :type="status"
    :label-for="id"
    :custom-class="customClass"
  >
    <BSelect
      :id="id"
      v-model="selectedValues"
      :name="name"
      :class="{'is-mbfs-small': isMobileSmall, 'dark-gray-color' : isPlaceholder}"
      class="select-tag"
      @input="handleInput"
    >
      <option value="" disabled selected hidden v-if="hasPlaceholder">{{ placeholder }}</option>
      <option
        v-for="opt in options"
        :key="opt.value"
        :value="opt.value"
        v-text="opt.label"
      />
    </BSelect>
  </BField>
</template>

<script>
import * as inputs from './inputs'

function setValues (placeholder, value) {
  if (value !== '') {
    return value
  } else {
    return placeholder
  }
}

export default {
  props: {
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: null
    },
    value: {
      type: [String, Number],
      default: () => []
    },
    errors: {
      type: Array,
      default: () => []
    },
    collection: {
      type: Array,
      required: true
    },
    includeBlank: {
      type: Boolean,
      default: false
    },
    onChange: {
      type: Function,
      default: () => {}
    },
    isMobileSmall: {
      type: Boolean,
      default: false
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    isPublished: {
      type: Boolean,
      default: false
    },
    addInputtedClass: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      selectedValues: setValues(this.placeholder, this.value),
      displayErrors: this.errors,
      isInputted: false
    }
  },
  computed: {
    status () {
      return inputs.status(this.errors, this.isInputted)
    },
    id () {
      return inputs.idForName(this.name)
    },
    customClass () {
      return inputs.customClass(this.$props)
    },
    options () {
      const opts = this.collection.map((e) => {
        const [label, value] = Array.isArray(e) ? e : [e, e]
        return { label, value }
      })
      if (this.includeBlank) {
        return [{ label: '', value: '' }].concat(opts)
      }
      return opts
    },
    errorMessage () {
      if (this.isInputted) {
        return this.displayErrors.filter(message => !message.match(/を入力してください/i))
      }
      return this.displayErrors
    },
    hasPlaceholder () {
      return this.placeholder !== ''
    },
    isPlaceholder () {
      return this.selectedValues === ''
    }
  },
  methods: {
    handleInput (value) {
      this.onChange(value)
      this.isInputted = this.addInputtedClass && value.length > 0

      if (this.selectedValues !== '') {
        this.$emit('validation', true)
      } else {
        this.$emit('validation', false)
      }
    }
  }
}
</script>

<style scoped lang="sass">
@import '~stylesheets/resources'
/deep/
  @import '~stylesheets/components/required-label'
  @import '~stylesheets/components/published-label'
/deep/ .select:not(.is-multiple):not(.is-loading)::after
  border-width: 2px
  height: 0.425em
  width: 0.425em
  margin-top: -0.2875em
/deep/
  +mobile
    .is-mbfs-small
      font-size: 14px
    .select select,
    .select select:not([multiple])
      padding-right: 1.5em
    .select:not(.is-multiple):not(.is-loading)::after
      right: 0.75em
.select-tag
  /deep/ span
    width: 100%
  /deep/ select
    width: 100%
    border-radius: 6px
    caret-color: #2DBA87
    &:focus
      border: 1px solid #2DBA87
      border-radius: 6px
      box-shadow: 0 0 0 0

.dark-gray-color
  /deep/
    span
      select
        color: #AAAAAA
</style>
