<template>
  <div class="">
    <div class="has-text-left is-mb-4">
      <h2 class="title-page has-text-weight-bold">近くのコミュニティを探す</h2>
    </div>
    <div class="columns is-mobile">
      <div class="column">
        <BField
          :message="zipCodeErrorMessage"
          :type="zipCodeStatus"
          class="is-mb-3"
          label='郵便番号'
        >
          <BInput
            v-model="dirtyZipCode"
            name="profile[zip_code]"
            placeholder="1000013"
            class="is-fullwidth"
            customClass=" input-green"
          />
        </BField>
      </div>
      <div class="column handle-zip-code">
        <BButton
          class="is-fullwidth is-mt-1 bg-white"
          type='is-success'
          outlined
          @click="handleZipCodeKeypress"
        >
          住所検索
        </BButton>
      </div>
    </div>
    <div class="columns is-mobile">
      <div class="column is-fullwidth select-prefecture">
        <BField
          label="都道府県"
          label-for="profile_prefecture_"
          class="is-mb-2"
        >
          <BSelect
            id="profile_prefecture_"
            :class="{'dark-gray-color' : isPlaceholder}"
            v-model="dirtyPrefecture"
            name="profile[prefecture]"
            class="js-geocoding-region is-fullwidth input-green"
          >
            <option value="" disabled selected hidden>選択する</option>
            <option
              v-for="opt in prefectureOptions"
              :key="opt"
              :value="opt"
              v-text="opt"
            />
          </BSelect>
        </BField>
      </div>
      <div class="column"> </div>
    </div>
    <div class="sign-up-address-input-address__form">
      <BField
        label="市区町村"
        label-for="profile_address1_"
      >
        <BInput
          id="profile_address1_"
          v-model="dirtyAddress1"
          name="profile[address1]"
          placeholder="横浜市中区山下町"
          class="js-geocoding-address1"
          customClass="input-green"
        />
      </BField>
      <BButton
        type='submit'
        @click='submit'
        :disabled="checkInput"
        class="is-fullwidth is-success border-radius-6"
      >
        次へ
      </BButton>
    </div>
  </div>
</template>

<script>
import { findAddressFromPostal } from 'lib/auto_input_address'

const VIEW_ZIP_CODE = 'zip-code'
const VIEW_ADDRESS = 'address'

export default {
  props: {
    zipCode: {
      type: String,
      default: ''
    },
    prefecture: {
      type: String,
      default: ''
    },
    address1: {
      type: String,
      default: ''
    },
    prefectureOptions: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      dirtyZipCode: this.zipCode,
      dirtyPrefecture: this.prefecture,
      dirtyAddress1: this.address1,
      zipCodeErrorMessage: '',
    }
  },
  computed: {
    zipCodeStatus () {
      return this.zipCodeErrorMessage.length > 0 ? 'is-danger' : null
    },
    checkInput () {
      return (this.dirtyAddress1 === '' || this.dirtyZipCode === '' || this.dirtyPrefecture === '')
    },
    isPlaceholder () {
      return this.dirtyPrefecture === ''
    }
  },
  methods: {
    async fillAddressByZipCode (zipCode) {
      const { address, error } = await findAddressFromPostal(zipCode)
      if (address) {
        this.dirtyPrefecture = address.region
        this.dirtyAddress1 = address.locality + address.street
        this.zipCodeErrorMessage = ''
      } else {
        this.dirtyPrefecture = ''
        this.dirtyAddress1 = ''
        this.zipCodeErrorMessage = error
      }
    },
    handleZipCodeBlur ({ currentTarget }) {
      this.fillAddressByZipCode(currentTarget.value)
    },
    handleZipCodeKeypress (ev) {
      this.fillAddressByZipCode(this.dirtyZipCode)
    },
    submit() {
      document.querySelector('form').submit()
    }
  }
}
</script>

<style scoped lang="sass">
  .dark-gray-color
    /deep/
      span
        select
          color: #AAAAAA
  .title-page
    font-size: 20px
  .column
    padding-bottom: unset
    /deep/ label
      font-weight: 300
      font-size: 14px
      line-height: 14px
      margin-bottom: 8px
  .handle-zip-code
    margin-top: 17px
    /deep/ > button.is-outlined
      border: unset
      box-shadow: 0px 2px 8px #F2EFDE
      border-radius: 6px
      &:hover
        background-color: #D5F1E7
        color: #2DBA87
  .sign-up-address-input-address__form
    border-radius: 6px
    /deep/ label
      font-weight: 300
      font-size: 14px
      line-height: 14px
      margin-bottom: 8px
    /deep/
      .field
        margin-bottom: 40px
  .select-prefecture
    /deep/ span
      width: 100%
    /deep/ select
      width: 100%
      border-radius: 6px
      caret-color: #2DBA87
      &:focus
        border: 1px solid #2DBA87
        border-radius: 6px
        box-shadow: 0 0 0 0
  .bg-white
    background: white !important
    border-radius: 6px
  /deep/ .input-green
    border-radius: 6px
    caret-color: #2DBA87
    &:focus
      border: 1px solid #2DBA87
      border-radius: 6px
      box-shadow: 0 0 0 0
  /deep/
    .border-radius-6
      border-radius: 6px
    input
      height: 48px
      font-family: "Hiragino Kaku Gothic ProN"
    .select
      height: 48px !important
      select
        height: 48px
        font-family: "Hiragino Kaku Gothic ProN"
    button
      height: 48px
      font-family: "Hiragino Kaku Gothic ProN"
  .is-success
    /deep/ span
      font-weight: bold
</style>
