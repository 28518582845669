<template>
  <div
    class="detail-group"
    :class="loading ? 'is-processing' : ''"
  >
    <Back
      title="シェア投稿"
      :show-commu="false"
      :use-emit="true"
      @back="back"
    />
    <Processing />
    <div class="form-content">
      <div class="description">
        <p>あなたの周りの気になる事を</p>
        <p>コミュニティのみんなに共有しましょう。</p>
      </div>
      <MyCommuBaseTextInput
        v-model="content"
        name="body"
        label="メッセージ"
        placeholder="投稿する本文を入力してください。"
        type="textarea"
      />

      <div class="setting-image mt-24">
        <div class="event-label">
          <label>
            <div class="primary">画像設定</div>
          </label>
          <MyCommuOptional />
        </div>
        <p class="image-note">
          最大4枚・20MBまで、JPG/JPEG・PNG・GIF形式対応
        </p>
      </div>
      <div class="mt-8 upload-image">
        <BField class="is-white file">
          <BUpload
            v-model="files"
            class="file-label file-upload"
            :multiple="true"
            :disabled="files.length === 4"
            accept=".jpg, .png, .jpeg, .gif"
            @input="cutFiles"
          >
            <span
              class="file-cta"
              :class="{'disabled': files.length === 4}"
            >
              <BIcon
                class="file-icon"
                icon="plus"
              />
            </span>
          </BUpload>
        </BField>
        <div
          v-for="(file, index) in files"
          :key="index"
          class="image-upload"
        >
          <img
            :src="getUrl(file)"
            class="img"
          >
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            @click="removeImage(index)"
          >
            <path
              d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z"
              fill="white"
              stroke="#273D35"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M6 6L10 10"
              stroke="#273D35"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M10 6L6 10"
              stroke="#273D35"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>
      <MyCommuBaseButton
        class="mt-40"
        text-content="投稿する"
        :disable="content === ''"
        @clickButton="createTopic"
      />
    </div>
  </div>
</template>

<script>
import Back from '../Back'
import { mapActions, mapState } from 'vuex'
import axios from 'axios'
import queryString from 'query-string'

export default {
  components: { Back },
  props: {
    images: {
      type: Array,
      default: () => []
    },
    body: {
      type: String,
      default: ''
    },
    path: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'topic'
    },
    groupId: {
      type: Number,
      default: undefined
    }
  },
  data () {
    return {
      isModalActive: true,
      iconBack: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
          '<g clip-path="url(#clip0_10847_11145)">\n' +
          '<path d="M5.43018 5.42999L18.5702 18.57" stroke="#273D35" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>\n' +
          '<path d="M18.5702 5.42999L5.43018 18.57" stroke="#273D35" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>\n' +
          '</g>\n' +
          '<defs>\n' +
          '<clipPath id="clip0_10847_11145">\n' +
          '<rect width="24" height="24" fill="white"/>\n' +
          '</clipPath>\n' +
          '</defs>\n' +
          '</svg>\n',
      files: this.images,
      content: this.body,
      groupPath: `/communities/${this.communityId}/community_groups/${this.groupId}?backTo=groups_list`
    }
  },
  computed: {
    ...mapState('session', ['communityId']),
    ...mapState('mycommu', ['loading'])
  },
  mounted () {
    this.toggerHeader(true)
    this.toggerMyCommuFooterMenu(false)
    this.toggerMyCommuButton(false)
  },
  methods: {
    ...mapActions('mycommu', [
      'toggerMyCommuButton',
      'toggerHeader',
      'toggerMyCommuFooterMenu',
      'setLoading'
    ]),
    back () {
      const paramsx = queryString.parse(location.search)
      window.location.href = paramsx.detailFrom === 'notification' ? this.path + '?from=notification' : this.path + '?from=edit'
    },
    getUrl (e) {
      if (e.url !== undefined) {
        return e.url
      } else {
        const url = URL.createObjectURL(e)
        URL.revokeObjectURL(e)
        return url
      }
    },
    cutFiles () {
      this.files = this.files.slice(0, 4)
    },
    removeImage (index) {
      this.files.splice(index, 1)
    },
    async createTopic () {
      this.setLoading(true)
      const data = new FormData()
      data.append(`${this.type}[body]`, this.content)
      Array.from(this.files).forEach((file, index) => {
        if (file.url !== undefined) {
          file = file.url
        }
        data.append(`${this.type}[image${index + 1}]`, file)
      })
      await axios.patch(
        `${this.path}`,
        data
      ).then((response) => {
        this.setLoading(false)
        window.location.href = this.groupId ? this.groupPath : this.path
      })
    }
  }
}
</script>
<style scoped lang="sass">
.is-processing
  opacity: 0.5
.event-label
  margin-top: 20px
  display: flex
  justify-content: space-between
  align-content: center
  margin-bottom: 5px
.ml-20
  margin-left: 20px
.mt-40
  margin-top: 40px
.upload-image
  display: flex
  /deep/
    .file
      margin-right: 16px
      display: unset
      .file-upload
        width: 48px
        height: 48px
        box-shadow: 0px 2px 8px #f2efde
        .file-cta
          width: 100%
          height: 100%
      .label
        font-size: 14px
        cursor: pointer
      .file-upload
        .file-icon
          margin: auto
          color: #2DBA87
        .disabled
          opacity: 0.4
.image-upload
  position: relative
  border-radius: 6px
  margin-right: 16px
  .img
    width: 48px
    height: 48px
    object-fit: cover
    border-radius: 6px
    border: 1px solid #7C7C7C
  svg
    position: absolute
    left: 38px
    top: -5.5px

.form-content
  margin: 0 !important
  padding: 0 32px
  .description
    margin: 40px 0
    text-align: center
.image-note
  font-size: 12px
  color: #2DBA87
  margin: 8px 0 16px
</style>
