<template>
  <div class="modal-card">
    <header class="modal-card-head">
      <p v-text="title" />
    </header>
    <section
      ref="cardBody"
      class="modal-card-body"
    >
      <div class="has-text-centered is-mt-3 is-mb-3">
        <BSelect
          v-model="baseLocation"
          @input="search"
        >
          <option value="home">
            自宅周辺から
          </option>
          <option value="address">
            任意の住所や場所
          </option>
        </BSelect>
        <BInput
          v-show="baseLocation === 'address'"
          v-model="address"
          placeholder="住所や施設名などを入力してください"
          @blur="onAddressBlur"
        />
        <div>
          <button
            type="button"
            :class="radiusButtonClass(2000)"
            @click.prevent="onRadius(2000)"
          >
            2km
          </button>
          <button
            type="button"
            :class="radiusButtonClass(5000)"
            @click.prevent="onRadius(5000)"
          >
            5km
          </button>
          <button
            type="button"
            :class="radiusButtonClass(10000)"
            @click.prevent="onRadius(10000)"
          >
            10km
          </button>
          <button
            type="button"
            :class="radiusButtonClass(20000)"
            @click.prevent="onRadius(20000)"
          >
            20km
          </button>
        </div>
      </div>
      <!-- <p class="section-label">
        {{ textTitle }}
      </p> -->
      <!-- <div class="community-groups"> -->
        <!-- <SharingCandidateCommunitySelect
          v-for="community in communities"
          :key="community.id"
          v-bind="community"
          :is-my-commu-domain="isMyCommuDomain"
          @change-selected-community="onCommunityChange"
          @change-selected-user="onCommunityUserChange"
        /> -->
      <!-- </div> -->
      <p class="section-label user-check-all">
        <BCheckboxButton
          v-model="checkedAll"
          @input="onChangeAll"
        >
          友達 (<span v-text="visibleUserCountText" />)
        </BCheckboxButton>
      </p>
      <div
        v-if="users.length > 0"
        class="user-wrapper"
      >
        <div class="user-bar">
          <div class="bar" />
        </div>
        <div class="user-items">
          <div
            v-for="user in visibleUsers"
            :key="user.id"
            class="sharing-candidate-checkbox"
          >
            <BCheckboxButton
              v-model="selectedUsers"
              :native-value="user.id"
              @input="onInputUser"
            >
              <SharingCandidateUser v-bind="user" :is-my-commu-domain="isMyCommuDomain" :isFriendGaugeHidden="true" />
            </BCheckboxButton>
          </div>
        </div>
      </div>
      <div v-if="isForEntry">
        <p class="section-label">
          シェア・コンシェルジュ
        </p>
        <div
          v-if="supporters.length > 0"
          class="user-wrapper"
        >
          <div class="user-bar">
            <div class="bar" />
          </div>
          <div class="user-items">
            <div
              v-for="user in supporters"
              :key="user.id"
              class="sharing-candidate-checkbox low-friend"
            >
              <BCheckboxButton
                v-model="selectedUsers"
                :native-value="user.id"
                @input="onInputUser"
              >
                <SharingCandidateUser v-bind="user" :is-my-commu-domain="isMyCommuDomain" :isFriendGaugeHidden="true" />
              </BCheckboxButton>
            </div>
          </div>
        </div>
      </div>
    </section>
    <footer class="modal-card-foot">
      <button
        class="button is-rounded"
        type="button"
        @click="$parent.close()"
      >
        閉じる
      </button>
      <button
        class="button is-primary is-rounded"
        type="button"
        @click="submit"
      >
        決定
      </button>
    </footer>
  </div>
</template>

<script>
import axios from 'axios'
import SharingCandidateCommunitySelect from 'components/SharingCandidateCommunitySelect'
import { fullAddress2Latlng } from 'lib/auto_input_latlng'
import { isIOS12 } from 'lib/browser'
import { mapState } from 'vuex'

const MAX_USER_COUNT = 300

export default {
  components: { SharingCandidateCommunitySelect },
  props: {
    title: {
      type: String,
      required: true
    },
    purpose: {
      type: String,
      required: true
    },
    isMyCommuDomain: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      baseLocation: 'home',
      radius: 2000,
      selectedUsers: [],
      selectedCommunities: [],
      users: [],
      communities: [],
      supporters: [],
      address: '',
      lat: null,
      lng: null,
      checkedAll: []
    }
  },
  computed: {
    ...mapState('session', ['communityGroupId', 'communityId']),
    radiusButtonClass () {
      return radius => ({
        'is-outlined': this.radius !== radius,
        button: true,
        'is-primary': true,
        'is-rounded': true,
        'is-small': true
      })
    },
    isForEntry () {
      return this.purpose === 'Entry'
    },
    visibleUsers () {
      return this.users.slice(0, MAX_USER_COUNT)
    },
    visibleUserCountText () {
      return `${this.visibleUsers.length}人${this.users.length > MAX_USER_COUNT ? '+' : ''}`
    }
  },
  created () {
    this.search()
  },
  methods: {
    onRadius (radius) {
      if (this.radius === radius) {
        return
      }
      this.radius = radius
      this.search()
    },
    async search () {
      var organizationType = window.location.href.includes('community_group') ? 'OrganizationGroup' : 'Organization'
      var organizationId = window.location.href.includes('community_group') ? this.communityGroupId : this.communityId
      const params = {
        'q[base_location]': this.baseLocation,
        'q[latitude]': this.lat,
        'q[longitude]': this.lng,
        'q[radius]': this.radius,
        'q[purpose]': this.purpose,
        'q[organization_type]': organizationType,
        'q[organization_id]': organizationId,
      }
      const { data } = await axios.get('/sharing_candidates.json', { params })
      this.users = data.users
      this.communities = data.communities
      this.supporters = data.supporters
    },
    submit () {
      const selectables = this.selectableUsers()
      const users = this.selectedUsers
        .map(id => selectables.filter(u => u.id === id)[0])
        .filter(u => !!u) // 念のため undefined があれば除外しておく
      this.$emit('submit', users, this.communities.filter(c => this.selectedCommunities.indexOf(c.id) !== -1))
      this.$parent.close()
    },
    selectableUsers () {
      const communityUsers = this.communities
        .map(c => c.users)
        .reduce((acc, val) => acc.concat(val), [])
      return [...this.users, ...communityUsers, ...(this.supporters || [])]
    },
    onCommunityUserChange ({ userId, checked }) {
      if (checked) {
        if (this.selectedUsers.indexOf(userId) === -1) {
          this.selectedUsers.push(userId)
        }
      } else {
        this.selectedUsers = this.selectedUsers.filter(_ => _ !== userId)
      }
      this.afterChangeCheck()
    },
    onCommunityChange ({ id, checked }) {
      if (checked) {
        if (this.selectedCommunities.indexOf(id) === -1) {
          this.selectedCommunities.push(id)
        }
      } else {
        this.selectedCommunities = this.selectedCommunities.filter(_ => _ !== id)
      }
      this.afterChangeCheck()
    },
    async onAddressBlur () {
      const latlng = await fullAddress2Latlng(this.address)
      if (latlng) {
        this.lat = latlng.lat()
        this.lng = latlng.lng()
        this.search()
      }
    },
    onChangeAll (checked) {
      if (checked.length > 0) {
        this.selectedUsers = this.users.map(_ => _.id)
      } else {
        this.selectedUsers = []
      }
      this.afterChangeCheck()
    },
    onInputUser () {
      this.afterChangeCheck()
    },
    afterChangeCheck () {
      // iOS 12 で data を変えても再描画されない不具合への対応
      // https://www.sonicgarden.world/groups/3415/entries/801248
      if (isIOS12()) {
        // https://martinwolf.org/before-2018/blog/2014/06/force-repaint-of-an-element-with-javascript/
        const { cardBody } = this.$refs
        const disp = cardBody.style.display
        cardBody.style.display = 'none'
        cardBody.offsetHeight // eslint-disable-line no-unused-expressions
        cardBody.style.display = disp
        // https://stackoverflow.com/a/17257650
        window.scrollBy(1, 1)
        window.scrollBy(-1, -1)
      }
    }
  }
}
</script>

<style scoped lang="sass">
@import '~stylesheets/resources'
@import '~stylesheets/components/sharing-candidate-checkbox'

.modal-card-head
  padding: 14px
  justify-content: center
  p
    text-align: center
    font-weight: bold

.modal-card-foot
  padding: 12px
  justify-content: center

.modal-card-body
  padding: 0 0 20px

.has-text-centered
  .control
    text-align: center
    margin-bottom: 1rem

.low-friend:last-child
  border-bottom: 1px solid #d7d7d7

.section-label
  font-size: 14px
  font-weight: 600
  border-top: 1px solid $color-border-gray
  border-bottom: 1px solid $color-border-gray
  padding: 10px 14px 6px

.community-groups
  /deep/ .community:last-child:not(.is-open)
    .community-item-wrapper
      border-bottom: none

.user-check-all
  padding: 0
  /deep/
    .control
      label
        font-size: 14px
        font-weight: 600
        padding-top: 10px
        padding-bottom: 6px

.user-wrapper
  display: flex
  justify-content: space-between

.user-bar
  width: 26px
  text-align: center
  padding: 6px 10px
  .bar
    display: block
    background-color: #eee
    width: 6px
    height: 100%
    border-radius: 6px

.user-items
  border-left: 1px solid #d7d7d7
  flex: 1
</style>
