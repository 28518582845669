<template>
  <div :class="loading ? 'is-processing': ''">
    <slot
      :onSubmit="onSubmit"
      :onChangeStartedAt="onChangeStartedAt"
      :onChangeFinishedAt="onChangeFinishedAt"
    />
  </div>
</template>

<script>
import { format } from 'date-fns'
import * as shareable from './shareable_form'
import { mapActions, mapState } from 'vuex'

export default {
  props: {
    startedOn: {
      type: String,
      default: ''
    },
    finishedOn: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      inputtedStartedOn: this.startedOn,
      inputtedFinishedOn: this.finishedOn
    }
  },
  computed: {
    ...mapState('mycommu', ['loading'])
  },
  methods: {
    ...mapActions('mycommu', ['setLoading']),
    onSubmit (ev) {
      this.setLoading(true)
      shareable.confirmSharingCandidateEmpty(ev)
    },
    onChangeStartedAt (date) {
      this.inputtedStartedOn = format(date, 'YYYY/MM/DD')
    },
    onChangeFinishedAt (date) {
      this.inputtedFinishedOn = format(date, 'YYYY/MM/DD')
    }
  }
}
</script>

<style scoped lang="sass">
.is-processing
  opacity: 0.5
</style>
