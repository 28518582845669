<template>
  <div class="event-tags">
    <ul>
      <li
        :class="{ 'active': activeClass('all')}"
        @click="onClick('all')"
      >
        全て表示
      </li>
      <li
        :class="{ 'active': activeClass('givings')}"
        @click="onClick('givings')"
      >
        譲るよ
      </li>
      <li
        :class="{ 'active': activeClass('takings')}"
        @click="onClick('takings')"
      >
        譲って
      </li>
      <li
        :class="{ 'active': activeClass('lendings')}"
        @click="onClick('lendings')"
      >
        貸すよ
      </li>
      <li
        :class="{ 'active': activeClass('borrowings')}"
        @click="onClick('borrowings')"
      >
        貸して
      </li>
      <li
        :class="{ 'active': activeClass('livings')}"
        @click="onClick('livings')"
      >
        暮らし
      </li>
      <li
        :class="{ 'active': activeClass('entries')}"
        @click="onClick('entries')"
      >
        子育て
      </li>
    </ul>
  </div>
</template>

<script>

export default {
  components: {},
  props: {
  },
  data () {
    return {
      activeValue: 'all'
    }
  },
  computed: {
  },
  mounted () {
  },
  methods: {
    activeClass (value) {
      return this.activeValue === value
    },
    onClick (value) {
      this.activeValue = value
      this.$emit('filter', value)
    }
  }
}
</script>
<style scoped lang="sass">
.event-tags
  width: 100%
  padding-bottom: 24px
  padding-top: 16px
  padding-left: 16px
  padding-right: 16px
  ul
    display: -webkit-box
    overflow: scroll
    height: 26px
    li
      border: 1px solid #EEEEEE
      border-radius: 90px
      padding: 6px 12px
      background: #FFFFFF
      color: #2DBA87
      font-size: 14px
      font-weight: 400
      text-align: center
      margin-right: 8px
      display: flex
      align-items: center
      &.active
        background: #D5F1E7
        border: 1px solid #2DBA87
      &:last-child
        margin-right: unset
</style>
