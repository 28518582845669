<template>
  <div>
    <div v-if="!isShowSchedule" class="groups-list">
      <b-tabs v-model="activeTab" position="is-centered" class="groups-tabs">
        <b-tab-item label="全て">
          <div class="all">
            <div class="all-header">
              <BButton outlined type='submit'
                       class="is-medium is-fullwidth is-success"
                       icon-left="plus"
                       @click="goToCreateGroup"
              >
                新しいグループを作成する
              </BButton>
            </div>

            <div class="all-container">
              <div class="all-container-card" v-for="group in groups" :key="group.id" @click="goDetail(group.id)">
                <div class="title" :data-id="group.id">
                  {{group.shortName}}
                </div>
                <div class="updated-at">
                  <span>{{ group.latestPostAt }}</span>
                </div>
                <div class="columns is-mobile ">
                  <div class="column is-3 text-center group-image">
                    <img :src="group.imageUrl" />
                  </div>
                  <div class="column is-9 text-center ">
                    <div class="columns is-mobile ">
                      <div class="column is-12 text-left short-description">
                        <span>{{group.shortDescription}}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="columns is-mobile">
                  <div class="column is-3 text-center pt-0">
                    <div v-if="group.privateMode">
                      <MyCommuGroupStatus value='非公開'/>
                    </div>
                    <div v-if="group.allowOnlyPeople" class="mt-4px">
                      <MyCommuGroupStatus value='招待制'/>
                    </div>
                    <div v-if="!group.allowOnlyPeople && !group.privateMode" class="mt-4px">
                      <MyCommuGroupStatus value='誰でも<br>参加可能' :is-special="true"/>
                    </div>
                  </div>
                  <div class="column is-9 text-center group-status pt-0">
                    <div class="columns is-mobile group--status">
                      <div class="column is-6 text-left group-member ">
                        <span>参加メンバー：{{ group.count_user }}人</span>
                      </div>
                      <div class="column is-6 text-center">
                        <b-button class="btn-join" v-if="!group.joined">参加する</b-button>
                        <div class="btn-joined" v-if="group.joined">
                          <BIcon
                            pack="far"
                            icon="check-circle"
                          />
                          <span>参加済み</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-tab-item>

        <b-tab-item label="参加済みグループ">
          <div class="all">
            <div class="all-header">
              <BButton outlined type='submit'
                       class="is-medium is-fullwidth is-success"
                       icon-left="plus"
                       @click="goToCreateGroup"
              >
                新しいグループを作成する
              </BButton>
            </div>

            <div class="all-container">
              <div class="all-container-card" v-for="group in join_groups" :key="group.id" @click="goDetail(group.id)">
                <div class="title" :data-id="group.id">
                  {{group.shortName}}
                </div>
                <div class="updated-at">
                  <span>{{ group.latestPostAt }}</span>
                </div>
                <div class="columns is-mobile ">
                  <div class="column is-3 text-center group-image">
                    <img :src="group.imageUrl" />
                  </div>
                  <div class="column is-9 text-center ">
                    <div class="columns is-mobile ">
                      <div class="column is-12 text-left short-description">
                        <span>{{group.shortDescription}}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="columns is-mobile">
                  <div class="column is-3 text-center pt-0">
                    <div v-if="group.privateMode">
                      <MyCommuGroupStatus value='非公開'/>
                    </div>
                    <div v-if="group.allowOnlyPeople" class="mt-4px">
                      <MyCommuGroupStatus value='招待制'/>
                    </div>
                    <div v-if="!group.allowOnlyPeople && !group.privateMode" class="mt-4px">
                      <MyCommuGroupStatus value='誰でも<br>参加可能' :is-special="true"/>
                    </div>
                  </div>
                  <div class="column is-9 text-center group-status pt-0">
                    <div class="columns is-mobile group--status">
                      <div class="column is-6 text-left group-member ">
                        <span>参加メンバー：{{ group.count_user }}人</span>
                      </div>
                      <div class="column is-6 text-center">
                        <b-button class="btn-join" v-if="!group.joined">参加する</b-button>
                        <div class="btn-joined" v-if="group.joined">
                          <BIcon
                            pack="far"
                            icon="check-circle"
                          />
                          <span>参加済み</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-tab-item>
      </b-tabs>
      <b-modal
        :active.sync="modal"
        has-modal-card
      >
        <div class="modal-card">
          <div class="modal-card-body">
            <p>グループ作成できるのはシェア・コンシェルジュの方のみになります。</p>
            <MyCommuBaseButton
              class="button-supporter"
              text-content="シェア・コンシェルジュになる"
              @clickButton="goToAsmamaEventMeeting()"
            />
            <p>シェア・コンシェルジュ以外の方はこちらからグループ作成のリクエストを送る事ができます。</p>
            <MyCommuBaseButton
              class="button-create"
              text-content="リクエストする"
              @clickButton="goToCreateGroup"
            />
            <p
              class="cancel"
              @click="modal = false"
            >
              閉じる
            </p>
          </div>
        </div>
      </b-modal>
    </div>
    <MyCommuSchedule v-if="isShowSchedule"/>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import BaseSwitchInput from 'components/BaseSwitchInput'

export default {
  components: {
    BaseSwitchInput
  },
  props: {
  },
  computed: {
    ...mapState('session', ['currentUser']),
    ...mapState('groups', ['groups', 'join_groups']),
    ...mapState('mycommu', ['isShowIconHeader', 'isShowChangeCommu', 'currentCommunity', 'hiddenHeader', 'isShowSchedule']),
    checkInput () {
      return (this.name === null || this.name === '')
    }
  },
  data () {
    return {
      activeTab: 0,
      modal: false
    }
  },
  created () {
    this.hiddenHeader.mainContainerUnsetMargin = true
    this.toggleShowIconHeader(true)
    this.toggleShowChangeCommu(true)
  },
  mounted () {
    this.toggerMyCommuButton(false)

    this.fetchGroups({ currentCommunity: 0 })
  },
  methods: {
    ...mapActions('groups', [
      'fetchGroups'
    ]),
    ...mapActions('mycommu', [
      'toggerMyCommuButton',
      'toggerHeader',
      'setTitleHeader',
      'setHiddenHeader',
      'toggleShowIconHeader',
      'toggleShowChangeCommu'
    ]),
    goToCreateGroup () {
      if (this.currentUser.adminOrStaffOrSupporter || this.modal) {
        window.location.href = `/communities/${this.currentCommunity.community_id}/community_groups/new`
      } else {
        this.modal = true
      }
    },
    goDetail (groupId) {
      window.location.href = `/communities/${this.currentCommunity.community_id}/community_groups/${groupId}`
    },
    goToAsmamaEventMeeting () {
      window.open('https://asmama.jp/event/meeting/', '_blank')
    }
  }
}
</script>
<style scoped lang="sass">
  $label-color: #f8f7f2 !default
  $label-weight: 400 !default
  /deep/
    .tabs
      background: white
    .tab-content
      padding: 16px !important
  .groups-list
    min-height: 100vh
    background: #E5E5E5
    font-size: 15px
    color: #273D35
    font-weight: 400
    .text-center
      justify-content: center
      text-align: center
    .all-text
      font-weight: 400
      font-size: 12px

    .all-header
      margin-top: 16px
      margin-bottom: 32px
      padding-bottom: 32px
      border-bottom: 1px solid #CCCCCC

      .button
        margin-bottom: unset
        font-weight: 700
        font-size: 15px
        padding: 23px
        &.is-success
          border: none
          background: white
          box-shadow: 0px 2px 8px #F2EFDE
          border-radius: 6px
          /deep/ span
            font-weight: bold

    .all-container-card
      background: #FFFFFF
      border-radius: 12px
      padding: 24px 20px
      margin-bottom: 24px

      .title
        color: #273D35
        font-weight: bold
        font-size: 16px
        margin-bottom: 8px
      .updated-at
        font-style: normal
        font-weight: 400
        font-size: 12px
        line-height: 12px
        color: #AAAAAA
        margin-bottom: 16px
      .short-description
        font-style: normal
        font-weight: 300
        font-size: 14px
        color: #273D35
      .group-image
        padding-bottom: 8px !important
        img
          border-radius: 4px
          width: 64px
          height: 64px
          object-fit: cover
      .btn-attention
        padding-top: 0
        padding-bottom: 4px
      .group-member
        font-style: normal
        font-weight: 400
        font-size: 12px
      .group--status
        align-items: center
        .is-6
          width: auto !important
      .group-status
        align-self: center
      .btn-join
        background: #FFFFFF
        box-shadow: 0px 2px 8px #F2EFDE
        border-radius: 6px
        font-style: normal
        font-size: 14px
        line-height: 14px
        text-align: center
        color: #2DBA87
        border: none
        /deep/ span
          font-weight: bold
      /deep/
        .btn-joined
          display: flex
          font-style: normal
          font-weight: 300
          font-size: 15px
          color: #2DBA87
          img
            width: 24px
            height: 24px
          span
            margin-left: 8px
            i.far.fa-check-circle
              font-size: 20px
      .btn-attention
        padding-bottom: unset
      .button-attention
        border-radius: unset
        max-width: 52px
        height: 18px
        /deep/
          span
            line-height: 6px
            align-self: self-end

  .pt-0
    padding-top: 0
  .text-left
    text-align: left !important
  .mt-4px
    margin-top: 4px !important
  .cancel
    font-weight: bold
    color: #2DBA87
  /deep/
    .modal-background
      background: #273D35
      opacity: 0.9
  .animation-content
    display: contents
    .modal-card
      font-size: 14px
      line-height: 24px
      display: flex
      justify-content: center
      padding: 0 52px
      .modal-card-body
        text-align: center
        border-radius: 20px
        padding: 32px 24px
  /deep/
    .modal-close
      display: none
    .base-button
      margin-top: 14px
      margin-bottom: 22px
      button
        font-size: 14px !important
        padding: 16px 6px 16px 6px !important
</style>
