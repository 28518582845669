<template>
  <div></div>
</template>
<script>
import { mapActions } from 'vuex'
export default {
  props: {
    isMyCommuDomain: {
      type: Boolean,
      default: false
    }
  },
  created () {
    if (this.isMyCommuDomain) {
      this.toggerMyCommuButton(false)
    }
  },
  methods: {
    ...mapActions('mycommu', [
      'toggerMyCommuButton'
    ])
  }
}
</script>
