<template>
  <div>
    <Back :title='title' :showCommu="false" :useEmit="true" @back="back" :nonBackground="true"/>
    <div class="container-method">
      <div v-if="false">
        <div class="title-header" v-if="isSignIn">
          前回のログインは
          <br>
          登録されたメールアドレスからでした。
        </div>
        <div class="title-header-2" :class="{'mt-40': isSignIn}">{{ subTitle }}</div>
        <MyCommuBaseButton
            :icon="lineIcon"
            class="button-method"
            :text-content="lineTitle"
            :is-white-button="true"
            @clickButton="goToLink('line')"
        />
        <MyCommuBaseButton
            :icon="facebookIcon"
            class="button-method"
            :text-content="faceBookTitle"
            :is-white-button="true"
            @clickButton="goToLink('facebook')"
        />
        <MyCommuBaseButton
            :icon="appleIcon"
            class="button-method"
            :text-content="appleTitle"
            :is-white-button="true"
            @clickButton="goToLink('apple')"
        />
        <MyCommuBaseButton
            :icon="yahooIcon"
            class="button-method"
            :text-content="yahooTitle"
            :is-white-button="true"
            @clickButton="goToLink('yahoo')"
        />
        <div class="button-method">もしくは</div>
      </div>
      <div class="text-center">
        <p class="kosodate-user-guide">「子育てシェア」に登録済の方は、同じ</p>
        <p class="kosodate-user-guide">メールアドレス・パスワードでログインください。</p>
      </div>
      <MyCommuBaseButton
        :icon="emailIcon"
        class="button-method is-mt-5"
        :text-content="emailTitle"
        :is-white-button="true"
        @clickButton="goToLink('email')"
      />
      <div v-html="line" class="hidden"></div>
      <div v-html="facebook" class="hidden"></div>
      <div v-html="yahoo" class="hidden"></div>
    </div>
  </div>
</template>
<script>
import Back from './Back'
export default {
  components: { Back },
  props: {
    isSignIn: {
      type: Boolean,
      default: true
    },
    url: {
      type: String,
      default: '#'
    },
    facebook: {
      type: String,
      default: ''
    },
    line: {
      type: String,
      default: ''
    },
    yahoo: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      title: '',
      subTitle: '',
      lineTitle: '',
      faceBookTitle: '',
      appleTitle: '',
      yahooTitle: '',
      emailTitle: '',
      lineIcon: "<svg width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n" +
          "<path d=\"M18.5895 0H5.4105C2.42236 0 0 2.42236 0 5.4105V18.5895C0 21.5776 2.42236 24 5.4105 24H18.5895C21.5776 24 24 21.5776 24 18.5895V5.4105C24 2.42236 21.5776 0 18.5895 0Z\" fill=\"#06C755\"/>\n" +
          "<path d=\"M19.9996 10.869C19.9996 7.28922 16.4101 4.37622 11.9978 4.37622C7.58559 4.37622 3.99609 7.28922 3.99609 10.869C3.99609 14.079 6.84609 16.7677 10.6876 17.2755C10.9486 17.3317 11.3033 17.4472 11.3926 17.67C11.4736 17.8725 11.4458 18.1897 11.4188 18.3937C11.4188 18.3937 11.3251 18.9585 11.3048 19.0785C11.2696 19.281 11.1436 19.8697 11.9978 19.5105C12.8521 19.1512 16.6058 16.797 18.2843 14.8642C19.4438 13.593 19.9996 12.3 19.9996 10.869Z\" fill=\"white\"/>\n" +
          "<path d=\"M17.3375 12.9368H15.0875C15.0477 12.9368 15.0096 12.921 14.9814 12.8929C14.9533 12.8648 14.9375 12.8266 14.9375 12.7868V9.29559V9.29185C14.9375 9.25206 14.9533 9.21391 14.9814 9.18578C15.0096 9.15765 15.0477 9.14185 15.0875 9.14185H17.3375C17.3773 9.14185 17.4154 9.15765 17.4436 9.18578C17.4717 9.21391 17.4875 9.25206 17.4875 9.29185V9.8596C17.4875 9.89938 17.4717 9.93753 17.4436 9.96566C17.4154 9.99379 17.3773 10.0096 17.3375 10.0096H15.8098V10.5983H17.3375C17.3773 10.5983 17.4154 10.6142 17.4436 10.6423C17.4717 10.6704 17.4875 10.7086 17.4875 10.7483V11.3251C17.4875 11.3649 17.4717 11.403 17.4436 11.4312C17.4154 11.4593 17.3773 11.4751 17.3375 11.4751H15.8098V12.0646H17.3375C17.3773 12.0646 17.4154 12.0804 17.4436 12.1085C17.4717 12.1367 17.4875 12.1748 17.4875 12.2146V12.7816C17.4882 12.8017 17.4849 12.8218 17.4776 12.8406C17.4704 12.8594 17.4595 12.8766 17.4455 12.8911C17.4315 12.9056 17.4147 12.9171 17.3961 12.9249C17.3776 12.9328 17.3577 12.9369 17.3375 12.9368Z\" fill=\"#06C755\"/>\n" +
          "<path d=\"M9.0217 12.9368C9.06149 12.9368 9.09964 12.921 9.12777 12.8928C9.1559 12.8647 9.1717 12.8266 9.1717 12.7868V12.2198C9.1717 12.18 9.1559 12.1418 9.12777 12.1137C9.09964 12.0856 9.06149 12.0698 9.0217 12.0698H7.49395V9.29478C7.49395 9.25499 7.47815 9.21684 7.45002 9.18871C7.42189 9.16058 7.38374 9.14478 7.34395 9.14478H6.77695C6.73717 9.14478 6.69902 9.16058 6.67089 9.18871C6.64276 9.21684 6.62695 9.25499 6.62695 9.29478V12.7845C6.62695 12.8243 6.64276 12.8625 6.67089 12.8906C6.69902 12.9187 6.73717 12.9345 6.77695 12.9345H9.02695L9.0217 12.9368Z\" fill=\"#06C755\"/>\n" +
          "<path d=\"M10.3743 9.13867H9.8073C9.7228 9.13867 9.6543 9.20717 9.6543 9.29167V12.7837C9.6543 12.8682 9.7228 12.9367 9.8073 12.9367H10.3743C10.4588 12.9367 10.5273 12.8682 10.5273 12.7837V9.29167C10.5273 9.20717 10.4588 9.13867 10.3743 9.13867Z\" fill=\"#06C755\"/>\n" +
          "<path d=\"M14.2373 9.13867H13.6703C13.6305 9.13867 13.5923 9.15448 13.5642 9.18261C13.5361 9.21074 13.5203 9.24889 13.5203 9.28867V11.3632L11.9228 9.20542C11.9191 9.19971 11.9149 9.19443 11.91 9.18968L11.901 9.18067L11.8928 9.17392H11.8883L11.88 9.16792H11.8755L11.8673 9.16342H11.862H11.8538H11.8485H11.8395H11.8335H11.8245H11.8185H11.8103H11.232C11.1922 9.16342 11.1541 9.17923 11.126 9.20736C11.0978 9.23549 11.082 9.27364 11.082 9.31342V12.8054C11.082 12.8452 11.0978 12.8834 11.126 12.9115C11.1541 12.9396 11.1922 12.9554 11.232 12.9554H11.7998C11.8396 12.9554 11.8777 12.9396 11.9058 12.9115C11.934 12.8834 11.9498 12.8452 11.9498 12.8054V10.7107L13.5495 12.8707C13.56 12.886 13.5732 12.8992 13.5885 12.9097L13.5975 12.9157H13.602H13.6095H13.617H13.6223H13.6328C13.646 12.9189 13.6596 12.9207 13.6733 12.9209H14.2373C14.2771 12.9209 14.3152 12.9051 14.3433 12.877C14.3715 12.8489 14.3873 12.8107 14.3873 12.7709V9.29167C14.3877 9.27173 14.3841 9.2519 14.3767 9.23335C14.3694 9.2148 14.3584 9.19791 14.3444 9.18366C14.3305 9.16941 14.3138 9.1581 14.2954 9.15037C14.277 9.14265 14.2572 9.13867 14.2373 9.13867Z\" fill=\"#06C755\"/>\n" +
          "</svg>",
      facebookIcon: "<svg width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n" +
          "<path d=\"M24 12.0733C24 5.40541 18.6274 -1.90735e-06 12 -1.90735e-06C5.37258 -1.90735e-06 0 5.40541 0 12.0733C0 18.0995 4.38823 23.0943 10.125 24V15.5633H7.07813V12.0733H10.125V9.41343C10.125 6.38755 11.9165 4.71615 14.6576 4.71615C15.9705 4.71615 17.3438 4.95195 17.3438 4.95195V7.92313H15.8306C14.3399 7.92313 13.875 8.85379 13.875 9.80857V12.0733H17.2031L16.6711 15.5633H13.875V24C19.6118 23.0943 24 18.0995 24 12.0733Z\" fill=\"#1877F2\"/>\n" +
          "</svg>\n",
      appleIcon: "<svg width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n" +
          "  <path d=\"M21.2808 18.424C20.933 19.2275 20.5213 19.9672 20.0442 20.6472C19.394 21.5743 18.8616 22.216 18.4513 22.5724C17.8153 23.1573 17.1338 23.4568 16.4041 23.4739C15.8802 23.4739 15.2485 23.3248 14.513 23.0224C13.7752 22.7214 13.0972 22.5724 12.4772 22.5724C11.827 22.5724 11.1296 22.7214 10.3837 23.0224C9.63662 23.3248 9.03481 23.4824 8.57468 23.498C7.87491 23.5278 7.1774 23.2197 6.48118 22.5724C6.03681 22.1848 5.48099 21.5204 4.81515 20.5791C4.10075 19.5739 3.51342 18.4084 3.05329 17.0795C2.56051 15.6442 2.31348 14.2543 2.31348 12.9087C2.31348 11.3673 2.64654 10.0379 3.31366 8.92385C3.83796 8.029 4.53546 7.32312 5.40844 6.80493C6.28142 6.28674 7.22468 6.02267 8.24048 6.00578C8.7963 6.00578 9.52518 6.1777 10.431 6.51559C11.3342 6.85462 11.9141 7.02655 12.1684 7.02655C12.3585 7.02655 13.0028 6.82552 14.0949 6.42473C15.1278 6.05305 15.9995 5.89916 16.7136 5.95978C18.6487 6.11595 20.1024 6.87876 21.0693 8.25303C19.3386 9.30163 18.4826 10.7703 18.4996 12.6544C18.5152 14.122 19.0476 15.3432 20.0939 16.3129C20.5681 16.7629 21.0977 17.1107 21.6868 17.3578C21.5591 17.7283 21.4242 18.0832 21.2808 18.424V18.424ZM16.8428 0.960131C16.8428 2.11039 16.4226 3.18439 15.5849 4.17847C14.5741 5.36023 13.3514 6.04311 12.0256 5.93536C12.0087 5.79736 11.9989 5.65213 11.9989 5.49951C11.9989 4.39526 12.4796 3.21349 13.3333 2.24724C13.7595 1.75801 14.3015 1.35122 14.9588 1.02671C15.6147 0.707053 16.2352 0.530273 16.8187 0.5C16.8357 0.653772 16.8428 0.807554 16.8428 0.960116V0.960131Z\" fill=\"black\"/>\n" +
          "</svg>",
      yahooIcon: "<svg width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\">\n" +
          "  <circle cx=\"12\" cy=\"12\" r=\"12\" fill=\"#FF0033\"/>\n" +
          "  <rect x=\"4\" y=\"8\" width=\"16\" height=\"9\" fill=\"url(#pattern0)\"/>\n" +
          "  <defs>\n" +
          "    <pattern id=\"pattern0\" patternContentUnits=\"objectBoundingBox\" width=\"1\" height=\"1\">\n" +
          "      <use xlink:href=\"#image0_9169_9433\" transform=\"scale(0.00390625 0.00694444)\"/>\n" +
          "    </pattern>\n" +
          "    <image id=\"image0_9169_9433\" width=\"256\" height=\"144\" xlink:href=\"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAQAAAACQCAMAAADQvUWjAAAAM1BMVEUAAAD///////////////////////////////////////////////////////////////+3leKCAAAAEHRSTlMAQIAQ77+fMGDP3yCPcK9QYG35PAAABMVJREFUeF7tnemO4yAQhJv7Pt7/aVerlaLRRhmMUxAc8/2fgxJ0dzVg6N5sNpvNZrPZbDYbVsch6AKUOgxJV4DXYai7CxDpCqg6jHR3ARhdgbsnAbp7EqDsKhSvlIqcFxboMhjGo3pDB6Uy55wxZujSBGZ5VrL2IC2jr4MVrg/rYGklQgqEQjDuaxsZaCFSrT4anAbuavVurn+RuhAIPabeMwzKw3SK+iBbMU0BA9W1H/8QwNafuGTmKOBOLFUk7LWwPjKAAnjXxwZ5TlmfkboIgAJY1yOgC6Dd28k2jPWLsldjP8Ryxvoax83IbJg/5sHjcVl9LAAFMLmQD1gAZGoTqe05BSQ4F5YKgvWq6ukUpqmAE9RBUAoRBhSnHzh8l6pnemnqRTxcVz9S8RL+E7UeINNZ7Dhf2KvDY+z91ZUUNE4Bad50nyk2ZXDaGnpBHt2n1sgw0F8hycxZ40fBRXu/xHrcLo0OiMRisO0BfHsovFgCysA9C6AcAIWBdiKTiQ4gT0RAfDngBD7KqAAymHbGXromdJ7hdIg4qXeXmjJjx+8NzF+GOV1CaZDj1wJWqcZZu8lewMYvLW5mSjGtV55R43eGDgPI0LhUkDDj14IOE2AREJJxDKAAlBZqVBghSeD1pjunf78R0oRFIyecyK3p3wYUAXExJ74XVBO4PkuERrTqjkIHMR7gKHSrD4jHSMyuOZMIPyE/cFrNQmyRhcQrAy5MMO5Dn5q60sL/FRloDOpdWyRUa/lDjBCnQQj3Xj1kHKidEDDeBB8IvegMf3qERS8fvGqQ++ybHVGUqI8etec94Y/ROQSuDYIPhOxw+HBmSEKO1AO+IpThYPBQgs6SgSYAHwgdPWMl1K19+Byr7bZFEft/FmwExFeEtl39lFHWnNEMXE+b2Dj0dpIHt0HwG2ZO4MzfMwYeAfE9Qv1bwFBi2ApMy1w9tK+Xqx63AD3NIx8JA0IPqFPCGpf2hG+HAeFGpOk0ug0C2pqLg8ZPCtAGmbJVYF0j/aONEKfJ5NqJNCPrUC9oMsIDxo+TvdB0zAfGT3Klm1xp/vgLoA0CRM0eP8W1vtwg5OTxk4eaALwpGB2hzHLXeePc68YcEQHn58I4uhPB1v4qkyMUAdkGmXfZ2BCKNC8CAo9r6dEmPC3+TRZOKAS+DTJfALwRYvcRQCPaIHi6igC8EQrLC8CGZlxO9xEgAtoglxbAA0zGlQUwaBMwXwB8GWjuJIADuKwrCxAAJuDSAlhAhXFpATLAZl9ZAAFQ9toCRA+w2dcuhExykAg4XwAcwWaACZguABRRtAS0QaYKAIdFz64jgKUFWb8jtAXYAmwBtgBbgC3AFsBKzW4tgK+1+hhoYcRIAUr9h0vramBHCqA6vhS87BkZDmuEel0ErYauLTj0l7tYaCGCqgMFEHLx9w2CrgfQjAU6BV/aXxvuag9OKcU5T+y4Hn7NIzEUWIqqtmjrkblt5JeVvIXmf8lK+YojN3PgOvthdQT8xLlg/U3PdPITCTZ8kwCl/wCm+qpXKln/X2RfJUDovhzkv+uVyv4caL9KAEm/YFKWC73o+PKlxX9k3k9UkRqYpFZprz298jUNxh0gB16bkFyjCLqLBobuTEiaTrLZbDabzR/68z5DiuM/GgAAAABJRU5ErkJggg==\"/>\n" +
          "  </defs>\n" +
          "</svg>",
      emailIcon: "<svg width=\"24\" height=\"19\" viewBox=\"0 0 24 19\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n" +
          "  <path d=\"M22.1518 13.9432C21.946 14.8041 21.493 15.5861 20.8486 16.193C20.2043 16.7999 19.3965 17.2052 18.5248 17.3592C14.1844 17.8414 9.80398 17.8414 5.46358 17.3592C4.5932 17.2047 3.78703 16.7989 3.14436 16.192C2.50169 15.585 2.05061 14.8033 1.84665 13.9432C1.38445 10.9097 1.38445 7.82372 1.84665 4.79026C2.05071 3.92813 2.50137 3.14409 3.14369 2.53389C3.786 1.92369 4.59213 1.51375 5.46358 1.35413C9.80453 0.881956 14.1839 0.881956 18.5248 1.35413C19.395 1.51169 20.2008 1.91828 20.8446 2.5246C21.4884 3.13092 21.9425 3.91094 22.1518 4.77014C22.6161 7.81019 22.6161 10.9031 22.1518 13.9432V13.9432Z\" fill=\"white\" stroke=\"#7C7C7C\" stroke-width=\"2\" stroke-miterlimit=\"10\"/>\n" +
          "  <path d=\"M3.63525 2.70044L11.9944 9.21097L20.1326 2.88129\" fill=\"white\"/>\n" +
          "  <path d=\"M3.63525 2.70044L11.9944 9.21097L20.1326 2.88129\" fill=\"white\" stroke=\"#7C7C7C\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n" +
          "</svg>"
    }
  },
  methods: {
    goToLink (method) {
      if (method === 'email') {
        window.location.href = this.url
      } else {
        document.getElementById(method).click()
      }
    },
    back () {
      this.$emit('back')
    }
  },
  created () {
    if (this.isSignIn) {
      this.title = 'ログイン'
      this.subTitle = 'カンタンログイン'
      this.lineTitle = 'LINEでログイン'
      this.faceBookTitle = 'Facebookでログイン'
      this.appleTitle = 'Appleでログイン'
      this.yahooTitle = 'Yahooでログイン'
      this.emailTitle = 'メールアドレスでログイン'
    } else {
      this.title = '新規アカウント登録'
      this.subTitle = '外部サイトでカンタン登録'
      this.lineTitle = 'LINEで新規登録'
      this.faceBookTitle = 'Facebookで新規登録'
      this.appleTitle = 'Appleで新規登録'
      this.yahooTitle = 'Yahooで新規登録'
      this.emailTitle = 'メールアドレスで新規登録'
    }
  }
}
</script>
<style scoped lang="sass">
  .text-center
    text-align: center
  .kosodate-user-guide
    font-size: 12px
  .container-method
    padding: 40px 32px
    font-size: 15px
    color: #273D35
    text-align: center
    .title-header
      font-size: 12px
    .title-header-2
      margin-top: 12px
    .button-method
      margin-top: 16px
      &:first-child
        margin-top: 24px
  .mt-40
    margin-top: 40px !important
  .hidden
    display: none
</style>
