<template>
  <div class='topic-new'>
    <Back :title="headerTitle" />
    <div class="topic-new-container">
      <div class="topic-new-note is-vcentered">
        <div class="columns is-mobile ">
          <div class="column is-12 text-center ">
            <span class="title-new-topic">{{title}}</span>
          </div>
        </div>
      </div>
      <div class="topic-new-form">
        <div class="form-element topic-new-form-name">
          <BField :label="nameLabel" class="topic-new-form-name--label">
            <BInput v-model="titleNewTopic" :placeholder="namePlaceholder"></BInput>
          </BField>
        </div>
        <div class="form-element topic-new-form-description">
          <BField :label="bodyLabel">
            <b-input v-model="bodyNewTopic" type="textarea" :placeholder="bodyPlaceholder"/>
          </BField>
        </div>
        <div class="form-element-border topic-new-form-description">
          <BButton
            type='submit'
            class="is-fullwidth is-success is-success-my-commu"
            @click="createTopic"
            :disabled="checkInput"
          >
            {{ buttonSubmit }}
          </BButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Back from '../../components/my-commu/Back'
import { mapActions, mapState } from 'vuex'
import axios from '../../utils/axios'

export default {
  components: {
    Back
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    headerTitle: {
      type: String,
      default: ''
    },
    nameLabel: {
      type: String,
      default: ''
    },
    bodyLabel: {
      type: String,
      default: ''
    },
    namePlaceholder: {
      type: String,
      default: ''
    },
    bodyPlaceholder: {
      type: String,
      default: ''
    },
    buttonSubmit: {
      type: String,
      default: ''
    },
    communityGroup: {
      type: Object,
      require: true
    }
  },
  data () {
    return {
      titleNewTopic: '',
      bodyNewTopic: ''
    }
  },
  computed: {
    ...mapState('mycommu', [
      'currentCommunity'
    ]),
    checkInput () {
      return this.titleNewTopic === '' || this.bodyNewTopic === ''
    }
  },
  mounted () {
    this.toggerMyCommuButton(false)
    this.toggerHeader(true)
  },
  methods: {
    ...mapActions('mycommu', [
      'toggerMyCommuButton',
      'toggerHeader'
    ]),
    async createTopic () {
      const form = new FormData()
      form.append('community_group_topic[title]', this.titleNewTopic)
      form.append('community_group_topic[body]', this.bodyNewTopic)
      form.append('community_group_topic[organization_group_id]', this.communityGroup.id)
      await axios({
        method: 'POST',
        url: `/communities/${this.currentCommunity.community_id}/community_groups/${this.communityGroup.id}/community_group_topics`,
        data: form
      }).then((res) => {
        if (res.data.status) {
          window.location.href = `/communities/${this.currentCommunity.community_id}/community_groups`
        } else {
          window.location.reload()
        }
      })
    }
  }
}
</script>
<style scoped lang="sass">
$label-color: #f8f7f2 !default
$label-weight: 400 !default
.topic-new
  height: 100vh
  background: #f8f7f2
  font-size: 15px
  color: #273D35
  font-weight: 400
  .label
    color: #273D35
    font-weight: 400
  .topic-new-container
    margin: 40px 23px 32px 23px
    font-size: 14px
    .title-new-topic
      font-size: 15px
    .topic-new-form
      margin-top: 40px
      .form-element
        margin-top: 24px
        /deep/
          .label
            font-size: 14px
      .topic-new-form-name
        /deep/
          input
            height: 48px
            caret-color: #2DBA87
            box-shadow: unset
            border-color: #cccccc
            border-radius: 6px
          input:focus
            border-color: #2DBA87
            box-shadow: unset
      .topic-new-form-description
        /deep/
          textarea
            height: 130px
            resize: none
            caret-color: #2DBA87
            box-shadow: unset
            border-color: #cccccc
            border-radius: 6px
          textarea:focus
            border-color: #2DBA87
            box-shadow: unset
      .form-element-border
        padding: 40px 0px
</style>
