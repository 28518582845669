<template>
    <BField
      class="base-switch"
      :label="label"
    >
      <input
        :name="name"
        type="hidden"
        value="0"
      >
      <BSwitch
        :id="id"
        type="is-success"
        v-model="isChecked"
        :name="name"
        class="is-switch"
        native-value="1"
        @input="onChange"
      >
      </BSwitch>
    </BField>
</template>

<script>
import * as inputs from './inputs'

export default {
  props: {
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: null
    },
    value: {
      type: Boolean,
      default: false
    },
    onChange: {
      type: Function,
      default: () => {}
    },
  },
  data () {
    return { isChecked: this.value }
  },
  computed:  {
    id () {
      return inputs.idForName(this.name)
    }
  }
}
</script>

<style scoped lang="sass">
  .base-switch
    display: flex
    align-items: center
    justify-content: end !important
    /deep/
      .switch
        cursor: unset
        .control-label
          padding-left: unset
      label
        font-weight: 300
        font-size: 14px
        line-height: 14px
        color: #273D35 !important
        display: flex
        flex-direction: row-reverse
      span.check
        height: 31px !important
        width: 51px !important
        padding: 2px !important
        background: rgba(120, 120, 128, 0.16) !important
        border-radius: 16px !important
        &:before
          width: 27px !important
          height: 27px !important
          background: #FFFFFF !important
          border: 0.5px solid rgba(0, 0, 0, 0.04) !important
          box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15), 0 3px 1px rgba(0, 0, 0, 0.06) !important
      .switch input[type=checkbox]:checked + .check:before
        transform: translate3d(80%, 0, 0)
      input[type=checkbox]:checked + .check
        background: rgba(45, 186, 135, 0.9) !important
    /deep/ > label.label
      color: #7a7a7a
      font-weight: 400
      margin-bottom: unset
    .is-switch
      /deep/
        span.check
          margin-left: 0.7em
          align-items: center
          /deep/
            .b-checkbox.checkbox + .checkbox
              background: #2DBA87
      /deep/ > input[type=checkbox]:focus + .check
        box-shadow: unset
</style>
