<template>
  <div>
    <Back
      title="子どもの情報編集"
      :show-commu="false"
      :show-cancel="true"
    />
    <div class="space">
      <form>
        <HiddenHeader />
        <MyCommuHiddenButtonFooter :is-my-commu-domain="true" />
        <MyCommuHiddenFooterMenu :is-my-commu-domain="true" />
        <div class="tutorial">
          送迎・託児OKの友達にだけ表示されます
        </div>
        <div class="has-text-left mb-24px is-flex">
          <img
            id="avatar"
            :src="avatar"
            alt="child-avatar"
            class="child-avatar"
          >
          <input
            id="child_profile_image"
            ref="imageAvatar"
            type="file"
            accept="image/*"
            class="d-none"
            name="child_profile[image]"
            @change="selectImage"
          >
          <div class="edit-avatar">
            <MyCommuBaseButton
              text-content="写真を選択する"
              :button-min="true"
              :is-white-button="true"
              @clickButton="chooseImage()"
            />
          </div>
        </div>
        <div class="columns is-mobile mb-24px">
          <div class="column">
            <MyCommuBaseTextInput
              id="child_profile_first_name"
              v-model="name"
              label="お名前"
              name="child_profile[first_name]"
              placeholder="名"
              :value="name"
            />
          </div>
        </div>
        <div class="columns is-mobile mb-24px">
          <div class="column">
            <MyCommuBaseTextInput
              id="child_profile_first_name_kana"
              v-model="nameKana"
              label="フリガナ"
              name="child_profile[first_name_kana]"
              placeholder="メイ"
            />
            <p
              v-if="!checkNameKana"
              class="name-error"
            >
              {{this.kanaError}}
            </p>
          </div>
        </div>
        <div class="columns is-mobile mb-24px">
          <div class="column is-fullwidth select-prefecture">
            <MyCommuBaseSelectInput
              id="child_profile_sex"
              v-model="sex"
              label="性別"
              name="child_profile[sex]"
              placeholder="選択する"
              :options="sexOptions"
            />
          </div>
          <div class="column" />
        </div>
        <div class="columns is-mobile mb-60px">
          <div class="column is-fullwidth select-prefecture">
            <MyCommuBirthdayDatePicker
              id="child_profile_date_of_birth"
              v-model="dateOfBirth"
              label="生年月日"
              year-placeholder="選択する"
              name="child_profile[date_of_birth]"
              :hide-unit="true"
            />
          </div>
        </div>

        <div class="mb-24px">
          <MyCommuBaseButton
            :disable="!canSubmit"
            text-content="保存する"
            @clickButton="update()"
          />
        </div>
        <div class="mb-24px button-w-170px">
          <MyCommuBaseButton
            v-if="isEdit"
            :is-white-button="true"
            :button-min="true"
            text-content="子どもの情報を削除する"
            custom-class="reject-button"
            @clickButton="destroy()"
          />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Back from '../../components/my-commu/Back'
import axios from 'axios'
import { mapState } from 'vuex'

export default {
  components: {
    Back
  },
  props: {
    child: {
      type: Object,
      default: () => {},
      require: true
    },
    prefecture: {
      type: String,
      default: ''
    },
    address1: {
      type: String,
      default: ''
    },
    avatar: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'edit'
    },
    redirect: {
      type: String,
      default: '/communities/profile'
    },
    sexOptions: {
      type: Array,
      default: () => [
        ['男の子', 'male'],
        ['女の子', 'female']
      ]
    }
  },
  data () {
    return {
      name: this.child.first_name,
      nameKana: this.child.first_name_kana,
      sex: this.child.sex,
      dateOfBirth: this.child.date_of_birth,
      canSubmit: (this.name !== '' && this.nameKana !== '' && this.checkNameKana && this.sex !== '' && this.sex !== null && this.dateOfBirth !== ''),
      kanaError: 'カタカナで入力してください'
    }
  },
  computed: {
    ...mapState('space', ['unsetMargin', 'unsetPadding']),
    ...mapState('mycommu', ['hiddenHeader']),
    propertiesValidation () {
      return `${this.name}|${this.nameKana}|${this.sex}|${this.dateOfBirth}`
    },
    isEdit () {
      return this.type === 'edit'
    },
    checkNameKana () {
      if (this.nameKana.length > 0) {
        if (!this.nameKana.match(/^([ァ-ン]|ー)+$/)) {
          if (this.nameKana.match(/[\u3000|\s/]/)) {
            this.kanaError = 'スペースを削除してください'
          } else {
            this.kanaError = 'カタカナで入力してください'
          }
          return false
        } else {
          return true
        }
      } else {
        return true
      }
    },
    canSubmit () {
      return (this.name !== '' && this.nameKana !== '' && this.checkNameKana && this.sex !== '' && this.sex !== null && this.dateOfBirth !== '')
    }
  },
  watch: {
    propertiesValidation () {
      this.canSubmit = (this.name !== '' && this.nameKana !== '' && this.checkNameKana && this.sex !== '' && this.sex !== null && this.dateOfBirth !== '')
    }
  },
  mounted () {
    this.unsetMargin.mainContainer = true
    this.hiddenHeader.mainContainerUnsetMargin = true
    this.unsetMargin.mainContainer = true
    this.unsetMargin.appMain = true
  },
  methods: {
    update () {
      this.canSubmit = false
      const params = new FormData(this.$el.querySelector('form'))
      if (this.type === 'new') {
        axios.post('/communities/child_profiles', params).then((res) => {
          if (res.data.status === 'success') {
            window.location.href = `${this.redirect}`
          } else {
            this.canSubmit = true
          }
        })
      } else {
        axios.patch(`/communities/child_profiles/${this.child.id}`, params).then((res) => {
          if (res.data.status === 'success') {
            window.location.href = `${this.redirect}`
          } else {
            this.canSubmit = true
          }
        })
      }
    },
    destroy () {
      axios.delete(`/communities/child_profiles/${this.child.id}`).then((res) => {
        if (res.data.status === 'success') {
          // commuSuccess(res.data.message)
          window.location.href = `${this.redirect}`
        } else {
          // commuError(res.data.message)
        }
      })
    },
    chooseImage () {
      this.$refs.imageAvatar.click()
    },
    selectImage () {
      const file = this.$refs.imageAvatar.files
      document.getElementById('avatar').src = URL.createObjectURL(file[0])
    }
  }
}
</script>

<style scoped lang="sass">
.space
  padding: 0 32px
  .tutorial
    color: #F68F54
    text-align: center
    margin: 40px 0 24px 0
    font-size: 12px
    font-weight: bold
  .child-avatar
    width: 64px
    height: 64px
    object-fit: cover
    margin-right: 24px
    border-radius: 50%
    flex-shrink: 0
  .edit-avatar
    display: flex
    align-items: center
  .mb-24px
    margin-bottom: 24px
  .mb-60px
    margin-bottom: 60px
.dark-gray-color
  /deep/
  span
    select
      color: #AAAAAA
.column
  padding-bottom: unset
  /deep/ label
    font-weight: 300
    font-size: 14px
    line-height: 14px
    margin-bottom: 8px
.sign-up-address-input-address__form
  border-radius: 6px
  /deep/ label
    font-weight: 300
    font-size: 14px
    line-height: 14px
    margin-bottom: 8px
  /deep/
  .field
    margin-bottom: 40px
.select-prefecture
  /deep/ span
    width: 100%
  /deep/ select
    width: 100%
    border-radius: 6px
    caret-color: #2DBA87
    &:focus
      border: 1px solid #2DBA87
      border-radius: 6px
      box-shadow: 0 0 0 0
.button-w-170px
  /deep/
    button
      width: 170px
/deep/
  .reject-button
    color: #EE5353 !important
    padding: 9px 0 !important
    &:hover
      background: #FFEEF0 !important
  .birthday-picker
    .select
      margin-right: 15px
      &:after
        margin-top: -0.2875em !important
        height: 0.625em !important
        width: 0.625em !important
        right: 1.125em !important
        border-width: 3px !important
      &:last-child
        margin-right: 0
.d-none
  display: none
.name-error
  color: red
  font-size: 12px
</style>
