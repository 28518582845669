<template>
  <div class="the-tutorials-wrapper" v-if="step < 3">
    <div class="next-step" @click="nextStep"></div>
    <div class="the-tutorials">
      <div class="main-tutorials" v-if="step == 1">
        <MyCommuHiddenFooterMenu :isMyCommuDomain="true"></MyCommuHiddenFooterMenu>
        <div class="w-100"></div>
        <div class="w-100"></div>
        <div class="w-100"></div>
        <div class="tutorial-wrapper fade-in">
          <div class="tutorial-1">
            <div class="header">
              <span>投稿・シェアリングができます</span>
              <button>次へ</button>
            </div>
            <div class="content">地域の情報や気になることを「投稿」したり、モノ・暮らし・子育ての「シェアリング（頼りあい）」をメンバー同士でできます。</div>
          </div>
          <div class="polygon-down"><img src="../images/icons/polygon-1.svg" alt="Polygon"></div>
        </div>
      </div>
      <div class="main-tutorials" v-if="step == 2">
        <div class="tutorial-wrapper">
          <div class="tutorial-2">
            <div class="header">
              <span>挨拶を投稿しましょう</span>
              <button>始める</button>
            </div>
            <div class="content">まずはコミュニティメンバーに挨拶を「投稿」してみましょう。</div>
          </div>
          <div class="polygon-down"><img src="../images/icons/polygon-1.svg" alt="Polygon"></div>
        </div>
        <div class="tutorial-2-transparent">
          <div class="tutorials-content">
            <myCommuHome :map="false" :bander="false"></myCommuHome>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  data () {
    return {
      step: 1
    }
  },
  methods: {
    ...mapActions('mycommu', ['toggerMyCommuFooterMenu', 'isTutorial']),
    nextStep () {
      this.step = this.step + 1
      this.toggerMyCommuFooterMenu(true)
      if (this.step === 3) {
        this.isTutorial(false)
      }
    }
  },
  created () {
    this.isTutorial(true)
  }
}
</script>

<style scoped lang="sass" >
.the-tutorials
  width: 100vw
  height: 100vh
  position: fixed
  z-index: 1997
  cursor: pointer
  top: 0
  .main-tutorials
    width: 100%
    margin: auto
    background: rgba(39, 61, 53, 0.9)
    height: 100%
    display: flex
    flex-wrap: wrap
    justify-content: center
    .w-100
      width: 100%
    .tutorial-1, .tutorial-2
      background: white
      border-radius: 10px
      margin: 10px 31px 0
      padding: 24px
      font-size: 14px
      align-self: end
      .header
        display: flex
        justify-content: space-between
        margin-bottom: 20px
        align-items: start
        font-weight: bold
        align-items: center
        button
          color: #2DBA87
          border: none
          color: #2DBA87
          box-shadow: 0px 2px 8px #f2efde
          border-radius: 6px
          background: white
          font-weight: bold
          padding: 9px 8px
          min-width: 58px
          margin-left: 23px
      .content
        font-weight: 300
    .polygon-down
      text-align: center
      margin-top: -2px
    .action-button
      align-self: baseline
      display: flex
      flex: 0 0 100%
      button
        font-family: "Hiragino Kaku Gothic ProN"
        display: flex
        align-items: center
        justify-content: space-evenly
        padding: 20px
        background: white
        font-size: 15px
        font-weight: 700
        color: #2DBA87
        width: 45%
        margin: auto
        border: none
        border-radius: 6px
    .tutorial-2
      align-self: start
      margin-top: 70px
    .tutorial-2-transparent
      width: 100%
      height: 100%
      background: #f8f7f2
.tutorials-content
  margin-top: 25px
.next-step
  position: absolute
  top: 0
  bottom: 0
  left: 0
  right: 0
  z-index: 9999
.fade-in
  position: fixed
  bottom: 130px

</style>
