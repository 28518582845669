<template>
  <div class="topic-tags">
    <ul>
      <li
        :class="{ 'active': activeClass('all')}"
        @click="onClick('all')"
      >
        全て表示
      </li>
      <li
        :class="{ 'active': activeClass('comments')}"
        @click="onClick('comments')"
      >
        コメントした投稿
      </li>
      <li
        :class="{ 'active': activeClass('like')}"
        @click="onClick('like')"
      >
        いいねした投稿
      </li>
    </ul>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  components: {},
  props: {
  },
  data() {
    return {}
  },
  computed: {
    ...mapState('filter', ['topics']),
  },
  mounted() {

  },
  methods: {
    ...mapActions('filter', [
      'setTopic'
    ]),
    ...mapActions('home', [
      'fetchRecents'
    ]),
    activeClass(value) {
      return this.topics[value]
    },
    onClick(value) {
      this.setTopic(value)
      this.fetchRecents({currentCommunity: 0});
    }
  }
}
</script>
<style scoped lang="sass">
  .topic-tags
    width: 100%
    margin: auto
    margin-bottom: 20px
    margin-left: 15px

    ul
      display: flex

      li
        border: 1px solid #2DBA87
        border-radius: 90px
        padding: 6px 12px
        background: #FFFFFF
        color: #2DBA87
        font-size: 14px
        font-weight: 400
        text-align: center
        margin-right: 10px
        &.active
          background: #D5F1E7

</style>
