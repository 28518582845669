<template>
  <div>
    <div v-if="!(isSearch || noResult)">
      <div class="card-top">
        <div class="card-top-img">
          <img src="../../images/for_community/mama_support.png">
        </div>
        <div class="card-top-text">
          <p>
            シェア・コンシェルジュはコミュニティの相談役。
            それぞれが得意とする3種類の活動で、あなたとコ
            ミュニティを絆げてくれます。気になる事があれば
            相談してみましょう。
          </p>
        </div>
      </div>

      <div class="card-filter">
        <Tag :count="count"/>
      </div>
    </div>
    <div class="count-supporter" v-if="searched && !noResult">
      {{ count }}人のシェア・コンシェルジュが見つかりました。
    </div>
    <div class="card-list" v-if="!(isSearch || noResult) || (searched && !noResult)">
      <Supporter
        v-for="(supporter, index) in supporters"
        :keys="index"
        :is-last="count == index + 1"
        :supporter="supporter"
        :is-parenting="isParenting"
        :is-pet="isPet"
        :is-sport="isSport"
        :is-cooking="isCooking"
        :is-fashion="isFashion"
        :is-outdoor="isOutdoor"
        :is-book-and-manga="isBookAndManga"
        :is-game="isGame"
        :is-movie-and-art="isMovieAndArt"
        :is-area="isArea"
        :is-business="isBusiness"
        :is-trip="isTrip"
        :is-music="isMusic"
        :is-dot="isDot"
      />
    </div>
    <MyCommuUserIndex v-if="!(!(isSearch || noResult) || (searched && !noResult))" :no-result="noResult"
                      text="名前でコミュニティ内の</br>シェア・コンシェルジュを</br>検索できます。"
                      notFound="該当するシェア・コンシェルジュが</br>見つかりませんでした。"
    />
  </div>
</template>

<script>
import Supporter from 'components/my-commu/mama_supporter/Supporter'
import { mapState, mapActions } from 'vuex'

export default {
  components: { Supporter },
  props: {
    isParenting: {
      type: String,
      default: ''
    },
    isPet: {
      type: String,
      default: ''
    },
    isSport: {
      type: String,
      default: ''
    },
    isCooking: {
      type: String,
      default: ''
    },
    isFashion: {
      type: String,
      default: ''
    },
    isOutdoor: {
      type: String,
      default: ''
    },
    isBookAndManga: {
      type: String,
      default: ''
    },
    isGame: {
      type: String,
      default: ''
    },
    isMovieAndArt: {
      type: String,
      default: ''
    },
    isArea: {
      type: String,
      default: ''
    },
    isBusiness: {
      type: String,
      default: ''
    },
    isTrip: {
      type: String,
      default: ''
    },
    isMusic: {
      type: String,
      default: ''
    },
    isDot: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapState('mycommu', ['supporters', 'searched', 'isSearch']),
    ...mapState('session', ['communityId']),
    count () {
      return this.supporters.length
    },
    noResult () {
      return this.supporters.length === 0 && this.searched
    }
  },
  created () {
    this.searchSupporters({ currentCommunity: this.communityId })
    console.log(this.supporters)
  },
  methods: {
    ...mapActions('mycommu', ['searchSupporters'])
  }
}
</script>
<style scoped lang="sass">
.card-top
  background-color: #FFFFFF

  .card-top-img
    padding: 24px 42px 24px 42px

  .card-top-text
    padding: 0 15px 32px 15px

    p
      font-weight: 300
      font-size: 15px
      line-height: 20px
      color: #273D35

.card-filter
  background-color: #f8f7f2

.count-supporter
  margin: 24px 16px 24px 16px
  font-weight: 300
  font-size: 15px
  color: #273D35
</style>
