<template>
  <div>
    <div class="card-coments-content-element first">
      <div class="card-coments-content-element-des">
        <div v-html="comment.body"></div>
      </div>

      <div class="author">
        <div class="avatar">
          <img :src="comment.writer.communityAvatarPath" />
        </div>
        <div class="author-container">
          <div class="name">
            <span class="infor-name">{{ commentWriterName(comment.writer) }}</span>
            <span class="infor-name-2">{{ comment.createdAt }}</span>
          </div>
        </div>
        <div class="author-reply">
          <button
            type="button"
            class="button is-rounded fw-bold"
            @click="clickReply"
          >
            返信する
          </button>
        </div>
        <div v-if="comment.isAuthor" class="action-comment">
          <BDropdown :mobile-modal="false" position="is-bottom-left">
            <img src="../../images/icons/three_dots.png" alt="" slot="trigger">
            <div class="co-dropdown-item edit" @click="comment.activeEdit = true">
              <span class="icon-edit">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.37012 8.93L14.1401 20.69L21.6301 21.63L20.6901 14.14L8.93012 2.37L2.37012 8.93Z" stroke="#273D35" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M13.5098 20.07L20.0698 13.51" stroke="#273D35" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </span>
              <span>編集</span>
            </div>
            <div
              class="co-dropdown-item remove"
              @click="isDeleteModalActive = true"
            >
              <span class="icon-remove">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M12.0002 6.40994C16.5621 6.40994 20.2602 5.46526 20.2602 4.29994C20.2602 3.13462 16.5621 2.18994 12.0002 2.18994C7.43836 2.18994 3.74023 3.13462 3.74023 4.29994C3.74023 5.46526 7.43836 6.40994 12.0002 6.40994Z"
                    stroke="#EE5353" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  <path
                    d="M20.2603 4.53003L19.5703 17.8801C19.4703 20.2801 17.0202 21.8101 12.0002 21.8101C6.98024 21.8101 4.53024 20.2801 4.43024 17.8801L3.74023 4.53003"
                    stroke="#EE5353" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M12 10.4V17.54" stroke="#EE5353" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M7.66992 10.7L7.94992 17.25" stroke="#EE5353" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M16.3298 10.7L16.0498 17.25" stroke="#EE5353" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </span>
              <span>削除</span>
            </div>
          </BDropdown>
        </div>
      </div>
      <div v-if="comment.activeEdit" class="edit-comment-area">
        <textarea v-model="commentBody" class="w-100"/>
        <div>
          <button type="button" @click="comment.activeEdit = false" class="button fw-bold">キャンセル</button>
          <button type="button" @click="editComment(comment.crudPath, commentBody)" class="button fw-bold btn-edit">編集</button>
        </div>
      </div>
    </div>
    <BModal
      :active.sync="isDeleteModalActive"
      has-modal-card
      :can-cancel="true"
    >
      <div class="modal-card">
        <section class="modal-card-body">
          <div class="content">
            <span>コメントを本当に削除してよろしいでしょうか？</span>
          </div>
        </section>
        <section class="modal-card-footer">
          <button @click="isDeleteModalActive = false" class="button cancel">キャンセル</button>
          <a :href="comment.crudPath" data-method="delete" class="button ok">はい</a>
        </section>
      </div>
    </BModal>
  </div>
</template>

<script>

import axios from 'axios'
import { showSuccess, showError } from '../../lib/app-toast'

export default {
  props: {
    comment: {
      type: Object,
      require: true
    },
    fetchComments: {
      type: Function,
      default: () => {}
    }
  },
  data () {
    return {
      commentBody: this.sanitizeString(this.comment.body),
      isDeleteModalActive: false
    }
  },
  methods: {
    commentWriterName (writer) {
      if (writer.commuNickname !== null && writer.commuNickname !== '') {
        return writer.commuNickname
      } else {
        return writer.fullName
      }
    },
    editComment (updatePath, body) {
      body = body.replace(`@${this.comment.writer.fullName}`, `[[@${this.comment.writer.fullName}]]`)
      const data = {
        topic_comment: {
          body: body
        }
      }
      axios.put(updatePath, data).then((res) => {
        if (res.data.data.status === 'success') {
          showSuccess(res.data.data.message)
          this.isDeleteModalActive = false
          this.fetchComments()
          this.comment.activeEdit = false
        } else {
          showError(res.data.data.message)
        }
      })
    },
    clickReply () {
      this.$emit('clickReply', this.comment.writer.fullName, this.comment.writer.id)
    },
    sanitizeString (str) {
      str = str.replace(/<\/?(?!br)[^>]+(>|$)/g, '')
      return str.replace(/<br\s*\/?>/g, '\n')
    }
  }
}
</script>

<style scoped lang="sass">
  .card-coments-content-element
    border-bottom: 1px solid #CCCCCC
    padding: 24px 0
    word-wrap: break-word
    &.firts
      color: #273D35
      font-weight: 400
      font-size: 12px
    .mention-hinglight
      color: red
    .author
      margin-top: 10px
      display: grid
      grid-template-columns: auto auto auto auto
      align-items: center
      .avatar
        margin-right: 10px
        img
          width: 40px
          height: 40px
          border-radius: 50%
          object-fit: cover
          flex-shrink: 0
      .author-container
        display: flex
        .name
          display: flex
          flex-direction: column
          justify-content: center
          color: #273D35
          font-size: 12px
          font-weight: 300
          .infor-name
            color: #273D35
            word-break: break-all

          .infor-name-2
            color: #7C7C7C
      .author-reply
        margin-left: auto
        .button
          color: #2DBA87
          border-radius: 6px
          font-weight: 600
          font-size: 14px
          padding: 9px
          border: unset
          box-shadow: 0 2px 8px #F2EFDE
      /deep/
      .action-comment
        display: flex
        margin-left: 8px
        .dropdown-trigger
          display: flex
          img
            width: 20px
            transform: rotate(90deg)
        .dropdown-menu
          min-width: unset
          width: 120px
          text-align: center
          .co-dropdown-item
            display: flex
            justify-content: space-around
            &.edit
              margin-bottom: 8px
  .w-100
    width: 100%
  .btn-edit
    background: #2DBA87
    border-radius: 6px
    color: #ffffff
    margin-left: 10px
  /deep/
    .dropdown-menu
      min-width: 7rem
      .dropdown-content
        padding: 8px 0
  .animation-content
    width: 80%
    background: #FFFFFF
    .modal-card
      border-radius: 20px
      .modal-card-footer
        line-height: 0
        display: inline-flex
        grid-template-columns: auto auto
        .button
          height: 48px
          border-top: 1px solid #CCCCCC
          background: #fff
          color: #2DBA87
          border-radius: 0
          font-size: 15px
          min-width: 50%
          &:focus-visible
            border: none
          &.cancel
            border-right: unset
        .ok
          font-weight: bold
          border-left: 1px solid #CCCCCC

</style>
