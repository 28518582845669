<template>
  <div v-if="isMyCommuDomain">
    <div class="top-bar">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" @click="redirect()">
        <path v-if="arrowBack" d="M16.0702 3.8501L7.93018 12.0001L16.0702 20.1501" stroke="#2DBA87" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      <div class="top-bar-title" v-text="title"/>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isMyCommuDomain: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: 'aaa'
    },
    arrowBack: {
      type: Boolean,
      default: true
    },
    redirectBackUrl: {
      type: String,
      default: ''
    }
  },
  methods: {
    redirect () {
      if (this.redirectBackUrl === '' || this.redirectBackUrl === undefined) {
        window.history.back()
      } else {
        window.location.href = this.redirectBackUrl
      }
    }
  }
}
</script>

<style scoped lang="sass">
@import '~stylesheets/resources'
.top-bar
  display: flex
  margin-top: -15px
  padding: 15px 15px
  display: grid
  grid-template-columns: 20% 60% 20%
.top-bar-title
  text-align: center
  font-size: 16px
  font-weight: bold
  margin-top: 2px
</style>
