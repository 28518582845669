<template>
  <BField
    :label="label"
    :message="errors"
    :type="status"
    :label-for="id"
    :addons="false"
    :custom-class="customClass"
  >
    <div class="birthday-picker">
      <div class="birthday-picker__text" :class="{'full-width': hideUnit}">
        <div class="birthday-picker__text-inner date-dropdown" :class="{'full-width': hideUnit}">
          <span class="select">
            <select
              v-model.number="year"
              :class="{'dark-gray-color' : isYearPlaceholder}"
              @change="validateInput"
            >
              <option value="" disabled selected hidden v-if="hasYearPlaceholder">{{ yearPlaceholder }}</option>
              <option
                v-for="num in selectableYears"
                :key="num"
                :value="num"
                v-text="num"
              />
            </select>
          </span>
          <div class="birthday-picker__unit" v-if="!hideUnit">
            年
          </div>
          <template v-if="!discardMonth">
            <span class="select">
              <select
                v-model.number="month"
                :class="{'dark-gray-color' : isMonthPlaceholder}"
                @change="validateInput"
              >
                <option value="" disabled selected hidden v-if="hasMonthPlaceholder">{{ monthPlaceholder }}</option>
                <option
                  v-for="num in 12"
                  :key="num"
                  :value="num"
                  v-text="num"
                />
              </select>
            </span>
            <div class="unit" v-if="!hideUnit">
              月
            </div>
          </template>

          <template v-if="!discardDay">
            <span class="select">
              <select
                v-model.number="day"
                :class="{'dark-gray-color' : isDayPlaceholder}"
                @change="validateInput"
              >
                <option value="" disabled selected hidden v-if="hasDayPlaceholder">{{ dayPlaceholder }}</option>
                <option
                  v-for="num in daysInMonth"
                  :key="num"
                  :value="num"
                  v-text="num"
                />
              </select>
            </span>
            <div class="unit" v-if="!hideUnit">
              日
            </div>
          </template>
        </div>
        <div
          v-if="isPreviewAge && age"
          class="preview-age tag is-rounded"
          v-text="age"
        />
      </div>
      <input
        :id="id"
        :value="inputValue"
        :name="name"
        type="hidden"
      >
    </div>
    <slot />
  </BField>
</template>

<script>
import { format, getDaysInMonth, parse } from 'date-fns'
import { ageOf } from 'lib/age'
import * as inputs from '.././inputs'

function setDay (placeholder, value, inputVal) {
  if (inputVal !== null && inputVal !== '') {
    return value
  } else {
    return placeholder
  }
}

function setMonth (placeholder, value, inputVal) {
  if (inputVal !== null && inputVal !== '') {
    return value
  } else {
    return placeholder
  }
}

function setYear (placeholder, value, inputVal) {
  if (inputVal !== null && inputVal !== '') {
    return value
  } else {
    return placeholder
  }
}
export default {
  props: {
    name: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: null
    },
    value: {
      type: String,
      default: ''
    },
    errors: {
      type: Array,
      default: () => []
    },
    isPublished: {
      type: Boolean,
      default: false
    },
    isChild: {
      type: Boolean,
      default: false
    },
    discardDay: {
      type: Boolean,
      default: false
    },
    discardMonth: {
      type: Boolean,
      default: false
    },
    yearOptions: {
      type: Array,
      default: null
    },
    yearPlaceholder: {
      type: String,
      default: ''
    },
    monthPlaceholder: {
      type: String,
      default: ''
    },
    dayPlaceholder: {
      type: String,
      default: ''
    },
    hideUnit: {
      type: Boolean,
      default: false
    },
    validateDay: {
      type: Boolean,
      default: true
    }
  },
  data () {
    const date = parse(this.value)
    return {
      initialYear: new Date().getFullYear(),
      year: setYear(this.yearPlaceholder, date.getFullYear(), this.value),
      month: setMonth(this.monthPlaceholder, this.discardMonth ? 1 : date.getMonth() + 1, this.value), // DateのMonth(0スタート)なので表示用に + 1
      day: setDay(this.dayPlaceholder, this.discardDay ? 1 : date.getDate(), this.value)
    }
  },
  computed: {
    status () {
      return inputs.status(this.errors)
    },
    id () {
      return inputs.idForName(this.name)
    },
    customClass () {
      return inputs.customClass(this.$props)
    },
    inputDate () {
      // DateのMonth(0スタート)なので表示用の値から -1
      return new Date(this.year, this.month - 1, this.day)
    },
    inputValue () {
      this.$emit('input', format(this.inputDate, 'YYYY-MM-DD'))
      return format(this.inputDate, 'YYYY-MM-DD')
    },
    daysInMonth () {
      return getDaysInMonth(this.inputValue)
    },
    selectableYears () {
      if (this.yearOptions) {
        return this.yearOptions
      }
      const years = this.isChild ? 19 : 100
      return Array.from(Array(years).keys()).map(
        (value, key) => this.initialYear - key
      )
    },
    isPreviewAge () {
      return this.isChild
    },
    age () {
      const { years, months } = ageOf(this.inputDate)
      if (years < 0) {
        return ''
      } else if (years < 3) {
        return `${years}歳${months}ヶ月`
      } else {
        return `${years}歳`
      }
    },
    hasYearPlaceholder () {
      return this.yearPlaceholder !== ''
    },
    hasMonthPlaceholder () {
      return this.monthPlaceholder !== ''
    },
    hasDayPlaceholder () {
      return this.dayPlaceholder !== ''
    },
    isYearPlaceholder () {
      return (typeof this.year === 'string')
    },
    isMonthPlaceholder () {
      return (typeof this.month === 'string')
    },
    isDayPlaceholder () {
      return (typeof this.day === 'string')
    }
  },
  methods: {
    validateInput () {
      const checkDay = this.validateDay ? this.day !== '' : true
      if ((checkDay) && (this.month !== '' && !this.isMonthPlaceholder) && (this.year !== '' && !this.isYearPlaceholder)) {
        this.$emit('date-validation', true)
      } else {
        this.$emit('date-validation', false)
      }
    }
  }
}
</script>

<style lang="sass" scoped>
@import '~stylesheets/resources'

/deep/
@import '~stylesheets/components/published-label'

.birthday-picker__text
  display: inline-flex
  padding: 0

.birthday-picker__text-inner
  display: flex
  justify-content: center
  align-items: center
  color: $black
  line-height: 1

.birthday-picker__unit, .unit
  font-size: 80%
  font-weight: normal
  color: #333
  padding: 0 16px 0 8px

.preview-age
  align-self: flex-end
  margin-left: 1rem
.select:not(.is-multiple):not(.is-loading)::after
  border-width: 2px
  height: 0.425em
  width: 0.425em
  margin-top: -0.2875em
  right: 0.75em
select
  box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1)
  border-radius: 6px
  caret-color: #2DBA87
  height: 48px !important
  &:focus
    border: 1px solid #2DBA87!important
    border-radius: 6px
    box-shadow: 0 0 0 0!important
.dark-gray-color
  color: #AAAAAA
.select
  height: unset!important
  &::after
    margin-top: 0!important
.full-width
  width: 100%
</style>
