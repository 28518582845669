<template>
  <div>
    <HiddenHeader/>
    <MyCommuHiddenButtonFooter :is-my-commu-domain="true"/>
    <MyCommuHiddenFooterMenu :is-my-commu-domain="true"/>
    <div class="topbar">
      <div class="topbar-icon-back">
        <svg width="21" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onclick="history.back()">
          <path d="M16.0697 3.8501L7.92969 12.0001L16.0697 20.1501" stroke="#2DBA87" stroke-width="2"
                stroke-linecap="round" stroke-linejoin="round" />
        </svg>

      </div>
      <div class="topbar-title">
        {{ staffCandidate.title }}
      </div>
      <div class="topbar-avatar">
        <img :src="currentCommunity.community_url" />
      </div>
    </div>
    <div class="assign-staff-container">
      <div class="assigned-alert-card">
        <div class="assigned-alert">
          <b-icon
            icon="exclamation-triangle"
            size="is-medium">
          </b-icon>
          <div class="assigned-alert-content">未回答の状態です。ご回答よろしくお願いいたします</div>
        </div>
        <div class="assigned-alert-date">
          <div class="assigned-alert-date-content">
            <img src="../../images/icons/calendar_black.png" alt="" width="16" height="16">
            <div class="has-text-weight-normal">申し込み期限</div>
            <div>{{ staffCandidate.requestExpiredAt }}まで</div>
          </div>
        </div>
      </div>
      <div class="assigned-content">
        <div class="assigned-content-top">
          <div class="event-logo-area">
            <div class="event-logo">
              <img src="../../images/mycommu-logo.png" alt="">
            </div>
            <div class="name">運営</div>
          </div>
          <div class="date">{{ staffCandidate.createdAt }}</div>
        </div>
        <div class="assigned-content-body" v-html="staffCandidate.body"></div>
        <div class="assigned-content-option">
          <div class="option-title">
            <div class="option-title-top">ご回答</div>
            <div>
              当日参加ができなくても、
            </div>
            <div>
              広報だけの参加でも大歓迎です。
            </div>
          </div>
          <div class="radio-area">
            <div class="radio-button">
              <label for="first">
                <input type="radio" v-model="choseQuestion" value="acceptance" id="first"/>
                <div class="mark">
                  <b-icon
                    pack="far"
                    icon="circle"
                    size="is-large">
                  </b-icon>
                  <div>参加</div>
                  <div>できます</div>
                </div>
              </label>
              <label for="second">
                <input type="radio" v-model="choseQuestion" value="arrangement" id="second"/>
                <div class="mark">
                  <b-icon
                    pack="far"
                    icon="triangle"
                    size="is-large">
                  </b-icon>
                  <div>検討</div>
                  <div>します</div>
                </div>
              </label>
              <label for="third">
                <input type="radio" v-model="choseQuestion" value="refusal" id="third" @click="clear"/>
                <div class="mark">
                  <b-icon
                    pack="far"
                    icon="times"
                    size="is-large">
                  </b-icon>
                  <div>参加</div>
                  <div>できません</div>
                </div>
              </label>
            </div>
          </div>
          <hr>
          <div class="title-field" v-if="isArrangement && !isRefusal">
            <div class="title-field-optional">
              <div class="title-content">検討している内容</div>
            </div>
            <div class="input-green entry-fee">
              <b-field>
                <BSelect
                  v-model="message"
                  placeholder="選択する"
                  :class="{'has-message': hasMessage}"
                >
                  <option>日程調整が必要</option>
                  <option>条件相談が必要</option>
                  <option>もう少し話を聞きたい</option>
                </BSelect>
              </b-field>
            </div>
          </div>
          <div v-if="!isRefusal">
            <div class="title-field mt-24px">
              <div class="title-field-optional">
                <div class="title-content">備考</div>
                <div class="optional">任意</div>
              </div>
              <div class="input-green entry-fee">
                <textarea rows="6" placeholder="なにか不明なことや、疑問があれば教えて下さい。" v-model="question"></textarea>
              </div>
            </div>
            <div class="memo-title">ご協力のお願い</div>
            <div class="memo-area">
              <div>
                <img src="../../images/icons/leaflets.png" alt="" width="45">
              </div>
              <div class="memo-area-content">
                <div>ご協力いただくイベントに、たくさんの方に</div>
                <div>ご参加いただくため、告知用チラシの配布に</div>
                <div>ご協力をお願いいたします。</div>
                <div>可能なら配布できる枚数を教えてください。</div>
                <div>100枚以上から奨励金をおし払いいたします。</div>
              </div>
            </div>
            <div class="leaflets-area">
              <div class="radio-button">
                <label for="first-ll">
                  <input type="radio" v-model="choseLeafLets" :value="0" id="first-ll" />
                  <div class="mark">
                    <div>0枚</div>
                  </div>
                </label>
                <label for="second-ll">
                  <input type="radio" v-model="choseLeafLets" :value="20" id="second-ll" />
                  <div class="mark">
                    <div>20枚</div>
                  </div>
                </label>
                <label for="third-ll">
                  <input type="radio" v-model="choseLeafLets" :value="40" id="third-ll" />
                  <div class="mark">
                    <div>40枚</div>
                  </div>
                </label>
                <label for="fourth-ll">
                  <input type="radio" v-model="choseLeafLets" :value="60" id="fourth-ll" />
                  <div class="mark">
                    <div>60枚</div>
                  </div>
                </label>
                <label for="fifth-ll">
                  <input type="radio" v-model="choseLeafLets" :value="80" id="fifth-ll" />
                  <div class="mark">
                    <div>80枚</div>
                  </div>
                </label>
                <label for="sixth-ll">
                  <input type="radio" v-model="choseLeafLets" :value="100" id="sixth-ll" />
                  <div class="mark">
                    <div>100枚</div>
                  </div>
                </label>
              </div>
              <div class="free-leaflets">
                <div>100枚以上の方は枚数入力</div>
                <input placeholder="0" min="101" @keyup="removeChecked()"
                       ref="Leaflets" type="number"/>
              </div>
            </div>
          </div>
          <button class="btn btn-success" @click="createTopicComment()">回答を返信する</button>
        </div>
      </div>
    </div>
    <BModal
      :active.sync="isComponentModalActive"
      has-modal-card
      :can-cancel="true"
      :on-cancel="redirectList"
      width="70%"
    >
      <div class="modal-card" style="width: auto">
        <div class="modal-card-body">
          <img src="../../images/for_community/circle-check.png">
          <p class="text-default">
            回答を受け付けました
          </p>
        </div>
      </div>
    </BModal>
  </div>
</template>

<script>

import { mapState } from 'vuex'
import Input from '../../components/my-commu/comments/Input'
import axios from '../../utils/axios'
import { commuError } from 'lib/app-toast'

// eslint-disable-next-line no-unused-vars
function questionValue (replyType) {
  if (replyType === 'unreplied') {
    return 'acceptance'
  } else {
    return replyType
  }
}

export default {
  components: { Input },
  props: {
    staffCandidate: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState('mycommu', ['currentCommunity']),
    ...mapState('space', ['unsetMargin', 'unsetPadding']),
    isRefusal () {
      return this.choseQuestion === 'refusal'
    },
    isArrangement () {
      return this.choseQuestion === 'arrangement'
    },
    hasMessage () {
      return this.message === null
    }
  },
  data () {
    return {
      choseQuestion: questionValue(this.staffCandidate.replyType),
      choseLeafLets: this.staffCandidate.tentativePosterCount,
      inputLeafLets: this.staffCandidate.inputTentativePosterCount,
      question: this.staffCandidate.childrenDescription,
      message: this.staffCandidate.message,
      isComponentModalActive: false
    }
  },
  watch: {
    choseQuestion () {
      this.choseLeafLets = 0
      this.inputLeafLets = ''
      this.question = ''
      this.message = ''
    }
  },
  created () {
  },
  mounted () {
    this.unsetMargin.mainContainer = true
    this.unsetMargin.appMain = true
    this.unsetPadding.mainContainer = true
  },
  methods: {
    removeChecked () {
      const Leaflets = this.$refs.Leaflets.value
      if (Leaflets > 100) {
        this.choseLeafLets = ''
        this.inputLeafLets = Leaflets
      }
    },
    clear () {
      this.question = ''
      this.choseLeafLets = 0
      this.inputLeafLets = ''
      this.message = ''
    },
    redirectList () {
      window.location.href = `/communities/${this.currentCommunity.community_id}/managements/staff_candidates/${this.staffCandidate.id}/chat`
    },
    createTopicComment () {
      const tentativePosterCount = this.inputLeafLets > 100 ? this.inputLeafLets : this.choseLeafLets
      const data = {
        staff_candidate: {
          reply_type: this.choseQuestion,
          children_description: this.question,
          tentative_poster_count: tentativePosterCount,
          message: this.message
        }
      }

      return axios.put(`/communities/${this.currentCommunity.community_id}/managements/staff_candidates/${this.staffCandidate.id}`, data).then((response) => {
        if (response.data.status === true) {
          this.isComponentModalActive = true
        } else {
          commuError(response.data.message)
        }
      })
    }
  }
}
</script>

<style scoped lang="sass">
  .topbar
    height: 41px
    background: #FFFFFF
    display: grid
    grid-template-columns: auto auto auto
    align-items: center
    .topbar-icon-back
      margin-left: 16px
    .topbar-title
      color: #273D35
      font-weight: 700
      font-size: 16px
      text-align: center
    .topbar-avatar
      margin-left: auto
      margin-right: 23px
      margin-top: 5px
      img
        width: 32px
        height: 32px
        border-radius: 4px
  .assign-staff-container
    font-weight: normal
    font-size: 15px
    line-height: 15px
    color: #273D35
    .assigned-alert-card
      padding: 24px 0
      background: white
      font-weight: bold
      font-size: 12px
      line-height: 12px
      .assigned-alert
        margin: 0 16px
        color: #F78F54
        border: 1px solid #F78F54
        padding: 12px 8px
        display: flex
        align-items: center
        justify-content: space-between
        /deep/
          .fal.fa-exclamation-triangle
            font-weight: 400
            font-size: 20px
      .assigned-alert-date
        text-align: center
        padding-top: 18px
        .assigned-alert-date-content
          display: inline-flex
          align-items: center
          div
            margin-left: 5px
    .assigned-content
      padding: 16px 16px 58px
      .assigned-content-top
        display: flex
        font-size: 12px
        align-items: center
        justify-content: space-between
        .event-logo-area
          display: flex
          align-items: center
          .event-logo
            width: 40px
            height: 40px
            border-radius: 50%
            background: white
            display: flex
            img
              border-radius: 50%
              width: 30px
              height: 28px
              margin: auto
          .name
            margin-left: 8px
            font-weight: 300
            color: #273D35
        .date
          font-weight: 400
          color: #AAAAAA
      .assigned-content-body
        margin-top: 16px
        line-height: 20px
      .assigned-content-option
        margin-top: 40px
        padding: 32px 20px 24px
        background: white
        border-radius: 6px
        .option-title
          text-align: center
          .option-title-top
            font-size: 17px
            font-weight: bold
            margin-bottom: 16px
          div:last-child
            margin-top: 5px
        .radio-area
          margin-top: 32px
          display: flex
          flex-direction: column
          text-align: center
          input
            display: none
          .radio-button
            display: flex
          label
            flex: 1
            margin: 0 4px
            &:first-child
              margin: 0 8px 0 0
            &:last-child
              margin: 0 0 0 8px
            .mark
              box-shadow: 0 2px 8px #F2EFDE
              border-radius: 6px
              min-width: 93px
              min-height: 132px
              font-weight: bold
              font-size: 12px
              line-height: 12px
              text-align: center
              color: #2DBA87
              .icon
                margin: 18px 0
              div:last-child
                margin-top: 5px
            & > input:checked ~ .mark
              background: #2DBA87
              color: white
        hr
          background: #cccccc
          height: 1px
          margin: 32px 0
        .title-field-optional
          display: flex
          justify-content: space-between
        .title-content
          font-style: normal
          font-weight: normal
          font-size: 14px
          line-height: 14px
          color: #273D35
        .input-green
          margin-top: 8px
          /deep/
            .select
              width: 100%
              &:after
                margin-top: 0
                border-color: #273D35
              option:disabled
                color: #AAAAAA
            textarea, select
              border-radius: 6px
              height: 48px
              padding: 16px
              font-size: 15px
              color: #273D35
              border: 1px solid #CCCCCC
              caret-color: #2DBA87
              outline: none
              background: white
              &::placeholder
                font-weight: 300
                font-size: 15px
                line-height: 20px
                color: #AAAAAA
              &:focus
                border: 1px solid #2DBA87
                box-shadow: unset
              &::-webkit-scrollbar
                display: none
            textarea
              width: 100%
              resize: none
              height: unset
            select
              width: 100%
              padding: 13px 16px
        .optional
          background-color: #FFFFFF
          color: #2DBA87
          font-style: normal
          font-weight: 700
          font-size: 10px
          line-height: 9px
          border: 1px solid #2DBA87
          padding: 2px
        .memo-title
          font-size: 14px
          margin-top: 24px
        .memo-area
          margin-top: 16px
          display: flex
          justify-content: space-between
          align-items: center
          .memo-area-content
            font-size: 12px
            div:not(:first-child)
              margin-top: 5px
        .leaflets-area
          margin-top: 16px
          text-align: center
          .radio-button
            flex-wrap: wrap
            display: flex
            input
              display: none
            label
              width: 33%
              flex-grow: 1
              .mark
                box-shadow: 0 2px 8px #F2EFDE
                border-radius: 6px
                font-size: 14px
                font-weight: bold
                color: #2DBA87
                background: white
                height: 50px
                margin: 6px
                display: flex
                align-items: center
                justify-content: center
              & > input:checked ~ .mark
                background: #2DBA87
                color: white
          .free-leaflets
            margin-top: 12px
            display: flex
            justify-content: space-between
            align-items: center
            div
              font-size: 12px
            input
              width: 92px
              height: 48px
              border: 1px solid #CCCCCC
              border-radius: 6px
              padding: 16px
              caret-color: #2DBA87
              outline: none
              background: white
              &::placeholder
                font-weight: 300
                font-size: 15px
                line-height: 20px
                color: #AAAAAA
              &:focus
                border: 1px solid #2DBA87
        .btn
          padding: 16px
          border-radius: 6px
          margin-top: 40px
          font-weight: 700
          font-size: 15px
          line-height: 15px
          background: white
          color: #2DBA87
          border: none
          width: 100%
          text-align: center
          box-shadow: 0 2px 8px #F2EFDE
          &.btn-success
            color: white
            background: #2DBA87
            margin-left: 5px
  .mt-24px
    margin-top: 24px
  /deep/
    .modal-background
      background: #273D35
      opacity: 0.9
    .modal-close
      display: none
  @media screen and (max-width: 768px)
    /deep/
    .modal .animation-content
      width: unset
  .modal-card-body
    margin: auto
    color: #273D35
    font-size: 15px
    border-radius: 20px
    text-align: center
    padding: 40px 16px
    width: 230px
    img
      margin-bottom: 20px
      height: 66px
      width: 66px
  .has-message
    /deep/ span
      select
        color: #AAAAAA !important
</style>
