<template>
  <div class="topic-comment">
    <TopicHeader
      v-if="!isMyCommuDomain && viewMode"
      v-bind="topicHeaderProps"
      :replyable="replyable"
    >
      <p v-if="canUpdateComment" class="dropdown-item item-hover" @click="toggleViewEdit()">編集</p>
      <slot name="option-menu" />
    </TopicHeader>
    <div class="main-content">
      <!-- eslint-disable-next-line vue/no-v-html -->
      <div
        v-if="showTruncatedBody"
        :class="isMyCommuDomain ? 'top-body' : 'body' "
        v-html="truncatedBody"
      />
      <!-- eslint-disable-next-line vue/no-v-html -->
      <div
        v-else
        :class="isMyCommuDomain ? 'top-body' : 'body' "
        v-html="body"
      />
      <image-modal
        v-if="hasImage"
        class="image"
        :src="image.url"
        :original-src="image.original"
      />
    </div>
    <div
      v-if="needReadMore"
      class="read-more"
    >
      <a
        href="javascript:void(0)"
        @click="toggleBody"
      >
        <span v-text="readMoreText" />
      </a>
    </div>
    <div class="commu-comments">
      <TopicHeader
        v-if="isMyCommuDomain"
        v-bind="topicHeaderProps"
        :isMyCommuDomain="false"
        :timeSince="true"
      >
        <slot name="option-menu" />
      </TopicHeader>
      <a
        class="my-commu-reply btn"
        href="#"
        @click="toggleCommentForm"
      >返信する</a>
    </div>
  </div>
</template>

<script>
import TopicHeader from 'components/TopicHeader'

export default {
  components: { TopicHeader },
  props: {
    writer: {
      type: Object,
      required: true
    },
    shortBody: {
      type: String,
      required: true
    },
    mediumBody: {
      type: String,
      required: true
    },
    body: {
      type: String,
      required: true
    },
    image: {
      type: Object,
      default: null
    },
    createdAt: {
      type: String,
      required: true
    },
    short: {
      type: Boolean,
      default: false
    },
    useNickname: {
      type: Boolean,
      required: true
    },
    canUpdateComment: {
      type: Boolean,
      default: false
    },
    editTopicCommentProps: {
      type: Object,
      default: null
    },
    isMyCommuDomain: {
      type: Boolean,
      default: false
    },
    replyable: {
      type: Boolean,
      default: false
    },
    commentFormVisible: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
      showTruncatedBody: true
    }
  },
  computed: {
    topicHeaderProps () {
      return { ...this.writer, createdAt: this.createdAt, useNickname: this.useNickname }
    },
    hasImage () {
      return !!this.image
    },
    truncatedBody () {
      return this.short ? this.shortBody : this.mediumBody
    },
    readMoreText () {
      return this.showTruncatedBody ? '続きを読む' : '隠す'
    },
    needReadMore () {
      return this.truncatedBody !== this.body
    }
  },
  methods: {
    toggleBody () {
      this.showTruncatedBody = !this.showTruncatedBody
    },
    toggleViewEdit () {
      this.viewMode = !this.viewMode
    },
    toggleCommentForm () {
      this.$emit('click-show-comment-form', { commentFormVisible: !this.commentFormVisible, writerName: this.writer.fullName})
    },
  }
}
</script>

<style scoped lang="sass">
@import '~stylesheets/resources'
.commu-comments
  display: flex
  justify-content: space-between
  .my-commu-reply
    box-shadow: 0px 2px 8px #F2EFDE
    background: #FFFFFF
    flex-direction: row
    justify-content: center
    display: flex
    align-items: center
    padding: 9px 8px 5px 9px
    gap: 10px
    height: 100%
    border-radius: 6px
    font-family: 'Hiragino Kaku Gothic ProN'
    font-style: normal
    font-weight: 600
    font-size: 14px
    line-height: 14px
    text-align: center
    color: #2DBA87

.main-content
  display: flex
  margin-top: 6px
  .body
    word-break: break-word
    padding-left: 40px
    font-size: 12px
    flex: 1
  .edit-body
    border-radius: 3px
    border: 1px solid #d7d7d7
    padding: 6px 12px
    resize: none
    font-size: 14px
    width: 100%
  .top-body
    word-break: break-word
    font-size: 12px
    flex: 1
  .image
    width: 110px
    height: 110px
    +app-mobile
      width: 55px
      height: 55px
.read-more
  margin-top: 8px
  padding-left: 40px
</style>
