import {fetchRecents, fetchTopic, fetchEvent, getCommunities} from '../../../apis/co'
import * as MyCommu from '../mycommu'
import * as Filter from '../filter'
import queryString from 'query-string'
import session from '../session'

export default {
  namespaced: true,
  state: {
    page: 2,
    recents: [],
    eventRecents: [],
    topic: {},
    event: {},
    searchResults:[],
    news: {
      id: 1,
      images: [
        { url: '../../../images/samples/unsplash_OtXADkUh3-I.png' },
        { url: '../../../images/samples/unsplash_OtXADkUh3-I.png' }
      ]
    },
    maxRoutes: 3,
    routesActive: 3,
    routes: [
      '/home?type=news',
      '/home?type=event',
      '/home?type=topic',
      '/home'
    ]
  },
  actions: {
    async fetchRecents({dispatch, commit, rootGetters}, {currentCommunity, params, page=1, currentTime, type = ''}) {
      const TopicFilters = rootGetters['filter/getTopics']
      const EventFilters = rootGetters['filter/getEvents']
      dispatch('mycommu/setLoading', true, { root:true })
      let filters = {
        q: params,
        topic_filter: TopicFilters,
        page: page,
        event_filter: EventFilters,
        currentTime: currentTime
      }
      await fetchRecents(session.state.communityId, filters, type).then((res) => {
        const commitKey = filters['q'] !== undefined ? 'newRecents' : 'recents'
        commit(commitKey, res.data.data)
        dispatch('mycommu/setLoading', false, { root:true })
      })
    },

    async fetchRecentsLoadMores({dispatch, commit, rootGetters, state}, {currentCommunity, params, page=1, currentTime}) {
      const TopicFilters = rootGetters['filter/getTopics']
      const EventFilters = rootGetters['filter/getEvents']
      let filters = {
        q: params,
        topic_filter: TopicFilters,
        page: page,
        event_filter: EventFilters,
        currentTime: currentTime
      }
      await fetchRecents(session.state.communityId, filters).then((res) => {
        if(res.data.data.length > 0){

          let data = [...state.recents, ...res.data.data]
          commit('recents', data)
          commit('page', 1)
        }
      })
    },

    fetchTopic({dispatch, commit}, params) {
      dispatch('mycommu/setLoading', true, { root:true })
      fetchTopic(params).then((res) => {
        commit('topic', res.data.data)
        dispatch('mycommu/setLoading', false, { root:true })
      })
    },
    fetchEvent({dispatch, commit}, params) {
      dispatch('mycommu/setLoading', true, { root:true })
      fetchEvent(params).then((res) => {
        commit('event', res.data.data)
        dispatch('mycommu/setLoading', false, { root:true })
      })
    },
    async communitySearch({dispatch, commit, rootGetters}, {currentCommunity, params, page=1, currentTime, type = ''}) {
      const TopicFilters = rootGetters['filter/getTopics']
      const EventFilters = rootGetters['filter/getEvents']
      dispatch('mycommu/setLoading', true, { root:true })
      let filters = {
        q: params,
        topic_filter: TopicFilters,
        page: page,
        event_filter: EventFilters,
        currentTime: currentTime
      }
      await fetchRecents(session.state.communityId, filters, type).then((res) => {
        commit('searchResults', res.data.data)
        dispatch('mycommu/setLoading', false, { root:true })
      })
    },

    changeCommu ({ dispatch, commit, rootGetters }, { params, redirect = false, linkTo = null }) {
      dispatch('mycommu/setLoading', true, { root: true })
      getCommunities(params).then((res) => {
        commit('mycommu/communities', res.data.data, { root: true })
        commit('mycommu/currentCommunity', res.data.currentCommunity, { root: true })
        commit('mycommu/otherCommunities', res.data.otherCommunities, { root: true })
        if (redirect) {
          if (window.location.pathname === '/communities/profile') {
            window.location.href = '/communities/profile'
          } else if (linkTo !== null) {
            window.location.href = linkTo
          } else {
            window.location.href = '/home?community_id=' + params.id
          }
        }
      })
    },
    setRoutes({commit}) {
      const params = queryString.parse(location.search)
      const currentPath = location.pathname
      if(currentPath === '/home')
      {
        if(params['type'] && params['type'] === 'news'){
          commit('setRoutes', 0)
        }
        if(params['type'] && params['type'] === 'event'){
          commit('setRoutes', 1)
        }
        if(params['type'] && params['type'] === 'topic'){
          commit('setRoutes', 2)
        }
      }
    },
    swipPage ({ dispatch, state }, action) {
      const { maxRoutes, routesActive } = state
      let index = routesActive
      if (action === 'left') {
        if (routesActive === 0) {
          index = maxRoutes
        } else {
          index = routesActive - 1
        }
      }
      if (action === 'right') {
        if (routesActive === maxRoutes) {
          index = 0
        } else {
          index = routesActive + 1
        }
      }
      dispatch('mycommu/setLoading', false, { root: true })
      location.href = state.routes[index]
    }
  },

  mutations: {
    recents (state, data) {
      state.recents = data
    },
    newRecents (state, data) {
      state.recents = data
    },
    eventRecents (state, data) {
      state.eventRecents = data
    },
    topic (state, data) {
      state.topic = data
    },
    event (state, data) {
      state.event = data
    },
    searchResults (state, data) {
      state.searchResults = data
    },
    setRoutes(state, index){
      state.routesActive = index
    },
    page(state, index) {
      state.page = state.page + 1
    }
  }
}
