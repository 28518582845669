<template>
  <div class="content-share-posts">
    <div class="description">
      <span>「貸して」内容入力</span>
    </div>

    <div class="post-title">
      <MyCommuBaseTextInput
        id="title"
        v-model="title"
        label="モノの名前"
        name="title"
        placeholder="掃除機"
        :is-require="true"
      />
    </div>

    <div class="columns is-mobile is-centered">
      <div class="column ">
        <MyCommuBaseTextInput
          id="body"
          v-model="body"
          label="メッセージ"
          name="body"
          placeholder="●●●が無くて困っているのですが、
少しの間どなたかお貸しいただけないで
しょうか？"
          type="textarea"
          :is-require="true"
        />
      </div>
    </div>

    <div class="columns is-mobile is-centered no-margin-top">
      <div class="column padding-bottom-8 no-margin-top date-time">
        <div class="flex-between">
          <label>開始日時</label>
          <p>必須</p>
        </div>
        <MyCommuBaseDatePicker
          placeholder="選択する"
          :value="started_at"
          id="started_at"
          :set-z-index="true"
          :date-time="true"
          @valueSelect="valueDateTimeStart"
          title="開始日時を選択してください"
          class="margin-top-8"
          :class="{'started-at-error': error_started_at !== ''}"
        />
        <p v-if="error_started_at !== ''" class="error" v-text="error_started_at"/>
      </div>
    </div>

    <div class="columns is-mobile is-centered no-margin-top">
      <div class="column padding-top-0 no-margin-top date-time">
        <div class="flex-between">
          <label>終了日時</label>
          <p>必須</p>
        </div>
        <MyCommuBaseDatePicker
          placeholder="選択する"
          id="finished_at"
          :value="finished_at"
          :set-z-index="true"
          :date-time="true"
          @valueSelect="valueDateTimeFinish"
          title="終了日時を選択してください"
          class="margin-top-8"
          :class="{'finished-at-error': error_finished_at !== ''}"
        />
        <p v-if="error_finished_at !== ''" class="error" v-text="error_finished_at"/>
      </div>
    </div>

    <div class="columns is-mobile is-centered no-margin-top">
      <div class="column no-padding-bottom">
        <div class="flex-between">
          <label>希望謝礼額 ※無償の場合は0と入力ください</label>
          <p>必須</p>
        </div>
        <div class="input-green entry-fee">
          <input
            v-model="reward"
            placeholder="500"
            type="number"
            class="w-50"
            min="0"
            step="1"
            @keydown="onKeyDown"
          >
          <div class="yen">
            円
          </div>
        </div>
      </div>
    </div>
    <label class="note-small">借していただける方にお礼の気持ちを表す事ができます。</label>

    <div class="columns is-mobile is-centered margin-top-32">
      <div class="column line-black" />
    </div>
    <div class="columns is-mobile is-centered condition-columns">
      <div class="column">
        <span>免責事項への同意</span>
        <br>
        <span>モノの状態については双方が一緒に目視で確認してください。物損・汚損についてはそれぞれの個人賠償責任保険が適用となります。投稿すると免責事項に同意したとみなします。</span>
      </div>
    </div>
    <div class="columns is-mobile is-centered column-accep">
      <div class="column">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
            stroke="#2DBA87"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M9.33001 9.89996C9.30789 9.54162 9.35935 9.18255 9.48126 8.84485C9.60317 8.50715 9.79295 8.19798 10.0389 7.9364C10.2848 7.67482 10.5816 7.46634 10.9112 7.32385C11.2407 7.18136 11.596 7.10791 11.955 7.10791C12.314 7.10791 12.6692 7.18136 12.9988 7.32385C13.3283 7.46634 13.6252 7.67482 13.8712 7.9364C14.1171 8.19798 14.3068 8.50715 14.4287 8.84485C14.5506 9.18255 14.6021 9.54162 14.58 9.89996C14.58 11.9 11.95 11.7 11.95 13.78"
            stroke="#2DBA87"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M12 17.84C12.5081 17.84 12.92 17.4281 12.92 16.92C12.92 16.4119 12.5081 16 12 16C11.4919 16 11.08 16.4119 11.08 16.92C11.08 17.4281 11.4919 17.84 12 17.84Z"
            fill="#2DBA87"
          />
        </svg>

        <span class="text-insurance">保険について</span>
      </div>
    </div>

    <div class="columns is-mobile is-centered column-submit">
      <div class="column">
        <BButton
          type="submit"
          :disabled="checkDisabled"
          class="is-fullwidth is-success btn-submit is-medium"
          @click="componentSubmit"
        >
          確認する
        </BButton>
      </div>
    </div>
  </div>
</template>

<script>
import { format } from 'date-fns'

export default {
  props: {
    submit: {
      type: Function,
      required: true
    },
    objects: {
      type: Object,
      required: true
    }
  },
  data () {
    if (this.objects.type === 'borrowings') {
      return {
        type: 'borrowings',
        title: this.objects.title,
        body: this.objects.body,
        reward: this.objects.reward,
        started_at: this.objects.started_at,
        finished_at: this.objects.finished_at,
        error_started_at: '',
        error_finished_at: '',
      }
    } else {
      return {
        type: 'borrowings',
        title: null,
        body: null,
        started_at: null,
        finished_at: null,
        reward: null,
        error_started_at: '',
        error_finished_at: '',
      }
    }
  },
  computed: {
    checkDisabled () {
      const rewardNumber = /^([1-9][0-9]*|0)$/
      return this.isEmpty(this.title) || this.isEmpty(this.body) || this.error_started_at !== '' || this.error_finished_at !== '' || !rewardNumber.test(this.reward)
    }
  },
  watch: {
    started_at () {
      if (this.finished_at < new Date (this.objects.finished_at) || this.finished_at <= this.started_at || this.started_at < new Date (this.objects.started_at) || this.started_at < new Date ()) {
        this.error_started_at = '日時を修正してください'
      } else {
        this.error_started_at = ''
        this.error_finished_at = ''
      }
    },
    finished_at () {
      if (this.finished_at < new Date (this.objects.finished_at) || this.finished_at <= this.started_at || this.started_at < new Date (this.objects.started_at) || this.finished_at < new Date ()) {
        this.error_finished_at = '日時を修正してください'
      } else {
        this.error_started_at = ''
        this.error_finished_at = ''
      }
    }
  },
  methods: {
    onKeyDown (event) {
      if (event.key === '+' || event.key === '-' || event.key === 'e' || event.key === '.') {
        event.preventDefault()
      }
    },
    displayFile (file) {
      const src = URL.createObjectURL(file)
      return src
    },
    componentSubmit () {
      this.submit(
        {
          type: 'borrowings',
          title: this.title,
          body: this.body,
          started_at: this.started_at,
          finished_at: this.finished_at,
          reward: this.reward
        }
      )
    },
    valueDateTimeStart (data) {
      this.started_at = data
    },
    valueDateTimeFinish (data) {
      this.finished_at = data
    },
    isEmpty (value) {
      if (value !== null && value.trim().length === 0) {
        value = null
      }
      return (value == null || value === '')
    }
  }
}
</script>

<style scoped lang="sass">
.container-share-posts
  font-family: "Hiragino Kaku Gothic ProN"
  padding: 0px 32px
  color: #424242

  .description
    padding-top: 40px
    padding-bottom: 30px
    text-align: center
    span
      font-weight: 600
      font-size: 17px
.switch-custom
  display: flex
  justify-content: right
  align-items: center
.line-black
  border-top: 1px solid #CCCCCC

.content-share-posts
  .description
    padding-bottom: 8px
    padding-top: 20px
    text-align: center
    span
      font-weight: 600
      font-size: 17px
label
  color: #273D35
  font-weight: 400
  font-size: 14px

.file-cta
  height: 48px
  color: #2DBA87 !important
  .icon
    font-size: 18px
    padding: 0
    margin: 0

.columns
  margin-top: 10px
  margin-bottom: 0px !important
  .flex-between
    display: flex
    justify-content: space-between
    align-items: center
    p
      font-size: 10px
      border: 1px solid #EE5353
      color: #EE5353
      background: #FFFFFF
      padding: 2px
      font-weight: 700
      font-style: normal
      line-height: 9px
  .date-time
    .started-at-error,.finished-at-error
      margin-bottom: unset !important
    .error
      color: #EE5353
      font-size: 12px
.post-title
  margin-top: 24px
.is-orange
  color: #F78F54
  font-weight: 700
  font-size: 12px

.switch-columns
  margin-top: 0px
.condition-columns
  span
    font-weight: 600
    font-size: 12px
.column-accep
  .column
    display: flex
    align-items: center
    span
      margin-left: 10px
.container-share-preview
  color: #273D35
  padding: 0px 32px
  .description
    display: flex
    justify-content: center
    margin-top: 40px
    font-weight: 300
    font-size: 15px
    margin-bottom: 25px

  .container-type--images
    margin-top: 8px
    .display-image
      display: flex
      img
        box-shadow: 0px 2px 8px #F2EFDE
        border: 1px solid #7C7C7C
        width: 48px
        height: 48px
        border-radius: 6px
        margin-right: 16px
  .container-share-preview--content
    display: flex
    padding-top: 24px
    flex-direction: column

    &.container-button
      margin-top: 100px
  .container-share-preview--title
    font-weight: 600
    font-size: 14px
  .is-halfwidth
    width: 50%
    margin-right: 10px
  .button
    color: #2DBA87
    font-weight: 700
    font-size: 15px
    height: 48px
    &.is-success
      color: #ffffff
.modal-card-body
  //display: flex
  //justify-content: center
  //align-items: center
  //flex-direction: column
  color: #273D35
  font-size: 15px
  border-radius: 20px
  text-align: center
  padding: 40px 16px
  width: 230px
  img
    margin-bottom: 20px
    height: 66px
    width: 66px

.input-green
  margin-top: 8px
  display: flex
  input, textarea, select
    border-radius: 6px
    height: 48px
    padding: 16px
    font-size: 15px
    color: #273D35
    border: 1px solid #CCCCCC
    caret-color: #2DBA87
    outline: none
    background: white
    font-family: 'Hiragino Kaku Gothic ProN'
    &::placeholder
      font-weight: 300
      font-size: 15px
      line-height: 20px
      color: #AAAAAA
    &:focus
      border: 1px solid #2DBA87
    &::-webkit-scrollbar
      display: none
.entry-fee
  display: flex
  align-items: center
  .yen
    color: #000000
    margin-left: 8px
.event-label,.datepicker-event
  font-weight: 300
  font-size: 14px
  line-height: 14px
  color: #273D35
  display: flex
  justify-content: space-between
  align-items: center
.switch
  margin-left: 10px
.is-medium
  &.submit
    padding-top: 16px
    padding-bottom: 17px
    font-size: 15px
    font-weight: 600
    height: 48px
.no-margin-top
  margin-top: 0
.no-margin-bottom
  margin-bottom: 0
.no-padding-bottom
  padding-bottom: 0
.padding-top-0
  padding-top: 0
.margin-top-8
  margin-top: 8px
.padding-bottom-8
  padding-bottom: 8px
.margin-top-32
  margin-top: 32px
.w-50
  width: 50%

.note-small
  color: #424242
  font-size: 12px
  font-weight: 300
  margin-top: 10px
.text-insurance
  font-weight: 700
  color: #2DBA87
  font-size: 12px
  line-height: 12px
/deep/
  .datepicker
    input
      height: 48px
  .icon-datepicker
    top: 11px !important

</style>
