<template>
  <div>
    <input
      :name="name"
      type="hidden"
      value="0"
    >
    <BCheckboxButton
      v-model="checked"
      :name="name"
      native-value="1"
      :disabled="disabled"
      @input="onChange"
      class="checkbox-button"
      :class="{ checked: checked }"
      :on-click="changeValueByLabel(checked, label)"
    >
      <img :src=iconSrc :alt="label" class="is-mr-1" v-if="iconSrc != ''">
      <span v-text="label" />
    </BCheckboxButton>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: null
    },
    value: {
      type: [Boolean, String],
      default: false
    },
    onChange: {
      type: Function,
      default: () => {}
    },
    disabled: {
      type: Boolean,
      default: false
    },
    iconSrc: {
      type: String,
      default: ''
    }
  },
  data () {
    return { checked: this.value }
  },
  methods: {
    changeValueByLabel (checked, label) {
      if (checked) {
        this.$emit('changeValueByLabel', label)
      }
    }
  }
}
</script>

<style scoped lang="sass">
  .checkbox-button
    /deep/
      span
        font-size: 15px
        line-height: normal
</style>
