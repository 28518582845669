<template>
  <div class="is-mb-3">
    <ShareConciergeCard
      v-if="type === 'supporter-for-my-commu'"
      v-bind="data"
      @close-modal="closeModal()"
    />
    <MyCommuEventCard
      v-if="type === 'event-for-my-commu'"
      v-bind="data"
      @close-modal="closeModal()"
    />
    <MyCommuOrganizationCard
      v-if="type === 'organization-for-my-commu'"
      v-bind="data"
      @close-modal="closeModal()"
    />
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      required: true
    },
    data: {
      type: Object,
      required: true
    }
  },
  methods: {
    closeModal(){
      this.$emit('close-modal')
    }
  },
}
</script>

<style scoped lang="sass">
</style>
