<template>
  <div class="base-select-interested-free-tag">
    <div class="header text-15 is-mb-2">
      <BaseCheckboxButton :onChange="toggleSwitch" :value=isDisplayed :name="checkUseName" :label="label"></BaseCheckboxButton>
    </div>
    <div class="nickname" v-show="isDisplayed">
      <BaseTextInput label='' :name="name" placeholder="興味ある事" :value=inputTag :isMarginless=true :is-my-commu-domain="isMyCommuDomain"></BaseTextInput>
    </div>
  </div>
</template>

<script>
import BaseCheckboxButton from 'components/BaseCheckboxButton'
import BaseTextInput from 'components/BaseTextInput'

export default {
  components: { BaseCheckboxButton, BaseTextInput },
  props: {
    selected: {
      type: Boolean,
      default: false
    },
    tag: {
      type: String,
      default: ''
    },
    isMyCommuDomain: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: 'profile[is_interested_free_tag]'
    },
    checkUseName: {
      type: String,
      default: 'profile[is_use_interested_free_tag]'
    },
    label: {
      type: String,
      default: 'フリータグ'
    }
  },
  data () {
    return { isDisplayed: this.selected, inputTag: this.tag }
  },
  methods: {
    toggleSwitch () {
      this.isDisplayed = !this.isDisplayed
    }
  }
}
</script>
<style scoped lang="sass">
</style>
