<template>
  <section>
    <div class="input-message-box">
      <b-dropdown id="modal">
        <template #trigger>
          <img src="../../images/for_community/camera.png" width="40">
        </template>
        <b-dropdown-item>
          <label for="chat-camera-input" class="is-inline-flex">
            <img src="../../images/for_community/camera.png" width="40">
            <div>写真を撮る</div>
          </label>
          <input
            id="chat-camera-input"
            type="file"
            class="img-upload"
            capture="environment"
            accept="image/*"
            @change="uploadFile"
          >
        </b-dropdown-item>
        <b-dropdown-item>
          <label for="chat-file-input" class="is-inline-flex">
            <img src="../../images/for_community/gallery.png" width="40">
            <div>アルバムから選択</div>
          </label>
          <input
            id="chat-file-input"
            type="file"
            class="img-upload"
            accept="image/*"
            @change="uploadFile"
          >
        </b-dropdown-item>
      </b-dropdown>
      <textarea v-model="message" placeholder="コメントする"></textarea>
      <button :class="{'active': !isDisable}" @click="submit">送信</button>
    </div>
  </section>
</template>

<script>

import { mapActions, mapState } from 'vuex'

export default {
  components: {
  },
  props: {
  },
  data () {
    return {
      message: '',
      isImageModalActive: true,
      isCardModalActive: true
    }
  },
  computed: {
    isDisable () {
      return this.message === ''
    }
  },
  created () {
  },
  methods: {
    submit () {
      if (!this.isDisable) {
        this.$emit('submit', this.message)
        this.message = ''
      }
    },
    uploadFile ({ target }) {
      if (!window.confirm('画像を送信します。よろしいですか？')) {
        return
      }

      const {
        files: [file]
      } = target
      if (!file) return
      this.$emit('uploadFile', file)
      target.value = null
      document.querySelector('#modal').classList.remove('is-active')
      document.querySelectorAll('#modal .background')[0].style.display = 'none'
    }
  }
}
</script>

<style lang="sass">
  .input-message-box
    background: #fff
    padding: 8px 8px 42px 16px
    display: flex
    justify-content: space-between
    align-items: center
    img
      width: 24px
      height: 24px
      margin-right: 16px
    textarea
      padding: 16px
      resize: none
      border: 1px solid #CCCCCC
      border-radius: 6px
      background: #FFFFFF
      font-size: 15px
      line-height: 15px
      height: 48px
      font-style: normal
      font-weight: 300
      color: #273D35
      caret-color: #2DBA87
      margin-right: 8px
      width: 100%
      outline: none
      &::placeholder
        color: #AAAAAA
      &:focus
        border: 1px solid #2DBA87
        border-radius: 6px
      &::-webkit-scrollbar
        display: none

    button
      min-width: 60px
      min-height: 48px
      background: #2DBA87
      border-radius: 6px
      opacity: 0.4
      color: #FFFFFF
      border: 0
      font-weight: 600
      font-size: 14px
      line-height: 14px
      &.active
        opacity: 1
  .dropdown
    .background
      background: unset !important
  @media screen and (max-width: 1022px)
    .input-message-box .dropdown.is-mobile-modal .dropdown-menu
      top: -105px !important
      max-height: unset
      position: absolute !important
      left: 0 !important
      transform: unset !important
      background: unset !important
      width: 179px !important
      border: 1px solid #AAAAAA
      box-shadow: 0 2px 7px rgba(0, 0, 0, 0.3)
      border-radius: 8px
      padding-top: unset
      overflow: unset
      .dropdown-content
        background: #FFFFFF
        box-shadow: unset
        border-radius: 8px
        padding: 16px
        .dropdown-item
          padding: unset
          font-style: normal
          font-weight: 300
          font-size: 14px
          color: #273D35
          display: inline-flex
          align-items: end
  .img-upload
    position: absolute
    left: 0
    z-index: 2
    opacity: 0
  .is-mobile-modal
    margin-right: 12px
</style>
