<template>
  <div>
    <!-- <div
    class="qr-row"
    >
      <MyCommuBaseButton
        class="qr-button"
        :is-white-button="isWhiteButton"
        :text-content="titleQrButton"
        @clickButton="redirectToQrScan"
      />
    </div> -->
    <div class="topbar" v-if="!myself">
      <div class="topbar-icon-back" @click="back()">
        <svg width="21" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M16.0697 3.8501L7.92969 12.0001L16.0697 20.1501" stroke="#2DBA87" stroke-width="2"
                stroke-linecap="round" stroke-linejoin="round">
          </path>
        </svg>
      </div>
      <div class="topbar-title">
        <span v-if="myself">{{ getName }}</span>
        <span v-else>{{ titleHeaderViewer }}</span>
      </div>
      <div>
        <b-dropdown aria-role="list" can-close="true" class="action-chat">
          <template #trigger>
            <img src="../../images/icons/three_dots.png" width="24">
          </template>
          <b-dropdown-item aria-role="listitem">
            <span class="icon">
              <img src="../../images/icons/report.svg">
            </span>
            <p>通報する</p>
          </b-dropdown-item>
          <b-dropdown-item aria-role="listitem" class="dropdown-last" @click="blockUser()">
            <span class="icon">
              <img src="../../images/icons/block_chat.svg">
            </span>
            <p>ブロックする</p>
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
    <div
        class="card-profile"
        :class="{'set-pd-t': !myself}"
    >
      <MyCommuHiddenButtonFooter :is-my-commu-domain="true"/>
      <div v-if="!myself" class="action">
        <MyCommuBaseButton
            class="mr-7 add-friend"
            :is-white-button="isWhiteButton"
            :text-content="titleButtonFriend"
            @clickButton="openOtpModal"
        />
        <MyCommuBaseButton
            v-if="lvFriendship === 3"
            :icon="circleCheckBlack"
            :custom-class="colorBlack"
            class="mr-7 add-friend"
            :is-white-button="isWhiteButton"
            text-content="友達"
        />
        <MyCommuBaseButton
            :icon="isFollower ? circleCheckBlack : ''"
            :custom-class="isFollower ? colorBlack : ''"
            class="message"
            :is-white-button="isWhiteButton"
            text-content="メッセージを送る"
            @clickButton="message"
        />
      </div>
      <MyCommuBaseButton
          v-if="!myself && (lvFriendship === 3) || isFollower"
          class="message mt-8"
          :is-white-button="isWhiteButton"
          text-content="メッセージを送る"
          @clickButton="message"
      />
      <div v-if="!myself && lvFriendship !== 3" @click="questionModal = true" class="question mt-16">
        <img src="../../images/icons/question-green.png" width="24" height="24" class="mr-8">
        <span>友達・フォローについて</span>
      </div>
      <div class="info-profile" :class="{'mt-24': !myself}"
      >
        <MyCommuSelfInformation
            :profile="profile"
            :topic-count="topicCount"
            :like-count="likeCount"
            :join-event-count="joinEventCount"
            :join-group-count="joinGroupCount"
            :supporter="supporter"
            :lv_max="isLevelMax"
            :myself="myself"
            :joined-event-count="joinEventCount"
            :create-event-count="createEventCount"
            :count-send="countSend"
            :count-receives="countReceives"
            :current-community="currentCommunity.community_id"
        />
        <MyCommuBaseButton
            v-if="myself"
            class="mt-16 px-16"
            :is-white-button="isWhiteButton"
            text-content="自己紹介を編集する"
            @clickButton="redirectToEdit"
        />
      </div>
      <div class="info-my-self">
        <p class="f-size-14 color-base px-16 font-weight-bold" v-text="`${currentCommunity.community_name}用自己紹介`"/>
        <div class="introduction mx-16">
          <div class="overview">
        <ul class="color-base d-flex mt-16"
            :class="{'suporter': supporter && myself}"
        >
          <li>
            <p class="font-weight-bold f-size-16">{{ topicCount }}</p>
            <p class="f-size-14 mt-16">投稿数</p>
          </li>
          <li>
            <p class="font-weight-bold f-size-16">{{likeCount}}</p>
            <p class="f-size-14 mt-16">いいね数</p>
          </li>
          <li>
            <p class="font-weight-bold f-size-16">{{joinEventCount}}</p>
            <p class="f-size-12 mt-4">イベント</p>
            <p class="f-size-12">参加回数</p>
          </li>
          <li>
            <p class="font-weight-bold f-size-16">{{ joinGroupCount }}</p>
            <p class="f-size-12 mt-4">グループ</p>
            <p class="f-size-12">参加数</p>
          </li>
        </ul>
      </div>
      <div v-if="supporter && myself" class="share">
        <ul>
          <li>
            <p>イベント実績</p>
          </li>
          <li>
            <p class="font-weight-bold f-size-16 color-base">{{joinedEventCount}}</p>
            <p class="mt-16">依頼数</p>
          </li>
          <li>
            <p class="font-weight-bold f-size-16 color-base">{{createEventCount}}</p>
            <p class="mt-16">支援数</p>
          </li>
        </ul>
      </div>
      <div class="share">
        <ul>
          <li>
            <p>シェアリング実績</p>
          </li>
          <li>
            <p class="font-weight-bold f-size-16 color-base">{{ countSend }}</p>
            <p class="mt-16">依頼数</p>
          </li>
          <li>
            <p class="font-weight-bold f-size-16 color-base">{{ countReceives }}</p>
            <p class="mt-16">支援数</p>
          </li>
        </ul>
      </div>
      <div class="home-town f-size-14 color-base base-block overview">
        <p>出身地</p>
        <div class="detail">
          <p>{{profile.placeOfBirth}}</p>
            <img src="../../images/icons/arr_right.svg">
        </div>
      </div>
      <div class="profession f-size-14 color-base base-block">
        <p>職業</p>
        <p>{{profile.profession || '記入なし'}}</p>
      </div>
      <div class="hobby-skin f-size-14 color-base base-block">
        <p>趣味・特技</p>
        <p>{{profile.hobby_and_skills || '記入なし'}}</p>
      </div>
      <div class="interested">
        <p class="f-size-14 color-base">興味あること</p>
        <div class="label-interested">
          <MyCommuInterested
            v-for="interested in profile.interested"
            v-if="interested.check"
            :label="interested.label"
            :icon-src="interested.url_icon"
          />
        </div>
      </div>
        </div>
      </div>
      <a href="/communities/profile/edit">
        <div class="bg-white mt-16">
          <div class="overview">
            <img class="profile-detail-icon" src="../../images/icons/profile-detail-icon.svg">
            <p>アカウント情報・基本情報</p>
          </div>
          <img class="px-16" src="../../images/icons/arr_right.svg">
        </div>
      </a>
      <a :href="`/communities/${currentCommunity.community_id}/myfriend`">
        <div class="bg-white">
          <div class="overview">
            <img class="px-16" src="../../images/icons/friend-list-icon.svg">
            <p>友達・フォロワーリスト</p>
          </div>
          <img class="px-16" src="../../images/icons/arr_right.svg">
        </div>
      </a>
      <a :href="`/communities/${currentCommunity.community_id}/blocklist`">
        <div class="bg-white">
          <div class="overview">
            <img class="px-16" src="../../images/icons/block_list.svg">
            <p>ブロックリスト</p>
          </div>
          <img class="px-16" src="../../images/icons/arr_right.svg">
        </div>
      </a>
      <a href="#">
        <div class="bg-white">
          <div class="overview">
            <img class="px-16" src="../../images/icons/user-sharing-icon.svg">
            <p>シェア履歴</p>
          </div>
          <img class="px-16" src="../../images/icons/arr_right.svg">
        </div>
      </a>
      <div class="info-commu mx-16">
        <p class="f-size-14 color-base font-weight-bold">参加中のコミュニティ・グループ</p>
        <MyCommuMyCommunities
            v-if="check_present(communities)"
            class="mt-16"
            :communities="communities"
        />
        <MyCommuBaseButton
            v-if="myself"
            class="mt-16 mb-8"
            :is-white-button="isWhiteButton"
            text-content="編集する"
            @clickButton="redirect('/communities')"
        />
      </div>
      <MyCommuBaseButton
          v-if="myself"
          class="mt-40 mb-8 px-16"
          :is-red-button="isRedButton"
          text-content="ログアウト"
          @clickButton="signOut()"
      />
      <MyCommuBaseButton
        v-if="canLeave"
        :is-red-button="isRedButton"
        text-content="退会する"
        class="mt-40 mb-8 px-16"
        @clickButton="remove()"
      />
      <b-modal
        :active.sync="isOpenPopupRemoveUser"
        has-modal-card
        trap-focus
        :destroy-on-hide="false"
        aria-role="dialog"
        aria-label="Example Modal"
        close-button-aria-label="Close"
        aria-modal
        class="remove-user"
        width="74%"
      >
        <div class="modal-card">
          <header class="modal-card-head">
            <p>退会後はログインできなくなってしまいます。それでもよろしければ、以下のボタンをクリックしてください。</p>
          </header>
          <div class="modal-card-body">
            <div
              class="card-left"
              @click="isOpenPopupRemoveUser = false"
            >
              <a href="#">キャンセル</a>
            </div>
            <div class="card-right">
              <p @click="confirmDelete()">
                削除する
              </p>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
    <b-modal
        :active.sync="titleHeader.isClick"
        has-modal-card
        trap-focus
        :destroy-on-hide="false"
        aria-role="dialog"
        aria-label="Example Modal"
        close-button-aria-label="Close"
        aria-modal
        class="preview-modal"
    >
      <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
          <img @click="closePrevew" src="../../images/icons/close.svg">
          <p class="f-size-16 font-weight-bold color-base">マイページプレビュー</p>
          <div class="w-24"></div>
        </header>
        <section class="modal-card-body">
          <MyCommuChatTap
              first-tap="一般ユーザー"
              second-tap="送迎・託児OKの友達"
              @changeTab="getActiveTab"
          />
          <div class="body-profile">
            <p v-if="firstTap" class="mt-24 f-size-15 text-center">他の人から見えるあなたのマイページ画面です。</p>
            <p v-else class="mt-24 f-size-15 text-center">
              送迎・託児OKの友達から見えるあなたの
              <br>
              マイページ画面です。
            </p>
            <div class="action mt-24">
              <MyCommuBaseButton
                  v-if="firstTap"
                  class="mr-7 add-friend"
                  :is-white-button="isWhiteButton"
                  text-content="友達になる"
              />
              <MyCommuBaseButton
                  class="message"
                  :class="{'w-100': secondTab}"
                  :is-white-button="isWhiteButton"
                  text-content="メッセージを送る"
              />
            </div>
            <MyCommuInformation
                :profile="profile"
                :topic-count="topicCount"
                :like-count="likeCount"
                :join-event-count="joinEventCount"
                :join-group-count="joinGroupCount"
                :supporter="supporter"
                :first-tap="firstTap"
                :lv_max="secondTab"
                :joined-event-count="joinEventCount"
                :create-event-count="createEventCount"
                :count-send="countSend"
                :count-receives="countReceives"
                class="mt-24 px-16"
            />
            <div v-if="secondTab" class="info-children mt-40">
              <p class="f-size-14 color-base font-weight-bold">子どもの情報</p>
              <MyCommuUsersChildren class="mt-16"
                                    v-if="check_present(usersChildren)"
                                    :users-children="usersChildren"
                                    :myself="myself"
                                    :preview="true"
              />
            </div>
            <div class="info-commu mt-40">
              <p class="f-size-14 color-base font-weight-bold">参加中のコミュニティ・グループ</p>
              <MyCommuMyCommunities
                  class="mt-16"
                  :communities="communities"
                  v-if="check_present(communities)"
              />
            </div>
          </div>
        </section>
      </div>
    </b-modal>
    <b-modal
        :active.sync="isOpenOtpModal"
        has-modal-card
        trap-focus
        :destroy-on-hide="false"
        aria-role="dialog"
        aria-label="Example Modal"
        close-button-aria-label="Close"
        aria-modal
        class="user-modal"
    >
      <div
          class="modal-card"
          style="width: auto"
      >
        <section class="modal-card-body card-add-friend">
          <div class="add-friend">
            <img
                src="../../images/icons/arr_left.svg"
                @click="closeOtpModal"
            >
            <strong><p>電話番号の下4桁を入力する</p></strong>
            <div class="div-fake"/>
          </div>
          <div
              v-if="noPhoneText"
              class="set-mb-error error-text-wrapper"
          >相手の電話番号が現在登録されていません。 相手に番号の登録を依頼しますので、登録完 了までお待ち下さい。番号が登録されましたら お知らせいたします。
          </div>
          <div
              v-if="wrongAuthText"
              class="set-mb-error error-text-wrapper"
          >相手の電話番号下4桁と一致しません
          </div>
          <div class="form-number-phone">
            <MyCommuOtp
              ref="otpInput"
              input-classes="otp-input"
              :input-classes="this.inputError ? 'input-error' : ''"
              :num-inputs="4"
              :should-auto-focus="true"
              :is-input-num="true"
              @on-complete="handleOnComplete"
              @on-change="handleOnChange"
              :value="this.keyPhone.split('')"
            />
          </div>
          <div class="set-pb-p">友達になるユーザーの電話番号下4桁を入力してください</div>
          <div
              v-if="noPhoneText"
              id="goback"
              onclick="window.history.back()"
          >
            <MyCommuBaseButton
              text-content="閉じる"
              :is-white-button="isWhiteButton"
            />
          </div>
          <div
            v-else
            id="sendData"
            @click="sendFriendRequest"
          >
            <MyCommuBaseButton
              text-content="次へ"
              :disable="!checkDisabled"
            />
          </div>
        </section>
      </div>
    </b-modal>
    <b-modal
        :active.sync="questionModal"
        has-modal-card
        custom-class="question-modal"
        :can-cancel="true"
    >
      <div class="question-content">
        <div class="modal-card" style="width: auto">
          <div class="modal-card-body">
            <div class="text-default">
              マイコミュで、実際に面職のある知<br>
              り合いを見つけたら友達になりま<br>
              しょう。 安心・安全のため、対面で<br>
              は二次元コード認証、非対面では電<br>
              話番号の下4桁認証があります。<br>
              <MyCommuBaseButton
                  class="mt-16 mb-8"
                  text-content="友達になる"
                  @clickButton="openOtpModal"
              />
              フォローすると、投稿の通知がきた
              りメッセージが送れるようになりま
              す。
              <MyCommuBaseButton
                  class="mt-16 mb-8"
                  text-content="フォローする"
                  @clickButton="follow"
              />
              <p
                  class="cancel mt-24"
                  @click="questionModal = false"
              >
                閉じる
              </p>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import axios from '../../utils/axios'
import { commuSuccess, commuError } from '../../lib/app-toast'

export default {
  data () {
    return {
      isWhiteButton: true,
      isRedButton: true,
      activeTab: 'first-tab',
      isLevelMax: this.lvFriendship === 3,
      circleCheckBlack: "<svg width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"white\" xmlns=\"http://www.w3.org/2000/svg\"> " +
        "<path d=\"M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z\" stroke=\"#273D35\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/> " +
        "<path d=\"M7.98047 13.16L11.1305 15.5L16.0205 8.5\" stroke=\"#273D35\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/> " +
        "</svg>",
      colorBlack: 'color-black',
      titleButtonFriend: '友達になる',
      titleHeaderViewer: '一般ユーザー',
      titleQrButton: '二次元コード',
      questionModal: false,
      keyPhone: '',
      isOpenOtpModal: false,
      inputError: false,
      noPhoneText: false,
      wrongAuthText: false,
      activeTab: 'first-tab',
      isOpenPopupRemoveUser: false
    }
  },
  props: {
    userId: {
      type: Number,
      default: null
    },
    profile: {
      type: Object,
      require: true,
      default: null
    },
    topicCount: {
      type: Number,
      default: 0
    },
    likeCount: {
      type: Number,
      default: 0
    },
    joinEventCount: {
      type: Number,
      default: 0
    },
    joinGroupCount: {
      type: Number,
      default: 0
    },
    supporter: {
      type: Boolean,
      default: false
    },
    myself: {
      type: Boolean,
      default: true
    },
    communityIntroductions: {
      type: Array,
      require: true
    },
    communities: {
      type: Array,
      default: () => []
    },
    usersChildren: {
      type: Array,
      default: () => []
    },
    lvFriendship: {
      type: Number,
      default: 0
    },
    licences: {
      type: Array,
      default: () => []
    },
    joinedEventCount: {
      type: Number,
      default: 0
    },
    createEventCount: {
      type: Number,
      default: 0
    },
    countSend: {
      type: Number,
      default: 0
    },
    countReceives: {
      type: Number,
      default: 0
    },
    isFollower: {
      type: Boolean,
      default: false
    },
    isChange: {
      type: Boolean,
      default: false
    },
    navPaths: {
      type: Object,
      required: true
    },
    canLeave: {
      type: Boolean,
      required: true
    },
    mobileApp: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapState('mycommu', ['searchUsersResult', 'searched', 'titleHeader', 'hiddenHeader', 'currentCommunity']),
    ...mapState('space', ['unsetMargin', 'unsetPadding']),
    ...mapState('session', ['communityId']),
    getName () {
      return this.profile.fullName
    },
    firstTap () {
      return this.activeTab === 'first-tab'
    },
    secondTab () {
      return this.activeTab === 'second-tab'
    },
    checkDisabled () {
      return this.keyPhone.length === 4
    }
  },
  created () {
    if (this.myself) {
      this.toggleShowChangeCommu(true)
      this.titleHeader.display = true
      this.titleHeader.content = 'マイページ'
      this.titleHeader.rightText = 'プレビューを見る'
      this.hiddenHeader.tabGroup = true
      this.hiddenHeader.searchTop = true
      this.hiddenHeader.searchBottom = true
      this.hiddenHeader.topBar = false
      this.hiddenHeader.mainContainerUnsetMargin = true
      this.hiddenHeader.notificationAndMessage = true
      this.setTitleHeader(this.titleHeader)
      this.setHiddenHeader(this.hiddenHeader)
    }
    this.unsetMargin.mainContainer = true
  },
  mounted () {
    // this.unsetMargin.mainContainer = true
    // this.unsetMargin.appMain = true
    this.unsetPadding.mainContainer = true
    if (this.profile.isFriend) {
      this.titleButtonFriend = '友達'
    } else if (this.profile.sentFriendRequest) {
      this.titleButtonFriend = '友達申請中'
    }
    if (this.profile.isFriend) {
      this.titleHeader = '友達ユーザー'
    } else if (this.isFollower) {
      this.titleHeader = 'フォロワー'
    }
  },
  methods: {
    ...mapActions('mycommu', [
      'toggleIsFindUser',
      'setTitleHeader',
      'setHiddenHeader',
      'toggleShowChangeCommu'
    ]),
    redirectToQrScan () {
      window.location.href = '/communities/profile/qr_scanner'
    },
    getActiveTab (tab) {
      this.activeTab = tab
    },
    check_present (array) {
      return array.length > 0
    },
    blockUser () {
    },
    handleOnComplete (value) {
      this.keyPhone = value
    },
    handleOnChange (value) {
      this.keyPhone = value
    },
    back () {
      window.location.href = document.referrer
    },
    getActiveTab (params) {
      this.activeTab = params
    },
    closePrevew () {
      this.titleHeader.isClick = false
    },
    redirect (url) {
      window.location.href = url
    },
    signOut () {
      axios.delete('/users/sign_out').then(() => {
        window.location.href = '/'
      })
    },
    redirectToEdit () {
      if (this.profile.id) {
        window.location.href = `/communities/${this.currentCommunity.community_id}/community_profiles/${this.profile.id}/edit`
      } else {
        axios.post(`/api/v1/co/communities/${this.currentCommunity.community_id}/join_community`).then((res) => {
          if (res.data.status === 'success') {
            window.location.href = `/communities/${res.data.community_id}/community_profiles/${res.data.community_profile_id}/edit`
          }
        })
      }
    },
    message () {
      window.location.href = `/communities/chats/${this.userId}/check_message`
    },
    openOtpModal () {
      if (this.profile.sentFriendRequest) return
      this.questionModal = false
      this.isOpenOtpModal = true
    },
    closeOtpModal () {
      this.isOpenOtpModal = false
    },
    sendFriendRequest () {
      if (!this.profile.sentFriendRequest) {
        const data = new FormData()
        data.append('friendship[auth_key]', this.keyPhone)
        axios.post(`/communities/notifications/${this.userId}/send_friend_request`, data).then((res) => {
          if (res.data.status === 'success') {
            commuSuccess('友達を申請しました。')
            window.location.reload()
          } else if (res.data.status === 'sented') {
            commuError('フォローが失敗しました。')
            this.isOpenOtpModal = false
            this.inputError = false
            this.wrongAuthText = false
            this.noPhoneText = false
          } else if (res.data.status === 'auth_failed') {
            this.wrongAuthText = true
            this.inputError = true
          } else if (res.data.status === 'receiver_does_not_have_phone_number') {
            this.noPhoneText = true
            this.inputError = true
          }
        })
      }
    },
    follow () {
      const data = new FormData()
      data.append('user_id', this.userId)
      if (!this.isFollower) {
        axios.post(`/communities/${this.communityId}/follows/follow`, data).then((res) => {
          if (res.data) {
            commuSuccess('フォローが成功しました。')
            this.isFollower = !this.isFollower
          } else {
            commuError('フォローが失敗しました。')
          }
          this.questionModal = false
        })
      }
    },
    confirmDelete () {
      if (window.confirm('退会します。本当によろしいですか？')) {
        const params = new FormData()
        axios.post('/users/leave', params).then((res) => {
          if (res.data.status) {
            commuSuccess('退会手続きが完了しました')
            if (!this.mobileApp) {
              window.open('https://docs.google.com/forms/d/e/1FAIpQLSe33qOMVYcplyBZjgiYQ3LB6B6H68c8A4rA4eYr79hXWwSkxQ/viewform', '_blank')
            }
            window.location.href = res.data.redirect_to
          } else {
            commuError('error')
            window.location.href = '/'
          }
        })
      } else {
        this.isOpenPopupRemoveUser = false
      }
    },
    remove () {
      this.isOpenPopupRemoveUser = true
    },
  }
}
</script>
<style scoped lang="sass">
.qr-row
  display: flex
  justify-content: flex-end
/deep/
  .qr-button
    margin-right: 17px
    width: 30%
    height: 14px
    button
      height: 30px
      padding: 0 10px
.profile-detail-icon
  padding-left: 16px
  padding-right: 22px
.mx-16
  margin: 0px 16px
.px-16
  padding: 0 16px
.bg-white
  display: flex
  align-items: center
  background-color: white
  justify-content: space-between
  height: 58px
  width: 100%
.share
  ul
    width: 100%
    display: flex
    border-bottom: 1px solid #cccccc
    li
      width: 25%
      height: 87px
      display: flex
      flex-direction: column
      justify-content: center
      align-items: center
      &:first-child
        width: 50%
        flex-direction: row
        justify-content: start
        margin-left: 20px
.base-block
  padding: 0 20px
  display: flex
  align-items: center
  justify-content: space-between
  height: 55px
  border-bottom: 1px solid #cccccc
.home-town
  .detail
    display: flex
    p
      margin-right: 8px
.interested
  padding: 20px 20px 24px 20px
  .label-interested
    display: flex
    flex-wrap: wrap
    /deep/ .base-interested
      margin-top: 8px
.overview
  width: 100%
  display: flex
  ul
    width: 100%
    display: flex
    border-top: 1px solid #cccccc
    border-bottom: 1px solid #cccccc
    li
      width: 25%
      border-right: 1px solid #cccccc
      height: 87px
      display: flex
      flex-direction: column
      justify-content: center
      align-items: center
      &:last-child
        border: none
.introduction
  margin-top: 10px
  background: #FFFFFF
  border: 1px solid #CCCCCC
  border-radius: 12px
  word-break: break-word
  .p-introduction
    padding: 15px
.community-name
  font-size: 15px
  padding-top: 12px
  font-weight: bold
.introduction-dialog
  font-size: 12px
  text-align: center
  color: #7C7C7C
  padding: 15px 0px
/deep/
.input-error
  border: 1px solid #EE5353 !important

.user-modal
  flex-direction: column
  justify-content: flex-end

  /deep/
  .modal-background
    background: #273D35
    opacity: 0.9

    .modal-card
      height: 65vh
      border-radius: 10px 10px 0px 0px

      .modal-card-body
        .user-avatar
          padding-top: 8px
          display: flex
          padding-bottom: 24px
          border-bottom: 1px solid #CCCCCC

          img
            width: 48px
            height: 48px
            border-radius: 50%
            object-fit: cover
            flex-shrink: 0
            margin-right: 8px

          .card-body
            display: flex
            flex-direction: column
            justify-content: center

        .mail, .friendship, .report, .block-user, .unfriend
          display: flex
          padding: 16px 0
          border-bottom: 1px solid #CCCCCC

          .icon
            margin-right: 18px

        .unfriend
          border-bottom: none

          .block-fake
            width: 24px
            margin-right: 18px

      .card-add-friend
        padding: 27px 32px 32px 32px

        .add-friend
          display: flex
          align-items: center
          justify-content: space-between
          margin-bottom: 37px

          .div-fake
            width: 24px

        .form-number-phone
          display: flex
          justify-content: center

    .modal-close
      display: none !important

.f-size-12
  font-size: 12px

.f-size-14
  font-size: 14px

.f-size-15
  font-size: 15px

.f-size-16
  font-size: 16px

.color-gray
  color: #7C7C7C

.color-base
  color: #273D35

.font-weight-bold
  font-weight: bold

.mt-8
  margin-top: 8px

.mr-8
  margin-right: 8px

.mt-16
  margin-top: 16px

.mt-24
  margin-top: 24px

.mt-40
  margin-top: 40px

.mb-8
  margin-bottom: 8px

.mr-7
  margin-right: 7px

.w-24
  width: 24px

.text-center
  text-align: center

.card-profile
  padding: 24px 0px 32px

  .info-my-self, .info-children, .info-commu, .qualifications
    margin-top: 40px

  .action
    display: flex

    .add-friend, .message
      width: calc((100% - 7px) / 2)

.topbar
  display: flex
  padding: 13px 15px 4px 15px
  background-color: white
  position: fixed
  width: 100%
  z-index: 99

  .topbar-title
    color: #273D35
    font-weight: bold
    font-size: 16px
    text-align: center
    width: 100%
    justify-content: center

  .action-chat
    /deep/
    .dropdown-menu
      min-width: 157px
      width: unset !important
      top: 65px !important
      left: auto !important
      border: 1px solid #AAAAAA
      box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.3)
      border-radius: 8px
      padding: unset
      transform: translate3d(0%, -25%, 0)
      right: 35px !important

      .dropdown-content
        padding: unset

        .dropdown-item
          padding: 1rem 1rem 0rem 1rem
          display: flex

          span
            color: #FFFFFF

          p
            color: #273D35
            margin-left: 10px
            font-weight: 300
            font-size: 14px
            display: flex
            align-items: center

        .dropdown-last
          padding-bottom: 1rem

.set-pd-t
  padding-top: 78px

.error-text-wrapper
  border: 1px solid #EE5353
  border-radius: 10px
  background-color: #FFEEF0
  padding: 10px
  color: #EE5353
  text-align: center

.set-pt-p
  padding-top: 20px

.set-pb-p
  padding-bottom: 20px

.set-mb-error
  margin-bottom: 30px

.question
  display: flex
  align-items: center
  color: #2DBA87
  font-weight: bold
  font-size: 12px

.question-modal
  /deep/
  .modal-close
    display: none

    .modal-background
      background: #273D35
      opacity: 0.9

  .question-content
    width: 80%
    background: #FFFFFF
    margin: auto
    text-align: center
    font-weight: 300
    font-size: 14px
    color: #273D35

    .cancel
      color: #2DBA87
      font-weight: 600

  .modal-card
    border-radius: 20px

    .content
      text-align: center
      font-size: 14px
      font-weight: 300

    .modal-card-footer
      line-height: 0
      display: inline-flex
      grid-template-columns: auto auto

      .button
        height: 48px
        min-width: 50%
        background: #fff
        color: #2DBA87
        border-radius: 0
        font-size: 15px

        &:focus-visible
          border: none

      .ok
        font-weight: bold
        border-left: none

.preview-modal
  justify-content: end
  background-color: #D6D9DD

  /deep/
  .modal-background
    background: #000000
    opacity: 0.3

    .modal-card
      height: calc(100vh - 10px)
      max-height: unset !important

      .modal-card-head
        border-top-left-radius: 10px
        border-top-right-radius: 10px
        padding: 23px 16px 13px 16px
        justify-content: space-between
        background-color: #FFFFFF

      .modal-card-body
        background-color: #F8F7F2
        padding: 16px 0px

        .body-profile
          padding: 0 16px

          .action
            display: flex

            .add-friend, .message
              width: calc((100% - 7px) / 2)

            .w-100
              width: 100%

    .modal-close
      display: none !important

.remove-user
  /deep/
    .modal-background
      background: #273D35
      opacity: 0.9
    .modal-card
      border-radius: 20px
      .modal-card-head
        background-color: #FFFFFF
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        padding: 32px 20px
        p
          color: #273D35
          font-size: 14px
      .modal-card-body
        display: flex
        padding: unset
      .card-left, .card-right
        width: 50%
        padding: 20px 0
        display: flex
        justify-content: center
        a
          font-size: 15px
      .card-left
        border-right: 1px solid #cccccc
        a
          color: #2DBA87
      .card-right
        color: #EE5353
        font-weight: bold
    .modal-close
      display: none
</style>
