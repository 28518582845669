<template>
  <div>
    <MyCommuUserIndex v-if="(!searched || noResultInUser) && currentTab == 'first-tab'" :no-result="noResultInUser"
      text="名前かメールアドレスで</br>マイコミュ内のユーザーを</br>検索できます。"
      notFound="該当するユーザーが</br>見つかりませんでした。"
    />
    <MyCommuUserIndex v-if="(!searched || noResultInSupporter) && currentTab == 'second-tab'" :no-result="noResultInSupporter"
      text="マイコミュ内のシェア・コンシェルジュを </br>検索できます。"
      notFound="該当するユーザーが</br>見つかりませんでした。"
    />
    <div class="count-users" v-if="searchUsersResult.count_user && currentTab == 'first-tab'">{{ searchUsersResult.count_user }}人のユーザーが見つかりました。</div>
    <div class="count-users" v-if="searchUsersResult.count_supporter && currentTab == 'second-tab'">{{ searchUsersResult.count_supporter }}人のシェア・コンシェルジュが見つかりました。</div>

    <div v-if="(searched && !noResultInUser) && currentTab == 'first-tab'" class="border-bottom-1-ccc">
      <div v-for="(user, index) in searchUsersResult.users" :key="user.id">
        <MyCommuUserCard
          v-if="!user.supporter"
          :id="user.id"
          :avatar-path="user.communityAvatarPath"
          :name="user.commuNickname"
          :content="user.introduction"
          :is-first="index === 0"
        />
      </div>
    </div>
    <div v-if="(searched && !noResultInSupporter) && currentTab == 'second-tab'" class="border-bottom-1-ccc">
      <div v-for="(user, index) in searchUsersResult.users" :key="user.id">
        <MyCommuUserCard
          v-if="user.supporter"
          :id="user.id"
          :avatar-path="user.communityAvatarPath"
          :name="user.commuNickname"
          :content="user.introduction"
          :is-first="index === 0"
          :isSupporter="user.supporter"
          :isAdmin="user.admin"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  components: {
  },
  props: {
  },
  computed: {
    ...mapState('mycommu', ['searchUsersResult', 'searched', 'titleHeader', 'currentTab', 'hiddenHeader']),
    noResultInUser () {
      return this.searchUsersResult.count_user === 0
    },
    noResultInSupporter () {
      return this.searchUsersResult.count_supporter === 0
    }
  },
  created () {
    this.toggleShowSearch(true)
    this.toggleIsFindUser(true)
    this.titleHeader.display = true
    this.titleHeader.content = 'ユーザー検索'
    this.hiddenHeader.notificationAndMessage = true
    this.hiddenHeader.topBar = true
    this.setTitleHeader(this.titleHeader)
    this.setHiddenHeader(this.hiddenHeader)
  },
  methods: {
    ...mapActions('mycommu', [
      'toggleIsFindUser',
      'setTitleHeader',
      'setHiddenHeader',
      'toggleShowSearch'
    ])
  }
}
</script>
<style scoped lang="sass">
  .count-users
    font-size: 15px
    margin: 18px 16px 24px
  .border-bottom-1-ccc
    border-bottom: 1px solid #CCCCCC
</style>
