<template>
  <div class="event-detail">
    <div class="image-preview">
      <img :src="event.image1"/>
    </div>

    <div class="card-container">
      <div class="thumnnail-name">
        <div class="event-status">
          <div class="is-warning" v-if="!accept">経費未請求</div>
          <div v-if="accept" :class="{'normal': accept}">経費申請終了</div>
        </div>
        <div class="thumnnail-time">
          <span>{{ event.createdAtFormatDot }}</span>
        </div>
      </div>
      <div class="title">
        <span>{{ event.title }}</span>
      </div>
      <div class="raw-body">
        <span v-html="event.short_body">
        </span>
        <span v-if="seeMore" class="see-more">もっと見る</span>
      </div>

      <div class="card-container--footer all-text columns is-mobile " v-if="accept">
        <div class="column is-12">
          <div class="accept-title" :class="{'mb-0': !expense}">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_6663_1210)">
                <path
                  d="M8.00004 14.7891C11.7496 14.7891 14.7891 11.7495 14.7891 7.99998C14.7891 4.25046 11.7496 1.21088 8.00004 1.21088C4.25052 1.21088 1.21094 4.25046 1.21094 7.99998C1.21094 11.7495 4.25052 14.7891 8.00004 14.7891Z"
                  fill="#FFC400"/>
                <path
                  d="M8.00002 15.9919C6.41776 15.9919 4.87102 15.5227 3.55542 14.6437C2.23983 13.7646 1.21449 12.5152 0.608985 11.0534C0.00348356 9.59156 -0.154966 7.98303 0.153715 6.43118C0.462397 4.87933 1.22433 3.45386 2.34315 2.33504C3.46197 1.21622 4.88744 0.454286 6.43929 0.145604C7.99114 -0.163078 9.59965 -0.00465235 11.0615 0.600849C12.5233 1.20635 13.7727 2.23174 14.6518 3.54734C15.5308 4.86293 16 6.40965 16 7.9919C15.9979 10.113 15.1543 12.1466 13.6545 13.6464C12.1547 15.1462 10.1211 15.9898 8.00002 15.9919ZM8.00002 2.42175C6.89641 2.42175 5.81755 2.74912 4.90005 3.36242C3.98254 3.97572 3.26758 4.84739 2.84562 5.86714C2.42366 6.8869 2.3137 8.00892 2.52959 9.0912C2.74548 10.1735 3.27755 11.1674 4.05849 11.9472C4.83942 12.727 5.83413 13.2576 6.91673 13.472C7.99933 13.6863 9.12113 13.5747 10.1403 13.1512C11.1594 12.7278 12.03 12.0116 12.642 11.0932C13.254 10.1748 13.5798 9.09551 13.5782 7.9919C13.5761 6.51387 12.9874 5.09709 11.9415 4.05272C10.8957 3.00835 9.47805 2.42175 8.00002 2.42175Z"
                  fill="#FFC400"/>
                <path
                  d="M8.00035 15.1847C6.57776 15.1847 5.18707 14.7628 4.00423 13.9725C2.82139 13.1821 1.89949 12.0588 1.35509 10.7445C0.810691 9.43017 0.668309 7.98396 0.945842 6.58871C1.22338 5.19345 1.90842 3.91183 2.91434 2.9059C3.92026 1.89998 5.20184 1.21494 6.59709 0.937406C7.99235 0.659873 9.43855 0.802304 10.7529 1.34671C12.0672 1.89111 13.1905 2.81303 13.9808 3.99586C14.7712 5.1787 15.1931 6.56935 15.1931 7.99194C15.191 9.89891 14.4324 11.7272 13.084 13.0756C11.7356 14.424 9.90732 15.1825 8.00035 15.1847ZM8.00035 1.61452C6.73708 1.61452 5.50217 1.98925 4.45191 2.69125C3.40165 3.39325 2.58324 4.391 2.10018 5.55827C1.61711 6.72553 1.49109 8.00983 1.73813 9.24871C1.98517 10.4876 2.59413 11.6254 3.48796 12.5181C4.38179 13.4108 5.5204 14.0183 6.75959 14.2638C7.99878 14.5092 9.28289 14.3816 10.4495 13.8971C11.6162 13.4125 12.6129 12.5929 13.3136 11.5417C14.0143 10.4906 14.3874 9.25521 14.3858 7.99194C14.3837 6.2998 13.71 4.6777 12.5127 3.48194C11.3155 2.28618 9.69248 1.61452 8.00035 1.61452Z"
                  fill="#FFFF4A"/>
                <path
                  d="M9.08197 12.1897H6.91047C6.80407 12.1877 6.70261 12.1445 6.62735 12.0692C6.5521 11.994 6.5089 11.8925 6.50684 11.7861V4.20588C6.50684 4.09883 6.54929 3.99617 6.62499 3.92047C6.70069 3.84478 6.80342 3.80225 6.91047 3.80225H9.08197C9.18902 3.80225 9.29165 3.84478 9.36735 3.92047C9.44304 3.99617 9.4856 4.09883 9.4856 4.20588V11.8184C9.47746 11.9197 9.43134 12.0143 9.35651 12.0831C9.28167 12.152 9.18365 12.19 9.08197 12.1897ZM7.3141 11.3825H8.67833V4.60951H7.3141V11.3825Z"
                  fill="#FFFF4A"/>
              </g>
              <defs>
                <clipPath id="clip0_6663_1210">
                  <rect width="16" height="16" fill="white"/>
                </clipPath>
              </defs>
            </svg>

            <span v-if="expense">{{ expense.expenseTypeName }}</span>
            <span v-if="!expense">経費申請無し</span>
          </div>
          <div v-if="expense">
            <div class="accept-price">
              <span>金額：{{ expense.price }}円</span>
            </div>
            <div class="accept-date">
              <span>経費建替日：{{ expense.paidDate }}</span>
            </div>
            <div class="accept-des">
              <span>{{ expense.note }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="card-container--footer all-text columns is-mobile " v-else>
        <div class="column is-6 text-center ">
          <b-button v-if="!expense" type="is-success" outlined @click="unExpense">経費がなかった</b-button>
        </div>
        <div class="column is-6 text-center" @click="gotoPage(url)">
          <b-button type="is-success" >経費を申請する</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import axios from 'axios'

export default {
  components: {},
  props: {
    event: {
      type: Object,
      required: true
    },
    accept: {
      type: Boolean,
      default: false
    },
    assigned_staffs: {
      type: Object,
      required: true
    },
    expenses: {
      type: Array,
      required: true
    },
    url: {
      type: String,
      required: true
    },
    urlNoExpense: {
      type: String,
      default: ''
    }
  },
  data () {
    return {}
  },
  computed: {
    ...mapState('space', ['unsetMargin', 'unsetPadding']),
    seeMore () {
      return this.event.short_body.length >= 100
    },
    expense () {
      if (this.expenses.length === 1 && +this.expenses[0].price === 0 && this.expenses[0].current_state === 'accepted') {
        return false
      } else {
        return this.expenses[0]
      }
    }
  },
  mounted () {
    this.unsetMargin.mainContainer = true
    this.unsetMargin.appMain = true
    this.unsetPadding.mainContainer = true
  },
  methods: {
    gotoPage (url) {
      window.location.href = url
    },
    unExpense () {
      const form = new FormData()
      form.append('expenses[price]', 0)
      form.append('id', this.assigned_staffs.id)
      form.append('expenses[current_state]', 'accepted')
      form.append('expenses[expense_type_id]', '3')
      form.append('expenses[note]', '経費がなかった')
      axios({
        method: 'POST',
        url: `${this.urlNoExpense}`,
        data: form
      }).then((res) => {
        if (res.status) {
          this.$emit('event-updated', this.assigned_staffs)
        }
      })
    }
  }
}
</script>
<style scoped lang="sass">
  .event-detail
    margin-bottom: 20px

  .card-container
    background: #ffffff
    padding-bottom: 24px
    border-radius: 0 0 12px 12px
    padding-top: 10px

    .thumnnail-name
      display: flex
      margin-right: 20px
      margin-left: 20px
      color: #FFFFFF
      .event-status
        div
          font-weight: bold
          font-size: 12px
          height: 18px
          display: flex
          border-radius: unset
          align-items: center
          padding: 0 8px
          &.is-warning
            color: #FFFFFF
            background: #F68F54
          &.normal
            color: #7C7C7C
            border: 1px solid #7C7C7C
            background: #FFFFFF
      .thumnnail-time
        margin-left: auto
        color: #AAAAAA
        font-size: 12px
    .title
      margin: 20px
      font-weight: 600
      font-size: 16px
      color: #273D35

    .raw-body
      margin-right: 20px
      margin-left: 20px
      font-weight: 300
      font-size: 14px
      color: #273D35
      word-break: break-word
    .see-more
      font-weight: normal
  .image-preview
    line-height: 0
    img
      aspect-ratio: 343 / 200
      object-fit: cover
      border-radius: 12px 12px 0 0
  .card-container--footer
    margin-top: 24px
    padding: 16px
    border-top: 1px solid #CCCCCC
    margin-left: 0
    margin-right: 0
    .column:first-child
      padding: 0 6px 0 0
    .column:last-child
      padding: 0 0 0 6px

    .is-outlined
      border: none
      box-shadow: 0px 2px 8px #f2efde
    button
      height: 100%
      padding: 10px 8px
      width: 100%
      font-weight: bold
      font-size: 15px
      font-family: "Hiragino Kaku Gothic ProN"
  .accept-title
    color: #273D35
    font-weight: 600
    font-size: 14px
    line-height: 14px
    margin-bottom: 10px
    display: flex
    align-items: center
    span
      margin-left: 12px
  .accept-price,
  .accept-date,
  .accept-des
    color: #273D35
    font-weight: 300
    font-size: 12px
  .accept-des
    margin-top: 10px
  .mb-0
    margin-bottom: unset !important
</style>
