<template>
  <div>
    <HiddenHeader />
    <Back :title="titleHeader"/>
    <div>
      <MyCommuStep
        v-if="!isConfirm"
        :is-step1="isStep1"
        :is-step2="isStep2"
        :is-step3="isStep3"
      />
      <MyCommuNewEventStep1
        v-if="isStep1"
        :collection="collection"
        :event="event"
        :options="options"
        :time-finished="timeFinished"
        :time-start="timeStart"
        :url="url"
        :fake-capacity="fakeCapacity"
        :real-capacity="realCapacity"
        :is-edit="isEdit"
        :is-copy="isCopy"
        :image-default="imageDefault"
        :init-schedules="schedules"
        :min-started="event.base_work_started_at"
        :max-finished="event.base_work_finished_at"
        :counts="schedules.length"
        :pass-step1="passStep1"
        :images="images"
        @nextStep1="nextStep1"
      />

      <MyCommuManagementEventNewStep3Location
        v-if="isStep2"
        :event="event"
        :prefecture-options="prefectureOptions"
        @backStep1="backStep1"
        @nextStep2="nextStep2"
        @nextConFirm="nextConFirm"
      />
      <Step4
        v-if="isStep3"
        :event="event"
        :group-id="groupId"
        :prefecture-options="prefectureOptions"
        :communities="communities"
        :event-commu-ids="eventCommu"
        :event-commu-group-ids="eventGroup"
        @backStep2="backStep2"
        @nextConFirm="nextConFirm"
      />
      <MyCommuManagementEventNewConfirm
        v-if="checkViewConfirm"
        :event="eventData"
        :url="url"
        :collection="collection"
        :prefecture-options="prefectureOptions"
        :fake-capacity="fakeCapacity"
        :real-capacity="realCapacity"
        :schedules="schedules"
        :is-edit="isEdit"
        :is-copy="isCopy"
        :communities="communities"
        :selected-communities="communitiesSelected"
        :selected-groups="groupSelected"
        :files="files"
        :image-default="imageDefault"
        :event-commu-ids="eventCommu"
        :event-commu-group-ids="eventGroup"
        :event-for-commu-and-group="eventForCommuAndGroup"
        @backStep3="backStep3"
      />
    </div>
  </div>
</template>
<script>

import { mapActions, mapState } from 'vuex'
import { format, parse } from 'date-fns'
import * as inputs from '../../components/inputs'
import Back from '../../components/my-commu/Back'
import Step4 from '../../components/my-commu/management/Step4'

export default {
  components: { Back, Step4 },
  computed: {
    ...mapState('mycommu', ['titleHeader', 'hiddenHeader', 'currentCommunity', 'loading']),
    ...mapState('space', ['unsetMargin']),
    options () {
      const arr = []
      for (let h = 0; h < 24; h += 1) {
        for (let m = 0; m < 60; m += this.selectableMinutesDuration) {
          const min = `00${m}`.slice(-2)
          const time = `${h}:${min}`
          arr.push({ value: time, label: time })
        }
      }
      return arr
    },
    checkViewConfirm () {
      return !this.isStep1 && !this.isStep2 && !this.isStep3
    },
    titleHeader () {
      if (this.isEdit) {
        return 'イベント編集'
      } else if (this.isNewEvent) {
        return '新規イベント作成'
      } else {
        return 'イベント作成'
      }
    }
  },
  props: {
    collection: {
      type: Array,
      default: () => []
    },
    event: {
      type: Object,
      require: true
    },
    selectableMinutesDuration: {
      type: Number,
      default: 15
    },
    prefectureOptions: {
      type: Array,
      default: () => []
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    isCopy: {
      type: Boolean,
      default: false
    },
    imageDefault: {
      type: String,
      require: true
    },
    eventSchedules: {
      type: Array,
      default: () => []
    },
    communities: {
      type: Array,
      default: () => []
    },
    images: {
      type: Array,
      default: () => []
    },
    eventForCommuIds: {
      type: Array,
      default: () => []
    },
    eventForCommuGroupIds: {
      type: Array,
      default: () => []
    },
    eventForCommuAndGroup: {
      type: Array,
      default: () => []
    },
    groupId: {
      type: Number,
      default: 0
    }
  },
  created () {
    this.unsetMargin.mainContainer = true
  },
  data () {
    let started = this.parseTimeString(this.event.base_work_started_at)
    let finished = this.parseTimeString(this.event.base_work_finished_at)
    started = this.normalizeTime(started)
    finished = this.normalizeTime(finished)
    let isSchedules = []
    if (this.eventSchedules.length > 0) {
      this.eventSchedules.forEach((schedule) => {
        const started = inputs.normalizeTime(this.parseTimeString(schedule.started_at))
        const finished = inputs.normalizeTime(this.parseTimeString(schedule.finished_at))
        isSchedules.push({
          id: schedule.id,
          startedAt: format(started, 'H:mm'),
          finishedAt: format(finished, 'H:mm'),
          subject: schedule.subject,
          real: schedule.real_capacity,
          delete: false
        })
      })
    } else {
      isSchedules = []
    }
    return {
      isNewEvent: true,
      isStep1: true,
      isStep2: false,
      isStep3: false,
      isConfirm: false,
      fakeCapacity: 6,
      realCapacity: 100,
      timeStart: format(started, 'H:mm'),
      timeFinished: format(finished, 'H:mm'),
      schedules: isSchedules,
      url: this.imageDefault === this.event.image1.url ? null : this.event.image1.url,
      eventData: {},
      communitiesSelected: [],
      groupSelected: [],
      passStep1: false,
      files: this.images,
      eventCommu: this.eventForCommuIds,
      eventGroup: this.eventForCommuGroupIds
    }
  },
  methods: {
    ...mapActions('mycommu', [
      'toggerTopbar'
    ]),
    nextStep1 (params) {
      this.eventData = params.event
      this.isStep1 = !params.nextStep1
      this.isStep2 = params.nextStep1
      let real = 0
      params.schedules.map((a, b) => {
        if (a.real !== '' && (a.id === null || (a.id && !a.delete))) {
          real += parseInt(a.real)
        }
      })
      this.realCapacity = real
      this.startedAt = params.startedAt
      this.finishedAt = params.finishedAt
      this.timeStart = params.startedAt
      this.timeFinished = params.finishedAt
      this.url = params.url

      this.schedules = params.schedules
      this.passStep1 = true
      this.files = params.files
    },
    backStep1 (params) {
      this.isStep1 = params.isStep1
      this.isStep2 = params.isStep2
      this.isStep3 = params.isStep3
    },
    nextStep2 (params) {
      this.isStep1 = params.isStep1
      this.isStep2 = params.isStep2
      this.isStep3 = params.isStep3
    },
    backStep2 (params) {
      this.isStep1 = params.isStep1
      this.isStep2 = params.isStep2
      this.isStep3 = params.isStep3
    },
    backStep3 (params) {
      this.isStep1 = params.isStep1
      this.isStep2 = params.isStep2
      this.isStep3 = params.isStep3
    },
    nextConFirm (params) {
      // this.event = params.event
      this.communitiesSelected = params.event.communities
      this.groupSelected = params.event.group
      this.isStep1 = params.isStep1
      this.isStep2 = params.isStep2
      this.isStep3 = params.isStep3
      this.isConfirm = true
    },
    normalizeTime (date) {
      return inputs.normalizeTime(date, this.selectableMinutesDuration)
    },
    parseTimeString (timeString) {
      // have to use this to keep time from database unchanged by local timezone
      const dateTimeParts = timeString.split('T');
      const datePart = dateTimeParts[0];
      const timePart = dateTimeParts[1].split('.')[0];

      return `${datePart} ${timePart}`
    }
  }
}

</script>

<style scoped lang="sass">
.topbar
  height: 41px
  background: #FFFFFF
  display: grid
  grid-template-columns: auto auto auto
  align-items: center

  .topbar-icon-back
    margin-left: 23px

  .topbar-title
    color: #273D35
    font-weight: 700
    font-size: 16px
    text-align: center

  .topbar-avatar
    margin-left: auto
    margin-right: 23px
    margin-top: 5px

    img
      width: 32px
      height: 32px
      border-radius: 4px

</style>
