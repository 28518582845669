<template>
  <div class="mycommu-top">
    <div class="is-relative" v-if="!showMethodSignIn">
      <div class="top-new-session">
        <div class="logo-center">
          <img src="../../images/logoMycommuNew.png" alt="">
        </div>
      </div>
      <div class="text-center">
        <p class="kosodate-user-guide">「子育てシェア」に登録済の方は、同じ</p>
        <p class="kosodate-user-guide">メールアドレス・パスワードでログインください。</p>
      </div>
      <div class="button-bottom is-mt-5">
        <a class="button is-success is-fullwidth" @click="methodSignIn(true)">ログイン</a>
        <a class="button is-fullwidth bg-white is-secondary is-mt-3" @click="methodSignIn(false)">新規アカウント登録</a>
      </div>
    </div>
    <ListMethodSignIn
      v-if="showMethodSignIn"
      :isSignIn="isSignIn"
      :url="url"
      :line="line"
      :facebook="facebook"
      :yahoo="yahoo"
      @back="changeShowMethodSignIn"/>
  </div>
</template>

<script>

import ListMethodSignIn from '../../components/my-commu/ListMethodSignIn'
import { isProduction } from 'lib/env'
import { isAndroid, isChrome, isIOS, isTouchDevice, isMobileSafari} from 'lib/browser'
export default {
  components: {
    ListMethodSignIn
  },
  props: {
    urlLogin: {
      type: String,
      required: false
    },
    urlSignUp: {
      type: String,
      required: true
    },
    line: {
      type: String,
      required: true
    },
    facebook: {
      type: String,
      required: true
    },
    yahoo: {
      type: String,
      required: true
    },
    mobileApp: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      showMethodSignIn: false,
      isSignIn: true,
      url: this.urlLogin
    }
  },
  computed: {
  },
  methods: {
    methodSignIn (isSignIn) {
      this.showMethodSignIn = true
      if (isSignIn) {
        this.isSignIn = true
        this.url = this.urlLogin
      } else {
        this.isSignIn = false
        this.url = this.urlSignUp
      }
    },
    changeShowMethodSignIn () {
      this.showMethodSignIn = false
    },
    timeOutToLeavedForm () {
      if (this.mobileApp && window.location.search == '?leaved=true') {
        window.location.href = 'https://docs.google.com/forms/d/e/1FAIpQLSe33qOMVYcplyBZjgiYQ3LB6B6H68c8A4rA4eYr79hXWwSkxQ/viewform'
      }
    }
  },
  mounted () {
    document.onreadystatechange = () => {
      this.timeOutToLeavedForm()
    }
  }
}
</script>
<style scoped lang="sass">
  .text-center
    text-align: center
  .kosodate-user-guide
    font-size: 12px
  .is-relative
    padding: 0 32px
  .top-new-session
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    height: calc(90vh - 192px)
    animation: toTop 1.5s ease
    -webkit-animation: toTop ease-in 1.5s
    -moz-animation: toTop ease-in 1.5s
    -o-animation: toTop ease-in 1.5s
    .logo-center
      width: 142px
      img
        width: 100%
  .button-bottom
    //position: absolute
    z-index: 10
    width: 100%
    bottom: -108px
    animation: showButton 1.5s ease
    -webkit-animation: showButton ease-in 1.5s
    -moz-animation: showButton ease-in 1.5s
    -o-animation: showButton ease-in 1.5s
    .button
      height: 48px
      font-weight: bold
    .is-secondary
      color: #2DBA87

  @keyframes toTop
    0%
      height: calc(90vh - 84px)
    50%
      height: calc(90vh - 84px)
    100%
      height: calc(90vh - 192px)
  @-moz-keyframes toTop
    0%
      height: calc(100vh - 84px)
    50%
      height: calc(100vh - 84px)
    100%
      height: calc(100vh - 192px)
  @-webkit-keyframes toTop
    0%
      height: calc(100vh - 84px)
    50%
      height: calc(100vh - 84px)
    100%
      height: calc(100vh - 192px)
  @-o-keyframes toTop
    0%
      height: calc(100vh - 84px)
    50%
      height: calc(100vh - 84px)
    100%
      height: calc(100vh - 192px)

  @keyframes showButton
    0%
      opacity: 0
      bottom: 0
    50%
      opacity: 0
      bottom: 0
    100%
      opacity: 1
      bottom: -108px
  @-moz-keyframes showButton
    0%
      opacity: 0
      bottom: 0
    50%
      opacity: 0
      bottom: 0
    100%
      opacity: 1
      bottom: -108px
  @-webkit-keyframes showButton
    0%
      opacity: 0
      bottom: 0
    50%
      opacity: 0
      bottom: 0
    100%
      opacity: 1
      bottom: -108px
  @-o-keyframes showButton
    0%
      opacity: 0
      bottom: 0
    50%
      opacity: 0
      bottom: 0
    100%
      opacity: 1
      bottom: -108px
</style>
