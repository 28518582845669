<template>
  <div>
    <div class="guest-message">
      <div class="guest-avatar avatar">
        <img :src="chatMessage.writer.communityAvatarPath" alt="">
      </div>
      <div class="guest-content-card">
        <div class="guest-content" v-if="hasContent" v-html="chatMessage.content"/>
        <div class="guest-image" v-if="hasImage">
          <img :src="imageUrl" alt="">
        </div>
        <div class="time-reaction">
          <div class="time">{{ chatMessage.postAt }}</div>
          <div class="like" v-if="canReact">
            <img v-if="chatMessage.heart" src="../../../images/for_community/heart_active_icon.png" alt="" width="16">
            <img v-if="!chatMessage.heart" @click="react('heart')" src="../../../images/for_community/heart_in_active_icon.png" alt="" width="16">
          </div>
          <div v-if="canReact">いいね</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    chatMessage: {
      type: Object,
      required: true
    },
    canReact: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return { isTrue: true }
  },
  computed: {
    hasImage () {
      return this.chatMessage.imageUrl || this.chatMessage.imageThumbUrl
    },
    imageUrl () {
      if (this.chatMessage.imageThumbUrl) {
        return this.chatMessage.imageThumbUrl
      } else {
        return this.chatMessage.imageUrl
      }
    },
    hasContent () {
      return this.chatMessage.content !== ''
    }
  },
  methods: {
    react (type) {
      this.$emit('react', type, this.chatMessage.id)
    }
  }
}

</script>

<style lang="sass">
  .guest-message
    padding: 0 16px
    display: flex
    margin-top: 16px
    .avatar
      img
        width: 40px
        height: 40px
        border-radius: 50%
        object-fit: cover
        flex-shrink: 0
    .guest-image
      margin-left: 8px
      img
        width: 248px
    .guest-content
      word-break: break-all
      margin-left: 8px
      padding: 16px
      max-width: 248px
      min-width: 50px
      background: #FFFFFF
      border-radius: 0 16px 16px 16px
      font-weight: 300
      font-size: 14px
      line-height: 24px
      color: #273D35
    .time-reaction
      margin-left: 8px
      margin-top: 10px
      display: flex
      font-style: normal
      font-weight: 300
      font-size: 12px
      line-height: 12px
      text-align: right
      color: #273D35
      .time
        align-self: center
        color: #7c7c7c
      .like
        margin-left: 8px
      div
        align-self: center

</style>
