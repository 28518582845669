<template>
  <BField
    :label="label"
    :message="errorMessage"
    :type="status"
    :label-for="id"
    :addons="false"
    :class="{'is-marginless': isMarginless, 'is-my-commu': isMyCommuDomain}"
    :custom-class="customClass"
  >
    <div v-if="noError" class="change-icon" @click="passwordToogle" :class="isShow ? 'hide-password' : 'show-password' "></div>
    <BInput
      :id="id"
      v-model="inputValue"
      customClass="input-password dot-password"
      :class="{'input-my-commu': isMyCommuDomain}"
      :name="name"
      :type="isType"
      :placeholder="placeholder"
      :min="min"
      :max="max"
      @blur="handleBlur"
      :size="inputSize"
      ref="myInput"
    />
    <slot />
  </BField>
</template>

<script>
import * as inputs from '.././inputs'
import Input from "./comments/Input";

export default {
  components: {Input},
  props: {
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: null
    },
    value: {
      type: [String, Number],
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    errors: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String,
      default: null
    },
    onBlur: {
      type: Function,
      default: () => {}
    },
    isMarginless: {
      type: Boolean,
      default: false
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    isPublished: {
      type: Boolean,
      default: false
    },
    isHidden: {
      type: Boolean,
      default: false
    },
    isProtected: {
      type: Boolean,
      default: false
    },
    addInputtedClass: {
      type: Boolean,
      default: false
    },
    min: {
      type: Number,
      default: null
    },
    max: {
      type: Number,
      default: null
    },
    passwordReveal: {
      type: Boolean,
      default: false
    },
    isLarge: {
      type: Boolean,
      default: false
    },
    isMedium: {
      type: Boolean,
      default: false
    },
    isMyCommuDomain: {
      type: Boolean,
      default: false
    },
    showPassword: {
      type: Boolean,
      default: false
    },
    noError: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return { inputValue: this.value, displayErrors: this.errors, isInputted: false, inputReveal: this.type == 'password' && this.passwordReveal, isType: 'password', isShow: this.showPassword }
  },
  computed: {
    status () {
      return inputs.status(this.displayErrors, this.isInputted)
    },
    id () {
      return inputs.idForName(this.name)
    },
    customClass () {
      return inputs.customClass(this.$props)
    },
    errorMessage () {
      if (this.isInputted) {
        // NOTE: ふつうに表示したいエラーメッセージも隠してしまう場合があるので要注意。制御を変えたほうがいい
        return this.displayErrors.filter(message => !message.match(/を入力してください$/i))
      }
      return this.displayErrors
    },
    inputSize () {
      if (this.isLarge){
        return 'is-large'
      } else if (this.isMedium) {
        return 'is-medium'
      }
    }
  },
  methods: {
    handleBlur (e) {
      this.isInputted = this.addInputtedClass && e.target.value.length > 0
      // inputのvalueをjsで入れた場合にstoreに入ってないので入れとく
      if (this.inputValue !== e.target.value) {
        this.inputValue = e.target.value
      }
      const result = this.onBlur(e)
      // 郵便番号自動入力時のエラーをトースト表示させないための苦肉の対応の模様
      // 郵便番号用のコンポーネントをいずれちゃんと作って分離しないとメンテが不安
      if (result instanceof Promise) {
        result.then((errorMessage) => {
          if (errorMessage) {
            this.displayErrors = [errorMessage]
            this.isInputted = false
          } else {
            this.displayErrors = []
          }
        })
      }
    },
    passwordToogle () {
      this.isShow = !this.isShow
      var input = this.$refs.myInput.$el.querySelector('input')
      input.type = this.isShow ? 'text' : 'password'
      this.isShow ? input.classList.remove('dot-password') : input.classList.add('dot-password')
    },
    hasError () {
      if (this.errors.length !== 0) {
        this.noError = false
        setTimeout(() => {
          this.noError = true
          const iconWarning = document.querySelector('.fa-exclamation-circle')
          iconWarning.remove()
        }, 5000)
      }
    }
  },
  created () {
    this.hasError()
  }
}
</script>

<style scoped lang="sass">
@import '~stylesheets/resources'
/deep/
@import '~stylesheets/components/required-label'
@import '~stylesheets/components/published-label'
.is-short
  .control
    width: 33%
.is-my-commu
/deep/ > label
  font-size: 14px
  font-weight: 300
  color: #273D35
  /deep/ .input-my-commu
    caret-color: #2DBA87
  /deep/ .input-my-commu:focus
    border-color: #2DBA87
    box-shadow: unset
/deep/ .input-my-commu > span
  color: #2DBA87 !important
.is-my-commu
    position: relative
.is-my-commu:not(:last-child)
  margin-bottom: 0px !important
.change-icon
  position: absolute
  right: 17px
  top: 2.4rem
  width: 1.5rem
  height: 1.5rem
  z-index: 1
.show-password
  background: url("../../images/signin/eyeclosed.svg") no-repeat
.hide-password
  background: url("../../images/signin/eyeopened.svg") no-repeat
/deep/ .input-password
  border-radius: 6px
  caret-color: #2DBA87
  padding-right: 50px
  height: 48px
  &:focus
    border: 1px solid #2DBA87
    border-radius: 6px
    box-shadow: 0 0 0 0
/deep/ .dot-password
  -webkit-text-stroke-width: 0.2em
  letter-spacing: 0.2em
  text-indent: 2px
  +app-mobile
    -webkit-text-stroke-width: unset
    letter-spacing: normal
    text-indent: 0px
/deep/ input
  font-family: "Hiragino Kaku Gothic ProN"
</style>
