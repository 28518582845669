<template>
  <div class="talk-wrapper">
    <div class="talk-toolbox">
      <div class="talk-toolbox-left talk-toolbox-block">
        <BDropdown :mobile-modal="false" position="is-top-right">
          <button slot="trigger" class="option-button option-button-horizontal" type="button">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_5784_1173)">
                <path
                  d="M17 20.77C13.6956 21.3912 10.3044 21.3912 6.99999 20.77C6.11281 20.5137 5.30477 20.0374 4.65093 19.3853C3.99708 18.7332 3.51862 17.9265 3.26 17.04C2.6399 13.7355 2.6399 10.3445 3.26 7.04C3.51862 6.15351 3.99708 5.3467 4.65093 4.6946C5.30477 4.0425 6.11281 3.56621 6.99999 3.30996C10.3034 2.67872 13.6965 2.67872 17 3.30996C17.8872 3.56621 18.6952 4.0425 19.349 4.6946C20.0029 5.3467 20.4813 6.15351 20.74 7.04C21.3601 10.3445 21.3601 13.7355 20.74 17.04C20.4813 17.9265 20.0029 18.7332 19.349 19.3853C18.6952 20.0374 17.8872 20.5137 17 20.77V20.77Z"
                  stroke="#273D35" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path
                  d="M12 15.26C13.8004 15.26 15.26 13.8005 15.26 12C15.26 10.1996 13.8004 8.74001 12 8.74001C10.1995 8.74001 8.73999 10.1996 8.73999 12C8.73999 13.8005 10.1995 15.26 12 15.26Z"
                  stroke="#273D35" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </g>
              <defs>
                <clipPath id="clip0_5784_1173">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <span v-if="fileLength > 0" class="noti">{{fileLength}}</span>
          </button>

          <div class="co-dropdown-item remove">
            <b-field class="file" :class="{ 'has-name': !!file }">
              <b-upload v-model="file" class="file-label" type="file" accept="image/*" multiple capture="environment" @input="hideModal">
                <span class="file-cta">
                  <span class="d-flex icon-edit is-mr-2">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_5784_1125)">
                        <path
                          d="M17 20.77C13.6956 21.3912 10.3044 21.3912 6.99999 20.77C6.11281 20.5137 5.30477 20.0374 4.65093 19.3853C3.99708 18.7332 3.51862 17.9265 3.26 17.04C2.6399 13.7355 2.6399 10.3445 3.26 7.03998C3.51862 6.15349 3.99708 5.34669 4.65093 4.69459C5.30477 4.04249 6.11281 3.5662 6.99999 3.30994C10.3034 2.67871 13.6965 2.67871 17 3.30994C17.8872 3.5662 18.6952 4.04249 19.349 4.69459C20.0029 5.34669 20.4813 6.15349 20.74 7.03998C21.3601 10.3445 21.3601 13.7355 20.74 17.04C20.4813 17.9265 20.0029 18.7332 19.349 19.3853C18.6952 20.0374 17.8872 20.5137 17 20.77V20.77Z"
                          stroke="#273D35" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        <path
                          d="M12 15.26C13.8004 15.26 15.26 13.8004 15.26 12C15.26 10.1995 13.8004 8.73999 12 8.73999C10.1995 8.73999 8.73999 10.1995 8.73999 12C8.73999 13.8004 10.1995 15.26 12 15.26Z"
                          stroke="#273D35" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                      </g>
                      <defs>
                        <clipPath id="clip0_5784_1125">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </span>
                  <span class="file-label">写真を撮る</span>
                </span>
              </b-upload>
            </b-field>
          </div>
          <div class="co-dropdown-item remove">
            <b-field class="file">
              <b-upload v-model="file" class="file-label" type="file" accept="image/*" multiple @input="hideModal">
                <span class="file-cta">
                  <span class="d-flex icon-remove is-mr-2">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M21.8526 16.31C21.6484 17.1655 21.1991 17.9429 20.5596 18.5468C19.9202 19.1508 19.1184 19.555 18.2526 19.71C14.151 20.1499 10.0141 20.1499 5.91258 19.71C5.04759 19.5556 4.2467 19.1514 3.60875 18.5473C2.9708 17.9431 2.52367 17.1653 2.32255 16.31C1.89248 13.4662 1.89248 10.5738 2.32255 7.73C2.52248 6.8741 2.96924 6.09567 3.6074 5.49129C4.24557 4.8869 5.04709 4.48311 5.91258 4.32997C10.0141 3.89001 14.151 3.89001 18.2526 4.32997C19.1189 4.48376 19.9213 4.88759 20.561 5.49172C21.2006 6.09584 21.6496 6.87389 21.8526 7.73C22.2726 10.5746 22.2726 13.4654 21.8526 16.31V16.31Z"
                        stroke="#273D35" stroke-width="2" stroke-miterlimit="10" />
                      <path d="M2.60278 16.5198C2.60278 16.4298 8.6828 8.58984 8.6828 8.58984L13.6828 13.7299"
                        stroke="#273D35" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M9.75269 19.9901C9.75269 19.8901 15.8427 12.05 15.8427 12.05L20.5826 16.92"
                        stroke="#273D35" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </span>
                  <span class="file-label">アルバムから選択</span>
                </span>
              </b-upload>
            </b-field>
          </div>
        </BDropdown>
      </div>
      <div class="talk-toolbox-center talk-toolbox-block">
        <Mentionable
          :keys="[]"
          :items="users"
          offset="6"
          @open="loadIssues()"
          @search="loadIssues($event)"
        >
          <b-input
            ref="comment"
            v-model="message"
            :placeholder="placeholder"
            class="talk-input"
            size="is-medium"
            :type="textarea ? 'textarea' : 'text'"
            @keydown="s_submit"
          />
          <template #no-result>
            {{ null }}
          </template>
        </Mentionable>
      </div>
      <div class="talk-toolbox-right talk-toolbox-block">
        <b-button
          type="is-success"
          @click="s_submit"
          size="is-medium">
          送信
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { Mentionable } from 'vue-mention'
import { mapActions, mapState } from 'vuex'
import { commuError } from 'lib/app-toast'

export default {
  components: {
    Mentionable
  },
  props: {
    placeholder: {
      type: String,
      default: 'メッセージを入力'
    },
    doCreateChatMessage: {
      type: Function,
      require: false
    },
    handleKeydown: {
      type: Function,
      require: false
    },
    focusComment: {
      type: Number,
      require: true
    },
    textarea: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('groups', ['message']),
    fileNames () {
      return '---'
    },
    ...mapState('mycommu', ['users']),
    fileLength () {
      return this.file.length
    },
    activeInput () {
      const messageNull = this.message === null || this.message === ''
      return !(this.file.length > 0 || !messageNull)
    },
    message: {
      get () { return this.$store.getters['groups/message'] },
      set (newValue) { return this.$store.dispatch('groups/setMessage', newValue) }
    }
  },
  data () {
    return {
      file: []
    }
  },
  created () {
  },
  mounted () {
    if (this.focusComment === 1) {
      this.$nextTick(() => {
        this.$refs.comment.focus()
      })
    }
  },
  methods: {
    ...mapActions('mycommu', [
      'fetchUsers'
    ]),
    s_submit () {
      if (this.file.length >= 5) {
        commuError('4枚までしか写真を選択できません。')
        this.file = []
      } else {
        this.doCreateChatMessage(this.message, this.file)
        this.message = null
        this.file = []
      }
    },
    async loadIssues (searchText = '') {
      await this.fetchUsers(searchText)
      this.$store.dispatch('groups/setLocalUser', this.users)
    },
    hideModal () {
      const e = document.getElementsByClassName('dropdown-menu')
      e[0].style.display = 'none'
    }
  }
}
</script>
<style scoped lang="sass">
  @import '~stylesheets/resources'

  $border-color: #e4e4e4
  $bg-gray-color: #fff
  .option-button
    background: none
    border: none
    position: relative
    .noti
      position: absolute
      background: #2DBA87
      width: 20px
      border-radius: 50%
      left: 21px
      color: #ffffff
      height: 20px
      top: -9px
      padding: 2px
  .talk-wrapper
    border-top: 1px solid $border-color
    position: fixed
    width: 100%
    bottom: 0vh
    top: auto !important

  .talk-toolbox
    background-color: $bg-gray-color
    padding: 8px
    line-height: 1
    display: flex
    justify-content: space-between
    align-items: center
    .talk-icon
      position: relative
      color: #333
      cursor: pointer
      padding: 2px 10px
      width: 42px
      overflow: hidden
      font-size: 22px
      &:hover
        opacity: 0.9
        cursor: pointer
    .talk-icon-file
      cursor: pointer
    .img-upload
      position: absolute
      z-index: 2
      top: 0
      left: 0
      opacity: 0
  .talk-toolbox-center
    width: 100%
  .talk-input
    margin-left: 10px
    margin-right: 10px
    width: 97%
    overflow: hidden
    min-height: 48px
    border: 1px solid #CCCCCC
    border-radius: 6px
    font-size: 14px
    line-height: 1.4
    outline: none
    padding: 6px 12px
    resize: none
    border: none
    background: #ffffff
    box-shadow: none
    font-size: 15px

    input
      height: 50px
    /deep/
      textarea
        height: 45px
        min-height: unset
        padding: 6.5px 11.5px
  +mobile
    .talk-input
      font-size: 15px
  /deep/
    .dropdown-menu
      bottom: 70px !important
      left: 16px
</style>
