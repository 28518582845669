<template>
  <div
    v-if="isShow"
    class="card-schedules"
  >
    <div class="event-schedules-label mt-24">
      <div class="primary">
        ラベル{{ count }}
      </div>
      <div class="optional">
        任意
      </div>
    </div>
    <div class="mt-8 subject">
      <BField>
        <BInput
          v-model="subject"
          name="subject"
          placeholder="午前"
          class="is-fullwidth input-my-commu"
          custom-class="input-green height-48"
          @input="changeValue"
        />
      </BField>
      <p>
        1回目２回目、午前午後、など時間を区切った表示が必要
        であれば使用
      </p>
    </div>
    <div class="mt-24 time">
      <div class="event-schedules-label is-fullwidth">
        <div class="primary">
          開始〜終了時間
        </div>
      </div>
      <div class="flex-between-center">
        <div class="is-fullwidth">
          <div>
            <div class="mt-14">
              <BField
                :type="{ 'is-danger': error() !== '' }"
                :message="error()"
                @input="changeValue"
              >
                <BSelect
                  v-model="startedAt"
                  name="start_at"
                  class="start_at"
                  @input="changeValue"
                >
                  <option
                    v-for="(opt,key) in options"
                    :key="key"
                    :value="opt.value"
                    v-text="opt.label"
                  />
                </BSelect>
              </BField>
            </div>
          </div>
        </div>
        <div class="distance">
          〜
        </div>
        <div class="is-fullwidth">
          <div>
            <div class="label-optional" />
            <div class="mt-8">
              <BField
                :type="{ 'is-danger': error() !== '' }"
                :message="error()"
                @input="changeValue"
              >
                <BSelect
                  v-model="finishedAt"
                  name="finished_at"
                  class="finished_at"
                  @input="changeValue"
                >
                  <option
                    v-for="(opt,key) in options"
                    :key="key"
                    :value="opt.value"
                    v-text="opt.label"
                  />
                </BSelect>
              </BField>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="input-number flex-between-center mt-24">
      <div>
        <label class="event-label">
          <div class="primary">募集人数</div>
        </label>
        <div class="mt-8 w-50">
          <BField>
            <BInput
              v-model="real"
              name=""
              placeholder=""
              class="is-fullwidth input-my-commu"
              custom-class="input-green height-48"
              type="number"
              @input="changeValue"
            />
          </BField>
        </div>
      </div>
    </div>
    {{ error() }}
    <div
      v-if="canDelete"
      class="label-optional w-5 mt-24"
      @click="deleteSchedules"
    >
      <BIcon
        pack="fas"
        icon="trash"
      />
    </div>
  </div>
</template>

<script>

export default {
  components: {},
  props: {
    options: {
      type: Array,
      require: true
    },
    count: {
      type: Number,
      require: true
    },
    total: {
      type: Number,
      require: true
    },
    schedule: {
      type: Object,
      require: true
    },
    indexCount: {
      type: Number,
      default: 0
    },
    errors: {
      type: Array,
      require: false
    }
  },
  data () {
    return {
      id: this.schedule.id,
      startedAt: this.schedule.startedAt,
      finishedAt: this.schedule.finishedAt,
      subject: this.schedule.subject,
      isShow: !this.schedule.delete,
      finds: [],
      real: this.schedule.real,
      delete: this.schedule.delete
    }
  },
  computed: {
    canDelete () {
      if (this.total > 1) {
        return true
      }
      return false
    }
  },
  watch: {
  },
  methods: {
    changeValue () {
      const data = {
        id: this.id,
        startedAt: this.startedAt,
        finishedAt: this.finishedAt,
        subject: this.subject,
        real: this.real,
        index: this.indexCount,
        delete: this.delete
      }
      if (this.real !== null) {
        this.$emit('nextStep3', data)
      }
    },
    deleteSchedules () {
      this.isShow = false
      this.delete = true
      const data = {
        id: this.id,
        startedAt: this.startedAt,
        finishedAt: this.finishedAt,
        subject: this.subject,
        real: this.real,
        index: this.indexCount,
        delete: this.delete
      }
      if (this.id === null) {
        this.$emit('removeSchedule', data)
      } else {
        this.changeValue()
      }
    },

    error () {
      if (this.errors[this.indexCount] === undefined || this.errors[this.indexCount] === '') {
        return ''
      }

      return this.errors[this.indexCount]
    }
  }
}
</script>
<style scoped lang="sass">
  .d-block
    display: block
  .card-schedules
    padding-bottom: 32px
    border-bottom: 1px solid #cccccc
  .is-fullwidth
    width: 100%
  .optional
    background-color: #FFFFFF
    color: #2DBA87
    font-size: 10px
    border: solid #2DBA87 1px
    padding: 2px 3px
    border-radius: 3px
    font-weight: 600
  .flex-between-center
    display: flex
    justify-content: space-between !important
    align-items: center
  .mt-24
    margin-top: 24px
  .mt-8
    margin-top: 8px
  .mt-14
    margin-top: 14px
  .w-20
    width: 5%
  .event-schedules-label
    font-weight: 300
    font-size: 14px
    line-height: 14px
    color: #273D35
    display: flex
    justify-content: space-between
    align-items: center
    /deep/
    .input-green
      background: #FFFFFF
      caret-color: #2DBA87
      border-radius: 6px
      &:focus
        border: 1px solid #2DBA87
        box-shadow: none
      .height-48
        height: 48px
      .field
        margin-bottom: 8px
      .subject
        p
          font-weight: 300
          font-size: 12px
          line-height: 12px
          color: #273D35
          line-height: 20px
  .start_at,.finished_at
    /deep/
      .select
        width: 100%
        select
          border-radius: 6px
          font-family: 'Hiragino Kaku Gothic ProN'
          width: 100%
          height: 48px
        .select:not(.is-multiple):not(.is-loading)::after
          border: 2px solid #273D35
          border-right: 0px
          border-top: 0px
          margin-top: -0.1375em
  .time
    .distance
      margin: 22px 13px 0px 13px
      padding: 0 5px
      font-size: 14px
      font-weight: 300
      color: #000000
    /deep/
      .select
        width: 100%
        select
          width: 100%
          height: 48px
          caret-color: #2DBA87
          &:focus
            border: 1px solid #2DBA87
            border-radius: 6px
            box-shadow: 0 0 0 0
      .select:not(.is-multiple):not(.is-loading)::after
        border: 2px solid #273D35
        border-right: 0px
        border-top: 0px
        margin-top: -0.1375em
      .is-danger
        margin-top: 1rem
  .label-optional
    display: flex
    justify-content: center

  .height-48
    height: 48px
  .w-50
    width: 50% !important
  .event-label
    .primary
      font-size: 14px
</style>
