<template>
  <div class="card-detail">
    <HiddenHeader/>
    <Back title='お知らせ' />

    <Slider
      :images="slider_images"
    />

    <div class="topic-header">
      <div class="topic-users">
        <div class="topic-users-avatar">
          <img src="../../../images/samples/unsplash_OtXADkUh3-I.png" />
        </div>
        <div class="topic-usrs-name">
          <span>富永 愛子</span>
        </div>
      </div>
      <div class="topic-actions">
        <div class="topic-actions-times">
          <span>2021.11.10</span>
        </div>
        <div class="topic-actions-favories">
          <span>
            <svg width="16" height="22" viewBox="0 0 16 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M13.0004 19.97L8.64038 16.2C8.46145 16.0529 8.237 15.9725 8.00537 15.9725C7.77375 15.9725 7.54936 16.0529 7.37042 16.2L3.00043 19.97C2.85661 20.0785 2.68611 20.1462 2.50702 20.1658C2.32793 20.1855 2.14688 20.1563 1.98297 20.0815C1.81906 20.0068 1.67841 19.8891 1.57587 19.741C1.47332 19.5928 1.41269 19.4198 1.40039 19.2401V2.76001C1.40039 2.49479 1.50576 2.24045 1.6933 2.05292C1.88083 1.86538 2.13517 1.76001 2.40039 1.76001H13.6704C13.9356 1.76001 14.19 1.86538 14.3775 2.05292C14.565 2.24045 14.6704 2.49479 14.6704 2.76001V19.23C14.67 19.4233 14.6135 19.6122 14.5079 19.774C14.4024 19.9359 14.2522 20.0636 14.0755 20.1419C13.8988 20.2202 13.7033 20.2457 13.5125 20.2151C13.3217 20.1846 13.1439 20.0995 13.0004 19.97Z"
                stroke="#CCCCCC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>

          </span>
        </div>
      </div>
    </div>

    <div class="cart-title">
      <span>未来に伝えたい、安心できる出産のオンライン上映会ダミーテキスト</span>
    </div>

    <div class="cart-container">
      お知らせです！「小路とつながる集合住宅」品
      川区二葉建物の周囲の小路やテラスからはみ出
      す植栽がなんとも楽しげ。小路を抜けて、自分た
      ゙けの秘密基地へ帰ってくるような気分が味わえる
      forRENT。

      ダミーテキストダミーダミーテキストダミーダ
      ミーテキストダミーダミーテキストダミーダミー
      テキスミーテキストダミーダミーテキストダミー
      ダミーテキスミーテキストダミーダミーテキスト
      ダミーダミーテキスト
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Slider from 'components/my-commu/Slider'
import Back from '../Back'

export default {
  components: {
    Slider,
    Back
  },
  props: {
    news: {
      type: Object,
      require: true
    }
  },
  data() {
    return {
      imagePaths: [1,2,3]
    }
  },
  computed: {
    slider_images() {
      let images = []
      if(this.news.images !== undefined){
        this.news.images.forEach((e)=> {
          images.push(e.url)
        });
        return images
      }else {
        return []
      }
    },
  },
  mounted() {

  },
  methods: {

  }
}
</script>
<style scoped lang="sass">

  .card-detail
    background: #ffffff
    .topbar
      height: 41px
      background: #FFFFFF
      display: flex
      display: grid
      grid-template-columns: auto auto auto
      align-items: center

      .topbar-icon-back
        margin-left: 23px
      .topbar-title
        color: #273D35
        font-weight: 600
        font-size: 16px
        text-align: center
      .topbar-avatar
        margin-left: auto
        margin-right: 23px
        img
          width: 32px
          height: 32px
          border-radius: 4px

    .topic-header
      margin: 15px 23px 25px 23px
      display: grid
      grid-template-columns: auto auto
      align-items: center
      .topic-users
        display: flex
        align-items: center
        .topic-users-avatar
          margin-right: 10px

          img
            width: 32px
            height: 32px
            border-radius: 50%
            object-fit: cover
            flex-shrink: 0
        .topic-usrs-name
          color: #273D35
          font-weight: 400
          font-size: 12px

      .topic-actions
        display: flex
        margin-left: auto
        .topic-actions-times
          margin-right: 20px
          color: #AAAAAA
          font-weight: 400
          font-size: 12px
    .cart-title
      color: #424242
      font-weight: 700
      font-size: 17px
      margin: 0px 23px 32px 23px

    .cart-container
      margin: 0px 23px 32px 23px
      color: #273D35
      font-size: 15px
      font-weight: 300
      word-break: break-word
</style>
