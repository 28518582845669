<template>
  <div class="event">
    <div class="info-event">
      <div class="title-info">
        <p>基本情報</p>
      </div>
      <label class="event-label mt-24">
        <div class="primary">交流イベント種別</div>
      </label>

      <div class="mt-8">
        <BField>
          <BSelect
            v-model="typeEvent"
            name="type_event"
            class="select-type"
            placeholder="選択する"
          >
            <option
              v-for="(opt,key) in collection"
              :key="key"
              :value="opt[1]"
              v-text="opt[0]"
            />
          </BSelect>
        </BField>
      </div>
      <label class="event-label mt-24">
        <div class="primary">交流イベントタイトル</div>
      </label>
      <div class="mt-8">
        <BField>
          <BInput
            name="title"
            v-model="title"
            placeholder="タイトル"
            class="is-fullwidth input-my-commu"
            custom-class="input-green height-48"
          />
        </BField>
      </div>
      <label class="event-label mt-24">
        <div class="primary">交流イベント内容紹介</div>
      </label>
      <div class="mt-8">
        <BField>
          <BInput
            name="description"
            v-model="description"
            placeholder="交流イベントの詳細説明文"
            class="is-fullwidth input-my-commu"
            custom-class="input-green height-130"
            type="textarea"
          />
        </BField>
      </div>
      <div class="setting-image mt-24">
        <div class="is-flex">
          <label class="event-label mr-16">
            <div class="primary">画像設定</div>
          </label>
        </div>
        <p>最大４枚まで</p>
      </div>
      <p class="image-note">JPG/JPEG・PNG・GIF形式、最大10MBまで</p>
      <div class="mt-8 upload-image">
        <BField class="is-white file">
          <BUpload v-model="files" @input="cutFiles" class="file-label file-upload" :multiple="true"
                   :disabled="files.length === 4" accept=".jpg, .png, .gif, .jpeg">
            <span class="file-cta" :class="{'disabled': files.length === 4}" >
              <BIcon class="file-icon" icon="plus"></BIcon>
            </span>
          </BUpload>
        </BField>
        <div
          v-for="(file, index) in files"
          :key="index"
          class="image-upload"
        >
          <div v-if="file.url !== imageDefault">
            <img :src="getUrl(file)" class="img">
            <svg @click="removeImage(index)" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z" fill="white" stroke="#273D35" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M6 6L10 10" stroke="#273D35" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M10 6L6 10" stroke="#273D35" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
        </div>
      </div>

      <label class="datepicker-event mt-24">
        <div class="primary">交流イベント開催日</div>
      </label>
      <div class="mt-8">
        <MyCommuBaseDatePicker
          placeholder="選択する"
          :error="this.earlierDateThan"
          :value="scheduledDate"
          title="交流イベント開催日を選択してください"
          @valueSelect="valueScheduledDate"
        />
      </div>
      <label class="datepicker-event mt-24">
        <div class="primary">申込期限</div>
      </label>
      <div class="mt-8">
        <MyCommuBaseDatePicker
          placeholder="選択する"
          :error="this.earlierDateThan"
          :value="requestExpiredAt"
          title="申込期限を選択してください"
          @valueSelect="requestExpiredDate"
        />
      </div>
    </div>
    <div class="setting-event">
      <div class="title flex-between-center setting">
        <p class="sz-16">各種条件設定</p>
        <MyCommuOptional/>
      </div>
      <div class="mt-24">
        <BField class="flex-between-center setting">
          <label class="">当日参加可能</label>
          <BSwitch v-model="acceptanceOnTheDay" ></BSwitch>
        </BField>
      </div>
    </div>

    <div class="time">
      <div class="title">
        <p>スタッフ</p>
      </div>
      <div class="input-number">
        <div class="mt-24">
          <label class="event-label">
            <div class="primary">集合時間～解散時間</div>
          </label>
          <div class="flex-between-center">
            <div class="is-fullwidth">
              <div class="mt-8">
                <BField>
                  <BSelect
                    v-model="startedAt"
                    name="type_event"
                    class="select-type"
                    placeholder="12：15"
                    @input="callValidate"
                  >
                    <option
                      v-for="(opt,key) in options"
                      :key="key"
                      :value="opt.value"
                      v-text="opt.label"
                    />
                  </BSelect>
                </BField>
              </div>
            </div>
            <div class="distance">〜</div>
            <div class="is-fullwidth">
              <div class="mt-8">
                <BField>
                  <BSelect
                    v-model="finishedAt"
                    name="type_event"
                    class="select-type"
                    placeholder="14：15"
                    @input="callValidate"
                  >
                    <option
                      v-for="(opt,key) in options"
                      :key="key"
                      :value="opt.value"
                      v-text="opt.label"
                    />
                  </BSelect>
                </BField>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <NewStep2
      ref="newEventStep2"
      :time-finished="timeFinished"
      :time-start="timeStart"
      :min-started="startedAt"
      :max-finished="finishedAt"
      :event="event"
      :options="options"
      :counts="schedules.length"
      :event-schedules="schedules"
      @nextStep2="nextStep2"
    />
    <div class="buton-next">
      <button class="button is-success is-success-my-commu is-fullwidth" :disabled="checkDisabled" @click="submit">次へ</button>
    </div>
  </div>
</template>

<script>
import NewStep2 from './NewStep2'

export default {
  components: { NewStep2 },
  props: {
    event: {
      type: Object,
      require: true
    },
    collection: {
      type: Array,
      default: () => []
    },
    includePlaceholder: {
      type: Boolean,
      default: true
    },
    options: {
      type: Array,
      require: true
    },
    timeStart: {
      type: String,
      require: true
    },
    timeFinished: {
      type: String,
      require: true
    },
    url: {
      type: String,
      default: null
    },
    realCapacity: {
      type: Number,
      default: 0
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    isCopy: {
      type: Boolean,
      defualt: false
    },
    imageDefault: {
      type: String,
      require: true
    },
    initSchedules: {
      type: Array,
      default: () => []
    },
    passStep1: {
      type: Boolean,
      default: false
    },
    images: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      typeEvent: this.event.event_type,
      title: this.event.title,
      description: this.event.body,
      files: this.images,
      scheduledDate: this.isEdit || this.isCopy ? new Date(this.event.scheduled_date) : this.passStep1 ? new Date(this.event.scheduled_date) : null,
      requestExpiredAt: this.isEdit || this.isCopy ? new Date(this.event.request_expired_at) : this.passStep1 ? new Date(this.event.request_expired_at) : null,
      real: this.realCapacity,
      startedAt: this.isEdit || this.isCopy ? this.timeStart : this.passStep1 ? this.timeStart : null,
      finishedAt: this.isEdit || this.isCopy ? this.timeFinished : this.passStep1 ? this.timeFinished : null,
      acceptanceOnTheDay: this.event.acceptance_on_the_day,
      capacityError: '',
      earlierDateThan: '',
      realCapacityError: '',
      urlImage: this.url,
      selectImage: !this.isCopy,
      schedules: this.initSchedules,
      schedulesArray: this.initSchedules,
      schedulesError: this.initSchedules.length === 0,
      errorInputNull: false,
      scheduleErrosLength: 0
    }
  },
  computed: {
    checkDisabled () {
      return this.isEmpty(this.typeEvent) || this.isEmpty(this.title) || this.isEmpty(this.description) || this.isEmpty(this.scheduledDate) ||
        this.isEmpty(this.requestExpiredAt) || this.isEmpty(this.real) || this.isEmpty(this.startedAt) ||
        this.isEmpty(this.finishedAt) || this.schedulesError || this.errorInputNull || this.files.length === 0 || this.scheduleErrosLength > 0
    },
    checkInputNull () {
      return this.schedulesArray.some(schedule => schedule.finishedAt === null || schedule.startedAt === null || schedule.real === null || schedule.real === '')
    }
  },
  watch: {
    schedulesArray () {
      this.errorInputNull = this.checkInputNull
    }
  },
  methods: {
    onFileChange (e) {
      this.selectImage = true
      this.urlImage = URL.createObjectURL(e)
      URL.revokeObjectURL(e)
    },
    isEmpty (value) {
      return (value == null || value === '')
    },
    valueScheduledDate (param) {
      this.scheduledDate = param
    },
    requestExpiredDate (param) {
      this.requestExpiredAt = param
    },
    submit () {
      if (this.scheduledDate < this.requestExpiredAt) {
        this.earlierDateThan = 'は開催日より前の日付にしてください'
      } else {
        this.earlierDateThan = ''
      }
      if (+this.realCapacity < 1 && this.realCapacity !== null) {
        this.realCapacityError = '募集人数は1以上の値にしてください'
      } else {
        this.realCapacityError = ''
      }

      if (!this.checkDisabled && this.earlierDateThan === '' && this.realCapacityError === '') {
        this.event.event_type = this.typeEvent
        this.event.title = this.title
        this.event.body = this.description
        this.event.scheduled_date = this.scheduledDate
        this.event.request_expired_at = this.requestExpiredAt
        this.event.acceptance_on_the_day = this.acceptanceOnTheDay
        this.event.base_work_started_at = this.startedAt
        this.event.base_work_finished_at = this.finishedAt
        Array.from(this.files).forEach((file, index) => {
          this.event[`image${index + 1}`] = file
        })
        Array(4).forEach((item, index) => {
          if (index + 1 > this.files.length) {
            this.event[`image${index + 1}`] = ''
          }
        })
        const data = {
          event: this.event,
          realCapacity: this.real,
          nextStep1: true,
          url: this.selectImage ? this.urlImage : null,
          url1: this.urlImage1,
          startedAt: this.startedAt,
          finishedAt: this.finishedAt,
          schedules: this.schedulesArray,
          files: this.files
        }
        this.$emit('nextStep1', data)
      }
    },
    nextStep2 (params) {
      this.schedulesError = false
      this.schedulesArray = params
      this.errorInputNull = this.schedulesArray.some(schedule => schedule.finishedAt === null || schedule.startedAt === null || schedule.real === null || schedule.real === '')
    },
    cutFiles (e) {
      this.files = this.files.slice(0, 4)
    },
    getUrl (e) {
      if (e.url !== undefined) {
        return e.url
      } else {
        const url = URL.createObjectURL(e)
        URL.revokeObjectURL(e)
        return url
      }
    },
    removeImage (index) {
      this.files.splice(index, 1)
    },
    callValidate () {
      this.$nextTick (() => {
        this.$refs.newEventStep2.$refs.MyCommuEventSchedules.forEach((component) => {
          component.changeValue()
        })
      })
    }
  }
}
</script>
<style scoped lang="sass">
  .is-fullwidth
    width: 100%
  .title-info
    font-weight: 700
    font-size: 17px
    line-height: 17px
    color: #273D35
  .title
    font-weight: 700
    font-size: 16px
    line-height: 16px
    color: #273D35
  .event-label,.datepicker-event
    font-weight: 300
    font-size: 14px
    line-height: 14px
    color: #273D35
    display: flex
    justify-content: space-between
    align-items: center
  .optional
    color: #2DBA87
    font-size: 10px
    border: solid #2DBA87 1px
    padding: 2px 3px
    border-radius: 3px
    font-weight: 700
    background-color: #FFFFFF
  .flex-between-center
    display: flex
    justify-content: space-between !important
    align-items: center
  /deep/
    .input-green
      background: #FFFFFF
      caret-color: #2DBA87
      border-radius: 6px
      font-family: 'Hiragino Kaku Gothic ProN' !important
      &:focus
        border: 1px solid #2DBA87
        box-shadow: none
    .height-48
      height: 48px
    .height-130
      height: 130px
  .mt-24
    margin-top: 24px
  .mr-16
    margin-right: 16px
  .mt-8
    margin-top: 8px
  .event
    margin: 32px 32px
    .info-event
      padding-bottom: 32px
      border-bottom: 1px solid #cccccc
      .setting-image
        display: flex
        justify-content: space-between
        align-items: center
        .primary
          font-weight: 300
          font-size: 14px
          line-height: 14px
          color: #273D35
        p
          font-weight: 300
          font-size: 14px
          line-height: 20px
          color: #2DBA87
      .label-upload
        display: flex
        justify-content: space-between
        align-items: center
        .ml-20
          margin-left: 20px
      .upload-image
        display: flex
        .image-upload
          position: relative
          border-radius: 6px
          margin-right: 16px
          .img
            width: 48px
            height: 48px
            object-fit: cover
            border-radius: 6px
            border: 1px solid #7C7C7C
          svg
            position: absolute
            left: 38px
            top: -5.5px
        /deep/
        .file
          display: unset
          margin-right: 16px
          .file-upload
            width: 48px
            height: 48px
            box-shadow: 0px 2px 8px #f2efde
            .file-cta
              width: 100%
              height: 100%
          .label
            font-size: 14px
            cursor: pointer
          .isDisabled
            opacity: 0.3
          .file-upload
            .file-icon
              margin: auto
              color: #2DBA87
        .preview
          width: 48px
          height: 48px
          position: relative
          margin-left: 16px
          .image-preview
            width: 100%
            height: 100%
            object-fit: cover
            box-shadow: 0px 2px 8px #F2EFDE
            border-radius: 6px
            border: 1px solid #7C7C7C
          .delete-preview
            position: absolute
            top: -4px
            right: -6px
      /deep/
        textarea
          resize: none
      .select-type
        /deep/
          .select
            width: 100%
            select
              width: 100%
              height: 48px
              outline: none
              border-radius: 6px
              font-family: 'Hiragino Kaku Gothic ProN' !important
              &:focus
                border: 1px solid #2DBA87
                box-shadow: unset
          .select:not(.is-multiple):not(.is-loading)::after
            border: 2px solid #273D35
            border-right: 0px
            border-top: 0px
            margin-top: -0.1375em
    .setting-event
      padding: 32px 0px
      border-bottom: 1px solid #cccccc
      .title
        display: flex
        justify-content: space-between
        align-items: center
      .setting
        /deep/
          .switch
            cursor: unset
            .control-label
              padding-left: unset
          label
            font-weight: 300
            font-size: 14px
            line-height: 14px
            color: #273D35
            display: flex
            flex-direction: row-reverse
          span.check
            height: 31px
            width: 51px
            padding: 2px
            background: rgba(120, 120, 128, 0.16)
            &:before
              width: 27px
              height: 27px
              background: #FFFFFF
              border: 0.5px solid rgba(0, 0, 0, 0.04)
              box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15), 0px 3px 1px rgba(0, 0, 0, 0.06)
          .switch input[type=checkbox]:checked + .check:before
            transform: translate3d(80%, 0, 0)
          input[type=checkbox]:checked + .check
            background: rgba(45, 186, 135, 0.9) !important
    .number-join
      padding: 32px 0px
      border-bottom: 1px solid #cccccc
      .total-user-join
        font-weight: 700
        font-size: 14px
        line-height: 14px
        color: #273D35
      .input-number
        .sum
          margin: 22px 13px 0px 13px
          padding: 0 5px
          font-size: 14px
          font-weight: 300
          color: #000000
    .time
      padding: 32px 0px 44px 0px
      .distance
        margin: 22px 13px 0px 13px
        padding: 0 5px
        font-size: 14px
        font-weight: 300
        color: #000000
      /deep/
        .select
          width: 100%
          select
            width: 100%
            height: 48px
            caret-color: #2DBA87
            font-family: 'Hiragino Kaku Gothic ProN' !important
            &:focus
              border: 1px solid #2DBA87
              border-radius: 6px
              box-shadow: 0 0 0 0
        .select:not(.is-multiple):not(.is-loading)::after
          border: 2px solid #273D35
          border-right: 0px
          border-top: 0px
          margin-top: -0.1375em
  .w-50
    width: 50% !important
  .is-success-my-commu
    font-family: 'Hiragino Kaku Gothic ProN' !important
  /deep/
    .datepicker
      input
        height: 48px
    .icon-datepicker
      top: 11px !important
  .sz-16
    font-size: 16px
  .image-note
    font-size: 12px
    color: #2DBA87
    margin: 8px 0 16px
  .invalid-image
    font-size: 14px
    color: red
    font-weight: 600
    margin-bottom: 16px
</style>
