import UAParser from 'ua-parser-js'

let _parser

function parser () {
  if (!_parser) {
    _parser = new UAParser()
  }
  return _parser
}

export function isIOS12 () {
  const { name, version } = parser().getOS()
  const ver = Number(version)
  return name === 'iOS' && ver >= 12 && ver < 13
}

export function isIOS () {
  return parser().getOS().name === 'iOS'
}

// TODO: 以下も UAParser ベースの実装に書き換える
const isMac = () => navigator.platform.toUpperCase().startsWith('MAC')

const isTouchDevice = () => 'ontouchstart' in window || navigator.msMaxTouchPoints || false

const isMobileSafari = () =>
  navigator.userAgent.match(/(iPod|iPhone|iPad)/) && navigator.userAgent.match(/AppleWebKit/)

const isAndroid = () => navigator.userAgent.match(/Android/) || false

const isChrome = () => navigator.userAgent.match(/Chrome/) || false

export {
  isMac,
  isTouchDevice,
  isMobileSafari,
  isAndroid,
  isChrome
}
