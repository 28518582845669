<template>
  <div class="card-detail">
    <HiddenHeader/>
    <Back :title=topicTitle />

    <Slider
      v-if="slider_images.length !== 0"
      :images="slider_images"
    />

    <div class="topic-header">
      <div class="topic-users-avatar" @click="goToProfile(topic.writer.id)">
          <img
            :src="avatarPath"
          />
        </div>
      <div class="topic-users" @click="goToProfile(topic.writer.id)">
        <div class="topic-usrs-name">
          <span>{{ writerName }}</span>
        </div>
      </div>
      <div class="topic-actions">
        <div
          class="topic-actions-times"
          :class="{'unset-magin': !topic.canEditAndDeleteComment}"
        >
          <span>{{topic.f_created_at}}</span>
        </div>
        <div
          v-if="topic.canEditAndDeleteComment" class="topic-actions-favories"
        >

          <BDropdown :mobile-modal="false" position="is-bottom-left">
            <button slot="trigger" class="option-button option-button-horizontal" type="button">
              <BIcon pack="far" icon="ellipsis-v" />
            </button>
            <div class="co-dropdown-item edit" @click="goToEdit">
              <span class="icon-edit">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.37012 8.93L14.1401 20.69L21.6301 21.63L20.6901 14.14L8.93012 2.37L2.37012 8.93Z"
                        stroke="#273D35" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M13.5098 20.07L20.0698 13.51" stroke="#273D35" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round" />
                </svg>

              </span>
              <span>編集する</span>
            </div>
            <div
              class="co-dropdown-item remove"
              @click.prevent="isHelpModalActive = true"
            >
              <span class="icon-remove">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M12.0002 6.40994C16.5621 6.40994 20.2602 5.46526 20.2602 4.29994C20.2602 3.13462 16.5621 2.18994 12.0002 2.18994C7.43836 2.18994 3.74023 3.13462 3.74023 4.29994C3.74023 5.46526 7.43836 6.40994 12.0002 6.40994Z"
                    stroke="#EE5353" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  <path
                    d="M20.2603 4.53003L19.5703 17.8801C19.4703 20.2801 17.0202 21.8101 12.0002 21.8101C6.98024 21.8101 4.53024 20.2801 4.43024 17.8801L3.74023 4.53003"
                    stroke="#EE5353" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M12 10.4V17.54" stroke="#EE5353" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M7.66992 10.7L7.94992 17.25" stroke="#EE5353" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M16.3298 10.7L16.0498 17.25" stroke="#EE5353" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </span>
              <span>削除する</span>
            </div>
          </BDropdown>
        </div>
      </div>
    </div>

<!--    <div class="cart-title">-->
<!--      <span>{{topic.title}}</span>-->
<!--    </div>-->

    <div class="cart-container" v-html="topic.body">
    </div>

    <div class="card-detail-footer">
      <div class="heart active">
        <svg width="14" height="13" viewBox="0 0 14 13" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M6.26701 11.94L1.54699 7.21327C0.858866 6.54924 0.440698 5.65413 0.373004 4.70025C0.305309 3.74637 0.592882 2.80117 1.18034 2.04661C1.51394 1.63943 1.92919 1.30671 2.39929 1.06988C2.86939 0.83305 3.38391 0.69738 3.90967 0.671626C4.43543 0.645872 4.96078 0.730593 5.45178 0.920344C5.94278 1.11009 6.38853 1.40067 6.76033 1.77329L6.99369 2.00661L7.11369 1.88661C7.77772 1.19848 8.67283 0.780315 9.62671 0.712621C10.5806 0.644927 11.5258 0.932499 12.2804 1.51995C12.6875 1.85356 13.0202 2.2688 13.2571 2.73891C13.4939 3.20901 13.6296 3.72352 13.6554 4.24928C13.6811 4.77504 13.5964 5.30038 13.4066 5.79138C13.2169 6.28238 12.9263 6.72815 12.5537 7.09995L7.72033 11.94C7.52688 12.1313 7.26579 12.2386 6.99369 12.2386C6.72159 12.2386 6.46046 12.1313 6.26701 11.94Z" fill="#d3d3d3"/>
        </svg>
        <span @click="goToListLike()">{{ likeCount }}</span>
      </div>
      <div class="comments-actions">
        <a @click="likeTopic">
          <div class="action-items active-heart">
            <span class="action-icon icon-heart">
              <img width="14" height="14" src="../../../images/for_community/heart_in_active_icon.png" v-if="!isLiked">
              <img width="14" height="14" src="../../../images/for_community/heart_active_icon.png" v-if="isLiked">

            </span>
            <span>いいね</span>
          </div>
        </a>
        <div
          class="action-items active-comment"
          @click="toggerCommentInput()"
        >
          <span class="action-icon icon-comment">
            <img width="14" height="14" src="../../../images/for_community/comment_icon.png">

          </span>
          <span>コメント</span>
        </div>
      </div>
    </div>

    <div class="card-detail-comments" :class="{'is-active': footerMenu}">
      <div class="card-coments-title">
        <span>コメント</span>
        <span class="card-coments-icon">
          <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M8.54732 0.873291C7.48686 0.874588 6.44487 1.15082 5.52303 1.67503C4.60119 2.19923 3.83101 2.9535 3.28768 3.8642C2.74435 4.7749 2.44644 5.81091 2.42301 6.87111C2.39958 7.93132 2.65144 8.97948 3.15401 9.91329C3.76735 11.0333 1.49398 12.6666 1.33398 12.9266C1.97242 13.0605 2.63159 13.0615 3.27043 12.9296C3.90926 12.7977 4.5141 12.5357 5.04732 12.16C6.0772 12.855 7.30696 13.1923 8.54732 13.1199C10.1364 13.0675 11.6428 12.3994 12.7483 11.2568C13.8539 10.1142 14.4719 8.58653 14.4719 6.99662C14.4719 5.40672 13.8539 3.87907 12.7483 2.73645C11.6428 1.59383 10.1364 0.92573 8.54732 0.873291Z"
              fill="#d3d3d3" stroke="#2DBA87" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          </svg>

        </span>
        <span class="fw-normal">{{ commentCount }}</span>
      </div>

      <div
        v-for="comment in comments"
        :key="comment.id"
        class="card-coments-contents"
      >
        <MyCommuCommentCard
          :comment="comment"
          @clickReply="toggerCommentInput"
          :fetch-comments="fetchComments"
        />
      </div>
    </div>
    <div
      class="card-detail-btn-comments"
      :class="{ 'active': !commentsInputStatus }"
    >
      <button
        type="button"
        class="button is-rounded is-mb-5 fw-bold"
        @click="toggerCommentInput()"
      >
        コメント
      </button>
    </div>

    <div
      class="card-detail-btn-comments-input"
      :class="{ 'active': commentsInputStatus }"
    >
      <Mentionable
        :keys="[]"
        :items="users"
        offset="6"
        @open="loadIssues()"
        @search="loadIssues($event)"
      >
        <textarea
          class="comment-textfield"
          v-model="comment"
          ref="comment"
          @focusin="inputFocusIn"
        />
        <template #no-result>
          {{ null }}
        </template>
      </Mentionable>
      <button
        type="button"
        class="button is-rounded is-mb-3"
        @click="toggerSubmit()"
      >
        送信
      </button>
    </div>
    <BModal
      :active.sync="isHelpModalActive"
      has-modal-card
    >
      <div class="modal-card">
        <section class="modal-card-body">
          <div class="content">
            <span>投稿を本当に削除してよろしいでしょうか</span>
          </div>
        </section>
        <section class="modal-card-footer">
          <button @click="closeModal" class="button cancel">キャンセル</button>
          <a :href="`${topic.communityPath}`" data-method="delete" class="button ok">はい</a>
        </section>
      </div>
    </BModal>
    <div class="modal-list-like">
      <BModal
        :active.sync="isListLikeModalActive"
        has-modal-card
      >
        <div class="modal-card">
          <MyCommuListLike
            :users="user_likes"
          />
        </div>
      </BModal>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Slider from 'components/my-commu/Slider'
import Back from '../Back'
import { Mentionable } from 'vue-mention'
import axios from 'axios'
import queryString from 'query-string'

export default {
  components: {
    Slider,
    Mentionable,
    Back
  },
  props: {
    topic: {
      type: Object,
      require: true
    }
  },
  data () {
    return {
      isHelpModalActive: false,
      commentsInputStatus: false,
      isListLikeModalActive: false,
      comment: '',
      loading: false,
      localUsers: [],
      user_likes: [],
      footerMenu: false,
      likeCount: this.topic.likesCount,
      isLiked: this.topic.liked,
      comments: this.topic.comments,
      commentCount: this.topic.commentsCount
    }
  },
  computed: {
    ...mapState('mycommu', ['users', 'currentCommunity']),
    ...mapState('mycommu', ['users']),
    ...mapState('space', ['unsetPadding']),
    ...mapState('session', ['communityId']),
    slider_images () {
      let images = []
      if (this.topic.images !== undefined) {
        this.topic.images.forEach((e) => {
          images.push(e.original)
        })
        return images
      } else {
        return []
      }
    },
    avatarPath () {
      if (this.topic.writer !== undefined) {
        return this.topic.writer.communityAvatarPath
      } else {
        return ''
      }
    },
    writerName () {
      if (this.topic.writer !== undefined) {
        if (this.topic.writer.commuNickname !== null && this.topic.writer.commuNickname !== '') {
          return this.topic.writer.commuNickname
        } else {
          return this.topic.writer.fullName
        }
      } else {
        return ''
      }
    },
    fullName () {
      if (this.topic.writer !== undefined) {
        return this.topic.writer.fullName
      } else {
        return ''
      }
    },
    topicTitle () {
      if (this.topic.writer !== undefined) {
        if (this.topic.writer.commuNickname !== null && this.topic.writer.commuNickname !== '') {
          return this.topic.writer.commuNickname + 'の投稿'
        } else {
          return this.topic.writer.fullName + 'の投稿'
        }
      } else {
        return 'の投稿'
      }
    }
  },
  mounted () {
    this.unsetPadding.mainContainer = true
    this.fetchUsers()
    const params = queryString.parse(location.search)
    if (params && params.action === 'comment') {
      this.commentsInputStatus = !this.commentsInputStatus
      this.$nextTick(() => {
        window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: 'smooth' })
        this.$refs.comment.focus()
      })
    }
  },
  updated () {
    if (this.commentsInputStatus) {
      window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: 'smooth' })
    }
  },
  methods: {
    ...mapActions('mycommu', [
      'fetchUsers', 'toggerMyCommuFooterMenu'
    ]),
    ...mapActions('comments', [
      'createComment',
      'setComment'
    ]),
    ...mapActions('home', [
      'fetchTopic'
    ]),
    toggerCommentInput (name = null, user_id = null) {
      this.toggerMyCommuFooterMenu(true)
      this.commentsInputStatus = !this.commentsInputStatus
      if (name !== null) {
        this.commentsInputStatus = true
        this.comment = `@${name} `
        this.localUsers = [
          { value: name, label: name, id: user_id }
        ]
      }

      this.$nextTick(() => {
        if (this.commentsInputStatus) {
          this.$refs.comment.focus()
          window.scrollTo({ left: 0, top: document.body.scrollHeight})
        }
      })
    },
    async toggerSubmit () {
      this.localUsers.forEach((e) => {
        this.comment = this.comment.replace(`@${e.label}`, `[[@${e.label}]]`)
      })
      this.setComment({ comment: this.comment, type: 'TOPIC' })
      this.toggerCommentInput()
      await this.createComment({ topicId: this.topic.id }).then(() => {
        this.fetchComments()
      })
      this.comment = ''
      this.toggerMyCommuFooterMenu(true)
      this.footerMenu = false
    },
    async loadIssues (searchText = null) {
      await this.fetchUsers(searchText)
      this.localUsers = [...new Map([...this.localUsers, ...this.users].map((item) => [item['label'], item])).values()]
    },
    back () {
      if (window.location.href.includes('from=notification')) {
        window.location.href = '/communities/notifications'
      } else {
        window.history.back()
      }
    },
    closeModal () {
      this.isHelpModalActive = false
    },
    likeTopic () {
      if (!this.topic.liked) {
        axios.post(`${this.topic.communityReactionUrl}`).then((response) => {
          this.likeCount = this.topic.likesCount = this.topic.likesCount + 1
          this.isLiked = this.topic.liked = true
          this.topic.likes.unshift(response.data)
        })
      } else {
        axios.post(`${this.topic.communityReactionUrl}`).then((response) => {
          this.likeCount = this.topic.likesCount = this.topic.likesCount - 1
          this.isLiked = this.topic.liked = false
          const removeIndex = this.topic.likes.findIndex(item => item === response.data)
          this.topic.likes.splice(removeIndex, 1)
        })
      }
    },
    inputFocusIn () {
      this.toggerMyCommuFooterMenu(false)
      this.footerMenu = true
    },
    goToEdit () {
      window.location.href = `${this.topic.communityPath}/edit`
    },
    goToProfile (id) {
      window.location.href = `/communities/${this.communityId}/users/${id}`
    },
    goToListLike () {
      axios.post(`/communities/${this.communityId}/topics/${this.topic.id}/list_likes`).then((res) => {
        this.user_likes = res.data.data
        this.isListLikeModalActive = true
      })
    },
    fetchComments () {
      axios.get(`/communities/${this.communityId}/topics/${this.topic.id}/fetch_show`).then((res) => {
        this.comments = res.data.data.comments
        this.commentCount = res.data.data.commentsCount
      })
    }
  }
}
</script>
<style scoped lang="sass">

  .card-detail
    background: #ffffff
    /deep/
      .animation-content
        margin: 0px 52px
        width: unset
        .modal-card-body
          padding: 32px 20px
          text-align: center
          .content
            padding: 0px 50px
      .modal-close
        display: none
      .modal-background
        background: #273D35
        opacity: 0.9
      .slides
        .swiper-container
          border-radius: 12px 12px 0 0
          background-color: #F3F3F3
          .swiper-wrapper
            .swiper-slide
              width: 100% !important
              img
                object-fit: unset
                aspect-ratio: unset
    .topbar
      height: 41px
      background: #FFFFFF
      display: flex
      display: grid
      grid-template-columns: auto auto auto
      align-items: center

      .topbar-icon-back
        margin-left: 23px
      .topbar-title
        color: #273D35
        font-weight: bold
        font-size: 16px
        text-align: center
      .topbar-avatar
        margin-left: auto
        margin-right: 23px
        img
          width: 32px
          height: 32px
          border-radius: 4px

    .topic-header
      margin: 15px 23px 15px 23px
      display: grid
      grid-template-columns: auto auto auto
      align-items: center
      .topic-users-avatar
        margin-right: 10px
        img
          width: 48px
          height: 48px
          border-radius: 50%
          object-fit: cover
          flex-shrink: 0
      .topic-users
        display: flex
        align-items: center
        cursor: pointer
        .topic-usrs-name
          color: #273D35
          font-weight: 400
          font-size: 12px

      .topic-actions
        display: flex
        margin-left: auto
        align-items: center
        .topic-actions-times
          margin-right: 20px
          color: #AAAAAA
          font-weight: 400
          font-size: 12px
        .unset-magin
          margin-right: unset
        .option-button
          background-color: transparent
          border: none
          cursor: pointer
          padding: 0
          transform: rotate(90deg)
          .option-text
            font-size: 0.7rem
            margin-top: -5px
        .topic-actions-favories
          /deep/
            .dropdown-menu
              min-width: 9.5rem
              .dropdown-content
                padding: 3px 0

        .option-button-horizontal
          .icon
            margin-top: 10px
            color: #2DBA87

        /deep/ a.dropdown-item
          padding-right: 1rem

        .co-dropdown-item
          margin: 15px 0px 0px 18px
          align-items: center
          display: flex
          .icon-edit,
          .icon-remove
            padding-right: 15px
          .remove
            color: red
        .remove
          padding-bottom: 15px
        .dropdown-content
          div
            color: #273D35
            font-size: 14px
            font-weight: 400
    .cart-title
      color: #424242
      font-weight: 700
      font-size: 17px
      margin: 0px 23px 32px 23px

    .cart-container
      margin: 0px 23px 32px 23px
      color: #273D35
      font-size: 15px
      font-weight: 300
      word-break: break-word

    .card-detail-footer
      margin: 0 23px 32px 23px
      .heart
        margin-bottom: 15px
        font-weight: 400
        font-size: 12px
        color: #273D35
        display: flex
        align-items: center
        span
          margin-left: 4px
        &.active
          svg
            fill: #F78F54
      .comments-actions
        display: grid
        grid-template-columns: auto auto
        align-items: center
        text-align: center
        color: #7C7C7C
        font-weight: 700
        font-size: 12px
        margin: 0px 2px
        border-bottom: 1px solid #cccccc
        border-top: 1px solid #cccccc
        .action-items
          display: flex
          justify-content: center
          color: #333333
          background-color: #ffffff
          .action-icon
            margin-right: 5px
            align-items: center
        .action-items:nth-child(1)
          padding: 10px 23px 10px
          border-right: 1px solid white
        .action-items:nth-child(2)
          padding: 10px 23px 10px
        span
          img
            margin-top: 2px
    .card-detail-comments
      background: #F8F7F2
      padding: 32px 23px 32px 23px
      &.is-active
        padding: 32px 23px 60px 23px
      .card-coments-title
        color: #273D35
        font-weight: 700
        font-size: 17px
        margin-bottom: 15px
        display: flex
        align-items: center
        .card-coments-icon
          margin: 0 4px 0 16px
        .fw-normal
          font-size: 12px

    .card-detail-btn-comments
      background: #F8F7F2
      padding: 32px 23px 32px 23px
      display: none
      &.active
        display: block
      .button
        width: 100%
        border-radius: 6px
        background: #FFFFFF
        box-shadow: 0px 2px 8px #F2EFDE
        font-size: 15px
        color: #2DBA87
        padding: 23px 0px
        border: unset
    .card-detail-btn-comments-input
      position: fixed
      bottom: 0
      width: 100%
      background: #ffffff
      padding: 8px 8px 0 8px
      display: none
      &.active
        display: flex
      .mentionable
        width: 80%
      .comment-textfield
        padding: 6.5px 11.5px
        width: 100%
        border: 1px solid #dbdbdb
        border-radius: 6px
        height: 45px
        box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1)
        outline: none
        font-size: 1.25rem
      .button
        width: 20%
        height: 48px
        background: #2DBA87
        border-radius: 6px
        color: #ffffff
        margin-left: 10px
    .animation-content
      width: 80%
      background: #FFFFFF
      .modal-card
        border-radius: 20px
        .modal-card-footer
          line-height: 0
          display: inline-flex
          grid-template-columns: auto auto
          .button
            height: 48px
            border-top: 1px solid #CCCCCC
            background: #fff
            color: #2DBA87
            border-radius: 0
            font-size: 15px
            min-width: 50%
            &:focus-visible
              border: none
            &.cancel
              border-right: unset
          .ok
            font-weight: bold
            border-left: 1px solid #CCCCCC

    .swiper
      &.swiper-container
        padding-bottom: 36px
      .swiper-slide
        img
          width: 100%
  .fw-bold
    font-weight: bold !important
  .fw-normal
    font-weight: normal !important
  .modal-list-like
    /deep/
      .animation-content
        width: 90%
        .modal-card
          overflow: scroll
          border-radius: 8px
          max-height: calc(812px - 190px)

</style>
