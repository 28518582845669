<template>
  <div class="event-tags">
    <ul>
      <li
        :class="{ 'active': activeClass('all')}"
        @click="onClick('all')"
      >
        全て表示
      </li>
      <li
        :class="{ 'active': activeClass('distance')}"
        @click="onClick('distance')"
      >
        距離順
      </li>
      <li
        :class="{ 'active': activeClass('web')}"
        @click="onClick('web')"
      >
        オンライン開催
      </li>
      <li
        :class="{ 'active': activeClass('participated')}"
        @click="onClick('participated')"
      >
        予約済
      </li>
      <li
        :class="{ 'active': activeClass('finished')}"
        @click="onClick('finished')"
      >
        開催済
      </li>
<!--          - wait comfirm-->
<!--      <li-->
<!--          :class="{ 'active': activeClass('day')}"-->
<!--          @click="onClick('day')"-->
<!--      >-->
<!--        日付順-->
<!--      </li>-->
    </ul>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  components: {},
  props: {
  },
  data () {
    return {}
  },
  computed: {
    ...mapState('filter', ['events'])
  },
  mounted () {
  },
  methods: {
    ...mapActions('filter', [
      'setEvent'
    ]),
    ...mapActions('home', [
      'fetchRecents'
    ]),
    activeClass (value) {
      return this.events[value]
    },
    onClick (value) {
      this.setEvent(value)
      this.fetchRecents({ currentCommunity: 0 })
    }
  }
}
</script>
<style scoped lang="sass">
  ::-webkit-scrollbar
    display: none
  .event-tags
    width: 100%
    padding-bottom: 24px
    padding-top: 16px
    padding-left: 16px
    padding-right: 16px
    ul
      display: -webkit-box
      overflow: scroll
      height: 26px
      li
        border: 1px solid #EEEEEE
        border-radius: 90px
        padding: 6px 12px
        background: #FFFFFF
        color: #2DBA87
        font-size: 14px
        font-weight: 400
        text-align: center
        margin-right: 8px
        display: flex
        align-items: center
        &.active
          background: #D5F1E7
          border: 1px solid #2DBA87
        &:last-child
          margin-right: unset
</style>
