<template>
  <iframe
    :src="src"
    frameborder="0"
    sandbox="allow-top-navigation"
  />
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped lang="sass">
  iframe
    width: 95%
    min-height: 200px
    height: 400px
    border: 1px solid #ccc
</style>
