<template>
  <div class="organization-card">
    <div class="organization-container">
      <div class="organization-box">
        <div class="organization-header">
          <div class="organization-thumbnail"><img :src="imagePath" class="media__thumbnail"></div>
          <div class="organization-information">
            <div class="organization-name" v-text="name" />
          </div>
        </div>

        <div class="organization-profile" >
          <div class="organization-notice" v-if="!joined">このコミュニティにはまだ参加していません</div>
          <div class="organization-description" v-text="description" />
        </div>
        <div class="card-action" >
          <div class="card-action-cancel-box" @click="closeModal()"><div class="cancel-text">キャンセル</div></div>
          <div class="card-action-next-box" v-if="joined" @click="goOrganizationDetail()"><div class="next-text">コミュニティTOPへ</div></div>
          <div class="card-action-next-box" v-else @click="joinOrganization()"><div class="next-text">参加する</div></div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  props: {
    imagePath: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    id: {
      type: Number,
      required: true
    },
    joined: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    goOrganizationDetail () {
      window.location.href = `/home?community_id=${this.id}`
    },
    joinOrganization(){
      window.location.href = `/communities/${this.id}/join_communities/new`
    },
    closeModal(){
      this.$emit('close-modal')
    }
  },
}
</script>

<style scoped lang="sass">
@import '~stylesheets/resources'

.organization-card
  display: flex
  align-items: flex-start
  font-size: 0.9rem
  position: relative
  overflow: hidden
  border-radius: 20px
  & > *
    box-sizing: border-box
  table
    font-size: 10px

.organization-container
  width: 100%
  background: white

.organization-box
    width: 100%

.organization-header
  display: flex
  justify-content: space-around
  align-items: center
  margin-bottom: 1em
  padding-top: 25px

  .organization-thumbnail
    position: relative
    display: inline-block

  .organization-information
    margin-left: 1.0em
    display: inline-block
    width: calc(100% - 64px - 6em )

    .organization-name
      font-size: 1.1em

.organization-profile
  padding-left: 25px
  padding-right: 25px
  padding-bottom: 15px

  .organization-notice
    font-size: 0.8em
    color: #f68f54
    font-weight: bold

  .organization-description
    font-size: 0.8em
    color: #273D35
 

.card-action
  display: flex
  justify-content: space-between
  align-items: center
  border-top: 1px solid
  border-top-color:  #CCCCCC
  color: #2DBA87
      
  .card-action-cancel-box
    display: flex
    align-items: center
    justify-content: center
    height: 50px
    width: 50%
    border-right: 1px solid
    border-right-color:  #CCCCCC

    .cancel-text
      text-align: center
      
  .card-action-next-box
    display: flex
    align-items: center
    justify-content: center
    height: 50px
    width: 50%
    font-weight: bold

  .next-text
    text-align: center


  </style>
