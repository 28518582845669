<template>
  <div class='list-user'>
    <div class="users-like-title">
      <p>いいねをおした人一覧</p>
    </div>
    <div class="justify-content-between">
      <div class="count-user">
        <p>{{users.length}}人</p>
      </div>
    </div>
    <div class="users">
      <MyCommuUserCard
        v-for="(user, index) in users"
        :id="user.id"
        :key="index"
        :avatar-path="user.avatarPath"
        :name="user.fullName"
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  props: {
    users: {
      type: Array,
      default: () => []
    }
  },
  computed: {
  },
  mounted () {
    this.toggerMyCommuButton(false)
    this.toggerHeader(true)
  },
  methods: {
    ...mapActions('mycommu', [
      'toggerMyCommuButton',
      'toggerHeader'
    ])
  }
}
</script>
<style scoped lang="sass">
.list-user
  background: white
  .users-like-title
    background: #cccccc
    padding: 10px
    text-align: center
    font-weight: bold
.justify-content-between
  display: flex
  justify-content: space-between
  .new-title
    font-size: 12px
    font-weight: 700
    padding: 16px
  .count-user
    font-size: 12px
    font-weight: 700
    padding: 16px

.users
  border-bottom: 1px solid #CCCCCC
</style>
