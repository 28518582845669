<template>
  <div>
    <div
      v-for="(item, index) in items"
      :id="nestedItemId(index)"
      :key="item._key"
    >
      <div class="card is-mb-3">
        <div class="card-content">
          <input
            v-if="item.id && item.id.value"
            :name="nestedFieldName('id', index)"
            :value="item.id.value"
            type="hidden"
          >
          <ChildrenSelectInput v-bind="nestedArrayInputProps(item.childProfileIds, index)" />
          <MyCommuBaseButton
            v-if="!isLiving"
            text-content="子どもを追加する"
            @clickButton="redirect(`/communities/child_profiles/new?redirect=${getPathName}`)"
            class="is-mb-3"
          />
          <MyCommuBaseDatePicker
            v-bind="nestedInputProps(item.startedAt, index)"
            :on-change="onChangeStartedAt"
            :set-z-index="true"
            :date-time="true"
            title="開始日時を選択してください"
          />
          <MyCommuBaseDatePicker
            v-bind="nestedInputProps(item.finishedAt, index)"
            :on-change="onChangeFinishedAt"
            :set-z-index="true"
            :date-time="true"
            title="終了日時を選択してください"
          />
          <BField
            :message="errors.request_time_interval"
            type="is-danger"
          />
          <BaseCounterButton v-bind="nestedInputProps(item.mealCount, index)" />
          <BaseCounterButton v-bind="nestedInputProps(item.sweetCount, index)" />
          <BaseCounterButton v-bind="nestedInputProps(item.bathCount, index)" />
          <BaseCheckboxInput
            v-bind="nestedInputProps(item.needOtherSupport, index)"
            :on-change="handleChangeNeedOtherSupport"
          />
          <template v-if="visibleNeedOtherSupportFields">
            <BaseTextInput v-bind="nestedInputProps(item.otherSupportBody, index)" />
            <AddonTextInput v-bind="nestedInputProps(item.otherSupportPrice, index)" />
          </template>
          <button
            v-if="enableNestedForm"
            type="button"
            class="button is-danger"
            @click="remove(index)"
            v-text="removeLabel"
          />
        </div>
      </div>
    </div>

    <div
      v-for="(destroyId, index) in destroyIds"
      :key="`destroy-${destroyId}`"
    >
      <input
        :name="nestedFieldName('id', items.length + index)"
        :value="destroyId"
        type="hidden"
      >

      <input
        :name="nestedFieldName('_destroy', items.length + index)"
        type="hidden"
        value="1"
      >
    </div>

    <button
      v-if="enableNestedForm"
      type="button"
      class="button is-primary"
      @click="add"
      v-text="addLabel"
    />
  </div>
</template>

<script>
import HasManyFormNestable from 'mixins/HasManyFormNestable'

export default {
  mixins: [HasManyFormNestable],
  props: {
    errors: {
      type: Object,
      default: () => ({})
    },
    enableNestedForm: {
      type: Boolean,
      default: false
    },
    checkedAnyNeedOtherSupportFields: {
      type: Boolean,
      required: true
    },
    onChangeStartedAt: {
      type: Function,
      default: () => ({})
    },
    onChangeFinishedAt: {
      type: Function,
      default: () => ({})
    }
  },
  data () {
    return {
      visibleNeedOtherSupportFields: this.checkedAnyNeedOtherSupportFields
    }
  },
  computed: {
    getPathName () {
      return window.location.pathname
    }
  },
  methods: {
    handleChangeNeedOtherSupport (value) {
      this.visibleNeedOtherSupportFields = value
    },
    redirect (url) {
      window.location.href = url
    }
  }
}
</script>

<style scoped lang="sass">
</style>
