import axios from 'axios'
import createChannel from 'client/cable'
import { commuError } from 'lib/app-toast'
import session from './session'

function showChatMessageCreateError (error) {
  if (!error.response) {
    commuError(error.message)
    return
  }

  const { response: { data } } = error
  if (data && data.error_messages) {
    data.error_messages.forEach(msg => commuError(msg))
  } else {
    commuError(error.message)
  }
}

/* eslint-disable no-param-reassign */
const store = {
  namespaced: true,
  state: {
    currentChat: null
  },
  getters: {},
  mutations: {
    setChannel (state) {
      state.channel = createChannel(
        {
          channel: 'ChatChannel',
          chat_id: state.currentChat.id
        },
        {
          received: (data) => {
            switch (data.action) {
              case 'create_chat_message':
                state.currentChat.chatMessages.push(data.chat_message)
                break
              case 'commu_create_message':
                state.currentChat.chatMessages.push(data.chat_message)
                if (session.state.currentUser.id !== data.chat_message.writerId) {
                  axios.post(
                    `/communities/chats/${state.currentChat.id}/seen`
                  )
                }
                break
              case 'chat_react':
                // eslint-disable-next-line no-case-declarations
                const message = state.currentChat.chatMessages.find(message => message.id === data.chat_message.id)
                message.heart = true
                break
              default:
                break
            }
          }
        }
      )
    },
    setCurrentChat (state, chat) {
      state.currentChat = chat
    }
  },
  actions: {
    initChat ({ commit }, chat) {
      commit('setCurrentChat', chat)
      commit('setChannel')
    },
    createChatMessage ({ state }, content) {
      const chatMessageParam = { chat_message: { content } }
      axios.post(
        `/api/v1/chat/${state.currentChat.id}/chat_messages`,
        chatMessageParam
      ).catch(e => showChatMessageCreateError(e))
    },
    createImageChatMessage ({ state: { currentChat } }, image) {
      const data = new FormData()
      data.append('chat_message[content]', '')
      data.append('chat_message[image]', image)
      axios.post(
        `/api/v1/chat/${currentChat.id}/chat_messages`,
        data
      )
    },
    commuCreateChatMessage ({ state }, content) {
      const chatMessageParam = { chat_message: { content } }
      axios.post(
        `/communities/chats/${state.currentChat.id}/create_message`,
        chatMessageParam
      ).catch(e => showChatMessageCreateError(e))
    },
    commuCreateImageChatMessage ({ state: { currentChat } }, image) {
      const data = new FormData()
      data.append('chat_message[content]', '')
      data.append('chat_message[image]', image)
      axios.post(
        `/communities/chats/${currentChat.id}/create_message`,
        data
      )
    },
    commuStaffCreateMessage ({ state }, content) {
      // eslint-disable-next-line camelcase
      const source_type = 'StaffCandidate'
      const chatMessageParam = { chat_message: { content, source_type } }
      axios.post(
        `/communities/chats/${state.currentChat.id}/create_message`,
        chatMessageParam
      ).catch(e => showChatMessageCreateError(e))
    },
    commuStaffCreateImageMessage ({ state: { currentChat } }, image) {
      const data = new FormData()
      data.append('chat_message[content]', '')
      data.append('chat_message[image]', image)
      data.append('source_type', 'StaffCandidate')
      axios.post(
        `/communities/chats/${currentChat.id}/create_message`,
        data
      )
    },
    reactMessage ({ state }, { messageId, type }) {
      const data = { type }
      axios.post(
        `/communities/chats/${state.currentChat.id}/chat_reacts/${messageId}/react`,
        data
      )
    }
  }
}
export default store
