<template>
  <div :class="{'padding-15': isChange}">
    <div>
      <MyCommuTopBar
        v-if="showSearch"
        :nav-paths="navPaths"
        :is-change="isChange"
        :hide-top="isChange"
        :show-for-search="showSearch"
      />
    </div>
    <div class="my-commu-domain search-area-menu justify-space-between">
      <div class="side-menu left-side" @click="toggerSidebar(true)" v-if="!showSearch">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
             :class="{'is-hide': !showCancelText }">
          <path d="M4 5H20" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M4 19H12" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M4 12H20" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        <div class="title-home" v-if="isShowIconHeader">ホーム</div>
      </div>
      <div class="side-menu left-side" v-if="showSearch">
        <svg
          width="21"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          @click="back()"
        >
          <path
            d="M16.0697 3.8501L7.92969 12.0001L16.0697 20.1501"
            stroke="#2DBA87"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <div class="right-side" v-if="!showSearch">
        <img src="../../images/icons/search.svg" alt="" @click="openURL('/communities/'+communityId+'/community_search')" v-if="isShowIconHeader">
        <img src="../../images/icons/mail_2.svg" alt="" @click="openURL('/communities/chats')" v-if="!hiddenHeader.notificationAndMessage">
        <div class="unopened-message-count" v-if="unOpenedMessageCount > 0 && !hiddenHeader.notificationAndMessage" @click="openURL('/communities/chats')">{{unOpenedMessageCount}}</div>
        <img src="../../images/icons/bell.svg" alt="" @click="openURL('/communities/notifications')" v-if="!hiddenHeader.notificationAndMessage">
        <div class="unopened-notification-count" v-if="hasNotification && !hiddenHeader.notificationAndMessage" @click="openURL('/communities/notifications')">{{unOpenedNotificationCount}}</div>
      </div>
      <div
        v-if="titleHeader.display"
        class="search-user-title"
        :class="{'has-right-text': titleHeader.rightText !== '', 'ml-16': !isShowIconHeader}"
        @click="openPreview"
      >
        {{ titleHeader.content }}
      </div>
      <div
        v-if="!isFindUser && showSearch"
        class="search-input-container"
        :class="{'ml-0': !isMarginLeft, 'is-hide': hiddenHeader.searchTop, 'is-change': isChange}"
      >
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.50665 3.33333C8.33206 3.33333 9.13891 3.57811 9.82521 4.03668C10.5115 4.49525 11.0465 5.14703 11.3623 5.90961C11.6782 6.67218 11.7608 7.51131 11.5998 8.32086C11.4388 9.13041 11.0413 9.87401 10.4577 10.4577C9.87401 11.0413 9.13037 11.4388 8.32082 11.5998C7.51127 11.7608 6.67218 11.6782 5.90961 11.3623C5.14703 11.0465 4.49521 10.5116 4.03664 9.82526C3.57807 9.13895 3.33331 8.33208 3.33331 7.50667C3.33331 6.39984 3.77299 5.33834 4.55564 4.55568C5.33829 3.77303 6.39982 3.33333 7.50665 3.33333ZM7.50665 2C6.41754 2 5.35289 2.32298 4.44733 2.92806C3.54176 3.53314 2.83595 4.39316 2.41917 5.39937C2.00238 6.40558 1.89334 7.51278 2.10581 8.58097C2.31829 9.64916 2.84273 10.6304 3.61285 11.4005C4.38297 12.1706 5.36416 12.6951 6.43235 12.9075C7.50054 13.12 8.60774 13.011 9.61395 12.5942C10.6202 12.1774 11.4802 11.4716 12.0853 10.566C12.6903 9.66045 13.0133 8.59579 13.0133 7.50667C13.0133 6.04621 12.4332 4.64557 11.4005 3.61287C10.3678 2.58017 8.96711 2 7.50665 2Z" fill="#7C7C7C"/>
          <path d="M11.1865 11.18L13.8332 13.8267" stroke="#7C7C7C" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
        </svg>

        <i class="fa fa-times font-weight-bold"
           :class="{'is-hide': showCancel }"
           @click="clearText"
        />
        <input
          ref="input"
          class="search-input"
          placeholder="キーワードを入力"
          @click="onClick"
          @keyup="changeValue"
          @keyup.enter="onEnter"
        />
      </div>
      <img src="../../images/for_community/header_plus.png" class="plus" :class="{'is-hide': hiddenHeader.plus}" @click="searchFriends()">
      <div
        class="right-text"
        :class="{'is-hide': titleHeader.rightText === ''}"
        @click="openPreview"
      >
        {{ titleHeader.rightText }}
      </div>
    </div>
    <MyCommuChatTap
      class="mt-8px"
      v-if="isFindUser"
      first-tap="ユーザー"
      second-tap="シェア・コンシェルジュ"
      @changeTab="getActiveTab"
    />
    <div
      v-if="isFindUser"
      class="search-user-input"
      :class="{'is-medium': !showCancelText, 'ml-0': !isMarginLeft, 'is-hide': hiddenHeader.searchBottom}"
    >
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.50665 3.33333C8.33206 3.33333 9.13891 3.57811 9.82521 4.03668C10.5115 4.49525 11.0465 5.14703 11.3623 5.90961C11.6782 6.67218 11.7608 7.51131 11.5998 8.32086C11.4388 9.13041 11.0413 9.87401 10.4577 10.4577C9.87401 11.0413 9.13037 11.4388 8.32082 11.5998C7.51127 11.7608 6.67218 11.6782 5.90961 11.3623C5.14703 11.0465 4.49521 10.5116 4.03664 9.82526C3.57807 9.13895 3.33331 8.33208 3.33331 7.50667C3.33331 6.39984 3.77299 5.33834 4.55564 4.55568C5.33829 3.77303 6.39982 3.33333 7.50665 3.33333ZM7.50665 2C6.41754 2 5.35289 2.32298 4.44733 2.92806C3.54176 3.53314 2.83595 4.39316 2.41917 5.39937C2.00238 6.40558 1.89334 7.51278 2.10581 8.58097C2.31829 9.64916 2.84273 10.6304 3.61285 11.4005C4.38297 12.1706 5.36416 12.6951 6.43235 12.9075C7.50054 13.12 8.60774 13.011 9.61395 12.5942C10.6202 12.1774 11.4802 11.4716 12.0853 10.566C12.6903 9.66045 13.0133 8.59579 13.0133 7.50667C13.0133 6.04621 12.4332 4.64557 11.4005 3.61287C10.3678 2.58017 8.96711 2 7.50665 2Z" fill="#7C7C7C"/>
        <path d="M11.1865 11.18L13.8332 13.8267" stroke="#7C7C7C" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
      </svg>

      <i class="fa fa-times"
         :class="{'is-hide': showCancel }"
         @click="clearText"
      />
      <input
        class="search-input"
        placeholder="キーワードを入力"
        ref="input"
        v-on:keyup.enter="onEnter()"
        v-model="searchUser"
      />
    </div>
    <div
      class="dark-gray"
      :class="{'is-hide':isHide }"
    />
    <MyCommuSideBar
      :isNavOpen="isNavOpen"
      :navPaths="navPaths"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import MyCommuSideBar from 'components/my-commu/SideBar.vue'
import MyCommuTopBar from 'components/my-commu/TopBar'

export default {
  components: {
    MyCommuSideBar, MyCommuTopBar
  },
  props: {
    navPaths: {
      type: Object,
      required: true
    },
    isChange: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isNavOpen: false,
      search: '',
      searchUser: '',
      isHide: true,
      isCancel: false,
      isMarginLeft: true,
    }
  },
  computed: {
    showCancel () {
      if (this.isFindUser) {
        return this.searchUser === ''
      } else {
        return this.search === ''
      }
    },
    showCancelText () {
      return this.search === '' && this.isHide
    },
    // eslint-disable-next-line vue/return-in-computed-property
    ...mapState('mycommu', ['isFindUser', 'hiddenHeader', 'titleHeader', 'isShowIconHeader', 'showSearch', 'searchUsersResult', 'unseenChatCount']),
    ...mapState('session', ['communityId']),
    ...mapState('notification', ['unopenedNotificationsCount', 'friendRequests']),
    hasNotification() {
      return this.unopenedNotificationsCount + this.friendRequests.length !== 0
    },
    unOpenedNotificationCount() {
      return this.unopenedNotificationsCount + this.friendRequests.length
    },
    unOpenedMessageCount() {
      return this.unseenChatCount
    }
  },
  mounted () {
  },
  created () {
    this.fetchUnseenChatCount()
    this.commuFetchNotificationCounts()
    this.commuFetchFriendRequests()
  },
  methods: {
    ...mapActions('mycommu', [
      'toggerSidebar',
      'setSearch',
      'toggerIsSearch',
      'toggleIsShowingSearchResult',
      'searchUsers',
      'searchFriends',
      'setCurrentTab',
      'toggleShowSearch',
      'toggleSearched',
      'clearSearchUsersResult',
      'fetchUnseenChatCount'
    ]),
    ...mapActions('home', [
      'fetchRecents',
      'communitySearch'
    ]),
    ...mapActions('notification', [
      'commuFetchNotificationCounts',
      'commuFetchFriendRequests'
    ]),
    changeValue () {
      this.search = this.$refs.input.value
    },
    onEnter () {
      this.$refs.input.blur()
      if (this.isFindUser) {
        if (this.searchUser !== '') {
          this.searchUsers(this.searchUser)
        }
      } else {
        this.isHide = true
        this.toggerIsSearch(false)
        this.setSearch(this.search)
        this.communitySearch({ currentCommunity: this.communityId, params: this.search, type: 'search'})
        this.toggleIsShowingSearchResult(true)
      }
      this.toggleSearched(true)
    },

    onClick () {
      this.toggleShowSearch(true)
      this.toggerIsSearch(true)
      this.toggleIsShowingSearchResult(false)
    },

    clearText () {
      this.$refs.input.focus()
      if (this.isFindUser) {
        this.searchUser = ''
        this.clearSearchUsersResult()
      } else {
        this.$refs.input.value = ''
      }
      this.toggleSearched(false)
    },

    closeSearch () {
      window.location.href = '/home'
    },
    openURL (url) {
      window.location.href = url
    },
    getActiveTab (tab) {
      this.setCurrentTab(tab)
    },
    openPreview () {
      this.titleHeader.isClick = true
    },
    back () {
      window.history.back()
    }
  }
}
</script>
<style scoped lang="sass">
  @import '~stylesheets/resources'

  $mobile-header-z-index: $over-leaflet-z-index
  $mobile-sidenav-z-index: $over-leaflet-z-index + 2
  $mobile-sidenav-bg-z-index: $over-leaflet-z-index + 1
  $app-mobile-footer-height: 50px
  $primary: #2DBA87
  $gray: #AAAAAA

  .ml-0
    margin-left: 0 !important

  .fontsize-15
    font-size: 15px

  .dark-gray
    background-color: #7c7c7c
    height: calc(100vh - 185px)
    width: 100vw
    z-index: 9999
    position: absolute

  .is-hide
    display: none

  .mb-1
    margin-bottom: 1rem

  .my-commu-domain
    font-size: 14px
    flex-direction: row
    justify-items: center
    align-items: center
    display: flex
    padding: 0 16px
    &.search-area-menu
      background: #ffffff
      display: flex
      align-items: center
      height: 56px
      .left-side
        display: flex
        align-items: center
        color: #273D35
        font-size: 20px
        font-weight: bold
        .title-home
          margin-left: 20px
      .right-side
        display: flex
        img:not(:first-child)
          margin-left: 24px

        .unopened-message-count
          position: absolute
          right: 57px
          top: 25px
          color: white
          width: 18px
          height: 18px
          background: #EE5353
          border-radius: 50%
          display: flex
          align-items: center
          justify-content: center
          font-weight: 400
          font-size: 12px
          line-height: normal
          vertical-align: middle

        .unopened-notification-count
          position: absolute
          right: 12px
          top: 25px
          color: white
          width: 18px
          height: 18px
          background: #EE5353
          border-radius: 50%
          display: flex
          align-items: center
          justify-content: center
          font-weight: 400
          font-size: 12px
          line-height: normal
          vertical-align: middle

    .plus
      width: 24px
      height: 24px
      position: absolute
      right: 16px
      margin-bottom: 6px
    .right-text
      width: 120px
      position: absolute
      right: 10px
      margin-bottom: 6px
      color: #2DBA87
    .green-text
      color: $primary !important

    .pl-1
      padding-left: 1rem

    .is-medium
      width: 70% !important

    .is-small
      width: 55% !important

    .search-input-container
      margin-left: 20px
      width: 100%
      position: relative

      svg
        position: absolute
        top: 11px
        left: 5px
      i
        position: absolute
        top: 12px
        right: 14px
        font-size: 16px
        font-weight: 300

      .search-input
        background: #F3F3F3
        border: 1px solid #CCCCCC
        border-radius: 8px
        width: 100%
        line-height: 36px
        padding-left: 30px
        &:focus-visible
          outline: unset
        &:focus
          caret-color: $primary
  .set-width
    width: 18%
  .padding-15
    padding-top: 10px
  .search-user-title
    width: 84%
    font-weight: bold
    font-size: 20px
    line-height: 20px
    //margin-left: 16px
    align-items: center
    padding-bottom: 4px
  .has-right-text
    width: 30%
  .search-user-input
    background: white
    padding: 10px 16px
    width: 100%
    position: relative

    svg
      position: absolute
      top: 11px
      left: 5px
      margin: 10px 20px
    i
      position: absolute
      top: 12px
      right: 14px
      font-size: 16px
      font-weight: 300
      margin: 10px 14px

    .search-input
      font-family: "Hiragino Kaku Gothic ProN"
      background: #F3F3F3
      border: 1px solid #CCCCCC
      border-radius: 8px
      width: 100%
      line-height: 36px
      padding-left: 30px
      font-size: 15px
      &:focus-visible
        outline: unset
      &:focus
        caret-color: $primary
  .mt-8px
    margin-top: 8px
  .ml-16
    margin-left: 16px
    width: 90%
  .justify-space-between
    justify-content: space-between
</style>
