<template>
  <div>
    <Back title="マイページ編集" :showCommu="false" :showCancel="true"/>
    <div class="space">
      <form>
        <HiddenHeader/>
        <MyCommuHiddenButtonFooter :is-my-commu-domain="true"/>
        <MyCommuHiddenFooterMenu :is-my-commu-domain="true"/>
        <p class="image-note">JPG/JPEG・PNG・GIF形式、最大10MBまで</p>
        <div class="has-text-left mb-24px is-flex">
          <BUpload v-model="file" class="file-label file-upload" name="community_profile[avatar]"
                   accept=".jpg, .png, .jpeg, .gif" @input="displayFile">
            <div class="display-image">
              <img :src="avatarUrl" alt="profile-avatar" class="profile-avatar" id="avatar" />
            </div>
            <div class="edit-avatar">
              <MyCommuBaseButton
                text-content="写真を選択する"
                :button-min="true"
                :is-white-button="true"
              />
            </div>
          </BUpload>
        </div>
        <div v-if="invalidImage && changeAvatar" class="invalid-image">JPG/JPEG・PNG・GIF形式</div>
        <div class="columns is-mobile mb-24px">
          <div class="column">
            <MyCommuBaseTextInput
              id="profile_last_name"
              v-model="lastName"
              label="お名前"
              name="profile[last_name]"
              placeholder="姓"
            />
          </div>
          <div class="column mt-14px">
            <MyCommuBaseTextInput
              id="profile_first_name"
              v-model="firstName"
              label=""
              name="profile[first_name]"
              placeholder="名"
            />
          </div>
        </div>
        <div class="columns is-mobile mb-24px">
          <div class="column">
            <MyCommuBaseTextInput
              id="profile_last_name_kana"
              v-model="lastNameKana"
              label="セイ"
              name="profile[last_name_kana]"
              placeholder="メイ"
            />
          </div>
          <div class="column mt-14px">
            <MyCommuBaseTextInput
              id="profile_first_name_kana"
              v-model="firstNameKana"
              label=""
              name="profile[first_name_kana]"
              placeholder="セイ"
            />
          </div>
        </div>

        <div class="columns is-mobile mb-24px">
          <div class="column is-fullwidth select-prefecture">
            <BaseSelectNickname
              :nickname-selected="nickname !== '' && nickname !== null"
              :nickname="nickname"
              :is-my-commu-domain="true"
              :has-note="false"
              check-use-name="community_profile[use_nickname]"
              name="community_profile[nickname]"
            />
          </div>
        </div>

        <div class="columns is-mobile mb-24px">
          <div class="column is-fullwidth select-prefecture">
            <MyCommuBaseSelectInput
              id="profile_sex"
              v-model="sex"
              label="性別"
              name="profile[sex]"
              placeholder="選択する"
              :options="sexOptions"
            />
          </div>
          <div class="column"></div>
        </div>

        <div class="is-mt-1 mb-24px">
          <MyCommuBirthdayDatePicker
            id="profile_date_of_birth"
            name="profile[date_of_birth]"
            :discard-day="true"
            label="生年月日（非公開）"
            year-placeholder="1980"
            month-placeholder="12"
            v-model="dateOfBirth"
            @date-validation="dateOfBirthValidate"
          />
          <p
              v-if="!checkYearOld"
              class="name-error"
            >
              生年月日18歳以上でないと登録出来ません。
            </p>
        </div>

        <div class="columns is-mobile mb-24px">
          <div class="column is-fullwidth select-prefecture">
            <MyCommuBaseSelectInput
              id="community_profile_place_of_birth"
              v-model="placeOfBirth"
              label="出身地"
              name="community_profile[place_of_birth]"
              placeholder="選択する"
              :options="placeOfBirthOptions"
              :is-optional="true"
            />
          </div>
        </div>

        <div class="columns is-mobile mb-24px">
          <div class="column">
            <MyCommuBaseTextInput
              id="community_profile_hobby_and_skills"
              v-model="hobbyAndSkills"
              label="趣味・特技"
              name="community_profile[hobby_and_skills]"
              placeholder="カメラ"
              :is-optional="true"
            />
          </div>
        </div>

        <div class="columns is-mobile mb-24px">
          <div class="column is-fullwidth select-prefecture">
            <MyCommuBaseSelectInput
              id="community_profile_profession"
              v-model="profession"
              label="職業"
              name="community_profile[profession]"
              placeholder="選択する"
              :options="professionOptions"
              :is-optional="true"
            />
          </div>
        </div>

        <div class="columns is-mobile mb-24px">
          <div class="column">
            <MyCommuBaseTextInput
              id="community_profile_introduction"
              v-model="introduction"
              label="自己紹介"
              name="community_profile[introduction]"
              placeholder="こんにちは。休日はカフェ巡りをよくしています。どこか素敵なお店があれば教えてほしいです。どうぞよろしくお願いいたします。"
              type="textarea"
              :is-optional="true"
            />
          </div>
        </div>

        <label class="mt-24px is-flex optional">
          <div class="primary">興味あること（複数選択可）</div>
          <MyCommuOptional/>
        </label>

        <div class="is-flex" style="flex-wrap: wrap; margin-top: 16px">
          <div class="is-flex mb-8">
            <BaseCheckboxButton class="mr-8" v-bind="isInterestedCooking" />
            <BaseCheckboxButton v-bind="isInterestedSport" />
          </div>
          <div class="is-flex mb-8">
            <BaseCheckboxButton class="mr-8 " v-bind="isInterestedFashion" />
            <BaseCheckboxButton v-bind="isInterestedOutdoor" />
          </div>
          <div class="is-flex mb-8 w-100">
            <BaseCheckboxButton class="mr-8 " v-bind="isInterestedBookAndManga" />
            <BaseCheckboxButton class="mr-8 " v-bind="isInterestedParenting" />
            <BaseCheckboxButton v-bind="isInterestedPet" />
          </div>
          <div class="is-flex mb-8 w-100">
            <BaseCheckboxButton class="mr-8 " v-bind="isInterestedGame" />
            <BaseCheckboxButton class="mr-8 " v-bind="isInterestedMovieAndArt" />
            <BaseCheckboxButton v-bind="isInterestedArea" />
          </div>
          <div class="is-flex mb-8 ">
            <BaseCheckboxButton class="mr-8" v-bind="isInterestedBusiness" />
            <BaseCheckboxButton class="mr-8" v-bind="isInterestedTrip" />
            <BaseCheckboxButton v-bind="isInterestedMusic" />
          </div>
        </div>
        <BaseSelectInterestedFreeTag
          :selected="freeTagSelected"
          :tag="freeTagTag"
          :is-my-commu-domain="true"
          check-use-name="community_profile[use_interested_free_tag]"
          name="community_profile[interested_free_tag]"
          :label="communityProfile.is_interested_free_tag !== '' && communityProfile.is_interested_free_tag !== null ? communityProfile.is_interested_free_tag : 'フリータグ'"
        />

        <div class="mt-40px">
          <MyCommuBaseButton
            :disable="!canSubmit"
            text-content="保存する"
            @clickButton="update()"
          />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Back from '../../components/my-commu/Back'
import axios from 'axios'
import { commuSuccess, commuError } from 'lib/app-toast'
import {mapActions, mapState} from 'vuex'

export default {
  components: {
    Back
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    profile: {
      type: Object,
      require: true
    },
    communityProfile: {
      type: Object,
      require: true
    },
    prefecture: {
      type: String,
      default: ''
    },
    address1: {
      type: String,
      default: ''
    },
    avatar: {
      type: String,
      default: ''
    },
    sexOptions: {
      type: Array,
      default: () => [
        ['男性', 'male'],
        ['女性', 'female'],
        ['無回答', 'no_answer']
      ]
    },
    avatarPublicOptions: {
      type: Array,
      default: () => [
        ['画像を全体に公開する', true],
        ['画像を友達まで公開する', false]
      ]
    },
    isInterestedParenting: {
      type: Object,
      required: true
    },
    isInterestedPet: {
      type: Object,
      required: true
    },
    isInterestedSport: {
      type: Object,
      required: true
    },
    isInterestedCooking: {
      type: Object,
      required: true
    },
    isInterestedFashion: {
      type: Object,
      required: true
    },
    isInterestedOutdoor: {
      type: Object,
      required: true
    },
    isInterestedBookAndManga: {
      type: Object,
      required: true
    },
    isInterestedGame: {
      type: Object,
      required: true
    },
    isInterestedMovieAndArt: {
      type: Object,
      required: true
    },
    isInterestedArea: {
      type: Object,
      required: true
    },
    isInterestedBusiness: {
      type: Object,
      required: true
    },
    isInterestedTrip: {
      type: Object,
      required: true
    },
    isInterestedMusic: {
      type: Object,
      required: true
    },
    freeTagSelected: {
      type: Boolean,
      default: false
    },
    freeTagTag: {
      type: String,
      default: ''
    },
    placeOfBirthOptions: {
      type: Array,
      default: () => []
    },
    professionOptions: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      firstName: this.profile.first_name,
      lastName: this.profile.last_name,
      firstNameKana: this.profile.first_name_kana,
      lastNameKana: this.profile.last_name_kana,
      sex: this.profile.sex,
      dateOfBirth: this.profile.date_of_birth,
      publicAvatar: this.communityProfile.public_avatar,
      nickname: this.communityProfile.nickname,
      placeOfBirth: this.communityProfile.place_of_birth,
      profession: this.communityProfile.profession,
      hobbyAndSkills: this.communityProfile.hobby_and_skills,
      introduction: this.communityProfile.introduction,
      file: null,
      avatarUrl: this.avatar,
      invalidImage: false,
      changeAvatar: false
    }
  },
  computed: {
    ...mapState('session', ['communityId']),
    ...mapState('space', ['unsetMargin', 'unsetPadding']),
    ...mapState('mycommu', ['hiddenHeader']),
    checkYearOld () {
      const today = new Date();
        const birthdate = new Date(this.dateOfBirth)
        let age = today.getFullYear() - birthdate.getFullYear() -
             (today.getMonth() < birthdate.getMonth() ||
             (today.getMonth() === birthdate.getMonth() && today.getDate() < birthdate.getDate()));

      if (age < 18) {
        return false
      } else {
        return true
      }
    },
    canSubmit () {
      return (this.firstName !== '' && this.lastName !== '' && this.firstNameKana !== '' && this.lastNameKana !== '' &&
          this.sex !== '' && this.dateOfBirth !== '' && this.checkYearOld)
    },
    // eslint-disable-next-line vue/return-in-computed-property
    displayFile () {
      if (this.file) {
        const src = URL.createObjectURL(this.file)
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.invalidImage = false
        this.avatarUrl = src
      } else {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.invalidImage = true
      }
    },
    uploadFile () {
      return this.file
    }
  },
  mounted () {
    this.unsetMargin.mainContainer = true
    this.hiddenHeader.mainContainerUnsetMargin = true
    this.unsetMargin.mainContainer = true
    this.unsetMargin.appMain = true
  },
  methods: {
    dateOfBirthValidate (value) {
      this.dateOfBirth = value
    },
    update () {
      const params = new FormData(this.$el.querySelector('form'))
      axios.patch(`/communities/${this.communityId}/community_profiles/${this.communityProfile.id}`, params).then((res) => {
        if (res.data.status === 'success') {
          // commuSuccess(res.data.message)
          window.location.href = '/'
        } else {
          commuError(res.data.message)
        }
      })
    },
    // chooseImage () {
    //   this.$refs.imageAvatar.click()
    // },
    // selectImage () {
    //   const file = this.$refs.imageAvatar.files
    //   document.getElementById('avatar').src = URL.createObjectURL(file[0])
    // }
  },
  watch: {
    uploadFile () {
      this.changeAvatar = true
    }
  }
}
</script>

<style scoped lang="sass">
.name-error
  color: red
  font-size: 12px
.space
  margin-top: 40px
  padding: 0 32px
  .profile-avatar
    width: 64px
    height: 64px
    object-fit: cover
    margin-right: 24px
    border-radius: 50%
    flex-shrink: 0
  .edit-avatar
    display: flex
    align-items: center
  .mb-60px
    margin-bottom: 60px
  .mb-24px
    margin-bottom: 24px
  .mt-14px
    margin-top: 14px
.dark-gray-color
  /deep/
  span
    select
      color: #AAAAAA
.column
  padding-bottom: unset
  /deep/ label
    font-weight: 300
    font-size: 14px
    line-height: 14px
    margin-bottom: 8px
.sign-up-address-input-address__form
  border-radius: 6px
  /deep/ label
    font-weight: 300
    font-size: 14px
    line-height: 14px
    margin-bottom: 8px
  /deep/
    .field
      margin-bottom: 40px
.select-prefecture
  /deep/ span
    width: 100%
  /deep/ select
    width: 100%
    border-radius: 6px
    caret-color: #2DBA87
    &:focus
      border: 1px solid #2DBA87
      border-radius: 6px
      box-shadow: 0 0 0 0
/deep/
  .reject-button
    color: #EE5353 !important
    padding: 9px 0 !important
    &:hover
      background: #FFEEF0 !important
  .birthday-picker
    span
      &:after
        margin-top: -0.2875em !important
        height: 0.625em !important
        width: 0.625em !important
        right: 0.725em !important
        border-width: 3px !important
.d-none
  display: none
.mb-8
  margin-bottom: 8px
.mr-8
  margin-right: 8px
.mt-40px
  margin-top: 40px
.optional
  align-items: center
  justify-content: space-between
.image-note
  font-size: 12px
  color: #2DBA87
  margin: 0 0 8px
.invalid-image
  font-size: 14px
  color: red
  font-weight: 600
  margin-bottom: 16px
</style>
