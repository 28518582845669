<template>
  <div class="shared-card-container">
    <div v-if="share.sharingType === 'entry' && !share.myShare && !share.isFriendLv3">
      <MyCommuCardTranferChildCare
        :user="share.user"
      />
    </div>
    <div v-else>
      <div class="my-commu-domain card-borrow">
        <div
          class="card-borrow-type"
          :class="sharingBgColor"
        >
          <span>{{ type }}</span>
        </div>
        <div class="image-preview">
          <img v-if="share.imagePaths && share.imagePaths.length > 0" :src="share.imagePaths[0].original" loading="lazy"/>
        </div>
        <div class="infor" :class="{ 'no-share-img': !share.imagePaths || share.imagePaths.length === 0 }">
          <img :src="share.user.communityAvatarPath" alt="" class="avatar" @click="goToProfile(share.user.id)"/>
          <span class="infor-name" @click="goToProfile(share.user.id)">{{ representationName }}</span>
        </div>
        <div class="card-container" @click="goToDetail(path)">
          <div class="title" v-if="share.subject !== ''">
            <span :class="sharingTextColor"> {{ share.subject }}{{ subTitle }}</span>
          </div>
          <div class="raw-body" v-html="share.body"></div>
          <div class="card-borrow-footer">
            <ul>
              <li><span class="created-date">{{ shortenDate(share.createdAt) }}</span></li>
              <li v-if="shareStatus"><span class="status">{{ shareStatus }}</span></li>
              <li>
                <span class="price-icon">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_2679_3600)">
                      <path
                        d="M8.00004 14.7891C11.7496 14.7891 14.7891 11.7496 14.7891 8.00004C14.7891 4.25052 11.7496 1.21094 8.00004 1.21094C4.25052 1.21094 1.21094 4.25052 1.21094 8.00004C1.21094 11.7496 4.25052 14.7891 8.00004 14.7891Z"
                        fill="#FFC400" />
                      <path
                        d="M8.00002 15.992C6.41776 15.992 4.87102 15.5228 3.55542 14.6437C2.23983 13.7647 1.21449 12.5152 0.608985 11.0534C0.00348356 9.59162 -0.154966 7.98309 0.153715 6.43124C0.462397 4.87939 1.22433 3.45392 2.34315 2.3351C3.46197 1.21628 4.88744 0.454347 6.43929 0.145665C7.99114 -0.163017 9.59965 -0.00459132 11.0615 0.60091C12.5233 1.20641 13.7727 2.23181 14.6518 3.5474C15.5308 4.86299 16 6.40972 16 7.99197C15.9979 10.113 15.1543 12.1466 13.6545 13.6464C12.1547 15.1463 10.1211 15.9898 8.00002 15.992ZM8.00002 2.42181C6.89641 2.42182 5.81755 2.74918 4.90005 3.36248C3.98254 3.97578 3.26758 4.84745 2.84562 5.8672C2.42366 6.88696 2.3137 8.00898 2.52959 9.09126C2.74548 10.1735 3.27755 11.1675 4.05849 11.9473C4.83942 12.7271 5.83413 13.2577 6.91673 13.472C7.99933 13.6863 9.12113 13.5747 10.1403 13.1513C11.1594 12.7278 12.03 12.0116 12.642 11.0932C13.254 10.1748 13.5798 9.09557 13.5782 7.99197C13.5761 6.51393 12.9874 5.09715 11.9415 4.05278C10.8957 3.00841 9.47805 2.42181 8.00002 2.42181Z"
                        fill="#FFC400" />
                      <path
                        d="M8.00035 15.1847C6.57776 15.1847 5.18707 14.7628 4.00423 13.9725C2.82139 13.1821 1.89949 12.0588 1.35509 10.7445C0.810691 9.43017 0.668309 7.98396 0.945842 6.58871C1.22338 5.19345 1.90842 3.91183 2.91434 2.9059C3.92026 1.89998 5.20184 1.21494 6.59709 0.937406C7.99235 0.659873 9.43855 0.802304 10.7529 1.34671C12.0672 1.89111 13.1905 2.81303 13.9808 3.99586C14.7712 5.1787 15.1931 6.56935 15.1931 7.99194C15.191 9.89891 14.4324 11.7272 13.084 13.0756C11.7356 14.424 9.90732 15.1825 8.00035 15.1847V15.1847ZM8.00035 1.61452C6.73708 1.61452 5.50217 1.98925 4.45191 2.69125C3.40165 3.39325 2.58324 4.391 2.10018 5.55827C1.61711 6.72553 1.49109 8.00983 1.73813 9.24871C1.98517 10.4876 2.59413 11.6254 3.48796 12.5181C4.38179 13.4108 5.5204 14.0183 6.75959 14.2638C7.99878 14.5092 9.28289 14.3816 10.4495 13.8971C11.6162 13.4125 12.6129 12.5929 13.3136 11.5417C14.0143 10.4906 14.3874 9.25521 14.3858 7.99194C14.3837 6.2998 13.71 4.6777 12.5127 3.48194C11.3155 2.28618 9.69248 1.61452 8.00035 1.61452V1.61452Z"
                        fill="#FFFF4A" />
                      <path
                        d="M9.08197 12.1897H6.91047C6.80407 12.1877 6.70261 12.1445 6.62735 12.0692C6.5521 11.994 6.5089 11.8925 6.50684 11.7861V4.20588C6.50684 4.09883 6.54929 3.99617 6.62499 3.92047C6.70069 3.84478 6.80342 3.80225 6.91047 3.80225H9.08197C9.18902 3.80225 9.29165 3.84478 9.36735 3.92047C9.44304 3.99617 9.4856 4.09883 9.4856 4.20588V11.8184C9.47746 11.9197 9.43134 12.0143 9.35651 12.0831C9.28167 12.152 9.18365 12.19 9.08197 12.1897ZM7.3141 11.3825H8.67833V4.60951H7.3141V11.3825Z"
                        fill="#FFFF4A" />
                    </g>
                    <defs>
                      <clipPath id="clip0_2679_3600">
                        <rect width="16" height="16" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </span>
                <span class="price">{{ sharePrice }}</span>
              </li>
<!--                <li><span class="pin">-->
<!--                  <svg width="16" height="22" viewBox="0 0 16 22" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                    <path-->
<!--                      d="M13.0004 19.97L8.64038 16.2C8.46145 16.0529 8.237 15.9725 8.00537 15.9725C7.77375 15.9725 7.54936 16.0529 7.37042 16.2L3.00043 19.97C2.85661 20.0785 2.68611 20.1462 2.50702 20.1658C2.32793 20.1855 2.14688 20.1563 1.98297 20.0815C1.81906 20.0068 1.67841 19.8891 1.57587 19.741C1.47332 19.5928 1.41269 19.4198 1.40039 19.2401V2.76001C1.40039 2.49479 1.50576 2.24045 1.6933 2.05292C1.88083 1.86538 2.13517 1.76001 2.40039 1.76001H13.6704C13.9356 1.76001 14.19 1.86538 14.3775 2.05292C14.565 2.24045 14.6704 2.49479 14.6704 2.76001V19.23C14.67 19.4233 14.6135 19.6122 14.5079 19.774C14.4024 19.9359 14.2522 20.0636 14.0755 20.1419C13.8988 20.2202 13.7033 20.2457 13.5125 20.2151C13.3217 20.1846 13.1439 20.0995 13.0004 19.97Z"-->
<!--                      stroke="#CCCCCC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />-->
<!--                  </svg>-->
<!--                </span></li>-->
            </ul>
          </div>
          <div class="mt-24">
            <div class="icon-count">
              <div class="count-likes">
                <svg width="16" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6.26652 11.9398L1.54651 7.21315C0.858378 6.54912 0.44021 5.65401 0.372516 4.70013C0.304821 3.74625 0.592394 2.80104 1.17985 2.04648C1.51345 1.63931 1.9287 1.30659 2.3988 1.06976C2.86891 0.832928 3.38342 0.697258 3.90918 0.671504C4.43494 0.64575 4.96029 0.730471 5.45129 0.920222C5.94229 1.10997 6.38804 1.40054 6.75984 1.77317L6.9932 2.00648L7.1132 1.88649C7.77723 1.19836 8.67234 0.780193 9.62622 0.712499C10.5801 0.644804 11.5253 0.932377 12.2799 1.51983C12.687 1.85344 13.0198 2.26868 13.2566 2.73878C13.4934 3.20889 13.6291 3.7234 13.6549 4.24916C13.6806 4.77492 13.5959 5.30026 13.4061 5.79125C13.2164 6.28225 12.9258 6.72803 12.5532 7.09983L7.71985 11.9398C7.52639 12.1312 7.2653 12.2385 6.9932 12.2385C6.7211 12.2385 6.45997 12.1312 6.26652 11.9398Z" fill="#d3d3d3"/>
                </svg>
                <span v-if="share.likesCount > 0">{{share.likesCount}}</span>
              </div>
              <div class="count-comments">
                <svg width="14" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.54683 0.873047C7.48637 0.874344 6.44438 1.15058 5.52254 1.67478C4.6007 2.19899 3.83052 2.95325 3.28719 3.86395C2.74386 4.77465 2.44596 5.81067 2.42253 6.87087C2.3991 7.93107 2.65096 8.97923 3.15352 9.91305C3.76686 11.033 1.4935 12.6664 1.3335 12.9264C1.97193 13.0602 2.6311 13.0612 3.26994 12.9293C3.90878 12.7974 4.51361 12.5355 5.04683 12.1597C6.07671 12.8548 7.30647 13.192 8.54683 13.1197C10.1359 13.0673 11.6423 12.3992 12.7478 11.2565C13.8534 10.1139 14.4714 8.58628 14.4714 6.99638C14.4714 5.40648 13.8534 3.87883 12.7478 2.73621C11.6423 1.59358 10.1359 0.925486 8.54683 0.873047Z" fill="#d3d3d3"/>
                </svg>
                <span v-if="share.commentsCount > 0">{{ share.commentsCount }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="comments-actions">
          <a @click="like">
            <div class="action-items active-heart">
              <span class="action-icon icon-heart">
                <img width="14" height="14" src="../../../images/for_community/heart_in_active_icon.png" v-if="!isLiked">
                <img width="14" height="14" src="../../../images/for_community/heart_active_icon.png" v-if="isLiked">
              </span>
              <span>いいね</span>
            </div>
          </a>
          <div class="action-items active-comment"
               @click="gotoComment"
          >
            <span class="action-icon icon-comment">
              <img width="14" height="14" src="../../../images/for_community/comment_icon.png">
            </span>
            <span>コメント</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import { commuError } from 'lib/app-toast'

export default {
  components: {},
  props: {
    path: {
      type: String,
      required: true
    },
    share: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      sharingBgColor: '',
      sharingTextColor: '',
      type: '',
      isLiked: this.share.liked
    }
  },
  computed: {
    ...mapState('session', ['communityId']),
    image () {
      if (this.share.images[0] !== undefined) {
        return this.share.images[0].url
      } else {
        return this.share.default_image
      }
    },
    sharingType () {
      if (this.share.sharingType === 'entry') {
        return 'お願いします'
      } else if (this.share.sharingType === 'borrowing') {
        return '貸して'
      } else if (this.share.sharingType === 'lending') {
        return '貸すよ'
      } else if (this.share.sharingType === 'taking') {
        return '譲って'
      } else if (this.share.sharingType === 'giving') {
        return '譲るよ'
      } else {
        return '預かるよ'
      }
    },
    subTitle () {
      if (this.share.sharingType === 'entry') {
        return 'お願いします'
      } else if (this.share.sharingType === 'borrowing') {
        return 'を貸してください'
      } else if (this.share.sharingType === 'lending') {
        return 'を貸します'
      } else if (this.share.sharingType === 'taking') {
        return 'を譲ってください'
      } else if (this.share.sharingType === 'giving') {
        return 'を譲ります'
      } else {
        return '預かるよ'
      }
    },
    representationName () {
      if (this.share.user.commuNickname === null) {
        return this.share.user.fullName
      } else {
        return this.share.user.commuNickname
      }
    },
    shareStatus () {
      if (this.share.sharingType === 'entry') {
        const finished = new Date(this.share.requestFinishedAt)
        if (finished > Date.now()) {
          if (this.share.requestState === 'solved') {
            return 'シェア成立'
          } else {
            return '募集中'
          }
        } else {
          return '募集終了'
        }
      } else if (this.share.sharingType === 'taking' || this.share.sharingType === 'giving' || this.share.sharingType === 'lending' || this.share.sharingType === 'borrowing') {
        if (this.share.isClosed) {
          return '募集終了'
        }
      } else if (this.share.sharingType === 'living') {
        const finished = new Date(this.share.requestFinishedAt).getTime()
        const today = new Date(Date.now()).getTime()
        if (finished < today || this.share.requestState === 'solved' || this.share.requestState === 'canceled') {
          return '募集終了'
        }
      } else {
        if (this.share.isClosed) {
          return '募集終了'
        } else {
          const deadlineOn = new Date(this.share.deadlineOn).getTime()
          const today = new Date(Date.now()).getTime()
          if (deadlineOn < today) {
            return '募集終了'
          }
        }
      }
    },
    sharePrice () {
      if (this.share.price === 'point') {
        return '0円'
      } else if (this.share.sharingType === 'entry') {

      } else {
        return this.share.priceText
      }
    }
  },
  mounted () {
    switch (this.share.sharingType) {
      case 'borrowing':
        this.type = '貸して'
        this.sharingBgColor = 'bg-green'
        this.sharingTextColor = 'green'
        break
      case 'entry':
        this.type = '預かって'
        this.sharingBgColor = 'bg-blue'
        this.sharingTextColor = 'blue'
        break
      case 'lending':
        this.type = '貸すよ'
        this.sharingBgColor = 'bg-sky-blue'
        this.sharingTextColor = 'sky-blue'
        break
      case 'giving':
        this.type = '譲るよ'
        this.sharingBgColor = 'bg-blue'
        this.sharingTextColor = 'blue'
        break
      case 'taking':
        this.type = '譲って'
        this.sharingBgColor = 'bg-pink'
        this.sharingTextColor = 'pink'
        break
      case 'living':
        this.type = 'くらしのサポート'
        this.sharingBgColor = 'bg-yellow'
        this.sharingTextColor = 'yellow'
        break
    }
  },
  methods: {
    shortenDate (datestring) {
      const dateobject = new Date(datestring)
      const month = (dateobject.getMonth() + 1).toString().padStart(2, '0')
      const day = dateobject.getDate().toString().padStart(2, '0')
      const year = dateobject.getFullYear().toString().padStart(4, '0')

      return year + '.' + month + '.' + day
    },
    like () {
      if (this.share_group && !this.joined) {
        this.$emit('confirmJoin', true)
      } else {
        const data = {
          share: {
            type: this.share.sharingType,
            id: this.share.id
          }
        }
        axios.post(`/communities/${this.communityId}/share_posts/handle_like`, data).then((response) => {
          if (response.data.action === 'like') {
            this.isLiked = true
            this.share.likesCount++
          } else if (response.data.action === 'unlike') {
            this.isLiked = false
            this.share.likesCount--
          } else {
            commuError(response.data.message)
          }
        })
      }
    },
    gotoComment () {
      if (this.topic_group && !this.joined) {
        this.$emit('confirmJoin', true)
      } else {
        location.href = `${this.path}?action=comment`
      }
    },
    redirect (url) {
      window.location.href = url
    },
    goToDetail (url) {
      location.href = `${url}`
    },
    goToProfile (id) {
      window.location.href = `/communities/${this.communityId}/users/${id}`
    }
  }
}
</script>
<style scoped lang="sass">
  @import '~stylesheets/resources'
  .green
    color: #2DBA87 !important
  .bg-green
    background: #2DBA87 !important
  .pink
    color: #F9A6BF !important
  .bg-pink
    background: #F9A6BF !important
  .yellow
    color: #E8B85D !important
  .bg-yellow
    background: #E8B85D !important
  .blue
    color: #6F8FE3 !important
  .bg-blue
    background: #6F8FE3 !important
  .sky-blue
    color: #7ECAE9 !important
  .bg-sky-blue
    background: #7ECAE9 !important
  .shared-card-container
    border-radius: 12px
  .card-borrow
    position: relative
    width: 92%
    margin: auto
    border-radius: 12px
    background: #FFFFFF
    margin-bottom: 20px
    .card-borrow-type
      padding: 17px 24px
      height: 48px
      position: absolute
      background: #6F8FE3
      border-radius: 16px 16px 16px 0px
      text-align: center
      align-items: center
      display: flex
      justify-content: center
      top: -10px
      right: 8px
      span
        color: #FFFFFF
        font-weight: 600
        font-size: 14px
    .infor
      display: flex
      text-align: center
      align-items: center
      padding-top: 3px
      padding-left: 26px
      img
        width: 40px
        height: 40px
        border-radius: 50%
        margin-right: 10px
        object-fit: cover
      span
        font-weight: 300
        font-size: 12px
        word-break: break-all
    .no-share-img
      padding-top: 40px

    .card-container
      margin-left: 32px
      margin-right: 32px
      margin-bottom: 10px
      .title
        margin-top: 10px
        margin-bottom: 7px
        color: #6F8FE3
        font-weight: 600
        font-size: 17px
      .raw-body
        font-weight: 300
        font-size: 12px
        color: #273D35
        margin-top: 7px
        margin-bottom: 3px
        word-break: break-word
      .card-borrow-footer
        padding-bottom: 3px
        color: #AAAAAA
        display: flex
        font-weight: 400
        font-size: 12px
        width: 100%
        ul
          display: flex
          grid-template-columns: auto auto auto auto
          grid-auto-rows: 4px
          width: 100%
          justify-content: space-between
          align-items: center
          li
            display: flex
            &:last-child
          .status
            background: #F3F3F3
            border: 1px solid #7C7C7C
            justify-content: center
            align-items: center
            padding: 0px 8px
            color: #7C7C7C
            font-size: 12px
          .price
            color: #273D35
            font-weight: 700
            font-size: 12px
            .price-icon
              display: flex
              align-items: center
              margin-right: 5px
  .image-preview
    width: 100%
    img
      width: 100%
      border-radius: 12px 12px 0px 0px
  .icon-count
    display: flex
    svg
      margin-right: 5px
    div
      display: flex
      align-items: center
      margin-right: 15px
      span
        font-size: 13px
        margin-top: 3px
        color: #273D35
  .comments-actions
    display: grid
    grid-template-columns: auto auto
    align-items: center
    text-align: center
    background: #ffffff
    color: #7C7C7C
    font-weight: 700
    font-size: 12px
    margin: 0 2px
    border-top: 1px solid #d3d3d3
    border-radius: 0px 0px 12px 12px
    .action-items
      display: flex
      justify-content: center
      color: #333333
      .action-icon
        margin-right: 5px
        align-items: center
    .action-items:nth-child(1)
      padding: 10px 27px 10px
      border-right: 1px solid white
    .action-items:nth-child(2)
      padding: 10px 27px 10px
    span
      img
        margin-top: 2px
</style>
