<template>
  <div style="padding-top: 85px">
    <div class="images image1">
      <img
        v-if="page === 1"
        src="../images/signin/introduction.png"
      />
      <img
        v-if="page === 2"
        src="../images/signin/introduction_page_2.png"
      />
      <img
        v-if="page === 3"
        src="../images/for_community/mama_support.png"
      />
    </div>
    <div class="icons text-has-center">
      <BIcon
        icon="circle"
        size="is-small"
        :type="page == 1 ? 'is-success' : 'is-light'"
        pack='fas'
      />
      <BIcon
        icon="circle"
        size="is-small"
        :type="page == 2 ? 'is-success' : 'is-light'"
        pack='fas'
      />
      <BIcon
        icon="circle"
        size="is-small"
        :type="page == 3 ? 'is-success' : 'is-light'"
        pack='fas'
      />
    </div>
    <div class="title text-has-center">
      <p v-if="page == 1">
        コミュニティで
        <br>
        つながろう、シェアしよう！
      </p>
      <p v-else-if="page == 2">
        地域の人と仲良くなる<br>
        交流イベントに参加
      </p>
      <p v-else>
        コミュニティのサポート役<br>
        シェア・コンシェルジュ
      </p>
    </div>
    <div class="description text-has-center">
      <p v-if="page == 1">
        メンバー同士で、情報交換や譲り合い・貸し借り、暮らしのお手伝い、送迎・託児などをシェアできます
      </p>
      <p v-else-if="page == 2">
        コミュニティで開催される交流イベントにいけば、<br/>
        ご近所さんや地域とのつながりができます
      </p>
      <p v-else>
        メンバー同士をつなぐ交流イベント企画や<br/>
        地域の情報をお伝えします
      </p>
    </div>
    <div class="redirect text-has-center">
      <BButton
        type="is-success"
        size="is-medium"
        class="is-fullwidth commu-submit-button"
        @click.prevent="next"
      >
        {{ page == 3 ? 'アカウントを登録する' : '次へ' }}
      </BButton>
      <a
        v-if="page == 1 || page == 2"
        href="/sign_ups/welcome"
        class="has-text-success"
      >スキップ</a>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    isPage: {
      type: Number,
      default: 1
    },
  },
  data () {
    return {
      page: this.isPage
    }
  },
  methods: {
    next () {
      if (this.page === 3) {
        window.location = '/sign_ups/welcome'
      } else {
        this.page += 1
      }
    },
    setWhiteBackground () {
      const el = document.querySelector('.is_my_commu_domain')
      el.classList.add('bg-white')
    }
  },
  created () {
    this.setWhiteBackground()
  }
}
</script>

<style scoped lang="sass">
  @import '~stylesheets/resources'
  .commu-submit-button
    height: 48px
    border-radius: 6px
    /deep/ span
      font-family: 'Hiragino Kaku Gothic ProN'
      font-size: 15px
      font-weight: bold
  .images
    padding: 0 33px
    margin-bottom: 1.5rem
  .title
    p
      font-size: 17px
      font-weight: 700
  .description
    margin-bottom: 1.5rem
    p
      font-size: 15px
      font-weight: 300
  .text-has-center
    text-align: center
  .icons
    width: 100%
    margin-bottom: 1.5rem
    /deep/ span
      width: 2rem
  /deep/ .has-text-success
    i
      font-size: 0.6em
      margin: 0 10px
  /deep/ .has-text-light
    i
      font-size: 0.6em
      margin: 0 10px
      color: #cccccc
  .redirect
    button
      margin-bottom: 1.5rem
    a
      font-size: 14px
      font-weight: 700
</style>
