<template>
  <div>
    <HiddenHeader />
    <Back
      title="メッセージ"
      :is-hidden-logo-commnu="true"
      :is-header-chat="true"
      back-url="/"
    />
    <div class="search-user-input">
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.50665 3.33333C8.33206 3.33333 9.13891 3.57811 9.82521 4.03668C10.5115 4.49525 11.0465 5.14703 11.3623 5.90961C11.6782 6.67218 11.7608 7.51131 11.5998 8.32086C11.4388 9.13041 11.0413 9.87401 10.4577 10.4577C9.87401 11.0413 9.13037 11.4388 8.32082 11.5998C7.51127 11.7608 6.67218 11.6782 5.90961 11.3623C5.14703 11.0465 4.49521 10.5116 4.03664 9.82526C3.57807 9.13895 3.33331 8.33208 3.33331 7.50667C3.33331 6.39984 3.77299 5.33834 4.55564 4.55568C5.33829 3.77303 6.39982 3.33333 7.50665 3.33333ZM7.50665 2C6.41754 2 5.35289 2.32298 4.44733 2.92806C3.54176 3.53314 2.83595 4.39316 2.41917 5.39937C2.00238 6.40558 1.89334 7.51278 2.10581 8.58097C2.31829 9.64916 2.84273 10.6304 3.61285 11.4005C4.38297 12.1706 5.36416 12.6951 6.43235 12.9075C7.50054 13.12 8.60774 13.011 9.61395 12.5942C10.6202 12.1774 11.4802 11.4716 12.0853 10.566C12.6903 9.66045 13.0133 8.59579 13.0133 7.50667C13.0133 6.04621 12.4332 4.64557 11.4005 3.61287C10.3678 2.58017 8.96711 2 7.50665 2Z"
          fill="#7C7C7C"
        />
        <path d="M11.1865 11.18L13.8332 13.8267" stroke="#7C7C7C" stroke-width="1.5" stroke-miterlimit="10"
              stroke-linecap="round"
        />
      </svg>

      <i
        class="fa fa-times"
        :class="{'is-hide': showCancel }"
        @click="clearText()"
      />
      <input
        ref="input"
        v-model="search"
        class="search-input"
        placeholder="名前で検索"
        @click="onClick"
        @keyup="changeValue"
        @keyup.enter="onEnter"
      >
    </div>
    <MyCommuChatCommunityTags
      v-if="hasMessage"
      @filterCommu="filterCommu"
    />
    <div class="mt-50" v-if="loadingChat">
      <MyCommuLoading :isLoading="loadingChat"/>
    </div>
    <div v-if="!loadingChat">
      <MyCommuChatCard
        v-for="chat in chats"
        :key="chat.id"
        :chat="chat"
        v-if="!chat.blocked"
      />
      <div v-if="!hasMessage && isChatIndex"
           class="no-message"
           v-html="message"
      />
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import Back from '../Back.vue'
import axios from '../../../utils/axios'

export default {
  components: { Back },
  data () {
    return {
      search: '',
      isHide: true,
      isCancel: false,
      isMarginLeft: true,
      showSearch: false
    }
  },
  props: {
    message: {
      type: String,
      default: 'メッセージはまだありません。<br />' +
        '<br />' +
        'メッセージは各ユーザーの<br />' +
        'プロフィール画面から送信でき、<br />' +
        '友達になった人へは、画面右上の「＋」を<br />' +
        'クリックですぐにメッセージを<br />' +
        '送る事もできます。'
    }
  },
  computed: {
    ...mapState('mycommu', ['chats', 'loadingChat', 'isChatIndex']),
    hasMessage () {
      return this.chats.length !== 0
    },
    countNormalNotification () {
      // eslint-disable-next-line no-unused-vars
      var count = 0
      this.chats.map((chat) => {
        if (chat.seen === false) {
          count += 1
        }
      })
      return count
    },
    showCancel () {
      return this.search === ''
    }
  },
  created () {
    this.chatIndex()
  },
  methods: {
    ...mapActions('mycommu', ['setHiddenHeader', 'chatIndex', 'setChats']),
    filterCommu (e) {
      this.chatIndex(e)
    },
    changeValue () {
      this.search = this.$refs.input.value
    },
    onEnter () {
      const data = {
        keyword: this.search
      }
      axios.post('/communities/chats/search_chat', data).then((response) => {
        this.setChats(response.data.chats)
      })
    },
    clearText () {
      this.search = ''
    },
    onClick () {
    }
  }
}

</script>

<style scoped lang="sass">
.no-message
  text-align: center
  position: absolute
  left: 50%
  top: 50%
  transform: translate(-50%, -50%)
  min-width: 310px

.search-user-input
  background: white
  padding: 10px 16px
  width: 100%
  position: relative

  svg
    position: absolute
    top: 11px
    left: 5px
    margin: 10px 20px

  i
    position: absolute
    top: 12px
    right: 14px
    font-size: 16px
    font-weight: 300
    margin: 10px 14px

  .search-input
    font-family: "Hiragino Kaku Gothic ProN"
    background: #F3F3F3
    border: 1px solid #CCCCCC
    border-radius: 8px
    width: 100%
    line-height: 36px
    padding-left: 30px
    font-size: 15px

    &:focus-visible
      outline: unset

    &:focus
      caret-color: #2DBA87FF

.is-hide
  display: none
.mt-50
  margin-top: 50%
</style>
