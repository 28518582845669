export default {
  toggerSidebar (state, value) {
    state.sidebar = value
  },

  toggerTopbar (state, value) {
    state.topbar = value
  },

  communities (state, data) {
    state.communities = data
  },

  setLoading (state, data) {
    state.loading = data
  },

  toggerHeader (state, data) {
    state.headerInActive = data
  },
  toggerMyCommuButton (state, data) {
    state.myCommuButton = data
  },
  toggerMyCommuFooterMenu (state, data) {
    state.myCommuFooterMenu = data
  },

  users (state, data) {
    state.users = data
  },

  currentCommunity (state, data) {
    state.currentCommunity = data
  },

  setSearch (state, data) {
    state.search = data
  },
  toggerIsSearch (state, data) {
    state.isSearch = data
  },
  toggleShowSearch (state, data) {
    state.showSearch = data
  },
  isTutorial (state, data) {
    state.isTutorial = data
  },
  isShowIconHeader (state, data) {
    state.isShowIconHeader = data
  },
  isShowChangeCommu (state, data) {
    state.isShowChangeCommu = data
  },
  isShowSchedule (state, data) {
    state.isShowSchedule = data
  },
  toggleIsShowingSearchResult (state, data) {
    state.isShowingSearchResult = data
  },
  toggleIsForcedShowingFooter (state, data) {
    state.isForcedShowingFooter = data
  },
  toggleIsFindUser (state, data) {
    state.isFindUser = data
  },
  toggleSearched (state, data) {
    state.searched = data
  },
  toggleIsChat (state, data) {
    state.isChat = data
  },
  searchUsersResult (state, data) {
    state.searchUsersResult = data
  },
  setSupporters (state, data) {
    state.supporters = data
  },
  setHiddenHeader (state, data) {
    state.hiddenHeader = data
  },
  setTitleHeader (state, data) {
    state.titleHeader = data
  },
  setFriends (state, data) {
    state.friends = data
  },
  setChats (state, data) {
    state.chats = data
  },
  toggleIsNewMessage (state, data) {
    state.isNewMessage = data
  },
  toggleIsChatIndex (state, data) {
    state.isChatIndex = data
  },
  isCommuChatDetail (state, data) {
    state.isCommuChatDetail = data
  },
  otherCommunities (state, data) {
    state.otherCommunities = data
  },
  setCurrentTab (state, data) {
    state.currentTab = data
  },
  setLoadingChat (state, data) {
    state.loadingChat = data
  },
  setUnseenChatCount (state, data) {
    state.unseenChatCount = data
  }
}
