<template>
  <div class="content-share-posts">
    <div class="description">
      <span>お手伝いのお願い内容入力</span>
    </div>

    <div class="post-title">
      <MyCommuBaseTextInput
        id="title"
        v-model="title"
        label="お願いしたい事"
        name="title"
        placeholder="掃除機"
      />
    </div>

    <div class="columns is-mobile is-centered">
      <div class="column ">
        <MyCommuBaseTextInput
          id="body"
          v-model="body"
          label="内容"
          name="body"
          placeholder="腰を痛めてしまったため、しばらく庭の
掃除ができていないのですが、お手伝い
していただける方いませんでしょうか？"
          type="textarea"
        />
      </div>
    </div>

    <div class="columns is-mobile is-centered">
      <div class="column">
        <label>お願いしたい日時</label>
        <MyCommuBaseDatePicker
            placeholder="選択する"
            :value="deadline_on"
            @valueSelect="valueScheduledDeadlineOn"
          />
      </div>
    </div>

    <div class="columns is-mobile is-centered">
      <div class="column">
        <BSelect
          v-model="startedAt"
          name="type_event"
          class="select-type"
          placeholder="12：15"
        >
          <option
            v-for="(opt,key) in options"
            :key="key"
            :value="opt.value"
            v-text="opt.label"
          />
        </BSelect>
      </div>
      <div class="column is-flex is-justify-content-center is-align-items-center">〜</div>

      <div class="column">
        <BSelect
          v-model="startedAt"
          name="type_event"
          class="select-type"
          placeholder="12：15"
        >
          <option
            v-for="(opt,key) in options"
            :key="key"
            :value="opt.value"
            v-text="opt.label"
          />
        </BSelect>
      </div>
    </div>

    <div class="columns is-mobile is-centered">
      <div class="column">
        <label>募集締め切り</label>
        <MyCommuBaseDatePicker
            placeholder="選択する"
            :value="deadline_on"
            @valueSelect="valueScheduledDeadlineOn"
          />
      </div>
    </div>

    <div class="columns is-mobile is-centered">
      <div class="column ">
        <MyCommuBaseSelectInput
          id="child_profile_sex"
          v-model="placeConfirm"
          label="お礼の気持ち"
          placeholder="選択してください"
          :options="placeConfirmData"
          name="placeConfirm"
          fullWidth
        />

      </div>

    </div>

    <div class="upload-images">
      <label>
        画像（最大4枚）
      </label>
      <BField class="is-white file">
        <BUpload v-model="images" class="file-label file-upload" multiple accept=".jpg, .png, .webp, .jpeg, .gif"
        >
          <span class="file-cta">
            <BIcon class="file-icon" icon="plus"></BIcon>
          </span>
        </BUpload>
        <div class="display-image">
          <div
            v-for="(imagexx, index) in images"
            :key="`images-${index}`"
            >
            <img :src="displayFile(imagexx)" />
          </div>
        </div>
      </BField>
    </div>

    <div class="columns is-mobile is-centered">
      <div class="column ">

        <MyCommuBaseSelectInput
          id="child_profile_sex"
          v-model="reward"
          label="お礼の気持ち"
          placeholder="選択してください"
          :options="rewardTypes"
          type="number"
          min="0"
          step="1"
          name="reward"
          fullWidth
        />

        <label class="note-small">借していただける方にお礼の気持ちを表す事ができます。</label>
      </div>

    </div>

    <div class="columns is-mobile is-centered ">
      <div class="column line-black">
      </div>
    </div>

    <div class="columns is-mobile is-centered column-submit">
      <div class="column">
        <BButton type='submit' @click='componentSubmit' class="is-fullwidth is-success btn-submit is-medium">
          確認する
        </BButton>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    submit: {
      type: Function,
      required: true
    },
    rewardTypes: {
      type: Array,
      required: true,
      default: []
    }
  },
  data () {
    return {
      type: "invitings",
      title: null,
      body: null,
      place: null,
      place_confirm: false,
      deadline_on: null,
      finished_at: null,
      date_confirm: false,
      reward: null,
      placeConfirmData: [
        ["メッセージのやり取りで決定", "email"]
      ],
      placeConfirm: null
    }
  },
  computed: {
    options () {
      const arr = []
      for (let h = 0; h < 24; h += 1) {
        for (let m = 0; m < 60; m += this.selectableMinutesDuration) {
          const min = `00${m}`.slice(-2)
          const time = `${h}:${min}`
          arr.push({ value: time, label: time })
        }
      }
      return arr
    }
  },
  methods: {
    componentSubmit() {
      this.submit(
        {
          type: "invitings",
          title: this.title,
          body: this.body,
          place: this.place,
          place_confirm: this.place_confirm,
          date: this.deadline_on,
          date_confirm: this.date_confirm,
          reward: this.reward,

        }
      )
    },
    valueScheduledDeadlineOn (param) {
      this.deadline_on = param
    },
  }
}
</script>

<style scoped lang="sass">
.container-share-posts
  font-family: "Hiragino Kaku Gothic ProN"
  padding: 0px 32px
  color: #424242

  .description
    padding-top: 40px
    padding-bottom: 30px
    text-align: center
    span
      font-weight: 600
      font-size: 17px
.switch-custom
  display: flex
  justify-content: right
  align-items: center
.line-black
  border-top: 1px solid #CCCCCC

.content-share-posts
  .description
    padding-bottom: 8px
    padding-top: 20px
    text-align: center
    span
      font-weight: 600
      font-size: 17px
label
  color: #273D35
  font-weight: 400
  font-size: 14px

.file-cta
  height: 48px
  color: #2DBA87 !important
  .icon
    font-size: 18px
    padding: 0
    margin: 0

.columns
  margin-top: 10px
  margin-bottom: 0px !important
.post-title
  margin-top: 24px
.is-orange
  color: #F78F54
  font-weight: 700
  font-size: 12px

.switch-columns
  margin-top: 0px
.condition-columns
  span
    font-weight: 600
    font-size: 12px
.column-accep
  .column
    display: flex
    span
      margin-left: 10px
.container-share-preview
  color: #273D35
  padding: 0px 32px
  .description
    display: flex
    justify-content: center
    margin-top: 40px
    font-weight: 300
    font-size: 15px
    margin-bottom: 25px

  .container-type--images
    margin-top: 8px
    .display-image
      display: flex
      img
        box-shadow: 0px 2px 8px #F2EFDE
        border: 1px solid #7C7C7C
        width: 48px
        height: 48px
        border-radius: 6px
        margin-right: 16px
  .container-share-preview--content
    display: flex
    padding-top: 24px
    flex-direction: column

    &.container-button
      margin-top: 100px
  .container-share-preview--title
    font-weight: 600
    font-size: 14px
  .is-halfwidth
    width: 50%
    margin-right: 10px
  .button
    color: #2DBA87
    font-weight: 700
    font-size: 15px
    height: 48px
    &.is-success
      color: #ffffff
.modal-card-body
  //display: flex
  //justify-content: center
  //align-items: center
  //flex-direction: column
  color: #273D35
  font-size: 15px
  border-radius: 20px
  text-align: center
  padding: 40px 16px
  width: 230px
  img
    margin-bottom: 20px
    height: 66px
    width: 66px

.upload-images
  .field
    flex-direction: column
    display: flex

    .display-image
      display: flex
      img
        box-shadow: 0px 2px 8px #F2EFDE
        border: 1px solid #7C7C7C
        width: 48px
        height: 48px
        border-radius: 6px
        margin-right: 16px

.event-label,.datepicker-event
  font-weight: 300
  font-size: 14px
  line-height: 14px
  color: #273D35
  display: flex
  justify-content: space-between
  align-items: center
.switch
  margin-left: 10px
.is-medium
  &.submit
    padding-top: 16px
    padding-bottom: 17px
    font-size: 15px
    font-weight: 600
    height: 48px
.no-margin-top
  margin-top: 0
.no-margin-bottom
  margin-bottom: 0
.padding-top-0
  padding-top: 0

.note-small
  color: #424242
  font-size: 12px
  font-weight: 300
  margin-top: 10px
.is-justify-content-center
  justify-content: center !important
.is-align-items-center
  align-items: center !important
</style>
