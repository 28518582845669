<template>
  <div>
    <div class="topbar"
         :class="{'background-F8F7F2': nonBackground, 'flex-10': isFlex10, 'flex-18': isFlex18, 'header-chat': isHeaderChat}"
    >
      <div class="topbar-icon-back d-flex" :class="{'has-cancel': showCancel}">
        <svg
          width="21"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          @click="back()"
          v-if="!showCancel && !hasCustomIcon"
        >
          <path
            d="M16.0697 3.8501L7.92969 12.0001L16.0697 20.1501"
            stroke="#2DBA87"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <span v-if="showCancel && !hasCustomIcon"
              @click="back()"
              class="cancel"
        >
          キャンセル</span>
        <div
          v-if="hasCustomIcon"
          @click="back()"
          v-html="iconBack"
        />
      </div>
      <div
        class="topbar-title"
        :class="{'has-cancel-title': showCancel}"
      >
        <span v-text="title"/>
      </div>
      <div v-if="!isHiddenLogoCommnu">
        <div v-if="showCancel" class="fake-block">
        </div>
        <div
          v-else
          class="topbar-avatar d-flex"
        >
          <img :src="currentCommunity.community_url"
               @click="toggerTopbar(true)"
               v-if="showCommu && headerAvatar === ''"
          >
          <img :src="headerAvatar"
               v-if="headerAvatar !== ''"
          >
        </div>
      </div>
      <div v-else class="d-flex">
        <img src="../../images/for_community/header_plus.png" class="plus" @click=searchFriends()>
        <img src="../../images/icons/bell.svg" alt="" class="bell" @click="openURL('/communities/notifications')">
        <div class="back-dots" v-if="hasNotification" @click="openURL('/communities/notifications')">
          {{ unOpenedNotificationCount }}
        </div>
      </div>
    </div>
    <MyCommuTopBarSideBar />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import MyCommuTopBarSideBar from 'components/my-commu/topbar/SideBar.vue'
import queryString from 'query-string'

export default {
  components: { MyCommuTopBarSideBar },
  props: {
    title: {
      type: String,
      required: true
    },
    showCommu: {
      type: Boolean,
      default: true
    },
    showCancel: {
      types: Boolean,
      default: false
    },
    eventId: {
      types: Number,
      default: 0
    },
    useEmit: {
      types: Boolean,
      default: false
    },
    nonBackground: {
      types: Boolean,
      default: false
    },
    iconBack: {
      type: String,
      default: ''
    },
    isFlex10: {
      types: Boolean,
      default: false
    },
    isFlex18: {
      types: Boolean,
      default: false
    },
    headerAvatar: {
      type: String,
      default: ''
    },
    isHiddenLogoCommnu: {
      type: Boolean,
      default: false
    },
    isHeaderChat: {
      type: Boolean,
      default: false
    },
    disableBackButton: {
      type: Boolean,
      default: false
    },
    backUrl: {
      type: String,
      required: false,
      default: undefined
    }
  },
  data () {
    return {}
  },
  computed: {
    ...mapState('mycommu', ['users', 'currentCommunity']),
    ...mapState('notification', ['unopenedNotificationsCount', 'friendRequests']),
    hasCustomIcon () {
      return !(this.iconBack === '')
    },
    hasNotification () {
      return this.unopenedNotificationsCount + this.friendRequests.length !== 0
    },
    unOpenedNotificationCount () {
      return this.unopenedNotificationsCount + this.friendRequests.length
    }
  },
  created () {
    this.commuFetchNotificationCounts()
    this.commuFetchFriendRequests()
  },
  methods: {
    ...mapActions('mycommu', [
      'toggerTopbar',
      'searchFriends'
    ]),
    ...mapActions('notification', ['commuFetchNotificationCounts', 'commuFetchFriendRequests', 'commuFetchFriendRequests']),
    back () {
      if (this.backUrl) {
        window.location.href = this.backUrl
      } else {
        const paramsx = queryString.parse(location.search)
        if (this.disableBackButton) return false
        if (paramsx.backTo) {
          window.location.href = this.backTo(paramsx.backTo)
        } else if (paramsx.from === 'notification') {
          window.location.href = '/communities/notifications'
        } else {
          if (this.useEmit) {
            this.$emit('back')
          } else {
            window.history.back()
          }
        }
      }
    },
    backTo (destination) {
      switch (destination) {
        case 'groups_list':
          return `/communities/${this.currentCommunity.community_id}/community_groups`
        default:
          return ''
      }
    },
    openURL (url) {
      window.location.href = url
    }
  }
}
</script>
<style scoped lang="sass">
.d-flex
  display: flex

.topbar
  height: 41px
  background: #FFFFFF
  display: flex
  //display: grid
  grid-template-columns: auto auto auto
  align-items: center

  &.background-F8F7F2
    background: #F8F7F2

  .topbar-icon-back
    flex: 2
    padding-left: 23px
    &.has-cancel
      padding-left: 16px
    .cancel
      font-weight: normal
      font-size: 15px
      line-height: 15px
      color: #2DBA87
  .topbar-title
    flex: 7
    color: #273D35
    font-weight: bold
    font-size: 16px
    text-align: center
  .has-cancel-title
    flex: 4
  .topbar-avatar
    flex: 2
    padding-right: 23px
    justify-content: flex-end
    img
      width: 32px
      height: 32px
      border-radius: 4px
  .fake-block
    flex: 2
  &.flex-10
    .topbar-title
      flex: 11
  &.flex-18
    .topbar-title
      flex: 18
  &.header-chat
    .topbar-title
      flex: 15
      text-align: left
  .d-flex
    display: flex

    .back-dots
      position: absolute
      right: 12px
      top: 20px
      color: white
      width: 18px
      height: 18px
      background: #EE5353
      border-radius: 50%
      display: flex
      align-items: center
      justify-content: center
      font-weight: 400
      font-size: 12px
      line-height: normal
      vertical-align: middle
  .plus
    width: 24px
    height: 24px
  .bell
    margin: 0 16px
</style>
