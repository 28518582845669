<template>
  <div class="template">
    <div class="first-content" @click="clickContent()">
      <div class="avatar">
        <img :src="avatarPath" alt="...">
      </div>
      <div class="name-commu">
        <div class="name" v-html="name"/>
        <div class="min-text" v-text="minText"></div>
      </div>
    </div>
    <div class="button-action">
      <MyCommuBaseButton
        v-if="hasButton"
        :text-content="buttonText"
        :button-secondary-s="true"
        :is-white-button="true"
        :custom-class="customClass"
        @clickButton="clickButton()"
      />
    </div>
  </div>
</template>

<script>

export default {
  components: { },
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    name: {
      type: String,
      required: true
    },
    avatarPath: {
      type: String,
      default: null
    },
    buttonType: {
      type: String,
      default: null
    },
    buttonText: {
      type: String,
      default: null
    },
    hasButton: {
      type: Boolean,
      default: true
    },
    // eslint-disable-next-line vue/require-default-prop
    community: {
      type: [Object, Array],
      require: true
    },
    minText: {
      type: String,
      default: ''
    }
  },
  data () {
    return {}
  },
  computed: {
    customClass () {
      if (this.buttonType === 'error') {
        return 'reject-button'
      } else {
        return ''
      }
    }
  },
  mounted () {

  },
  methods: {
    clickButton () {
      this.$emit('clickButton', this.community)
    },
    clickContent () {
      this.$emit('clickContent', this.community)
    }
  }
}
</script>
<style scoped lang="sass">
.template
  display: flex
  justify-content: space-between
  padding: 16px
  border-top: 1px solid #CCCCCC
  align-items: center
  background-color: #FFFFFF
  .first-content
    align-items: center
    display: flex
    width: 100%
    .avatar
      width: 64px
      height: 64px
      margin-right: 16px
      img
        width: 64px
        height: 64px
        object-fit: cover
        flex-shrink: 0
        border-radius: 4px
    .name-commu
      width: calc(100% - 94px)
      .name
        font-size: 15px
        color: #273D35
      .min-text
        font-size: 12px
        color: #7C7C7C
  .button-action
    /deep/
      .base-button
        width: 72px
/deep/
  .reject-button
    color: #EE5353!important
    &:hover
      background: #FFEEF0!important
</style>
