<template>
  <div>
    <div v-if="(!isChange && !isSearch)">
      <MyCommuTopBar
        :navPaths="navPaths"
        :isChange="isChange"
      />
    </div>
    <div class="my-commu-domain search-area-menu center-item">
      <div class="side-menu center-item">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" :class="{'is-hide': !showCancelText }" @click="toggerSidebar(true)">
          <path d="M4 5H20" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M4 19H12" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M4 12H20" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" :class="{'is-hide': showCancelText }" @click="closeSearch"
        >
          <path d="M16.0702 3.8501L7.93018 12.0001L16.0702 20.1501" stroke="#2DBA87" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>

      <div class="search-input-container center-item"
           :class="{'ml-0': !isMarginLeft}"
      >
        <div
          class="fontsize-20 fontweight-700 center-item text-title"
          :class="{'fontsize-18': !isSearch && isChange, 'fontsize-16': isSearch }"
          v-text="changeText"
        >
        </div>
      </div>
      <div class="center-item" :class="{'is-hide': !showCancelText }">
        <svg width="20" height="20" viewBox="0 0 16 16" fill="#2DBA87" xmlns="http://www.w3.org/2000/svg" @click="onClick"
        >
          <path d="M7.50665 3.33333C8.33206 3.33333 9.13891 3.57811 9.82521 4.03668C10.5115 4.49525 11.0465 5.14703 11.3623 5.90961C11.6782 6.67218 11.7608 7.51131 11.5998 8.32086C11.4388 9.13041 11.0413 9.87401 10.4577 10.4577C9.87401 11.0413 9.13037 11.4388 8.32082 11.5998C7.51127 11.7608 6.67218 11.6782 5.90961 11.3623C5.14703 11.0465 4.49521 10.5116 4.03664 9.82526C3.57807 9.13895 3.33331 8.33208 3.33331 7.50667C3.33331 6.39984 3.77299 5.33834 4.55564 4.55568C5.33829 3.77303 6.39982 3.33333 7.50665 3.33333ZM7.50665 2C6.41754 2 5.35289 2.32298 4.44733 2.92806C3.54176 3.53314 2.83595 4.39316 2.41917 5.39937C2.00238 6.40558 1.89334 7.51278 2.10581 8.58097C2.31829 9.64916 2.84273 10.6304 3.61285 11.4005C4.38297 12.1706 5.36416 12.6951 6.43235 12.9075C7.50054 13.12 8.60774 13.011 9.61395 12.5942C10.6202 12.1774 11.4802 11.4716 12.0853 10.566C12.6903 9.66045 13.0133 8.59579 13.0133 7.50667C13.0133 6.04621 12.4332 4.64557 11.4005 3.61287C10.3678 2.58017 8.96711 2 7.50665 2Z" fill="#2DBA87"/>
          <path d="M11.1865 11.18L13.8332 13.8267" stroke="#2DBA87" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
        </svg>
      </div>
      <div
        class="w-14 ml-0"
        v-if="(isChange && !isSearch) || isSearch"
      >
        <MyCommuTopBar
          :navPaths="navPaths"
          :isChange="true"
        />
      </div>
    </div>
    <div class="my-commu-domain search-area-menu" :class="{'is-hide': showCancelText}">
      <div class="search-input-container w-100" :class="{'ml-0': !isMarginLeft}">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.50665 3.33333C8.33206 3.33333 9.13891 3.57811 9.82521 4.03668C10.5115 4.49525 11.0465 5.14703 11.3623 5.90961C11.6782 6.67218 11.7608 7.51131 11.5998 8.32086C11.4388 9.13041 11.0413 9.87401 10.4577 10.4577C9.87401 11.0413 9.13037 11.4388 8.32082 11.5998C7.51127 11.7608 6.67218 11.6782 5.90961 11.3623C5.14703 11.0465 4.49521 10.5116 4.03664 9.82526C3.57807 9.13895 3.33331 8.33208 3.33331 7.50667C3.33331 6.39984 3.77299 5.33834 4.55564 4.55568C5.33829 3.77303 6.39982 3.33333 7.50665 3.33333ZM7.50665 2C6.41754 2 5.35289 2.32298 4.44733 2.92806C3.54176 3.53314 2.83595 4.39316 2.41917 5.39937C2.00238 6.40558 1.89334 7.51278 2.10581 8.58097C2.31829 9.64916 2.84273 10.6304 3.61285 11.4005C4.38297 12.1706 5.36416 12.6951 6.43235 12.9075C7.50054 13.12 8.60774 13.011 9.61395 12.5942C10.6202 12.1774 11.4802 11.4716 12.0853 10.566C12.6903 9.66045 13.0133 8.59579 13.0133 7.50667C13.0133 6.04621 12.4332 4.64557 11.4005 3.61287C10.3678 2.58017 8.96711 2 7.50665 2Z" fill="#7C7C7C"/>
          <path d="M11.1865 11.18L13.8332 13.8267" stroke="#7C7C7C" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
        </svg>

        <i class="fa fa-times"
           :class="{'is-hide': !showClearText }"
           @click="clearText"
        />
        <input
          ref="input"
          v-model="search"
          class="search-input"
          placeholder="名前かメールアドレスで検索する"
          @click="onClick"
          @keyup.enter="onEnter()"
          @keyup="canClearText()"
        />
      </div>
    </div>
    <MyCommuSideBar
      :isNavOpen="isNavOpen"
      :navPaths="navPaths"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import MyCommuSideBar from 'components/my-commu/SideBar.vue'
import MyCommuTopBar from 'components/my-commu/TopBar'

export default {
  components: {
    MyCommuSideBar, MyCommuTopBar
  },
  props: {
    navPaths: {
      type: Object,
      required: true
    },
    isChange: {
      type: Boolean,
      default: false
    },
    isMamaSupport: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isNavOpen: false,
      search: '',
      isHide: true,
      isCancel: false,
      isMarginLeft: true,
      hideTop: false,
      showClearText: false
    }
  },
  computed: {
    ...mapState('mycommu', ['currentCommunity', 'supporters', 'searched', 'isSearch']),
    showCancel () {
      return this.search === ''
    },
    showCancelText () {
      return this.search === '' && this.isHide
    },
    noResult () {
      return this.supporters.length < 1 && this.searched
    },
    changeText () {
      if (this.isChange && !this.isSearch) {
        return 'シェア・コンシェルジュ...'
      } else {
        return 'シェア・コンシェルジュ一覧'
      }
    }
  },
  mounted () {
  },
  methods: {
    ...mapActions('mycommu', [
      'toggerSidebar',
      'toggerIsSearch',
      'searchSupporters'
    ]),
    ...mapActions('home', [
      'fetchRecents'
    ]),
    onEnter () {
      this.$refs.input.blur()
      this.isHide = false
      this.searchSupporters({ currentCommunity: this.currentCommunity.community_id, params: this.search, withUsers: true })
    },

    onClick () {
      this.isHide = false
      this.toggerIsSearch(true)
      this.isMarginLeft = false
      this.hideTop = false
    },

    clearText () {
      this.search = ''
    },

    closeSearch () {
      window.location.reload()
    },
    canClearText () {
      if (this.$refs.input.value !== '') {
        this.showClearText = true
      } else {
        this.showClearText = false
      }
    }
  }
}
</script>
<style scoped lang="sass">
  @import '~stylesheets/resources'

  $mobile-header-z-index: $over-leaflet-z-index
  $mobile-sidenav-z-index: $over-leaflet-z-index + 2
  $mobile-sidenav-bg-z-index: $over-leaflet-z-index + 1
  $app-mobile-footer-height: 50px
  $primary: #2DBA87
  $gray: #AAAAAA

  .center-item
    display: flex
    justify-content: center
    align-items: center

  .text-title
    vertical-align: middle
    line-height: normal

  .pt-50
    padding-top: 50%

  .text-center
    text-align: center

  .ml-0
    margin-left: 0px !important

  .fontsize-15
    font-size: 15px

  .fontweight-700
    font-weight: 700

  .fontsize-20
    font-size: 20px

  .fontsize-18
    font-size: 18px

  .fontsize-16
    font-size: 16px

  .light-gray
    background-color: #f8f7f2
    height: 100vh
    width: 100vw
    z-index: 9998
    position: absolute

  .is-hide
    display: none !important

  .mb-1
    margin-bottom: 1rem

  .w-100
    width: 100% !important

  .w-14
    width: 14%

  .my-commu-domain
    font-size: 14px
    margin-left: 16px
    margin-right: 16px
    flex-direction: row
    justify-items: center
    align-items: center
    display: flex
    padding-bottom: 24px
    justify-content: space-between;
    .green-text
      color: $primary !important

    .pl-1
      padding-left: 1rem

    .is-medium
      width: 70% !important

    .is-small
      width: 55% !important

    .search-input-container
      position: relative

      svg
        position: absolute
        top: 11px
        left: 5px
      i
        position: absolute
        top: 12px
        right: 14px
        font-size: 16px
        font-weight: 300

      .search-input
        background: #F3F3F3
        border: 1px solid #CCCCCC
        border-radius: 8px
        width: 100%
        line-height: 36px
        padding-left: 30px
        &:focus-visible
          outline: unset
        &:focus
          caret-color: $primary
  .set-width
    width: 12%
  .padding-15
    padding-top: 10px
</style>
