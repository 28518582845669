<template>
  <div v-if="user">
    <div v-if="user.joinType === 'group'">
      <div v-if="isInGroup" class="avatar-area" >
        <img :src="user.communityAvatarPath" alt="" class="avatar" loading="lazy" @click="goToProfile(user.id)">
        <div class="d-inline-table">
          <span class="user-join-name" @click="goToProfile(user.id)">{{ nameUserJoin(user) }}</span>
          <span class="user-join-content">さんが参加しました</span>
        </div>
      </div>
      <div v-else class="avatar-area" >
        <img :src="user.communityAvatarPath" alt="" class="avatar" loading="lazy" @click="goToProfile(user.id)">
        <div class="d-inline-table">
          <span class="user-join-name" @click="goToProfile(user.id)">{{ nameUserJoin(user) }}</span>
          <span class="user-join-content">さんが</span>
          <span class="group-name" @click="goToGroup(user.groupId)">{{ user.commuGroupName }}</span>
          <span class="user-join-content">グループに参加しました</span>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="avatar-area" >
        <img :src="user.communityAvatarPath" alt="" class="avatar" loading="lazy" @click="goToProfile(user.id)">
        <div class="d-inline-table">
          <span class="user-join-name" @click="goToProfile(user.id)">{{ nameUserJoin(user) }}</span>
          <span class="user-join-content">さんが参加しました</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    user: {
      type: Object,
      required: true
    },
    isInGroup: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      fullName: this.user.fullName,
      commuNickname: this.user.commuNickname
    }
  },
  computed: {
    ...mapState('session', ['communityId'])
  },
  methods: {
    nameUserJoin (user) {
      if (user.commuNickname !== null && user.commuNickname !== '') {
        return user.commuNickname
      } else {
        return user.fullName
      }
    },
    goToProfile (id) {
      window.location.href = `/communities/${this.communityId}/users/${id}`
    },
    goToGroup (groupId) {
      window.location.href = `/communities/${this.communityId}/community_groups/${groupId}`
    }
  }
}
</script>

<style scoped lang="sass">
  .user-join-content
    position: relative
    text-align: center
    color: #273D35
    font-size: 12px
  .user-join-name
    color: #2DBA87
    font-weight: 600
    font-size: 12px
    padding-left: 7px
    word-break: break-all
  .group-name
    color: #2DBA87
    font-weight: 600
    font-size: 12px
  .avatar-area
    display: flex
    justify-content: center
    align-items: center
    padding-bottom: 12px
    .avatar
      width: 40px
      height: 40px
      object-fit: cover
      border-radius: 50%
      flex-shrink: 0
  .d-inline-table
    display: inline-table
</style>
