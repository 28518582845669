<template>
  <div>
    <HiddenHeader/>
    <MyCommuHiddenButtonFooter :is-my-commu-domain="true"/>
    <MyCommuHiddenFooterMenu :is-my-commu-domain="true"/>
    <Back title="スタッフアサイン"/>
    <div class="assign-staff-content">
      <MyCommuManagementCardEvent
        v-for="StaffCandidate in StaffCandidates"
        :key="StaffCandidate"
        :staff-candidate="StaffCandidate"/>
    </div>
  </div>
</template>

<script>

import { mapState } from 'vuex'
import Back from '../../components/my-commu/Back'

export default {
  components: {
    Back
  },
  props: {
    StaffCandidates: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapState('mycommu', ['currentCommunity']),
    ...mapState('space', ['unsetMargin'])
  },

  created () {
  },
  mounted () {
    this.unsetMargin.mainContainer = true
  },
  methods: {
  }
}
</script>
<style scoped lang="sass">
.topbar
  height: 41px
  background: #FFFFFF
  display: grid
  grid-template-columns: auto auto auto
  align-items: center
  .topbar-icon-back
    margin-left: 16px
  .topbar-title
    color: #273D35
    font-weight: 700
    font-size: 16px
    text-align: center
  .topbar-avatar
    margin-left: auto
    margin-right: 23px
    margin-top: 5px
    img
      width: 32px
      height: 32px
      border-radius: 4px

.assign-staff-content
  margin-top: 16px
</style>
