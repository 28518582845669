<template>
  <div
    class="my-commu-domain tab-group"
    :class="{'d-none': !display || isFindUser || hiddenHeader.tabGroup }"
  >
    <ul>
      <li class="item" :class="active('')">
        <a v-bind:href="home_path">
          <svg width="32" height="32" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_10508_10931)">
              <path d="M9.14794 25.7626L9.14805 25.7626L9.14062 25.7533L3.51562 18.7133L2.625 17.5986V19.0254V25.0976C2.625 25.6795 2.14619 26.1585 1.5625 26.1585C0.978807 26.1585 0.5 25.6795 0.5 25.0976V14.561C0.5 14.1196 0.78593 13.7146 1.21191 13.5656C1.63755 13.4168 2.11596 13.557 2.38865 13.9028L2.38864 13.9028L2.39063 13.9053L8.01563 20.9453L8.90625 22.0599V20.6332V14.561C8.90625 13.979 9.38506 13.5 9.96875 13.5C10.5524 13.5 11.0312 13.979 11.0312 14.561V25.0976C11.0312 25.539 10.7453 25.944 10.3193 26.0929L10.3193 26.0929L10.3137 26.0949C10.193 26.1388 10.0788 26.1585 9.96875 26.1585C9.64421 26.1585 9.34324 26.0194 9.14794 25.7626Z" fill="white" stroke="white"/>
              <path d="M16.8281 15.622H16.3281V16.122V23.5366V24.0366H16.8281H22.5625C23.1462 24.0366 23.625 24.5156 23.625 25.0976C23.625 25.6795 23.1462 26.1585 22.5625 26.1585H15.2656C14.6819 26.1585 14.2031 25.6795 14.2031 25.0976V14.561C14.2031 13.979 14.6819 13.5 15.2656 13.5H22.5625C23.1462 13.5 23.625 13.979 23.625 14.561C23.625 15.1429 23.1462 15.622 22.5625 15.622H16.8281Z" fill="white" stroke="white"/>
              <path d="M21.1875 21.3824H16.9375C16.0781 21.3824 15.375 20.68 15.375 19.8215C15.375 18.9629 16.0781 18.2605 16.9375 18.2605H21.1875C22.0469 18.2605 22.75 18.9629 22.75 19.8215C22.75 20.68 22.0469 21.3824 21.1875 21.3824Z" fill="white"/>
              <path d="M29.469 26.6585C28.7815 26.6585 28.1565 26.2059 27.969 25.5034L25.1096 14.9668C24.8909 14.1395 25.3752 13.281 26.2034 13.0468C27.0315 12.8127 27.8909 13.3122 28.1252 14.1395L30.9846 24.6761C31.2034 25.5034 30.719 26.3619 29.8909 26.5961C29.7502 26.6273 29.6096 26.6585 29.4846 26.6585H29.469Z" fill="white"/>
              <path d="M35.5784 26.6585C34.8909 26.6585 34.2659 26.2059 34.0784 25.5034L31.219 14.9668C31.0002 14.1395 31.4846 13.281 32.3127 13.0468C33.1409 12.8127 34.0002 13.3122 34.2346 14.1395L37.094 24.6761C37.3127 25.5034 36.8284 26.3619 36.0002 26.5961C35.8596 26.6273 35.719 26.6585 35.594 26.6585H35.5784Z" fill="white"/>
              <path d="M35.5784 26.6585C35.4378 26.6585 35.3128 26.6429 35.1721 26.5961C34.344 26.3776 33.844 25.519 34.0784 24.6761L36.9378 14.1395C37.1721 13.3122 38.0315 12.8127 38.8596 13.0468C39.6878 13.2654 40.1878 14.1239 39.9534 14.9668L37.094 25.5034C36.9065 26.2059 36.2815 26.6585 35.594 26.6585H35.5784Z" fill="white"/>
              <path d="M29.5784 26.6273C29.4378 26.6273 29.2971 26.6117 29.1721 26.5649C28.344 26.3307 27.844 25.4722 28.0784 24.6449L29.094 20.961C29.3284 20.1337 30.1721 19.6498 31.0159 19.8683C31.844 20.1024 32.344 20.961 32.1096 21.7883L31.094 25.4722C30.9065 26.159 30.2815 26.6273 29.594 26.6273H29.5784Z" fill="white"/>
            </g>
            <defs>
              <clipPath id="clip0_10508_10931">
                <rect width="40" height="13.6585" fill="white" transform="translate(0 13)"/>
              </clipPath>
            </defs>
          </svg>
          <div>新着情報</div>
        </a>
      </li>
      <li class="item" :class="active('event')">
        <a href="/home?type=event">
          <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 27L11 4" stroke="#AAAAAA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M9 17.6548C15.1209 17.6548 16.9192 21.2704 23.6655 19.5229L26 5.8801C19.2983 7.57943 17.4553 4 11.3345 4" stroke="#AAAAAA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <div>交流ｲﾍﾞﾝﾄ</div>
        </a>
      </li>
      <li class="item" :class="active('share')">
        <a :href="`/communities/${communityId}/share_posts`">
          <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_10508_10915)">
              <path d="M15.1904 1L18.4828 4.73425L13.8755 6.71182" stroke="#AAAAAA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M3.86084 16C3.86084 19.3556 5.21779 22.385 7.41626 24.5835" stroke="#AAAAAA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M15.9998 3.86102C9.28865 3.86102 3.86084 9.29935 3.86084 16" stroke="#AAAAAA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M16.81 31.0002L13.5176 27.266L18.1249 25.2884" stroke="#AAAAAA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M28.1384 16.0001C28.1384 12.6446 26.7815 9.6151 24.583 7.41663" stroke="#AAAAAA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M15.9995 28.1389C22.7106 28.1389 28.1384 22.7005 28.1384 15.9999" stroke="#AAAAAA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <circle cx="16" cy="16" r="5" stroke="#AAAAAA" stroke-width="2"/>
            </g>
            <defs>
              <clipPath id="clip0_10508_10915">
                <rect width="32" height="32" fill="white"/>
              </clipPath>
            </defs>
          </svg>
          <div>シェアリング</div>
        </a>
      </li>
      <li class="item" :class="active('community_groups')">
        <a :href="`/communities/${communityId}/community_groups`">
          <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_10508_10888)">
              <path d="M28.6001 3.71002H15.2601C14.8701 3.71002 14.5601 4.03002 14.5601 4.41002V12.21C13.5701 11.8 12.4901 11.58 11.3501 11.58C6.68014 11.58 2.89014 15.37 2.89014 20.04C2.89014 24.71 6.68014 28.5 11.3501 28.5C16.0201 28.5 19.8101 24.71 19.8101 20.04C19.8101 19.5 19.7501 18.98 19.6601 18.47H28.6101C29.0001 18.47 29.3101 18.15 29.3101 17.77V4.41002C29.3101 4.02002 28.9901 3.71002 28.6101 3.71002H28.6001Z" stroke="#AAAAAA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M9.34002 18.21H9.62002C9.65868 18.21 9.69002 18.2414 9.69002 18.28V19.18C9.69002 19.2187 9.65868 19.25 9.62002 19.25H9.34002C9.30136 19.25 9.27002 19.2187 9.27002 19.18V18.28C9.27002 18.2414 9.30136 18.21 9.34002 18.21Z" fill="#AAAAAA" stroke="#AAAAAA"/>
              <path d="M13.36 18.21H13.64C13.6787 18.21 13.71 18.2414 13.71 18.28V19.18C13.71 19.2187 13.6787 19.25 13.64 19.25H13.36C13.3214 19.25 13.29 19.2187 13.29 19.18V18.28C13.29 18.2414 13.3214 18.21 13.36 18.21Z" fill="#AAAAAA" stroke="#AAAAAA"/>
              <path d="M10.0601 22.02C10.3701 22.47 10.8901 22.77 11.4801 22.77C12.0701 22.77 12.5901 22.47 12.9001 22.02" stroke="#AAAAAA" stroke-miterlimit="10" stroke-linecap="round"/>
              <path d="M19.7399 8.97003H20.0199C20.0586 8.97003 20.0899 9.00137 20.0899 9.04003V9.94003C20.0899 9.97869 20.0586 10.01 20.0199 10.01H19.7399C19.7013 10.01 19.6699 9.97869 19.6699 9.94003V9.04003C19.6699 9.00137 19.7013 8.97003 19.7399 8.97003Z" fill="#AAAAAA" stroke="#AAAAAA"/>
              <path d="M24.0399 8.47003H23.7599C23.4451 8.47003 23.1899 8.72523 23.1899 9.04003V9.94003C23.1899 10.2548 23.4451 10.51 23.7599 10.51H24.0399C24.3547 10.51 24.6099 10.2548 24.6099 9.94003V9.04003C24.6099 8.72523 24.3547 8.47003 24.0399 8.47003Z" fill="#AAAAAA"/>
              <path d="M20.46 12.78C20.77 13.23 21.29 13.53 21.88 13.53C22.47 13.53 22.99 13.23 23.3 12.78" stroke="#AAAAAA" stroke-miterlimit="10" stroke-linecap="round"/>
            </g>
            <defs>
              <clipPath id="clip0_10508_10888">
                <rect width="30" height="30" fill="white" transform="translate(1 1)"/>
              </clipPath>
            </defs>
          </svg>
          <div>グループ</div>
        </a>
      </li>
      <li class="item" :class="active('partner')">
        <a :href="`/communities/${communityId}/area_partners`">
          <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.04186 8.02423H2" stroke="#AAAAAA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M2 21.2434H5.7235C5.7235 21.2434 9.50236 30.5729 14.9007 27.3062C15.925 27.5692 19.1502 28.9949 20.7144 25.5898C23.3582 26.0466 25.0884 24.9669 25.656 22.6138L19.3025 12.7306H16.1327L13.9595 14.8346C12.5891 15.9696 11.0803 15.8035 9.83457 15.2914C8.38116 14.6823 8.04895 12.7583 9.15631 11.6371L13.7657 7H21.2957L26.3757 10.3498H30.6944" stroke="#AAAAAA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M25.7539 21.2431H30.7093" stroke="#AAAAAA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M20.8535 25.3958L18.1958 21.2571" stroke="#AAAAAA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M15.8569 26.9464L13.1992 22.8077" stroke="#AAAAAA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <div>パートナー</div>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import queryString from 'query-string'

export default {
  components: { },
  props: {
  },

  data () {
    return {}
  },
  computed: {
    ...mapState('mycommu', ['search', 'isFindUser', 'hiddenHeader']),
    ...mapState('session', ['communityId']),

    display () {
      return this.search === ''
    },
    home_path () {
      return '/home?community_id=' + this.communityId
    },
  },
  mounted () {
    const vm = this
    vm.$el.addEventListener('scroll', (event) => {
      vm.scrollHandler()
    }, true)
    // if (window.innerWidth <= 576) {
    //   this.scrollTo(document.querySelector('.item.active'))
    // }
  },
  methods: {
    active (type) {
      const params = queryString.parse(location.search)
      if (window.location.pathname.match('community_groups')) {
        if (type === 'community_groups') {
          return 'active'
        }
      } else if (window.location.pathname.match('area_partners')) {
        if (type === 'partner') {
          return 'active'
        }
      } else if (window.location.pathname.match('/share_posts/new') || window.location.pathname.match('/share_posts')) {
        if (type === 'share') {
          return 'active'
        }
      } else {
        if (params.type === undefined && type === '') {
          return 'active'
        } else {
          return type === params.type ? 'active' : ''
        }
      }
    }
    // scrollHandler () {
    //   const container = this.$el.querySelector('ul')
    //   var x = this.$el.scrollLeft
    //   if (x + this.$el.offsetWidth == container.offsetWidth) {
    //     this.$el.scrollTo(0, 0)
    //   } else if (x == 0) {
    //     this.$el.scrollTo(this.$el.offsetWidth, 0)
    //   }
    // },
    // scrollTo (el) {
    //   var elWidth = el.offsetWidth
    //   var elPosition = el.getBoundingClientRect().right
    //   var containerWidth = this.$el.offsetWidth
    //   this.$el.scrollTo(elPosition - containerWidth + (containerWidth / 2) - (elWidth / 2), 0)
    // },
  }
}
</script>
<style scoped lang="sass">
  @import '~stylesheets/resources'

  $mobile-header-z-index: $over-leaflet-z-index
  $mobile-sidenav-z-index: $over-leaflet-z-index + 2
  $mobile-sidenav-bg-z-index: $over-leaflet-z-index + 1
  $app-mobile-footer-height: 50px
  $primary: #2DBA87
  $gray: #AAAAAA
  $white: #FFF

  ::-webkit-scrollbar
    display: none

  .my-commu-domain
    &.d-none
      display: none
    .clone
      display: none
    &.tab-group
      margin-top: 7px
      color: #273D35
      border-bottom: 4px solid $primary
      ul
        height: 61px
        font-weight: 700
        font-size: 12px
        li
          padding: 5px
          text-align: center
          border-radius: 8px 8px 0 0
          background: $white
          width: 20%
          height: 100%
          overflow-x: auto
          float: left
          a
            font-weight: bold
            font-size: 9px
            line-height: 9px
            height: 100%
            color: #AAAAAA
          &:first-child
            a svg path
              fill: $gray
              stroke: $gray
          &:first-child.active
            a svg path
              fill: $white !important
        .active
          background: $primary
          a
            color: $white
            svg path, svg circle
              stroke: $white !important
  @media only screen and (max-width: 576px)
    .clone
      display: block !important

</style>
