<template>
  <BField :label="label" :message="error === '' ? errors : error" :type="{ 'is-danger': this.error !== '' || this.errors.length !== 0 }" :label-for="id" :addons="false"
    :custom-class="customClass" class="label-datepicker">
    <div class="position">
      <DatepickerBaseVue class="datepicker-is-commu datepicker-is-commu--full" :class="{'set-z-index': this.setZIndex }" :id="id" v-model="date" :day-names="dateName"
        :mobile-native="this.isMobi" :close-on-click="false" range :placeholder="placeholder" @input="selectDate" :date-formatter="formatDate"
        :focused-date="this.focusedDate" ref="datePicker" :name="name" :blank-input="this.blankInput"
        >
        <template #header>
          <div class="header-guide">
            <span>{{title}}</span>
          </div>
          <div class="pagination field is-centered">
            <a class="pagination-previous" role="button" href="#"
              @click.prevent="prev" @keydown.enter.prevent="prev"
              @keydown.space.prevent="prev">

              <BIcon :icon="iconPrev" both type="is-primary is-clickable" />
            </a>
            <span>{{formatDateTitle}}</span>
            <a
              class="pagination-next"
              role="button"
              href="#"
              @click.prevent="next" @keydown.enter.prevent="next"
              @keydown.space.prevent="next"
            >
              <b-icon :icon="iconNext" both type="is-primary is-clickable" />
            </a>
          </div>
        </template>
        <div v-if="dateTime" class="time-selector">
          <i class="fas fa-clock iconclock" />
          <div class="date-dropdown">
            <select v-model.number="selectedHours">
              <option
                v-for="(num, key) in 24"
                :key="key"
                :value="num - 1"
                v-text="num - 1"
              />
            </select>
          </div>
          <div class="unit">
            時
          </div>
          <div class="date-dropdown">
            <select v-model.number="selectedMinutes">
              <option
                v-for="(num, key) in selectableMinutesTimes"
                :key="key"
                :value="selectableMinutes(num)"
                v-text="selectableMinutes(num)"
              />
            </select>
          </div>
          <div class="unit">
            分
          </div>
        </div>
        <BButton class="submit" label="決定する" type="is-primary" @click="chooseDate()" />
      </DatepickerBaseVue>
      <div v-if="this.error === ''" class="icon-datepicker"></div>
    </div>
  </BField>
</template>

<script>
import Input from './comments/Input'
import {addMonths, subMonths, format, setHours, setMinutes} from 'date-fns'
import DatepickerBaseVue from './DatepickerBase.vue'
import * as inputs from "../inputs";
export default {
  components: { Input, DatepickerBaseVue },
  props: {
    title: {
      type: String,
      default: '返却日を選択してください'
    },
    value: {
      type: Date,
      require: true
    },
    label: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    },
    error: {
      type: String,
      default: ''
    },
    errors: {
      type: Array,
      default: () => []
    },
    customClass: {
      type: String,
      default: ''
    },
    dateName: {
      type: Array,
      default: () => ['日', '月', '火', '水', '木', '金', '土']
    },
    placeholder: {
      type: String,
      default: ''
    },
    iconPrev: {
      type: String,
      default: "chevron-left"
    },
    iconNext: {
      type: String,
      default: "chevron-right"
    },
    setZIndex: {
      type: Boolean,
      default: false
    },
    selectableMinutesDuration: {
      type: Number,
      default: 15
    },
    dateTime: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: ''
    },
    blankInput: {
      type: Boolean,
      default: false
    }
  },
  data () {
    const time = this.value !== null ? (this.value.toString() !== 'Invalid Date' ? new Date(this.value) : new Date()) : new Date ()
    return {
      date: time,
      isMobi: false,
      inline: false,
      focusedDate: time,
      selectedHours: format(time, 'H'),
      selectedMinutes: format(time, 'mm')
    }
  },
  created () {
    this.date = this.normalizeDate(this.date)
    this.selectedHours = format(this.date, 'H')
    this.selectedMinutes = format(this.date, 'mm')
  },
  mounted () {
    if (!this.blankInput) {
      if (this.dateTime) {
        this.date = setHours(this.date, this.selectedHours)
        this.date = setMinutes(this.date, this.selectedMinutes)
        this.$emit('valueSelect', this.date)
      } else {
        this.$emit('valueSelect', this.date)
      }
    }
  },
  computed: {
    formatDateTitle () {
      const value = this.focusedDate
      const fValue = format(value, 'YYYY年M月')
      return fValue
    },
    selectableMinutesTimes () {
      return Math.floor(60 / this.selectableMinutesDuration)
    },
    inputValue () {
      return format(this.date, 'YYYY-MM-DDTHH:mm:ssZ')
    }
  },
  methods: {
    selectDate () {
      this.$refs.datePicker.computedValue = this.$refs.datePicker.dateSelected
      this.date = this.$refs.datePicker.dateSelected
      if (this.dateTime) {
        this.date = setHours(this.date, this.selectedHours)
        this.date = setMinutes(this.date, this.selectedMinutes)
        this.$emit('valueSelect', this.date)
      } else {
        this.$emit('valueSelect', this.date)
      }
    },
    prev () {
      this.focusedDate = subMonths(this.focusedDate, 1)
    },
    next () {
      this.focusedDate = addMonths(this.focusedDate, 1)
    },
    chooseDate () {
      this.selectDate()
      this.$refs.datePicker.closeDatePicker()
    },
    selectableMinutes (num) {
      return `00${(num - 1) * this.selectableMinutesDuration}`.slice(-2)
    },
    formatDate (date) {
      if (this.dateTime) {
        return format(date, 'YYYY/M/D H:mm')
      } else {
        return format(date, 'YYYY/M/D')
      }
    },
    normalizeDate (val) {
      return inputs.normalizeTime(val, this.selectableMinutesDuration)
    }
  }
}
</script>

<style lang="sass" scoped>
$primary: #F68F54
$success: #2DBA87

.label-datepicker
  .position
    position: relative
    /deep/  
      .dropdown-menu
        width: calc(100% + 1px)!important
        overflow: hidden
        height: 100%
        min-height: 580px
        // top: 35% !important
        .dropdown-content
          position: fixed
          width: 100%
          top: 10%
          .datepicker-content
            height: unset
      .has-text-danger
        top: 5px
      .close-dropdown
        position: absolute
        z-index: 1000
        left: 20px
        top: -10%
        &:before
          height: 2px
        &:after
          width: 2px
    .icon-datepicker
      position: absolute
      right: 15px
      bottom: 13px
      width: 1.5rem
      height: 1.5rem
      z-index: 1
      top: 5px
      background: url("../../images/icons/datepicker.svg") no-repeat
.set-z-index
  /deep/
    .background
      z-index: 1998
    .dropdown-menu
      z-index: 1999 !important
.time-selector
  display: flex
  align-items: center
  justify-content: center
  margin-bottom: 15px
  vertical-align: bottom
  padding: 10px 40px
  line-height: 1

  > .unit
    font-size: 120%
    margin-top: 1px

    &:last-child
      margin-right: 0

  .iconclock
    font-size: 120%
    margin-right: 0.5em
.date-dropdown
  > select
    background-color: transparent
    border: none
    font-family: Avenir, sans-serif
    appearance: none
    line-height: 1
    font-size: 18px
    text-align: right
    padding: 0

    > option
      direction: rtl
</style>
