<template>
  <div class="base-checkbox">
    <b-collapse :open="false" @open="open = true" @close="open = false" aria-id="contentIdForA11y1" :class="{'collapse-open': open}">
      <template #trigger="child1">
        <b-button
          :label="title"
          type="is-primary collapse-title"
          aria-controls="contentIdForA11y1"
          :aria-expanded="child1.open" />
      </template>
      <div class="notification">
        <div class="content">
          <MyCommuBaseSelectInput
            v-model="childTicket.joined_event"
            label="イベント当日に同伴しますか?"
            :options="[['はい', 'yes'], ['いいえ', 'no']]"
            class="mb-14px"
            placeholder="選択する"
          />

          <div class="columns is-mobile mb-14px">
            <div class="column">
              <MyCommuBaseTextInput
                id="profile_last_name"
                v-model="childTicket.first_name"
                label="名"
                name="ticket_child_profile[first_name]"
              />
            </div>
            <div class="column">
              <MyCommuBaseTextInput
                id="profile_first_name"
                v-model="childTicket.first_name_kana"
                label="名(カナ)"
                name="ticket_child_profile[first_name_kana]"
              />
            </div>
          </div>
          <MyCommuBaseSelectInput
            v-model="childTicket.sex"
            label="性別"
            :options="[['女の子', 'female'], ['男の子', 'male']]"
            class="mb-14px"
            placeholder="選択する"
          />

          <MyCommuBirthdayDatePicker
            v-model="childTicket.date_of_birth"
            label="誕生日"
            year-placeholder="1980"
            month-placeholder="12"
            day-placeholder="12"
            :hide-unit="true"
            @valueSelect="valueDateOfBirth"
          />

          <MyCommuBaseSelectInput
            v-if="hasChild"
            v-model="childTicket.discharge_step"
            label="排泄状況について"
            :options="[['一人でできる', 'oneself'], ['オムツをつけている', 'diaper'], ['トレーニング中', 'training']]"
            class="mb-14px"
          />
          <MyCommuBaseSelectInput
            v-if="hasChild"
            v-model="childTicket.have_allergy"
            label="アレルギーの有無"
            :options="[['あり', '1'], ['なし', '0']]"
            class="mb-14px"
          />

          <MyCommuBaseCheckBox label="卵" v-model="childTicket.egg_allergy" v-if="hasChild"/>
          <MyCommuBaseCheckBox label="乳" v-model="childTicket.milk_allergy" v-if="hasChild"/>
          <MyCommuBaseCheckBox label="小麦" class="mb-14px" v-model="childTicket.wheat_allergy" v-if="hasChild"/>

          <MyCommuBaseTextInput
            v-model="childTicket.allergy_description"
            label="その他（下記特記事項へお書きください）"
            type="textarea"
            class="mb-14px"
          />

          <MyCommuBaseSelectInput
            v-if="hasChild"
            v-model="childTicket.day_care_experience"
            label="託児経験の有無"
            :options="[['あり', '1'], ['なし', '0']]"
          />

        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>

export default {
  props: {
    child: {
      type: Object,
      default: () => {}
    },
    title: {
      type: String,
      default: '子どもの情報'
    },
    hasChild: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      childTicket: this.child,
      open: false
    }
  },
  methods: {
    valueDateOfBirth (param) {
      this.childTicket.date_of_birth = param
    }
  }

}
</script>

<style lang="sass" scoped>
.mb-14px
  margin-bottom: 14px
.collapse
  border: 1px solid #CCCCCC
  border-radius: 4px 4px 0 0
  .notification
    padding: 12px
    background-color: white
  .collapse-title
    font-weight: bold
    width: 100%
    display: flex
    justify-content: flex-start
    background-color: white !important
    font-size: 18px
    line-height: 48px
    color: #AAAAAA
    &:hover
      color: #AAAAAA
    &:focus
      box-shadow: unset !important
  /deep/
    label
      font-size: 14px
    .icon-datepicker
      top: 11px !important
.collapse-open
  border-radius: 4px 4px 0 0
  .collapse-title
    color: #FFFFFF
    background-color: #2DBA87 !important
    border-radius: 4px 4px 0 0
    &:hover
      color: #FFFFFF
/deep/
  .birthday-picker__text-inner
    .select
      width: 100%
      margin-right: 15px
      &:last-child
        margin-right: 0
      select
        width: 100%
</style>
