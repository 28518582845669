<template>
  <div>
    <MyCommuChatIndex v-if="!isNewMessage"/>
    <MyCommuChatNewChat
      v-if="isNewMessage"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  components: {
  },
  computed: {
    ...mapState('mycommu', ['isNewMessage'])
  },
  created () {
  },
  methods: {
  }
}
</script>
<style scoped lang="sass">
</style>
