<template>
  <a :href="path">
  <div class="my-commu-domain card-borrow">
    <div class="card-borrow-type">
      <span>借りたい</span>
    </div>
    <div class="infor">
      <img src="../../../images/samples/unsplash_OtXADkUh3-I.png" />
      <span class="infor-name">前田 健</span>
    </div>
    <div class="card-container">
      <div class="title">
        <span>ダイソンの掃除機を借していただけませんでしょうか？</span>
      </div>
      <div class="raw-body">
        <span>
          家の掃除機が壊れてしまって、しばらくの間掃除機を借してほしいのですが、お近くのかたでかして…
        </span>
      </div>
      <div class="card-borrow-footer">
        <ul>
          <li><span class="created-date">2021.11.10</span></li>
          <li><span class="status">募集終了</span></li>
          <li><span class="price-icon">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_2679_3600)">
                  <path
                    d="M8.00004 14.7891C11.7496 14.7891 14.7891 11.7496 14.7891 8.00004C14.7891 4.25052 11.7496 1.21094 8.00004 1.21094C4.25052 1.21094 1.21094 4.25052 1.21094 8.00004C1.21094 11.7496 4.25052 14.7891 8.00004 14.7891Z"
                    fill="#FFC400" />
                  <path
                    d="M8.00002 15.992C6.41776 15.992 4.87102 15.5228 3.55542 14.6437C2.23983 13.7647 1.21449 12.5152 0.608985 11.0534C0.00348356 9.59162 -0.154966 7.98309 0.153715 6.43124C0.462397 4.87939 1.22433 3.45392 2.34315 2.3351C3.46197 1.21628 4.88744 0.454347 6.43929 0.145665C7.99114 -0.163017 9.59965 -0.00459132 11.0615 0.60091C12.5233 1.20641 13.7727 2.23181 14.6518 3.5474C15.5308 4.86299 16 6.40972 16 7.99197C15.9979 10.113 15.1543 12.1466 13.6545 13.6464C12.1547 15.1463 10.1211 15.9898 8.00002 15.992ZM8.00002 2.42181C6.89641 2.42182 5.81755 2.74918 4.90005 3.36248C3.98254 3.97578 3.26758 4.84745 2.84562 5.8672C2.42366 6.88696 2.3137 8.00898 2.52959 9.09126C2.74548 10.1735 3.27755 11.1675 4.05849 11.9473C4.83942 12.7271 5.83413 13.2577 6.91673 13.472C7.99933 13.6863 9.12113 13.5747 10.1403 13.1513C11.1594 12.7278 12.03 12.0116 12.642 11.0932C13.254 10.1748 13.5798 9.09557 13.5782 7.99197C13.5761 6.51393 12.9874 5.09715 11.9415 4.05278C10.8957 3.00841 9.47805 2.42181 8.00002 2.42181Z"
                    fill="#FFC400" />
                  <path
                    d="M8.00035 15.1847C6.57776 15.1847 5.18707 14.7628 4.00423 13.9725C2.82139 13.1821 1.89949 12.0588 1.35509 10.7445C0.810691 9.43017 0.668309 7.98396 0.945842 6.58871C1.22338 5.19345 1.90842 3.91183 2.91434 2.9059C3.92026 1.89998 5.20184 1.21494 6.59709 0.937406C7.99235 0.659873 9.43855 0.802304 10.7529 1.34671C12.0672 1.89111 13.1905 2.81303 13.9808 3.99586C14.7712 5.1787 15.1931 6.56935 15.1931 7.99194C15.191 9.89891 14.4324 11.7272 13.084 13.0756C11.7356 14.424 9.90732 15.1825 8.00035 15.1847V15.1847ZM8.00035 1.61452C6.73708 1.61452 5.50217 1.98925 4.45191 2.69125C3.40165 3.39325 2.58324 4.391 2.10018 5.55827C1.61711 6.72553 1.49109 8.00983 1.73813 9.24871C1.98517 10.4876 2.59413 11.6254 3.48796 12.5181C4.38179 13.4108 5.5204 14.0183 6.75959 14.2638C7.99878 14.5092 9.28289 14.3816 10.4495 13.8971C11.6162 13.4125 12.6129 12.5929 13.3136 11.5417C14.0143 10.4906 14.3874 9.25521 14.3858 7.99194C14.3837 6.2998 13.71 4.6777 12.5127 3.48194C11.3155 2.28618 9.69248 1.61452 8.00035 1.61452V1.61452Z"
                    fill="#FFFF4A" />
                  <path
                    d="M9.08197 12.1897H6.91047C6.80407 12.1877 6.70261 12.1445 6.62735 12.0692C6.5521 11.994 6.5089 11.8925 6.50684 11.7861V4.20588C6.50684 4.09883 6.54929 3.99617 6.62499 3.92047C6.70069 3.84478 6.80342 3.80225 6.91047 3.80225H9.08197C9.18902 3.80225 9.29165 3.84478 9.36735 3.92047C9.44304 3.99617 9.4856 4.09883 9.4856 4.20588V11.8184C9.47746 11.9197 9.43134 12.0143 9.35651 12.0831C9.28167 12.152 9.18365 12.19 9.08197 12.1897ZM7.3141 11.3825H8.67833V4.60951H7.3141V11.3825Z"
                    fill="#FFFF4A" />
                </g>
                <defs>
                  <clipPath id="clip0_2679_3600">
                    <rect width="16" height="16" fill="white" />
                  </clipPath>
                </defs>
              </svg>

            </span>
            <span class="price">1,000円</span>
          </li>
          <li><span class="pin">
              <svg width="16" height="22" viewBox="0 0 16 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M13.0004 19.97L8.64038 16.2C8.46145 16.0529 8.237 15.9725 8.00537 15.9725C7.77375 15.9725 7.54936 16.0529 7.37042 16.2L3.00043 19.97C2.85661 20.0785 2.68611 20.1462 2.50702 20.1658C2.32793 20.1855 2.14688 20.1563 1.98297 20.0815C1.81906 20.0068 1.67841 19.8891 1.57587 19.741C1.47332 19.5928 1.41269 19.4198 1.40039 19.2401V2.76001C1.40039 2.49479 1.50576 2.24045 1.6933 2.05292C1.88083 1.86538 2.13517 1.76001 2.40039 1.76001H13.6704C13.9356 1.76001 14.19 1.86538 14.3775 2.05292C14.565 2.24045 14.6704 2.49479 14.6704 2.76001V19.23C14.67 19.4233 14.6135 19.6122 14.5079 19.774C14.4024 19.9359 14.2522 20.0636 14.0755 20.1419C13.8988 20.2202 13.7033 20.2457 13.5125 20.2151C13.3217 20.1846 13.1439 20.0995 13.0004 19.97Z"
                  stroke="#CCCCCC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </span></li>
        </ul>
      </div>
    </div>
  </div>
  </a>
</template>

<script>
import { mapState } from 'vuex'

export default {
  components: {},
  props: {
    path: {
     type: String,
     required: true
    }
  },
  data() {
    return {}
  },
  computed: {

  },
  mounted() {

  },
  methods: {

  }
}
</script>
<style scoped lang="sass">
  @import '~stylesheets/resources'

  .card-borrow
    position: relative
    width: 92%
    margin: auto
    border-radius: 12px
    background: #FFFFFF

    .card-borrow-type
      width: 104px
      height: 48px
      position: absolute
      background: #6F8FE3
      border-radius: 16px 16px 16px 0px
      text-align: center
      align-items: center
      display: flex
      justify-content: center
      top: -10px
      right: 8px
      span
        color: #FFFFFF
        font-weight: 600
        font-size: 14px
    .infor
      display: flex
      text-align: center
      align-items: center
      padding-top: 20px
      padding-left: 26px

      img
        width: 40px
        height: 40px
        border-radius: 50%
        object-fit: cover
        flex-shrink: 0
        margin-right: 10px
      span
        font-weight: 300
        font-size: 12px

    .card-container
      margin-left: 32px
      margin-right: 32px
      margin-bottom: 32px
      .title
        margin-top: 10px
        color: #6F8FE3
        font-weight: 600
        font-size: 17px
      .raw-body
        font-weight: 300
        font-size: 12px
        color: #273D35
        margin-top: 16px
        margin-bottom: 23px
        word-break: break-word
      .card-borrow-footer
        padding-bottom: 30px
        color: #AAAAAA
        display: flex
        font-weight: 400
        font-size: 12px

        ul
          display: grid
          gap: 30px
          grid-template-columns: auto auto auto auto
          grid-auto-rows: 4px
          justify-content: center
          align-items: center
          li
            &:last-child

          .status
            background: #F3F3F3

            border: 1px solid #7C7C7C
            justify-content: center
            align-items: center
            padding: 3px 8px

          .price
            color: #273D35
            font-weight: 700
            font-size: 12px
            .price-icon
              display: flex
              align-items: center
              margin-right: 5px
  </style>
