<template>
  <div>
    <div
      class="user-card"
      :class="{'the-last': lastItem}"
    >
      <div
        class="d-flex"
        @click="navigate()"
      >
        <img
          class="avatar"
          :src="profile.images"
        >
        <div class="card-body">
          <div
            class="user-name font-size-15"
            v-text="this.userName"
          />
          <div
            class="content font-size-12 color-gray"
            :class="{'mt-10': profile.introduction !== '', 'mt-8': profile.introduction !== ''}"
            v-html="profile.introduction"
          />
        </div>
      </div>
      <div
        class="card-icon"
        @click="openModalUser"
      >
        <img
          src="../../../images/icons/three_dots.png"
          alt=""
          width="24"
        >
      </div>
    </div>
    <b-modal
      :active.sync="isOpenPopup"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-label="Example Modal"
      close-button-aria-label="Close"
      aria-modal
      class="user-modal"
    >
      <div
        v-if="!isOpenOtpModal"
        class="modal-card"
        style="width: auto"
      >
        <section class="modal-card-body">
          <div class="user-avatar">
            <img
              class="avatar"
              :src="profile.images"
            >
            <div class="card-body">
              <div
                class="user-name font-size-12 color-base"
                v-text="this.userName"
              />
              <div
                class="content font-size-12 color-gray mt-8"
                v-html="profile.introduction"
              />
            </div>
          </div>
          <div
            class="mail"
            v-if="(isFollowed || friendRequested || isFriend) && !isBlock"
            @click="message"
          >
            <div class="icon">
              <img
                src="../../../images/icons/mail.svg"
                alt=""
              >
            </div>
            <div class="descreption">
              <strong><p>メッセージを送る</p></strong>
            </div>
          </div>
          <div
            class="mail"
            v-else-if="(isFollowed || friendRequested || isFriend) && isBlock"
          >
            <div class="icon">
              <img
                src="../../../images/icons/mail-gray.svg"
                alt=""
              >
            </div>
            <div class="descreption">
              <strong><p class="color-gray-4">メッセージを送る</p></strong>
              <p class="font-size-12 color-base">
                ブロックしている相手にはメッセージを送れません
              </p>
            </div>
          </div>
          <div
            class="follow"
            v-if="hasFollowed && !isFriend"
            @click="follow"
          >
            <div class="icon">
              <img
                v-if="hasFollowed && !isFriend"
                src="../../../images/icons/followed.svg"
              >
              <img
                v-else
                src="../../../images/icons/not-followed.svg"
              >
            </div>
            <div class="descreption">
              <strong>
                <p
                  class="font-size-15"
                  :class="{'color-gray-4': hasFollowed}"
                >
                  フォローする
                </p>
              </strong>
              <p
                v-if="hasFollowed"
                class="font-size-12"
              >
                すでにフォロー済み
              </p>
            </div>
          </div>
          <div
            class="friendship"
            @click="openOtpModal"
          >
            <div class="icon">
              <img
                v-if="!isFriend"
                src="../../../images/icons/add-friend.svg"
              >
              <img
                v-else
                src="../../../images/icons/add-friend-gray.svg"
              >
            </div>
            <div class="descreption">
              <strong>
                <p
                  class="font-size-15"
                  :class="{'color-gray-4': isFriend}"
                >友達になる</p>
              </strong>
              <p
                v-if="!isFriend"
                class="font-size-12"
              >
                実際に面職のある知り合いを見つけたら友達<br>
                になりましょう。 安心・安全のため、対面<br>
                では二次元コード認証、非対面では電話番号<br>
                の下4桁認証があります。友達とは、子育て<br>
                のシェアリングを頼りあえます。
              </p>
              <p
                v-else
                class="font-size-12 color-base"
              >
                すでに友達承認済み
              </p>
            </div>
          </div>
          <div class="report" @click="redirectToReportChat()">
            <div class="icon">
              <img
                src="../../../images/icons/report_base.svg"
                alt=""
              >
            </div>
            <div class="descreption">
              <strong><p v-html="`${this.userName}を運営に通報する`"/></strong>
            </div>
          </div>
          <div v-if="!isBlock" class="block-user" @click="blockUser()">
            <div class="icon">
              <img
                src="../../../images/icons/block_chat_base.svg"
                alt=""
              >
            </div>
            <div class="descreption">
              <strong><p v-html="`${this.userName}をブロックする`"/></strong>
            </div>
          </div>
          <div v-else class="block-user" @click="unblockUser()">
            <div class="icon">
              <img
                src="../../../images/icons/block_chat_base.svg"
                alt=""
              >
            </div>
            <div class="descreption">
              <strong><p v-html="`${this.userName}のブロックを解除する`"/></strong>
            </div>
          </div>
          <div
            v-if="isFriend || friendRequested"
            class="unfriend"
            @click="unfriend"
          >
            <div class="block-fake" />
            <div class="descreption">
              <strong><p class="color-red">友達から削除する</p></strong>
            </div>
          </div>
          <div
            v-if="(hasFollowed && !isFollowed && !isFriend) || friendRequested"
            class="unfollow"
            @click="unfollow()"
          >
            <div class="block-fake" />
            <div class="descreption">
              <strong><p class="color-red">フォローをやめる</p></strong>
            </div>
          </div>
          <div
            v-if="isFollowed && !isFriend && !friendRequested"
            class="delete-follower"
            @click="deleteFollower()"
          >
            <div class="block-fake" />
            <div class="descreption">
              <strong><p class="color-red">このフォロワーを削除</p></strong>
            </div>
          </div>
        </section>
      </div>
    </b-modal>
    <b-modal
        :active.sync="isOpenOtpModal"
        has-modal-card
        trap-focus
        :destroy-on-hide="false"
        aria-role="dialog"
        aria-label="Example Modal"
        close-button-aria-label="Close"
        aria-modal
        class="user-modal"
      >
        <div
          class="modal-card"
          style="width: auto"
        >
          <section class="modal-card-body card-add-friend">
            <div class="add-friend">
              <img
                src="../../../images/icons/arr_left.svg"
                alt=""
                @click="closeOtpModal"
              >
              <strong><p>電話番号の下4桁を入力する</p></strong>
              <div class="div-fake" />
            </div>
            <div
              v-if="noPhoneText"
              class="set-mb-error error-text-wrapper"
            >相手の電話番号が現在登録されていません。相手に番号の登録を依頼しますので、登録完 了までお待ち下さい。番号が登録されましたら お知らせいたします。</div >
            <div
              v-if="wrongAuthText"
              class="set-mb-error error-text-wrapper"
            >相手の電話番号下4桁と一致しません</div >
            <div class="form-number-phone">
              <MyCommuOtp
                ref="otpInput"
                input-classes="otp-input"
                :input-classes="this.inputError ? 'input-error' : ''"
                :num-inputs="4"
                :should-auto-focus="true"
                :is-input-num="true"
                @on-complete="handleOnComplete"
                @on-change="handleOnChange"
                :value="this.keyPhone.split('')"
              />
            </div>
            <div class="set-pb-p">友達になるユーザーの電話番号下4桁を入力してください</div >
            <div
              v-if="noPhoneText"
              id="goback"
              onclick="window.history.back()"
            >
              <MyCommuBaseButton
                text-content="閉じる"
                :is-white-button="isWhiteButton"
              />
            </div>
            <div
              v-else
              id="sendData"
              @click="sendFriendRequest"
            >
              <MyCommuBaseButton
                text-content="次へ"
                :disable="!checkDisabled"
              />
            </div>
          </section>
        </div>
      </b-modal>
    <b-modal
      :active.sync="isOpenPopupUnfriend"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-label="Example Modal"
      close-button-aria-label="Close"
      aria-modal
      class="unfriend"
    >
      <div class="modal-card">
        <header class="modal-card-head">
          <p>本当に削除しますか？</p>
          <p>この作業は元に戻せません</p>
        </header>
        <div class="modal-card-body">
          <div
            class="card-left"
            @click="isOpenPopupUnfriend = false"
          >
            <a href="#">キャンセル</a>
          </div>
          <div class="card-right">
            <a
              :href="deleteLink"
              data-method="delete"
            >削除する</a>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'

export default {
  components: {
  },
  props: {
    profile: {
      type: Object,
      require: true
    },
    isFriend: {
      type: Boolean,
      default: false
    },
    lastItem: {
      type: Boolean,
      default: false
    },
    hasFollowed: {
      type: Boolean,
      default: false
    },
    isFollowed: {
      type: Boolean,
      default: false
    },
    friendRequested: {
      type: Boolean,
      default: false
    },
    isBlock: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
      isOpenPopup: false,
      isOpenNumberPhone: false,
      isFriend: this.isFriend,
      isOpenPopupUnfriend: false,
      keyPhone: '',
      isOpenOtpModal: false,
      inputError: false,
      noPhoneText: false,
      wrongAuthText: false,
      userName: this.profile.nickName ? this.profile.nickName : this.profile.fullName
    }
  },
  computed: {
    ...mapState('session', ['communityId']),
    checkDisabled () {
      return this.keyPhone.length === 4
    },
    deleteLink () {
      return `/communities/${this.communityId}/users/${this.profile.userId}/friendship`
    }
  },
  created () {
  },
  methods: {
    openOtpModal () {
      if (this.profile.sentFriendRequest) return
        this.questionModal = false
        this.isOpenOtpModal = true
      },
    closeOtpModal () {
      this.isOpenOtpModal = false
      this.isOpenPopup = true
    },
    handleOnComplete (value) {
      this.keyPhone = value
    },
    handleOnChange (value) {
      this.keyPhone = value
    },
    navigate () {
      window.location.href = `/communities/${this.communityId}/users/${this.profile.userId}`
    },
    openModalUser () {
      this.isOpenPopup = true
    },
    back () {
      this.isOpenOtpModal = false
    },
    openNumberPhone () {
      if (!this.isFriend) {
        this.isOpenOtpModal = true
      }
    },
    async update () {
      // eslint-disable-next-line camelcase
      const data = new FormData()
      data.append('friendship[auth_key]', this.keyPhone)
      await axios({
        method: 'PATCH',
        url: `/communities/${this.communityId}/users/${this.profile.userId}/friendship`,
        data: data
      }).then((res) => {
        if (res.data.status) {
          this.isLv3 = true
          this.isOpenOtpModal = false
        }
      })
    },
    async unfollow () {
      const data = new FormData()
      data.append('user_id', this.profile.userId)
      if (this.hasFollowed) {
        await axios({
          method: 'POST',
          url: `/communities/${this.communityId}/follows/unfollow`,
          data: data
        }).then((res) => {
          if (res.data) {
            this.hasFollowed = !this.hasFollowed
          }
          this.questionModal = false
        })
      }
    },
    deleteFollower () {
      const data = new FormData()
      data.append('user_id', this.profile.userId)
      if (this.isFollowed) {
        axios({
          method: 'POST',
          url: `/communities/${this.communityId}/follows/delete_follower`,
          data: data
        }).then((res) => {
          if (res.data) {
            this.isFollowed = !this.isFollowed
          }
          this.questionModal = false
        })
      }
    },
    message () {
      window.location.href = `/communities/chats/${this.profile.userId}/check_message`
    },
    redirectToReportChat() {
      window.location.href = 'https://ws.formzu.net/fgen/S77589438/'
    },
    blockUser () {
      axios.put(`/users/${this.profile.userId}/one_to_one_chat/block.json`).then((respone) => {
        window.location.reload()
      })
    },
    unblockUser () {
      axios.put(`/users/${this.profile.userId}/one_to_one_chat/unblock.json`).then((respone) => {
        window.location.reload() 
      })
    },
    unfriend () {
      this.isOpenPopupUnfriend = true
    },
    async follow () {
      const data = new FormData()
      data.append('user_id', this.profile.userId)
      if (!this.hasFollowed) {
        await axios({
          method: 'POST',
          url: `/communities/${this.communityId}/follows/follow`,
          data: data
        }).then((res) => {
          if (res.data) {
            this.hasFollowed = !this.hasFollowed
          }
          this.questionModal = false
        })
      }
    },
    async sendFriendRequest () {
      if (!this.profile.sentFriendRequest) {
      const data = new FormData()
      data.append('friendship[auth_key]', this.keyPhone)
      axios.post(`/communities/notifications/${this.profile.userId}/send_friend_request`, data).then((res) => {
        if (res.data.status === 'success') {
          commuSuccess('友達を申請しました。')
          window.location.reload()
        } else if (res.data.status === 'sented') {
          commuError('フォローが失敗しました。')
          this.isOpenOtpModal = false
          this.inputError = false
          this.isOpenOtpModal = false
          this.wrongAuthText = false
          this.noPhoneText = false
        } else if (res.data.status === 'auth_failed') {
          this.wrongAuthText = true
          this.inputError = true
        } else if (res.data.status === 'receiver_does_not_have_phone_number') {
          this.noPhoneText = true
          this.inputError = true
        }
      })
      }
    },
  }
}
</script>
<style scoped lang="sass">
  /deep/
    .input-error
      border: 1px solid #EE5353 !important
  .user-modal
    flex-direction: column
    justify-content: flex-end
    /deep/
      .modal-background
        background: #273D35
        opacity: 0.9
      .modal-card
        height: 65vh
        border-radius: 10px 10px 0px 0px
        .modal-card-body
          .user-avatar
            padding-top: 8px
            display: flex
            padding-bottom: 24px
            border-bottom: 1px solid #CCCCCC
            img
              width: 48px
              height: 48px
              border-radius: 50%
              object-fit: cover
              flex-shrink: 0
              margin-right: 8px
            .card-body
              display: flex
              flex-direction: column
              justify-content: center
          .mail, .friendship, .report, .block-user, .unfriend, .follow, .unfollow, .delete-follower
            display: flex
            padding: 16px 0
            border-bottom: 1px solid #CCCCCC
            .icon
              margin-right: 18px
          .unfriend, .follow, .unfollow, .delete-follower
            border-bottom: none
            .block-fake
              width: 24px
              margin-right: 18px
        .card-add-friend
          padding: 27px 32px 32px 32px
          .add-friend
            display: flex
            align-items: center
            justify-content: space-between
            margin-bottom: 37px
            .div-fake
              width: 24px
          .form-number-phone
            display: flex
            justify-content: center
      .modal-close
        display: none !important
  .f-size-12
    font-size: 12px
  .f-size-14
    font-size: 14px
  .f-size-15
    font-size: 15px
  .f-size-16
    font-size: 16px
  .color-gray
    color: #7C7C7C
  .color-base
    color: #273D35
  .font-weight-bold
    font-weight: bold
  .mt-8
    margin-top: 8px
  .mr-8
    margin-right: 8px
  .mt-16
    margin-top: 16px
  .mt-24
    margin-top: 24px
  .mt-40
    margin-top: 40px
  .mb-8
    margin-bottom: 8px
  .mr-7
    margin-right: 7px
  .w-24
    width: 24px
  .text-center
    text-align: center
  .card-profile
    padding: 24px 16px 32px
    .info-my-self, .info-children, .info-commu, .qualifications
      margin-top: 40px
    .action
      display: flex
      .add-friend, .message
        width: calc((100% - 7px) / 2)
  .topbar
    display: flex
    padding: 13px 15px 4px 15px
    background-color: white
    position: fixed
    width: 100%
    z-index: 99
    .topbar-title
      color: #273D35
      font-weight: bold
      font-size: 16px
      text-align: center
      width: 100%
      justify-content: center
    .action-chat
      /deep/
        .dropdown-menu
          min-width: 157px
          width: unset !important
          top: 65px !important
          left: auto !important
          border: 1px solid #AAAAAA
          box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.3)
          border-radius: 8px
          padding: unset
          transform: translate3d(0%, -25%, 0)
          right: 35px !important
          .dropdown-content
            padding: unset
            .dropdown-item
              padding: 1rem 1rem 0rem 1rem
              display: flex
              span
                color: #FFFFFF
              p
                color: #273D35
                margin-left: 10px
                font-weight: 300
                font-size: 14px
                display: flex
                align-items: center
            .dropdown-last
              padding-bottom: 1rem
  .set-pd-t
    padding-top: 78px
  .error-text-wrapper
    border: 1px solid #EE5353
    border-radius: 10px
    background-color: #FFEEF0
    padding: 10px
    color: #EE5353
    text-align: center
  .set-pt-p
    padding-top: 20px
  .set-pb-p
    padding-bottom: 20px
  .set-mb-error
    margin-bottom: 30px
  .question
    display: flex
    align-items: center
    color: #2DBA87
    font-weight: bold
    font-size: 12px
  .color-base
    color:  #273D35
  .color-gray
    color: #7C7C7C
  .color-gray-4
    color: #AAAAAA
  .color-red
    color: #EE5353
  .mt-8
    margin-top: 8px
  .mt-10
    margin-top: 10px
  .font-size-15
    font-size: 15px
  .font-size-12
    font-size: 12px
  .user-card
    border-top: 1px solid #CCCCCC
    display: flex
    align-items: center
    background-color: #FFFFFF
    .d-flex
      display: flex
      width: 90%
      .avatar
        margin: 16px
        width: 64px
        height: 64px
        border-radius: 50%
        object-fit: cover
      .card-body
        display: flex
        flex-direction: column
        justify-content: center
        width: 75%
        .user-name
          font-size: 15px
          line-height: 15px
          color: #273D35
        .content
          font-size: 12px
          line-height: 12px
          color: #7C7C7C
        .no-introduction
          margin-bottom: 0
      .card-icon
        display: flex
        position: absolute
        right: 16px
  .the-last
    border-bottom: 1px solid #CCCCCC
  .unfriend, .follow, .unfollow, .delete-follower
    /deep/
      .animation-content
        width: 270px
        margin: auto
        .modal-card
          border-radius: 20px
          .modal-card-head
            background-color: #FFFFFF
            display: flex
            flex-direction: column
            justify-content: center
            align-items: center
            padding: 32px 0
            p
              color: #273D35
              font-weight: bold
              font-size: 14px
          .modal-card-body
            display: flex
            padding: unset
          .card-left, .card-right
            width: 50%
            padding: 20px 0
            display: flex
            justify-content: center
            a
              font-size: 15px
          .card-left
            border-right: 1px solid #cccccc
            a
              color: #2DBA87
          .card-right
            a
              color: #EE5353
              font-weight: bold
      .modal-close
        display: none !important
</style>
