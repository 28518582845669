<template>
  <div class="">
    <p class="guide">コミュニティの安心・安全性を高め、趣味や関心事が近しい人とつながるために、詳しいプロフィールを入力してください。</p>
    <p class="sub-guide">コミュニティではニックネームが設定できます。</p>

    <label class="sign-up-label">
      <div class="primary">お名前</div>
    </label>
    <div class="columns is-mobile is-mt-1">
      <div class="column">
        <BField>
          <BInput
            v-model="lastNameModel"
            :name="this.lastNameProps.name"
            :placeholder="this.lastNameProps.placeholder"
            class="is-fullwidth"
            custom-class="input-green"
          />
        </BField>
      </div>
      <div class="column">
        <BField>
          <BInput
            v-model="firstNameModel"
            :name="this.firstNameProps.name"
            :placeholder="this.firstNameProps.placeholder"
            class="is-fullwidth"
            custom-class="input-green"
          />
        </BField>
      </div>
    </div>

    <label class="sign-up-label">
      <div class="primary">フリガナ</div>
    </label>
    <div class="columns is-mobile is-mt-1">
      <div class="column">
        <BField
          :type="{ 'is-danger': this.lastNameKanaProps.errors.length > 0 }"
          :message="this.lastNameKanaProps.errors[0]"
        >
          <BInput
            v-model="lastNameKanaModel"
            :name="this.lastNameKanaProps.name"
            :placeholder="this.lastNameKanaProps.placeholder"
            class="is-fullwidth input-my-commu"
            custom-class="input-green"
            icon-pack="fas"
          />
        </BField>
      </div>
      <div class="column">
        <BField
          :type="{ 'is-danger': this.firstNameKanaProps.errors.length > 0 }"
          :message="this.firstNameKanaProps.errors[0]"
        >
          <BInput
            v-model="firstNameKanaModel"
            :name="this.firstNameKanaProps.name"
            :placeholder="this.firstNameKanaProps.placeholder"
            class="is-fullwidth input-my-commu"
            custom-class="input-green"
            icon-pack="fas"
          />
        </BField>
      </div>
    </div>

    <label class="sign-up-label">
      <div class="primary">性別</div>
    </label>
    <div class="columns is-mt-1 is-flex">
      <div class="column">
        <BaseSelectInput
          v-bind="sexProps"
          placeholder="選択する"
          @validation="sexValidation"
        />
      </div>
      <div class="column">
      </div>
    </div>

    <label class="sign-up-label is-mt-3">
      <div class="primary">生年月（非公開）</div>
    </label>
    <div class="is-mt-1">
      <MyCommuBirthdayDatePicker
        v-bind="dateOfBirthProps"
        :discard-day="true"
        label=""
        year-placeholder="1980"
        month-placeholder="12"
        :validate-day="false"
        @date-validation="dateOfBirthValidate"
      />
    </div>

    <label class="sign-up-label is-mt-4">
      <div class="primary" v-text="mobilePhoneProps.label"/>
    </label>

    <div class="is-mt-1">
      <BField
        :type="{ 'is-danger': this.mobilePhoneProps.errors.length > 0 }"
        :message="this.mobilePhoneProps.errors[0]"
      >
        <BInput
          v-model="mobilePhoneModel"
          :name="this.mobilePhoneProps.name"
          :placeholder="this.mobilePhoneProps.placeholder"
          class="is-fullwidth input-my-commu"
          custom-class="input-green"
          icon-pack="fas"
        />
      </BField>
    </div>

    <div class="columns is-mobile is-centered is-mt-5 is-mb-5">
      <div class="column">
        <BButton
          type='submit'
          @click='submit'
          :disabled="checkInput"
          class="is-fullwidth is-success"
          v-text="submitProps.label"
        >
        </BButton>
      </div>
    </div>

  </div>
</template>

<script>
import MyCommuBirthdayDatePicker from './BirthdayDatePicker'
import BaseSelectInput from '../BaseSelectInput'
import BaseTextInput from '../BaseTextInput'

export default {
  components: { MyCommuBirthdayDatePicker, BaseSelectInput, BaseTextInput},
  props: {
    lastNameProps: {
      type: Object,
      required: true
    },
    firstNameProps: {
      type: Object,
      required: true
    },
    lastNameKanaProps: {
      type: Object,
      required: true
    },
    firstNameKanaProps: {
      type: Object,
      required: true
    },
    nicknameSelected: {
      type: Boolean
    },
    nickname: {
      type: String,
      default: ''
    },
    sexProps: {
      type: Object,
      required: true
    },
    dateOfBirthProps: {
      type: Object,
      required: true
    },
    mobilePhoneProps: {
      type: Object,
      required: true
    },
    submitProps: {
      type: Object,
      required: true
    }

  },
  data () {
    return {
      lastNameModel: this.lastNameProps.value,
      firstNameModel: this.firstNameProps.value,
      lastNameKanaModel: this.lastNameKanaProps.value,
      firstNameKanaModel: this.firstNameKanaProps.value,
      dateOfBirth: this.dateOfBirthProps.value !== null,
      sexModel: this.dateOfBirthProps.value !== null || this.submitProps.value !== null,
      mobilePhoneModel: this.mobilePhoneProps.value,
    }
  },
  computed: {
    checkInput () {
      return (this.lastNameModel === '' || this.firstNameModel === '' || this.lastNameKanaModel === '' || this.firstNameKanaModel === '' || !this.dateOfBirth || !this.sexModel || this.mobilePhoneModel === '' || this.mobilePhoneModel === null)
    }
  },
  methods: {
    submit () {
      document.querySelector('form').submit()
    },
    dateOfBirthValidate (value) {
      this.dateOfBirth = value
    },
    sexValidation (value) {
      this.sexModel = value
    }
  }
}
</script>
<style lang="sass" scoped>
/deep/
  @import '~stylesheets/components/required-label'
  @import '~stylesheets/components/published-label'

/deep/ .input-green
  border-radius: 6px
  caret-color: #2DBA87
  &:focus
    border: 1px solid #2DBA87
    border-radius: 6px
    box-shadow: 0 0 0 0

/deep/ .input-my-commu > span
  color: #2DBA87 !important

/deep/
  input
    height: 48px
    font-family: "Hiragino Kaku Gothic ProN"
  span
    height: 48px !important
    select
      height: 48px
      font-family: "Hiragino Kaku Gothic ProN"
  button
    height: 48px
    border-radius: 6px
    font-family: "Hiragino Kaku Gothic ProN"
.columns
  margin-bottom: 0 !important
.column
  padding: 5px 11px 24px 11px
.guide
  padding-bottom: 16px
.sub-guide
  color: #2DBA87
  padding-bottom: 16px
  font-size: 14px
.is-success
  font-weight: bold
</style>
