<template>
  <div>
    <div v-if="usersChildren.length > 0" class="children-component">
      <div
        v-for="(child) in usersChildren"
        :key="child.id"
        class="child"
      >
        <div class="first-content">
          <img :src="child.src" class="children-image">
          <div>
            <div class="name-kanji">{{ child.name }}</div>
            <div class="name-kana">{{ child.nameKana }}</div>
            <div class="sex-and-age">{{ child.info}}</div>
          </div>
        </div>
        <div v-if="myself && !preview" class="second-content">
          <a class="edit" @click="redirectToEdit(child.id)">編集する</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data () {
    return {}
  },
  props: {
    usersChildren: {
      type: Array,
      default: () => []
    },
    myself: {
      type: Boolean,
      default: false
    },
    preview: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    redirectToEdit (id) {
      window.location.href = `/communities/child_profiles/${id}/edit`
    }
  }
}
</script>

<style lang="sass" scoped>
.children-component
  background: #FFFFFF
  border: 1px solid #CCCCCC
  border-radius: 12px
  padding: 12px 20px
  .child
    padding: 8px 0
    display: flex
    justify-content: space-between
    .first-content
      display: flex
      align-items: center
      .children-image
        width: 64px
        height: 64px
        object-fit: cover
        flex-shrink: 0
        border-radius: 50%
        margin-right: 16px
      .name-kanji
        padding-bottom: 8px
        color: #273D35
        font-size: 15px
        line-height: 15px
      .name-kana
        padding-bottom: 6px
        font-size: 12px
        line-height: 12px
        color: #7C7C7C
      .sex-and-age
        font-size: 12px
        line-height: 12px
        color: #7C7C7C
    .second-content
      display: flex
      align-items: center
      .edit
        align-items: center
        font-style: normal
        font-weight: bold
        font-size: 14px
        line-height: 14px
        text-align: center
        color: #2DBA87
        padding: 9px
        background: #FFFFFF
        box-shadow: 0 2px 8px #F2EFDE
        border-radius: 6px
        border: 0
</style>
