<template>
  <div>
    <div :class="{'is-flex justify-space-between': optionalOrRequire, 'mb-7px': label===''} ">
      <label
        :for="id"
        class="label"
        v-text="label"
      />
      <MyCommuOptional v-if="isOptional && !isRequire"/>
      <MyCommuRequire v-if="isRequire && !isOptional"/>
    </div>
    <input
      v-if="type === 'input'"
      :id="id"
      v-model="inputValue"
      :name="name"
      :placeholder="placeholder"
      class="base-input"
      @input="$emit('input', $event.target.value)"
    />
    <textarea
      v-if="type === 'textarea'"
      :id="id"
      v-model="inputValue"
      rows="4"
      :name="name"
      :placeholder="placeholder"
      class="base-input area"
      @input="$emit('input', $event.target.value)"
    />
  </div>
</template>

<script>

export default {
  props: {
    name: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    },
    isOptional: {
      type: Boolean,
      default: false
    },
    isRequire: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'input'
    }
  },
  data () {
    return { inputValue: this.value }
  },
  computed: {
    optionalOrRequire () {
      return (this.isOptional && !this.isRequire) || (this.isRequire && !this.isOptional)
    }
  },
  methods: {
  }
}
</script>

<style scoped lang="sass">
.mb-7px
  margin-bottom: 7px
.label
  margin-bottom: 8px
  font-size: 14px
.base-input
  border-radius: 6px
  border: 1px solid #CCCCCC
  width: 100%
  height: 48px
  padding-left: 16px
  font-size: 15px
  line-height: 15px
  &::placeholder
    color: #AAAAAA
  &:focus-visible
    outline: none
    border: 1px solid #2DBA87
    border-radius: 6px
    box-shadow: 0 0 0 0
    caret-color: #2DBA87
.justify-space-between
  justify-content: space-between
.area
  max-width: 100%
  padding: 16px 16px 30px 16px
  height: unset
  min-height: 48px
  line-height: 25px
  font-family: Hiragino Kaku Gothic ProN
</style>
