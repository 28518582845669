<template>
  <div class="password-modal-ok">
    <slot :on-click="onClick" />
    <BModal
      :active.sync="isModalOpen"
      has-modal-card
    >
      <div class="modal-card">
        <header class="modal-card-head">
          <p v-text="modalTitle" class="message-title"></p>
        </header>
        <div class="modal-card-body">
          <div class="card-left">
            <a href="#" @click="onClick">キャンセル</a>
          </div>
          <div class="card-right">
            <a :href="deleteLink" data-method="delete">はい</a>
          </div>
        </div>
      </div>
    </BModal>
  </div>
</template>

<script>
export default {
  props: {
    modalTitle: {
      type: String,
      default: '投稿を本当に削除してよろしいでしょうか'
    },
    deleteLink: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      isModalOpen: false
    }
  },
  methods: {
    onClick () {
      this.isModalOpen = !this.isModalOpen
    }
  }
}
</script>

<style scoped lang="sass">
.modal-card
  width: 17rem
  margin: auto !important
  text-align: center
.password-modal-ok
  border-radius: 20px
  /deep/ .modal-card
    border-radius: 20px
  /deep/ .modal-background
    background: #273D35
    opacity: 0.9
.card-left, .card-right
  display: block
  text-align: center
  width: 50%
  padding: 20px
  color: #2DBA87
  a
    color: #2DBA87
    &:hover
      color: #2DBA87
.card-left
  border-right: #dbdbdb solid 1px
.modal-card-body
  display: inherit
  padding: 0
.message-title
  margin: 0 13% 0 13%
</style>