<template>
  <div class="managements-expense-news" :class="{ preview: preview }">
    <Back :title="title" />
    <div class="groups-new-container">
      <div class="groups-new-note">
        <div class="title-new-group">イベント名</div>
      </div>

      <div class="groups-new-note">
        <div class="sub-title">
          <span>開催日時：{{assigned_staff.event.event_time_my_commu_year_month_day}}</span>
          <br>
          <span>{{assigned_staff.event.title}}</span>
        </div>
      </div>

      <div class="form-containers" :class="{'mt-41': preview}">
        <div class="form-element is-flex flex-column">
          <div class="label">
            <label class="label label--left">経費の種類</label>
            <label class="label-optional label--right" v-if="!preview">任意</label>
          </div>

          <div class="mt-8">
            <div class="">
              <b-field>
                <b-select
                  placeholder="選択する"
                  custom-class="full-width"
                  v-if="!preview"
                  v-model="select_expense_type"
                >
                  <option value=""></option>
                  <option
                    v-for="expense_type in expense_types"
                    :key="expense_type[1]"
                    :value="expense_type[1]">{{expense_type[0]}}</option>
                </b-select>
              </b-field>
              <div class="field" v-if="preview">
                <div class="control is-medium is-clearfix is-flex">
                  <label class="label label--left label-preview" v-if="preview" >{{displayExpenseType}}</label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="form-element is-flex flex-column">
          <div class="label">
            <label class="label label--left pd-10">領収書画像/レシート添付</label>
            <label class="label-optional label--left" v-if="!preview">任意</label>
          </div>

          <div class="is-flex">
            <div class="">
              <BField class="is-white file is-flex flex-column mt-8">
                <BUpload v-model="file" class="file-label file-upload" accept=".jpg, .png, .webp, .jpeg, .gif" v-if="!preview">
                  <span class="file-cta">
                    <BIcon class="file-icon" icon="plus"></BIcon>
                  </span>
                </BUpload>
                <div class="display-image" v-if="file && preview">
                  <img :src="displayFile" />
                </div>
              </BField>
            </div>
          </div>
        </div>

        <div class="form-element is-flex flex-column">
          <div class="label">
            <label class="label label--left">金額</label>
          </div>

          <div class="is-flex mt-8">
            <div class="is-flex align-item-center w-100">
              <b-field class="w-50 pd-10" v-if="!preview">
                <b-input size="is-medium" v-model="price" placeholder="1000" type="number"></b-input>
              </b-field>
              <label class="label label--left yen" v-if="!preview">円</label>

              <div class="field" v-if="preview">
                <div class="control is-medium is-clearfix  is-flex">
                  <label class="label label--left label-preview" v-if="preview">{{displayPrice}}</label>
                  <label class="w-50 label label--left yen">円</label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="form-element is-flex flex-column">
          <div class="label">
            <label class="label label--left">経費建替日</label>
          </div>

          <div class="is-flex">
            <div class="w-100">
              <b-field v-if="!preview" class="mt-8 w-100">
                <MyCommuBaseDatePicker
                  v-model="choose_date"
                  placeholder="選択する"
                  @valueSelect="valueScheduledDate"
                />
              </b-field>
              <div class="field mt-8" v-if="preview">
                <div class="control is-medium is-clearfix  is-flex">
                  <label class="label label--left label-preview" v-if="preview">{{displayDate}}</label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="form-element groups-new-form-description">
          <div class="label">
            <label class="label label--left">内容</label>
          </div>
          <BField class="mt-8" v-if="!preview">
            <b-input v-model="description" type="textarea" placeholder="経費の内容"></b-input>
          </BField>
          <div class="field">
            <div class="field mt-8" v-if="preview">
              <div v-html="description" class="preview-description"></div>
            </div>
          </div>
        </div>

        <div class="form-element mt-40">
          <BButton
            v-if="!preview"
            size="is-medium" type='submit' @click='changePreview()' :disabled="checkInput" class="is-fullwidth is-success">
            入力内容を確認する
          </BButton>
          <BButton
            v-if="preview"
            size="is-medium" type='submit' @click='submitForm' :disabled="checkInput" class="is-fullwidth is-success">
            経費を申請する
          </BButton>
          <BButton
            v-if="preview"
            size="is-medium" type='submit' @click='changePreview(true)' :disabled="checkInput" class="is-fullwidth mt-15 is-white">
            戻る
          </BButton>
        </div>
      </div>
    </div>

    <BModal
      :active.sync="isComponentModalActive"
      has-modal-card
      width="70%"
      :on-cancel="redirectTop"
    >
      <div class="modal-card" style="width: auto">
        <div class="modal-card-body">
          <img src="../../../images/for_community/circle-check.png" />
          <p class="text-default">
            経費の申請が完了したしまし
            <br>
            た。控えはメールでお送りい
            <br>
            たします。
          </p>
        </div>
      </div>
    </BModal>
  </div>
</template>
<script>

import { mapActions, mapState } from 'vuex'
import Back from '../../../components/my-commu/Back'
import axios from '../../../utils/axios'
import { format as dateFormat, isSameYear } from 'date-fns'
import { commuError } from 'lib/app-toast'

export default {
  components: { Back },
  props: {
    community_id: {
      type: Number,
      require: true
    },
    expense_types: {
      type: Array,
      require: true
    },
    assigned_staff: {
      type: Object,
      require: true
    }

  },
  data () {
    return {
      file: null,
      preview: false,
      select_expense_type: null,
      price: null,
      priceNumberFormat: null,
      choose_date: null,
      description: null,
      submit: false,
      isComponentModalActive: false,
      title: '経費申請入力'
    }
  },
  computed: {
    ...mapState('mycommu', ['titleHeader', 'hiddenHeader', 'currentCommunity']),
    ...mapState('space', ['unsetMargin', 'unsetPadding']),
    displayFile () {
      if (this.file) {
        const src = URL.createObjectURL(this.file)
        return src
      }
    },
    displayDate () {
      const date = this.choose_date
      return dateFormat(date, 'YYYY/M/D')
    },
    displayPrice () {
      return (parseInt(this.price)).toLocaleString()
    },
    displayExpenseType () {
      if (this.select_expense_type === null) {
        return ''
      }
      const expense_type = this.expense_types.find(element => element[1] == this.select_expense_type)
      return expense_type[0]
    },
    checkInput () {
      if (this.price === null || this.choose_date === null || this.description === null) {
        return true
      } else {
        return false
      }
    }
  },

  created () {
  },
  mounted () {
    this.toggerMyCommuButton(false)
    this.toggerHeader(true)
    this.unsetMargin.mainContainer = true
    this.unsetMargin.appMain = true
    this.unsetPadding.mainContainer = true
  },
  methods: {
    ...mapActions('mycommu', [
      'toggerMyCommuButton',
      'toggerHeader',
      'setTitleHeader',
      'setHiddenHeader'
    ]),
    ...mapActions('events', ['setEvents']),
    redirectEventNew () {
      window.location.href = `/communities/${this.currentCommunity.community_id}/managements/events/new`
    },
    changePreview (flag = false) {
      this.preview = !this.preview
      if (flag) {
        this.title = '経費申請入力'
      } else {
        this.title = '経費申請内容確認'
      }
    },
    async submitForm () {
      const form = new FormData()
      if (this.file) {
        form.append('expenses[receipt_image]', this.file, this.file.name)
      }
      form.append('expenses[note]', this.description)
      form.append('expenses[expense_type_id]', this.select_expense_type)
      form.append('expenses[price]', this.price)
      form.append('expenses[paid_date]', this.choose_date)
      await axios({
        method: 'PUT',
        url: `/communities/${this.currentCommunity.community_id}/managements/expenses/events/${this.assigned_staff.event.id}`,
        data: form
      }).then((res) => {
        if (res.data.status === false) {
          commuError(res.data.message)
        } else {
          this.isComponentModalActive = true
        }
      })
    },
    redirectTop () {
      window.location.href = `/communities/${this.currentCommunity.community_id}/managements/expenses/events`
    },
    valueScheduledDate (params) {
      this.choose_date = params
    }
  }
}
</script>

<style scoped lang="sass">
  .groups-new-container
    padding: 32px 32px 58px
    font-size: 14px !important
    .title-new-group
      color: #273D35
      font-weight: bold
      font-size: 17px
    .sub-title
      margin-top: 24px
      color: #273D35
      font-weight: 300
      font-size: 15px

  .form-element
    color: #AAAAAA
    margin-top: 24px

    .field
      color: #AAAAAA
      margin-bottom: unset
    .label
      .label--left
        margin-left: 0
      .label--right
        margin-left: auto
      .label-optional
        font-weight: 600
        font-size: 10px
        color: #2DBA87
        border: 1px solid #2DBA87
        padding: 1px 3px
        background: white
        height: 18px
    &.yen
      font-size: 14px
      font-weight: normal !important
  .flex-column
    flex-direction: column

  .column
    padding-top: 0

  .form-containers
    margin-top: 32px
  .mt-40
    margin-top: 40px

  .pd-10
    padding-right: 10px
  .field
    .control
      .select
        select
          width: 100%

  .file
      display: unset
      .file-upload
        width: 48px !important
        height: 48px !important
        box-shadow: 0 2px 8px #f2efde
        .file-cta
          width: 100%
          height: 100%

  .file-upload
      .file-icon
        margin: auto
        color: #2DBA87
  .is-white
    &.file
      .file-label
        width: 40%
  .display-image
    width: 48px
    height: 48px
    box-shadow: 0 2px 8px #F2EFDE
    border-radius: 6px
    img
      box-shadow: 0 2px 8px #f2efde
      border-radius: 6px
      aspect-ratio: 1 / 1
      object-fit: cover
  .label
    margin: 0 !important
    height: 14px
    font-size: 14px !important
  .preview
    .label
      font-weight: 600
      &.yen
        font-size: 14px
        font-weight: normal !important
    .label-preview
      font-weight: 300
  .mt-15
    margin-top: 15px
  /deep/
    .modal-background
      background: #273D35
      opacity: 0.9
    .modal-close
      display: none
  @media screen and (max-width: 768px)
    /deep/
    .modal .animation-content
      width: unset
  .modal-card-body
    margin: auto
    color: #273D35
    font-size: 15px
    border-radius: 20px
    text-align: center
    padding: 40px 16px
    width: 230px
    img
      margin-bottom: 20px
      height: 66px
      width: 66px
  .mt-8
    margin-top: 8px
  .mt-41
    margin-top: 41px
  /deep/
    .select
      width: 100%
      &:after
        margin-top: 0
        border-color: #273D35
      option:disabled
        color: #AAAAAA
    textarea, select, input
      border-radius: 6px
      height: 48px !important
      padding: 16px
      font-size: 15px !important
      color: #273D35 !important
      border: 1px solid #CCCCCC
      caret-color: #2DBA87
      outline: none
      background: white
      &::placeholder
        font-weight: 300
        font-size: 15px !important
        line-height: 20px
        color: #AAAAAA
      &:focus
        border: 1px solid #2DBA87
        box-shadow: unset
      &::-webkit-scrollbar
        display: none
    textarea
      width: 100%
      resize: none
      height: unset
    select
      width: 100%
      padding: 13px 16px
      color: #273D35 !important
      &:hover
        border-color: #2DBA87 !important
      outline: unset !important
      &:focus
        box-shadow: unset !important
      option
        color: #273D35 !important
  .align-item-center
    align-items: center
  .w-100
    width: 100%

  /deep/
    button
      font-style: normal
      font-weight: bold
      font-size: 15px
      line-height: 15px
      padding: 16px
      box-shadow: 0 2px 8px #F2EFDE
      border-radius: 6px
      span
        font-style: normal
        font-weight: bold
        font-size: 15px !important
        line-height: 15px !important
      &.is-white
        color: #2dbA87 !important
        border: none !important
    .preview-description
      font-weight: 300
      font-size: 14px
      white-space: pre
      color: #273D35

</style>
