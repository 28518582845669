<template>
  <button
    type="submit"
    :class="buttonClass"
    :data-disable-with="disableWith"
    :data-confirm="confirm"
    v-text="label"
  />
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true
    },
    disableWith: {
      type: String,
      default: '送信中です...'
    },
    isLarge: {
      type: Boolean,
      default: false
    },
    isMedium: {
      type: Boolean,
      default: false
    },
    confirm: {
      type: String,
      default: null
    },
    isOutlined: {
      type: Boolean,
      default: false
    },
    isFullwidth: {
      type: Boolean,
      default: false
    },
    isHalfwidth: {
      type: Boolean,
      default: false
    },
    isRounded: {
      type: Boolean,
      default: false
    },
    isSuccess: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    buttonClass () {
      return {
        button: true,
        'is-primary': true,
        'is-rounded': this.isRounded,
        'is-outlined': this.isOutlined,
        'is-large': this.isLarge,
        'is-medium': this.isMedium,
        'is-fullwidth': this.isFullwidth,
        'is-halfwidth': this.isHalfwidth,
        'is-success': this.isSuccess
      }
    }
  }
}
</script>

<style scoped lang="sass">
  /deep/ > button
    font-size: 15px
</style>
