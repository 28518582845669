<template>
  <div>
    <CardDetailsTopic
      :topic="topicCommu"
    />
  </div>
</template>

<script>
import CardDetailsTopic from '../components/my-commu/details/Topic.vue'
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    CardDetailsTopic
  },
  props: {
    topicCommu: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState('session', ['communityId'])
  },
  created () {
    this.toggerHeader(true)
  },
  mounted () {
    this.toggleIsForcedShowingFooter(true)
  },
  methods: {
    ...mapActions('mycommu', [
      'toggerHeader',
      'toggleIsForcedShowingFooter'
    ])
  }
}
</script>
