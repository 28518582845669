<template>
  <div class="community-tags">
    <ul v-if="!hasOneCommunity">
      <li
        :class="{ 'active': activeTagClass('all')}"
        @click="activeTag('all')"
      >
        全て表示
      </li>
      <li
        v-for="commu in communities"
        :key="commu.community_id"
        :class="{ 'active': activeTagClass(commu.community_id)}"
        @click="activeTag(commu.community_id)"
      >
        {{ commu.community_name }}
      </li>
    </ul>
    <p v-if="hasMessage" class="message">友達になったユーザーにメッセージを送れます。</p>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  components: {
  },
  props: {
    hasMessage: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return { activeTagKey: 'all' }
  },
  computed: {
    ...mapState('mycommu', ['communities']),
    hasOneCommunity () {
      return this.communities.length <= 1
    }
  },
  created () {
  },
  methods: {
    activeTagClass (key) {
      return (this.activeTagKey === key)
    },
    activeTag (key) {
      this.$emit('filterCommu', key)
      this.activeTagKey = key
    }
  }
}
</script>
<style scoped lang="sass">

::-webkit-scrollbar
  display: none

.community-tags
  overflow-x: auto
  white-space: nowrap
  padding-bottom: 16px
  .message
    padding: 16px 0 8px 16px
    font-size: 15px
    background-color: #f8f7f2
  ul
    padding: 16px
    padding-bottom: 0
    display: flex
    li
      border: 1px solid #2DBA87
      border-radius: 90px
      padding: 3px 12px
      background: #FFFFFF
      color: #2DBA87
      font-size: 14px
      font-weight: 400
      text-align: center
      margin-right: 8px
      &.active
        background: #D5F1E7
      &:last-child
        margin-right: unset
</style>
