<!-- import Modal from '../Modal' -->
<template>
  <Modal v-model="active">
    <template #header>
      <Back title="シェア投稿" />
    </template>
    <div v-if="steps('init')" class="container-share-posts">
        <div class="description">
          <span>モノをシェア</span>
        </div>
        <div class="sub-description">
          <span>同じコミュニティのユーザーに</span>
          <br>
          <span>暮らしのお手伝いをお願いすることができます</span>
        </div>
        <div class="columns is-mobile is-centered ">
          <div class="column line-black"></div>
        </div>
        <Inviting
          :submit="fSubmit"
        />
      </div>

      <div v-if="steps('preview')"  class="container-share-preview">
        <div class="description">
          <span>投稿する内容を確認してください</span>
        </div>

        <div class="container-share-preview--content container-type">
          <span class="container-share-preview--title">お願いしたい事</span>
          <span class="container-type--element">{{shareTypeText}}</span>
        </div>

        <div class="container-share-preview--content container-title">
          <span class="container-share-preview--title">内容</span>
          <span class="container-type--element">
            {{objects.body}}
          </span>
        </div>

        <div class="container-share-preview--content container-body">
          <span class="container-share-preview--title">お願いしたい日時</span>
          <span class="container-type--element">
            {{objects.body}} 2022/12/12　12:15〜13:10
          </span>
        </div>

        <div class="container-share-preview--content container-title">
          <span class="container-share-preview--title">募集締め切り</span>
          <span class="container-type--element">
            {{placeText}} 2022/12/12
          </span>
        </div>

        <div class="container-share-preview--content container-title">
          <span class="container-share-preview--title">場所</span>
          <span class="container-type--element">
            {{dateText}} メッセージのやり取りで決定
          </span>
        </div>

        <div class="container-share-preview--content container-image">
          <span class="container-share-preview--title">内容</span>
          <div class="container-type--images">
            <div class="display-image">
              <div
                v-for="(imagexx, index) in objects.images"
                :key="`images-${index}`"
                >
                <img :src="displayFile(imagexx)" />
              </div>
            </div>
          </div>
        </div>

        <div class="container-share-preview--content container-title">
          <span class="container-share-preview--title">お礼の気持ち</span>
          <span class="container-type--element">
            {{dateText}} #ダミーテキスト,#ダミーテキスト
          </span>
        </div>

        <div class="container-share-preview--content is-mt-5 is-mb-5 container-button">
          <div class="is-flex">
            <BButton type='submit' @click='backInit' class="is-halfwidth btn-submit is-medium">
              修正する
            </BButton>
            <BButton type='submit' @click='submit' class="is-halfwidth is-success btn-submit is-medium">
              投稿する
            </BButton>
          </div>
        </div>

      </div>
      <div v-if="steps('done')"  class="container-share-preview">
        <BModal
          :active.sync="isComponentModalActive"
          has-modal-card
          :can-cancel="true"
          :on-cancel="redirectLogin"
          width="70%"
        >
          <div class="modal-card" style="width: auto">
            <div class="modal-card-body">
              <img src="../../images/for_community/circle-check.png">
              <p class="text-default">
                無事ホームに投稿
                <br>
                されました
              </p>
            </div>
          </div>
        </BModal>
      </div>
  </Modal>
</template>

<script>
import Inviting from "../../components/my-commu/share_post/Inviting"
import Modal from "../../components/my-commu/Modal"
import Back from "../../components/my-commu/Back"
import { mapActions, mapState } from 'vuex'

export default {
  components: { Back, Modal, Inviting },
  props: {
    active: Boolean,
  },
  model: {
    prop: 'active',
    event: 'update:active'
  },
  data () {
    return {
      isImageModalActive: false,
      isCardModalActive: false,
      iconBack: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
          '<g clip-path="url(#clip0_10847_11145)">\n' +
          '<path d="M5.43018 5.42999L18.5702 18.57" stroke="#273D35" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>\n' +
          '<path d="M18.5702 5.42999L5.43018 18.57" stroke="#273D35" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>\n' +
          '</g>\n' +
          '<defs>\n' +
          '<clipPath id="clip0_10847_11145">\n' +
          '<rect width="24" height="24" fill="white"/>\n' +
          '</clipPath>\n' +
          '</defs>\n' +
          '</svg>\n',
      isModalActive: this.active,
      step: "init", // init | preview | done,
    }
  },
  computed: {
    ...mapState('session', ['communityId']),
    ...mapState('mycommu', ['communityId', 'isShowSchedule'])
  },
  created () {
    this.toggleShowIconHeader(true)
    this.toggleShowChangeCommu(true)
  },
  mounted () {
    this.toggerMyCommuButton(true)
    this.toggerHeader(false)
    this.toggerMyCommuFooterMenu(true)
  },
  methods: {
    ...mapActions('mycommu', [
      'toggerMyCommuButton',
      'toggerHeader',
      'toggerMyCommuFooterMenu',
      'toggleShowIconHeader',
      'toggleShowChangeCommu'
    ]),
    gotoPage (url) {
      window.location.href = url
    },
    back () {
      // console.log(1)
    },
    steps(key) {
      return this.step === key
    },
    fSubmit (data) {
      if(this.step === "init") {
        this.objects = {...data}
        this.step = "preview"
      }else {

      }
    },
  }
}
</script>
<style scoped lang="sass">
.container-share-posts
  padding: 0px
  color: #273D35

  .description
    padding-top: 40px
    padding-bottom: 30px
    text-align: center

    span
      font-weight: 300
      font-size: 15px

  .elements
    padding: 0px 16px
    display: grid
    gap: 15px
    .element-item
      background: #FFFFFF
      box-shadow: 0px 2px 8px #f2efde
      border-radius: 6px
      padding: 18px
      display: flex
      flex-direction: column

      align-items: center
      color: #2DBA87
      text-align: center
      font-size: 15px
      font-weight: 600
      img
        padding-bottom: 15px
    .col-1
      grid-column: 1 / span 2
/deep/
  .modal-body
    background-color: #F8F7F2
  .modal-content
    margin: 0 !important
    padding: 0 32px
    .modal-description
      text-align: center
      margin: 40px 0
      color: #273D35
      font-size: 15px
.coming-soon
  text-align: center
  margin-top: 20px
  font-size: 45px
  font-weight: bold
  color: #2DBA87
  .container-share-posts
  padding: 0px 30px
  color: #424242

  .description
    padding-top: 40px
    padding-bottom: 30px
    text-align: center
    span
      font-weight: 600
      font-size: 17px
  .sub-description
    padding-bottom: 20px
    text-align: center

    span
      font-weight: 300
      font-size: 15px
      color: #273D35
  .elements
    padding: 0px
    display: grid
    gap: 15px
    grid-template-columns: auto auto
    padding-bottom: 30px
    .element-item
      background: #FFFFFF
      box-shadow: 0px 2px 8px #f2efde
      border-radius: 6px
      padding: 12.75px
      display: flex
      flex-direction: column

      align-items: center
      color: #2DBA87
      text-align: center
      font-size: 15px
      font-weight: 700

      &.is-success
        background: #2DBA87
        color: #FFFFFF
      img
        padding-bottom: 15px
.switch-custom
  display: flex
  justify-content: right
  align-items: center
.line-black
  border-top: 1px solid #CCCCCC

.content-share-posts
  .description
    padding: 0px
    padding-bottom: 32px
label
  color: #273D35
  font-weight: 400
  font-size: 14px

.file-cta
  height: 48px
  color: #2DBA87 !important
  .icon
    font-size: 18px
    padding: 0
    margin: 0

.columns
  margin-top: 10px
  margin-bottom: 0px !important
.post-title
  margin-top: 24px
.is-orange
  color: #F78F54
  font-weight: 700
  font-size: 12px

.switch-columns
  margin-top: 0px
.condition-columns
  span
    font-weight: 600
    font-size: 12px
.column-accep
  .column
    display: flex
    span
      margin-left: 10px
.container-share-preview
  color: #273D35
  padding: 0px 32px
  .description
    display: flex
    justify-content: center
    margin-top: 40px
    font-weight: 300
    font-size: 15px
    margin-bottom: 25px

  .container-type--images
    margin-top: 8px
    .display-image
      display: flex
      img
        box-shadow: 0px 2px 8px #F2EFDE
        border: 1px solid #7C7C7C
        width: 48px
        height: 48px
        border-radius: 6px
        margin-right: 16px
  .container-share-preview--content
    display: flex
    padding-top: 24px
    flex-direction: column

    &.container-button
      margin-top: 100px
  .container-share-preview--title
    font-weight: 600
    font-size: 14px
  .is-halfwidth
    width: 50%
    margin-right: 10px
  .button
    color: #2DBA87
    font-weight: 700
    font-size: 15px
    height: 48px
    &.is-success
      color: #ffffff
.modal-card-body
  //display: flex
  //justify-content: center
  //align-items: center
  //flex-direction: column
  color: #273D35
  font-size: 15px
  border-radius: 20px
  text-align: center
  padding: 40px 16px
  width: 230px
  img
    margin-bottom: 20px
    height: 66px
    width: 66px

.upload-images
  .field
    flex-direction: column
    display: flex

    .display-image
      display: flex
      img
        box-shadow: 0px 2px 8px #F2EFDE
        border: 1px solid #7C7C7C
        width: 48px
        height: 48px
        border-radius: 6px
        margin-right: 16px

.event-label,.datepicker-event
  font-weight: 300
  font-size: 14px
  line-height: 14px
  color: #273D35
  display: flex
  justify-content: space-between
  align-items: center
.switch
  margin-left: 10px
</style>
