<template>
  <div>
    <Processing/>
    <MyCommuUserIndex :no-result="noResult" v-if="(!searched || noResult) && !loading"
      text="キーワードで</br>マイコミュ内の投稿、イベント、シェアリングを</br>検索できます。"
      notFound="該当する投稿などが</br>見つかりませんでした。"
    />

    <div v-if="searchResultCount > 0 && !loading" class="border-bottom-1-ccc">
      <div v-for="share in searchResults" :key="share.id">
        <MyCommuCardShare v-if="share.sharingType == 'borrowing'" :path="share.path" :share="share" />
        <MyCommuCardShare v-if="share.sharingType == 'entry'" :path="share.path" :share="share" />
        <MyCommuCardShare v-if="share.sharingType == 'lending'" :path="share.path" :share="share" />
        <MyCommuCardShare v-if="share.sharingType == 'giving'" :path="share.path" :share="share" />
        <MyCommuCardShare v-if="share.sharingType == 'living'" :path="share.path" :share="share" />
        <MyCommuCardShare v-if="share.sharingType == 'taking'" :path="share.path" :share="share"/>
        <MyCommuCardEvent v-if="share.sharingType == 'events'" :path="share.organizationPath" :event="share" />
        <Topic v-if="share.sharingType == 'topic'" :path="share.communityPath" :topic="share" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  components: {
  },
  props: {
  },
  computed: {
    ...mapState('mycommu', ['searched', 'titleHeader', 'hiddenHeader', 'showSearch', 'loading']),
    ...mapState('home', ['searchResults']),
    searchResultCount () {
      return this.searched && this.searchResults.length
    },
    noResult () {
      console.log(this.searchResults[0])
      return this.searched && this.searchResults.length === 0
    }
  },
  created () {
    this.toggleIsFindUser(false)
    this.toggleShowSearch(true)
    this.titleHeader.display = false
    this.hiddenHeader.tabGroup = true
    this.hiddenHeader.notificationAndMessage = true
    this.hiddenHeader.topBar = true
    this.setTitleHeader(this.titleHeader)
    this.setHiddenHeader(this.hiddenHeader)
    this.toggerMyCommuButton(false)
  },
  methods: {
    ...mapActions('mycommu', [
      'toggleIsFindUser',
      'setTitleHeader',
      'setHiddenHeader',
      'toggleShowSearch'
    ])
  }
}
</script>
<style scoped lang="sass">
  .count-users
    font-size: 15px
    margin: 18px 16px 24px
  .border-bottom-1-ccc
    border-bottom: 1px solid #CCCCCC
</style>
