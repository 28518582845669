<template>
  <div>
    <div class="component">
      <div
        v-for="(licence, index) in licences"
        :key="index"
      >
        <div
          class="child"
          v-if="licence !== ''"
        >
          {{ licence }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    licences: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style lang="sass" scoped>
.component
  background: #FFFFFF
  border: 1px solid #CCCCCC
  border-radius: 12px
  padding: 16px 20px
  .child
    font-size: 15px
    line-height: 15px
    padding: 4px 0
    display: flex
    justify-content: space-between
</style>
