<template>
  <div>
    <div class="topic-detail">
      <TopicHeader v-bind="topicHeaderProps" v-if="viewMode" :isMyCommuDomain="isMyCommuDomain">
        <div v-if="isMyCommuDomain">
          <p v-if="canUpdateTopic" class="dropdown-item item-hover" @click="toggleViewEdit()"><i class="fal fa-pen"></i> 編集する</p>
          <slot name="option-menu" />
        </div>
        <div v-else :isMyCommuDomain="isMyCommuDomain">
          <p v-if="canUpdateTopic" class="dropdown-item item-hover" @click="toggleViewEdit()">編集</p>
          <slot name="option-menu" />
        </div>
      </TopicHeader>
      <div class="main-content">
        <!-- <div v-if="hasImages" class="main-image">
          <image-modal
            :src="mainImage.url"
            :original-src="mainImage.original"
          />
          <div class="sub-image-container">
            <div
              v-for="(img, idx) in subImages"
              :key="idx"
              class="sub-image"
            >
              <image-modal
                :src="img.small"
                :original-src="img.original"
              />
            </div>
          </div>
        </div> -->
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div>
          <div
            v-if="displayFullPost"
            class="body"
            v-html="body"
          >{{body}}</div>
        </div>
      </div>
      <div class="like-and-comment">
        <ReactionTopic
          :likes="likes"
          :likesCount="likesCount"
          :liked="liked"
          :reactionUrl="reactionUrl"
          :displayHeart="true"
        />
      </div>
      <div class="action-panel">
        <div class="actions">
          <a
            href="javascript:void(0)"
            class="toggle-comment-form"
            :class="{ hide: !canPostComment }"
            @click="toggleReaction"
          >
            <i :class="liked ? 'fas fa-heart orange-color' : 'fas fa-heart white-color'" />
            いいね
          </a>
          <a
            href="javascript:void(0)"
            class="toggle-comment-form"
            :class="{ hide: !canPostComment }"
            @click="toggleCommentForm"
          >
            <i class="far fa-comment" />
            コメント
          </a>
        </div>
      </div>
    </div>
    <div class="topic-comments">
      <div class="comment-count">
        <span class="comment-title">
          コメント
        </span>
        <i class="far fa-comment green" />
        <span class="counting">
          {{commentsCount}}
        </span>
      </div>
      <p>
        相手の事を考え丁寧なコメントでご利用ください。不適切な
        表現や言葉遣いなどはアカウント停止の対応を取らせていた
        だく事があります。
      </p>
      <div
        class="comments"
      >
        <TopicComment
          v-for="(comment, idx) in comments"
          v-bind="comment"
          :key="idx"
          :short="true"
          :isMyCommuDomain="isMyCommuDomain"
          :replyable="isMyCommuDomain"
          @click-show-comment-form="clickToMention"
          :commentFormVisible="commentFormVisible"
        />
      </div>
    </div>
    <div
      v-if="canPostComment"
      class="comment-form"
      :class="{ visible: commentFormVisible }"
    >
      <TopicForm
        v-bind="commentFormProps"
        :isMyCommuDomain="isMyCommuDomain"
        :mentionName="mentionName"
        :mentionableList="mentionableList"
      />
    </div>
  </div>
</template>

<script>
  import TopicHeader from '../TopicHeader.vue'
  import ReactionTopic from '../ReactionTopic.vue'
  import TopicComment from '../TopicComment.vue'
  import TopicForm from '../TopicForm.vue'
  import axios from 'axios'

  export default {
    components: { TopicHeader, ReactionTopic, TopicComment, TopicForm },
    props: {
      writer: {
        type: Object,
        required: true
      },
      title: {
        type: String,
        required: true
      },
      body: {
        type: String,
        required: true
      },
      images: {
        type: Array,
        default: () => []
      },
      createdAt: {
        type: String,
        required: true
      },
      comments: {
        type: Array,
        default: () => []
      },
      commentsCount: {
        type: Number,
        required: true
      },
      commentFormProps: {
        type: Object,
        required: true
      },
      path: {
        type: String,
        default: null
      },
      canPostComment: {
        type: Boolean,
        required: true
      },
      likes: {
        type: Array,
        default: () => []
      },
      likesCount: {
        type: Number,
        required: true,
        default: 0
      },
      reactionUrl: {
        type: String,
        required: true
      },
      liked: {
        type: Boolean,
        required: true,
        default: false
      },
      label: {
        type: Object,
        default: null
      },
      useNickname: {
        type: Boolean,
        required: true
      },
      hideComment: {
        type: Boolean,
        default: false
      },
      isMyCommuDomain: {
        type: Boolean,
        default: false
      },
      canUpdateTopic: {
        type: Boolean,
        default: false
      },
      mentionableList: {
        type: Array,
        default: () => []
      }
    },
    data () {
      return {
        commentFormVisible: false,
        displayAllComments: false,
        displayFullPost: true,
        mentionName: '',
        viewMode: true,
      }
    },
    computed: {
      topicHeaderProps () {
        return {
          ...this.writer,
          createdAt: this.createdAt,
          label: this.label,
          title: this.title,
          useNickname: this.useNickname,
          isMyCommuDomain: this.isMyCommuDomain,
          viewMode: this.viewMode,
        }
      },
      latestCommentTopicHeaderProps () {
        const { writer, createdAt } = this.latestComment
        return { ...writer, createdAt }
      },
      hasImages () {
        return this.images.length > 0
      },
      mainImage () {
        return this.images[0]
      },
      subImages () {
        return this.images.slice(1)
      },
      hasComments () {
        return this.commentsCount > 0
      },
      latestComment () {
        return this.comments[this.commentsCount - 1]
      },
    },
    methods: {
      toggleCommentForm () {
        this.commentFormVisible = !this.commentFormVisible
      },
      clickToMention (value) {
        this.commentFormVisible = value.commentFormVisible
        this.mentionName = "@" + value.writerName
      },
      toggleReaction () {
        if (!this.isLiked){
          axios.post(`${this.reactionUrl}`).then((respone) => {
            this.numberOfLikes ++;
            this.isLiked = true;
            this.listUserLike.unshift(respone.data);
          })
        }
      },
      toggleViewEdit () {
        this.viewMode = !this.viewMode
      },
    },
  }
</script>

<style scoped lang="sass">
@import '~stylesheets/resources'

.topic-comments
  padding: 30px 2rem 0px 2rem
  font-size: 12px
  background-color: #F8F7F2
  .comment-title
    font-size: 17px
  .comment-count
    display: flex
    padding-bottom: 10px
    .green
      padding: 6px 3px 0px 5px
      color: #2DBA87
    .counting
      padding-top: 4px

.topic-detail
  padding: 2rem 2rem 0px 2rem
  .main-content
    display: flex
    margin-top: 12px
    .main-image, .sub-image
      border-radius: 6px
      background-repeat: no-repeat
      background-position: center
      background-size: cover
    .main-image
      position: relative
      width: 178px
      min-width: 178px
      height: 178px
      margin-right: 16px
      +app-mobile
        width: 80px
        min-width: 80px
        height: 87px
      &.smaller-size
        width: 82px
        min-width: 82px
        height: 82px
        +app-mobile
          width: 55px
          min-width: 55px
          height: 55px
    .sub-image-container
      position: absolute
      left: 0
      bottom: 0
      display: flex
    .sub-image
      width: 30px
      height: 30px
      margin: 5px 5px 5px 0
      &:first-child
        margin-left: 5px
      +app-mobile
        width: 10px
        height: 10px
        margin: 2.5px 2.5px 2.5px 0
        &:first-child
          margin-left: 2.5px
    .body
      word-break: break-all
      font-size: 15px
      +app-mobile
        .read-more
          margin-top: 0px

.actions
  width: 100%
  +app-mobile
    width: 100%
  display: flex
  justify-content: space-between
  .toggle-comment-form
    &.hide
      visibility: hidden
  span
    cursor: pointer
.latest-comment
  margin-top: 24px
  .topic-comment
    padding: 12px 0
.comments
  margin-top: 24px
  .topic-comment
    padding: 12px 0
    border-top: 1px solid #eee
.comment-form
  display: none
  &.visible
    margin-top: 12px
    display: block !important
.read-more
  text-align: right
  span
    cursor: pointer
.like-and-comment
  margin-top: 20px
  +app-mobile
    font-size: 12px
  .like-and-popup
    width: 26%
    +app-mobile
      width: 25%
.action-panel
  padding: 15px
  border-width: 1px 0px 1px 0px
  border-style: solid
  border-color: #CCCCCC
.white-color
  color: white
.orange-color
  color: #F78F54

</style>
