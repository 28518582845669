<template>
  <div>
    <MapBasedSelector
      :radius="item.radius.value"
      :latitude="item.latitude.value"
      :longitude="item.longitude.value"
      :location="item.location.value"
      @change="onLocationChange"
    >
      <div v-if="neighborEventsData.length > 0">
        <table class="table is-fullwidth">
          <caption class="has-text-left">
            交流イベントの概要を指定場所からの距離の近い順に表示しています。
          </caption>
          <thead>
            <tr>
              <th>
                <BCheckbox
                  native-value="1"
                  @input="$_onToggleAllEvents"
                />
              </th>
              <th />
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="event in neighborEventsData"
              :key="event.id"
            >
              <td>
                <BCheckbox
                  v-model="event.checked"
                  :native-value="event.id"
                  name="mailing_list[event_ids][]"
                />
              </td>
              <td v-text="$_eventDescription(event)" />
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else>
        近隣で開催される交流イベントは見つかりませんでした。
      </div>
    </MapBasedSelector>
    <input
      :name="nestedInputProps(item.location).name"
      :value="location"
      type="hidden"
    >
    <input
      :name="nestedInputProps(item.latitude).name"
      :value="lat"
      type="hidden"
    >
    <input
      :name="nestedInputProps(item.longitude).name"
      :value="lng"
      type="hidden"
    >
    <input
      :name="nestedInputProps(item.radius).name"
      :value="radius"
      type="hidden"
    >
  </div>
</template>

<script>
import axios from 'axios'
import HasOneFormNestable from 'mixins/HasOneFormNestable'
import MapBasedSelector from 'components/MapBasedSelector'

export default {
  components: { MapBasedSelector },
  mixins: [HasOneFormNestable],
  props: {
    neighborEvents: {
      type: Array,
      default: () => []
    }
  },
  data () {
    const item = this.formInputPropItem
    return {
      item,
      radius: item.radius.value,
      lat: item.latitude.value,
      lng: item.longitude.value,
      location: item.location.value,
      neighborEventsData: this.neighborEvents
    }
  },
  methods: {
    onLocationChange (ev) {
      const {
        radius,
        latitude,
        longitude,
        location
      } = ev
      this.radius = radius
      this.lat = latitude
      this.lng = longitude
      this.location = location
      this.$_reloadNeighborEvents()
    },
    async $_reloadNeighborEvents () {
      const params = { latitude: this.lat, longitude: this.lng, radius: this.radius }
      const { data: { events } } = await axios.get('/neighbor_events.json', { params })
      this.neighborEventsData = events
    },
    $_onToggleAllEvents (checked) {
      this.neighborEventsData.forEach((ev) => {
        ev.checked = checked
      })
    },
    $_eventDescription (event) {
      const { scheduled_date: scheduledDate, title, tickets } = event
      return `${scheduledDate} ${title}(${tickets})`
    }
  }
}
</script>

<style scoped lang="sass">
</style>
