import { fetchGroups, getAreaPartners, createComment, fetchTopic, likeTopic } from '../../../apis/groups'
import mycommu from '../mycommu'
import session from '../session'
import axios from '../../../utils/axios'

export default {
  namespaced: true,
  state: {
    groups: [],
    leadPartners: [],
    generalPartners: [],
    concierges: [],
    groupMembers: [],
    groupAdmins: [],
    join_groups: [],
    topic: {},
    message: '',
    localUsers: [],
    posts: [],
    groupCurrentTab: ''
  },
  getters: {
    message: (state) => {
      return state.message
    }
  },
  actions: {
    async fetchGroups ({
      dispatch,
      commit,
      rootGetters
    }, {
      params,
      page = 1
    }) {
      let filters = {
        q: params,
        page: page
      }
      await fetchGroups(session.state.communityId, filters).then((res) => {
        commit('groups', res.data.data)
        dispatch('mycommu/setLoading', false, { root: true })
      })
    },
    async getAreaPartners ({
      dispatch,
      commit,
      rootGetters
    }, args) {
      dispatch('mycommu/setLoading', true, { root: true })
      await getAreaPartners(session.state.communityId, args).then((res) => {
        commit('leadPartners', res.data.data.lead_partners)
        commit('generalPartners', res.data.data.general_partners)
        commit('concierges', res.data.data.concierges)
        commit('groupMembers', res.data.data.group_members)
        commit('groupAdmins', res.data.data.group_admins)
        dispatch('mycommu/setLoading', false, { root: true })
      })
    },
    async createComment ({
      dispatch,
      commit,
      rootGetters
    }, args) {
      await createComment(mycommu.state.currentCommunity.community_id, args)
    },
    async fetchTopic ({
      dispatch,
      commit,
      rootGetters
    }, args) {
      await fetchTopic(session.state.communityId, args).then((res) => {
        commit('topic', res.data)
      })
    },
    async likeTopic ({
      dispatch,
      commit,
      rootGetters
    }, args) {
      await likeTopic(mycommu.state.currentCommunity.community_id, args)
    },
    setMessage ({ commit }, newValue) {
      commit('SET_MESSAGE', newValue)
    },
    setLocalUser ({ commit }, args) {
      commit('SET_LOCAL_USER', args)
    },
    changeGroupCurrentTab ({ commit }, args) {
      commit('setGroupCurrentTab', args)
    },
    async fetchPosts ({ commit }, filter = 'all') {
      await axios.get(`/communities/${session.state.communityId}/community_groups/${session.state.communityGroupId}/fetch_posts?filter=${filter}`).then((res) => {
        commit('setPosts', res.data.post)
      })
    }
  },

  mutations: {
    groups (state, data) {
      state.groups = data.community_groups
      state.join_groups = data.join_community_groups
    },
    leadPartners (state, data) {
      state.leadPartners = data
    },
    generalPartners (state, data) {
      state.generalPartners = data
    },
    concierges (state, data) {
      state.concierges = data
    },
    groupMembers (state, data) {
      state.groupMembers = data
    },
    groupAdmins (state, data) {
      state.groupAdmins = data
    },
    topic (state, data) {
      state.topic = data.topic
    },
    SET_MESSAGE (state, data) {
      state.message = data
    },
    SET_LOCAL_USER (state, data) {
      data.forEach(element => state.localUsers.push(element))
    },
    setGroupCurrentTab (state, data) {
      console.log('abc', data)
      state.groupCurrentTab = data
    },
    setPosts (state, data) {
      state.posts = data
    }
  }
}
