<template>
  <div class="mycommu-news">
    <div v-if="!isShowSchedule">
      <div v-if="!isShowRequest">
        <div
            v-if="!isShowingSearchResult"
            class="filter-section">
          <TopicTags v-if="isTopics" />
          <EventTags v-if="isEvents" />
          <div class="btn-create-event" v-if="isEvents">
            <MyCommuBaseButton
                class="event-btn"
                :icon="plusIcon"
                text-content="交流イベントを開催する"
                :is-white-button="true"
                @clickButton="redirectEventNew()"
            />
            <b-modal
                :active.sync="modal"
                has-modal-card
            >
              <div class="modal-card">
                <div class="modal-card-body">
                  <p>交流イベント作成できるのは</p>
                  <p>シェア・コンシェルジュの方のみ</p>
                  <p>になります。</p>
                  <MyCommuBaseButton
                      class="button-supporter"
                      text-content="シェア・コンシェルジュになる"
                      @clickButton="goToAsmamaEventMeeting()"
                  />
                  <p>シェア・コンシェルジュ以外の方は</p>
                  <p>こちらから交流イベント作成の</p>
                  <p>リクエストを送る事ができます。</p>
                  <MyCommuBaseButton
                      class="button-create"
                      text-content="リクエストする"
                      @clickButton="sendRequest()"
                  />
                  <p
                      class="cancel"
                      @click="modal = false"
                  >
                    閉じる
                  </p>
                </div>
              </div>
            </b-modal>
          </div>
        </div>

        <img :src="community.image1.url" alt="" loading="lazy"
             class="community-banner"
             v-if="typeIsHome && !isShowingSearchResult"
        >
        <MyCommuLoading v-if="loading"/>
        <div
          v-if="!noRecord && !loading"
          ref="scroll-container"
          v-touch:end="endHandler"
          v-touch:start="startHandler"
          class="scroll-container"
        >

          <div v-for="share in recents" :key="share.id">
            <MyCommuCardShare v-if="share.sharingType == 'borrowing'" :path="share.path" :share="share" />
            <MyCommuCardShare v-if="share.sharingType == 'entry'" :path="share.path" :share="share" />
            <MyCommuCardShare v-if="share.sharingType == 'lending'" :path="share.path" :share="share" />
            <MyCommuCardShare v-if="share.sharingType == 'giving'" :path="share.path" :share="share" />
            <MyCommuCardShare v-if="share.sharingType == 'living'" :path="share.path" :share="share" />
            <MyCommuCardShare v-if="share.sharingType == 'taking'" :path="share.path" :share="share"/>
            <MyCommuCardNews v-if="share.sharingType == 'news'" :path="share.path" :share="share" />
            <MyCommuCardEvent v-if="share.sharingType == 'events'" :path="share.organizationPath" :event="share" />
            <Topic v-if="share.sharingType == 'topic'" :path="share.communityPath" :topic="share" />
            <NoticeJoinGroup v-if="share.sharingType === 'user'" :user="share" />
            <NoticeSharingPost v-if="share.type === 'borrowing' || share.type === 'taking' || share.type === 'lending' || share.type === 'giving' || share.type === 'living'" :notice="share"/>
            <MyCommuCardTranferChildCare v-if="share.sharingType == 'childcare'" />
          </div>
          <div
            v-if="loadMore"
            class="loadmores"
          >
            <img src="../images/for_community/spinner.svg">
          </div>
        </div>

        <div v-if="noRecord"
             v-touch:end="endHandler"
             v-touch:start="startHandler"
             class="scroll-container"
             ref="scroll-container"
        >
          <div v-if="noRecord">
            <div class="no-records">
              <div class="no-records-icons">
            <span>
              <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M51 51L64 64" stroke="#CCCCCC" stroke-width="6" stroke-miterlimit="10" stroke-linecap="round" />
                <path
                    d="M32.5 57C46.031 57 57 46.031 57 32.5C57 18.969 46.031 8 32.5 8C18.969 8 8 18.969 8 32.5C8 46.031 18.969 57 32.5 57Z"
                    stroke="#CCCCCC" stroke-width="6" stroke-linejoin="round" />
                <path d="M39 38L27 26" stroke="#CCCCCC" stroke-width="6" stroke-miterlimit="10" stroke-linecap="round" />
                <path d="M39 26L27 38" stroke="#CCCCCC" stroke-width="6" stroke-miterlimit="10" stroke-linecap="round" />
              </svg>
            </span>
              </div>
              <div class="no-records-text">
            <span>
              検索条件に一致するものは
            </span>
            <span>
              見つかりません。
            </span>
              </div>
              <div class="no-records-button-backs">
                <a class="btn" href="/home">
                  ホーム画面に戻る
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Request v-if="isShowRequest" @clickBack="clickBack"/>
    </div>
    <MyCommuSchedule v-if="isShowSchedule"/>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Topic from '../components/my-commu/card/Topic'
import TopicTags from '../components/my-commu/topic/Tag'
import EventTags from '../components/my-commu/event/Tag'
import queryString from 'query-string'
import Request from './event/Request'

export default {
  components: {
    Request,
    Topic,
    TopicTags,
    EventTags
  },
  props: {
    bander: {
      type: Boolean,
      default: true
    },
    map: {
      type: Boolean,
      default: true
    },
    currentTime: {
      type: String,
      require: true,
      default: null
    }
  },
  data () {
    return {
      typeIsHome: false,
      isShowRequest: false,
      modal: false,
      startPosition: 0,
      endPosition: 0,
      loadMore: false,
      plusIcon: "<svg width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n" +
          "<g clip-path=\"url(#clip0_10743_25754)\">\n" +
          "<path d=\"M12 2.70996V21.29\" stroke=\"#2DBA87\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n" +
          "<path d=\"M21.2909 12H2.71094\" stroke=\"#2DBA87\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n" +
          "</g>\n" +
          "<defs>\n" +
          "<clipPath id=\"clip0_10743_25754\">\n" +
          "<rect width=\"24\" height=\"24\" fill=\"white\"/>\n" +
          "</clipPath>\n" +
          "</defs>\n" +
          "</svg>\n"
    }
  },
  computed: {
    ...mapState('home', ['recents', 'page']),
    ...mapState('mycommu', ['loading', 'isShowingSearchResult', 'isShowIconHeader', 'isShowChangeCommu']),
    ...mapState('session', ['communityId', 'community', 'currentUser']),
    isShowSchedule () {
      const params = queryString.parse(location.search)
      return params && params.type === 'schedule'
    },
    isTopics () {
      const params = queryString.parse(location.search)
      return params && params['type'] === 'topic'
    },
    noRecord () {
      return this.recents.length === 0 && !this.loading;
    },
    isEvents () {
      const params = queryString.parse(location.search)
      return params && params['type'] === 'event'
    }
  },
  created () {
    this.fetchRecents({ currentCommunity: this.communityId, currentTime: this.currentTime })
    this.checkType()
    this.setRoutes()
    this.toggleShowIconHeader(true)
    this.toggleShowChangeCommu(true)
  },
  mounted () {
    if (this.isEvents) {
      console.log('events')
      this.toggleIsForcedShowingFooter(true)
    } else {
      console.log('else')
      this.toggleIsForcedShowingFooter(false)
    }
  },
  methods: {
    ...mapActions('home', [
      'fetchRecents',
      'setRoutes',
      'swipPage',
      'fetchRecentsLoadMores'
    ]),
    ...mapActions('mycommu', ['toggleShowIconHeader', 'toggleShowChangeCommu', 'toggleIsForcedShowingFooter']),

    gotoPage: (path) => {
      window.location.href = path
    },
    redirectEventNew () {
      if (this.currentUser.supporter) {
        window.location.href = `/communities/${this.communityId}/managements/events/new`
      } else {
        this.modal = true
      }
    },
    sendRequest () {
      this.isShowRequest = true
    },
    checkType () {
      const params = queryString.parse(location.search)
      if (params['type'] === undefined) {
        this.typeIsHome = true
      } else {
        this.typeIsHome = false
      }
    },
    swipHorizontal (direction) {
      if (direction === 'left' || direction === 'right') {
        this.swipPage(direction)
      }
    },
    startHandler (node) {
      const [{ pageY }] = node.touches
      this.startPosition = pageY
    },
    endHandler (node) {
      const [{ pageY }] = node.changedTouches
      this.endPosition = pageY
      this.handleLoadMores()
    },
    async handleLoadMores () {
      const dist = this.endPosition - this.startPosition
      const el = this.$refs['scroll-container']
      const abs = Math.abs(dist) + this.startPosition
      if (dist < 0 && abs > el.scrollHeight) {
        this.loadMore = true
        await this.fetchRecentsLoadMores({ currentCommunity: this.communityId, params: null, page: this.page, currentTime: this.currentTime });
        this.loadMore = false
      }
    },
    clickBack () {
      this.isShowRequest = false
      this.modal = false
    },
    goToAsmamaEventMeeting () {
      window.location.href = 'https://asmama.jp/event/meeting/'
    }
  }
}
</script>
<style scoped lang="sass">
  .scroll-container
    margin-top: 20px

  .no-records
    display: flex
    justify-items: center
    align-items: center
    flex-direction: column
    margin-top: 10vh

    .no-records-text
      display: flex
      justify-items: center
      align-items: center
      flex-direction: column
      margin-top: 32px
      span
        font-weight: 300
        font-size: 15px
        color: #273D35

    .no-records-button-backs
      width: 80%
      background: #ffffff
      padding: 16px
      justify-content: center
      align-items: center
      display: flex
      margin-top: 40px
      box-shadow: 0 2px 8px #f2efde
      border-radius: 6px
      height: 48px
      .btn
        color: #2DBA87
        font-weight: 700
        font-size: 15px

    .mt-50
      margin-top: 50px
  .loadmores
    display: flex
    justify-content: center
  .community-banner
    aspect-ratio: 375 / 150
    object-fit: cover
    width: 100%
  .btn-banner
    margin: 24px 16px
    background: #FFFFFF
    box-shadow: 0 2px 8px #f2efde
    border-radius: 6px
    border: unset
    padding: 11px 74px
    height: 48px
  .btn-create-event
    margin: 0 16px 32px
    padding-bottom: 32px
    border-bottom: 1px solid #CCCCCC

  /deep/
    .cancel
      font-weight: bold
      color: #2DBA87
    .modal-background
      background: #273D35
      opacity: 0.9
    .animation-content
      display: contents
      .modal-card
        font-size: 14px
        line-height: 24px
        display: flex
        justify-content: center
        padding: 0 52px
        .modal-card-body
          text-align: center
          border-radius: 20px
          padding: 32px 20px
    .button-supporter
      margin-top: 16px
      margin-bottom: 24px
    .button-create
      margin-top: 18px
      margin-bottom: 28px
    .modal-close
      display: none
      .base-button
        margin-top: 14px
        margin-bottom: 22px
        button
          font-size: 14px !important
          padding: 16px 6px 16px 6px !important

  .mycommu-news
    background-color: #E5E5E5
  .scroll-container
    padding-bottom: 3rem
</style>

<style lang="sass">
.event-btn
  button
    box-shadow: none !important
</style>
