<template>
  <div class="hidden-header"></div>
</template>
<script>
import { mapActions } from 'vuex'
export default {
  created () {
    this.toggerHeader(true)
  },
  methods: {
    ...mapActions('mycommu', [
      'toggerHeader'
    ])
  }
}
</script>
<style scoped lang="sass">
@import '~stylesheets/resources'
.hidden-header
  margin-top: -16px
</style>
