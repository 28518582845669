<template>
  <div>
    <form>
      <BField
        :message="message"
        :type="status"
        class="text-left"
      />
      <BaseTextInput
        v-bind="resetPasswordToken"
      />
      <input type="hidden" name="user[reset_password_token]"/>
      <div class="is-mb-5">
        <BField
          :label="label"
          class="label-password"
        >
          <div class="change-icon" @click="passwordToogle" :class="isShow ? 'hide-password' : 'show-password' "></div>
          <BInput
            v-model="value"
            type="password"
            customClass="input-password dot-password"
            ref="myInput"
          />
        </BField>
      </div>
      <div
        class="is-mb-5"
      >
        <b-button type="is-success" size='is-medium' @click="onSubmit" label="パスワードを変更する" class="is-fullwidth commu-submit-password"/>
      </div>
    </form>
    <BModal
      :active.sync="isComponentModalActive"
      has-modal-card
      :can-cancel="true"
      :on-cancel="redirectLogin"
      :width="200"
    >
      <div class="modal-card" style="width: auto">
        <div class="modal-card-body">
          <img src="../images/for_community/circle-check.png">
          <p class="text-default">
            新しいパスワードが設定されました。
          </p>
        </div>
      </div>
    </BModal>
  </div>
</template>

<script>
import axios from 'axios'
import BaseTextInput from 'components/BaseTextInput'
import * as inputs from './inputs'
import Input from './my-commu/comments/Input'

export default {
  components: { Input, BaseTextInput },
  props: {
    resetPasswordToken: {
      type: Object,
      required: true
    },
    label: {
      type: String,
      required: null
    },
    password: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      isComponentModalActive: false,
      value: this.password,
      errorMessage: [],
      isShow: false
    }
  },
  computed: {
    status () {
      return inputs.status(this.errorMessage)
    },
    message () {
      return this.errorMessage
    }
  },
  methods: {
    redirectLogin () {
      window.location.href = '/users/sign_in'
    },
    async onSubmit () {
      axios.put('/users/password', { user: { reset_password_token: this.resetPasswordToken.value, password: this.value }} )
        .then(() => {
          this.isComponentModalActive = true
        })
        .catch((e) => {
          const { response: { data: { errorMessages } } } = e
          this.errorMessage = errorMessages
          this.isComponentModalActive = false
        })
    },
    passwordToogle () {
      this.isShow = !this.isShow
      var input = this.$refs.myInput.$el.querySelector('input')
      input.type = this.isShow ? 'text' : 'password'
      this.isShow ? input.classList.remove('dot-password') : input.classList.add('dot-password')
    }
  }
}
</script>

<style scoped lang="sass">
  @import '~stylesheets/resources'

  .commu-submit-password
    font-weight: bold
    height: 48px
    border-radius: 6px
  .is-fullwidth
    /deep/ span
      font-size: 15px
  .text-default
    font-size: 14px
    font-weight: 300
  .modal-card
    border-radius: 5%
    .modal-card-body
      padding: 30px
      img
        width: 72px
        margin-bottom: 10px
  .label-password
    position: relative
    display: block!important
    /deep/ > label
      font-size: 14px
      font-weight: 300
  .text-left
    /deep/ > p
      text-align: left
      font-size: 14px
      margin-top: 2em
      font-weight: 300
  /deep/ .input-password
    border-radius: 6px!important
    height: 48px
    font-size: 1.25rem
    caret-color: #2DBA87
    padding-right: 50px
    &:focus
      border: 1px solid #2DBA87
      border-radius: 6px
      box-shadow: 0 0 0 0
  /deep/ .dot-password
    -webkit-text-stroke-width: 0.2em
    letter-spacing: 0.2em
    text-indent: 2px
    +app-mobile
      -webkit-text-stroke-width: unset
      letter-spacing: normal
      text-indent: 0px
  .change-icon
    position: absolute
    right: 17px
    top: 2.4rem
    width: 1.5rem
    height: 1.5rem
    z-index: 5
  .show-password
    background: url("../images/signin/eyeclosed.svg") no-repeat
  .hide-password
    background: url("../images/signin/eyeopened.svg") no-repeat
  /deep/ .modal-close
    display: none
  /deep/
    .modal-background
      background: RGBA(39,61,53,0.9) !important
    button, input
      font-family: "Hiragino Kaku Gothic ProN"
</style>
