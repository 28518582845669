<template>
  <div>
    <div
      v-for="(opt, key) in options"
      :key="key"
    >
      <b-field>
        <b-radio
          v-model="inputValue"
          :native-value="opt[1]"
          :name="name"
          @input="click(inputValue)"
        >
          {{ opt[0] }}
        </b-radio>
      </b-field>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: ''
    },
    value: {
      type: [String, Boolean, Number],
      default: ''
    },
    isOptional: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      default: () => []
    },
  },
  data () {
    return { inputValue: this.value }
  },
  computed: {
    isPlaceholder () {
      return this.inputValue === ''
    }
  },
  methods: {
    click (value) {
      this.$emit('click', value)
    }
  }
}
</script>

<style scoped lang="sass">
/deep/
  label
    cursor: unset
  .field
    .check
      border: 1px solid #7C7C7C !important
      border-color: #7C7C7C !important
      &:before
        width: 16px !important
        height: 16px !important
        background-color: #2DBA87 !important
        margin-left: calc(-16px/2) !important
        margin-bottom: calc(-16px/2) !important
  .b-radio.radio input[type=radio]:checked + .check
    background: #D5F1E7
</style>
