<template>
  <div>
    <HiddenHeader/>
    <MyCommuHiddenButtonFooter :is-my-commu-domain="true"/>
    <MyCommuHiddenFooterMenu :is-my-commu-domain="true"/>
    <Back
      :title="checkHeader"
      :showCommu="false"
    />
    <div class="tab-commu">
      <div class="community-tags">
        <ul>
          <li
            :class="{ 'active': activeTagClass('all')}"
            @click="activeTag('all')"
          >
            全て表示
          </li>
          <li
            v-for="commu in myfriend"
            :key="commu.organization_id"
            :class="{ 'active': activeTagClass(commu.organization_id)}"
            @click="activeTag(commu.organization_id)"
          >
            {{ commu.organization_name }}
          </li>
        </ul>
      </div>
    </div>
    <div>
      <div
        v-for="(commu_friend, index) in myFriendAllCommu"
        :key="index"
      >
        <p
          v-text="commu_friend.organization_name_count"
          class="name-commu"
          :class="index == 0 ? 'mt-24' : 'mt-40'"
        />
        <MyCommuFriendship
          v-for="(friend, key) in commu_friend.profiles"
          v-if="friend.profile"
          :key="key"
          :friend="friend"
          :profile="friend.profile"
          :isFriend="friend.is_friend"
          :lastItem="key + 1 === commu_friend.profiles.length"
          :has-followed="friend.has_followed"
          :model-follow="friend.model_follow"
          :is-followed="friend.is_followed"
          :friend-requested="friend.friend_requested"
          :is-block="friend.is_block"
        />
      </div>
    </div>
  </div>
</template>

<script>

import Back from '../../components/my-commu/Back'
import axios from 'axios'
import { mapState } from 'vuex'

export default {
  components: {
    Back
  },
  data () {
    return {
      activeTagKey: 'all',
      myFriendAllCommu: this.myfriend,
    }
  },
  props: {
    myfriend: {
      type: Array,
      require: true
    },
  },
  computed: {
    ...mapState('session', ['communityId']),
    checkHeader () {
      console.log(window.location)
      if (window.location.pathname === `/communities/${this.communityId}/follows/followers`) {
        return 'フォロワー一覧'
      } else if (window.location.pathname === `/communities/${this.communityId}/follows/following`) {
        return 'フォロー中一覧'
      } else {
        return '友達一覧'
      }
    }
  },
  methods: {
    activeTagClass (key) {
      return (this.activeTagKey === key)
    },
    async activeTag (key) {
      this.activeTagKey = key
      if (key === 'all') {
        this.myFriendAllCommu = this.myfriend
      } else {
        await axios({
          method: 'get',
          url: `/communities/${this.communityId}/myfriend/friend_commu?organization_id=${key}`
        }).then((res) => {
          this.myFriendAllCommu = res.data
        })
      }
    }
  }
}
</script>

<style lang="sass" scoped>
  .name-commu
    font-size: 14px
    font-weight: bold
    margin-bottom: 16px
    margin-left: 16px
  .mt-24
    margin-top: 24px
  .mt-40
    margin-top: 40px
  .tab-commu
    ::-webkit-scrollbar
      display: none

    .community-tags
      overflow-x: auto
      white-space: nowrap
      .message
        padding: 16px 0 8px 16px
        font-size: 15px
        background-color: #f8f7f2
      ul
        padding: 16px
        padding-bottom: 0
        display: flex
        li
          border: 1px solid #2DBA87
          border-radius: 90px
          padding: 3px 12px
          background: #FFFFFF
          color: #2DBA87
          font-size: 14px
          font-weight: 400
          text-align: center
          margin-right: 8px
          &.active
            background: #D5F1E7
          &:last-child
            margin-right: unset
</style>
