<template>
  <div>
    <div class="host-message">
      <div class="host-content-card">
        <div
          v-if="hasContent"
          class="host-content"
          v-html="chatMessage.content"
        />
        <div class="host-image" v-if="hasImage">
          <img :src="imageUrl" alt="">
        </div>
        <div class="time-reaction">
          <div class="time">{{ chatMessage.postAt }}</div>
        </div>
      </div>
      <div class="host-avatar avatar">
        <img :src="chatMessage.writer.communityAvatarPath" alt="">
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    chatMessage: {
      type: Object,
      required: true
    }
  },
  data () {
    return { isTrue: true }
  },
  computed: {
    hasImage () {
      return this.chatMessage.imageUrl || this.chatMessage.imageThumbUrl
    },
    imageUrl () {
      if (this.chatMessage.imageThumbUrl) {
        return this.chatMessage.imageThumbUrl
      } else {
        return this.chatMessage.imageUrl
      }
    },
    hasContent () {
      return this.chatMessage.content !== ''
    }
  }
}

</script>
<style lang="sass">
.host-message
  padding: 0 16px
  display: flex
  margin-top: 16px
  flex-wrap: wrap
  justify-content: flex-end
  .avatar
    img
      width: 40px
      height: 40px
      border-radius: 50%
      object-fit: cover
      flex-shrink: 0
  .host-image
    margin-right: 8px
    img
      width: 248px
  .host-content
    word-break: break-all
    margin-right: 8px
    padding: 16px
    max-width: 199px
    min-width: 50px
    background: #2DBA87
    border-radius: 16px 0px 16px 16px
    font-weight: 300
    font-size: 14px
    line-height: 24px
    color: #FFFFFF
  .time-reaction
    .time
      text-align: right
      margin-right: 8px
      margin-top: 8px
      font-weight: 400
      font-size: 12px
      line-height: 12px
      color: #7C7C7C
</style>
