<template>
  <div v-if="isMyCommuDomain" class="is-my-commu">
    <div class="main-content">
      <slot />
    </div>
  </div>
  <div v-else>
    <div class="eyecatch-image" />
    <div class="main-content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isMyCommuDomain: {
      type: Boolean,
      default: false
    }
  },
}
</script>

<style scoped lang="sass">
  @import '~stylesheets/resources'
  .font-bold
    font-weight: bold
  .height-48
    height: 48px
  .commu-submit-button
    height: 48px
    font-family: 'Hiragino Kaku Gothic ProN'
  .top
    .eyecatch-image
      background-image: url('../images/top/eyecatch.jpg')

  .signup
    .eyecatch-image
      background-image: url('../images/signup/eyecatch.jpg')

  .signin
    .eyecatch-image
      background-image: url('../images/signin/eyecatch.jpg')

  .for-community
    .eyecatch-image
      background-image: url('../images/for_community/eyecatch.jpg')

  .forgot-password
    .eyecatch-image
      background-image: url('../images/signin/eyecatch.jpg')

  .resend-confirmation
    .eyecatch-image
      background-image: url('../images/signin/eyecatch.jpg')

  .text-default
    font-weight: 300
    color: #273D35
    text-align: initial
  .main-content
    padding: 35px 32px 45px
    text-align: center
  .omniauth-button
    width: 300px
    +app-mobile
      width: 100%
  .text-left
    display: flex
    justify-content: start
  .size-15
    font-size: 15px
  .size-14
    font-size: 14px
  .top
    text-align: left
  .top__title
    text-align: center
    font-size: 25px
    font-weight: bold
    line-height: 30px
    color: #333
    margin-top: 10px
    margin-bottom: 21px
    +app-mobile
      span
        display: block
  .top__content
    font-size: 16px
    line-height: 24px
    color: #333
    p
      margin-top: 50px
      margin-bottom: 60px
      text-align: center
  .forgot-password
    font-weight: 700
    font-size: 14px
  .mb-40px
    margin-bottom: 40px
  .my-commu-submit-btn
    font-family: "Hiragino Kaku Gothic ProN"
    height: 3rem
    border-radius: 6px
    font-size: 15px !important
    line-height: 15px
    font-weight: 700
  /deep/ .switch
    cursor: default
</style>
