import { fetchRecents, fetchTopic, fetchEvent } from '../../../apis/co'

export default {
  namespaced: true,
  state: {
    topics: {
      all: true,
      comments: false,
      like: false
    },
    events: {
      all: true,
      real: false,
      web: false,
      participated: false,
      distance: false,
      finished: false
    },
    supporters: {
      all: true,
      tutaeru: false,
      tunagu: false,
      azukaru: false
    }
  },
  getters: {
    getTopics(state) {
      if(state.topics.comments)
        return 'comments'
      if(state.topics.like)
        return 'like'
      return 'all'
    },
    getEvents(state) {
      if(state.events.real)
        return 'real'
      if(state.events.web)
        return 'web'
      if(state.events.participated)
        return 'participated'
      if(state.events.finished)
        return 'finished'
      if(state.events.distance)
        return 'distance'
      return 'all'
    },
    getSupporters(state) {
      if(state.supporters.tutaeru)
        return 'tutaeru'
      if(state.supporters.tunagu)
        return 'tunagu'
      if(state.supporters.azukaru)
        return 'azukaru'
      return 'all'
    }
  },
  actions: {
    setTopic({ commit }, value) {
      switch (value) {
        case 'all':
          commit('setAll', value)
          break;
        case 'comments':
          commit('setComments', value)
          break;
        case 'like':
          commit('setLike', value)
          break;
      }
    },
    setEvent ({ commit }, value) {
      switch (value) {
        case 'all':
          commit('setAllEvent', value)
          break;
        case 'real':
          commit('setReal', value)
          break;
        case 'web':
          commit('setWeb', value)
          break;
        case 'participated':
          commit('setParticipated', value)
          break;
        case 'finished':
          commit('setFinished', value)
          break;
        case 'distance':
          commit('setDistance', value)
          break;
      }
    },
    setSupporter ({ commit }, value) {
      switch (value) {
        case 'all':
          commit('setAllSupporter', value)
          break;
        case 'tutaeru':
          commit('setTutaeru', value)
          break;
        case 'tunagu':
          commit('setTunagu', value)
          break;
        case 'azukaru':
          commit('setAzukaru', value)
          break;
      }
    }
  },

  mutations: {
    setAll (state, data) {
      state.topics.all = true
      state.topics.comments = false
      state.topics.like = false
    },
    setComments (state, data) {
      state.topics.all = false
      state.topics.comments = true
      state.topics.like = false
    },
    setLike (state, data) {
      state.topics.all = false
      state.topics.comments = false
      state.topics.like = true
    },
    setAllEvent (state, data) {
      state.events.all = true
      state.events.real = false
      state.events.web = false
      state.events.participated = false
      state.events.finished = false
      state.events.distance = false
    },
    setReal (state, data) {
      state.events.all = false
      state.events.real = true
      state.events.web = false
      state.events.participated = false
      state.events.finished = false
      state.events.distance = false
    },
    setWeb (state, data) {
      state.events.all = false
      state.events.real = false
      state.events.web = true
      state.events.participated = false
      state.events.finished = false
      state.events.distance = false
    },
    setParticipated (state, data) {
      state.events.all = false
      state.events.real = false
      state.events.web = false
      state.events.participated = true
      state.events.finished = false
      state.events.distance = false
    },
    setFinished (state, data) {
      state.events.all = false
      state.events.real = false
      state.events.web = false
      state.events.participated = false
      state.events.finished = true
      state.events.distance = false
    },
    setDistance (state, data) {
      state.events.all = false
      state.events.real = false
      state.events.web = false
      state.events.participated = false
      state.events.finished = false
      state.events.distance = true
    },
    setAllSupporter(state, data) {
      state.supporters.all = true
      state.supporters.tutaeru = false
      state.supporters.tunagu = false
      state.supporters.azukaru = false
    },
    setTutaeru(state, data) {
      state.supporters.all = false
      state.supporters.tutaeru = true
      state.supporters.tunagu = false
      state.supporters.azukaru = false
    },
    setTunagu(state, data) {
      state.supporters.all = false
      state.supporters.tutaeru = false
      state.supporters.tunagu = true
      state.supporters.azukaru = false
    },
    setAzukaru(state, data) {
      state.supporters.all = false
      state.supporters.tutaeru = false
      state.supporters.tunagu = false
      state.supporters.azukaru = true
    }
  }
}
