<template>
  <div class="password-modal-ok">
    <slot :on-click="onClick" />
    <BModal
      :active.sync="isModalOpen"
      has-modal-card
    >
      <div class="modal-card">
        <header
          class="modal-card-head"
          v-text="modalTitle"
        />
        <div class="modal-card-body">
          <slot name=icon></slot>
        </div>
      </div>
    </BModal>
  </div>
</template>

<script>
export default {
  props: {
    modalTitle: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      isModalOpen: false
    }
  },
  methods: {
    onClick () {
      this.isModalOpen = true
    }
  }
}
</script>

<style scoped lang="sass">
.font-bold
  font-weight: bold
.password-modal-ok
  border-bottom-left-radius: 6px
  border-bottom-right-radius: 6px
  /deep/ .is-susccess
    color: #2DBA87
/deep/ .modal-close
  display: none
</style>
