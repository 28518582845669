<template>
  <a :href="path">
  <div class="my-commu-domain card-news">
    <div class="image-preview">
      <img src="../../../images/samples/unsplash_OtXADkUh3-I.png" />
    </div>
    <div class="card-container">
      <div class="thumnnail-name">
        <div class="thumnnail-type">
          <svg width="48" height="48" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
            <circle cx="24" cy="24" r="24" />
            <path d="M30.39 14.91L19.74 18.61H14.25V26.23H19.74L30.39 30.18" stroke-width="2" stroke-linecap="round"
              stroke-linejoin="round" />
            <path
              d="M30.8196 30.18C32.9901 30.18 34.7496 26.7595 34.7496 22.54C34.7496 18.3206 32.9901 14.9 30.8196 14.9C28.6492 14.9 26.8896 18.3206 26.8896 22.54C26.8896 26.7595 28.6492 30.18 30.8196 30.18Z"
              stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M22.2098 27.67L21.2098 33.49H17.5498L18.5498 26.23" stroke-width="2" stroke-linecap="round"
              stroke-linejoin="round" />
          </svg>
          <span>お知らせ</span>
        </div>
        <div class="thumnnail-time">
          <span>2021.11.10</span>
        </div>
      </div>
      <div class="title">
        <span>未来に伝えたい、安心できる出産のオンライン上映会ダミーテキスト…</span>
      </div>
      <div class="raw-body">
        <span>
          家の掃除機が壊れてしまって、しばらくの間掃除機を借してほしいのですが、お近くのかたでかして…
        </span>
      </div>
      <div class="card-news-footer">
        <ul>
          <li>
            <div class="infor">
              <img src="../../../images/header-logo-mycommu.png" />
              <span class="infor-name">前田 健</span>
            </div>
          </li>
          <li><span class="pin">
              <svg width="16" height="22" viewBox="0 0 16 22" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M13.0004 19.97L8.64038 16.2C8.46145 16.0529 8.237 15.9725 8.00537 15.9725C7.77375 15.9725 7.54936 16.0529 7.37042 16.2L3.00043 19.97C2.85661 20.0785 2.68611 20.1462 2.50702 20.1658C2.32793 20.1855 2.14688 20.1563 1.98297 20.0815C1.81906 20.0068 1.67841 19.8891 1.57587 19.741C1.47332 19.5928 1.41269 19.4198 1.40039 19.2401V2.76001C1.40039 2.49479 1.50576 2.24045 1.6933 2.05292C1.88083 1.86538 2.13517 1.76001 2.40039 1.76001H13.6704C13.9356 1.76001 14.19 1.86538 14.3775 2.05292C14.565 2.24045 14.6704 2.49479 14.6704 2.76001V19.23C14.67 19.4233 14.6135 19.6122 14.5079 19.774C14.4024 19.9359 14.2522 20.0636 14.0755 20.1419C13.8988 20.2202 13.7033 20.2457 13.5125 20.2151C13.3217 20.1846 13.1439 20.0995 13.0004 19.97Z"
                  stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </span></li>
        </ul>
      </div>
    </div>
  </div>
  </a>
</template>

<script>
import { mapState } from 'vuex'

export default {
  components: {},
  props: {
    path: {
     type: String,
     required: true
    }
  },
  data() {
    return {}
  },
  computed: {

  },
  mounted() {

  },
  methods: {

  }
}
</script>
<style scoped lang="sass">
@import '~stylesheets/resources'

.card-news
  position: relative
  width: 92%
  margin: auto
  border-radius: 12px
  background: #FFFFFF
  .image-preview
    width: 100%
    img
      width: 100%
      border-radius: 12px 12px 0px 0px

  .card-container
    margin-left: 32px
    margin-right: 32px
    margin-bottom: 32px

    .thumnnail-name
      display: flex
      align-items: center
      margin-bottom: 15px
      .thumnnail-type
        display: flex
        align-items: center
        span
          margin-left: 8px
          color: #273D35
        svg
          stroke: #2DBA87
          fill: #D5F1E7

      .thumnnail-time
        margin-left: auto
        color: #AAAAAA
    .title
      color: #273D35
      font-size: 16px
    .raw-body
      font-weight: 300
      font-size: 14px
      color: #273D35
      margin-top: 16px
      margin-bottom: 23px
      word-break: break-word
    .card-news-footer
      padding-bottom: 30px
      color: #273D35
      display: flex
      font-weight: 300
      font-size: 12px

      ul
        display: grid
        gap: 30px
        grid-template-columns: auto auto
        grid-auto-rows: 4px
        align-items: center
        width: 100%
        .infor
          display: flex
          text-align: center
          align-items: center

          img
            width: 40px
            height: 40px
            border-radius: 50%
            object-fit: cover
            flex-shrink: 0
            margin-right: 10px
          span
            font-weight: 300
            font-size: 12px
        li
          &:last-child
            justify-content: flex-end
            display: flex
        .status
          background: #F3F3F3

          border: 1px solid #7C7C7C
          justify-content: center
          align-items: center
          padding: 3px 8px

        .price
          color: #273D35
          font-weight: 700
          font-size: 12px
          .price-icon
            display: flex
            align-items: center
            margin-right: 5px
        .pin
          svg
            stroke: #CCCCCC
            fill: none
</style>
