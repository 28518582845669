<template>
  <div class="like-and-popup">
    <div class="like-topic">
      <div class="action-like">
        <a
          :class="displayHeart ? 'action-heart' : ''"
          @click="displayHeart ? '' : likeTopic"
        >
          <img v-if="!displayHeart"
            class="like"
            src="../images/like.png"
            alt="Like"
          >
          <i v-if="displayHeart"
            class="fas fa-heart heart"
          />
        </a>
        <span
          :class="numberOfLikes > 0 ? 'open-popup' : ''"
          v-text="displayHeart ? numberOfLikes : `(${numberOfLikes}件)`"
          @click.prevent="openListLike"
        />
      </div>
      <!-- 名前非表示でリリースし、後から名前表示を戻す -->
      <!-- <p class="list-name">
        <template v-for="(like, index) in listUserLike">
          <a
            class="name-user"
            v-if="index < displayLikedNumber"
            :href="`/users/${like.user_id}`"
            v-text="`${like.fullname}`"
          />
          <span
            v-if="index < displayLikedNumber"
            :class="(index + 1 == displayLikedNumber || index + 1 == numberOfLikes) && numberOfLikes <= displayLikedNumber ? 'no-content' : 'content'"
          >さん</span>
        </template>
        <span
          v-if="numberOfLikes > displayLikedNumber"
          v-text="`他${numberOfLikes - displayLikedNumber}人`"
          @click.prevent="openListLike"
          class="open-popup"
        />
        <span v-if="numberOfLikes > 0" >がいいね！しました。</span>
      </p> -->
    </div>
    <Portal
      v-if="numberOfLikes > 0"
      to="modal"
    >
      <BModal
        :active.sync="isModalListLike"
        :width="515"
        class="popup-modal-content"
      >
        <div
          class="modal-like"
          style="width: auto"
        >
          <header class="modal-like-head">
            <p class="modal-like-title">いいね！</p>
          </header>
          <section class="modal-like-body">
            <ul>
              <li
                v-for="(like, idx) in listUserLike"
                :key="idx"
              >
                <a 
                  :href="`/users/${like.user_id}`"
                  class="icon-user"
                >
                  <FaceIcon
                    :src="like.avatar_url"
                  />
                  <strong v-text="like.fullname" />
                </a>
              </li>
            </ul>
          </section>
        </div>
      </BModal>
    </Portal>   
  </div>
</template>

<script>
import axios from 'axios'

export default {
  props: {
    likes: {
      type: Array,
      default: () => []
    },
    likesCount: {
      type: Number,
      default: 0
    },
    reactionUrl: {
      type: String,
      required: true
    },
    liked: {
      type: Boolean,
      default: false
    },
    displayLikedNumber: {
      type: Number,
      default: 2
    },
    displayHeart: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isModalListLike: false,
      numberOfLikes: this.likesCount,
      isLiked: this.liked,
      listUserLike: this.likes
    }
  },
  methods: {
    openListLike () {
      if (this.numberOfLikes > 0){
        this.isModalListLike = true
      }
    },
    likeTopic () {
      if (!this.isLiked){
        axios.post(`${this.reactionUrl}`).then((respone) => {
          this.numberOfLikes ++;
          this.isLiked = true;
          this.listUserLike.unshift(respone.data);
        })
      }
    }
  }
}
</script>

<style scoped lang="sass">
@import '~stylesheets/resources'

.heart
  color: #F78F54
.main-content
  .like-topic
    .action-like
      margin-left: 0px
.like-topic
  .action-heart
    margin-bottom: 3px
  .action-like
    display: flex
    align-items: center
    margin-left: 1rem
    +app-mobile
      margin-left: unset
    a
      display: flex
      margin-right: 0.4rem
      img
        height: 1.2rem
        width: 1.2rem
    .open-popup
      cursor: pointer
      font-size: unset
  .list-name
    font-size: 1em
    word-spacing: -4px
    .name-user,.open-popup
      font-weight: 700
      word-spacing: 0px
    span 
      font-size: 12px
    span.content::after
      content: "、"
.modal-like
  display: flex
  flex-direction: column
  max-height: calc(100vh - 30vh)
  overflow: hidden
  .modal-like-head
    border-bottom: 1px solid #dbdbdb
    border-top-left-radius: 6px
    border-top-right-radius: 6px
    align-items: center
    background-color: whitesmoke
    display: flex
    flex-shrink: 0
    justify-content: center
    padding: 20px
    position: relative
    .modal-like-title
      font-weight: 900
      font-size: 1.4em
  .modal-like-body
    background-color: white
    flex-grow: 1
    flex-shrink: 1
    overflow: auto
    border-bottom-left-radius: 6px
    border-bottom-right-radius: 6px
    padding: 0
    ul
      li
        a.icon-user
          display: flex
          font-weight: bold
          padding: 5px 30px
          align-items: center
          .face-icon
            .icon
              height: 3.3rem
              width: 3.3rem
              display: flex
          strong
            margin-left: 1em
</style>
