<template>
  <div class="share-container">
    <Tag @filter="filter"/>
    <div v-for="share in sharingFilter" :key="share.id">
      <ShareCard
        class="mt-34"
        :share="share"
        :share-path="share.path"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Tag from './SharePost/Tag.vue'
import ShareCard from './SharePost/ShareCard.vue'
import axios from 'axios'

export default {
  components: {
    Tag, ShareCard
  },
  props: {
    sharing: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      sharingFilter: this.sharing
    }
  },
  computed: {
    ...mapState('session', ['communityId'])
  },
  created () {
    this.toggleShowIconHeader(true)
    this.toggleShowChangeCommu(true)
  },
  mounted () {
  },
  methods: {
    ...mapActions('mycommu', [
      'toggleShowIconHeader',
      'toggleShowChangeCommu'
    ]),

    filter (value) {
      console.log(value)
      this.sharingFilter = []
      axios.get(`/communities/${this.communityId}/share_posts/filter?type=${value}`).then((res) => {
        this.sharingFilter = res.data.data
        console.log(res.data.data)
      })
    }
  }
}
</script>

<style scoped lang="sass">
  .share-container
    background-color: #E5E5E5
    padding: 0 16px
    padding-bottom: 3rem
  .mt-34
    margin-top: 34px
</style>
