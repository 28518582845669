<template>
  <div class="reward">
    <div class="container-reward">
      <div class="icon-gratitude">
        <img src="../images/icons/icon-gratitude.svg">
      </div>
      <div class="reward-content">
        <div v-if="isAuthor" class="content2">謝礼</div>
        <div v-else class="content2">あなたへのお礼の気持ち</div>
        <div class="reward-price">
          <img src="../images/icons/coin.svg" alt="coin">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isAuthor: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="sass">
@import '~stylesheets/resources'
.reward
  padding: 12px
  background: white
  border-right: 1px solid #eee
  border-left: 1px solid #eee
.container-reward
  display: flex
  width: 100%
  background: #D5F1E7
  border-radius: 4px
  height: 42px
.icon-gratitude
  position: relative
  margin-left: 4px
  bottom: 5px
.reward-content
  display: flex
  justify-content: space-between
  align-items: center
  width: 80%
.content2
  margin-left: 8px
  color: #273D35
  font-weight: 400
  font-size: 12px
  line-height: 12px
  font-style: normal
  margin-bottom: 0px
.reward-price
  display: flex
  color: #273D35
  font-weight: 700
  font-size: 12px
</style>
