<template>
  <div class="notice-area">
    <div class="d-inline-table">
      <span class="group-name" @click="goToGroup(notice.groupId)">{{ notice.groupName }}</span>
      <span class="sharing-title">グループで「</span>
      <span class="sharing-title">{{ sharingPostType }}</span>
      <span class="sharing-title">」のシェアリング投稿がありました</span>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    notice: {
      type: Object,
      required: true
    }
  },
  data () {
    return {}
  },
  computed: {
    ...mapState('session', ['communityId']),
    sharingPostType () {
      if (this.notice.type === 'giving') {
        return '譲るよ'
      } else if (this.notice.type === 'borrowing') {
        return '貸して'
      } else if (this.notice.type === 'lending') {
        return '貸すよ'
      } else if (this.notice.type === 'taking') {
        return '譲って'
      } else if (this.notice.type === 'living') {
        return 'くらし'
      }
    }
  },
  methods: {
    goToGroup (groupId) {
      window.location.href = `/communities/${this.communityId}/community_groups/${groupId}`
    }
  }
}

</script>

<style scoped lang="sass">
  .notice-area
    width: 92%
    margin: auto
    display: flex
    justify-content: center
    align-items: center
    padding-bottom: 12px
  .d-inline-table
    display: inline-table
  .group-name
    color: #2DBA87
    font-weight: 600
    font-size: 12px
  .sharing-title
    text-align: center
    color: #273D35
    font-size: 12px
</style>
