import Vue from 'vue'
import Vuex from 'vuex'
import session from 'store/modules/session'
import chat from 'store/modules/chat'
import notification from 'store/modules/notification'
import mycommu from 'store/modules/mycommu'
import home from 'store/modules/home'
import filter from 'store/modules/filter'
import comments from 'store/modules/comments'
import groups from 'store/modules/groups'
import events from 'store/modules/event'
import space from 'store/modules/space'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    session,
    chat,
    notification,
    mycommu,
    home,
    filter,
    comments,
    groups,
    events,
    space
  }
})
