<template>
  <div>
    <div class="chat-body" @click="navigate">
      <div class="dots" v-if="!chat.seen"></div>
      <div class="share-message" v-if="chat.isShareMessage">シェアリング</div>
      <div class="chat-avatar">
        <img :src="chat.partner.communityAvatarPath" alt="">
      </div>
      <div class="chat-content">
        <div class="time">{{ chat.createdAt }}</div>
        <div class="sender-name" :class="{ 'active': !chat.seen }">
          {{ chat.title }}
        </div>
        <div class="message-chat" :class="{ 'active': !chat.seen }">
          {{ chat.shortLastMessageContent }}
        </div>
        <div class="commu-name" v-if="!hasOneCommunity" v-text="commuName"></div>
      </div>
      <div class="card-icon">
        <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.92993 1.8501L10.0699 10.0001L1.92993 18.1501" stroke="#2DBA87" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    chat: {
      chat: Object,
      required: true
    }
  },
  computed: {
    ...mapState('mycommu', ['communities']),

    commuName () {
      var communities = []
      this.chat.sameCommunities.map((community) => {
        if (community && community.name) {
          communities.push(community.name)
        }
      })
      return communities.join(', ')
    },
    hasOneCommunity () {
      return this.communities.length <= 1
    }
  },
  methods: {
    navigate () {
      window.location.href = this.chat.detailUrl
    }
  }
}
</script>

<style lang="sass">
  .chat-body
    display: flex
    position: relative
    padding: 16px
    background: white
    border-top: 1px solid #CCCCCC
    border-bottom: 1px solid #CCCCCC
    align-items: center
    .dots
      width: 10px
      height: 10px
      background: #EE5353
      position: absolute
      border-radius: 50%
      left: 10px
      top: 10px
    .share-message
      position: absolute
      right: 8px
      top: 8px
      font-style: normal
      font-weight: bold
      font-size: 12px
      line-height: 12px
      color: white
      background: #F68F54
      border: 1px solid #F68F54
      padding: 3px 8px
    .chat-avatar
      img
        width: 64px
        height: 64px
        border-radius: 50%
        object-fit: cover
        flex-shrink: 0
        max-width: unset
    .chat-content
      margin-left: 16px
      margin-right: 15px
      .time
        font-style: normal
        font-weight: 300
        font-size: 10px
        line-height: 10px
        color: #7C7C7C
      .sender-name
        font-style: normal
        font-weight: 300
        font-size: 15px
        line-height: 15px
        color: #273D35
        margin-top: 8px
        &.active
          font-weight: bold
      .message-chat
        margin-top: 8px
        font-style: normal
        font-weight: 300
        font-size: 12px
        line-height: 12px
        color: #273D35
        &.active
          font-weight: bold
      .commu-name
        font-style: normal
        font-weight: 300
        font-size: 12px
        line-height: 12px
        display: flex
        align-items: center
        color: #7C7C7C
        margin-top: 8px
    .card-icon
      display: flex
      right: 16px
      position: absolute

</style>
