import axios from 'axios'
import mycommu from './mycommu'

const store = {
  namespaced: true,
  state: {
    events: []
  },
  mutations: {
    setEvents (state, events) {
      state.events = events
    }
  },
  actions: {
    setEvents ({ commit }) {
      axios.get(
        `/communities/${mycommu.state.currentCommunity.community_id}/managements/fetch_event`
      ).then((res) => {
        commit('setEvents', res.data.data)
      })
    }
  }
}
export default store
