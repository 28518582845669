<template>
  <div class="topic">
    <div class="header-topic"
      @click="gotoTopic('0')"
    >
      <div class="label-topic">
        <img :src="isIcon">
        <p class="title-topic">{{ topic.title }}</p>
      </div>
    </div>
    <div class="body-topic"
      @click="gotoTopic('0')"
    >
      <div class="body">
        <p class="time-update">
          {{topic.createdAt + '前更新'}}
        </p>
        <p class="short-body">
          {{topic.body}}
        </p>
        <div class="icon-count">
          <div class="count-likes">
            <svg width="16" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.26652 11.9398L1.54651 7.21315C0.858378 6.54912 0.44021 5.65401 0.372516 4.70013C0.304821 3.74625 0.592394 2.80104 1.17985 2.04648C1.51345 1.63931 1.9287 1.30659 2.3988 1.06976C2.86891 0.832928 3.38342 0.697258 3.90918 0.671504C4.43494 0.64575 4.96029 0.730471 5.45129 0.920222C5.94229 1.10997 6.38804 1.40054 6.75984 1.77317L6.9932 2.00648L7.1132 1.88649C7.77723 1.19836 8.67234 0.780193 9.62622 0.712499C10.5801 0.644804 11.5253 0.932377 12.2799 1.51983C12.687 1.85344 13.0198 2.26868 13.2566 2.73878C13.4934 3.20889 13.6291 3.7234 13.6549 4.24916C13.6806 4.77492 13.5959 5.30026 13.4061 5.79125C13.2164 6.28225 12.9258 6.72803 12.5532 7.09983L7.71985 11.9398C7.52639 12.1312 7.2653 12.2385 6.9932 12.2385C6.7211 12.2385 6.45997 12.1312 6.26652 11.9398Z" fill="#d3d3d3"/>
            </svg>
            <span v-if=" topic.count_like > 0 ">{{topic.count_like}}</span>
          </div>
          <div class="count-comments">
            <svg width="14" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8.54683 0.873047C7.48637 0.874344 6.44438 1.15058 5.52254 1.67478C4.6007 2.19899 3.83052 2.95325 3.28719 3.86395C2.74386 4.77465 2.44596 5.81067 2.42253 6.87087C2.3991 7.93107 2.65096 8.97923 3.15352 9.91305C3.76686 11.033 1.4935 12.6664 1.3335 12.9264C1.97193 13.0602 2.6311 13.0612 3.26994 12.9293C3.90878 12.7974 4.51361 12.5355 5.04683 12.1597C6.07671 12.8548 7.30647 13.192 8.54683 13.1197C10.1359 13.0673 11.6423 12.3992 12.7478 11.2565C13.8534 10.1139 14.4714 8.58628 14.4714 6.99638C14.4714 5.40648 13.8534 3.87883 12.7478 2.73621C11.6423 1.59358 10.1359 0.925486 8.54683 0.873047Z" fill="#d3d3d3"/>
            </svg>
            <span v-if="topic.count_comment > 0 ">{{topic.count_comment}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="author-and-comment">
      <div class="author"
        @click="gotoTopic('0')"
      >
        <img :src="topic.writer.avatarPath">
        <p class="name">
          {{topic.writer.fullName}}
        </p>
      </div>
      <div class="comment">
        <BButton
          class="bg-white is-secondary is-comment"
          type='is-success'
          outlined
          @click="gotoTopic(1)"
        >
          コメント
        </BButton>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  components: {},
  props: {
    isIcon: {
      type: String,
      default: ''
    },
    topic: {
      type: Object,
      require: true
    },
    joined: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {}
  },
  computed: {
    ...mapState('session', ['communityId']),
  },
  mounted () {
  },
  methods: {
    gotoTopic (params) {
      if (this.joined) {
        window.location.href = `/communities/${this.communityId}/community_groups/${this.topic.community_group_id}/community_group_topics/${this.topic.id}?comment=${params}`
      } else {
        this.$emit('notJoined')
      }
    }
  }
}
</script>
<style scoped lang="sass">
  .topic
    margin-top: 24px
    .header-topic
      background: #2DBA87
      border-radius: 12px 12px 0px 0px
      .label-topic
        display: flex
        align-items: center
        padding: 8px 0px
        img
          margin: 0px 10px 0px 16px
        .title-topic
          font-weight: 600
          font-size: 16px
          line-height: 16px
          color: #FFFFFF
    .body-topic
      background: #FFFFFF
      .body
        margin: 0px 20px
        .time-update
          padding-top: 16px
          font-weight: 400
          font-size: 12px
          line-height: 12px
          color: #AAAAAA
        .short-body
          font-weight: 300
          font-size: 14px
          line-height: 20px
          color: #273D35
          padding-top: 16px
        .icon-count
          margin-top: 16px
          display: flex
          font-size: 12px
          .count-likes
            margin-right: 16px
          .count-likes, .count-comments
            display: flex
            align-items: center
            span
              margin-left: 4px
    .author-and-comment
      background: #FFFFFF
      border-radius: 0px 0px 12px 12px
      padding: 0px 20px 26px 20px
      display: flex
      align-items: end
      justify-content: space-between
      .author
        display: flex
        width: 100%
        img
          width: 24px
          height: 24px
          object-fit: cover
          border-radius: 50%
          flex-shrink: 0
          margin-right: 8px
        .name
          font-weight: 300
          font-size: 12px
          line-height: normal
          color: #273D35
          display: flex
          align-items: center
      .comment
        text-align: right
        /deep/
          .is-comment
            height: 32px
            font-weight: bold
</style>
