<template>
  <div class='groups-new'>
    <Back :title="title" />
    <div class="groups-new-container">
      <div class="groups-new-note is-vcentered" v-if="!adminOrStaffOrSupporter">
        <div class="columns is-mobile ">
          <div class="column is-12 text-center title-new-group">
            <span>新しく作りたいグループの内容を書いて、</span>
            <br>
            <span>リクエストしてみましょう。</span>
          </div>
        </div>
      </div>

      <div class="groups-new-form">
        <div class="form-element groups-new-form-name" v-if="adminOrStaffOrSupporter">
          <BField label="新規グループ名" class="groups-new-form-name--label">
            <BInput v-model="name" placeholder="カメラが好きな人あつまれ！"></BInput>
          </BField>
        </div>
        <div class="form-element groups-new-form-description">
          <BField :label="labelDescription">
            <b-input v-model="description" type="textarea"
                     :placeholder="placeholderDescription"></b-input>
          </BField>
        </div>
        <div class="form-element groups-new-form-description" v-if="adminOrStaffOrSupporter">
          <div>サムネイル画像</div>
          <p class="image-note">JPG/JPEG・PNG・GIF形式、最大10MBまで</p>
          <div class="upload-area">
            <BField class="is-white file">
              <BUpload v-model="file"
                       class="file-label file-upload"
                       accept=".jpg, .png, .jpeg, .gif"
              >
                <span class="file-cta">
                  <BIcon class="file-icon" icon="plus"></BIcon>
                </span>
              </BUpload>
            </BField>
            <div v-if="file !== null" class="preview">
              <img class="image-preview" :src="displayFile(file)" >
              <img @click="deletePreview" class="delete-preview" src="../../images/icons/delete_preview.svg" alt="">
            </div>
          </div>
          <div v-if="!file && changeAvatar" class="invalid-image">JPG/JPEG・PNG・GIF形式</div>
        </div>
        <div class="form-element form-element-border groups-new-form-description" v-if="adminOrStaffOrSupporter">
          <BField>
            <div class="columns is-mobile ">
              <label class="column is-10" @click="focus">招待した人だけ参加できるようにする</label>
              <BSwitch v-model="allowOnlyPeople"></BSwitch>
            </div>
          </BField>
          <BInput v-model="passwordNumber" v-if="allowOnlyPeople" placeholder="認証コードを設定してください（英数半角のみ）"
                  type="text" class="password-group"
                  @input="validatePasswordNumberInput"></BInput>
<!--                  type="number" pattern="/^-?\d+\.?\d*$/" onKeyPress="if(this.value.length==4) return false;"-->
        </div>
        <div class="form-element-border groups-new-form-description" v-if="adminOrStaffOrSupporter">
          <BField>
            <div class="columns is-mobile">
              <div>
                <label class="column is-9" @click="focus">グループ一覧に掲載しない</label>
                <div class="memo" v-if="currentUser.supporter">非公開グループ</div>
              </div>
              <BSwitch v-model="privateMode"></BSwitch>
            </div>
          </BField>
        </div>

        <div class="form-element-border groups-new-form-submit" :class="{'border-0': !adminOrStaffOrSupporter}">
          <div class="memo" v-if="adminOrStaffOrSupporter">商業の広告・宣伝・広報・勧誘などを目的とした営業活動・営利利用のグループ作成はおやめください。</div>
          <BButton type='submit' @click='submit' :disabled="checkInput || isSubmitted" class="is-fullwidth is-success">
            {{ buttonTitle}}
          </BButton>
        </div>
      </div>
    </div>

    <BModal
      :active.sync="isComponentModalActive"
      has-modal-card
      :can-cancel="true"
      :on-cancel="redirectLogin"
      width="70%"
    >
      <div class="modal-card" style="width: auto">
        <div class="modal-card-body">
          <img src="../../images/for_community/circle-check.png">
          <p class="text-default">
            リクエストが完了しました。
            <br>
            2営業日以内に事務局からご
            <br>
            連絡いたしますので、それま
            <br>
            で少々おまちください。
          </p>
        </div>
      </div>
    </BModal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Back from '../../components/my-commu/Back'
import BaseSwitchInput from 'components/BaseSwitchInput'
import axios from '../../utils/axios'
import { commuSuccess } from '../../lib/app-toast'

export default {
  components: {
    Back,
    BaseSwitchInput
  },
  props: {
    objectGroup: {
      type: Object,
      default: () => ({})
    },
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('mycommu', ['currentCommunity', 'hiddenHeader', 'unsetPadding']),
    ...mapState('session', ['communityId', 'currentUser']),
    ...mapState('space', ['unsetPadding', 'unsetMargin']),
    checkInput () {
      if (this.allowOnlyPeople) {
        this.validatePasswordNumberInput()
      }
      if (this.adminOrStaffOrSupporter) {
        return (this.name === null || this.name === '' || this.file === null || (this.allowOnlyPeople && !this.validatePasswordNumber))
      } else {
        return (this.description === null || this.description === '')
      }
    },
    labelDescription () {
      if (this.adminOrStaffOrSupporter) {
        return 'グループの紹介'
      } else {
        return '作成したいグループ'
      }
    },
    placeholderDescription () {
      if (this.adminOrStaffOrSupporter) {
        return 'こんにちは。休日は近くを散歩しがら写真撮影をしています。撮った写真を紹介しあいませんか？'
      } else {
        return '同じ趣味や関心事、サークルや団体など、できるだけ具体的に教えてください。'
      }
    },
    uploadFile () {
      return this.file
    }
  },
  watch: {
    uploadFile () {
      this.changeAvatar = true
    }
  },
  data () {
    return {
      id: this.isEdit ? this.objectGroup.id : null,
      isComponentModalActive: false,
      file: this.isEdit ? this.objectGroup.file : null,
      name: this.isEdit ? this.objectGroup.name : null,
      description: this.isEdit ? this.objectGroup.description : null,
      privateMode: this.isEdit ? this.objectGroup.privateMode : false,
      allowOnlyPeople: this.isEdit ? this.objectGroup.allowOnlyPeople : false,
      title: this.isEdit ? this.objectGroup.title : '新しいグループのリクエスト',
      buttonTitle: 'リクエストする',
      passwordNumber: this.isEdit ? this.objectGroup.passwordNumber : '',
      validatePasswordNumber: false,
      adminOrStaffOrSupporter: false,
      changeAvatar: false,
      isSubmitted: false
    }
  },
  created () {
    this.adminOrStaffOrSupporter = this.currentUser.adminOrStaffOrSupporter
    if (this.adminOrStaffOrSupporter) {
      this.title = '新しいグループを作成'
      this.buttonTitle = this.isEdit ? '編集して公開する' : '作成する'
    }
  },
  mounted () {
    this.toggerMyCommuButton(false)
    this.toggerHeader(true)
    this.hiddenHeader.mainContainerUnsetMargin = true
    this.unsetPadding.mainContainer = true
  },
  methods: {
    ...mapActions('mycommu', [
      'toggerMyCommuButton',
      'toggerHeader'
    ]),
    toggleSwitch () {
    },
    redirectLogin () {
      window.location.href = `/communities/${this.currentCommunity.community_id}/community_groups`
    },
    async submit () {
      this.isSubmitted = true
      const form = new FormData()
      if (this.file !== null && this.file !== undefined && this.file.url === undefined) {
        form.append('community_group[image1]', this.file)
      }
      if (this.adminOrStaffOrSupporter) {
        form.append('community_group[name]', this.name)
      }
      form.append('community_group[description]', this.description)
      form.append('community_group[private_mode]', this.privateMode)
      form.append('community_group[allow_only_people]', this.allowOnlyPeople)
      form.append('community_group[join_password]', this.passwordNumber)
      const method = this.isEdit ? 'PATCH' : 'POST'
      const url = this.isEdit ? `/communities/${this.communityId}/community_groups/${this.id}` : `/communities/${this.communityId}/community_groups`
      await axios({
        method: method,
        url: url,
        data: form
      }).then((res) => {
        if (res.data.status) {
          if (this.adminOrStaffOrSupporter) {
            const textSuccess = this.isEdit ? 'グループは編集されました。' : 'グループは作成されました。'
            commuSuccess(textSuccess)
            window.location.href = `/communities/${this.currentCommunity.community_id}/community_groups`
          } else {
            this.isComponentModalActive = true
          }
        } else {
          this.isSubmitted = false
        }
      })
    },
    focus () {
      // this.$refs.input.focus()
    },
    deletePreview () {
      this.file = null
      this.changeAvatar = false
    },
    validatePasswordNumberInput () {
      this.validatePasswordNumber = this.passwordNumber.length > 0
    },
    displayFile (file) {
      if (file.url !== undefined) {
        return file.url
      } else {
        const url = URL.createObjectURL(file)
        URL.revokeObjectURL(file)
        return url
      }
    }
  }
}
</script>
<style scoped lang="sass">
  $label-color: #f8f7f2 !default
  $label-weight: 400 !default
  /deep/
    .switch input[type=checkbox]:checked + .check:before
      transform: translate3d(70%, 0, 0)
  .groups-new
    background: #f8f7f2
    font-size: 15px
    color: #273D35
    font-weight: 400
    overflow: scroll
    .label
      color: #273D35
      font-weight: 400
    .groups-new-container
      margin: 40px 23px 32px 23px
      font-size: 14px
      .title-new-group
        font-size: 15px
        font-weight: 300
      .groups-new-form
        margin-top: 40px
        .form-element
          margin-top: 24px
          /deep/
            .file
              display: unset
              .file-upload
                width: 48px
                height: 48px
                box-shadow: 0px 2px 8px #f2efde
                border-radius: 6px
                .file-cta
                  width: 100%
                  height: 100%
            .label
              font-size: 14px
              cursor: pointer
            .file-upload
              .file-icon
                margin: auto
                color: #2DBA87
        .form-element-border
          border-top: 1px solid #CCCCCC
          padding: 32px 0
          border-radius: unset
        .groups-new-form-submit
          padding: 32px 0
          .memo
            margin-bottom: 32px
            color: #2DBA87
            font-size: 12px
          /deep/
            button
              border-radius: 6px
              height: 48px
        .columns
          justify-content: space-between
          margin-right: unset
          /deep/
            span.check
              height: 31px
              width: 51px
              &:before
                width: 27px
                height: 27px

      .groups-new-form-name
        /deep/
          input
            height: 48px
            caret-color: #2DBA87
            box-shadow: unset
            border-color: #cccccc
            border-radius: 6px
          input:focus
            border-color: #2DBA87
            box-shadow: unset
      .groups-new-form-description
        /deep/
          input
            height: 48px
            caret-color: #2DBA87
            box-shadow: unset
            border-color: #cccccc
            border-radius: 6px
          input:focus
            border-color: #2DBA87
            box-shadow: unset
          textarea
            caret-color: #2DBA87
            height: 130px
            resize: none
            box-shadow: unset
            border-color: #cccccc
          border-radius: 6px
          textarea:focus
            border-color: #2DBA87
            box-shadow: unset
    /deep/
      .modal-background
        background: #273D35
        opacity: 0.9
      .modal-close
        display: none
  @media screen and (max-width: 768px)
    /deep/
      .modal .animation-content
        width: unset
  .modal-card-body
    //display: flex
    //justify-content: center
    //align-items: center
    //flex-direction: column
    color: #273D35
    font-size: 15px
    border-radius: 20px
    text-align: center
    padding: 40px 16px
    width: 230px
    img
      margin-bottom: 20px
      height: 66px
      width: 66px
  .is-success
    /deep/ span
      font-weight: bold
  .upload-area
    display: flex
    .preview
      margin-left: 16px
      position: relative
      .image-preview
        height: 48px
        width: 48px
        border: 1px solid #7C7C7C
        box-shadow: 0 2px 8px #F2EFDE
        border-radius: 6px
      .delete-preview
        position: absolute
        right: -8px
        top: -8px
  .border-0
    border: unset !important
  /deep/
    .password-group
      input::placeholder
        font-size: 15px !important
  .image-note
    font-size: 12px
    color: #2DBA87
    margin: 8px 0 16px
  .invalid-image
    font-size: 14px
    color: red
    font-weight: 600
    margin-bottom: 16px
</style>
