<template>
  <div class="event-schedules">
    <div class="card-event-schedules">
      <p class="title">
        タイムスケジュール
      </p>
      <MyCommuEventSchedules
        v-for="(schedule, index) in schedules"
        :key="index"
        :options="options"
        :total="totalSchedule"
        :count="index + 1"
        :submit="submit"
        :index-count="index"
        :schedule="schedule"
        @nextStep3="getDataSchedules"
        @removeSchedule="removeSchedule"
        ref="MyCommuEventSchedules"
        :errors="scheduleErrors"
      />
    </div>
    <div class="button-event-schedules">
      <BButton
        class="is-fullwidth bg-white is-secondary"
        type="is-success"
        outlined
        :focused="focused"
        @click="addEventSchedules"
      >
        <BIcon
          class="add-topic-icon"
          icon="plus"
          size="is-medium"
        />
        <p>新しいラベルを作成する</p>
      </BButton>
    </div>
  </div>
</template>

<script>

export default {
  components: {},
  props: {
    options: {
      type: Array,
      require: true
    },
    counts: {
      type: Number,
      default: 1
    },
    timeStart: {
      type: String,
      require: true
    },
    timeFinished: {
      type: String,
      require: true
    },
    minStarted: {
      type: String,
      default: '00:00'
    },
    maxFinished: {
      type: String,
      default: '00:00'
    },
    eventSchedules: {
      type: Array,
      default: () => []
    }
  },
  data () {
    let isSchedules = []
    var draftSchedule = []
    if (this.eventSchedules.length > 0 && this.eventSchedules[0].finishedAt !== '') {
      isSchedules = this.eventSchedules
      this.eventSchedules.map((value, index) => {
        draftSchedule.push(null)
      })
    } else {
      isSchedules = [{
        startedAt: this.minStarted,
        finishedAt: this.maxFinished,
        subject: '',
        real: null,
        index: 0,
        delete: false,
        id: null
      }]
    }
    let count = 1
    if (this.counts !== 0) {
      count = this.counts
    }
    return {
      focused: false,
      isCounts: count,
      indexCount: 0,
      submit: false,
      schedules: isSchedules,
      submitCounts: 0,
      submitSchedule: [{}],
      rollback: false,
      scheduleErrors: [],
      drafSchedule: draftSchedule.length > 0 ? draftSchedule : [null],
      totalSchedule: isSchedules.length
    }
  },
  computed: {
  },
  methods: {
    addEventSchedules: function () {
      this.isCounts = this.isCounts + 1
      this.indexCount = this.indexCount + 1
      this.totalSchedule += 1
      const data = {
        id: null,
        startedAt: null,
        finishedAt: null,
        subject: '',
        real: null,
        index: this.indexCount,
        delete: false
      }
      this.drafSchedule.push(null)
      this.schedules.push(data)
    },
    nextStep2 () {
      this.validateScheduleTime()
      this.submit = true
    },
    getDataSchedules (data) {
      this.submitCounts += 1
      this.submitSchedule = []
      this.submitSchedule.push(data)
      this.validateScheduleTime()
      if (!this.rollback) {
        this.schedules[data.index] = data
        this.$emit('nextStep2', this.schedules)
      }
      if (data.id && data.delete) {
        this.totalSchedule -= 1
      }
    },
    validateScheduleTime () {
      // eslint-disable-next-line no-unused-vars
      let errorsCount = 0
      this.submitSchedule.forEach((schedule1, index) => {
        if (!this.checkMinMaxTime(schedule1)) {
          const error = '時間誤差'
          errorsCount += 1
          this.scheduleErrors.splice(index, 0, error)
        }
      })
      this.$parent._data.scheduleErrosLength = errorsCount
      if (errorsCount === 0) {
        this.rollback = false
        this.scheduleErrors = []
        return true
      } else {
        this.submit = false
        this.rollback = true
        // this.submitSchedule = []
        this.submitCounts = 0
        return false
      }
    },
    checkMinMaxTime (schedule) {
      const start1 = this.convertToSec(this.minStarted)
      const end1 = this.convertToSec(this.maxFinished)
      const start2 = this.convertToSec(schedule.startedAt)
      const end2 = this.convertToSec(schedule.finishedAt)

      return (start1 <= start2 && end1 >= end2) && start2 < end2
    },
    convertToSec (time) {
      if (time !== undefined && time !== null && time.includes(':')) {
        const timeArr = time.split(':')
        return timeArr[0] * 3600 + timeArr[1] * 60
      } else {
        return 0
      }
    },
    removeSchedule (data) {
      if (data.id === null && data.delete) {
        this.schedules.splice(data.index, 1)
        this.$emit('nextStep2', this.schedules)
        this.totalSchedule -= 1
      }
    }
  }
}
</script>
<style scoped lang="sass">
  .is-fullwidth
    width: 100%
  .title-info
    font-weight: 700
    font-size: 17px
    line-height: 17px
    color: #273D35
  .title
    font-weight: 700
    font-size: 16px
    line-height: 16px
    color: #273D35
  .event-schedules-label
    font-weight: 300
    font-size: 14px
    line-height: 14px
    color: #273D35
    display: flex
    justify-content: space-between
    align-items: center
  .optional
    background-color: #FFFFFF
    color: #2DBA87
    font-size: 10px
    border: solid #2DBA87 1px
    padding: 2px 3px
    border-radius: 3px
    font-weight: 700
  .flex-between-center
    display: flex
    justify-content: space-between !important
    align-items: center
  .mt-24
    margin-top: 24px
  .mt-8
    margin-top: 8px
  .mt-14
    margin-top: 14px
  .event-schedules
    /deep/
      .input-green
        font-family: 'Hiragino Kaku Gothic ProN' !important
        background: #FFFFFF
        caret-color: #2DBA87
        border-radius: 6px
        &:focus
          border: 1px solid #2DBA87
          box-shadow: none
      .height-48
        height: 48px
      .field
        margin-bottom: 8px
      .subject
        p
          font-weight: 300
          font-size: 12px
          line-height: 12px
          color: #273D35
          line-height: 20px
      .start_at,.finished_at
        /deep/
          .select
            width: 100%
            select
              width: 100%
              height: 48px
          .select:not(.is-multiple):not(.is-loading)::after
            border: 2px solid #273D35
            border-right: 0px
            border-top: 0px
            margin-top: -0.1375em
      .time
        .distance
          margin: 22px 13px 0px 13px
          padding: 0 5px
          font-size: 14px
          font-weight: 300
          color: #000000
        .label-optional
          display: flex
          justify-content: end
  .button-event-schedules
    margin-top: 32px
    margin-bottom: 30px
    /deep/
      span
        display: flex
        width: 100%
        align-items: center
        .add-topic-icon
          display: flex
          width: unset
          width: 24px
          height: 24px
          margin-left: 4px !important
          margin-right: unset !important
        p
          width: 100%
          line-height: normal
          font-weight: bold
          font-size: 15px
      .button.is-success:hover, .button.is-success:focus
        color: #2DBA87
        border: none !important
        box-shadow: 0px 2px 8px #f2efde
  .btn-area
    display: inline-flex
    width: 100%
    .btn
      padding: 16px
      border-radius: 6px
      margin-top: 40px
      font-weight: 700
      font-size: 15px
      line-height: 15px
      background: white
      color: #2DBA87
      border: none
      width: 100%
      text-align: center
      box-shadow: 0 2px 8px #F2EFDE
      &.btn-success
        color: white
        background: #2DBA87
        margin-left: 5px
      &.btn-white
        margin-right: 5px
  button
    font-family: 'Hiragino Kaku Gothic ProN' !important

</style>
