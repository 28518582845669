import { render, staticRenderFns } from "./BaseModalDelete.vue?vue&type=template&id=36b86159&scoped=true&"
import script from "./BaseModalDelete.vue?vue&type=script&lang=js&"
export * from "./BaseModalDelete.vue?vue&type=script&lang=js&"
import style0 from "./BaseModalDelete.vue?vue&type=style&index=0&id=36b86159&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36b86159",
  null
  
)

export default component.exports