<template>
  <div class="cancel-modal">
    <div
      ref="cancelBackground"
      class="background-modal"
      @click="outModal"
    />
    <div
      ref="cancelForm"
      class="custom-modal"
    >
      <HiddenHeader />
      <Back
        title="予約キャンセル"
        :show-commu="false"
        :use-emit="true"
        @back="outModal"
      />
      <div class="cancel-content">
        <div class="cancel-content-header">
          交流イベント予約をキャンセルするには
          <br>
          主催者に理由を連絡する必要があります。
        </div>
        <div class="cancel-content-reason">
          <div class="reason-title">
            キャンセル理由
          </div>
          <div class="reason-select">
            <b-field>
              <BSelect
                v-model="cancelReason"
                placeholder="選択する"
              >
                <option>申込回・時間の変更</option>
                <option>別の予定が入った</option>
                <option>自身が体調不良</option>
                <option>家族が体調不良</option>
                <option>参加対象外だった</option>
                <option>その他</option>
              </BSelect>
            </b-field>
          </div>
        </div>
        <div class="cancel-content-reason cancel-content-comment">
          <div class="reason-title comment-title ">
            <div>詳細</div>
            <MyCommuOptional />
          </div>
          <div class="reason-select">
            <textarea
              v-model="cancelMessage"
              rows="7"
              placeholder="キャンセル理由をご記入ください"
            />
          </div>
        </div>
        <div class="button-submit">
          <button
            class="button is-success is-success-my-commu is-fullwidth mt-35"
            :disabled="checkDisabled"
            @click="submit()"
          >
            送信する
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Back from '../Back'
import axios from '../../../utils/axios'
import { commuError, commuSuccess } from 'lib/app-toast'
import { mapState } from 'vuex'

export default {
  components: {
    Back
  },
  props: {
    event: {
      type: Object
    }
  },
  data () {
    return {
      cancelMessage: null,
      cancelReason: null
    }
  },
  computed: {
    ...mapState('session', ['communityId']),
    checkDisabled () {
      return this.cancelReason === null
    }
  },
  methods: {
    submit () {
      const data = {
        id: this.event.id,
        cancel_reason: this.cancelReason,
        message: this.cancelMessage
      }

      return axios.post(`${window.location.pathname}/cancel_join_event`, data).then((response) => {
        if (response.data.status === true) {
          commuSuccess(response.data.message)
          this.outModal()
        } else {
          commuError(response.data.message)
          this.outModal()
        }
      })
    },
    outModal () {
      this.$refs.cancelForm.classList.add('close')
      this.$refs.cancelBackground.classList.add('close-bg')
      setTimeout(this.closeModal, 500)
    },
    closeModal () {
      this.$emit('closeModalClose')
    }
  }
}
</script>

<style scoped lang="sass">
  @keyframes showModal
    0%
      bottom: -70%
    100%
      bottom: 0
  @keyframes closeModal
    0%
      bottom: 0
    100%
      bottom: -70%
  @keyframes closeBg
    0%
      opacity: 1
    100%
      opacity: 0
  .cancel-modal
    position: fixed
    top: 0
    bottom: 0
    right: 0
    left: 0
    z-index: 2000
    .background-modal
      z-index: 2000
      background-color: #273D35
      opacity: 0.9
      position: absolute
      top: 0
      bottom: 0
      right: 0
      left: 0
    .close-bg
      opacity: 0
      animation: closeBg 0.5s ease-in-out
    .custom-modal
      z-index: 2000
      position: absolute
      width: 100%
      bottom: 0
      animation: showModal 0.5s ease-in-out
      padding-top: 30px
      border-radius: 10px 10px 0 0
      background: #FFFFFF
    .close
      bottom: calc(-70% + -30px)
      animation: closeModal 0.5s ease-in-out
    .cancel-content
      padding: 25px 32px 45px 32px
      .cancel-content-header
        font-size: 15px
        text-align: center
        color: #273D35
      .cancel-content-reason
        margin-top: 40px
        &.cancel-content-comment
          margin-top: 36px
        .reason-title
          font-size: 14px
          line-height: 14px
          color: #273D35
          &.comment-title
            display: flex
            justify-content: space-between
        .reason-select
          margin-top: 8px
      .btn
        padding: 16px
        border-radius: 6px
        margin-top: 35px
        font-weight: 700
        font-size: 15px
        line-height: 15px
        background: white
        color: #2DBA87
        border: none
        width: 100%
        text-align: center
        box-shadow: 0 2px 8px #F2EFDE
        &.btn-success
          color: white
          background: #2DBA87
  /deep/
    .select
      width: 100%
      &:after
        margin-top: 0 !important
        border-color: #273D35 !important
      option:disabled
        color: #AAAAAA
      textarea, select
        border-radius: 6px
        height: 48px
        padding: 16px
        font-size: 15px
        color: #273D35
        border: 1px solid #CCCCCC
        caret-color: #2DBA87
        outline: none
        background: white
        &::placeholder
          font-weight: 300
          font-size: 15px
          line-height: 20px
          color: #AAAAAA
        &:focus
          border: 1px solid #2DBA87
          box-shadow: unset
        &::-webkit-scrollbar
          display: none
      select
        width: 100%
        padding: 13px 16px
    textarea
      width: 100%
      resize: none
      height: unset
      border-radius: 6px
      padding: 16px
      font-size: 15px
      color: #273D35
      border: 1px solid #CCCCCC
      caret-color: #2DBA87
      outline: none
      background: white
      &::placeholder
        font-weight: 300
        font-size: 15px
        line-height: 20px
        color: #AAAAAA
      &:focus
        border: 1px solid #2DBA87
        box-shadow: unset
      &::-webkit-scrollbar
        display: none
  .mt-35
    margin-top: 35px
</style>
