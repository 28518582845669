<template>
  <div class="">
    <p class="guide">他ユーザーから友達の申請がありましたが、
      あなたの携帯電話番号の登録がされていない
      ため、友達承認ができません。
      他のユーザーと友達になるには、相手の携帯
      電話番号の下4桁を登録する必要があるため、
      携帯電話番号の登録をお願いいたします。
    </p>

    <label class="sign-up-label is-mt-4">
      <div class="primary" v-text="mobilePhoneProps.label"/>
    </label>

    <div class="is-mt-1">
      <BField
        :type="{ 'is-danger': this.mobilePhoneProps.errors.length > 0 }"
        :message="this.mobilePhoneProps.errors[0]"
      >
        <BInput
          v-model="mobilePhoneModel"
          :name="this.mobilePhoneProps.name"
          :placeholder="this.mobilePhoneProps.placeholder"
          class="is-fullwidth input-my-commu"
          custom-class="input-green"
          icon-pack="fas"
        />
      </BField>
    </div>

    <div class="columns is-mobile is-centered is-mt-5 is-mb-5">
      <div class="column">
        <BButton
          type='submit'
          @click='submit'
          :disabled="checkInput"
          class="is-fullwidth is-success"
          v-text="submitLabel"
        >
        </BButton>
      </div>
    </div>
    <b-modal
      :active.sync="isOpenModal"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-label="Example Modal"
      close-button-aria-label="Close"
      aria-modal
      :on-cancel="redirectToHome"
      class="success-modal"
    >
      <div class="modal-card">
        <div class="modal-card-body">
          <img src="../../images/icons/success.svg" class="success-img">
          <p class="success-message">
            携帯電話番号が<br>登録されました。
          </p>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import BaseSelectInput from '../../components/BaseSelectInput'
import BaseTextInput from '../../components/BaseTextInput'
import { commuError } from 'lib/app-toast'
import axios from 'axios'

export default {
  components: { BaseSelectInput, BaseTextInput},
  props: {
    mobilePhoneProps: {
      type: Object,
      required: true
    },
    openModal: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      mobilePhoneModel: this.mobilePhoneProps.value,
      isOpenModal: this.openModal,
      submitLabel: '携帯電話番号を登録する'
    }
  },
  computed: {
    checkInput () {
      return this.mobilePhoneModel === ''
    }
  },
  methods: {
    async submit () {
      const data = new FormData()
      data.append('profile[mobile_phone]', this.mobilePhoneModel)
      await axios.post(`/communities/profile/update_phone_number`,data).then((res) => {
        if (res.data.status) {
          this.isOpenModal = true
        } else {
          commuError(res.data.message)
        }
      })
    },
    redirectToHome () {
      window.location.href = '/'
    }
  }
}
</script>
<style lang="sass" scoped>
/deep/ .success-modal
  .animation-content
    width: 60%
    .modal-card
      .modal-card-body
        border-radius: 10px
        text-align: center
        .success-img
          margin-top: 15px
        .success-message
          padding: 15px 0px
/deep/ .input-my-commu > span
  color: #2DBA87 !important

.columns
  margin-bottom: 0 !important
.column
  padding: 5px 11px 24px 11px
.guide
  padding-bottom: 16px
.sub-guide
  color: #2DBA87
  padding-bottom: 16px
  font-size: 14px
.is-success
  font-weight: bold
</style>
