<template>
  <div>
    <div class="chat-body" @click="navigateOrOpenPopUp">
      <div class="chat-avatar">
        <img src="../../../images/icons/flag.svg" v-if="checkType('isRemind')">
        <img :src="avatarPath" v-if="!checkType('isRemind')">
        <img src="../../../images/notifications/mail.png" class="type" v-if="checkType('isMail')">
        <img src="../../../images/notifications/user.png" class="type" v-if="checkType('isFriend')">
        <img src="../../../images/notifications/heart.png" class="type" v-if="checkType('isReaction')">
        <img src="../../../images/notifications/comment.png" class="type" v-if="checkType('isComment')">
        <img src="../../../images/notifications/time.png" class="type" v-if="checkType('isRemind')">
        <img src="../../../images/notifications/share.png" class="type" v-if="checkType('isShare')">
      </div>
      <div class="chat-content">
        <div class="time">{{ time }}</div>
        <div class="message-chat" :class="{'un-opened': unOpened}">{{ content }}</div>
        <div class="commu-name" v-if="!hasOneCommunity">{{ communitiesName }}</div>
      </div>
      <div class="card-icon">
        <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.92993 1.8501L10.0699 10.0001L1.92993 18.1501" stroke="#2DBA87" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import axios from '../../../utils/axios'

export default {
  props: {
    avatarPath: {
      type: String,
      default: ''
    },
    content: {
      type: String,
      default: ''
    },
    time: {
      type: String,
      default: ''
    },
    iconType: {
      type: String,
      default: ''
    },
    communitiesName: {
      type: String,
      default: ''
    },
    communitiesIds: {
      type: Array,
      default: () => []
    },
    linkTo: {
      type: String,
      default: ''
    },
    readPath: {
      type: String,
      default: ''
    },
    unOpened: {
      type: Boolean,
      default: false
    },
    isUsingModal: {
      type: Boolean,
      default: false
    },
    friendOrRequested: {
      type: Boolean,
      default: false
    },
    notifierId: {
      type: Number,
    },
    fromProfile: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    ...mapState('mycommu', ['communities']),
    ...mapState('session', ['communityId']),
    hasOneCommunity () {
      return this.communities.length <= 1
    }
  },
  methods: {
    checkType (type) {
      return this.iconType === type
    },
    navigateOrOpenPopUp () {
      if (!this.communitiesIds.includes(this.communityId)) {
        this.$emit('openChangeCommuModal', this.communitiesIds[0], this.readPath, this.linkTo + '?from=notification')
      } else {
        this.navigate()
      }
    },
    navigate () {
      axios.put(`${this.readPath}`).then((respone) => {
        if (this.isUsingModal && !this.friendOrRequested && this.fromProfile) {
          this.$emit('openOtpModal', this.notifierId)
        } else {
          window.location.href = this.linkTo + '?from=notification'
        }
      })
    },
    openOtpModal () {
      this.$emit('openOtpModal')
    }
  },
  data () {
    return {
      isMail: true,
      isFriend: true,
      isComment: true,
      isRemind: true,
      isReaction: true,
      isShare: true
    }
  }
}
</script>

<style scoped lang="sass">
.chat-body
  display: flex
  position: relative
  padding: 16px
  background: white
  border-top: 1px solid #CCCCCC
  border-bottom: 1px solid #CCCCCC
  align-s: center
  .chat-avatar
    position: relative
    img
      width: 64px
      height: 64px
      border-radius: 50%
      flex-shrink: 0
      max-width: unset
      object-fit: cover
    .type
      width: 16px !important
      height: auto !important
      border-radius: unset !important
      position: absolute
      bottom: 5px
      right: 5px
  .chat-content
    margin-left: 16px
    margin-right: 20px
    .time
      font-style: normal
      font-weight: 300
      font-size: 10px
      line-height: 10px
      color: #7C7C7C
    .message-chat
      margin-top: 8px
      font-style: normal
      font-weight: 300
      font-size: 15px
      line-height: 23px
      color: #273D35
      white-space: pre-wrap
    .un-opened
      font-weight: bold
    .commu-name
      font-style: normal
      font-weight: 300
      font-size: 12px
      line-height: 12px
      display: flex
      align-s: center
      color: #7C7C7C
      margin-top: 8px
  .card-icon
    display: flex
    right: 16px
    position: absolute

</style>
