<template>
  <div>
    <div class="component">
      <div
        v-for="(community) in communities"
        :key="community.community_id"
        class="child"
      >
        <div class="community-name">
          {{ community.community_name }}
        </div>
        <div class="groups" v-if="community.community_groups.length > 0">
          <div
            class="group-name"
            v-for="group in community.community_groups"
            :key="group.id"
            v-if="group.active_status === 'active'"
          >
            ・{{ group.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    communities: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style lang="sass" scoped>
.component
  background: #FFFFFF
  border: 1px solid #CCCCCC
  border-radius: 12px
  padding: 16px 20px
  .child
    font-size: 15px
    line-height: 15px
    padding: 4px 0
    .groups
      padding: 12px 12px 4px 12px
      .group-name
        padding: 4px 0
</style>
