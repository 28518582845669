<template>
  <BDropdown
    v-if="$slots.default"
    :mobile-modal="false"
    position="is-bottom-left"
    :class="isCommunityDomainDropdown"
  >
    <button
      slot="trigger"
      class="option-button"
      :class="{ 'option-button-horizontal': horizontal }"
      type="button"
      :title="title"
    >
      <BIcon
        v-if="isMyCommuDomain"
        pack="far"
        icon="ellipsis-h"
        class="color-ellipsis-h"
        :custom-class="iconClass"
      />
      <BIcon
        v-else
        pack="far"
        icon="ellipsis-v"
        :custom-class="iconClass"
      />
      <div
        v-if="showText"
        class="option-text"
      >
        設定
      </div>
    </button>
    <slot />
  </BDropdown>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'オプション'
    },
    // NOTE: 今は使われていないオプション。デザイン調整中
    horizontal: {
      type: Boolean,
      default: false
    },
    isMyCommuDomain: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    iconClass () {
      if (this.horizontal) return 'fa-rotate-90'
      return ''
    },
    showText () {
      return this.horizontal
    },
    isCommunityDomainDropdown () {
      if (this.isMyCommuDomain) return 'community-option-button'
      return ''
    },
  },
}
</script>

<style scoped lang="sass">
.option-button
  background-color: transparent
  border: none
  cursor: pointer
  padding: 0
  .option-text
    font-size: 0.7rem
    margin-top: -5px

.option-button-horizontal
  .icon
    margin-top: 10px

/deep/ a.dropdown-item
  padding-right: 1rem
/deep/ .color-trash
  &:hover
    color: #EE5353
  color: #EE5353
.community-option-button
  /deep/ .dropdown-menu
    min-width: 8rem
.color-ellipsis-h
  color: #2DBA87
</style>
