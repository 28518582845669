/* eslint-disable no-param-reassign */
const store = {
  namespaced: true,
  state: {
    currentUser: null,
    communityId: null,
    communityGroupId: null,
    community: null
  },
  getters: {},
  mutations: {
    setCurrentUser (state, user) {
      state.currentUser = user
    },
    setCommunityId (state, communityId) {
      state.communityId = communityId
    },
    setCommunityGroupId (state, communityGroupId) {
      state.communityGroupId = communityGroupId
    },
    setCommunity (state, community) {
      state.community = community
    }
  },
  actions: {
    setCurrentUser ({ commit }, value) {
      commit('setCurrentUser', value)
    }
  }
}
export default store
