<template>
  <div>
    <div class="chat-body" v-for="friendRequest in friendRequests" :key="friendRequest.id">
      <div class="dots"></div>
      <div class="chat-avatar">
        <img :src="friendRequest.notifier.communityAvatarPath">
        <img src="../../../images/notifications/mail.png" alt="" class="mail">
      </div>
      <div class="chat-content">
        <div class="time">{{ friendRequest.createdAt }}</div>
        <div class="message-chat">
          {{ friendRequest.body }}
        </div>
        <div class="commu-name" v-if="!hasOneCommunity">{{ communitiesName(friendRequest) }}</div>
        <div class="btn-container">
          <button class="btn btn-accept" :class="{'disable': submit}" @click="acceptRequests(friendRequest)">承認する</button>
          <button class="btn btn-reject" :class="{'disable': submit}" @click="rejectRequests(friendRequest)">削除する</button>
        </div>
      </div>
      <a :href="friendRequest.path">
        <div class="card-icon">
          <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.92993 1.8501L10.0699 10.0001L1.92993 18.1501" stroke="#2DBA87" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
      </a>
    </div>
    <div class="memo">リクエストを削除しても相手には伝わりません。</div>
    <b-modal
      :active.sync="isOpenOtpModal"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-label="Example Modal"
      close-button-aria-label="Close"
      aria-modal
      class="user-modal"
    >
      <div
        class="modal-card"
        style="width: auto"
      >
        <section class="modal-card-body card-add-friend">
          <div class="add-friend">
            <img
              src="../../../images/icons/arr_left.svg"
              alt=""
              @click="closeOtpModal"
            >
            <strong><p>電話番号の下4桁を入力する</p></strong>
            <div class="div-fake" />
          </div>
          <div
            v-if="wrongAuthText"
            class="set-mb-error error-text-wrapper"
          >相手の電話番号下4桁と一致しません</div >
          <div class="form-number-phone">
            <MyCommuOtp
              ref="otpInput"
              input-classes="otp-input"
              :input-classes="this.inputError ? 'input-error' : ''"
              :num-inputs="4"
              :should-auto-focus="true"
              :is-input-num="true"
              @on-complete="handleOnComplete"
              @on-change="handleOnChange"
            />
          </div>
          <div class="set-pb-p">友達になるユーザーの電話番号下4桁を入力してください</div >
          <div
            id="sendData"
            @click="acceptRequests(chosenNotification)"
          >
            <MyCommuBaseButton
              text-content="次へ"
              :disable="!checkDisabled"
            />
          </div>
        </section>
      </div>
    </b-modal>
    <b-modal
      :active.sync="isAddFriendSuccess"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-label="Example Modal"
      close-button-aria-label="Close"
      aria-modal
      class="user-modal"
    >
      <div
        class="modal-card"
        style="width: auto"
      >
        <section class="modal-card-body card-add-friend">
          <div class="img-center">
            <strong><p>友達のリクエストを承認しました。</p></strong>
            <div class="div-fake" />
          </div>
          <div class="img-center">
            <img
              src="../../../images/icons/success.svg"
              alt="Add Friend Success"
            >
          </div>
          <div class="img-center">友達になりました</div>
          <div
            id="sendData"
            @click="closeSuccessModal"
          >
            <MyCommuBaseButton
              text-content="ホームへもどる"
            />
          </div>
        </section>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import axios from 'axios'

export default {
  data () {
    return {
      submit: false,
      keyPhone: '',
      isOpenOtpModal: false,
      inputError: false,
      wrongAuthText: false,
      chosenNotification: {},
      isAddFriendSuccess: false,
      // successText: `${chosenNotification}さんと友達になりました`
    }
  },
  computed: {
    ...mapState('notification', ['friendRequests']),
    ...mapState('mycommu', ['communities']),
    hasOneCommunity () {
      return this.communities.length <= 1
    }
  },
  methods: {
    ...mapActions('notification', ['commuFetchFriendRequests', 'acceptFriendRequests', 'rejectFriendRequests']),
    communitiesName (notification) {
      var communities = []
      notification.sameCommunities.map((community) => {
        communities.push(community.name)
      })
      return communities.join(', ')
    },
    handleOnComplete (value) {
      this.keyPhone = value
    },
    handleOnChange (value) {
      this.keyPhone = value
    },
    openOtpModal (fr) {
      this.isOpenOtpModal = true
      this.chosenNotification = fr
    },
    closeOtpModal () {
      this.isOpenOtpModal = false
      this.chosenNotification = {}
    },
    closeSuccessModal () {
      this.isAddFriendSuccess = false
    },
    acceptRequests (notification) {
      if (!this.submit) {
        const params = {
          'auth_key': this.keyPhone,
          'request_id': notification.id
        }
        axios.post('/communities/notifications/accept_friend_request', params).then((res) => {
          if (res.data.status === 'success') {
            this.commuFetchFriendRequests()
            this.wrongAuthText = false
            this.inputError = false
            this.isOpenOtpModal = false
            this.isAddFriendSuccess = true
          } else if (res.data.status === 'auth_failed') {
            this.wrongAuthText = true
            this.inputError = true
          } else {
            window.location.href = '/communities/notifications'
          }
        })
      }
    },
    rejectRequests (fr) {
      if (!this.submit) {
        this.submit = true
        this.rejectFriendRequests(fr)
      }
    }
  },
  created () {
    this.commuFetchFriendRequests()
  }
}
</script>

<style scoped lang="sass">
.img-center
  text-align: center
  margin-bottom: 30px
.error-text-wrapper
  border: 1px solid #EE5353
  border-radius: 10px
  background-color: #FFEEF0
  padding: 10px
  color: #EE5353
  text-align: center
.set-pt-p
  padding-top: 20px
.set-pb-p
  padding-bottom: 20px
.set-mb-error
  margin-bottom: 30px
/deep/
  .input-error
    border: 1px solid #EE5353 !important
.user-modal
  flex-direction: column
  justify-content: flex-end
  /deep/
    .modal-background
      background: #273D35
      opacity: 0.9
    .modal-card
      height: 65vh
      border-radius: 10px 10px 0px 0px
      .modal-card-body
        
        .user-avatar
          padding-top: 8px
          display: flex
          padding-bottom: 24px
          border-bottom: 1px solid #CCCCCC
          img
            width: 48px
            height: 48px
            border-radius: 50%
            object-fit: cover
            flex-shrink: 0
            margin-right: 8px
          .card-body
            display: flex
            flex-direction: column
            justify-content: center
        .mail, .friendship, .report, .block-user, .unfriend
          display: flex
          padding: 16px 0
          border-bottom: 1px solid #CCCCCC
          .icon
            margin-right: 18px
        .unfriend
          border-bottom: none
          .block-fake
            width: 24px
            margin-right: 18px
      .card-add-friend
        padding: 27px 32px 32px 32px
        .add-friend
          display: flex
          align-items: center
          justify-content: space-between
          margin-bottom: 37px
          .div-fake
            width: 24px
        .form-number-phone
          display: flex
          justify-content: center
    .modal-close
      display: none !important
.chat-body
  display: flex
  position: relative
  padding: 16px
  background: white
  border-top: 1px solid #CCCCCC
  border-bottom: 1px solid #CCCCCC
  align-items: center
  .dots
    width: 10px
    height: 10px
    background: #EE5353
    position: absolute
    border-radius: 50%
    left: 10px
    top: 10px
  .chat-avatar
    position: relative
    margin-bottom: 35px
    img
      width: 64px
      height: 64px
      border-radius: 50%
      object-fit: cover
      flex-shrink: 0
      max-width: unset
      object-fit: cover
    .mail
      width: 16px !important
      height: auto !important
      border-radius: unset !important
      position: absolute
      bottom: 5px
      right: 5px
  .chat-content
    margin-left: 16px
    margin-right: 20px
    .time
      font-style: normal
      font-weight: 300
      font-size: 10px
      line-height: 10px
      color: #7C7C7C
    .message-chat
      margin-top: 8px
      font-style: normal
      font-weight: 600
      font-size: 15px
      line-height: 23px
      color: #273D35

    .commu-name
      font-style: normal
      font-weight: 300
      font-size: 12px
      line-height: 12px
      display: flex
      align-items: center
      color: #7C7C7C
      margin-top: 8px
    .btn
      box-shadow: 0 2px 8px #F2EFDE
      border-radius: 6px
      font-style: normal
      font-weight: 600
      font-size: 14px
      line-height: 14px
      border: none
      padding: 9px
      &-container
        margin-top: 16px
      &-accept
        padding: 9px 30px
        min-width: 145px
        background: #2DBA87
        color: #FFFFFF
        margin-right: 16px
      &-reject
        min-width: 72px
        background: #FFFFFF
        color: #EE5353
  .card-icon
    display: flex
    right: 16px
    position: absolute
.memo
  font-style: normal
  font-weight: 400
  font-size: 12px
  line-height: 12px
  color: #7C7C7C
  margin: 16px 13px
</style>
