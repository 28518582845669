<template>
  <div class="base-button">
    <button :class="[{'has-icon': checkIcon, 'button-min': buttonMin,'button-secondary-s': buttonSecondaryS, 'white-button': isWhiteButton, 'red-button': isRedButton, 'disable': disable}, customClass]" :type="type" @click="submit()">
      <div v-if="checkIcon" v-html="icon" class="icon"></div>
      <div class="text-content">{{ textContent }}</div>
      <div v-if="checkIcon" class="fake-icon"></div>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    textContent: {
      type: String,
      default: 'テキストテキスト'
    },
    icon: {
      type: String,
      default: ''
    },
    buttonMin: {
      type: Boolean,
      default: false
    },
    isWhiteButton: {
      type: Boolean,
      default: false
    },
    isRedButton: {
      type: Boolean,
      default: false
    },
    disable: {
      type: Boolean,
      default: false
    },
    customClass: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'button'
    },
    buttonSecondaryS: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      hasIcon: this.icon !== '',
      hasCustomClass: this.customClass !== ''
    }
  },
  computed: {
    checkIcon () {
      return this.icon !== ''
    }
  },
  methods: {
    submit () {
      if (!this.disable) {
        this.$emit('clickButton', this.date)
      }
    }
  }

}
</script>

<style lang="sass" scoped>
.base-button
  display: flex
  justify-content: center
  button
    font-family: 'Hiragino Kaku Gothic ProN'
    font-weight: bold
    width: 100%
    height: 48px
    padding: 16px 10px
    font-size: 15px
    line-height: 15px
    color: #FFFFFF
    background: #2DBA87
    border: 0
    border-radius: 6px
    box-shadow: 0 2px 8px #F2EFDE
    &:hover
      background: #61DEB1
    &.disable
      opacity: 0.4
      &:hover
        background: #2DBA87
  .icon
    width: 24px
    height: 24px
  .fake-icon
    width: 24px
    height: 24px
  .has-icon
    display: flex
    justify-content: space-between
    align-items: center
    padding: 14px 18px 14px 18px
  .button-min
    padding: 9px 18px 9px 18px
    font-size: 14px
  .button-secondary-s
    padding: 9px 8px 9px 8px
    font-size: 14px
  .white-button
    color: #2DBA87
    background: #FFFFFF !important
    &:hover
      background: #D5F1E7
    &.disable
      opacity: 0.4
      &:hover
        background: #FFFFFF
  .red-button
    color: #EE5353
    background: #FFFFFF
    &:hover
      background: #D5F1E7
    &.disable
      opacity: 0.4
      &:hover
        background: #FFFFFF
  .color-black
    color: black
    justify-content: center
    /deep/
      .icon
        margin: 8px
        svg
          fill: white
    .fake-icon
      display: none
</style>
