<template>
  <div class="my-commu-domain card-tranfer-child-care">
    <div class="card-tranfer-child-care-border">
      <div class="border-left"></div>
      <span class="co-icon">
        <svg width="24" height="12" viewBox="0 0 24 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M23.3707 0.499756H14.7821C13.4355 0.499756 7.02163 4.22392 6.65255 6.3182C6.28348 8.41247 10.9219 13.3971 14.5029 10.7247C17.1786 8.93775 20.1882 7.65131 23.3707 6.93419V0.499756Z"
            fill="#FFBEB1" stroke="black" stroke-linejoin="round" />
          <path
            d="M0.907227 0.499756H9.50568C10.8423 0.499756 17.2563 4.22392 17.6254 6.3182C17.9944 8.41247 13.3561 13.3971 9.77503 10.7247C7.10024 8.93613 4.09032 7.64955 0.907227 6.93419V0.499756Z"
            fill="#FFBEB1" stroke="black" stroke-linejoin="round" />
        </svg>

      </span>
      <div class="border-right"></div>
    </div>
    <div class="co-title">
      <p>コミュニティ内でリクエストされた</p>
      <p>送迎・託児のシェアリングが成立しました！</p>
    </div>

    <div class="co-info">
      <div class="co-icon-congratulations-left">
        <svg width="51" height="60" viewBox="0 0 51 60" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_2879_10092)">
            <path
              d="M2.69768 59.2302L25.474 41.3798L8.71142 30.1958L0.989497 58.0808C0.916347 58.3024 0.917602 58.5419 0.993155 58.7626C1.06871 58.9834 1.21446 59.1734 1.40801 59.3036C1.60155 59.4338 1.83229 59.4972 2.06516 59.4839C2.29802 59.4707 2.52014 59.3815 2.69768 59.2302Z"
              fill="#22B573" />
            <path
              d="M1.99824 59.9998C1.74886 59.9984 1.50333 59.9379 1.28183 59.8233C1.06032 59.7086 0.869168 59.5431 0.723939 59.3403C0.57871 59.1374 0.483616 58.903 0.446397 58.6563C0.409178 58.4096 0.430884 58.1575 0.509807 57.9209L8.4216 29.3962L26.3328 41.3898L3.0172 59.65C2.7317 59.8873 2.36921 60.0117 1.99824 59.9998ZM8.99095 31.0154L1.44877 58.2307C1.39921 58.3503 1.39196 58.4833 1.42804 58.6076C1.46411 58.732 1.54143 58.8403 1.64729 58.9148C1.75316 58.9893 1.88115 59.0255 2.01032 59.0174C2.13949 59.0094 2.26209 58.9575 2.35785 58.8704L24.6047 41.4198L8.99095 31.0154Z"
              fill="black" />
            <path
              d="M10.8689 44.768C11.3726 44.0165 11.6676 43.1445 11.7239 42.2414C11.7801 41.3383 11.5956 40.4365 11.1891 39.6282C10.7826 38.8199 10.1688 38.1341 9.41047 37.6411C8.65212 37.1481 7.77644 36.8655 6.87308 36.8223L4.21582 46.4371C5.33441 47.0329 6.63432 47.1921 7.8635 46.8837C9.09269 46.5754 10.1637 45.8214 10.8689 44.768Z"
              fill="white" />
            <path
              d="M6.62285 47.5866C5.69568 47.5843 4.7832 47.3544 3.96559 46.9169L3.62598 46.767L6.50298 36.3826H6.90259C7.89434 36.428 8.85613 36.7368 9.68923 37.277C10.5223 37.8173 11.1967 38.5697 11.6431 39.4569C12.0896 40.3441 12.292 41.3342 12.2295 42.3255C12.1671 43.3169 11.8422 44.2738 11.288 45.0979C10.7764 45.866 10.0825 46.4953 9.2684 46.9296C8.45433 47.3638 7.54541 47.5896 6.62285 47.5866ZM4.7948 46.2073C5.7841 46.6376 6.89326 46.7051 7.9274 46.398C8.96153 46.0909 9.85434 45.4288 10.4488 44.5282C10.7853 44.0244 11.0192 43.4591 11.1371 42.8648C11.2549 42.2705 11.2545 41.6587 11.1357 41.0646C11.0169 40.4704 10.7822 39.9055 10.4449 39.4022C10.1076 38.899 9.67452 38.4672 9.17024 38.1316C8.58997 37.7405 7.92623 37.4907 7.2322 37.402L4.7948 46.2073Z"
              fill="black" />
            <path
              d="M14.7252 48.0163C14.1622 47.6001 13.4571 47.4248 12.7649 47.5288C12.0727 47.6328 11.4501 48.0077 11.0341 48.571C10.6182 49.1343 10.4429 49.8398 10.5469 50.5324C10.6508 51.225 11.0255 51.8478 11.5885 52.264L15.5843 49.0957C15.3996 48.6655 15.1029 48.2928 14.7252 48.0163Z"
              fill="white" />
            <path
              d="M11.6183 52.9036L11.3086 52.6538C10.7493 52.1255 10.4035 51.4099 10.337 50.6432C10.2704 49.8765 10.4878 49.112 10.9478 48.4952C11.4077 47.8783 12.0781 47.452 12.8317 47.2974C13.5852 47.1428 14.3693 47.2706 15.0348 47.6565C15.5036 47.9758 15.8793 48.4137 16.1236 48.9257L16.2935 49.2856L11.6183 52.9036ZM13.2866 48.0762C13.1472 48.0615 13.0065 48.0615 12.867 48.0762C12.4993 48.1461 12.1566 48.3118 11.8733 48.5566C11.5901 48.8013 11.3763 49.1165 11.2537 49.4703C11.1311 49.8241 11.104 50.204 11.1751 50.5716C11.2461 50.9393 11.4128 51.2817 11.6583 51.5643L15.0148 48.9257C14.8703 48.7164 14.6872 48.5367 14.4753 48.3961C14.1107 48.1688 13.6858 48.0573 13.2566 48.0762H13.2866Z"
              fill="black" />
            <path
              d="M5.62387 52.9637C4.73858 52.3752 3.68873 52.0845 2.627 52.1341L0.978695 58.1309C0.905545 58.3525 0.906922 58.5919 0.982475 58.8127C1.05803 59.0335 1.20366 59.2234 1.3972 59.3537C1.59075 59.4839 1.82149 59.5472 2.05436 59.534C2.28722 59.5207 2.50946 59.4316 2.687 59.2803L7.54192 55.4723C7.19503 54.4482 6.52102 53.5667 5.62387 52.9637Z"
              fill="white" />
            <path
              d="M2.00834 59.9998C1.69512 59.9986 1.38927 59.9047 1.12925 59.73C0.839909 59.54 0.622042 59.2592 0.5099 58.9316C0.397758 58.6041 0.397725 58.2485 0.5099 57.9209L2.24808 51.6544H2.60769C3.77902 51.5933 4.93881 51.9122 5.9143 52.5638C6.8938 53.212 7.63413 54.1634 8.02208 55.2724L8.14195 55.6122L3.00729 59.61C2.73376 59.8588 2.37798 59.9976 2.00834 59.9998ZM3.00729 52.6338L1.45886 58.2108C1.40931 58.3305 1.40206 58.4634 1.43813 58.5878C1.4742 58.7121 1.55152 58.8204 1.65738 58.8949C1.76325 58.9694 1.89124 59.0056 2.02041 58.9975C2.14958 58.9894 2.27218 58.9376 2.36795 58.8505L6.94313 55.2624C6.60337 54.4948 6.05255 53.8397 5.35482 53.3734C4.65986 52.9135 3.85019 52.6573 3.01729 52.6338H3.00729Z"
              fill="black" />
            <path
              d="M2.00834 60C1.69513 59.9987 1.38927 59.9048 1.12925 59.7301C0.839909 59.5402 0.622042 59.2593 0.5099 58.9318C0.397758 58.6042 0.397725 58.2486 0.5099 57.9211L8.23182 30.0562L9.23078 30.326L1.49886 58.211C1.4493 58.3306 1.44193 58.4636 1.47801 58.5879C1.51408 58.7123 1.5914 58.8206 1.69726 58.8951C1.80312 58.9696 1.93124 59.0057 2.06041 58.9977C2.18958 58.9896 2.31218 58.9378 2.40794 58.8507L25.2041 40.9902L25.8135 41.7798L3.03729 59.6202C2.75461 59.8723 2.38696 60.008 2.00834 60Z"
              fill="black" />
            <path
              d="M25.9952 41.2437C27.1131 39.5679 24.1468 35.6234 19.3697 32.4335C14.5927 29.2436 9.81392 28.0162 8.69602 29.692C7.57812 31.3678 10.5444 35.3122 15.3215 38.5021C20.0985 41.6921 24.8773 42.9195 25.9952 41.2437Z"
              fill="#217B53" />
            <path
              d="M24.0646 42.4693C23.3317 42.4516 22.6038 42.3441 21.8969 42.1495C19.4449 41.4426 17.1238 40.3422 15.0241 38.8912C10.6287 35.9528 6.7627 31.6251 8.26113 29.3763C8.94043 28.3769 10.4988 28.137 12.7664 28.7167C15.218 29.4215 17.5367 30.5258 19.6293 31.985C21.7766 33.3636 23.6861 35.0816 25.2834 37.0722C26.6919 38.9512 27.0815 40.4804 26.4022 41.4898C26.1268 41.8371 25.768 42.109 25.3593 42.2802C24.9506 42.4515 24.5052 42.5165 24.0646 42.4693ZM10.6187 29.4064C10.3433 29.3699 10.0632 29.3989 9.80115 29.4909C9.53908 29.5829 9.3024 29.7354 9.11022 29.936C8.29108 31.1754 10.7485 34.8135 15.6034 38.0517C17.6055 39.4466 19.8227 40.5033 22.1667 41.18C23.9049 41.6297 25.1635 41.5398 25.593 40.9401C25.9926 40.3305 25.5931 39.1112 24.5042 37.6719C22.9775 35.7707 21.1523 34.1301 19.0998 32.8145C17.0933 31.4275 14.8775 30.3713 12.5367 29.6862C11.9115 29.5133 11.2672 29.4193 10.6187 29.4064Z"
              fill="black" />
            <path d="M36.6795 25.9985L31.9727 24.583L30.5578 29.2923L35.2647 30.7078L36.6795 25.9985Z" fill="white" />
            <path
              d="M35.503 31.4053L29.8389 29.6962L31.547 24.0293L37.2112 25.7384L35.503 31.4053ZM31.0876 29.0266L34.8337 30.156L35.9625 26.408L32.2164 25.2786L31.0876 29.0266Z"
              fill="black" />
            <path
              d="M34.5942 15.3739L30.4385 12.9751L32.836 8.81738L37.0017 11.2161L34.5942 15.3739ZM31.807 12.6053L34.2346 14.0145L35.6331 11.5859L33.2056 10.1766L31.807 12.6053Z"
              fill="black" />
            <path d="M21.1383 10.6866L17.6357 11.9541L18.9026 15.4584L22.4051 14.1909L21.1383 10.6866Z" fill="white" />
            <path
              d="M18.9501 16.933L17.3418 12.4854L21.7872 10.8862L23.3955 15.3238L18.9501 16.933ZM18.6205 13.0851L19.5495 15.6537L22.1168 14.7242L21.1878 12.1656L18.6205 13.0851Z"
              fill="black" />
            <path d="M28.281 17.8775L25.1514 19.4812L26.7543 22.6125L29.8839 21.0088L28.281 17.8775Z" fill="#6764CB" />
            <path
              d="M26.6028 23.1798L24.5449 19.1819L28.5408 17.123L30.5987 21.1209L26.6028 23.1798ZM25.8936 19.5917L27.0423 21.8305L29.2801 20.6811L28.1313 18.4423L25.8936 19.5917Z"
              fill="black" />
            <g clip-path="url(#clip1_2879_10092)">
              <path d="M43.0134 1.9609L39.3887 3.16455L40.5917 6.79109L44.2165 5.58743L43.0134 1.9609Z"
                fill="#B1A9BA" />
              <path
                d="M40.1407 7.27137L38.637 2.71311L43.1929 1.20868L44.6966 5.76694L40.1407 7.27137ZM39.9041 3.34904L40.7697 6.01108L43.437 5.13767L42.5573 2.47644L39.9041 3.34904Z"
                fill="black" />
            </g>
            <path d="M23.3161 32.7446H19.7998V36.2627H23.3161V32.7446Z" fill="white" />
            <path d="M23.8151 36.7725H19.2998V32.2449H23.8151V36.7725ZM20.2988 35.773H22.8162V33.2443H20.2988V35.773Z"
              fill="black" />
            <path d="M5.00129 9.76939L1.5293 10.3264L2.08604 13.8002L5.55804 13.2431L5.00129 9.76939Z" fill="#6764CB" />
            <path
              d="M1.62817 14.3745L0.918945 9.91691L5.37426 9.19727L6.09348 13.6649L1.62817 14.3745ZM2.05777 10.7465L2.45726 13.2351L4.94465 12.8353L4.54517 10.3467L2.05777 10.7465Z"
              fill="black" />
            <path d="M22.1872 23.8794H18.6709V27.3975H22.1872V23.8794Z" fill="#FFC400" />
            <path d="M22.6861 27.8972H18.1709V23.3696H22.6861V27.8972ZM19.1699 26.8977H21.6871V24.3691H19.1699V26.8977Z"
              fill="black" />
            <path d="M44.1493 16.9572L40.1797 20.417L43.6377 24.3885L47.6072 20.9288L44.1493 16.9572Z" fill="#22B573" />
            <path
              d="M43.5842 24.9888L39.4785 20.2613L44.1936 16.1436L48.3093 20.8711L43.5842 24.9888ZM40.8871 20.3613L43.6841 23.5796L46.9007 20.7711L44.1037 17.5528L40.8871 20.3613Z"
              fill="black" />
            <path d="M9.77907 19.6353L6.63867 17.8213L4.82556 20.9633L7.96596 22.7773L9.77907 19.6353Z"
              fill="#FF7569" />
            <path
              d="M8.14134 23.4597L4.14551 21.1409L6.45304 17.1431L10.4489 19.4618L8.14134 23.4597ZM5.50408 20.7811L7.77173 22.0904L9.0903 19.8117L6.81265 18.5023L5.50408 20.7811Z"
              fill="black" />
            <path d="M29.0888 3.52984L26.3203 1.93066L24.722 4.70046L27.4904 6.29964L29.0888 3.52984Z" fill="#FF7569" />
            <path
              d="M27.6811 6.98836L24.0449 4.87951L26.1428 1.24146L29.7791 3.35031L27.6811 6.98836ZM25.4036 4.51966L27.3115 5.6191L28.4105 3.7101L26.5024 2.61071L25.4036 4.51966Z"
              fill="black" />
            <path
              d="M12.9069 26.6379H12.797C12.6664 26.6241 12.5398 26.5848 12.4245 26.5221C12.3091 26.4594 12.2072 26.3746 12.1246 26.2725C12.042 26.1704 11.9805 26.053 11.9433 25.9271C11.9061 25.8011 11.8941 25.6691 11.9079 25.5385L14.3753 1.86122C14.4031 1.59614 14.5351 1.353 14.7421 1.18525C14.9491 1.01749 15.2142 0.938845 15.4791 0.966677C15.7441 0.99451 15.9872 1.12649 16.1548 1.33361C16.3225 1.54072 16.401 1.80605 16.3732 2.07112L13.8958 25.7783C13.8621 26.0168 13.7435 26.235 13.5618 26.3929C13.3801 26.5509 13.1476 26.6378 12.9069 26.6379Z"
              fill="#FFC400" />
            <path
              d="M30.3483 37.2621C30.2171 37.2766 30.0843 37.2651 29.9576 37.2282C29.8308 37.1913 29.7126 37.1298 29.6096 37.0472C29.4017 36.8804 29.2686 36.6377 29.2394 36.3726C29.2103 36.1075 29.2876 35.8417 29.4543 35.6337C29.621 35.4256 29.8636 35.2924 30.1285 35.2632L49.7679 30.8856C49.8965 30.8561 50.0297 30.8524 50.1597 30.8747C50.2898 30.897 50.4141 30.9448 50.5256 31.0154C50.637 31.0861 50.7334 31.1781 50.8091 31.2862C50.8848 31.3943 50.9384 31.5163 50.9668 31.6452C51.0233 31.9036 50.9751 32.1739 50.8327 32.3968C50.6904 32.6198 50.4656 32.7772 50.2075 32.8345L30.598 37.2322C30.5168 37.2548 30.4325 37.2649 30.3483 37.2621Z"
              fill="#FFC400" />
          </g>
          <defs>
            <clipPath id="clip0_2879_10092">
              <rect width="50.5573" height="59.0382" fill="white" transform="translate(0.429688 0.96167)" />
            </clipPath>
            <clipPath id="clip1_2879_10092">
              <rect width="6.55463" height="6.555" fill="white"
                transform="matrix(0.74586 0.666103 -0.665729 0.746194 41.4043 -0.388672)" />
            </clipPath>
          </defs>
        </svg>

      </div>
      <div class="co-container">
<!--        use later-->
<!--        <div class="avatar">-->
<!--          <img :src="user.avatarPath" />-->
<!--        </div>-->
<!--        <div class="name">-->
<!--          <span>{{ user.commuNickname || user.fullName }}</span>-->
<!--        </div>-->
      </div>
      <div class="co-icon-congratulations-right">
        <svg width="51" height="60" viewBox="0 0 51 60" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_2879_10128)">
            <path
              d="M48.4127 59.2302L25.6364 41.3798L42.3989 30.1958L50.1209 58.0808C50.194 58.3024 50.1927 58.5419 50.1172 58.7626C50.0416 58.9834 49.8959 59.1734 49.7023 59.3036C49.5088 59.4338 49.2781 59.4972 49.0452 59.4839C48.8123 59.4707 48.5902 59.3815 48.4127 59.2302V59.2302Z"
              fill="#FF7569" />
            <path
              d="M49.1121 59.9998C49.3615 59.9984 49.607 59.9379 49.8285 59.8233C50.05 59.7086 50.2412 59.5431 50.3864 59.3403C50.5316 59.1374 50.6267 58.903 50.664 58.6563C50.7012 58.4096 50.6795 58.1575 50.6005 57.9209L42.6888 29.3962L24.7775 41.3898L48.0932 59.65C48.3786 59.8873 48.7411 60.0117 49.1121 59.9998V59.9998ZM42.1194 31.0154L49.6616 58.2307C49.7111 58.3503 49.7184 58.4833 49.6823 58.6076C49.6462 58.732 49.5689 58.8403 49.4631 58.9148C49.3572 58.9893 49.2292 59.0255 49.1 59.0174C48.9709 59.0094 48.8483 58.9575 48.7525 58.8704V58.8704L26.5057 41.4198L42.1194 31.0154Z"
              fill="black" />
            <path
              d="M40.2414 44.768C39.7378 44.0165 39.4428 43.1445 39.3865 42.2414C39.3302 41.3383 39.5147 40.4365 39.9212 39.6282C40.3277 38.8199 40.9415 38.1341 41.6999 37.6411C42.4582 37.1481 43.3339 36.8655 44.2373 36.8223L46.8945 46.4371C45.7759 47.0329 44.476 47.1921 43.2468 46.8837C42.0177 46.5754 40.9467 45.8214 40.2414 44.768V44.768Z"
              fill="white" />
            <path
              d="M44.4875 47.5866C45.4147 47.5843 46.3272 47.3544 47.1448 46.9169L47.4844 46.767L44.6074 36.3826H44.2078C43.216 36.428 42.2542 36.7368 41.4211 37.277C40.588 37.8173 39.9137 38.5697 39.4672 39.4569C39.0208 40.3441 38.8184 41.3342 38.8808 42.3255C38.9432 43.3169 39.2682 44.2738 39.8223 45.0979C40.3339 45.866 41.0279 46.4953 41.8419 46.9296C42.656 47.3638 43.5649 47.5896 44.4875 47.5866V47.5866ZM46.3156 46.2073C45.3263 46.6376 44.2171 46.7051 43.183 46.398C42.1488 46.0909 41.256 45.4288 40.6615 44.5282V44.5282C40.325 44.0244 40.0911 43.4591 39.9733 42.8648C39.8554 42.2705 39.8559 41.6587 39.9746 41.0646C40.0934 40.4704 40.3282 39.9055 40.6654 39.4022C41.0027 38.899 41.4358 38.4672 41.9401 38.1316C42.5204 37.7405 43.1841 37.4907 43.8782 37.402L46.3156 46.2073Z"
              fill="black" />
            <path
              d="M36.3851 48.0163C36.9481 47.6001 37.6532 47.4248 38.3455 47.5288C39.0377 47.6328 39.6602 48.0077 40.0762 48.571C40.4922 49.1343 40.6675 49.8398 40.5635 50.5324C40.4595 51.225 40.0848 51.8478 39.5218 52.264L35.526 49.0957C35.7108 48.6655 36.0074 48.2928 36.3851 48.0163V48.0163Z"
              fill="white" />
            <path
              d="M39.492 52.9036L39.8018 52.6538C40.3611 52.1255 40.7068 51.4099 40.7734 50.6432C40.8399 49.8765 40.6225 49.112 40.1626 48.4952C39.7026 47.8783 39.0322 47.452 38.2787 47.2974C37.5252 47.1428 36.7411 47.2706 36.0755 47.6565C35.6068 47.9758 35.2311 48.4137 34.9867 48.9257L34.8168 49.2856L39.492 52.9036ZM37.8237 48.0762C37.9632 48.0615 38.1039 48.0615 38.2433 48.0762C38.611 48.1461 38.9538 48.3118 39.237 48.5566C39.5203 48.8013 39.734 49.1165 39.8566 49.4703C39.9792 49.8241 40.0063 50.204 39.9353 50.5716C39.8643 50.9393 39.6976 51.2817 39.452 51.5643L36.0955 48.9257C36.24 48.7164 36.4231 48.5367 36.635 48.3961C36.9997 48.1688 37.4245 48.0573 37.8537 48.0762H37.8237Z"
              fill="black" />
            <path
              d="M45.4865 52.9637C46.3718 52.3752 47.4216 52.0845 48.4834 52.1341L50.1317 58.1309C50.2048 58.3525 50.2034 58.5919 50.1279 58.8127C50.0523 59.0335 49.9067 59.2234 49.7131 59.3537C49.5196 59.4839 49.2889 59.5472 49.056 59.534C48.8231 59.5207 48.6009 59.4316 48.4234 59.2803L43.5684 55.4723C43.9153 54.4482 44.5893 53.5667 45.4865 52.9637V52.9637Z"
              fill="white" />
            <path
              d="M49.102 59.9998C49.4152 59.9986 49.7211 59.9047 49.9811 59.73C50.2704 59.54 50.4883 59.2592 50.6005 58.9316C50.7126 58.6041 50.7126 58.2485 50.6005 57.9209L48.8623 51.6544H48.5027C47.3313 51.5933 46.1715 51.9122 45.1961 52.5638V52.5638C44.2165 53.212 43.4762 54.1634 43.0883 55.2724L42.9684 55.6122L48.1031 59.61C48.3766 59.8588 48.7324 59.9976 49.102 59.9998V59.9998ZM48.1031 52.6338L49.6515 58.2108C49.701 58.3305 49.7083 58.4634 49.6722 58.5878C49.6361 58.7121 49.5588 58.8204 49.453 58.8949C49.3471 58.9694 49.2191 59.0056 49.0899 58.9975C48.9608 58.9894 48.8382 58.9376 48.7424 58.8505L44.1672 55.2624C44.507 54.4948 45.0578 53.8397 45.7555 53.3734V53.3734C46.4505 52.9135 47.2602 52.6573 48.0931 52.6338H48.1031Z"
              fill="black" />
            <path
              d="M49.102 60C49.4152 59.9987 49.7211 59.9048 49.9811 59.7301C50.2704 59.5402 50.4883 59.2593 50.6005 58.9318C50.7126 58.6042 50.7126 58.2486 50.6005 57.9211L42.8785 30.0562L41.8796 30.326L49.6115 58.211C49.661 58.3306 49.6684 58.4636 49.6323 58.5879C49.5963 58.7123 49.519 58.8206 49.4131 58.8951C49.3072 58.9696 49.1791 59.0057 49.0499 58.9977C48.9208 58.9896 48.7982 58.9378 48.7024 58.8507L25.9062 40.9902L25.2969 41.7798L48.0731 59.6202C48.3557 59.8723 48.7234 60.008 49.102 60Z"
              fill="black" />
            <path
              d="M25.1151 41.2437C23.9972 39.5679 26.9636 35.6234 31.7406 32.4335C36.5177 29.2436 41.2964 28.0162 42.4143 29.692C43.5322 31.3678 40.5659 35.3122 35.7889 38.5021C31.0119 41.6921 26.233 42.9195 25.1151 41.2437Z"
              fill="#CB4E3F" />
            <path
              d="M27.0458 42.4693C27.7787 42.4516 28.5066 42.3441 29.2134 42.1495C31.6654 41.4426 33.9866 40.3422 36.0863 38.8912C40.4817 35.9528 44.3477 31.6251 42.8492 29.3763C42.1699 28.3769 40.6115 28.137 38.3439 28.7167C35.8924 29.4215 33.5737 30.5258 31.4811 31.985C29.3338 33.3636 27.4242 35.0816 25.8269 37.0722C24.4184 38.9512 24.0288 40.4804 24.7081 41.4898C24.9836 41.8371 25.3424 42.109 25.7511 42.2802C26.1598 42.4515 26.6052 42.5165 27.0458 42.4693V42.4693ZM40.4917 29.4064C40.767 29.3699 41.0471 29.3989 41.3092 29.4909C41.5713 29.5829 41.8079 29.7354 42.0001 29.936C42.8193 31.1754 40.3618 34.8135 35.5069 38.0517C33.5049 39.4466 31.2877 40.5033 28.9437 41.18C27.2055 41.6297 25.9469 41.5398 25.5173 40.9401C25.1177 40.3305 25.5173 39.1112 26.6062 37.6719C28.1328 35.7707 29.9581 34.1301 32.0105 32.8145C34.0171 31.4275 36.2329 30.3713 38.5736 29.6862C39.1988 29.5133 39.8432 29.4193 40.4917 29.4064V29.4064Z"
              fill="black" />
            <path d="M14.4308 25.9985L19.1377 24.583L20.5525 29.2923L15.8456 30.7078L14.4308 25.9985Z" fill="white" />
            <path
              d="M15.6074 31.4053L21.2715 29.6962L19.5633 24.0293L13.8992 25.7384L15.6074 31.4053ZM20.0228 29.0266L16.2767 30.156L15.1479 26.408L18.894 25.2786L20.0228 29.0266Z"
              fill="black" />
            <path
              d="M16.5162 15.3739L20.6719 12.9751L18.2744 8.81738L14.1087 11.2161L16.5162 15.3739ZM19.3033 12.6053L16.8758 14.0145L15.4772 11.5859L17.9047 10.1766L19.3033 12.6053Z"
              fill="black" />
            <path d="M29.9721 10.6866L33.4746 11.9541L32.2077 15.4584L28.7052 14.1909L29.9721 10.6866Z" fill="white" />
            <path
              d="M32.1602 16.933L33.7686 12.4854L29.3231 10.8862L27.7148 15.3238L32.1602 16.933ZM32.4899 13.0851L31.5609 15.6537L28.9935 14.7242L29.9226 12.1656L32.4899 13.0851Z"
              fill="black" />
            <path d="M22.8294 17.8775L25.959 19.4812L24.3561 22.6125L21.2264 21.0088L22.8294 17.8775Z" fill="#6764CB" />
            <path
              d="M24.5075 23.1798L26.5654 19.1819L22.5696 17.123L20.5117 21.1209L24.5075 23.1798ZM25.2167 19.5917L24.068 21.8305L21.8303 20.6811L22.9791 18.4423L25.2167 19.5917Z"
              fill="black" />
            <g clip-path="url(#clip1_2879_10128)">
              <path d="M8.09691 1.9609L11.7217 3.16455L10.5187 6.79109L6.89388 5.58743L8.09691 1.9609Z"
                fill="#B1A9BA" />
              <path
                d="M10.9696 7.27137L12.4734 2.71311L7.91744 1.20868L6.41373 5.76694L10.9696 7.27137ZM11.2062 3.34904L10.3407 6.01108L7.67338 5.13767L8.55305 2.47644L11.2062 3.34904Z"
                fill="black" />
            </g>
            <path d="M27.7942 32.7446H31.3105V36.2627H27.7942V32.7446Z" fill="white" />
            <path d="M27.2952 36.7725H31.8105V32.2449H27.2952V36.7725ZM30.8116 35.773H28.2942V33.2443H30.8116V35.773Z"
              fill="black" />
            <path d="M46.1091 9.76939L49.5811 10.3264L49.0243 13.8002L45.5523 13.2431L46.1091 9.76939Z"
              fill="#6764CB" />
            <path
              d="M49.4822 14.3745L50.1914 9.91691L45.7361 9.19727L45.0169 13.6649L49.4822 14.3745ZM49.0526 10.7465L48.6531 13.2351L46.1657 12.8353L46.5652 10.3467L49.0526 10.7465Z"
              fill="black" />
            <path d="M28.9231 23.8794H32.4395V27.3975H28.9231V23.8794Z" fill="#FFC400" />
            <path d="M28.4243 27.8972H32.9395V23.3696H28.4243V27.8972ZM31.9405 26.8977H29.4232V24.3691H31.9405V26.8977Z"
              fill="black" />
            <path d="M6.96109 16.9572L10.9307 20.417L7.47271 24.3885L3.50314 20.9288L6.96109 16.9572Z" fill="#22B573" />
            <path
              d="M7.52613 24.9888L11.6318 20.2613L6.91678 16.1436L2.80108 20.8711L7.52613 24.9888ZM10.2233 20.3613L7.42626 23.5796L4.20965 20.7711L7.00666 17.5528L10.2233 20.3613Z"
              fill="black" />
            <path d="M41.3313 19.6353L44.4717 17.8213L46.2848 20.9633L43.1444 22.7773L41.3313 19.6353Z"
              fill="#FF7569" />
            <path
              d="M42.969 23.4597L46.9648 21.1409L44.6573 17.1431L40.6615 19.4618L42.969 23.4597ZM45.6063 20.7811L43.3386 22.0904L42.0201 19.8117L44.2977 18.5023L45.6063 20.7811Z"
              fill="black" />
            <path d="M22.0216 3.52984L24.79 1.93066L26.3884 4.70046L23.6199 6.29964L22.0216 3.52984Z" fill="#FF7569" />
            <path
              d="M23.4292 6.98836L27.0654 4.87951L24.9675 1.24146L21.3313 3.35031L23.4292 6.98836ZM25.7067 4.51966L23.7988 5.6191L22.6999 3.7101L24.6079 2.61071L25.7067 4.51966Z"
              fill="black" />
            <path
              d="M38.2035 26.6379H38.3133C38.4439 26.6241 38.5705 26.5848 38.6859 26.5221C38.8012 26.4594 38.9032 26.3746 38.9857 26.2725C39.0683 26.1704 39.1299 26.053 39.1671 25.9271C39.2043 25.8011 39.2163 25.6691 39.2024 25.5385L36.735 1.86122C36.7072 1.59614 36.5752 1.353 36.3682 1.18525C36.1612 1.01749 35.8961 0.938845 35.6312 0.966677C35.3663 0.99451 35.1232 1.12649 34.9555 1.33361C34.7879 1.54072 34.7093 1.80605 34.7371 2.07112L37.2145 25.7783C37.2482 26.0168 37.3668 26.235 37.5485 26.3929C37.7302 26.5509 37.9628 26.6378 38.2035 26.6379Z"
              fill="#FFC400" />
            <path
              d="M20.7621 37.2621C20.8933 37.2766 21.0261 37.2651 21.1528 37.2282C21.2795 37.1913 21.3977 37.1298 21.5007 37.0472C21.7087 36.8804 21.8418 36.6377 21.8709 36.3726C21.9001 36.1075 21.8228 35.8417 21.6561 35.6337C21.4893 35.4256 21.2468 35.2924 20.9818 35.2632L1.34242 30.8856C1.21382 30.8561 1.08064 30.8524 0.950613 30.8747C0.820583 30.897 0.696237 30.9448 0.584784 31.0154C0.473332 31.0861 0.376985 31.1781 0.301267 31.2862C0.225548 31.3943 0.171924 31.5163 0.143594 31.6452C0.0870662 31.9036 0.135299 32.1739 0.27761 32.3968C0.41992 32.6198 0.64471 32.7772 0.902812 32.8345L20.5124 37.2322C20.5936 37.2548 20.6778 37.2649 20.7621 37.2621Z"
              fill="#FFC400" />
          </g>
          <defs>
            <clipPath id="clip0_2879_10128">
              <rect width="50.5573" height="59.0382" fill="white" transform="matrix(-1 0 0 1 50.6807 0.96167)" />
            </clipPath>
            <clipPath id="clip1_2879_10128">
              <rect width="6.55463" height="6.555" fill="white"
                transform="matrix(-0.74586 0.666103 0.665729 0.746194 9.70605 -0.388672)" />
            </clipPath>
          </defs>
        </svg>
      </div>
    </div>

    <div class="card-tranfer-child-care-border">
      <div class="border-left"></div>
      <span class="co-icon">
        <svg width="24" height="12" viewBox="0 0 24 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M23.3707 0.499756H14.7821C13.4355 0.499756 7.02163 4.22392 6.65255 6.3182C6.28348 8.41247 10.9219 13.3971 14.5029 10.7247C17.1786 8.93775 20.1882 7.65131 23.3707 6.93419V0.499756Z"
            fill="#FFBEB1" stroke="black" stroke-linejoin="round" />
          <path
            d="M0.907227 0.499756H9.50568C10.8423 0.499756 17.2563 4.22392 17.6254 6.3182C17.9944 8.41247 13.3561 13.3971 9.77503 10.7247C7.10024 8.93613 4.09032 7.64955 0.907227 6.93419V0.499756Z"
            fill="#FFBEB1" stroke="black" stroke-linejoin="round" />
        </svg>

      </span>
      <div class="border-right"></div>
    </div>
  </div>
</template>

<script>

export default {
  components: {},
  props: {
    user: {
      type: Object,
      require: true
    }
  }
}
</script>
<style scoped lang="sass">
  .card-tranfer-child-care
    position: relative
    width: 100%
    margin: auto
    box-shadow: 4px 1px 12px 1px #F2EFDE, -8px -8px 5px 2px rgba(253, 247, 232, 0.8)
    background: #FFFFFF
    margin-bottom: 24px

    .card-tranfer-child-care-border
      display: flex
      line-height: 0
      z-index: 0
      .border-left
        width: 50%
        line-height: 11px
        height: 6.5px
        background: #22B573
        border: 1px solid #000000
        border-right: none
      .border-right
        width: 50%
        line-height: 11px
        height: 6.5px
        background: #FF7569
        border: 1px solid #000000
        border-left: none

    .co-title
      text-align: center
      padding-top: 15px
      padding-bottom: 10px
      font-weight: 700
      font-size: 14px

    .co-info
      display: grid
      grid-template-columns: auto auto auto
      margin-bottom: -20px
      .co-icon-congratulations-left
        z-index: 2
        padding-left: 20px
        svg
          margin-bottom: -50px
      .co-icon-congratulations-right
        display: flex
        justify-content: flex-end
        padding-right: 20px
        z-index: 2
        margin-top: 8px

      .co-container
        display: flex
        justify-content: center
        .avatar
          padding-bottom: 25px
          display: flex
          justify-content: flex-end
          padding-right: 10px
          img
            width: 52px
            height: 52px
            border-radius: 50%
        .name
          align-items: center
          display: flex
          padding-bottom: 20px
</style>
