<template>
  <div class="not-found">
    <div class="icon">
      <span>
        <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M36.3164 29.5678C42.9438 29.5678 48.3163 24.1953 48.3163 17.5679C48.3163 10.9405 42.9438 5.5679 36.3164 5.5679C29.689 5.5679 24.3164 10.9405 24.3164 17.5679C24.3164 24.1953 29.689 29.5678 36.3164 29.5678Z" stroke="#CCCCCC" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M40.5946 67.2885C39.2408 67.3229 37.8183 67.3458 36.3154 67.3458C15.2065 67.3458 9 63.8124 9 59.7627C9 55.713 19.325 38.7455 36.3154 38.7455C39.5965 38.7455 42.6137 39.3765 45.3785 40.4205" stroke="#CCCCCC" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M43.4746 54.0952L51.1381 59.7739L63.0004 42.7835" stroke="#CCCCCC" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </span>
    </div>
    <div class="content">
      <span>
        {{content}}
      </span>
      <br>
      <span>{{subContent}}</span>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  components: { },
  props: {
    content: {
      type: String,
      required: true
    },
    subContent: {
      type: String,
      require: String
    },
    icon: {
      type: String,
      require: String
    }
  },
  data () {
    return {}
  },
  computed: {
  },
  mounted () {

  },
  methods: {

  }
}
</script>
<style scoped lang="sass">
  .not-found
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    height: 50vh
    .content
      font-weight: 300
      font-size: 15px
      color: #424242
      align-items: center
      text-align: center

    .icon
      widht: auto
      height: auto
      margin-bottom: 40px

      svg
        fill: #f8f7f2
        width: 72px
        height: 72px

</style>
