<template>
  <div class="base-select-nickname">
    <div class="header text-15 is-mt-2 is-mb-2">
      ニックネームを使用したい
      <BaseSwitchInput :onChange="toggleSwitch" :value=isDisplayed :name="checkUseName"></BaseSwitchInput>
    </div>
    <div class="nickname" v-show="isDisplayed">
      <BaseTextInput label='' :name="name" placeholder="ニックネーム" :value=inputNickname :isMarginless=true :isMyCommuDomain="isMyCommuDomain"></BaseTextInput>
      <p v-if="hasNote">{{ note }}</p>
    </div>
    <div class="smallnote">参加表示や投稿・コメント等にニックネームが使用されますが、</div>
    <div class="smallnote">シェアリング成立後やメッセージではお名前が表示されます。</div>
  </div>
</template>

<script>
import BaseSwitchInput from 'components/BaseSwitchInput'
import BaseTextInput from 'components/BaseTextInput'

export default {
  components: { BaseSwitchInput, BaseTextInput },
  props: {
    nicknameSelected: {
      type: Boolean,
      default: false
    },
    nickname: {
      type: String,
      default: ''
    },
    isMyCommuDomain: {
      type: Boolean,
      default: false
    },
    hasNote: {
      type: Boolean,
      default: true
    },
    note: {
      type: String,
      default: 'ニックネームを使用すると「親しい友人」以外の方には本名が表示されません'
    },
    name: {
      type: String,
      default: 'profile[nickname]'
    },
    checkUseName: {
      type: String,
      default: 'profile[is_use_nickname]'
    }
  },
  data () {
    return { isDisplayed: this.nicknameSelected, inputNickname: this.nickname }
  },
  methods: {
    toggleSwitch () {
      this.isDisplayed = !this.isDisplayed
    }
  }
}
</script>
<style scoped lang="sass">
  .smallnote
    font-size: 10px
    color: #2DBA87
  .base-select-nickname
    .header
      display: flex
      justify-content: space-between
      align-items: center
    .nickname
      font-size: 12px
</style>
