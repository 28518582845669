<template>
  <div class="profile-card">
    <div v-if="supporter && myself" class="header-supporter">
      わからない事は何もない。地域のマイスター！
    </div>
    <div class="set-border"
         :class="{'set-border-support': supporter && myself}"
    >
      <div class="card-avatar" style="padding-bottom: 12px;">
        <div class="avatar">
          <img :src="profile.images">
          <img v-if="supporter" src="../../../images/icons/supporter.svg" alt="" class="icon-suporter">
        </div>
        <div class="info">
          <p class="name f-size-15 color-base">
            {{getName}}
          </p>
          <p v-if="supporter" class="name f-size-12 color-base">シェア・コンシェルジュ</p>
          <!-- <p v-if="myself" class="birth-day f-size-12 color-gray mt-4">
            {{sex_and_birth}}
          </p> -->
          <p class="f-size-12 color-base mt-4">写真は全体に公開</p>
          <div class="f-size-12 pb-10">
            友達
            <a class="green-link" :href="friendListUrl">{{ profile.numberOfFriends }}</a>
            フォロワー
            <a class="green-link" :href="followersListUrl">{{ profile.numberOfFollowers }}</a>
            フォロー中
            <a class="green-link" :href="followingListUrl">{{ profile.numberOfFollowing }}</a>
          </div>
        </div>
      </div>
      <div v-if="profile.admin || lv_max" class="armorial">
        <div v-if="profile.admin" class="admin">
          <img src="../../../images/icons/administrator.svg">
          <span class="f-size-12 color-base">管理者</span>
        </div>
        <div v-if="lv_max" class=" admin lv_3">
          <img src="../../../images/icons/best_friend.svg">
          <span class="f-size-12 color-base">送迎・託児OKの友達</span>
        </div>
      </div>
      <div v-if="!myself" class="mt-18 introduction">
        <p class="f-size-14">{{profile.introduction}}</p>
      </div>
      
    </div>
  </div>

</template>

<script>
export default {
  data () {
    return {
      friendListUrl: `/communities/${this.currentCommunity}/myfriend`,
      followersListUrl : `/communities/${this.currentCommunity}/follows/followers`,
      followingListUrl : `/communities/${this.currentCommunity}/follows/following`
    }
  },
  components: {
  },
  props: {
    profile: {
      type: Object,
      require: true
    },
    topicCount: {
      type: Number,
      default: 0
    },
    likeCount: {
      type: Number,
      default: 0
    },
    joinEventCount: {
      type: Number,
      default: 0
    },
    joinGroupCount: {
      type: Number,
      default: 0
    },
    supporter: {
      type: Boolean,
      default: false
    },
    myself: {
      type: Boolean,
      default: false
    },
    lv_max: {
      type: Boolean,
      default: false
    },
    joinedEventCount: {
      type: Number,
      default: 0
    },
    createEventCount: {
      type: Number,
      default: 0
    },
    countSend: {
      type: Number,
      default: 0
    },
    countReceives: {
      type: Number,
      default: 0
    },
    currentCommunity: {
      type: Number,
      required: true
    }
  },
  computed: {
    getName () {
      this.profile.fullName
    },
    sex_and_birth () {
      return this.profile.sex + ' ' + this.profile.dateOfBirth
    }
  },
  created () {
  },
  methods: {
  }
}
</script>
<style scoped lang="sass">
  .profile-card
    padding: 0px 16px
  .pb-10
    padding-bottom: 15px
  .green-link
    color: #2DBA87
  .f-size-16
    font-size: 16px
  .f-size-15
    font-size: 15px
  .f-size-12
    font-size: 12px
  .f-size-14
    font-size: 14px
  .color-gray
    color: #7C7C7C
  .color-base
    color: #273D35
  .font-weight-bold
    font-weight: bold
  .mt-16
    margin-top: 16px
  .mt-18
    margin-top: 18px
  .mt-4
    margin-top: 4px
  .header-supporter
    background: #2DBA87
    border-radius: 12px 12px 0px 0px
    color: #FFFFFF
    font-size: 12px
    justify-content: center
    height: 44px
    display: flex
    align-items: center
  .set-border
    background: #FFFFFF
    border: 1px solid #CCCCCC
    border-radius: 12px
    .card-avatar
      display: flex
      align-items: center
      padding: 20px 0px 0px 20px
      .avatar
        position: relative
        img:first-child
          width: 64px
          height: 64px
          object-fit: cover
          border-radius: 50%
          flex-shrink: 0
        .icon-suporter
          position: absolute
          top: 47px
          left: 50%
          transform: translateX(-50%)
      .info
        margin-left: 16px
    .armorial
      display: flex
      padding-left: 20px
      margin-top: 16px
      .admin
        display: flex
        align-items: center
        img
          margin-right: 6px
      .lv_3
        margin-left: 19px
    .introduction
      padding: 0 20px
      word-break: break-word
      
    .share
      ul
        width: 100%
        display: flex
        border-bottom: 1px solid #cccccc
        li
          width: 25%
          height: 87px
          display: flex
          flex-direction: column
          justify-content: center
          align-items: center
          &:first-child
            width: 50%
            flex-direction: row
            justify-content: start
            margin-left: 20px
    .base-block
      padding: 0 20px
      display: flex
      align-items: center
      justify-content: space-between
      height: 55px
      border-bottom: 1px solid #cccccc
    .home-town
      .detail
        display: flex
        p
          margin-right: 8px
    .interested
      padding: 20px 20px 24px 20px
      .label-interested
        display: flex
        flex-wrap: wrap
        /deep/ .base-interested
          margin-top: 8px
  .set-border-support
    border-top: unset
    border-radius: 0px 0 12px 12px
</style>
