<template>
  <div>
    <HiddenHeader v-if="!myself"/>
    <div class="topbar" v-if="!myself">
      <div class="topbar-icon-back" @click="back()">
        <svg width="21" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M16.0697 3.8501L7.92969 12.0001L16.0697 20.1501" stroke="#2DBA87" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
          </path>
        </svg>
      </div>
      <div class="topbar-title">
        <span>{{ getName + 'さん' }}</span>
      </div>
      <div>
        <b-dropdown aria-role="list" can-close="true" class="action-chat">
          <template #trigger>
            <img src="../../images/icons/three_dots.png" alt="" width="24">
          </template>
          <b-dropdown-item aria-role="listitem" @click="redirectToReport()">
            <span class="icon">
              <img src="../../images/icons/report.svg">
            </span>
            <p>通報する</p>
          </b-dropdown-item>
          <b-dropdown-item v-if="!isBlock" aria-role="listitem" class="dropdown-last" @click="blockUser()">
            <span class="icon">
              <img src="../../images/icons/block_chat.svg">
            </span>
            <p>ブロックする</p>
          </b-dropdown-item>
          <b-dropdown-item v-else aria-role="listitem" class="dropdown-last" @click="unblockUser()">
            <span class="icon">
              <img src="../../images/icons/block_chat.svg">
            </span>
            <p>ブロックを解除する</p>
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
    <div
      class="card-profile"
      :class="{'set-pd-t': !myself}"
    >
      <MyCommuHiddenButtonFooter :is-my-commu-domain="true"/>
      <div v-if="!myself" class="action">
        <MyCommuBaseButton
          class="mr-7 add-friend"
          :icon="lvFriendship === 3 ? circleCheckBlack : ''"
          :custom-class="lvFriendship === 3 ? colorBlack : ''"
          :is-white-button="isWhiteButton"
          :text-content="titleButtonFriend"
          @clickButton="openOtpModal"
        />
        <MyCommuBaseButton
          :icon="isFollower ? circleCheckBlack : ''"
          :custom-class="isFollower ? colorBlack : ''"
          class="message"
          :is-white-button="isWhiteButton"
          :text-content="isFollower ? 'フォロー中' : 'フォローする'"
          @clickButton="follow"
        />
      </div>
      <MyCommuBaseButton
          v-if="(!myself && (lvFriendship === 3) || isFollower) && !isBlock"
          class="message mt-8"
          :is-white-button="isWhiteButton"
          text-content="メッセージを送る"
          @clickButton="message"
      />
      <MyCommuBaseButton
          v-else-if="(!myself && (lvFriendship === 3) || isFollower) && isBlock"
          class="message mt-8"
          :custom-class="colorBlack"
          :is-white-button="isWhiteButton"
          text-content="ブロック中の相手にはメッセージを送れません"
      />
      <div v-if="!myself && lvFriendship !== 3" @click="questionModal = true" class="question mt-16">
        <img src="../../images/icons/question-green.png" width="24" height="24" class="mr-8">
        <span>友達・フォローについて</span>
      </div>
      <div class="info-profile" :class="{'mt-24': !myself}"
      >
        <MyCommuInformation
          :profile="profile"
          :topic-count="topicCount"
          :like-count="likeCount"
          :join-event-count="joinEventCount"
          :join-group-count="joinGroupCount"
          :supporter="supporter"
          :lv_max="isLevelMax"
          :myself="myself"
          :joined-event-count="joinEventCount"
          :create-event-count="createEventCount"
          :count-send="countSend"
          :count-receives="countReceives"
        />
        <MyCommuBaseButton
          v-if="myself"
          class="mt-16"
          :is-white-button="isWhiteButton"
          text-content="マイページを編集する"
          @clickButton="redirectToEdit"
        />
      </div>
      <div class="info-my-self">
        <p class="f-size-14 color-base font-weight-bold">自己紹介</p>
        <p class="f-size-12 color-gray">各コミュニティ毎に自己紹介文をわけられます</p>
        <MyCommuIntroductions
          v-if="check_present(communityIntroductions)"
          class="mt-24"
          :community-introductions="communityIntroductions"
        />
      </div>
      <div v-if="myself || isLevelMax" class="info-children">
        <p class="f-size-14 color-base font-weight-bold">子どもの情報</p>
        <p class="f-size-12 color-gray">送迎・託児OKの友達にだけ表示されます</p>
        <MyCommuUsersChildren
          v-if="check_present(usersChildren)"
          class="mt-16"
          :users-children="usersChildren"
          :myself="myself"
        />
        <MyCommuBaseButton
          v-if="myself"
          class="mt-16"
          :is-white-button="isWhiteButton"
          text-content="子どもを追加する"
          @clickButton="redirect('/communities/child_profiles/new')"
        />
      </div>
      <div class="info-commu">
        <p class="f-size-14 color-base font-weight-bold">参加中のコミュニティ・グループ</p>
        <MyCommuMyCommunities
          v-if="check_present(communities)"
          class="mt-16"
          :communities="communities"
        />
        <MyCommuBaseButton
          v-if="myself"
          class="mt-16 mb-8"
          :is-white-button="isWhiteButton"
          text-content="編集する"
          @clickButton="redirect('/communities')"
        />
      </div>
      <div v-if="supporter && myself" class="qualifications">
        <p class="f-size-14 color-base font-weight-bold">シェアリングの「子育て」にかかわる資格</p>
        <p class="f-size-12 color-gray">以下の資格保有者は1時間単価の報酬に100円上乗せ金額が推</p>
        <p class="f-size-12 color-gray"> 奨されます。</p>
        <p class="f-size-12 color-gray">保育士/幼稚園教諭</p>
        <MyCommuQualifications
          class="mt-16"
          :licences="licences"
        />
        <MyCommuBaseButton
          v-if="myself"
          class="mt-16 mb-8"
          :is-white-button="isWhiteButton"
          text-content="編集する"
          @clickButton="redirect('/communities/profile/licence')"
        />
      </div>
      <MyCommuBaseButton
        v-if="myself"
        class="mt-40 mb-8"
        :is-white-button="isWhiteButton"
        text-content="友達リスト"
        @clickButton="redirect(`/communities/${currentCommunity.community_id}/myfriend`)"
      />
      <MyCommuBaseButton
        v-if="myself"
        class="mt-40 mb-8"
        :is-red-button="isRedButton"
        text-content="ログアウト"
        @clickButton="signOut()"
      />
    </div>
    <b-modal
      :active.sync="titleHeader.isClick"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-label="Example Modal"
      close-button-aria-label="Close"
      aria-modal
      class="preview-modal"
    >
      <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
          <img @click="closePrevew" src="../../images/icons/close.svg">
          <p class="f-size-16 font-weight-bold color-base">マイページプレビュー</p>
          <div class="w-24"></div>
        </header>
        <section class="modal-card-body">
          <MyCommuChatTap
            first-tap="一般ユーザー"
            second-tap="送迎・託児OKの友達"
            @changeTab="getActiveTab"
          />
          <div class="body-profile">
            <p v-if="firstTap" class="mt-24 f-size-15">他の人から見えるあなたのマイページ画面です。</p>
            <p v-else class="mt-24 f-size-15 text-center">
              送迎・託児OKの友達から見えるあなたの
              <br>
              マイページ画面です。
            </p>
            <div class="action mt-24">
              <MyCommuBaseButton
                v-if="firstTap"
                class="mr-7 add-friend"
                :is-white-button="isWhiteButton"
                text-content="友達になる"
              />
              <MyCommuBaseButton
                class="message"
                :class="{'w-100': secondTab}"
                :is-white-button="isWhiteButton"
                text-content="メッセージを送る"
              />
            </div>
            <MyCommuInformation
              :profile="profile"
              :topic-count="topicCount"
              :like-count="likeCount"
              :join-event-count="joinEventCount"
              :join-group-count="joinGroupCount"
              :supporter="supporter"
              :first-tap="firstTap"
              :lv_max="secondTab"
              :joined-event-count="joinEventCount"
              :create-event-count="createEventCount"
              :count-send="countSend"
              :count-receives="countReceives"
              class="mt-24"
            />
            <div v-if="secondTab" class="info-children mt-40">
              <p class="f-size-14 color-base font-weight-bold">子どもの情報</p>
              <MyCommuUsersChildren
                v-if="check_present(usersChildren)"
                class="mt-16"
                :users-children="usersChildren"
                :myself="myself"
                :preview="true"
              />
            </div>
            <div class="info-commu mt-40">
              <p class="f-size-14 color-base font-weight-bold">参加中のコミュニティ・グループ</p>
              <MyCommuMyCommunities
                v-if="check_present(communities)"
                class="mt-16"
                :communities="communities"
              />
            </div>
          </div>
        </section>
      </div>
    </b-modal>
      <b-modal
        :active.sync="isOpenOtpModal"
        has-modal-card
        trap-focus
        :destroy-on-hide="false"
        aria-role="dialog"
        aria-label="Example Modal"
        close-button-aria-label="Close"
        aria-modal
        class="user-modal"
      >
        <div
          class="modal-card"
          style="width: auto"
        >
          <section class="modal-card-body card-add-friend">
            <div class="add-friend">
              <img
                src="../../images/icons/arr_left.svg"
                alt=""
                @click="closeOtpModal"
              >
              <strong><p>電話番号の下4桁を入力する</p></strong>
              <div class="div-fake" />
            </div>
            <div
              v-if="noPhoneText"
              class="set-mb-error error-text-wrapper"
            >相手の電話番号が現在登録されていません。 相手に番号の登録を依頼しますので、登録完 了までお待ち下さい。番号が登録されましたら お知らせいたします。</div >
            <div
              v-if="wrongAuthText"
              class="set-mb-error error-text-wrapper"
            >相手の電話番号下4桁と一致しません</div >
            <div class="form-number-phone">
              <MyCommuOtp
                ref="otpInput"
                input-classes="otp-input"
                :input-classes="this.inputError ? 'input-error' : ''"
                :num-inputs="4"
                :should-auto-focus="true"
                :is-input-num="true"
                @on-complete="handleOnComplete"
                @on-change="handleOnChange"
                :value="this.keyPhone.split('')"
              />
            </div>
            <div class="set-pb-p">友達になるユーザーの電話番号下4桁を入力してください</div >
            <div
              v-if="noPhoneText"
              id="goback"
              onclick="window.history.back()"
            >
              <MyCommuBaseButton
                text-content="閉じる"
                :is-white-button="isWhiteButton"
              />
            </div>
            <div
              v-else
              id="sendData"
              @click="sendFriendRequest"
            >
              <MyCommuBaseButton
                text-content="次へ"
                :disable="!checkDisabled"
              />
            </div>
          </section>
        </div>
      </b-modal>
    <b-modal
      :active.sync="questionModal"
      has-modal-card
      custom-class="question-modal"
      :can-cancel="true"
    >
      <div class="question-content">
        <div class="modal-card" style="width: auto">
          <div class="modal-card-body">
            <div class="text-default">
              マイコミュで、実際に面職のある<br>
              知り合いを見つけたら友達に<br>
              なりましょう。安心・安全のため、<br>
              対面では二次元コード認証、<br>
              非対面では電話番号の下4桁認証<br>
              があります。<br>
              <MyCommuBaseButton
                  class="mt-16 mb-8"
                  text-content="友達になる"
                  @clickButton="openOtpModal"
              />
              フォローすると、投稿の通知が<br>
              きたり、メッセージが送れるように<br>
              なります。
              <MyCommuBaseButton
                class="mt-16 mb-8"
                text-content="フォローする"
                @clickButton="follow"
              />
              <p
                class="cancel mt-24"
                @click="questionModal = false"
              >
                閉じる
              </p>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import axios from '../../utils/axios'
import { commuSuccess, commuError } from 'lib/app-toast'

export default {
  data () {
    return {
      isWhiteButton: true,
      isRedButton: true,
      activeTab: 'first-tab',
      isLevelMax: this.lvFriendship === 3,
      circleCheckBlack: "<svg width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"white\" xmlns=\"http://www.w3.org/2000/svg\"> " +
          "<path d=\"M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z\" stroke=\"#273D35\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/> " +
          "<path d=\"M7.98047 13.16L11.1305 15.5L16.0205 8.5\" stroke=\"#273D35\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/> " +
          "</svg>",
      colorBlack: 'color-black',
      titleButtonFriend: '友達になる',
      questionModal: false,
      keyPhone: '',
      isOpenOtpModal: false,
      inputError: false,
      noPhoneText: false,
      wrongAuthText: false
    }
  },
  props: {
    userId: {
      type: Number,
      default: null
    },
    profile: {
      type: Object,
      require: true,
      default: null
    },
    topicCount: {
      type: Number,
      default: 0
    },
    likeCount: {
      type: Number,
      default: 0
    },
    joinEventCount: {
      type: Number,
      default: 0
    },
    joinGroupCount: {
      type: Number,
      default: 0
    },
    supporter: {
      type: Boolean,
      default: false
    },
    myself: {
      type: Boolean,
      default: true
    },
    communityIntroductions: {
      type: Array,
      require: true
    },
    communities: {
      type: Array,
      default: () => []
    },
    usersChildren: {
      type: Array,
      default: () => []
    },
    lvFriendship: {
      type: Number,
      default: 0
    },
    licences: {
      type: Array,
      default: () => []
    },
    joinedEventCount: {
      type: Number,
      default: 0
    },
    createEventCount: {
      type: Number,
      default: 0
    },
    countSend: {
      type: Number,
      default: 0
    },
    countReceives: {
      type: Number,
      default: 0
    },
    isFollower: {
      type: Boolean,
      default: false
    },
    isBlock: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    ...mapState('mycommu', ['searchUsersResult', 'searched', 'titleHeader', 'hiddenHeader', 'currentCommunity']),
    ...mapState('space', ['unsetMargin', 'unsetPadding']),
    ...mapState('session', ['communityId']),
    getName () {
      return this.profile.nickName || this.profile.fullName
    },
    firstTap () {
      return this.activeTab === 'first-tab'
    },
    secondTab () {
      return this.activeTab === 'second-tab'
    },
    checkDisabled () {
      return this.keyPhone.length === 4
    }
  },
  created () {
    if (this.myself) {
      this.titleHeader.display = true
      this.titleHeader.content = 'マイページ'
      this.titleHeader.rightText = 'プレビューを見る'
      this.hiddenHeader.tabGroup = true
      this.hiddenHeader.searchTop = true
      this.hiddenHeader.searchBottom = true
      this.hiddenHeader.topBar = true
      this.hiddenHeader.mainContainerUnsetMargin = true
      this.hiddenHeader.notificationAndMessage = true
      this.setTitleHeader(this.titleHeader)
      this.setHiddenHeader(this.hiddenHeader)
    }
    this.unsetMargin.mainContainer = true
  },
  mounted () {
    // this.unsetMargin.mainContainer = true
    // this.unsetMargin.appMain = true
    this.unsetPadding.mainContainer = true
    if (this.lvFriendship === 3) {
      this.titleButtonFriend = '友達'
    } else if (this.profile.sentFriendRequest) {
      this.titleButtonFriend = '友達申請中'
    }
    if (this.lvFriendship === 3) {
      this.titleHeader = '友達ユーザー'
    } else if (this.isFollower) {
      this.titleHeader = 'フォロワー'
    }
  },
  methods: {
    ...mapActions('mycommu', [
      'toggleIsFindUser',
      'setTitleHeader',
      'setHiddenHeader'
    ]),
    check_present (array) {
      return array.length > 0
    },
    redirectToReport() {
      window.location.href = 'https://ws.formzu.net/fgen/S77589438/'
    },
    blockUser () {
      axios.put(`/users/${this.profile.userId}/one_to_one_chat/block.json`).then((respone) => {
        window.history.back()
      })
    },
    unblockUser () {
      axios.put(`/users/${this.profile.userId}/one_to_one_chat/unblock.json`).then((respone) => {
        window.location.reload() 
      })
    },
    handleOnComplete (value) {
      this.keyPhone = value
    },
    handleOnChange (value) {
      this.keyPhone = value
    },
    back () {
      window.location.href = document.referrer
    },
    getActiveTab (params) {
      this.activeTab = params
    },
    closePrevew () {
      this.titleHeader.isClick = false
    },
    redirect (url) {
      window.location.href = url
    },
    signOut () {
      axios.delete('/users/sign_out').then(() => {
        window.location.href = '/'
      })
    },
    redirectToEdit () {
      if (this.profile.id) {
        window.location.href = `/communities/${this.currentCommunity.community_id}/community_profiles/${this.profile.id}/edit`
      } else {
        axios.post(`/api/v1/co/communities/${this.currentCommunity.community_id}/join_community`).then((res) => {
          if (res.data.status === 'success') {
            window.location.href = `/communities/${res.data.community_id}/community_profiles/${res.data.community_profile_id}/edit`
          }
        })
      }
    },
    message () {
      window.location.href = `/communities/chats/${this.userId}/check_message`
    },
    openOtpModal () {
      if (this.profile.sentFriendRequest) return
      this.questionModal = false
      this.isOpenOtpModal = true
    },
    closeOtpModal () {
      this.isOpenOtpModal = false
    },
    sendFriendRequest () {
      if (!this.profile.sentFriendRequest) {
        const data = new FormData()
        data.append('friendship[auth_key]', this.keyPhone)
        axios.post(`/communities/notifications/${this.userId}/send_friend_request`, data).then((res) => {
          if (res.data.status === 'success') {
            commuSuccess('友達を申請しました。')
            window.location.reload()
          } else if (res.data.status === 'sented') {
            commuError('フォローが失敗しました。')
            this.isOpenOtpModal = false
            this.inputError = false
            this.isOpenOtpModal = false
            this.wrongAuthText = false
            this.noPhoneText = false
          } else if (res.data.status === 'auth_failed') {
            this.wrongAuthText = true
            this.inputError = true
          } else if (res.data.status === 'receiver_does_not_have_phone_number') {
            this.noPhoneText = true
            this.inputError = true
          }
        })
      }
    },
    follow () {
      const data = new FormData()
      data.append('user_id', this.userId)
      if (!this.isFollower) {
        axios.post(`/communities/${this.communityId}/follows/follow`, data).then((res) => {
          if (res.data) {
            commuSuccess('フォローが成功しました。')
            this.isFollower = !this.isFollower
          } else {
            commuError('フォローが失敗しました。')
          }
          this.questionModal = false
        })
      }
    }
  }
}
</script>
<style scoped lang="sass">
  /deep/
    .input-error
      border: 1px solid #EE5353 !important
  .user-modal
    flex-direction: column
    justify-content: flex-end
    /deep/
      .modal-background
        background: #273D35
        opacity: 0.9
      .modal-card
        height: 65vh
        border-radius: 10px 10px 0px 0px
        .modal-card-body
          .user-avatar
            padding-top: 8px
            display: flex
            padding-bottom: 24px
            border-bottom: 1px solid #CCCCCC
            img
              width: 48px
              height: 48px
              border-radius: 50%
              object-fit: cover
              flex-shrink: 0
              margin-right: 8px
            .card-body
              display: flex
              flex-direction: column
              justify-content: center
          .mail, .friendship, .report, .block-user, .unfriend
            display: flex
            padding: 16px 0
            border-bottom: 1px solid #CCCCCC
            .icon
              margin-right: 18px
          .unfriend
            border-bottom: none
            .block-fake
              width: 24px
              margin-right: 18px
        .card-add-friend
          padding: 27px 32px 32px 32px
          .add-friend
            display: flex
            align-items: center
            justify-content: space-between
            margin-bottom: 37px
            .div-fake
              width: 24px
          .form-number-phone
            display: flex
            justify-content: center
      .modal-close
        display: none !important
  .f-size-12
    font-size: 12px
  .f-size-14
    font-size: 14px
  .f-size-15
    font-size: 15px
  .f-size-16
    font-size: 16px
  .color-gray
    color: #7C7C7C
  .color-base
    color: #273D35
  .font-weight-bold
    font-weight: bold
  .mt-8
    margin-top: 8px
  .mr-8
    margin-right: 8px
  .mt-16
    margin-top: 16px
  .mt-24
    margin-top: 24px
  .mt-40
    margin-top: 40px
  .mb-8
    margin-bottom: 8px
  .mr-7
    margin-right: 7px
  .w-24
    width: 24px
  .text-center
    text-align: center
  .card-profile
    padding: 24px 16px 32px
    .info-my-self, .info-children, .info-commu, .qualifications
      margin-top: 40px
    .action
      display: flex
      .add-friend, .message
        width: calc((100% - 7px) / 2)
  .topbar
    display: flex
    padding: 13px 15px 4px 15px
    background-color: white
    position: fixed
    width: 100%
    z-index: 99
    .topbar-title
      color: #273D35
      font-weight: bold
      font-size: 16px
      text-align: center
      width: 100%
      justify-content: center
    .action-chat
      /deep/
        .dropdown-menu
          min-width: 157px
          width: unset !important
          top: 65px !important
          left: auto !important
          border: 1px solid #AAAAAA
          box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.3)
          border-radius: 8px
          padding: unset
          transform: translate3d(0%, -25%, 0)
          right: 35px !important
          .dropdown-content
            padding: unset
            .dropdown-item
              padding: 1rem 1rem 0rem 1rem
              display: flex
              span
                color: #FFFFFF
              p
                color: #273D35
                margin-left: 10px
                font-weight: 300
                font-size: 14px
                display: flex
                align-items: center
            .dropdown-last
              padding-bottom: 1rem
  .set-pd-t
    padding-top: 78px
  .error-text-wrapper
    border: 1px solid #EE5353
    border-radius: 10px
    background-color: #FFEEF0
    padding: 10px
    color: #EE5353
    text-align: center
  .set-pt-p
    padding-top: 20px
  .set-pb-p
    padding-bottom: 20px
  .set-mb-error
    margin-bottom: 30px
  .question
    display: flex
    align-items: center
    color: #2DBA87
    font-weight: bold
    font-size: 12px
  .question-modal
    /deep/
      .modal-close
        display: none
      .modal-background
        background: #273D35
        opacity: 0.9
    .question-content
      width: 80%
      background: #FFFFFF
      margin: auto
      text-align: center
      font-weight: 300
      font-size: 14px
      color: #273D35
      .cancel
        color: #2DBA87
        font-weight: 600
    .modal-card
      border-radius: 20px
      .content
        text-align: center
        font-size: 14px
        font-weight: 300
      .modal-card-footer
        line-height: 0
        display: inline-flex
        grid-template-columns: auto auto
        .button
          height: 48px
          min-width: 50%
          background: #fff
          color: #2DBA87
          border-radius: 0
          font-size: 15px
          &:focus-visible
            border: none
        .ok
          font-weight: bold
          border-left: none
  .preview-modal
    justify-content: end
    background-color: #D6D9DD
    /deep/
      .modal-background
        background: #000000
        opacity: 0.3
      .modal-card
        height: calc(100vh - 10px)
        max-height: unset !important
        .modal-card-head
          border-top-left-radius: 10px
          border-top-right-radius: 10px
          padding: 23px 16px 13px 16px
          justify-content: space-between
          background-color: #FFFFFF
        .modal-card-body
          background-color: #F8F7F2
          padding: 16px 0px
          .body-profile
            padding: 0 16px
            .action
              display: flex
              .add-friend, .message
                width: calc((100% - 7px) / 2)
              .w-100
                width: 100%
      .modal-close
        display: none !important
</style>
