<template>
  <BMessage
    v-if="hasError"
    :title="title"
    type="is-danger"
    :class="{'is-my-commu': isMyCommuDomain}"
  >
    <p v-if="hasMessage">
      <strong v-text="message"/>
    </p>
    <template v-if="visibleFullMessages">
      <ul
        v-for="(fullMessage, i) in fullMessages"
        :key="i"
      >
        <li v-text="fullMessage" />
      </ul>
    </template>
  </BMessage>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    message: {
      type: String,
      required: true
    },
    fullMessages: {
      type: Array,
      default: () => []
    },
    hideFullMessages: {
      type: Boolean,
      default: false
    },
    isMyCommuDomain: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    hasMessage () {
      return this.message.length > 0
    },
    hasError () {
      return this.fullMessages.length > 0
    },
    visibleFullMessages () {
      return !this.hideFullMessages
    }
  }
}
</script>

<style scoped lang="sass">
  .is-my-commu
    /deep/ .message-body
      border-radius: 10px
      border-width: thin
      padding: 16px 11px
      font-size: 15px
      margin: 0 -16px
      .media-content
        text-align: center
</style>
