<template>
  <div>
    <div class="user-card" :class="checkPosition()">
      <div @click="navigate()" class="user-card-content">
        <div class="avatar-area">
          <img :src="avatarPath" class="avatar-user">
          <img src="../../../images/icons/supporter.svg" alt="" v-if="isSupporter || isOrganizationGroupAdmin" class="supporter">
        </div>
        <div class="card-body">
          <div
            class="user-name"
            :class="{'no-introduction': !checkContent}"
            v-text="name"
          />
          <div
            v-if="checkContent"
            class="content-user"
            v-text="content"
          />
          <div v-if="isAdmin" class="is-admin">
            <img src="../../../images/icons/administrator.svg" alt="">
            <span>管理者</span>
          </div>
        </div>
        <div class="card-icon" :class="{'mr-32px': isInGroup && isRootAdmin}">
          <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.92993 1.8501L10.0699 10.0001L1.92993 18.1501" stroke="#2DBA87" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
        <div class="options" v-if="isInGroup && isRootAdmin">
          <b-dropdown aria-role="list" can-close="true">
            <template #trigger>
              <img src="../../../images/icons/three_dots.png" alt="" width="24">
            </template>
            <b-dropdown-item aria-role="listitem">
              <p v-if="isSupporter || isOrganizationGroupAdmin" @click="changeAdmin(false)">脱退させる</p>
              <p v-else @click="changeAdmin(true)">管理権限を追加</p>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import axios from '../../../utils/axios'

export default {
  components: {
  },
  props: {
    avatarPath: {
      type: String,
      require: true,
      default: ''
    },
    name: {
      type: String,
      require: true,
      default: ''
    },
    content: {
      type: String,
      default: ''
    },
    id: {
      type: Number,
      required: true
    },
    isFirst: {
      type: Boolean,
      default: false
    },
    isLast: {
      type: Boolean,
      default: false
    },
    navigateUrl: {
      type: String,
      default: '#'
    },
    isSupporter: {
      type: Boolean,
      default: false
    },
    isAdmin: {
      type: Boolean,
      default: false
    },
    isOrganizationGroupAdmin: {
      type: Boolean,
      default: false
    },
    isInGroup: {
      type: Boolean,
      default: false
    },
    isRootAdmin: {
      type: Boolean,
      default: false
    },
    organizationGroupId: {
      type: Number,
      default: 0
    }
  },
  computed: {
    ...mapState('session', ['communityId']),
    checkContent () {
      return !!this.content
    }
  },
  created () {
  },
  methods: {
    checkPosition () {
      if (this.isFirst && this.isLast) {
        return 'the-uniq'
      } else if (this.isFirst) {
        return 'the-first'
      } else if (this.isLast) {
        return 'the-last'
      } else {
        return 'the-middle'
      }
    },
    navigate () {
      window.location.href = `/communities/${this.communityId}/users/${this.id}`
    },
    async changeAdmin (status) {
      const form = new FormData()
      form.append('admin', status)
      form.append('user_id', this.id)
      await axios({
        method: 'POST',
        url: `/communities/${this.communityId}/community_groups/${this.organizationGroupId}/change_admin`,
        data: form
      }).then((res) => {
        if (res.data.status) {
          window.location.reload()
        }
      })
    }
  }
}
</script>
<style scoped lang="sass">
.user-card
  border-top: 1px solid #CCCCCC
  align-items: center
  background-color: #FFFFFF
  .user-card-content
    display: flex
    align-items: center
  .avatar-area
    position: relative
    width: 90px
    img.avatar-user
      margin: 16px
      width: 64px
      height: 64px
      border-radius: 50%
      object-fit: cover
      flex-shrink: 0
    img.supporter
      position: absolute
      bottom: 13px
      left: 31px
  .card-body
    display: inline-block
    width: 70%
    word-break: break-word
    .user-name
      font-size: 15px
      line-height: 15px
      color: #273D35
      margin-bottom: 8px
    .content-user
      font-size: 12px
      line-height: 12px
      color: #7C7C7C
      margin-top: 8px
    .no-introduction
      margin-bottom: 0
    .is-admin
      margin-top: 4px
      display: flex
      img
        margin-right: 4px
  .card-icon
    display: flex
    position: absolute
    right: 16px
    &.mr-32px
      margin-right: 32px
.the-first
  border-top: 1px solid #CCCCCC
  border-bottom: 0
.the-last
  border-top: 1px solid #CCCCCC
  border-bottom: 1px solid #CCCCCC
.the-uniq
  border-top: 1px solid #CCCCCC
  border-bottom: 1px solid #CCCCCC
.options
  /deep/
    .dropdown-trigger
      display: flex
      img
        transform: rotate(90deg)
    .is-mobile-modal
      margin-right: unset
    .dropdown.is-mobile-modal
      .dropdown-menu
        position: absolute
        top: 55px !important
        left: -50px !important
        width: unset
        min-width: unset
        .dropdown-content
          border: 1px solid #ccc
  right: 16px
  position: absolute
</style>
