<template>
  <div class="topic-comments columns is-mobile">
    <div class="column is-12">
      <div class="card-detail-comments">
        <div class="card-coments-title">
          <span class="fw-bold">コメント</span>
          <span class="card-coments-icon">
            <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M8.54732 0.873291C7.48686 0.874588 6.44487 1.15082 5.52303 1.67503C4.60119 2.19923 3.83101 2.9535 3.28768 3.8642C2.74435 4.7749 2.44644 5.81091 2.42301 6.87111C2.39958 7.93132 2.65144 8.97948 3.15401 9.91329C3.76735 11.0333 1.49398 12.6666 1.33398 12.9266C1.97242 13.0605 2.63159 13.0615 3.27043 12.9296C3.90926 12.7977 4.5141 12.5357 5.04732 12.16C6.0772 12.855 7.30696 13.1923 8.54732 13.1199C10.1364 13.0675 11.6428 12.3994 12.7483 11.2568C13.8539 10.1142 14.4719 8.58653 14.4719 6.99662C14.4719 5.40672 13.8539 3.87907 12.7483 2.73645C11.6428 1.59383 10.1364 0.92573 8.54732 0.873291Z"
                fill="#D5F1E7" stroke="#2DBA87" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </span>
          <span>{{ commentsCount }}</span>
        </div>
        <div class="comment-warning">
          相手の事を考え丁寧なコメントでご利用ください。不適切な
          表現や言葉遣いなどはアカウント停止の対応を取らせていた
          だく事があります。
        </div>

        <div class="card-coments-contents" v-for="(comment, index) in comments" :key="comment.id">
          <MyCommuCommentCard2
            :comment="comment"
            :is-show-mention="isShowMention"
            :togger-comment-input="replyClick"
            :is-last-comment="index === comments.length - 1"
            :fetch-comments="fetchComments"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Mentionable } from 'vue-mention'
import CommuInput from './Input.vue'
import queryString from 'query-string'
export default {
  components: {
    Mentionable,
    CommuInput
  },
  props: {
    comments: {
      type: Array,
      require: true
    },
    commentsCount: {
      type: Number,
      require: true
    },
    handleKeydown: {
      type: Function,
      require: false
    },
    doCreateChatMessage: {
      type: Function,
      require: false
    },
    doFocusInput: {
      type: Function,
      require: false
    },
    isShowMention: {
      type: Boolean,
      default: true
    },
    isTopic: {
      type: Boolean,
      default: false
    },
    replyClick: {
      type: Function,
      default: () => {}
    },
    fetchComments: {
      type: Function,
      default: () => {}
    }
  },
  computed: {
  },
  mounted () {
  },
  data () {
    return {
      // imageUrl: '',
      // isImageModalActive: false
    }
  },
  updated () {
    const params = queryString.parse(location.search)
    if (params && params.comment === '1') {
      window.scrollTo(0, this.$el.scrollHeight)
    }
  },
  methods: {
    toggerCommentInput (name = null, user_id = null) {
      if (name !== null) {
        const comment = `@${name} `
        const localUsers = [{ 'value': name, 'label': name, 'id': user_id }]

        this.$store.dispatch('groups/setMessage', comment)
        this.$store.dispatch('groups/setLocalUser', localUsers)
        this.doFocusInput()
      }
    },
    // showImage (imgUrl) {
    //   this.imageUrl = imgUrl
    //   this.isImageModalActive = true
    // },
    // commentWriterName (writer) {
    //   if (writer.commuNickname !== null && writer.commuNickname !== '') {
    //     return writer.commuNickname
    //   } else {
    //     return writer.fullName
    //   }
    // }
  }
}
</script>
<style scoped lang="sass">
  @import '~stylesheets/resources'

  $border-color: #e4e4e4
  $bg-gray-color: #fff
  .topic-comments
    .column
      padding-left: 0
      padding-right: 0
    .card-detail-comments
      background: #F8F7F2
      padding: 5px 24px 5px 24px

      .card-coments-title
        color: #273D35
        font-size: 17px
        margin-bottom: 5px
        display: flex
        align-items: center
        .card-coments-icon
          margin-left: 17px
          margin-right: 4px
        .fw-bold
          font-weight: bold
        span:last-child
          font-size: 12px
      .comment-warning
        font-size: 12px
        color: #273D35
        padding-bottom: 3px
        border-bottom: 1px solid #CCCCCC
      .card-coments-contents
        .card-coments-content-element
          border-bottom: 1px solid #CCCCCC
          padding: 24px 0px
          word-wrap: break-word
          &.firts
            color: #273D35
            font-weight: 400
            font-size: 12px
          .images
            margin-top: 16px
            .columns.is-mobile
              flex-wrap: wrap
              .column
                padding-top: 0
                padding-bottom: 0
                &.is-6:nth-child(odd)
                  padding-right: 4px
                &.is-6:nth-child(even)
                  padding-left: 4px
                img
                  height: auto
                  max-width: 100%
                  aspect-ratio: 1 /1
                  object-fit: cover
          .mention-hinglight
            color: red
          .author
            margin-top: 16px
            display: flex
            .author-container
              display: flex
              .avatar
                margin-right: 10px
                img
                  width: 40px
                  height: 40px
                  border-radius: 50%
                  object-fit: cover
                  flex-shrink: 0
              .name
                display: flex
                flex-direction: column
                justify-content: center
                color: #273D35
                font-size: 12px
                font-weight: 300
                .infor-name
                  color: #273D35

                .infor-name-2
                  color: #7C7C7C
            .author-reply
              margin-left: auto
              .button
                color: #2DBA87
                border-radius: 6px
                font-weight: bold
                font-size: 14px
                padding: 9px
                box-shadow: 0 2px 8px #f2efde
                border: unset

    .card-detail-btn-comments
      background: #F8F7F2
      padding: 32px 23px 32px 23px
      display: none
      &.active
        display: block
      .button
        width: 100%
        border-radius: 6px
        background: #FFFFFF
        box-shadow: 0px 2px 8px #F2EFDE
        font-size: 15px
        color: #2DBA87
        padding: 23px 0px

</style>
