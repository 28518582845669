<template>
  <div>
    <MyCommuHiddenButtonFooter
      :is-my-commu-domain="true"
    />
    <MyCommuHiddenFooterMenu
      :is-my-commu-domain="true"
    />
    <HiddenHeader/>
    <div class="topbar">
      <div class="topbar-icon-back" @click="back()">
        <svg width="21" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M16.0697 3.8501L7.92969 12.0001L16.0697 20.1501" stroke="#2DBA87" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
          </path>
        </svg>
      </div>
      <div class="topbar-title">
        <span>{{ staff_candidate.title }}</span>
      </div>
      <div class="width-22" />
    </div>
    <div class="staff-candidate-content">
      <div class="assigned-content-top">
        <div class="event-logo-area">
          <div class="event-logo">
            <img src="../../../images/mycommu-logo.png" alt="">
          </div>
          <div class="name">運営</div>
        </div>
        <div class="date">{{ staff_candidate.createdAt }}</div>
      </div>
      <div class="assigned-content-body" v-html="staff_candidate.body"></div>
    </div>
    <div
      class="list-message"
      id="list-message"
    >
      <div v-for="(message, index) in chatMessages" :key="message.id">

        <div class="bjf-notidate"
             v-if="compareDate(index)"
        >
          <span>{{formatDate(message.createAt)}}</span>
        </div>

        <MyCommuChatsGuestMessage
          v-if="!formHost(message)"
          :chat-message="message"
          :can-react="false"
        />
        <MyCommuChatsHostMessage
          v-if="formHost(message)"
          :chat-message="message"
        />
      </div>
    </div>
    <MyCommuInputMessageBox
      class="input-chat-box"
      @submit="doCreateChatMessage"
      @uploadFile="doCreateImageChatMessage"
    />
  </div>
</template>

<script>

import { mapActions, mapState } from 'vuex'
import {format} from "date-fns";

export default {
  components: {
  },
  props: {
    chat: {
      type: Object,
      required: true
    },
    staff_candidate: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      message: '',
      chatMessages: this.chat.chatMessages,
      partner: this.chat.partner
    }
  },
  computed: {
    ...mapState('mycommu', [
      'friends',
      'titleHeader',
      'currentCommunity'
    ]),
    ...mapState('chat', ['currentChat']),
    ...mapState('session', ['currentUser'])
  },
  created () {
    this.initChat(this.chat)
    this.isCommuChatDetail(true)
  },
  watch: {
    chatMessages () {
      this.$nextTick(() => {
        this.scrollBottom()
      })
    }
  },
  mounted () {
    this.scrollBottom()
  },
  methods: {
    ...mapActions('chat', [
      'initChat',
      'commuStaffCreateMessage',
      'commuStaffCreateImageMessage',
    ]),
    ...mapActions('mycommu', [
      'isCommuChatDetail'
    ]),
    doCreateChatMessage (message) {
      const msg = message.trim()
      this.commuStaffCreateMessage(msg)
    },
    formHost (message) {
      return this.currentUser.id === message.writerId
    },
    doCreateImageChatMessage (file) {
      this.commuStaffCreateImageMessage(file)
    },
    scrollBottom () {
      window.scrollTo(0, this.$el.scrollHeight)
    },
    back () {
      window.location.href = `/communities/${this.currentCommunity.community_id}/managements/staff_candidates`
    },
    compareDate(index) {
      if(index === 0){
        return true
      }
      const dest = this.chatMessages[index]?.createAt
      const src = this.chatMessages[index - 1]?.createAt
      const f_dest = format(dest, 'YYYY.MM.DD')
      const f_src = format(src, 'YYYY.MM.DD')
      return f_dest !== f_src
    },
    formatDate(date) {
      return format(date, 'YYYY.MM.DD')
    }
  }
}
</script>

<style scoped lang="sass">
.topbar
  height: 41px
  background: #FFFFFF
  display: grid
  grid-template-columns: auto auto auto
  align-items: center
  .topbar-icon-back
    margin-left: 16px
  .topbar-title
    color: #273D35
    font-weight: 700
    font-size: 16px
    text-align: center
  .topbar-avatar
    margin-left: auto
    margin-right: 23px
    margin-top: 5px
    img
      width: 32px
      height: 32px
      border-radius: 4px
.input-chat-box
  position: fixed
  width: 100%
  bottom: 0
.list-message
  padding-bottom: 110px
.staff-candidate-content
  padding: 16px 16px 24px
  background: white
  font-size: 15px
  color: #273D35
  .assigned-content-top
    display: flex
    font-size: 12px
    align-items: center
    justify-content: space-between
    .event-logo-area
      display: flex
      align-items: center
      .event-logo
        width: 40px
        height: 40px
        border-radius: 50%
        background: white
        display: flex
        img
          border-radius: 50%
          width: 30px
          height: 28px
          margin: auto
      .name
        margin-left: 8px
        font-weight: 300
        color: #273D35
    .date
      font-weight: 400
      color: #AAAAAA
  .assigned-content-body
    margin-top: 16px
    line-height: 20px
  .width-22
    width: 22px
.bjf-notidate
  display: flex
  justify-content: center
  font-weight: 700
  color: #273D35
  font-size: 12px
</style>
