<template>
  <div>
    <div class="event-container" @click="navigate(StaffCandidate.id)">
      <div class="dots" v-if="!StaffCandidate.seen"></div>
      <div class="event-type" :class="{'orange': isNotAnswered}" v-if="isNotAnswered">未回答</div>
      <div class="event-type" :class="{'green': isOwnerConfirmation}" v-if="isOwnerConfirmation">キャスティング確定</div>
      <div class="event-type" :class="{'border-green': isConfirmation}" v-if="isConfirmation">キャスティング待ち</div>
      <div class="event-type" v-if="ownerNonAccept">キャスティングならず</div>
      <div class="event-type" :class="{'border-orange': isUnderConsideration}" v-if="isUnderConsideration">検討中</div>
      <div class="event-type" v-if="isNonParticipation">不参加</div>
      <div class="event-logo">
        <img src="../../../images/mycommu-logo.png" alt="">
      </div>
      <div class="event-content">
        <div class="time">{{ StaffCandidate.updateAt }}</div>
        <div class="event-title active">
          {{ StaffCandidate.title }}
        </div>
        <div class="event-description">{{ StaffCandidate.shortBody }}</div>
      </div>
      <div class="card-icon">
        <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.92993 1.8501L10.0699 10.0001L1.92993 18.1501" stroke="#2DBA87" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    // eslint-disable-next-line vue/prop-name-casing
    StaffCandidate: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState('mycommu', ['currentCommunity']),
    isNotAnswered () {
      return this.StaffCandidate.replyType === 'unreplied'
    },
    isNonParticipation () {
      return this.StaffCandidate.replyType === 'refusal'
    },
    isUnderConsideration () {
      return this.StaffCandidate.replyType === 'arrangement'
    },
    isOwnerConfirmation () {
      return this.StaffCandidate.replyType === 'conditionally_acceptance'
    },
    isConfirmation () {
      return this.StaffCandidate.replyType === 'acceptance'
    },
    ownerNonAccept () {
      return this.StaffCandidate.replyType === 'owner_refusal'
    }
  },
  methods: {
    navigate (StaffCandidateId) {
      if (this.isNotAnswered || this.isUnderConsideration) {
        window.location.href = `/communities/${this.currentCommunity.community_id}/managements/staff_candidates/${StaffCandidateId}`
      } else {
        window.location.href = `/communities/${this.currentCommunity.community_id}/managements/staff_candidates/${StaffCandidateId}/chat`
      }
    }
  }
}
</script>

<style scoped lang="sass">
.event-container
  display: flex
  position: relative
  padding: 16px
  background: white
  border-top: 1px solid #CCCCCC
  border-bottom: 1px solid #CCCCCC
  align-items: center
  .dots
    width: 10px
    height: 10px
    background: #EE5353
    position: absolute
    border-radius: 50%
    left: 10px
    top: 10px
  .event-type
    position: absolute
    right: 8px
    top: 8px
    font-style: normal
    font-weight: bold
    font-size: 12px
    height: 18px
    color: #7C7C7C
    border: 1px solid #7c7c7c
    padding: 1px 8px
    display: flex
    align-items: center
    justify-content: center
    &.orange
      color: white
      background: #F78F54
      border: 1px solid #F78F54
    &.green
      color: white
      background: #2DBA87
      border: 1px solid #2DBA87
    &.border-orange
      color: #F78F54
      border: 1px solid #F78F54
    &.border-green
      color: #2DBA87
      border: 1px solid #2DBA87
  .event-logo
    display: flex
    align-self: flex-start
    img
      width: 30px
      height: auto
      border-radius: 50%
      max-width: unset
  .event-content
    margin-left: 16px
    margin-right: 30px
    .time
      font-style: normal
      font-weight: 300
      font-size: 10px
      line-height: 10px
      color: #7C7C7C
    .event-title
      font-style: normal
      font-weight: 300
      font-size: 15px
      line-height: 15px
      color: #273D35
      margin-top: 8px
      &.active
        font-weight: 700
    .event-description
      margin-top: 8px
      font-style: normal
      font-weight: 300
      font-size: 12px
      color: #273D35
      &.active
        font-weight: 700
    .commu-name
      font-style: normal
      font-weight: 300
      font-size: 12px
      line-height: 12px
      display: flex
      align-items: center
      color: #7C7C7C
      margin-top: 8px
  .card-icon
    display: flex
    right: 16px
    position: absolute

</style>
