<template>
  <div>
    <MyCommuChatTap
      first-tap="通常"
      second-tap="友達リクエスト"
      :normal-notification="isOpenAll ? 0 : unopenedNotificationsCount"
      :special-notification="friendRequests.length"
      @changeTab="getActiveTab"
    />
    <MyCommuChatCommunityTags
      @filterCommu="filterCommu"
    />
    <div class="mt-50" v-if="!fetched">
      <MyCommuLoading />
    </div>
    <MyCommuNotificationNon v-if="isNoNotice && activeTab == 'first-tab' && fetched"/>
    <div
      v-if="activeTab == 'first-tab' && fetched"
    >
      <MyCommuNotificationCard
        v-for="notification in openedNotifications"
        :key="notification.id"
        :avatar-path="notification.notifier.communityAvatarPath"
        :content="notification.body"
        :icon-type="notification.notificationType"
        :link-to="notification.path"
        :time="notification.createdAt"
        :un-opened="isOpenAll ? !isOpenAll : notification.openedAt === null"
        :read-path="notification.readPath"
        :communities-name="communitiesName(notification)"
        :is-using-modal="notification.isUsingModal"
        :friend-or-requested="notification.friendOrRequested"
        :notifier-id="notification.notifier.id"
        :from-profile="notification.fromProfile"
        :communities-ids="communitiesId(notification)"
        @openOtpModal="openOtpModal"
        @openChangeCommuModal="openChangeCommuModal"
      />

      <infinite-loading @infinite="openedNotificationsInfinite">
        <div slot="no-more" />
        <div slot="no-results" />
      </infinite-loading>
    </div>
    <MyCommuNotificationFriendRequest v-if="activeTab == 'second-tab'"/>
    <b-modal
      :active.sync="isOpenOtpModal"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-label="Example Modal"
      close-button-aria-label="Close"
      aria-modal
      class="user-modal"
    >
      <div
        class="modal-card"
        style="width: auto"
      >
        <section class="modal-card-body card-add-friend">
          <div class="add-friend">
            <img
              src="../images/icons/arr_left.svg"
              alt=""
              @click="closeOtpModal"
            >
            <strong><p>電話番号の下4桁を入力する</p></strong>
            <div class="div-fake" />
          </div>
          <div
            v-if="noPhoneText"
            class="set-mb-error error-text-wrapper"
          >相手の電話番号が現在登録されていません。 相手に番号の登録を依頼しますので、登録完 了までお待ち下さい。番号が登録されましたら お知らせいたします。</div >
          <div
            v-if="wrongAuthText"
            class="set-mb-error error-text-wrapper"
          >相手の電話番号下4桁と一致しません</div >
          <div class="form-number-phone">
            <MyCommuOtp
              ref="otpInput"
              input-classes="otp-input"
              :input-classes="this.inputError ? 'input-error' : ''"
              :num-inputs="4"
              :should-auto-focus="true"
              :is-input-num="true"
              @on-complete="handleOnComplete"
              @on-change="handleOnChange"
              :value="this.keyPhone.split('')"
            />
          </div>
          <div class="set-pb-p">友達になるユーザーの電話番号下4桁を入力してください</div >
          <div
            v-if="noPhoneText"
            id="goback"
            onclick="window.history.back()"
          >
            <MyCommuBaseButton
              text-content="閉じる"
              :is-white-button="isWhiteButton"
            />
          </div>
          <div
            v-else
            id="sendData"
            @click="sendFriendRequest"
          >
            <MyCommuBaseButton
              text-content="次へ"
              :disable="!checkDisabled"
            />
          </div>
        </section>
      </div>
    </b-modal>
    <b-modal
      :active.sync="isOpenChangeCommuModal"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-label="Example Modal"
      close-button-aria-label="Close"
      aria-modal
      class="change-commu"
    >
      <div class="modal-card">
        <header class="modal-card-head">
          <p>このコミュニティに移動しますか？</p>
        </header>
        <div class="modal-card-body">
          <div
            class="card-left"
            @click="isOpenChangeCommuModal = false"
          >
            <a href="#">戻る</a>
          </div>
          <div class="card-right">
            <a
              @click="changeCommuToNotifiableCommu()"
            >移動する</a>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal
      :active.sync="titleHeader.isClick"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-label="Example Modal"
      close-button-aria-label="Close"
      aria-modal
      class="change-commu"
    >
      <div class="modal-card">
        <header class="modal-card-head">
          <p>本当にすべて既読にしますか？</p>
        </header>
        <div class="modal-card-body">
          <div
            class="card-left"
            @click="titleHeader.isClick = false"
          >
            <a href="#">キャンセル</a>
          </div>
          <div class="card-right">
            <a
              @click="() => {
                titleHeader.isClick = false
                openAll()
              }"
            >既読にする</a>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>

import { mapActions, mapState } from 'vuex'
import axios from 'axios'
import InfiniteLoading from 'vue-infinite-loading'
import { commuSuccess, commuError } from '../lib/app-toast'

export default {
  components: {
    InfiniteLoading
  },
  props: {
  },
  computed: {
    ...mapState('mycommu', ['titleHeader', 'loading', 'hiddenHeader', 'communities']),
    ...mapState('notification', ['openedNotifications', 'openedNotificationsCount', 'unopenedNotificationsCount', 'fetched', 'friendRequests']),
    hasOneCommunity () {
      return this.communities.length <= 1
    },
    isNoNotice () {
      return this.openedNotificationsCount === 0 && this.unopenedNotificationsCount === 0
    },
    checkDisabled () {
      return this.keyPhone.length === 4
    }
  },
  data () {
    return {
      isNew: true,
      activeTab: 'first-tab',
      keyPhone: '',
      isOpenOtpModal: false,
      inputError: false,
      noPhoneText: false,
      wrongAuthText: false,
      notifierId: '',
      moveToCommunityId: '',
      isOpenChangeCommuModal: false,
      isOpenAll: false,
      linkTo: '',
      readPathWithChangeCommu: ''
    }
  },
  created () {
    this.titleHeader.display = true
    this.titleHeader.content = 'お知らせ'
    this.titleHeader.rightText = 'すべて既読にする'
    this.hiddenHeader.tabGroup = true
    this.hiddenHeader.searchTop = true
    this.hiddenHeader.searchBottom = true
    this.hiddenHeader.topBar = true
    this.hiddenHeader.mainContainerUnsetMargin = true
    this.hiddenHeader.notificationAndMessage = true
    this.setHiddenHeader(this.hiddenHeader)
    this.setTitleHeader(this.titleHeader)
    this.commuFetchNotifications()
    this.commuFetchNotificationCounts()
    this.commuFetchFriendRequests()
  },
  methods: {
    ...mapActions('mycommu', ['setTitleHeader', 'setHiddenHeader']),
    ...mapActions('notification', ['commuFetchNotifications', 'commuFetchNotificationsMore', 'commuFetchNotificationCounts', 'commuFetchFriendRequests']),
    ...mapActions('home', ['changeCommu']),
    getActiveTab (tab) {
      this.activeTab = tab
    },
    communitiesName (notification) {
      var communities = []
      if (notification.sameCommunities != null) {
        notification.sameCommunities.map((community) => {
          communities.push(community ? community.name : '')
        })
        return communities.join(', ')
      }
    },
    communitiesId (notification) {
      var ArrayIds = []
      if (notification.sameCommunities != null) {
        notification.sameCommunities.map((community) => {
          if (community != null){ ArrayIds.push(community.id) }
        })
      }
      var communityIds = Array.from(new Set(ArrayIds))
      return communityIds
    },
    filterCommu (community) {
      if (this.activeTab === 'first-tab') {
        this.commuFetchNotifications(community)
      } else {
        this.commuFetchFriendRequests(community)
      }
    },
    openOtpModal (notifierId) {
      this.isOpenOtpModal = true
      this.notifierId = notifierId
    },
    openChangeCommuModal (communityId, readPathWithChangeCommu, linkTo) {
      this.linkTo = linkTo
      this.readPathWithChangeCommu = readPathWithChangeCommu
      this.isOpenChangeCommuModal = true
      this.moveToCommunityId = communityId
    },
    closeChangeCommuModal () {
      this.isOpenChangeCommuModal = false
    },
    changeCommuToNotifiableCommu () {
      axios.put(`${this.readPathWithChangeCommu}`).then((respone) => {
        this.changeCommu({ params: { id: this.moveToCommunityId }, redirect: true, linkTo: this.linkTo })
      })
    },
    closeOtpModal () {
      this.isOpenOtpModal = false
    },
    sendFriendRequest () {
      const data = new FormData()
      data.append('friendship[auth_key]', this.keyPhone)
      axios.post(`/communities/notifications/${this.notifierId}/send_friend_request`, data).then((res) => {
        if (res.data.status === 'success') {
          commuSuccess('友達を申請しました。')
          window.location.reload()
        } else if (res.data.status === 'sented') {
          commuError('フォローが失敗しました。')
          this.isOpenOtpModal = false
          this.inputError = false
          this.isOpenOtpModal = false
          this.wrongAuthText = false
          this.noPhoneText = false
        } else if (res.data.status === 'auth_failed') {
          this.wrongAuthText = true
          this.inputError = true
        } else if (res.data.status === 'receiver_does_not_have_phone_number') {
          this.noPhoneText = true
          this.inputError = true
        }
      })
    },
    handleOnComplete (value) {
      this.keyPhone = value
    },
    handleOnChange (value) {
      this.keyPhone = value
    },
    async openAll () {
      try {
        await axios.post('/api/v1/all_notifications_open')
        commuSuccess('すべて既読にしました。')
        this.isOpenAll = true
      } catch (e) {
        commuError(e.message)
      }
    },
    async openedNotificationsInfinite ($state) {
      if (await this.commuFetchNotificationsMore()) {
        $state.loaded()
      } else {
        $state.complete()
      }
    }
  }
}
</script>
<style scoped lang="sass">
  .change-commu
    /deep/
      .animation-content
        width: 270px
        margin: auto
        .modal-card
          border-radius: 20px
          .modal-card-head
            background-color: #FFFFFF
            display: flex
            flex-direction: column
            justify-content: center
            align-items: center
            padding: 32px 0
            p
              color: #273D35
              // font-weight: bold
              font-size: 14px
          .modal-card-body
            display: flex
            padding: unset
          .card-left, .card-right
            width: 50%
            padding: 20px 0
            display: flex
            justify-content: center
            a
              font-size: 15px
          .card-left
            border-right: 1px solid #cccccc
            a
              color: #2DBA87
          .card-right
            a
              color: #2DBA87
              font-weight: bold
      .modal-close
        display: none !important
  .set-pb-p
    padding-bottom: 20px
  .set-mb-error
    margin-bottom: 30px
  .error-text-wrapper
    border: 1px solid #EE5353
    border-radius: 10px
    background-color: #FFEEF0
    padding: 10px
    color: #EE5353
    text-align: center
  /deep/
    .input-error
      border: 1px solid #EE5353 !important
  .user-modal
    flex-direction: column
    justify-content: flex-end
    /deep/
      .modal-background
        background: #273D35
        opacity: 0.9
      .modal-card
        height: 65vh
        border-radius: 10px 10px 0px 0px
        .modal-card-body
          .user-avatar
            padding-top: 8px
            display: flex
            padding-bottom: 24px
            border-bottom: 1px solid #CCCCCC
            img
              width: 48px
              height: 48px
              border-radius: 50%
              flex-shrink: 0
              margin-right: 8px
            .card-body
              display: flex
              flex-direction: column
              justify-content: center
          .mail, .friendship, .report, .block-user, .unfriend
            display: flex
            padding: 16px 0
            border-bottom: 1px solid #CCCCCC
            .icon
              margin-right: 18px
          .unfriend
            border-bottom: none
            .block-fake
              width: 24px
              margin-right: 18px
        .card-add-friend
          padding: 27px 32px 32px 32px
          .add-friend
            display: flex
            align-items: center
            justify-content: space-between
            margin-bottom: 37px
            .div-fake
              width: 24px
          .form-number-phone
            display: flex
            justify-content: center
      .modal-close
        display: none !important
  .new-container
    margin-bottom: 16px
    .title-new
      padding: 0 16px 8px
      font-style: normal
      font-weight: 600
      font-size: 14px
      line-height: 14px
      color: #273D35
    .mt-16px
      margin-top: 16px
  .mt-50
    margin-top: 50%
</style>
