<template>
  <div>
    <div class="my-commu-domain card-news">
      <div @click="goToDetail(path)">
        <Slider
          v-if="slider_images.length !== 0"
          :images="slider_images"
        />
      </div>
      <div class="preview-topic">
        <div class="card-container">
          <div class="thumnnail-name">
            <div class="thumnnail-type" @click="goToProfile(topic.writer.id)">
              <img
                :src="topic.writer.communityAvatarPath" loading="lazy"
              />
              <span>{{ writerName }}</span>
            </div>
            <div class="thumnnail-time" @click="goToDetail(path)">
              <span>{{topic.f_created_at}}</span>
            </div>
          </div>
          <div class="raw-body" @click="goToDetail(path)">
            <span v-html="topic.shortBody">
            </span>
            <span v-if="seeMore" class="see-more">もっと見る</span>
          </div>
          <div class="icon-count">
            <div class="count-likes">
              <svg width="16" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.26652 11.9398L1.54651 7.21315C0.858378 6.54912 0.44021 5.65401 0.372516 4.70013C0.304821 3.74625 0.592394 2.80104 1.17985 2.04648C1.51345 1.63931 1.9287 1.30659 2.3988 1.06976C2.86891 0.832928 3.38342 0.697258 3.90918 0.671504C4.43494 0.64575 4.96029 0.730471 5.45129 0.920222C5.94229 1.10997 6.38804 1.40054 6.75984 1.77317L6.9932 2.00648L7.1132 1.88649C7.77723 1.19836 8.67234 0.780193 9.62622 0.712499C10.5801 0.644804 11.5253 0.932377 12.2799 1.51983C12.687 1.85344 13.0198 2.26868 13.2566 2.73878C13.4934 3.20889 13.6291 3.7234 13.6549 4.24916C13.6806 4.77492 13.5959 5.30026 13.4061 5.79125C13.2164 6.28225 12.9258 6.72803 12.5532 7.09983L7.71985 11.9398C7.52639 12.1312 7.2653 12.2385 6.9932 12.2385C6.7211 12.2385 6.45997 12.1312 6.26652 11.9398Z" fill="#d3d3d3"/>
              </svg>
              <span v-if="topic.likesCount > 0">{{topic.likesCount}}</span>
            </div>
            <div class="count-comments">
              <svg width="14" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.54683 0.873047C7.48637 0.874344 6.44438 1.15058 5.52254 1.67478C4.6007 2.19899 3.83052 2.95325 3.28719 3.86395C2.74386 4.77465 2.44596 5.81067 2.42253 6.87087C2.3991 7.93107 2.65096 8.97923 3.15352 9.91305C3.76686 11.033 1.4935 12.6664 1.3335 12.9264C1.97193 13.0602 2.6311 13.0612 3.26994 12.9293C3.90878 12.7974 4.51361 12.5355 5.04683 12.1597C6.07671 12.8548 7.30647 13.192 8.54683 13.1197C10.1359 13.0673 11.6423 12.3992 12.7478 11.2565C13.8534 10.1139 14.4714 8.58628 14.4714 6.99638C14.4714 5.40648 13.8534 3.87883 12.7478 2.73621C11.6423 1.59358 10.1359 0.925486 8.54683 0.873047Z" fill="#d3d3d3"/>
              </svg>
              <span v-if="topic.commentsCount > 0">{{topic.commentsCount}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="comments-actions">
        <a @click="likeTopic">
          <div class="action-items active-heart">
            <span class="action-icon icon-heart">
              <img width="14" height="14" src="../../../images/for_community/heart_in_active_icon.png" v-if="!isLiked">
              <img width="14" height="14" src="../../../images/for_community/heart_active_icon.png" v-if="isLiked">

            </span>
            <span>いいね</span>
          </div>
        </a>
        <div class="action-items active-comment"
          @click="gotoComment"
        >
          <span class="action-icon icon-comment">
            <img width="14" height="14" src="../../../images/for_community/comment_icon.png">

          </span>
          <span>コメント</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Slider from 'components/my-commu/Slider'
import axios from "axios";

export default {
  components: {
    Slider
  },
  props: {
    topic: {
      type: Object,
      required: true
    },
    path: {
      type: String,
      required: true
    },
    topic_group: {
      type: Boolean,
      default: false
    },
    joined: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isLiked: this.topic.liked
    }
  },
  computed: {
    ...mapState('session', ['communityId']),
    slider_images () {
      let images = []
      if (this.topic.images !== undefined) {
        this.topic.images.forEach((e) => {
          images.push(e.original)
        })
        return images
      } else {
        return []
      }
    },
    seeMore () {
      return this.topic.shortBody.length === 100
    },
    currentLiked () {
      return this.topic.liked
    },
    writerName () {
      if (this.topic.writer !== undefined) {
        if (this.topic.writer.commuNickname !== null && this.topic.writer.commuNickname !== '') {
          return this.topic.writer.commuNickname
        } else {
          return this.topic.writer.fullName
        }
      } else {
        return ''
      }
    },
  },
  mounted () {

  },
  methods: {
    likeTopic () {
      if (this.topic_group && !this.joined) {
        this.$emit('confirmJoin', true)
      } else {
        if (!this.isLiked) {
          if (!this.topic_group) {
            axios.post(`${this.topic.communityReactionUrl}`).then((response) => {
              this.topic.likesCount++
              this.isLiked = true
            })
          } else {
            axios.post(`${this.path}/community_group_topic_likes`, {}).then((response) => {
              this.isLiked = true
              this.topic.likesCount++
            })
          }
        } else {
          if (!this.topic_group) {
            axios.post(`${this.topic.communityReactionUrl}`).then((response) => {
              this.topic.likesCount--
              this.isLiked = false
            })
          } else {
            axios.post(`${this.path}/community_group_topic_likes`, {}).then((response) => {
              this.isLiked = false
              this.topic.likesCount--
            })
          }
        }
      }
    },
    gotoComment () {
      if (this.topic_group && !this.joined) {
        this.$emit('confirmJoin', true)
      } else {
        window.location.href = `${this.path}?action=comment`
      }
    },
    goToDetail (url) {
      window.location.href = `${url}`
    },
    goToProfile (id) {
      window.location.href = `/communities/${this.communityId}/users/${id}`
    }
  }
}
</script>
<style scoped lang="sass">
@import '~stylesheets/resources'

.card-news
  position: relative
  width: 92%
  margin: auto
  border-radius: 12px
  background: #FFFFFF
  margin-bottom: 20px
  /deep/
    .slides
      .swiper-container
        border-radius: 12px 12px 0 0
        background-color: #F3F3F3
        .swiper-wrapper
          .swiper-slide
            width: 100% !important
            img
              object-fit: unset
              aspect-ratio: unset
  a
    .image-preview
      width: 100%
      img
        width: 100%
        object-fit: cover
        aspect-ratio: 4/2
        border-radius: 12px 12px 0px 0px
  .preview-topic
    .card-container
      padding-left: 32px
      padding-right: 32px
      padding-bottom: 10px
      padding-top: 10px

      .thumnnail-name
        display: flex
        align-items: center
        margin-bottom: 10px
        .thumnnail-type
          display: flex
          align-items: center
          cursor: pointer
          span
            margin-left: 8px
            color: #273D35
            word-break: break-all
          img
            width: 48px
            height: 48px
            border-radius: 50%
            object-fit: cover
            flex-shrink: 0
            object-fit: cover

        .thumnnail-time
          font-size: 12px
          margin-left: auto
          color: #AAAAAA
      .title
        color: #273D35
        font-size: 16px
      .raw-body
        font-weight: 300
        font-size: 14px
        color: #273D35
        margin-top: 6px
        margin-bottom: 5px
        word-break: break-word
        .see-more
          font-weight: bold
      .card-news-footer
        padding-bottom: 30px
        color: #273D35
        display: flex
        font-weight: 300
        font-size: 12px

        ul
          display: grid
          gap: 30px
          grid-template-columns: auto auto
          grid-auto-rows: 4px
          align-items: center
          width: 100%
          .infor
            display: flex
            text-align: center
            align-items: center

            img
              width: 40px
              height: 40px
              border-radius: 50%
              object-fit: cover
              flex-shrink: 0
              margin-right: 10px
            span
              font-weight: 300
              font-size: 12px
          li
            &:last-child
              justify-content: flex-end
              display: flex
          .status
            background: #F3F3F3

            border: 1px solid #7C7C7C
            justify-content: center
            align-items: center
            padding: 3px 8px

          .price
            color: #273D35
            font-weight: 700
            font-size: 12px
            .price-icon
              display: flex
              align-items: center
              margin-right: 5px
          .pin
            svg
              stroke: #CCCCCC
              fill: none
  .comments-actions
    display: grid
    grid-template-columns: auto auto
    align-items: center
    text-align: center
    background: #ffffff
    color: #7C7C7C
    font-weight: 700
    font-size: 12px
    margin: 0px 2px
    border-top: 1px solid #d3d3d3
    border-radius: 0px 0px 12px 12px
    .action-items
      display: flex
      justify-content: center
      color: #333333
      .action-icon
        margin-right: 5px
        align-items: center
    .action-items:nth-child(1)
      padding: 10px 27px 10px
      border-right: 1px solid white
    .action-items:nth-child(2)
      padding: 10px 27px 10px
    span
      img
        margin-top: 2px
.icon-count
  display: flex
  height: 21px
  svg
    margin-right: 5px
  div
    display: flex
    align-items: center
    margin-right: 15px
    span
      font-size: 13px
      margin-top: 3px
      color: #273D35
</style>
