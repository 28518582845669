<template>
  <div class="base-interested">
    <img :src="iconSrc" :alt="label" class="is-mr-1" v-if="iconSrc != ''">
    <span v-text="label" />
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6 4L10 8L6 12" stroke="#2DBA87" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    iconSrc: {
      type: String,
      default: ''
    }
  },
  data () {
    return { checked: this.value }
  }
}
</script>

<style scoped lang="sass">
  .base-interested
    background: #FFFFFF
    border: 1px solid #EEEEEE
    border-radius: 90px
    display: flex
    align-items: center
    color: #2DBA87
    margin-right: 8px
    font-size: 15px
    padding: 11px 12px
    span
      line-height: revert
      margin: 0 10px 0 5px
</style>
