<template>
  <div>
    <div class="children-component">
      <div
        v-for="(community) in communityIntroductions"
        :key="community.id"
        class="child"
      >
        <div class="first-content">
          <div class="community-name">{{ community.shortNameCommu }}</div>
          <button v-if="community.userId === currentUser.id" class="edit" @click="redirectToEdit(community.communityId, community.id)">編集する</button>
        </div>
        <div class="second-content" v-html="community.shortIntroduction"/>
      </div>
    </div>
  </div>
</template>

<script>

import { mapState } from 'vuex'

export default {
  props: {
    communityIntroductions: {
      type: Array,
      require: true
    }
  },
  computed: {
    ...mapState('session', ['currentUser'])
  },
  methods: {
    redirectToEdit (communityId, id) {
      window.location.href = `/communities/${communityId}/community_profiles/${id}/edit`
    }
  }
}
</script>

<style lang="sass" scoped>
.children-component
  background: #FFFFFF
  border: 1px solid #CCCCCC
  border-radius: 12px
  .child
    padding: 20px 20px
    &:not(:last-child)
      border-bottom: 1px solid #CCCCCC
    .first-content
      display: flex
      align-items: center
      justify-content: space-between
      .community-name
        font-size: 15px
        line-height: 15px
      .edit
        align-items: center
        font-style: normal
        font-weight: bold
        font-size: 14px
        line-height: 14px
        text-align: center
        color: #2DBA87
        padding: 9px
        background: #FFFFFF
        box-shadow: 0 2px 8px #F2EFDE
        border-radius: 6px
        border: 0
    .second-content
      margin-top: 16px
      font-size: 12px
button
  font-family: "Hiragino Kaku Gothic ProN"
</style>
