<template>
  <div>
    <MyCommuHiddenButtonFooter :is-my-commu-domain="true" />
    <MyCommuChatTap
    first-tap="リスト表示"
    second-tap="カレンダー表示"
    @changeTab="getActiveTab"
    />
    <div
    class="schedule-area"
    :class="{'unset-pading-y': activeTab !== 'first-tab'}"
    >

    <!-- <div class="abcdef">{{ nextAble(focusedDateData) }}</div> -->
      <div v-if="activeTab === 'first-tab'">
        <div
          v-if="schedules_today.length > 0"
          class="title-share mb-16px"
        >
          今日の予定
        </div>
        <div
          v-if="Object.keys(schedules_today).length === 0 && Object.keys(schedules_in_future).length == 0"
        >
          予定はありません
        </div>
        <div
          v-for="(array, schedule) in schedules_today"
          >
          <div
            v-for="data in array"
            :key="data.id"
            @click="redirect(data.path, data.sharingType, data.organizationPath)"
          >
            <div>{{ schedule }}</div>
            <MyCommuCardSchedule :schedule="data" />
          </div>
        </div>
        <div class="mt-40px">
          <div
            v-for="(array, schedule, index ) in schedules_in_future"
          >
            <div
              v-for="(data, i) in array"
              :key="data.id"
              class="tomorrow-date"
              @click="redirect(data.path, data.sharingType, data.organizationPath)"
            >
              <div>
                <div
                  v-if="i === 0"
                  class="line-dot"
                />
                <div
                  v-if="Object.keys(schedules_in_future).length - 1 === index"
                  class="invi-dot"
                />
                <div
                  v-if="Object.keys(schedules_in_future).length - 1 !== index"
                  class="line--dot"
                  :class="Object.keys(schedules_in_future).length - 2 === index && i === array.length - 1 && array.length === 1? '' : 'extra-height'"
                />
              </div>
              <div class="ml-10px w-100">
                <div
                  v-if="i === 0"
                >{{ schedule }}</div>
                <MyCommuCardSchedule :schedule="data" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="datepicker datepicker-is-commu datepicker-is-commu--full">
          <header class="datepicker-header">
            <div class="pagination field is-centered">
              <a
                class="pagination-previous"
                role="button"
                href="#"
                :disabled="!this.prevAble(focusedDateData )"
                @click.prevent="prev"
                @keydown.enter.prevent="prev"
                @keydown.space.prevent="prev"
              >
                <BIcon
                  :icon="iconPrev"
                  both
                  type="is-primary is-clickable"
                />
              </a>
              <span>{{ formatDateTitle }}</span>
              <a
                class="pagination-next"
                role="button"
                href="#"
                :disabled="!this.nextAble(focusedDateData)"
                @click.prevent="next"
                @keydown.enter.prevent="next"
                @keydown.space.prevent="next"
              >
                <b-icon
                  :icon="iconNext"
                  both
                  type="is-primary is-clickable"
                />
              </a>
            </div>
          </header>

          <div
            class="datepicker-content"
          >
            <b-datepicker-table
              v-model="computedValue"
              :day-names="dayNames"
              :month-names="monthNames"
              :first-day-of-week="firstDayOfWeek"
              :min-date="minDate"
              :max-date="maxDate"
              :focused="focusedDateData"
              :disabled="disabled"
              :unselectable-dates="unselectableDates"
              :unselectable-days-of-week="unselectableDaysOfWeek"
              :selectable-dates="selectableDates"
              :events="executeSchedulesData(schedules_in_months)"
              :indicators="indicators"
              :date-creator="dateCreator"
              :type-month="true"
              :nearby-month-days="nearbyMonthDays"
              :nearby-selectable-month-days="nearbySelectableMonthDays"
              @input="getInformationDate"
            />
          </div>
        </div>
        <div class="pb-20px">
          <div
            v-for="(schedule) in schedules_chosen_date"
            :key="schedule.id"
            class="tomorrow-date"
            @click="redirect(schedule.path, schedule.sharingType, schedule.organizationPath)"
          >
            <div class="ml-10px w-100">
              <div>{{ schedule.startedAtShort }}</div>
              <MyCommuCardSchedule :schedule="schedule" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import DatepickerTable from '../components/my-commu/DatepickerTable.vue'
import config from 'buefy/src/utils/config'
import { format } from 'date-fns'
import axios from 'axios'

export default {
  components: {
    [DatepickerTable.name]: DatepickerTable
  },
  props: {
    minDate: Date,
    maxDate: Date,
    disabled: Boolean,
    unselectableDates: Array,
    nearbyMonthDays: Boolean,
    nearbySelectableMonthDays: Boolean,
    schedules_today: {
      type: Object,
      require: true
    },
    schedules_chosen_date: {
      type: Array,
      require: true
    },
    unselectableDaysOfWeek: {
      type: Array,
      default: () => {
        return config.defaultUnselectableDaysOfWeek
      }
    },
    selectableDates: Array,
    schedules_in_future: {
      type: Object,
      require: true,
      // default: {}
    },
    indicators: {
      type: String,
      default: 'dots'
    },
    dateCreator: {
      type: Function,
      default: () => {
        if (typeof config.defaultDateCreator === 'function') {
          return config.defaultDateCreator()
        } else {
          return new Date()
        }
      }
    },
    schedules_in_months: {
      type: Array,
      require: true
    },
    dayNames: {
      type: Array,
      default: () => {
        if (Array.isArray(config.defaultDayNames)) {
          return config.defaultDayNames
        } else {
          return ['日', '月', '火', '水', '木', '金', '土']
        }
      }
    },
    firstDayOfWeek: {
      type: Number,
      default: () => {
        if (typeof config.defaultFirstDayOfWeek === 'number') {
          return config.defaultFirstDayOfWeek
        } else {
          return 0
        }
      }
    },
    monthNames: {
      type: Array,
      default: () => {
        if (Array.isArray(config.defaultMonthNames)) {
          return config.defaultMonthNames
        } else {
          return [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December'
          ]
        }
      }
    },
    iconPrev: {
      type: String,
      default: 'chevron-left'
    },
    iconNext: {
      type: String,
      default: 'chevron-right'
    }
  },
  data () {
    const focused = new Date()
    return {
      activeTab: 'first-tab',
      selectedDate: new Date('2023-03-22'),
      computedValue: new Date(),
      focusedDateData: {
        month: focused.getMonth(),
        year: focused.getFullYear()
      }
    }
  },
  created () {
    this.toggleShowChangeCommu(true)
    this.titleHeader.display = true
    this.titleHeader.content = '予定'
    this.hiddenHeader.tabGroup = true
    this.hiddenHeader.unsetMarginBottomChangeCommu = true
    this.hiddenHeader.notificationAndMessage = true
    this.setTitleHeader(this.titleHeader)
    this.setHiddenHeader(this.hiddenHeader)
  },
  computed: {
    ...mapState('space', ['unsetMargin', 'unsetPadding']),
    ...mapState('mycommu', ['hiddenHeader', 'titleHeader']),
    ...mapState('session', ['communityId']),
    showPrev () {
      if (!this.minDate) return false
      if (this.isTypeMonth) {
        return this.focusedDateData.year <= this.minDate.getFullYear()
      }
      const dateToCheck = new Date(this.focusedDateData.year, this.focusedDateData.month)
      const date = new Date(this.minDate.getFullYear(), this.minDate.getMonth())
      return (dateToCheck <= date)
    },

    showNext () {
      if (!this.maxDate) return false
      if (this.isTypeMonth) {
        return this.focusedDateData.year >= this.maxDate.getFullYear()
      }
      const dateToCheck = new Date(this.focusedDateData.year, this.focusedDateData.month)
      const date = new Date(this.maxDate.getFullYear(), this.maxDate.getMonth())
      return (dateToCheck >= date)
    },
    listOfYears () {
      let latestYear = this.focusedDateData.year + this.yearsRange[1]
      if (this.maxDate && this.maxDate.getFullYear() < latestYear) {
        latestYear = this.maxDate.getFullYear()
      }

      let earliestYear = this.focusedDateData.year + this.yearsRange[0]
      if (this.minDate && this.minDate.getFullYear() > earliestYear) {
        earliestYear = this.minDate.getFullYear()
      }

      const arrayOfYears = []
      for (let i = earliestYear; i <= latestYear; i++) {
        arrayOfYears.push(i)
      }

      return arrayOfYears.reverse()
    },
    formatDateTitle () {
      const value = new Date(this.focusedDateData['year'], this.focusedDateData['month'])
      const fValue = format(value, 'YYYY年M月')
      return fValue
    },
  },
  mounted () {
    this.unsetMargin.appMain = true
    this.unsetPadding.mainContainer = true
    this.hiddenHeader.mainContainerUnsetMargin = true
  },
  methods: {
    ...mapActions('mycommu', [
      'setHiddenHeader',
      'setTitleHeader',
      'toggleShowChangeCommu'
    ]),
    getActiveTab (tab) {
      this.activeTab = tab
    },
    executeSchedulesData (schedules) {
      if (schedules) {
        return schedules.map((hash) => {
          return {
            date: new Date(hash.date),
            type: hash.type == 'events' ? 'event-commu' : hash.type
          }
        })
      }
    },
    prev () {
      const estimateData = {
        month: this.focusedDateData.month > 0 ? this.focusedDateData.month - 1 : this.focusedDateData.month = 11,
        year: this.isTypeMonth || this.focusedDateData.month === 0 ? this.focusedDateData.year - 1 : this.focusedDateData.year
      }
      if (this.disabled || !this.prevAble(estimateData)) return

      if (this.isTypeMonth) {
        this.focusedDateData.year -= 1
      } else {
        if (this.focusedDateData.month > 0) {
          this.focusedDateData.month -= 1
        } else {
          this.focusedDateData.month = 11
          this.focusedDateData.year -= 1
        }
      }
    },

    /*
    * Either increment month by 1 if not December or increment year by 1
    * and set month to 0 (January) or increment year when 'month'
    */
    next () {
      const estimateData = {
        month: this.focusedDateData.month < 11 ? this.focusedDateData.month + 1 : this.focusedDateData.month = 0,
        year: this.isTypeMonth || this.focusedDateData.month === 12 ? this.focusedDateData.year + 1 : this.focusedDateData.year
      }
      if (this.disabled || !this.nextAble(estimateData)) return

      if (this.isTypeMonth) {
        this.focusedDateData.year += 1
      } else {
        if (this.focusedDateData.month < 11) {
          this.focusedDateData.month += 1
        } else {
          this.focusedDateData.month = 0
          this.focusedDateData.year += 1
        }
      }
    },
    nextAble (data) {
      if (this.schedules_in_months.length === 0) return false
      const monthArray = []
      const yearArray = []
      this.schedules_in_months.forEach((hash) => {
        monthArray.push(parseInt(Date(hash.date).getMonth))
        yearArray.push(parseInt(Date(hash.date).getFullYear))
      })
      if ((parseInt(data['month']) < Math.max(...monthArray) && parseInt(data['year']) === Math.max(...yearArray)) && parseInt(data['year']) < Math.max(...yearArray)) {
        return true
      } else {
        return false
      }
    },
    prevAble (data) {
      if (this.schedules_in_months.length === 0) return false
      const monthArray = []
      const yearArray = []
      this.schedules_in_months.forEach((hash) => {
        monthArray.push(parseInt(Date(hash.date).getMonth))
        yearArray.push(parseInt(Date(hash.date).getFullYear))
      })
      if ((parseInt(data['month']) > Math.max(...monthArray) && parseInt(data['year']) === Math.max(...yearArray)) || parseInt(data['year']) > Math.max(...yearArray)) {
        return true
      } else {
        return false
      }
    },
    redirect (url, type = false, OrganizationUrl = false) {
      if (type === 'events') {
        window.location.href = OrganizationUrl
      } else {
        window.location.href = url
      }
    },
    getInformationDate (date) {
      const schedule = {
        date: format(date, 'DD/MM/YYYY')
      }
      axios.post(`/communities/${this.communityId}/home/date_information`, (schedule)).then((res) => {
        this.schedules_chosen_date = res.data.data
      })
    }
  }
}
</script>

<style scoped lang="sass">
  body
    font-family: 'Hiragino Kaku Gothic ProN'
  .schedule-area
    padding: 24px 16px
  .title-share
    font-style: normal
    font-weight: 600
    font-size: 14px
    color: #273D35
  .tomorrow-date
    margin: 24px 0 16px 0
    display: flex
  .line-dot
    background: #E2DFCC
    width: 16px
    height: 16px
    border-radius: 50%
  .invi-dot
    background: #f8f7f2
    width: 9px !important
    height: 16px
    border-radius: 50%
  .line--dot
    width: 2px
    background: #E2DFCC
    height: 115%
    margin-left: 7px
  .extra-height
    height: 125%
    .mb-8px
    margin-bottom: 8px
  .mb-16px
    margin-bottom: 8px
  .mt-40px
    margin-top: 40px
  .ml-10px
    margin-left: 10px
  .w-100
    width: 100%
  .unset-pading-y
    padding-left: unset
    padding-right: unset
    padding-top: 0.875rem
  .label-datepicker
    .position
      position: relative
      /deep/
      .dropdown-menu
        width: calc(100% + 1px)!important
        overflow: hidden
        .datepicker-content
          height: unset
        .has-text-danger
          top: 5px
        .close-dropdown
          z-index: 42
          left: 20px
          top: 30px
          &:before
            height: 1px
          &:after
            width: 1px
      .icon-datepicker
        position: absolute
        right: 15px
        bottom: 13px
        width: 1.5rem
        height: 1.5rem
        z-index: 1
        top: 5px
        background: url("../images/icons/datepicker.svg") no-repeat
  .set-z-index
    /deep/
    .background
      z-index: 999
      .dropdown-menu
        z-index: 999 !important
  .time-selector
    display: flex
    align-items: center
    justify-content: center
    margin-bottom: 15px
    vertical-align: bottom
    padding: 10px 40px
    line-height: 1

    > .unit
      font-size: 120%
      margin-top: 1px

      &:last-child
        margin-right: 0

    .iconclock
      font-size: 120%
      margin-right: 0.5em
  .date-dropdown
    > select
      background-color: transparent
      border: none
      font-family: Avenir, sans-serif
      appearance: none
      line-height: 1
      font-size: 18px
      text-align: right
      padding: 0

      > option
        direction: rtl
  .datepicker-is-commu--full
    /deep/
      .datepicker-header
        margin-bottom: unset
      .datepicker-content
        background: #FFFFFF
        height: unset
        .has-events
          .datepicker-cell-event
            padding-top: 16px !important
            padding-bottom: 16px !important
            padding-right: unset !important
            padding-left: unset !important
          .is-selected
            background: unset !important
            border-radius: unset !important
            p
              background: #2DBA87
              border-radius: 20px
              font-weight: 700
              line-height: 10px
              padding: 2px 0
  .pb-20px
    padding-bottom: 20px
</style>
