<template>
  <BCollapse
    :open.sync="isOpen"
    class="community"
    :class="{ 'is-open': isOpen }"
  >
    <div
      slot="trigger"
      class="community-item-wrapper"
      :class="{ 'is-checked': checkedAll }"
    >
      <div
        class="community-item-left"
        @click="onClickStop"
      >
        <BCheckbox
          v-model="checkedAll"
          class="community-checkbox"
          :indeterminate="selectedPartial"
          @input="onChangeAll"
        />
      </div>
      <div class="community-item">
        <p
          class="community-name"
          v-text="name"
        />
        <p
          class="community-address is-size-7"
          v-text="addressAndDistance"
        />
      </div>
      <div class="community-arrow-icon">
        <i
          v-if="isOpen"
          class="fal fa-minus"
        />
        <i
          v-else
          class="fal fa-plus"
        />
      </div>
    </div>
    <div class="group-wrapper">
      <div class="group-inner">
        <div class="group-items">
          <div
            v-for="group in groups"
            :key="group.id"
            class="sharing-candidate-checkbox"
            :id="`select-${group.id}`"
          >
            <BCheckboxButton
              v-model="selectedUsers"
              :native-value="group.id"
              @change.native="onChange"
            >
              {{ group.name }}
            </BCheckboxButton>
          </div>
        </div>
      </div>
    </div>
  </BCollapse>
</template>

<script>
export default {
  props: {
    id: {
      type: Number,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    groups: {
      type: Array,
      required: true
    },
    addressAndDistance: {
      type: String,
      default: null
    },
    isMyCommuDomain: {
      type: Boolean,
      default: false
    },
    checkedCommu: {
      type: Boolean,
      default: false
    },
    eventCommuGroupIds: {
      type: Array,
      default: () => []
    },
    openCommu: {
      type: Boolean,
      default: false
    },
    groupId: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      isOpen: false || this.openCommu,
      selectedUsers: this.eventCommuGroupIds,
      checkedAll: this.checkedCommu
    }
  },
  computed: {
    selectedPartial () {
      return this.selectedUsers.length > 0 && !this.checkedAll
    }
  },
  methods: {
    onClickStop (event) {
      event.stopPropagation()
    },
    onChange (ev) {
      const groupId = parseInt(ev.target.value, 10)
      this.emitSelectionChanged(groupId, ev.target.checked)
    },
    onChangeAll (checked) {
      this.checkedAll = checked
      if (checked) {
        this.selectedUsers = this.groups.map(_ => _.id)
        this.selectedUsers.forEach(_ => this.emitSelectionChanged(_, true))
      }
      this.$emit('change-selected-community', { id: this.id, checked })
    },
    emitSelectionChanged (groupId, checked) {
      this.$emit('change-selected-group', { groupId, checked })
    },
    checkSelectCommuGroup (id) {
      return this.eventCommuGroupIds.includes(id)
    }
  }
}
</script>

<style scoped lang="sass">
@import '~stylesheets/resources'
@import '~stylesheets/components/sharing-candidate-checkbox'

.community.is-open
  border-bottom: 0px solid #d7d7d7
  &:last-child
    border-bottom: none

$left-padding: 104px
$left-padding-sp: 50px
.community
  border-bottom: 1px solid #d7d7d7

.community-item-wrapper
  display: flex
  align-items: center
  justify-content: space-between
  border-bottom: 0px solid $color-border-gray
  padding-top: 8px
  padding-bottom: 8px
  padding-left: $left-padding
  position: relative
  min-height: 72px
  &.is-checked
    background-color: $color-bg-orange
  +app-mobile
    padding-left: $left-padding-sp

.group-items
  /deep/ .group:last-child
    border-bottom: none

.community-item-left
  position: absolute
  top: 0
  left: 0
  width: $left-padding
  height: 100%
  display: flex
  justify-content: space-between
  align-items: center
  cursor: default
  +app-mobile
    width: $left-padding-sp

.community-checkbox.b-checkbox.checkbox
  padding-left: 13px
  width: 40px
  height: 100%
  /deep/ input[type=checkbox] + .check
    background: none
    border: none
    &::before
      font-family: 'Font Awesome 5 Pro'
      content: "\f00c"
      font-weight: bold
      font-size: 18px
      color: $color-border-gray
  /deep/ input[type=checkbox]:checked + .check
    &::before
      color: #2DBA87

.community-icon.face-icon
  display: block
  width: 64px
  /deep/ > .icon
    display: block
  /deep/ > .name
    display: none
  +app-mobile
    width: 48px
    /deep/ > .icon
      height: 3rem
      width: 3rem

.community-item
  flex-grow: 1
  margin-left: 8px
  padding-right: 10px

.community-arrow-icon
  width: 45px
  padding-right: 21px
  i
    font-size: 28px
  +app-mobile
    padding-right: 10px

.community-name
  font-weight: bold
.community-address
  color: #777

.group-wrapper
  display: flex
  justify-content: space-between

.group-bar
  width: 26px
  text-align: center
  padding: 6px 10px
  .bar
    display: block
    background-color: #eeeeee
    width: 6px
    height: 100%
    border-radius: 3px

.group-inner
  flex: 1

.group.low-friend
  border-bottom: 0px solid #d7d7d7
  &:last-child
    border-bottom: none

.section-label
  font-size: 14px
  font-weight: 600
  padding: 10px 14px 6px

/deep/ .button .icon:first-child:not(:last-child)
  margin: 0
/deep/ .b-checkbox.checkbox.button.is-primary
  background-color: $color-bg-orange
  color: inherit
  &::before
    color: #2DBA87

/deep/ .b-checkbox.checkbox.button
  background: #f8f7f3

/deep/ .sharing-candidate-checkbox
  border-bottom: none

/deep/ .collapse-content
  margin-left: 30px
</style>
