import { Toast } from 'buefy/dist/components/toast'
import escape from 'lodash.escape'

const showSuccess = (message) => {
  Toast.open({
    message: escape(message),
    type: 'is-success',
    position: 'is-top',
    duration: 3000,
    queue: false
  })
}

const showError = (message) => {
  Toast.open({
    message: escape(message),
    type: 'is-danger',
    position: 'is-top',
    duration: 5000,
    queue: false
  })
}

const commuSuccess = (message) => {
  Toast.open({
    message: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
      '<path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#5DC9E0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>\n' +
      '<path d="M7.98047 13.16L11.1305 15.5L16.0205 8.5" stroke="#5DC9E0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>\n' +
      '</svg>\n ' +
      '<span class="community-toast-content">' + message.toString() + '</span>',
    type: 'is-primary community-toast-success',
    position: 'is-bottom',
    duration: 5000,
    queue: false,
  })
}

const commuError = (message) => {
  Toast.open({
    message: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
      '<g clip-path="url(#clip0_222_3384)">\n' +
      '<path d="M9.52008 3.52991L1.39007 17.6199C0.290074 19.5199 1.66008 21.8999 4.27008 21.8999H19.74C22.35 21.8999 23.7401 19.5199 22.6201 17.6199L14.4901 3.52991C14.2402 3.09128 13.8786 2.72662 13.4421 2.47296C13.0057 2.2193 12.5099 2.08569 12.0051 2.08569C11.5002 2.08569 11.0044 2.2193 10.5679 2.47296C10.1315 2.72662 9.76998 3.09128 9.52008 3.52991V3.52991Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>\n' +
      '<path d="M12 8.3999V13.2499" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>\n' +
      '<path d="M12.7705 17.1401C12.7705 17.5654 12.4257 17.9101 12.0005 17.9101C11.5752 17.9101 11.2305 17.5654 11.2305 17.1401C11.2305 16.7149 11.5752 16.3701 12.0005 16.3701C12.4257 16.3701 12.7705 16.7149 12.7705 17.1401Z" fill="white" stroke="white"/>\n' +
      '</g>\n' +
      '<defs>\n' +
      '<clipPath id="clip0_222_3384">\n' +
      '<rect width="24" height="24" fill="white"/>\n' +
      '</clipPath>\n' +
      '</defs>\n' +
      '</svg>\n' +
      '<span class="community-toast-content">' + message.toString() + '</span>',
    type: 'is-primary community-toast-error',
    position: 'is-bottom',
    duration: 10000,
    queue: false,
  })
}

export { showSuccess, showError, commuSuccess, commuError }
