<template>
  <div>
    <HiddenHeader/>
    <Back title='新しい交流イベントのリクエスト' :is-flex10="true" :useEmit="true" @back="back"/>
    <div class="content-request-main">
      <div class="content-request">
        <div class="description-request">新しく作りたい交流イベントの内容を書いて、リクエストしてみましょう。</div>
        <div class="title-field">
          <div class="title-content">作成したい交流イベント</div>
          <div class="input-green">
            <textarea v-model="eventInformation" placeholder="カメラが趣味な方々であつまるイベントを開催したい" rows="6"></textarea>
          </div>
        </div>
      </div>
      <MyCommuBaseButton
        class="button-create"
        text-content="リクエストする"
        @clickButton="submit()"
      />
    </div>
    <BModal
      :active.sync="isComponentModalActive"
      has-modal-card
      :can-cancel="true"
      :on-cancel="redirectList"
      width="70%"
    >
      <div class="modal-card" style="width: auto">
        <div class="modal-card-body">
          <img src="../../images/for_community/circle-check.png">
          <p class="text-default">
            リクエストが完了しました。
            <br>
            2営業日以内に事務局からご
            <br>
            連絡いたしますので、それま
            <br>
            で少々おまちください。
          </p>
        </div>
      </div>
    </BModal>
  </div>
</template>

<script>
import Back from '../../components/my-commu/Back'
import { mapActions, mapState } from 'vuex'
import axios from '../../utils/axios'

export default {
  components: {
    Back
  },
  props: {
  },
  data () {
    return {
      eventInformation: '',
      isComponentModalActive: false
    }
  },
  created () {
  },
  computed: {
    ...mapState('session', ['communityId'])
  },
  methods: {
    ...mapActions('mycommu', ['toggleIsForcedShowingFooter', 'toggerHeader']),
    submit () {
      const data = {
        event_information: this.eventInformation
      }
      return axios.post(`/communities/${this.communityId}/events/create_request`, data).then((response) => {
        this.isComponentModalActive = true
      })
    },
    redirectList () {
      console.log(window.location)
      if (window.location.pathname === '/home') {
        this.toggerHeader(false)
      }
      this.$emit('clickBack')
    },
    back () {
      this.toggerHeader(false)
      this.$emit('clickBack')
    }
  },
  mounted () {
    this.toggleIsForcedShowingFooter(true)
  }
}
</script>

<style scoped lang="sass">
.content-request-main
  padding: 32px 40px 32px 32px
  height: calc(100vh - 122px) !important
  display: flex
  justify-content: space-between
  flex-direction: column
.content-request
  font-style: normal
  font-weight: normal
  color: #273D35
  .description-request
    text-align: center
    font-size: 15px
  .title-field
    margin-top: 40px
    font-size: 14px
  .input-green
    margin-top: 8px
    textarea
      border-radius: 6px
      height: 48px
      padding: 16px
      font-size: 15px
      color: #273D35
      border: 1px solid #CCCCCC
      caret-color: #2DBA87
      outline: none
      background: white
      font-family: 'Hiragino Kaku Gothic ProN'
      &::placeholder
        font-weight: 300
        font-size: 15px
        line-height: 20px
        color: #AAAAAA
      &:focus
        border: 1px solid #2DBA87
      &::-webkit-scrollbar
        display: none
    textarea
      width: 100%
      resize: none
      height: unset
/deep/
  .modal-background
    background: #273D35
    opacity: 0.9
  .modal-close
    display: none
.modal-card-body
  margin: auto
  color: #273D35
  font-size: 15px
  border-radius: 20px
  text-align: center
  padding: 40px 16px
  width: 235px
  img
    margin-bottom: 20px
    height: 66px
    width: 66px
</style>
