<template>
  <div>
    <div class="message-types">
      <div
        class="message-type"
        :class="{ 'active': activeTapClass('first-tab')}"
        @click="activeTap('first-tab')"
      >
        <div>{{ firstTap }}</div>
        <div
          v-if="hasNoti"
          class="count-notification"
          v-text="normalNotification"
        />
      </div>
      <div
        class="message-type"
        :class="{ 'active': activeTapClass('second-tab')}"
        @click="activeTap('second-tab')"
      >
        <div>{{ secondTap }}</div>
        <div
          v-if="hasSpecialNoti"
          class="count-notification"
          v-text="specialNotification"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  components: {
  },
  props: {
    hasMessage: {
      type: Boolean,
      default: false
    },
    normalNotification: {
      type: Number,
      default: 0
    },
    specialNotification: {
      type: Number,
      default: 0
    },
    firstTap: {
      type: String,
      default: ''
    },
    secondTap: {
      type: String,
      default: ''
    }
  },
  data () {
    return { activeTapKey: 'first-tab' }
  },
  computed: {
    hasNoti () {
      return this.normalNotification > 0
    },
    hasSpecialNoti () {
      return this.specialNotification > 0
    }
  },
  created () {
  },
  methods: {
    activeTapClass (key) {
      return (this.activeTapKey === key)
    },
    activeTap (key) {
      this.$emit('changeTab', key)
      this.activeTapKey = key
    }
  }
}
</script>
<style scoped lang="sass">

.message-types
  color: #7C7C7C
  font-style: normal
  font-weight: 300
  font-size: 14px
  line-height: 14px
  display: flex
  .message-type
    padding: 16px
    position: relative
    border-bottom: 3px solid #cccccc
    display: flex
    justify-content: center
    width: 50%
    text-align: center
    background: #ffffff
    .count-notification
      position: absolute
      right: 12px
      bottom: 12px
      color: white
      width: 20px
      height: 20px
      background: #EE5353
      border-radius: 50%
      display: flex
      align-items: center
      justify-content: center
      font-weight: 400
      font-size: 12px
      line-height: normal
      vertical-align: middle
  .active
    border-bottom: 3px solid #2DBA87
    font-weight: bold
    color: #2DBA87
</style>
