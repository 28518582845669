<template>
  <div class="event-item">
    <div
      class="event-item-thumb-container"
      :style="{ 'backgroundImage': `url(${primaryImageUrl})` }"
    >
      <div
        v-if="generationFilters.length > 0"
        class="event-item-generation-filters"
      >
        <span
          v-for="text in generationFilters"
          :key="text"
          class="tag is-primary is-rounded"
          v-text="text"
        />
      </div>
    </div>
    <div class="event-status">
      <div class="may-join" v-if="eventStatus === 'unpublished'">
        <div class="is-flex">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_11955_16970)">
              <path d="M5.48047 4.58666V2.38666" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
              <path d="M10.5195 4.58666V2.38666" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
              <path d="M2 6.93335H13.8267" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
              <path d="M14.2929 10.9467C14.1354 11.7063 13.7519 12.4006 13.1927 12.9384C12.6335 13.4762 11.9248 13.8323 11.1595 13.96C9.05731 14.1533 6.94179 14.1533 4.83956 13.96C4.07481 13.8328 3.3666 13.4767 2.80839 12.9388C2.25018 12.4008 1.86822 11.7062 1.71289 10.9467C1.54622 9.458 1.54622 7.95536 1.71289 6.46668C1.86994 5.70794 2.25248 5.01433 2.81038 4.47665C3.36828 3.93897 4.07554 3.5823 4.83956 3.45335C6.94209 3.26669 9.05701 3.26669 11.1595 3.45335C11.9244 3.58194 12.6327 3.93835 13.1917 4.47598C13.7508 5.0136 14.1345 5.70741 14.2929 6.46668C14.4529 7.95573 14.4529 9.45763 14.2929 10.9467V10.9467Z" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
            </g>
            <defs>
              <clipPath id="clip0_11955_16970">
                <rect width="16" height="16" fill="white"/>
              </clipPath>
            </defs>
          </svg>
          <div class="deadline-text">申し込み期限</div>
          <div class="deadline-time">{{ request_expired_at + 'まで' }}</div>
        </div>
        <div class="ev-status">
          非公開
        </div>
      </div>
      <div class="may-join" v-if="eventStatus === 'may-join'">
        <div class="is-flex">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_11955_16970)">
              <path d="M5.48047 4.58666V2.38666" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
              <path d="M10.5195 4.58666V2.38666" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
              <path d="M2 6.93335H13.8267" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
              <path d="M14.2929 10.9467C14.1354 11.7063 13.7519 12.4006 13.1927 12.9384C12.6335 13.4762 11.9248 13.8323 11.1595 13.96C9.05731 14.1533 6.94179 14.1533 4.83956 13.96C4.07481 13.8328 3.3666 13.4767 2.80839 12.9388C2.25018 12.4008 1.86822 11.7062 1.71289 10.9467C1.54622 9.458 1.54622 7.95536 1.71289 6.46668C1.86994 5.70794 2.25248 5.01433 2.81038 4.47665C3.36828 3.93897 4.07554 3.5823 4.83956 3.45335C6.94209 3.26669 9.05701 3.26669 11.1595 3.45335C11.9244 3.58194 12.6327 3.93835 13.1917 4.47598C13.7508 5.0136 14.1345 5.70741 14.2929 6.46668C14.4529 7.95573 14.4529 9.45763 14.2929 10.9467V10.9467Z" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
            </g>
            <defs>
              <clipPath id="clip0_11955_16970">
                <rect width="16" height="16" fill="white"/>
              </clipPath>
            </defs>
          </svg>
          <div class="deadline-text">申し込み期限</div>
          <div class="deadline-time">{{ request_expired_at + 'まで' }}</div>
        </div>
        <div class="ev-status">
          募集中
        </div>
      </div>
      <div class="waiting" v-if="eventStatus === 'waiting'">
        <div class="is-flex">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_11955_16970)">
              <path d="M5.48047 4.58666V2.38666" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
              <path d="M10.5195 4.58666V2.38666" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
              <path d="M2 6.93335H13.8267" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
              <path d="M14.2929 10.9467C14.1354 11.7063 13.7519 12.4006 13.1927 12.9384C12.6335 13.4762 11.9248 13.8323 11.1595 13.96C9.05731 14.1533 6.94179 14.1533 4.83956 13.96C4.07481 13.8328 3.3666 13.4767 2.80839 12.9388C2.25018 12.4008 1.86822 11.7062 1.71289 10.9467C1.54622 9.458 1.54622 7.95536 1.71289 6.46668C1.86994 5.70794 2.25248 5.01433 2.81038 4.47665C3.36828 3.93897 4.07554 3.5823 4.83956 3.45335C6.94209 3.26669 9.05701 3.26669 11.1595 3.45335C11.9244 3.58194 12.6327 3.93835 13.1917 4.47598C13.7508 5.0136 14.1345 5.70741 14.2929 6.46668C14.4529 7.95573 14.4529 9.45763 14.2929 10.9467V10.9467Z" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
            </g>
            <defs>
              <clipPath id="clip0_11955_16970">
                <rect width="16" height="16" fill="white"/>
              </clipPath>
            </defs>
          </svg>
          <div class="deadline-text">申し込み期限</div>
          <div class="deadline-time">{{ request_expired_at + 'まで' }}</div>
        </div>
        <div class="ev-status">
          キャンセル待ち
        </div>
      </div>
      <div class="expired" v-if="eventStatus === 'expired'">
        <div class="ev-status">
          募集終了
        </div>
      </div>
      <div class="closed" v-if="eventStatus === 'closed'">
        <div class="ev-status">
          開催済
        </div>
      </div>
      <div class="joined" v-if="eventStatus === 'joined'">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#2DBA87" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M7.98047 13.16L11.1305 15.5L16.0205 8.5" stroke="#2DBA87" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <div>予約済み・参加確定</div>
      </div>
      <div class="waiting-join" v-if="eventStatus === 'waiting-join'">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#CCCCCC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M7.98047 13.16L11.1305 15.5L16.0205 8.5" stroke="#CCCCCC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <div>予約済み・キャンセル待ち</div>
      </div>
    </div>
    <div class="card-container">
      <div class="thumnnail-name">
        <div class="thumnnail-type">
          <svg width="48" height="48" viewBox="-1 -1 50 50" xmlns="http://www.w3.org/2000/svg">
            <circle cx="24" cy="24" r="24" />
            <path d="M15.4004 34.27L19.5204 14.46" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path
                d="M17.4404 25.7899C22.9204 25.7899 24.5304 28.79 30.5704 27.34L32.6605 16.02C26.6605 17.43 25.0105 14.46 19.5305 14.46"
                stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>

          <span>{{ event_type }}</span>
        </div>
        <div class="thumnnail-time">
          <span>{{ createdAtFormatDot }}</span>
        </div>
      </div>
      <div class="title">
        <span>{{title}}</span>
      </div>
      <div class="raw-body">
        <span v-html="short_body">
        </span>
        <span v-if="seeMore" class="see-more">もっと見る</span>
      </div>
      <div class="card-event-footer">
        <ul>
          <li v-if="address !== ''">
            <div class="card-event-footer-container">
              <span class="card-event-footer-container-icon">
                <svg width="24" height="24" viewBox="-1 -1 26 26" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="12" cy="12" r="12" fill="#D5F1E7"/>
                  <path
                      d="M15.6396 13.309C16.1751 12.6419 16.5207 11.8428 16.6397 10.9958C16.7588 10.1489 16.6469 9.28551 16.316 8.49679C15.9851 7.70806 15.4474 7.02323 14.7595 6.51451C14.0717 6.0058 13.2593 5.69205 12.4079 5.60639C12.0976 5.57521 11.785 5.57521 11.4746 5.60639C10.4147 5.71789 9.42238 6.18004 8.65515 6.91948C7.88792 7.65893 7.38973 8.63331 7.23962 9.68802C7.1527 10.3244 7.19685 10.9718 7.36939 11.5905C7.54193 12.2091 7.8392 12.786 8.24293 13.2857L11.9413 18.0437L15.6396 13.309Z"
                      stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
                  <path
                      d="M11.9596 12.3177C12.852 12.3177 13.5754 11.5946 13.5754 10.7026C13.5754 9.81053 12.852 9.0874 11.9596 9.0874C11.0672 9.0874 10.3438 9.81053 10.3438 10.7026C10.3438 11.5946 11.0672 12.3177 11.9596 12.3177Z"
                      stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </span>
              <div class="events-info-header-element-label">
                <span>開催場所：</span>
              </div>
              <div class="events-info-header-element-label">
                <div class="flex-column white-space-normal">
                  <span>
                    {{shortAddress}}
                  </span>
                  <span>
                    {{placeName}}
                  </span>
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="card-event-time-container">
              <span class="card-event-footer-container-icon">
                <svg width="24" height="24" viewBox="-1 -1 26 26" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="12" cy="12" r="12" fill="#D5F1E7"/>
                  <path
                      d="M11.9997 18.6666C15.6816 18.6666 18.6663 15.6818 18.6663 11.9999C18.6663 8.31802 15.6816 5.33325 11.9997 5.33325C8.31778 5.33325 5.33301 8.31802 5.33301 11.9999C5.33301 15.6818 8.31778 18.6666 11.9997 18.6666Z"
                      stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M12 8.04004V12L14.1333 13.74" stroke-width="1.3" stroke-linecap="round"
                        stroke-linejoin="round" />
                </svg>
              </span>
              <div class="events-info-header-element-label">
                <span>開催日時：</span>
              </div>
              <div class="events-info-header-element-label">
                <div :class="{'flex-column': schedules_time.length !== 1}">
                  <span>
                    {{schedules_date_no_year_and_times}}
                  </span>
                  <span v-for="(time, index) in schedules_time" :key="index">
                    {{ time }}
                  </span>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="card-action" >
      <div class="card-action-cancel-box" @click="closeModal()"><div class="cancel-text">キャンセル</div></div>
      <div class="card-action-next-box" @click="goEventDetail()"><div class="next-text">詳細を見る</div></div>
    </div>
  </div>
</template>

<script>
import EventListItemSchedule from 'components/EventListItemSchedule'

export default {
  components: { EventListItemSchedule },
  props: {
    primaryImageUrl: {
      type: String,
      required: true
    },
    status: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      default: ''
    },
    month: {
      type: String,
      required: true
    },
    day: {
      type: String,
      required: true
    },
    dayOfTheWeek: {
      type: String,
      required: true
    },
    times: {
      type: Array,
      required: true
    },
    address: {
      type: String,
      default: ''
    },
    placeName: {
      type: String,
      default: ''
    },
    eventPath: {
      type: String,
      required: true
    },
    ticketCount: {
      type: Number,
      default: null
    },
    generationFilters: {
      type: Array,
      default: () => []
    },
    isMyCommuDomain: {
      type: Boolean,
      default: false,
    },
    request_expired_at: {
      type: String,
      required: true
    },
    user_request_status: {
      type: String,
      required: true
    },
    isPublished: {
      type: String,
      required: true
    },
    schedules_time: {
      type: Array,
      required: true
    },
    schedules_date_no_year_and_times: {
      type: String,
      required: true
    },
    createdAtFormatDot: {
      type: String,
      required: true
    },
    short_body: {
      type: String,
      required: true
    },
    shortAddress: {
      type: String,
      required: true
    },
    event_type: {
      type: String,
      required: true
    },
  },
  computed: {
    statusLabel () {
      switch (this.status) {
        case 'accepting':
          return this.ticketCount > 0 ? `参加者募集中（${this.ticketCount}人が参加予定）` : '参加者募集中'
        case 'expired':
          return '募集締切'
        case 'over-capacity':
          return 'キャンセル待ち'
        case 'in-session':
          return '開催中'
        case 'finished':
          return '開催済み'
        case 'unpublished':
          return '非公開'
        default:
          return ''
      }
    },
    isAccepting () {
      return this.status === 'accepting' || this.status === 'over-capacity'
    },
    scheduleProps () {
      return {
        month: this.month,
        day: this.day,
        dayOfTheWeek: this.dayOfTheWeek,
        times: this.times
      }
    },
    eventStatus () {
      if (this.isPublished) {
        if (this.status === 'finished') {
          return 'closed'
        } else if (this.user_request_status === 'applied') {
          return 'joined'
        } else if (this.user_request_status === 'waiting') {
          return 'waiting-join'
        } else if (this.status === 'expired') {
          return 'expired'
        } else if (this.status === 'over-capacity') {
          return 'waiting'
        } else {
          return 'may-join'
        }
      } else {
        return 'unpublished'
      }
    }
  },
  methods: {
    goEventDetail () {
      window.location.href = this.eventPath
    },
    closeModal(){
      this.$emit('close-modal')
    }
  },
}
</script>

<style scoped lang="sass">
@import '~stylesheets/resources'

.event-item
  display: flex
  flex-direction: column
  padding: 0
  border: 1px solid #d7d7d7
  margin-bottom: 20px
  border-radius: 20px
  background-color: #fff

  .event-item-thumb-container
    border-top-left-radius: 20px
    border-top-right-radius: 20px
    position: relative
    background-size: cover
    background-position: center
    background-repeat: no-repeat
    padding-top: 300px
    aspect-ratio:1/1
    +app-mobile
      padding-top: 200px

  .event-item-generation-filters
    position: absolute
    top: 10px
    left: 10px
    display: flex
    flex-direction: column
    +app-mobile
      top: 5px
      left: 5px
    span
      margin-bottom: 0.25rem
      background-color: rgba(255, 123, 0, .7)
      font-weight: 700

  .event-status
    .may-join
      padding: 8px
      background: #2DBA87
      width: 100%
      color: white
      display: flex
      justify-content: space-between
      align-items: center
      svg
        margin-right: 4px
      .deadline-text
        font-size: 12px
        margin-right: 4px
      .deadline-time
        font-size: 12px
        font-weight: bold
      .ev-status
        color: #2DBA87
        background: #FFFFFF
        padding: 1px 11px
        font-size: 12px
        font-weight: bold
    .waiting
      padding: 8px
      background: #2DBA87
      width: 100%
      color: white
      display: flex
      justify-content: space-between
      align-items: center
      svg
        margin-right: 4px
      .deadline-text
        font-size: 12px
        margin-right: 4px
      .deadline-time
        font-size: 12px
        font-weight: bold
      .ev-status
        color: #2DBA87
        background: #FFFFFF
        padding: 1px 11px
        font-size: 12px
        font-weight: bold
    .expired
      padding: 8px
      background: #EEEEEE
      width: 100%
      color: white
      display: flex
      justify-content: right
      align-items: center
      .ev-status
        color: #7C7C7C
        background: #F3F3F3
        padding: 0px 10px
        font-size: 12px
        font-weight: bold
        border: 1px solid #7C7C7C
    .closed
      padding: 8px
      background: #CCCCCC
      width: 100%
      color: white
      display: flex
      justify-content: right
      align-items: center
      .ev-status
        color: #7C7C7C
        background: #F3F3F3
        padding: 0px 10px
        font-size: 12px
        font-weight: bold
        border: 1px solid #7C7C7C
    .joined
      padding: 8px
      background: #FFFFFF
      width: 100%
      color: #2DBA87
      display: flex
      align-items: center
      font-size: 15px
      svg
        margin-right: 8px
    .waiting-join
      padding: 8px
      background: #FFFFFF
      width: 100%
      color: #2DBA87
      display: flex
      align-items: center
      font-size: 15px
      svg
        margin-right: 8px

  .card-container
    margin-left: 10px
    margin-right: 10px
    margin-bottom: 8px
    
  .thumnnail-name
    margin-top: 10px
    display: flex
    align-items: center
    margin-bottom: 15px
    font-size: 12px
    .thumnnail-type
      display: flex
      align-items: center
      span
        margin-left: 8px
        color: #273D35
      svg
        stroke: #2DBA87
        fill: #D5F1E7
    .thumnnail-time
      margin-left: auto
      color: #AAAAAA
  .title
    color: #273D35
    font-size: 16px
    font-weight: bold
  .raw-body
    font-weight: 300
    font-size: 14px
    color: #273D35
    margin-top: 24px
    margin-bottom: 16px
    word-break: break-word
    .see-more
      font-weight: bold
  .card-event-footer
    color: #273D35
    display: flex
    font-weight: bold
    font-size: 12px
    .card-event-footer-container-title
      font-size: 14px
    .card-event-footer-container
      align-items: flex-start
      display: flex
      margin-top: 8px
    .card-event-time-container
      display: flex
      white-space: nowrap
      margin-top: 8px
    .card-event-footer-container-title
      color: #2DBA87
    .card-event-footer-container-content
      color: #273D35
      font-size: 14px
    .card-event-footer-container-icon
      margin-right: 4px
      svg
        fill: none
        stroke: #2DBA87
    .coin-icon
      margin-right: 8px
      padding: 0 4px

  .events-info-header-element-label
    color: #2DBA87
    font-weight: bold
    font-size: 0.9em
    .flex-column
      display: flex
      flex-direction: column
    .events-info-header-element-value
      color: #273D35
      font-weight: bold
      font-size: 0.9em


  .card-action
    display: flex
    justify-content: space-between
    align-items: center
    border-top: 1px solid
    border-top-color:  #CCCCCC
    color: #2DBA87
        
    .card-action-cancel-box
      display: flex
      align-items: center
      justify-content: center
      height: 50px
      width: 50%
      border-right: 1px solid
      border-right-color:  #CCCCCC
  
      .cancel-text
        text-align: center
        
    .card-action-next-box
      display: flex
      align-items: center
      justify-content: center
      height: 50px
      width: 50%
      font-weight: bold
  
    .next-text
      text-align: center

</style>
