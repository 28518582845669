<template>
  <div class="search-tags">
    <ul>
      <li
        :class="{ 'active': activeClass('all')}"
        @click="onClick('all')"
      >
        全て
      </li>
      <li
        :class="{ 'active': activeClass('tutaeru')}"
        @click="onClick('tutaeru')"
      >
        伝える
      </li>
      <li
        :class="{ 'active': activeClass('tunagu')}"
        @click="onClick('tunagu')"
      >
        つなげる
      </li>
       <li
        :class="{ 'active': activeClass('azukaru')}"
        @click="onClick('azukaru')"
      >
        預かる
      </li>
    </ul>
    <div class="count-user">
      <p>全部：{{count}}人</p>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  components: {},
  props: {
    count: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {}
  },
  computed: {
    ...mapState('filter', ['supporters']),
    ...mapState('mycommu', ['currentCommunity'])
  },
  mounted() {

  },
  methods: {
    ...mapActions('mycommu', ['searchSupporters']),
    ...mapActions('filter', ['setSupporter']),
    activeClass(value) {
      return this.supporters[value]
    },
    onClick(value) {
      this.setSupporter(value)
      this.searchSupporters({ currentCommunity: this.currentCommunity.community_id, params: value })
    }
  }
}
</script>
<style scoped lang="sass">
  .search-tags
    width: 100%
    padding-bottom: 16px
    background: #f8f7f200
    .count-user
      font-weight: 700
      padding-right: 16px
      font-size: 12px
      line-height: 12px
      text-align: right
      color: #273D35
    ul
      display: flex
      justify-content: space-between
      margin: 32px 16px 24px 16px
      li
        width: 80px
        border: 1px solid #EEEEEE
        border-radius: 24px
        padding: 16px 0px
        background: #FFFFFF
        color: #2DBA87
        font-size: 15px
        font-weight: 300
        text-align: center
        margin-right: 7px
        line-height: 15px
        &.active
          border: 1px solid #2DBA87
          background: #D5F1E7
        &:last-child
          margin-right: unset
</style>
