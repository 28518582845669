<template>
  <div class='list-user'>
    <Back title="参加メンバー一覧" />
    <div class="justify-content-between">
      <div class="new-title">
        <p v-if="newBie.length > 0"> NEW! </p>
      </div>
      <div class="count-user">
        <p>{{countUser}}人</p>
      </div>
    </div>
    <div class="new-bie" v-if="newBie.length > 0">
      <MyCommuUserCard
        v-for="(user, index) in newBie"
        :id="user.id"
        :key="index"
        :avatar-path="user.communityAvatarPath"
        :name="user.commuNickname"
        :content="user.introduction"
        :is-first="index === 0"
        :is-last="index === (countUser - 1)"
        :is-organization-group-admin="user.isOrganizationGroupAdmin"
        :organization-group-id="organizationGroupId"
        :is-root-admin="isRootAdmin"
        is-in-group="true"
      />
    </div>
    <div class="users" v-if="oldUsers.length > 0">
      <MyCommuUserCard
        v-for="(user, index) in oldUsers"
        :id="user.id"
        :key="index"
        :avatar-path="user.avatarPath"
        :name="user.commuNickname"
        :content="user.introduction"
        :is-first="index === 0"
        :is-last="index === (countUser - 1)"
        :is-organization-group-admin="user.isOrganizationGroupAdmin"
        :organization-group-id="organizationGroupId"
        :is-root-admin="isRootAdmin"
        is-in-group="true"
      />
    </div>
  </div>
</template>

<script>
import Back from '../../components/my-commu/Back'
import { mapActions } from 'vuex'

export default {
  components: {
    Back
  },
  props: {
    oldUsers: {
      type: Array,
      default: () => []
    },
    newBie: {
      type: Array,
      default: () => []
    },
    organizationGroupId: {
      type: Number,
      require: true
    },
    isRootAdmin: {
      type: Boolean,
      defaults: false
    }
  },
  computed: {
    countUser () {
      return this.oldUsers.length + this.newBie.length
    }
  },
  mounted () {
    this.toggerMyCommuButton(false)
    this.toggerHeader(true)
  },
  methods: {
    ...mapActions('mycommu', [
      'toggerMyCommuButton',
      'toggerHeader'
    ])
  }
}
</script>
<style scoped lang="sass">
.justify-content-between
  display: flex
  justify-content: space-between
  .new-title
    font-size: 12px
    font-weight: 700
    padding: 16px
  .count-user
    font-size: 12px
    font-weight: 700
    padding: 16px
.new-bie
  margin-bottom: 24px
  border-bottom: 1px solid #CCCCCC
.users
  border-bottom: 1px solid #CCCCCC
</style>
