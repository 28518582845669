<template>
  <div class="share-concierge-card">
    <div class="concierge-container">
      <div class="concierge-box">
        <div class="concierge-header">
          <div class="concierge-avatar"><img class="concierge-face" :src="`${user.communityAvatarPath}`" /><img class="concierge-cert" src="../../images/for_community/certification.png" /></div>
          <div class="concierge-information">
            <div class="concierge-name" v-if="user.commuNickname" v-text="user.commuNickname" />
            <div class="concierge-name" v-else v-text="user.fullName" />
            <div class="concierge-introduction" v-text="user.introduction"  />
          </div>
        </div>

        <div class="concierge-profile" >
          <div class="concierge-interest-title">興味あること</div>
          <div class="concierge-interest-list">
            <div class="concierge-interest" v-for="interest in user.interested" v-if="interest.check" ><img class="concierge-interest-icon" v-bind:src="interest.url_icon"/><span class="concierge-interest-text">{{ interest.label }}</span></div>
          </div>
        </div>
        <div class="card-action" >
          <div class="card-action-cancel-box" @click="closeModal()"><div class="cancel-text">キャンセル</div></div>
          <div class="card-action-next-box" @click="goConciergeDetail(user.id)"><div class="next-text">詳細を見る</div></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import MapAction from 'mixins/MapAction'

export default {
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState('session', ['communityId']),
  },
  methods: {
    goConciergeDetail (id) {
      window.location.href = `/communities/${this.communityId}/users/${id}`
    },
    closeModal(){
      this.$emit('close-modal')
    }
  },
  mixins: [MapAction]
}
</script>

<style lang="sass" scoped>
@import '~stylesheets/resources'

.share-concierge-card
  display: flex
  align-items: flex-start
  font-size: 0.9rem
  position: relative
  overflow: hidden
  border-radius: 20px
  & > *
    box-sizing: border-box
  table
    font-size: 10px

.concierge-container
  width: 100%
  background: white

.concierge-box
    width: 100%

.concierge-header
  display: flex
  justify-content: space-around
  align-items: center
  margin-bottom: 1em
  padding-top: 25px

  .concierge-avatar
    position: relative
    display: inline-block

    .concierge-face
      border-radius: 50%
      width: 64px
      height: 64px
      object-fit: cover
      flex-shrink: 0

    .concierge-cert
      position: absolute
      width: 40px
      top: 55px
      left: 12px

  .concierge-information
    margin-left: 1.0em
    display: inline-block
    width: calc(100% - 64px - 6em )

    .concierge-name
      font-size: 1.1em

    .concierge-introduction
      display: -webkit-box
      -webkit-line-clamp: 2
      -webkit-box-orient: vertical
      overflow: hidden
      text-overflow: ellipsis
      font-size: 0.9em
      color: #7C7C7C
      width: 100%

.concierge-profile
  padding-left: 25px
  padding-right: 25px
  padding-bottom: 15px
  .concierge-interest-title
    font-size: 0.8em
  .concierge-interest-list

    .concierge-interest
      display: inline-block
      justify-content: space-between
      align-items: center
      margin: 0.3em

      .concierge-interest-icon
        width:  1.0em
        height: 1.0em
        display: inline-block
        align-items: center

      .concierge-interest-text
        font-size: 0.8em
        color: #7C7C7C
        align-items: center


.card-action
  display: flex
  justify-content: space-between
  align-items: center
  border-top: 1px solid
  border-top-color:  #CCCCCC
  color: #2DBA87
      
  .card-action-cancel-box
    display: flex
    align-items: center
    justify-content: center
    height: 50px
    width: 50%
    border-right: 1px solid
    border-right-color:  #CCCCCC

    .cancel-text
      text-align: center
      
  .card-action-next-box
    display: flex
    align-items: center
    justify-content: center
    height: 50px
    width: 50%
    font-weight: bold

  .next-text
    text-align: center


  </style>
