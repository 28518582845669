<template>
  <div
    class="my-commu-domain card-give"

  >
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  components: { },
  props: {
  },
  data () {
return {}
  },
  computed: {

  },
  mounted () {

  },
  methods: {

  }
}
</script>
<style scoped lang="sass">
  @import '~stylesheets/resources'

  $mobile-header-z-index: $over-leaflet-z-index
  $mobile-sidenav-z-index: $over-leaflet-z-index + 2
  $mobile-sidenav-bg-z-index: $over-leaflet-z-index + 1
  $app-mobile-footer-height: 50px
  $primary: #2DBA87
  $gray: #AAAAAA

  .my-commu-domain
    height: 100%
    color: $primary
    background: #FFFFFF
    box-shadow: 0px 2px 8px #F2EFDE
    border-radius: 6px
    text-align: center

    span
      font-weight: 700
      font-size: 15px

</style>
