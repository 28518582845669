<template>
  <div>
    <HiddenHeader/>
    <CardDetailsEvent :event="event" :cancel-path="cancelPath" :event-path="eventPath" :action-event="actionEvent" :group-id="groupId" :admin-or-event-of-share-supporter="adminOrEventOfShareSupporter"/>
  </div>
</template>

<script>

import { mapActions } from 'vuex'
import CardDetailsEvent from '../components/my-commu/details/Event'

export default {
  components: {
    CardDetailsEvent
  },
  props: {
    event: {
      type: Object,
      require: true
    },
    cancelPath: {
      type: String,
      require: false,
      default: '#'
    },
    sharingType: {
      type: String,
      required: false
    },
    eventPath: {
      type: String,
      require: false
    },
    actionEvent: {
      type: Boolean,
      default: false
    },
    groupId: {
      type: Number,
      default: null
    },
    adminOrEventOfShareSupporter: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    ...mapActions('mycommu', ['toggleIsForcedShowingFooter'])
  },
  created () {
  },
  mounted () {
    this.toggleIsForcedShowingFooter(true)
  }
}
</script>
