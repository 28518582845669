<template>
  <b-modal
    :active.sync="isOpenModal"
    has-modal-card
    trap-focus
    :destroy-on-hide="false"
    aria-role="dialog"
    aria-label="Example Modal"
    close-button-aria-label="Close"
    aria-modal
    class="delete-modal"
    @close="closeModal"
  >
    <div class="modal-card">
      <header class="modal-card-head">
        <p>今後リリース予定の機能です。</p>
      </header>
    </div>
  </b-modal>
</template>
<script>

export default {
  props: {
    openModal: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return { isOpenModal: this.openModal }
  },
  watch: {
    openModal () {
      this.isOpenModal = this.openModal
    }
  },
  methods: {
    closeModal () {
      this.isOpenModal = false
      this.$emit('close', true)
    }
  }
}
</script>

<style scoped lang="sass">
.delete-modal
  /deep/
  .animation-content
    width: 270px
    margin: auto
    .modal-card
      border-radius: 20px
      .modal-card-head
        background-color: #FFFFFF
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        padding: 32px 0
        p
          color: #273D35
          font-weight: bold
          font-size: 14px
    .modal-close
      display: none !important
</style>
