<template>
  <div class="mycommu-areapartner">
    <div class="mt-50" v-if="loading">
      <MyCommuLoading/>
    </div>
    <div v-if="!loading">
      <div class="lead-partner-container" v-if="leadPartners.length > 0">
        <div v-for="(leadPartner, i) in leadPartners" :key="i" class="lead-partner-box">
          <div class="lead-partner-header">
            <div class="lead-partner-logo"><img :src="leadPartner.logo_url"/></div>
            <div class="lead-partner-block">
              <div class="lead-partner-subheader">
                <div class="lead-partner-badge"><img src="../images/for_community/badge.png"/></div>
                <div class="lead-partner-title">リードパートナー</div>
              </div>
              <div class="lead-partner-name">{{ leadPartner.name.replace(/.{22}/g, "$&\r\n") }}</div>
            </div>
          </div>
          <div class="lead-partner-vision">
            <div class="lead-partner-vision-title">このコミュニティで実現したいビジョン</div>
            <div class="lead-partner-vision-contents">{{ leadPartner.introduction.slice(0, 196) }}</div>
          </div>
        </div>
      </div>

      <div v-if="generalPartners.length > 0">
        <div class="general-partner-title">地域パートナー</div>
        <div class="general-partner-container">
          <div
            class="general-partner-box" v-for="(generalPartner, index) in generalPartners" :key="index"
            @click="showGeneralPartner (generalPartner.user_id)"
          >
            <div class="general-partner-logo"><img :src="`${generalPartner.logo_url}`"/></div>
            <div class="general-partner-name"><span>{{ generalPartner.name.replace(/.{22}/g, "$&\r\n") }}</span></div>
            <div class="general-partner-arrow"><img class="arrow" src="../images/for_community/arrow.png"/></div>
            <Portal to="modal" @click="showGeneralPartner (generalPartner.user_id)">
              <BModal :active.sync="generalPartner.user_id==isModalActive"
                      @close="showGeneralPartner (generalPartner.user_id)"
                      class="detail-general-partners"
              >
                <CorporateUserModalContent @click="showGeneralPartner (generalPartner.user_id)"
                                           :fullName="generalPartner.name" :coverStyle="generalPartner.coverstyle.style"
                                           :logoImageUrl="generalPartner.logo_url"
                                           :introduction="generalPartner.introduction"
                />
              </BModal>
            </Portal>
          </div>
        </div>
      </div>

      <div class="concierge-title">シェア・コンシェルジュ</div>
      <div v-if="concierges.length > 0">
        <div class="concierge-container">
          <div class="concierge-box" v-for="(concierge, conciergeI) in concierges" :key="conciergeI">
            <div class="concierge-header" @click="goConciergeDetail(concierge.data.userId)">
              <div class="concierge-avatar"><img class="concierge-face" :src="`${concierge.data.images}`"/><img
                class="concierge-cert" src="../images/for_community/certification.png"
              /></div>
              <div class="concierge-information">
                <div class="concierge-name" v-text="concierge.data.fullName" v-if="concierge.data.nickName == '' || concierge.data.nickName == null"></div>
                <div class="concierge-name" v-text="concierge.data.nickName" v-else></div>
                <div class="concierge-introduction">{{ concierge.data.addressAndDistance }}</div>
                <div class="concierge-introduction">{{ concierge.data.introduction }}</div>
              </div>
              <div class="concierge-arrow"><img class="arrow" src="../images/for_community/arrow.png"/></div>
            </div>
            <div class="concierge-profile">
              <div class="concierge-interest-title">興味あること</div>
              <div class="concierge-interest-list">
                <div class="concierge-interest" v-for="(interest, interestI) in concierge.data.interested" :key="interestI" v-if="interest.check">
                  <img class="concierge-interest-icon" v-bind:src="interest.gray_url_icon"/><span class="concierge-interest-text">{{ interest.label }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="concierge-zero" v-else>見つかりませんでした。</div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import CorporateUserModalContent from 'components/CorporateUserModalContent'

export default {
  components: {
    CorporateUserModalContent
  },
  props: {
  },
  computed: {
    ...mapState('session', ['communityId']),
    ...mapState('groups', ['leadPartners', 'generalPartners', 'concierges']),
    ...mapState('mycommu', ['loading', 'isShowIconHeader', 'isShowChangeCommu', 'currentCommunity', 'hiddenHeader', 'isShowSchedule']),
    checkInput () {
      return (this.name === null || this.name === '')
    }
  },
  data () {
    return {
      activeTab: 0,
      isModalActive: 0
    }
  },
  created () {
    this.hiddenHeader.mainContainerUnsetMargin = true
    this.toggleShowIconHeader(true)
    this.toggleShowChangeCommu(true)
  },
  mounted () {
    this.toggerMyCommuButton(false)
    this.getAreaPartners(false)
  },
  methods: {
    ...mapActions('groups', [
      'getAreaPartners'
    ]),
    ...mapActions('mycommu', [
      'toggerMyCommuButton',
      'toggerHeader',
      'setTitleHeader',
      'setHiddenHeader',
      'toggleShowIconHeader',
      'toggleShowChangeCommu'
    ]),
    showGeneralPartner (id) {
      if (this.isModalActive === 0) {
        this.isModalActive = id
      } else {
        this.isModalActive = 0
      }
    },
    goConciergeDetail (id) {
      window.location.href = `/communities/${this.currentCommunity.community_id}/users/${id}`
    }
  }
}
</script>
<style scoped lang="sass">
  .mycommu-areapartner
    background-color: #E5E5E5
    padding-bottom: 3rem
  .mt-50
    margin-top: 50%
  /deep/
    .lead-partner-container
      width: 100%
      background: white
      margin-bottom: 1em

    .lead-partner-box
      padding: 24px 16px

    .lead-partner-header
      display: flex

      .lead-partner-logo
        width: 80px
        height: 80px
        object-fit: cover
        border: 1px solid #CCCCCC
      .lead-partner-block
        margin-left: 16px

      .lead-partner-subheader
        display: flex
        align-items: center

        .lead-partner-badge
          width: 25px
          height: auto

        .lead-partner-title
          color: #2DBA87
          font-weight: bold
          font-size: 15px
          padding-left: 8px

      .lead-partner-name
        font-weight: bold
        font-size: 15px
        margin-top: 6px
        color: #273D35

    .lead-partner-vision
      margin-top: 16px
      font-size: 12px
      color: #273D35
      .lead-partner-vision-title
        font-weight: bold
      .lead-partner-vision-contents
        margin-top: 16px
        white-space: break-spaces

    .general-partner-title
      margin-left: 16px
      font-weight: bold
      padding-top: 24px
      padding-bottom: 16px
      font-size: 17px
      line-height: 17px

    .general-partner-container
      width: 100%
      background: white
      margin-bottom: 1em
      border-bottom: 1px solid #CCCCCC

      .general-partner-box
        display: flex
        justify-content: space-between
        padding: 8px
        align-items: center
        width: 100%
        border-top: 1px solid
        border-top-color:  #CCCCCC
        border-bottom-color: #CCCCCC

        .general-partner-logo
          img
            border: 1px solid #CCCCCC
            width: 64px
            height: 64px

        .general-partner-name
          align-items: center
          width: calc(100% - 10em )
          margin-left: 8px
          font-size: 14px
          font-weight: bold
          span
            white-space: pre

        .general-partner-arrow
          align-items: center
          margin-left: auto
          .arrow
            width: 24px
            height: 24px

    .concierge-title
      margin-left: 16px
      font-weight: bold
      margin-top: 24px
      margin-bottom: 16px
      font-size: 17px
      line-height: 17px

    .concierge-container
      width: 100%
      background: white
      border-bottom: 1px solid #CCCCCC

      .concierge-box
        width: 100%
        border-top: 1px solid
        border-top-color:  #CCCCCC
        padding-top: 24px
        padding-bottom: 16px
        padding-left: 16px

        .concierge-header
          display: flex
          justify-content: space-around
          align-items: center
          margin-bottom: 16px

          .concierge-avatar
            position: relative
            display: inline-block

            .concierge-face
              border-radius: 50%
              width: 64px
              height: 64px
              object-fit: cover
              flex-shrink: 0

            .concierge-cert
              position: absolute
              width: 40px
              top: 55px
              left: 12px

          .concierge-information
            margin-left: 1.0em
            display: inline-block
            width: calc(100% - 64px - 4em )

            .concierge-name
              font-size: 16px
              word-break: break-all

            .concierge-introduction
              display: -webkit-box
              -webkit-line-clamp: 2
              -webkit-box-orient: vertical
              overflow: hidden
              font-size: 12px
              width: 100%
              text-overflow: ellipsis
              color: #7C7C7C

          .concierge-arrow
            display: inline-block
            align-items: center
            margin-left: auto

            .arrow
              width: 24px
              height: 24px
              margin-right: 8px

        .concierge-profile
          width: 100%
          .concierge-interest-title
            font-size: 12px
            color: #273D35
          .concierge-interest-list
            margin-top: 8px
            .concierge-interest
              display: inline-flex
              gap: 4px
              align-items: center
              margin-right: 12px
              margin-bottom: 8px

              .concierge-interest-icon
                width:  1.0em
                height: 1.0em
                display: inline-block
                align-items: center

              .concierge-interest-text
                font-size: 12px
                color: #7C7C7C
                align-items: center

    .concierge-zero
      margin-left: 3em

  .detail-general-partners
    z-index: 1997
  /deep/
    .modal-close
      top: 40px
      right: 30px
      background: #00000045
</style>
