import { createTopicComment, createShareComment } from '../../../apis/co'

export default {
  namespaced: true,
  state: {
    comment: null,
    type: null
  },
  getters: {
  },
  actions: {
    createComment ({ dispatch, commit, state }, { topicId }) {
      return createTopicComment(topicId, state.comment)
    },
    createCommentShare ({ dispatch, commit, state }, { shareId, shareType, comment }) {
      return createShareComment(shareId, shareType, comment)
    },
    setComment ({ commit }, data) {
      commit('setComment', data)
    }
  },

  mutations: {
    setComment (state, data) {
      const { comment, type } = data
      state.comment = comment
      state.type = type
    }
  }
}
