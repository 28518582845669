<template>
  <div>
    <div class="wrapper">
      <p class="paragraph">実際に面識がある知り合いの場合は「友達」になりましょう。安心・安全のため、電話番号の下4桁認証があります。</p>
      <div class="base-button">
        <button class="button-class" :type="type" @click="isOpenOtpModal = true">
          <div v-if="hasIcon" v-html="icon" class="icon"></div>
          <div class="text-content">友達になる</div>
          <div v-if="hasIcon" class="fake-icon"></div>
        </button>
      </div>
    </div>
    <b-modal
      :active.sync="isOpenOtpModal"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-label="Example Modal"
      close-button-aria-label="Close"
      aria-modal
      class="user-modal"
    >
      <div
        class="modal-card"
        style="width: auto"
      >
        <section class="modal-card-body card-add-friend">
          <div class="add-friend">
            <img
              src="../../images/icons/arr_left.svg"
              alt=""
              @click="closeOtpModal"
            >
            <strong><p>電話番号の下4桁を入力する</p></strong>
            <div class="div-fake" />
          </div>
          <div
            v-if="noPhoneText"
            class="set-mb-error error-text-wrapper"
          >相手の電話番号が現在登録されていません。 相手に番号の登録を依頼しますので、登録完 了までお待ち下さい。番号が登録されましたら お知らせいたします。</div >
          <div
            v-if="wrongAuthText"
            class="set-mb-error error-text-wrapper"
          >相手の電話番号下4桁と一致しません</div >
          <div class="form-number-phone">
            <MyCommuOtp
              ref="otpInput"
              input-classes="otp-input"
              :input-classes="this.inputError ? 'input-error' : ''"
              :num-inputs="4"
              :should-auto-focus="true"
              :is-input-num="true"
              @on-complete="handleOnComplete"
              @on-change="handleOnChange"
              :value="this.keyPhone.split('')"
            />
          </div>
          <div class="set-pb-p">友達になるユーザーの電話番号下4桁を入力してください</div >
          <div
            v-if="noPhoneText"
            id="goback"
            onclick="window.history.back()"
          >
            <MyCommuBaseButton
              text-content="閉じる"
              :is-white-button="isWhiteButton"
            />
          </div>
          <div
            v-else
            id="sendData"
            @click="sendFriendRequest"
          >
            <MyCommuBaseButton
              text-content="次へ"
              :disable="!checkDisabled"
            />
          </div>
        </section>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'
import { commuSuccess, commuError } from '../../lib/app-toast'
export default {
  props: {
    profile: {
      type: Object,
      require: true
    },
    bestFriend: {
      type: Boolean,
      default: false
    },
    lastItem: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isOpenNumberPhone: false,
      isLv3: this.bestFriend,
      isOpenPopupUnfriend: false,
      keyPhone: '',
      isOpenOtpModal: false,
      inputError: false,
      noPhoneText: false,
      wrongAuthText: false
    }
  },
  created () {
  },
  computed: {
    ...mapState('session', ['communityId']),
    checkDisabled () {
      return this.keyPhone.length === 4
    }
  },
  methods: {
    handleOnComplete (value) {
      this.keyPhone = value
    },
    handleOnChange (value) {
      this.keyPhone = value
    },
    navigate () {
      window.location.href = `/communities/${this.communityId}/users/${this.profile.userId}`
    },
    openOtpModal () {
      if (this.profile.sentFriendRequest) return
      this.questionModal = false
      this.isOpenOtpModal = true
    },
    closeOtpModal () {
      this.isOpenOtpModal = false
    },
    back () {
      this.isOpenNumberPhone = false
    },
    openNumberPhone () {
      if (!this.bestFriend) {
        this.isOpenNumberPhone = true
      }
    },
    async sendFriendRequest () {
      // eslint-disable-next-line camelcase
      const data = new FormData()
      data.append('friendship[auth_key]', this.keyPhone)
      await axios({
        method: 'POST',
        url: `/communities/notifications/${this.profile.userId}/send_friend_request`,
        data: data
      }).then((res) => {
        if (res.data.status === 'success') {
          commuSuccess('友達を申請しました。')
          window.location.reload()
        } else if (res.data.status === 'sented') {
          commuError('フォローが失敗しました。')
          this.isOpenPopup = false
          this.inputError = false
          this.isOpenPopup = false
          this.wrongAuthText = false
          this.noPhoneText = false
        } else if (res.data.status === 'auth_failed') {
          this.wrongAuthText = true
          this.inputError = true
        } else if (res.data.status === 'receiver_does_not_have_phone_number') {
          this.noPhoneText = true
          this.inputError = true
        }
      })
    },
    message () {
      window.location.href = `/communities/chats/${this.profile.userId}/check_message`
    },
    unfriend () {
      this.isOpenPopupUnfriend = true
    }
  }
}
</script>

<style scoped lang="sass">
/deep/
  .input-error
    border: 1px solid #EE5353 !important
.error-text-wrapper
  border: 1px solid #EE5353
  border-radius: 10px
  background-color: #FFEEF0
  padding: 10px
  color: #EE5353
  text-align: center
.set-pt-p
  padding-top: 20px
.set-pb-p
  padding-bottom: 20px
.set-mb-error
  margin-bottom: 30px
.set-pb-p
  padding-bottom: 20px
.wrapper
  border: 1px solid black
  border-radius: 10px
  background-color: white
  margin: 0px 10px
.paragraph
  word-break: break-all
  padding: 20px
.button-class
  margin: 5px 20px 20px 20px
.base-button
  display: flex
  justify-content: center
  button
    font-family: 'Hiragino Kaku Gothic ProN'
    font-weight: bold
    width: 100%
    height: 48px
    padding: 16px 10px
    font-size: 15px
    line-height: 15px
    color: #FFFFFF
    background: #2DBA87
    border: 0
    border-radius: 6px
    box-shadow: 0 2px 8px #F2EFDE
    &:hover
      background: #61DEB1
    &.disable
      opacity: 0.4
      &:hover
        background: #2DBA87
.user-modal
  flex-direction: column
  justify-content: flex-end
  /deep/
    .modal-background
      background: #273D35
      opacity: 0.9
    .modal-card
      height: 65vh
      border-radius: 10px 10px 0px 0px
      .modal-card-body
        .user-avatar
          padding-top: 8px
          display: flex
          padding-bottom: 24px
          border-bottom: 1px solid #CCCCCC
          img
            width: 48px
            height: 48px
            border-radius: 50%
            margin-right: 8px
          .card-body
            display: flex
            flex-direction: column
            justify-content: center
        .mail, .friendship, .report, .block-user, .unfriend
          display: flex
          padding: 16px 0
          border-bottom: 1px solid #CCCCCC
          .icon
            margin-right: 18px
        .unfriend
          border-bottom: none
          .block-fake
            width: 24px
            margin-right: 18px
      .card-add-friend
        padding: 27px 32px 32px 32px
        .add-friend
          display: flex
          align-items: center
          justify-content: space-between
          margin-bottom: 37px
          .div-fake
            width: 24px
        .form-number-phone
          display: flex
          justify-content: center
    .modal-close
      display: none !important
</style>
