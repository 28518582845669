<template>
  <div>
    <div class="detail-group">
      <Back :title="group.name" :header-avatar="group.imageUrl"/>
      <HiddenHeader/>
      <MyCommuHiddenButtonFooter :is-my-commu-domain="true"/>
      <div class="container-share-posts">
        <div class="description">
          <span>何のシェアをしますか？</span>
        </div>

        <div class="content-share-posts">
          <div class="elements">
            <div class="col-2 element-item">
              <a :href="pathSharing">
                <div class="element-item-content">
                  <img src="../../images/for_community/share.png"/>
                  <span>モノ</span>
                </div>
              </a>
            </div>
            <div class="col-2 element-item" >
              <a :href="pathLiving">
                <div class="element-item-content">
                  <img src="../../images/for_community/timer.png"/>
                  <span>暮らし</span>
                </div>
              </a>
            </div>
            <div class="col-1 element-item">
              <a href="#" @click="isOpenTutorial = true">
                <div class="element-item-content">
                  <img src="../../images/for_community/family.png"/>
                  <span>子育て</span>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      :active.sync="modal"
      has-modal-card
      :on-cancel="changeClickedTopic()"
      class="first-topic-and-share"
    >
      <div class="modal-card">
        <div class="modal-card-body">
          <p class="fw-bold">
            コミュニティ内で共有しましょう！
          </p>
          <p class="fw-bold">
            誰かが助けてくれるかも
          </p>
          <div class="d-flex mt-32">
            <div class="content-left">
              <img src="../../images/icons/icon-post.svg">
              <span class="title">投稿</span>
              <p class="text-align-start">
                あなたの気になるもの<br>
                や、自分の好きな場所<br>
                等なんでも教えてくださ<br>
                い。
              </p>
            </div>
            <div class="content-right">
              <img src="../../images/icons/icon-share.svg">
              <span class="title">シェア</span>
              <p class="text-align-start">
                モノの貸し借りや、空<br>
                いた時間でのお手伝<br>
                い、子どもの送迎など<br>
                をお願いできます。
              </p>
            </div>
          </div>
          <div class="content-footer">
            <p>（一部を除き内容はホーム画面に投稿されます）</p>
          </div>
          <p
            class="cancel"
            @click="modal = false"
          >
            閉じる
          </p>
        </div>
      </div>
    </b-modal>
    <MyCommuSchedule v-if="isShowSchedule"/>
    <b-modal
      :active.sync="isOpenTutorial"
      has-modal-cardh
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-label="Example Modal"
      close-button-aria-label="Close"
      aria-modal
      class="modal-toturial"
    >
      <div class="modal-card" style="width: auto">
        <section class="modal-card-body">
          <h3 class="f-size-14 font-weight-bold">お子様の送迎・預かりに関して</h3>
          <div class="card-turial">
            <div class="image">
              <img src="../../images/for_community/share-family.png" alt="">
            </div>
            <p class="f-size-12 color-base text-justify">大事なお子様の送迎・預かりに関しては、実際に面識のある方や普段交流のある方へのお願いを推奨しています。</p>
          </div>
          <div class="card-turial">
            <div class="image">
              <img src="../../images/for_community/share-phone.png" alt="">
            </div>
            <p class="f-size-12 color-base text-justify">お願いできる方は、相手の電話番号を知っている関係性で「送迎託児OKの友達」になっている方です。</p>
          </div>
          <div class="card-turial">
            <div class="image d-flex-column content-centrer">
              <img src="../../images/for_community/share-supporter.png" alt="">
            </div>
            <p class="f-size-12 color-base text-justify">頼れる相手がいない人は、近くのシェア・コンシェルジュに連絡してみましょう。有資格者や子ども好きな人も多く、仲良くなると、頼れる知り合いになれます！</p>
          </div>
          <div class="card-turial">
            <div class="image padding-left-10 padding-right-10 d-flex-column content-centrer">
              <img src="../../images/for_community/share-gift.png" alt="">
            </div>
            <p class="f-size-12 color-base text-justify">謝礼ルールは500円～700円/時を推奨していますが、相互に合意すれば任意に設定ができます。手渡しでもカード払いも可能です。</p>
          </div>
          <div class="action">
            <MyCommuBaseButton
              text-content="ユーザ検索"
              :is-white-button="true"
              @clickButton="redirect('/users')"
            />
            <MyCommuBaseButton
              text-content="次へ"
              @clickButton="redirect(`${navPaths.communityGroupEntryNew.path}`)"
            />
          </div>
          <div @click="isOpenTutorial = false" class="close f-size-14 font-weight-bold">
            閉じる
          </div>
        </section>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Back from '../../components/my-commu/Back'
import { mapActions, mapState } from 'vuex'
import axios from '../../utils/axios'

export default {
  components: { Back },
  props: {
    navPaths: {
      type: Object,
      required: true
    },
    group: {
      type: Object,
      default: () => ({})
    },
    firstClickTopic: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isOpenTutorial: false,
      modal: !this.firstClickTopic,
      iconBack: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
          '<g clip-path="url(#clip0_10847_11145)">\n' +
          '<path d="M5.43018 5.42999L18.5702 18.57" stroke="#273D35" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>\n' +
          '<path d="M18.5702 5.42999L5.43018 18.57" stroke="#273D35" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>\n' +
          '</g>\n' +
          '<defs>\n' +
          '<clipPath id="clip0_10847_11145">\n' +
          '<rect width="24" height="24" fill="white"/>\n' +
          '</clipPath>\n' +
          '</defs>\n' +
          '</svg>\n'
    }
  },
  computed: {
    ...mapState('session', ['communityId', 'currentUser']),
    ...mapState('mycommu', ['isShowSchedule']),
    pathSharing () {
      return `/communities/${this.communityId}/community_groups/${this.group.id}/share_posts/every_things/new`
    },
    pathLiving () {
      return `/communities/${this.communityId}/community_groups/${this.group.id}/livings/new`
    }
  },
  methods: {
    ...mapActions('mycommu', [
      'toggerMyCommuButton', ['setCurrentUser'],
      'toggerHeader',
      'toggerMyCommuFooterMenu',
      'toggleShowIconHeader',
      'toggleShowChangeCommu'
    ]),
    ...mapActions('session', ['setCurrentUser']),
    changeClickedTopic () {
      if (!this.modal) {
        const params = new FormData()
        params.append('profile[clicked_topic]', true)
        axios.put('/profiles/update_clicked_topic', params).then((res) => {
          if (res.data.status) {
            this.modal = false
            this.setCurrentUser(res.data.user)
          }
        })
      }
    },
    gotoPage (url) {
      window.location.href = url
    },
    back () {
      // console.log(1)
    },
    openInviTing () {
      this.isOpenInviting = !this.isOpenInviting
    },
    redirect (url) {
      window.location.href = url
    }
  }
}
</script>
<style scoped lang="sass">
  .text-justify
    text-align: justify
  .container-share-posts
    padding: 0px 16px
    color: #273D35
    .description
      padding-top: 40px
      padding-bottom: 30px
      text-align: center
      span
        font-weight: 300
        font-size: 15px
    .elements
      padding: 0 16px
      display: grid
      gap: 15px
      grid-auto-columns: 1fr
      .element-item
        background: #FFFFFF
        box-shadow: 0px 2px 8px #f2efde
        border-radius: 6px
        padding: 18px
        display: flex
        flex-direction: column
        align-items: center
        color: #2DBA87
        text-align: center
        font-size: 15px
        font-weight: 600
        .element-item-content
          display: flex
          flex-direction: column
          color: #2DBA87
        img
          padding-bottom: 15px
      .col-1
        grid-column: 1 / span 2
  .first-topic-and-share
    /deep/
      .cancel
        font-weight: bold
        color: #2DBA87
      .modal-background
        background: #273D35
        opacity: 0.9
      .animation-content
        display: contents
        .modal-card
          font-size: 14px
          line-height: 24px
          display: flex
          justify-content: center
          //padding: 0 52px
          padding: 0 24px
          .modal-card-body
            text-align: center
            border-radius: 20px
            padding: 32px 20px
      .button-supporter
        margin-top: 16px
        margin-bottom: 24px
      .button-create
        margin-top: 18px
        margin-bottom: 28px
      .modal-close
        display: none
        .base-button
          margin-top: 14px
          margin-bottom: 22px
          button
            font-size: 14px !important
            padding: 16px 6px 16px 6px !important
  .d-flex
    display: flex
  .mt-32
    margin-top: 32px
  .title
    font-weight: bold !important
    font-size: 15px
    margin: 16px 0
  .fw-bold
    font-weight: bold !important
  .text-align-start
    display: flex
    justify-content: center
    text-align: start !important
  .content-left
    display: flex
    flex-direction: column
    margin-right: 8px
    width: 50%
    color: #273D35
    font-size: 12px
    img
      height: 38px
  .content-right
    display: flex
    flex-direction: column
    margin-left: 8px
    width: 50%
    color: #273D35
    font-size: 12px
    img
      height: 38px
  .content-footer
    margin-top: 24px
    font-size: 10px
  .cancel
    font-weight: bold
    color: #2DBA87
    margin-top: 28px
  .f-size-12
    font-size: 12px
  .color-base
    color: #273D35
  .font-weight-bold
    font-weight: 700
  .modal-toturial
    /deep/
      .modal-background
        background: #273D35
        opacity: 0.9
      .animation-content
        border-radius: 20px
        max-width: calc(100% - 50px) !important
        margin: auto
        .modal-card-body
          padding: 32px 24px 28px 24px
          h3
            text-align: center
            margin-bottom: 32px
          .card-turial
            display: flex
            justify-content: space-between
            margin-bottom: 24px
            .image
              width: 25%
              height: auto
            p
              width: calc(75% - 20px)
          .action
            display: flex
            justify-content: space-between
            width: 100%
            .base-button
              width: calc((100% - 9px)/2)
          .close
            color: #2DBA87
            text-align: center
            margin-top: 28px
        .modal-close
          height: 40px
          max-height: 40px
          max-width: 40px
          width: 40px
          right: unset
          left: 15px
          top: 40px
  /deep/
    .modal
      z-index: 1997
</style>
