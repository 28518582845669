<template>
  <div class="user-box">
    <div class="user-header" @click="goUserDetail(data.userId)">
      <div class="user-avatar"><img class="user-face" :src="`${data.images}`" /><img class="user-cert" src="../../../images/for_community/certification.png" v-if="isSupporter"/></div>
      <div class="user-information">
        <div class="user-name" v-text="data.fullName" v-if="data.nickName == ''"></div>
        <div class="user-name" v-text="data.nickName" v-else></div>
        <div class="user-introduction">{{ data.introduction }}</div>
        <div class="user-group-admin" v-if="isAdmin"><img class="user-group-admin-img" src="../../../images/for_community/groupadmin.png"/><span class="user-group-admin-text">管理者</span></div>
      </div>
      <div class="user-arrow" ><img class="arrow" src="../../../images/for_community/arrow.png" /></div>
    </div>
    <div class="user-profile" >
      <div class="user-interest-title">興味あること</div>
      <div class="user-interest-list">
        <div class="user-interest" v-for="interest in data.interested" v-if="interest.check" ><img class="user-interest-icon" v-bind:src="interest.url_icon"/><span class="user-interest-text">{{ interest.label }}</span></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  components: {
  },
  props: {
    data: {
      type: Object,
      require: true,
      default: () => ({})
    },
    isAdmin: {
      type: Boolean,
      default: false
    },
    isSupporter: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    ...mapState('session', ['communityId']),
    ...mapState('mycommu', ['currentCommunity']),

    checkContent () {
      return !!this.content
    }
  },
  created () {
  },
  methods: {
    goUserDetail (id) {
     window.location.href = `/communities/${this.currentCommunity.community_id}/users/${id}`
    },
  }
}
</script>
<style scoped lang="sass">

.user-box
  width: 100%
  border-top: 1px solid
  border-top-color:  #EEEEEE
  border-bottom: 1px solid
  border-bottom-color: #EEEEEE
  padding-top: 1em
  padding-left: 1em

  .user-header
    display: flex
    justify-content: space-around
    align-items: center
    margin-bottom: 1em

    .user-avatar
      position: relative
      display: inline-block

      .user-face
        border-radius: 50%
        width: 64px
        height: 64px
        object-fit: cover
        flex-shrink: 0

      .user-cert
        position: absolute
        width: 40px
        top: 55px
        left: 12px

    .user-information
      margin-left: 1.0em
      display: inline-block
      width: calc(100% - 64px - 6em )

      .user-name
        font-weight: bold
        word-break: break-all

      .user-introduction
        display: -webkit-box
        -webkit-line-clamp: 2
        -webkit-box-orient: vertical
        overflow: hidden
        font-size: 1em
        width: 100%
        text-overflow: ellipsis

      .user-group-admin

        .user-group-admin-img
          width: 16px
          height: 16px

        .user-group-admin-text
          margin-left: 0.5em
          font-size: 0.8em

    .user-arrow
      display: inline-block
      align-items: center
      margin-left: auto

      .arrow
        width: 2.0em
        height: 2.0em


  .user-profile
    width: 100%
  
    .user-interest-title
      font-size: 0.8em
    .user-interest-list

      .user-interest
        display: inline-block
        justify-content: space-between
        align-items: center
        margin: 0.3em

        .user-interest-icon
          width:  1.0em
          height: 1.0em
          display: inline-block
          align-items: center

        .user-interest-text
          font-size: 0.8em
          color: #7C7C7C
          align-items: center
</style>
