<template>
  <div class="list-user">
    <div class="group-admin">
      <MyCommuUserWithInterestCard
        v-for="(user, index) in groupAdmins"
        :key="index"
        :data="user.data"
        :is-admin="true"
        :is-supporter="isSupporter(user.data.userId)"
      />
    </div>
    <div class="users-title">グループ参加者</div>
    <div class="users">
      <div
        v-for="(user, index) in groupMembers"
        :key="index"
      >
        <MyCommuUserWithInterestCard
          v-if="!isAdmin(user.data.userId)"
          :data="user.data"
          :is-admin="false"
          :is-supporter="isSupporter(user.data.userId)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  components: {},
  props: {
    organizationGroupId: {
      type: Number,
      require: true
    }
  },
  computed: {
    ...mapState('groups', ['concierges', 'groupMembers', 'groupAdmins']),
    countAdmins () {
      return this.groupAdmins.length
    },
    countMembers () {
      return this.groupMembers.length - this.groupAdmins.length
    }
  },
  mounted () {
    this.toggerMyCommuButton(false)
    this.toggerHeader(true)
    this.getAreaPartners(false)
  },
  methods: {
    ...mapActions('groups', [
      'getAreaPartners'
    ]),
    ...mapActions('mycommu', [
      'toggerMyCommuButton',
      'toggerHeader'
    ]),
    isAdmin (id) {
      var tmp = this.groupAdmins.filter(e => e.data.userId == id)
      return tmp.length > 0
    },
    isSupporter (id) {
      console.log('id' + id)
      var tmp = this.concierges.filter(e => e.data.userId == id)
      console.log('tmp' + tmp)
      return tmp.length > 0
    }
  },
  destroyed () {
    this.toggerMyCommuButton(true)
  }
}
</script>
<style scoped lang="sass">
.justify-content-between
  display: flex
  justify-content: space-between

  .new-title
    font-size: 12px
    font-weight: 700
    padding: 16px

  .count-user
    font-size: 12px
    font-weight: 700
    padding: 16px

.group-admin
  border-bottom: 1px solid #CCCCCC

.users-title
  padding: 1em
  background: #f8f7f2
  font-weight: bold

.users
  border-bottom: 1px solid #CCCCCC
</style>
