<template>
  <div class="slides">
    <div v-if="event" class="event-status">
      <div class="event-status">
        <div class="may-join" v-if="eventStatus === 'unpublished'">
          <div class="is-flex">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_11955_16970)">
                <path d="M5.48047 4.58666V2.38666" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
                <path d="M10.5195 4.58666V2.38666" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
                <path d="M2 6.93335H13.8267" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
                <path d="M14.2929 10.9467C14.1354 11.7063 13.7519 12.4006 13.1927 12.9384C12.6335 13.4762 11.9248 13.8323 11.1595 13.96C9.05731 14.1533 6.94179 14.1533 4.83956 13.96C4.07481 13.8328 3.3666 13.4767 2.80839 12.9388C2.25018 12.4008 1.86822 11.7062 1.71289 10.9467C1.54622 9.458 1.54622 7.95536 1.71289 6.46668C1.86994 5.70794 2.25248 5.01433 2.81038 4.47665C3.36828 3.93897 4.07554 3.5823 4.83956 3.45335C6.94209 3.26669 9.05701 3.26669 11.1595 3.45335C11.9244 3.58194 12.6327 3.93835 13.1917 4.47598C13.7508 5.0136 14.1345 5.70741 14.2929 6.46668C14.4529 7.95573 14.4529 9.45763 14.2929 10.9467V10.9467Z" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
              </g>
              <defs>
                <clipPath id="clip0_11955_16970">
                  <rect width="16" height="16" fill="white"/>
                </clipPath>
              </defs>
            </svg>
            <div class="deadline-text">申し込み期限</div>
            <div class="deadline-time">{{ event.request_expired_at + 'まで' }}</div>
          </div>
          <div class="ev-status">
            非公開
          </div>
        </div>
      </div>
      <div class="may-join" v-if="eventStatus === 'may-join'">
        <div class="is-flex">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_11955_16970)">
              <path d="M5.48047 4.58666V2.38666" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
              <path d="M10.5195 4.58666V2.38666" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
              <path d="M2 6.93335H13.8267" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
              <path d="M14.2929 10.9467C14.1354 11.7063 13.7519 12.4006 13.1927 12.9384C12.6335 13.4762 11.9248 13.8323 11.1595 13.96C9.05731 14.1533 6.94179 14.1533 4.83956 13.96C4.07481 13.8328 3.3666 13.4767 2.80839 12.9388C2.25018 12.4008 1.86822 11.7062 1.71289 10.9467C1.54622 9.458 1.54622 7.95536 1.71289 6.46668C1.86994 5.70794 2.25248 5.01433 2.81038 4.47665C3.36828 3.93897 4.07554 3.5823 4.83956 3.45335C6.94209 3.26669 9.05701 3.26669 11.1595 3.45335C11.9244 3.58194 12.6327 3.93835 13.1917 4.47598C13.7508 5.0136 14.1345 5.70741 14.2929 6.46668C14.4529 7.95573 14.4529 9.45763 14.2929 10.9467V10.9467Z" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
            </g>
            <defs>
              <clipPath id="clip0_11955_16970">
                <rect width="16" height="16" fill="white"/>
              </clipPath>
            </defs>
          </svg>
          <div class="deadline-text">申し込み期限</div>
          <div class="deadline-time">{{ event.request_expired_at + 'まで' }}</div>
        </div>
        <div class="ev-status">
          募集中
        </div>
      </div>
      <div class="waiting" v-if="eventStatus === 'waiting'">
        <div class="is-flex">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_11955_16970)">
              <path d="M5.48047 4.58666V2.38666" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
              <path d="M10.5195 4.58666V2.38666" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
              <path d="M2 6.93335H13.8267" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
              <path d="M14.2929 10.9467C14.1354 11.7063 13.7519 12.4006 13.1927 12.9384C12.6335 13.4762 11.9248 13.8323 11.1595 13.96C9.05731 14.1533 6.94179 14.1533 4.83956 13.96C4.07481 13.8328 3.3666 13.4767 2.80839 12.9388C2.25018 12.4008 1.86822 11.7062 1.71289 10.9467C1.54622 9.458 1.54622 7.95536 1.71289 6.46668C1.86994 5.70794 2.25248 5.01433 2.81038 4.47665C3.36828 3.93897 4.07554 3.5823 4.83956 3.45335C6.94209 3.26669 9.05701 3.26669 11.1595 3.45335C11.9244 3.58194 12.6327 3.93835 13.1917 4.47598C13.7508 5.0136 14.1345 5.70741 14.2929 6.46668C14.4529 7.95573 14.4529 9.45763 14.2929 10.9467V10.9467Z" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
            </g>
            <defs>
              <clipPath id="clip0_11955_16970">
                <rect width="16" height="16" fill="white"/>
              </clipPath>
            </defs>
          </svg>
          <div class="deadline-text">申し込み期限</div>
          <div class="deadline-time">{{ event.request_expired_at + 'まで' }}</div>
        </div>
        <div class="ev-status">
          キャンセル待ち
        </div>
      </div>
      <div class="expired" v-if="eventStatus === 'expired'">
        <div class="ev-status">
          募集終了
        </div>
      </div>
      <div class="closed" v-if="eventStatus === 'closed'">
        <div class="ev-status">
          開催済
        </div>
      </div>
      <div class="joined" v-if="eventStatus === 'joined'">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#2DBA87" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M7.98047 13.16L11.1305 15.5L16.0205 8.5" stroke="#2DBA87" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <div>予約済み・参加確定</div>
      </div>
      <div class="waiting-join" v-if="eventStatus === 'waiting-join'">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#CCCCCC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M7.98047 13.16L11.1305 15.5L16.0205 8.5" stroke="#CCCCCC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <div>予約済み・キャンセル待ち</div>
      </div>
    </div>
    <swiper class="swiper" navigation :options="swiperOptions">
      <swiper-slide v-for="(image, index) in images" :key="index">
        <img :src="image" loading="lazy" />
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
export default {
  components: {
    Swiper,
    SwiperSlide
  },
  directives: {
    swiper: directive
  },
  props: {
    images: {
      type: Array,
      require: true
    },
    event: {
      type: Object,
      required: false
    },
    isEvent: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      swiperOptions: {
        pagination: {
          el: '.swiper-pagination'
        },
      },
      iconColor: 'white'
    }
  },
  computed: {
    swiper () {
      return this.$refs.mySwiper.$swiper
    },
    eventStatus () {
      if (this.event) {
        if (this.event.isPublished) {
          if (this.event.status === 'finished') {
            return 'closed'
          } else if (this.event.user_request_status === 'applied') {
            return 'joined'
          } else if (this.event.user_request_status === 'waiting') {
            return 'waiting-join'
          } else if (this.event.status === 'expired') {
            return 'expired'
          } else if (this.event.status === 'over-capacity') {
            return 'waiting'
          } else {
            return 'may-join'
          }
        } else {
          return 'unpublished'
        }
      }
    },
    mayJoin () {
      if (this.event) {
        return !this.event.is_request_expired && this.event.user_request_status === 'no_joined' && this.event.status !== 'finished'
      }
    },
    join () {
      if (this.event) {
        return this.event.user_request_status === 'neutral' && this.event.status !== 'finished'
      }
    },
    joined () {
      if (this.event) {
        return (this.event.user_request_status === 'waiting' || this.event.user_request_status === 'neutral') && this.event.status === 'finished'
      }
    },
    noJoined () {
      if (this.event) {
        return (this.event.user_request_status === 'applied' || this.event.user_request_status === 'no_joined')
      }
    }
  }
}
</script>
<style scoped lang="sass">
.slides
  .swiper
    &.swiper-container
      padding-bottom: 36px
    .swiper-slide
      img
        width: 100%
        aspect-ratio : 1 / 1
        object-fit: cover
        border-top-left-radius: 12px
        border-top-right-radius: 12px

.event-status
  .may-join
    padding: 8px
    background: #2DBA87
    width: 100%
    color: white
    display: flex
    justify-content: space-between
    align-items: center
    svg
      margin-right: 4px
    .deadline-text
      font-size: 12px
      margin-right: 4px
    .deadline-time
      font-size: 12px
      font-weight: bold
    .ev-status
      color: #2DBA87
      background: #FFFFFF
      padding: 1px 11px
      font-size: 12px
      font-weight: bold
  .waiting
    padding: 8px
    background: #2DBA87
    width: 100%
    color: white
    display: flex
    justify-content: space-between
    align-items: center
    svg
      margin-right: 4px
    .deadline-text
      font-size: 12px
      margin-right: 4px
    .deadline-time
      font-size: 12px
      font-weight: bold
    .ev-status
      color: #2DBA87
      background: #FFFFFF
      padding: 1px 11px
      font-size: 12px
      font-weight: bold
  .expired
    padding: 8px
    background: #EEEEEE
    width: 100%
    color: white
    display: flex
    justify-content: right
    align-items: center
    .ev-status
      color: #7C7C7C
      background: #F3F3F3
      padding: 0px 10px
      font-size: 12px
      font-weight: bold
      border: 1px solid #7C7C7C
  .closed
    padding: 8px
    background: #CCCCCC
    width: 100%
    color: white
    display: flex
    justify-content: right
    align-items: center
    .ev-status
      color: #7C7C7C
      background: #F3F3F3
      padding: 0px 10px
      font-size: 12px
      font-weight: bold
      border: 1px solid #7C7C7C
  .joined
    padding: 8px
    background: #FFFFFF
    width: 100%
    color: #2DBA87
    display: flex
    align-items: center
    font-size: 15px
    svg
      margin-right: 8px
  .waiting-join
    padding: 8px
    background: #FFFFFF
    width: 100%
    color: #2DBA87
    display: flex
    align-items: center
    font-size: 15px
    svg
      margin-right: 8px
</style>
