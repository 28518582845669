<template>
  <div
    class="supporters"
    :class="{'is-last': isLast}"
    @click="viewProfile"
  >
    <div class="user-card">
      <div class="thumbnail-name">
        <div class="card-avatar">
          <img :src="supporter.user.avatarPath">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="supporter.user.supporter">
            <path d="M13.8398 14.8496H10.1598C9.91979 13.1896 9.69979 11.5296 9.52979 9.84961H14.4698C14.2598 11.5296 14.0798 13.1896 13.8398 14.8496Z" fill="#D5F1E7"/>
            <path d="M9.53023 9.88019C7.26023 8.77019 5.14021 7.69021 3.26021 6.77021C3.04775 6.73494 2.83032 6.74378 2.62142 6.79615C2.41251 6.84851 2.21662 6.94328 2.04592 7.07459C1.87521 7.2059 1.73331 7.37091 1.62911 7.55939C1.52491 7.74788 1.46062 7.95583 1.4402 8.17023C1.02787 10.8817 1.17764 13.6491 1.88021 16.3002C2.0047 16.6268 2.23459 16.9024 2.53346 17.0836C2.83234 17.2647 3.18313 17.341 3.53023 17.3002C5.60023 16.6002 7.8502 15.7602 10.1902 14.8702C9.9202 13.1902 9.70023 11.5302 9.53023 9.88019Z" fill="#D5F1E7"/>
            <path d="M14.4698 9.87947C16.7098 8.75947 18.8698 7.68948 20.7698 6.75948C20.9829 6.72422 21.2008 6.73303 21.4103 6.7853C21.6198 6.83757 21.8164 6.93218 21.9879 7.06337C22.1593 7.19456 22.3021 7.35953 22.4073 7.54805C22.5126 7.73657 22.5782 7.94468 22.5999 8.1595C23.0089 10.8717 22.8557 13.639 22.1498 16.2895C22.0253 16.6161 21.7955 16.8917 21.4966 17.0728C21.1977 17.254 20.8469 17.3302 20.4998 17.2895C18.4398 16.5895 16.1798 15.7495 13.8398 14.8595C14.0798 13.1895 14.2598 11.5295 14.4698 9.87947Z" fill="#D5F1E7"/>
            <path d="M13.84 14.8496H10.16C9.92003 13.1896 9.70003 11.5296 9.53003 9.84961H14.47C14.26 11.5296 14.08 13.1896 13.84 14.8496Z" stroke="#2DBA87" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M9.52999 9.88019C7.25999 8.77019 5.13997 7.69021 3.25997 6.77021C3.04751 6.73494 2.83008 6.74378 2.62117 6.79615C2.41227 6.84851 2.21638 6.94328 2.04567 7.07459C1.87497 7.2059 1.73306 7.37091 1.62886 7.55939C1.52466 7.74788 1.46037 7.95583 1.43996 8.17023C1.02763 10.8817 1.1774 13.6491 1.87996 16.3002C2.00446 16.6268 2.23435 16.9024 2.53322 17.0836C2.83209 17.2647 3.18289 17.341 3.52999 17.3002C5.59999 16.6002 7.84996 15.7602 10.19 14.8702C9.91996 13.1902 9.69999 11.5302 9.52999 9.88019Z" stroke="#2DBA87" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M14.4701 9.88045C16.7101 8.76045 18.8701 7.69045 20.7701 6.76045C20.9831 6.7252 21.2011 6.734 21.4106 6.78627C21.6201 6.83854 21.8166 6.93316 21.9881 7.06435C22.1596 7.19554 22.3023 7.36051 22.4076 7.54903C22.5128 7.73755 22.5784 7.94566 22.6001 8.16048C23.0092 10.8727 22.856 13.64 22.1501 16.2905C22.0256 16.617 21.7957 16.8927 21.4968 17.0738C21.198 17.2549 20.8472 17.3312 20.5001 17.2905C18.4401 16.5905 16.1801 15.7505 13.8401 14.8605C14.0801 13.1905 14.2601 11.5304 14.4701 9.88045Z" stroke="#2DBA87" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
        <div class="card-body">
          <div
            class="user-name"
            :class="{ 'mb-0': !supporter.user.introductionShort && !checkType }"
            v-text="supporter.user.fullName"
          />
          <div
            v-if="supporter.user.introductionShort"
            class="user-introduction"
            :class="{ 'mb-0': !checkType }"
            v-text="supporter.user.introductionShort"
          />
          <div v-if="checkType" class="user-type">
            <ul class="types">
              <li v-if="supporter.user.tutaeru"><span>伝える</span></li>
              <li v-if="supporter.user.tunagu"><span>つながる</span></li>
              <li v-if="supporter.user.azukaru"><span>預ける</span></li>
            </ul>
          </div>
        </div>
      </div>
      <div class="card-icon">
        <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.92993 1.8501L10.0699 10.0001L1.92993 18.1501" stroke="#2DBA87" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
    </div>
    <div class="user-tags">
      <p class="label-user">特徴</p>
      <ul class="tags">
        <li><span>#地域に詳しい</span></li>
        <li><span>#イベンター</span></li>
      </ul>
    </div>
    <div class="user-hobbies">
      <p class="label-user">興味あること</p>
      <div class="hobbies mb-0">
        <ul>
          <li v-if="supporter.user.isInterestedParenting">
            <div class="hobby">
              <img :src="isParenting">
              <span class="label-user label-user-tag">子育て</span>
            </div>
          </li>
          <li v-if="supporter.user.isInterestedPet">
            <div class="hobby">
              <img :src="isPet">
              <span class="label-user label-user-tag">ペット</span>
            </div>
          </li>
          <li v-if="supporter.user.isInterestedSport">
            <div class="hobby">
              <img :src="isSport">
              <span class="label-user label-user-tag">スポーツ</span>
            </div>
          </li>
          <li v-if="supporter.user.isInterestedCooking">
            <div class="hobby">
              <img :src="isCooking">
              <span class="label-user label-user-tag">料理・グルメ</span>
            </div>
          </li>
          <li v-if="supporter.user.isInterestedFashion">
            <div class="hobby">
              <img :src="isFashion">
              <span class="label-user label-user-tag">おしゃれ好き</span>
            </div>
          </li>
          <li v-if="supporter.user.isInterestedOutdoor">
            <div class="hobby">
              <img :src="isOutdoor">
              <span class="label-user label-user-tag">アウトドア</span>
            </div>
          </li>
          <li v-if="supporter.user.isInterestedBookAndManga">
            <div class="hobby">
              <img :src="isBookAndManga">
              <span class="label-user label-user-tag">本・マンガ</span>
            </div>
          </li>
          <li v-if="supporter.user.isInterestedGame">
            <div class="hobby">
              <img :src="isGame">
              <span class="label-user label-user-tag">ゲーム</span>
            </div>
          </li>
          <li v-if="supporter.user.isInterestedMovieAndArt">
            <div class="hobby">
              <img :src="isMovieAndArt">
              <span class="label-user label-user-tag">映画・アート</span>
            </div>
          </li>
          <li v-if="supporter.user.isInterestedArea">
            <div class="hobby">
              <img :src="isArea">
              <span class="label-user label-user-tag">地域</span>
            </div>
          </li>
          <li v-if="supporter.user.isInterestedBusiness">
            <div class="hobby">
              <img :src="isBusiness">
              <span class="label-user label-user-tag">ビジネス</span>
            </div>
          </li>
          <li v-if="supporter.user.isInterestedTrip">
            <div class="hobby">
              <img :src="isTrip">
              <span class="label-user label-user-tag">旅行</span>
            </div>
          </li>
          <li v-if="supporter.user.isInterestedMusic">
            <div class="hobby">
              <img :src="isMusic">
              <span class="label-user label-user-tag">音楽</span>
            </div>
          </li>
          <li v-if="supporter.user.isInterestedFreeTag">
            <div class="hobby">
              <img :src="isDot">
              <span class="label-user label-user-tag" v-text="supporter.user.isInterestedFreeTag"></span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  props: {
    supporter: {
      type: Object,
      required: true
    },
    isParenting: {
      type: String,
      default: ''
    },
    isPet: {
      type: String,
      default: ''
    },
    isSport: {
      type: String,
      default: ''
    },
    isCooking: {
      type: String,
      default: ''
    },
    isFashion: {
      type: String,
      default: ''
    },
    isOutdoor: {
      type: String,
      default: ''
    },
    isBookAndManga: {
      type: String,
      default: ''
    },
    isGame: {
      type: String,
      default: ''
    },
    isMovieAndArt: {
      type: String,
      default: ''
    },
    isArea: {
      type: String,
      default: ''
    },
    isBusiness: {
      type: String,
      default: ''
    },
    isTrip: {
      type: String,
      default: ''
    },
    isMusic: {
      type: String,
      default: ''
    },
    isDot: {
      type: String,
      default: ''
    },
    isLast: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return { checkType: this.supporter.user.tutaeru || this.supporter.user.tunagu || this.supporter.user.azukaru }
  },
  created () {
    this.titleHeader.setHeight = 50
    this.hiddenHeader.mainContainerUnsetMargin = true
    this.setTitleHeader(this.titleHeader)
    this.setHiddenHeader(this.hiddenHeader)
  },
  computed: {
    ...mapState('mycommu', ['titleHeader', 'hiddenHeader']),
    ...mapState('session', ['communityId']),
    checkHobbies () {
      return this.supporter.user.isInterestedParenting || this.supporter.user.isInterestedPet || this.supporter.user.isInterestedSport ||
          this.supporter.user.isInterestedCooking || this.supporter.user.isInterestedFashion || this.supporter.user.isInterestedOutdoor ||
          this.supporter.user.isInterestedBookAndManga || this.supporter.user.isInterestedGame || this.supporter.user.isInterestedMovieAndArt ||
          this.supporter.user.isInterestedArea || this.supporter.user.isInterestedArea || this.supporter.user.isInterestedBusiness ||
          this.supporter.user.isInterestedTrip || this.supporter.user.isInterestedMusic || this.supporter.user.isInterestedFreeTag
    }
  },
  methods: {
    ...mapActions('mycommu', ['setTitleHeader', 'setHiddenHeader']),
    viewProfile () {
      window.location.href = `/communities/${this.communityId}/users/${this.supporter.user.id}`
    }
  }
}
</script>
<style scoped lang="sass">
  .is-last
    border-bottom: 1px solid #CCCCCC
  .supporters
    border-top: 1px solid #CCCCCC
    background-color: #FFFFFF
    padding: 0px 16px
    .user-card
      display: flex
      align-items: center
      padding: 24px 0px 8px 0px
      justify-content: space-between
      .thumbnail-name
        display: flex
        width: 90%
        .card-avatar
          width: 64px
          height: 74px
          position: relative
          padding-bottom: 10px
          margin-right: 13px
          svg
            position: absolute
            bottom: 0
            left: 50%
            transform: translateX(-50%)
          img
            width: 100%
            border-radius: 50%
            object-fit: cover
        .card-body
          width: 75%
          display: flex
          flex-direction: column
          justify-content: center
          .user-name
            font-style: normal
            font-weight: 300
            font-size: 15px
            line-height: 15px
            color: #273D35
            margin-bottom: 8px
          .user-introduction
            font-size: 12px
            line-height: 20px
            color: #7C7C7C
            margin-bottom: 8px
          .user-type
            .types
              display: flex
              li
                padding: 3px 8px
                border: solid 1px #7c7c7c
                margin-right: 8px
                line-height: 12px
                span
                  font-weight: 600
                  font-size: 12px
                  line-height: 12px
                  vertical-align: text-bottom
                  color: #7c7c7c
      .card-icon
        display: flex
    .user-tags
      .tags
        background: #EAF8F3
        border-radius: 6px
        margin: 8px 0 16px 0
        li
          margin-left: 16px
          display: flex
          padding: 8px 0
          span
            display: flex
            align-items: center
            font-weight: 300
            font-size: 12px
            line-height: 12px
            vertical-align: middle
            line-height: normal
            color: #273D35
    .user-hobbies
      .hobbies
        display: flex
        flex-wrap: wrap
        margin-top: 11px
        margin-bottom: 14px
        ul
          display: flex
          flex-wrap: wrap
          .hobby
            display: flex
            align-items: center
            margin-right: 12px
            margin-bottom: 11px
            span
              vertical-align: middle
              line-height: normal
              margin-left: 4px
  .label-user
    font-weight: 300
    font-size: 12px
    line-height: 12px
    color: #273D35
    &-tag
      color: #7c7c7c
  .mb-0
    margin-bottom: unset !important
</style>
