<template>
  <div class="manager">
    <div class="columns">
<!--      <div class="column">-->
<!--        <BButton-->
<!--          class="btn-magager is-fullwidth"-->
<!--          type="is-success"-->
<!--          outlined-->
<!--          @click="redirectToEvent()"-->
<!--        >-->
<!--          <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--            <path d="M15.045 4.91998L10.8675 27.5775H18.15L26.0925 31.455L37.1325 30.69L40.905 8.12248L33.5475 8.63998L22.0125 5.78248L15.045 4.91998Z" fill="#D5F1E7"/>-->
<!--            <path d="M6.8025 44.535L15.045 4.91998" stroke="#2DBA87" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--            <path d="M10.8675 27.5775C21.8475 27.5775 25.0575 33.5175 37.14 30.6975L41.3175 8.03998C29.2425 10.8675 26.025 4.91998 15.045 4.91998" stroke="#2DBA87" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--          </svg>-->
<!--          <p>イベント作成</p>-->
<!--        </BButton>-->
<!--      </div>-->
      <div class="column notification">
        <BButton
          class="btn-magager is-fullwidth"
          type="is-success"
          outlined
          @click="redirectToAssignedStaff()"
        >
          <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M23.94 18.99C28.2727 18.99 31.785 15.4777 31.785 11.145C31.785 6.81231 28.2727 3.29999 23.94 3.29999C19.6073 3.29999 16.095 6.81231 16.095 11.145C16.095 15.4777 19.6073 18.99 23.94 18.99Z" fill="#D5F1E7" stroke="#2DBA87" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M26.7375 43.65C25.8525 43.6725 24.9225 43.6875 23.94 43.6875C10.14 43.6875 6.0825 41.3775 6.0825 38.73C6.0825 36.0825 12.8325 24.99 23.94 24.99C26.085 24.99 28.0575 25.4025 29.865 26.085" stroke="#2DBA87" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M28.62 35.025L33.63 38.7375L41.385 27.63" stroke="#2DBA87" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <p>スタッフアサイン</p>
          <div class="notification-count">
            <span>1</span>
          </div>
        </BButton>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <BButton
          class="btn-magager is-fullwidth"
          to="#"
          type="is-success"
          outlined
        >
          <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_6053_18978)">
              <path d="M7.89751 6.51748V42.54L12.7275 44.6475L29.625 43.935C29.625 43.935 29.07 34.0425 28.875 32.625C28.7818 31.9474 38.25 33.1301 39.1101 31.125C41.9563 24.4898 39.7125 6.50998 39.7125 6.50998L37.0275 5.78998L10.5225 5.22748L7.89001 6.50998L7.89751 6.51748Z" fill="#D5F1E7"/>
              <path d="M30.2625 44.1525H11.28C9.795 44.1525 8.595 42.9525 8.595 41.4675V8.47501C8.595 6.99001 9.795 5.79001 11.28 5.79001H37.0275C38.5125 5.79001 39.7125 6.99001 39.7125 8.47501V32.0775" stroke="#2DBA87" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M16.86 18.585H31.455" stroke="#2DBA87" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M16.86 28.08H22.0125" stroke="#2DBA87" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M39.7125 32.0775H29.2125V44.145C35.025 44.145 39.7125 40.1175 39.7125 32.0775V32.0775Z" stroke="#2DBA87" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
              <clipPath id="clip0_6053_18978">
                <rect width="48" height="48" fill="white"/>
              </clipPath>
            </defs>
          </svg>
          <p>活動報告</p>
        </BButton>
      </div>
      <div class="column">
        <BButton
          class="btn-magager is-fullwidth"
          type="is-success"
          outlined
          @click="gotoLink(`/communities/${currentCommunity.community_id}/managements/expenses/events`)"
        >
          <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_6053_18964)">
              <path d="M23.9999 44.25C34.7695 44.25 43.4999 35.3517 43.4999 24.375C43.4999 13.3984 34.7695 4.50003 23.9999 4.50003C13.2304 4.50003 4.49994 13.3984 4.49994 24.375C4.49994 35.3517 13.2304 44.25 23.9999 44.25Z" fill="#D5F1E7"/>
              <path d="M24 43.905C35.0636 43.905 44.0325 34.9361 44.0325 23.8725C44.0325 12.8089 35.0636 3.84 24 3.84C12.9364 3.84 3.9675 12.8089 3.9675 23.8725C3.9675 34.9361 12.9364 43.905 24 43.905Z" stroke="#2DBA87" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M18.5925 22.425H29.4075" stroke="#2DBA87" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M18.5925 28.335H29.4075" stroke="#2DBA87" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M24 33.51V22.425L19.2 14.2425" stroke="#2DBA87" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M27.48 16.6275L28.8 14.2425" stroke="#2DBA87" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
              <clipPath id="clip0_6053_18964">
                <rect width="48" height="48" fill="white"/>
              </clipPath>
            </defs>
          </svg>
          <p>経費申請</p>
        </BButton>
      </div>
    </div>
    <div class="column-footer" @click="gotoLink('https://local-leaders.asmama.jp/', true)">
      <div class="icon-footer">
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M27.63 28.695C25.185 28.695 22.755 28.695 20.31 28.695C19.83 25.395 19.395 22.095 19.05 18.795H28.89C28.59 22.095 28.14 25.395 27.63 28.695Z" fill="#D5F1E7" stroke="#2DBA87" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M19.0799 18.855L6.62996 12.66C6.20589 12.5989 5.7739 12.6229 5.35921 12.7306C4.94451 12.8383 4.55545 13.0275 4.21471 13.2872C3.87397 13.547 3.58833 13.872 3.37462 14.2433C3.1609 14.6147 3.02336 15.0249 2.96996 15.45C2.06405 20.8349 2.37149 26.3541 3.86992 31.605C4.13611 32.2444 4.60023 32.7817 5.19414 33.1381C5.78804 33.4944 6.48049 33.6511 7.16994 33.585C11.2349 32.19 15.69 30.585 20.34 28.755C19.86 25.395 19.4249 22.095 19.0799 18.855Z" fill="#D5F1E7" stroke="#2DBA87" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M28.92 18.855L41.37 12.66C41.7949 12.5987 42.2277 12.6226 42.6434 12.7301C43.059 12.8376 43.4491 13.0267 43.7911 13.2863C44.133 13.5459 44.4199 13.8708 44.6352 14.2423C44.8505 14.6137 44.9897 15.0242 45.045 15.4499C45.9433 20.8352 45.636 26.3528 44.145 31.605C43.8742 32.2441 43.4069 32.7805 42.8109 33.1365C42.2149 33.4924 41.5212 33.6495 40.83 33.585C36.78 32.19 32.31 30.5849 27.66 28.7549C28.14 25.3949 28.59 22.095 28.92 18.855Z" fill="#D5F1E7" stroke="#2DBA87" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
      <a href="#">シェア・コンシェルジュ専用サイト</a>
      <p>シェア・コンシェルジュの活動内容が解ります。</p>
    </div>
  </div>
</template>

<script>

import { mapActions, mapState } from 'vuex'

export default {
  props: {
  },
  computed: {
    ...mapState('mycommu', ['titleHeader', 'hiddenHeader', 'currentCommunity']),
    checkInput () {
      return (this.name === null || this.name === '')
    }
  },
  data () {
    return {
      activeTab: 0
    }
  },
  created () {
  },
  mounted () {
    this.toggerMyCommuButton(false)
    // this.toggerHeader(true)
    this.titleHeader.display = true
    this.titleHeader.content = 'シェア・コンシェルジュ管理画面'
    this.titleHeader.setHeight = 50
    this.hiddenHeader.mainContainerUnsetMargin = true
    this.hiddenHeader.tabGroup = true
    this.hiddenHeader.searchTop = true
    this.hiddenHeader.searchBottom = true
    this.hiddenHeader.plus = true
    this.hiddenHeader.notificationAndMessage = true
    this.setHiddenHeader(this.hiddenHeader)
    this.setTitleHeader(this.titleHeader)
  },
  methods: {
    ...mapActions('mycommu', [
      'toggerMyCommuButton',
      'toggerHeader',
      'setTitleHeader',
      'setHiddenHeader'
    ]),
    redirectToEvent () {
      window.location.href = `/communities/${this.currentCommunity.community_id}/managements/events`
    },
    redirectToAssignedStaff () {
      window.location.href = `/communities/${this.currentCommunity.community_id}/managements/staff_candidates`
    },
    gotoLink (url, target = false) {
      if (target) {
        window.open(url, '_blank')
      } else {
        window.location.href = url
      }
    }
  }
}
</script>
<style scoped lang="sass">
  @import '~stylesheets/resources'
  .manager
    margin: 24px 24px
    .columns
      display: flex
      margin: 0px 0px 16px 0px
      .column
        padding: unset
        margin: 0px 8px
        .btn-magager
          background: #FFFFFF
          color: #D5F1E7
          box-shadow: 0px 2px 8px #F2EFDE
          border: none
          height: 148px
          border-radius: 6px
          aspect-ratio: 1 / 1
          +app-mobile
            aspect-ratio: unset
          p
            color: #2DBA87
            font-weight: 700
            font-size: 15px
            margin-top: 15px
      .notification
        position: relative
        .notification-count
          background: red
          width: 20px
          height: 20px
          color: #ffffff
          border-radius: 50%
          object-fit: cover
          flex-shrink: 0
          position: absolute
          top: 10px
          right: 10px
          display: flex
          align-items: center
          justify-content: center
          span
            line-height: normal
            vertical-align: middle
            font-size: 12px
    .column-footer
      background: #FFFFFF
      box-shadow: 0 2px 8px #F2EFDE
      text-align: center
      margin: 0 8px
      border-radius: 6px
      .icon-footer
        padding: 24px 0px 16px 0px
      a
        color: #2DBA87
        font-size: 15px
        font-weight: 700
      p
        font-size: 12px
        padding: 8px 0px 24px 0px
</style>
