<template>
  <div>
    <div class="my-commu-domain card-event">
      <a :href="path">
        <div class="image-preview">
          <img :src="image" loading="lazy"/>
        </div>
        <div class="event-status">
          <div class="may-join" v-if="eventStatus === 'unpublished'">
            <div class="is-flex">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_11955_16970)">
                  <path d="M5.48047 4.58666V2.38666" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
                  <path d="M10.5195 4.58666V2.38666" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
                  <path d="M2 6.93335H13.8267" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
                  <path d="M14.2929 10.9467C14.1354 11.7063 13.7519 12.4006 13.1927 12.9384C12.6335 13.4762 11.9248 13.8323 11.1595 13.96C9.05731 14.1533 6.94179 14.1533 4.83956 13.96C4.07481 13.8328 3.3666 13.4767 2.80839 12.9388C2.25018 12.4008 1.86822 11.7062 1.71289 10.9467C1.54622 9.458 1.54622 7.95536 1.71289 6.46668C1.86994 5.70794 2.25248 5.01433 2.81038 4.47665C3.36828 3.93897 4.07554 3.5823 4.83956 3.45335C6.94209 3.26669 9.05701 3.26669 11.1595 3.45335C11.9244 3.58194 12.6327 3.93835 13.1917 4.47598C13.7508 5.0136 14.1345 5.70741 14.2929 6.46668C14.4529 7.95573 14.4529 9.45763 14.2929 10.9467V10.9467Z" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
                </g>
                <defs>
                  <clipPath id="clip0_11955_16970">
                    <rect width="16" height="16" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
              <div class="deadline-text">申し込み期限</div>
              <div class="deadline-time">{{ event.request_expired_at + 'まで' }}</div>
            </div>
            <div class="ev-status">
              非公開
            </div>
          </div>
          <div class="may-join" v-if="eventStatus === 'may-join'">
            <div class="is-flex">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_11955_16970)">
                  <path d="M5.48047 4.58666V2.38666" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
                  <path d="M10.5195 4.58666V2.38666" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
                  <path d="M2 6.93335H13.8267" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
                  <path d="M14.2929 10.9467C14.1354 11.7063 13.7519 12.4006 13.1927 12.9384C12.6335 13.4762 11.9248 13.8323 11.1595 13.96C9.05731 14.1533 6.94179 14.1533 4.83956 13.96C4.07481 13.8328 3.3666 13.4767 2.80839 12.9388C2.25018 12.4008 1.86822 11.7062 1.71289 10.9467C1.54622 9.458 1.54622 7.95536 1.71289 6.46668C1.86994 5.70794 2.25248 5.01433 2.81038 4.47665C3.36828 3.93897 4.07554 3.5823 4.83956 3.45335C6.94209 3.26669 9.05701 3.26669 11.1595 3.45335C11.9244 3.58194 12.6327 3.93835 13.1917 4.47598C13.7508 5.0136 14.1345 5.70741 14.2929 6.46668C14.4529 7.95573 14.4529 9.45763 14.2929 10.9467V10.9467Z" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
                </g>
                <defs>
                  <clipPath id="clip0_11955_16970">
                    <rect width="16" height="16" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
              <div class="deadline-text">申し込み期限</div>
              <div class="deadline-time">{{ event.request_expired_at + 'まで' }}</div>
            </div>
            <div class="ev-status">
              募集中
            </div>
          </div>
          <div class="waiting" v-if="eventStatus === 'waiting'">
            <div class="is-flex">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_11955_16970)">
                  <path d="M5.48047 4.58666V2.38666" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
                  <path d="M10.5195 4.58666V2.38666" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
                  <path d="M2 6.93335H13.8267" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
                  <path d="M14.2929 10.9467C14.1354 11.7063 13.7519 12.4006 13.1927 12.9384C12.6335 13.4762 11.9248 13.8323 11.1595 13.96C9.05731 14.1533 6.94179 14.1533 4.83956 13.96C4.07481 13.8328 3.3666 13.4767 2.80839 12.9388C2.25018 12.4008 1.86822 11.7062 1.71289 10.9467C1.54622 9.458 1.54622 7.95536 1.71289 6.46668C1.86994 5.70794 2.25248 5.01433 2.81038 4.47665C3.36828 3.93897 4.07554 3.5823 4.83956 3.45335C6.94209 3.26669 9.05701 3.26669 11.1595 3.45335C11.9244 3.58194 12.6327 3.93835 13.1917 4.47598C13.7508 5.0136 14.1345 5.70741 14.2929 6.46668C14.4529 7.95573 14.4529 9.45763 14.2929 10.9467V10.9467Z" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
                </g>
                <defs>
                  <clipPath id="clip0_11955_16970">
                    <rect width="16" height="16" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
              <div class="deadline-text">申し込み期限</div>
              <div class="deadline-time">{{ event.request_expired_at + 'まで' }}</div>
            </div>
            <div class="ev-status">
              キャンセル待ち
            </div>
          </div>
          <div class="expired" v-if="eventStatus === 'expired'">
            <div class="ev-status">
              募集終了
            </div>
          </div>
          <div class="closed" v-if="eventStatus === 'closed'">
            <div class="ev-status">
              開催済
            </div>
          </div>
          <div class="joined" v-if="eventStatus === 'joined'">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#2DBA87" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M7.98047 13.16L11.1305 15.5L16.0205 8.5" stroke="#2DBA87" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <div>予約済み・参加確定</div>
          </div>
          <div class="waiting-join" v-if="eventStatus === 'waiting-join'">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#CCCCCC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M7.98047 13.16L11.1305 15.5L16.0205 8.5" stroke="#CCCCCC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <div>予約済み・キャンセル待ち</div>
          </div>
        </div>
        <div class="card-container">
          <div class="thumnnail-name">
            <div class="thumnnail-type">
              <svg width="48" height="48" viewBox="-1 -1 50 50" xmlns="http://www.w3.org/2000/svg">
                <circle cx="24" cy="24" r="24" />
                <path d="M15.4004 34.27L19.5204 14.46" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path
                    d="M17.4404 25.7899C22.9204 25.7899 24.5304 28.79 30.5704 27.34L32.6605 16.02C26.6605 17.43 25.0105 14.46 19.5305 14.46"
                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>

              <span>{{ event.event_type }}</span>
            </div>
            <div class="thumnnail-time">
              <span>{{event.createdAtFormatDot}}</span>
            </div>
          </div>
          <div class="title">
            <span>{{event.title}}</span>
          </div>
          <div class="raw-body">
            <span v-html="event.short_body">
            </span>
            <span v-if="seeMore" class="see-more">もっと見る</span>
          </div>
          <div class="card-event-footer">
            <ul>
              <li v-if="event.address !== ''">
                <div class="card-event-footer-container">
                  <span class="card-event-footer-container-icon">
                    <svg width="24" height="24" viewBox="-1 -1 26 26" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="12" cy="12" r="12" fill="#D5F1E7"/>
                      <path
                          d="M15.6396 13.309C16.1751 12.6419 16.5207 11.8428 16.6397 10.9958C16.7588 10.1489 16.6469 9.28551 16.316 8.49679C15.9851 7.70806 15.4474 7.02323 14.7595 6.51451C14.0717 6.0058 13.2593 5.69205 12.4079 5.60639C12.0976 5.57521 11.785 5.57521 11.4746 5.60639C10.4147 5.71789 9.42238 6.18004 8.65515 6.91948C7.88792 7.65893 7.38973 8.63331 7.23962 9.68802C7.1527 10.3244 7.19685 10.9718 7.36939 11.5905C7.54193 12.2091 7.8392 12.786 8.24293 13.2857L11.9413 18.0437L15.6396 13.309Z"
                          stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
                      <path
                          d="M11.9596 12.3177C12.852 12.3177 13.5754 11.5946 13.5754 10.7026C13.5754 9.81053 12.852 9.0874 11.9596 9.0874C11.0672 9.0874 10.3438 9.81053 10.3438 10.7026C10.3438 11.5946 11.0672 12.3177 11.9596 12.3177Z"
                          stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </span>
                  <div class="events-info-header-element-label">
                    <span>開催場所：</span>
                  </div>
                  <div class="events-info-header-element-label">
                    <div class="flex-column white-space-normal">
                      <span>
                        {{event.shortAddress}}
                      </span>
                      <span>
                        {{event.placeAddress}}
                      </span>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div class="card-event-time-container">
                  <span class="card-event-footer-container-icon">
                    <svg width="24" height="24" viewBox="-1 -1 26 26" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="12" cy="12" r="12" fill="#D5F1E7"/>
                      <path
                          d="M11.9997 18.6666C15.6816 18.6666 18.6663 15.6818 18.6663 11.9999C18.6663 8.31802 15.6816 5.33325 11.9997 5.33325C8.31778 5.33325 5.33301 8.31802 5.33301 11.9999C5.33301 15.6818 8.31778 18.6666 11.9997 18.6666Z"
                          stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M12 8.04004V12L14.1333 13.74" stroke-width="1.3" stroke-linecap="round"
                            stroke-linejoin="round" />
                    </svg>
                  </span>
                  <div class="events-info-header-element-label">
                    <span>開催日時：</span>
                  </div>
                  <div class="events-info-header-element-label">
                    <div :class="{'flex-column': event.schedules_time.length !== 1}">
                      <span>
                        {{event.schedules_date_no_year_and_times}}
                      </span>
                      <span v-for="(time, index) in event.schedules_time" :key="index">
                        {{ time }}
                      </span>
                    </div>
                  </div>

                </div>
              </li>
<!--              <li>-->
<!--                <div class="card-event-footer-container">-->
<!--                <span class="card-event-footer-container-icon">-->
<!--                  <svg width="24" height="24" viewBox="-1 -1 26 26" xmlns="http://www.w3.org/2000/svg">-->
<!--                    <circle cx="12" cy="12" r="12" fill="#D5F1E7"/>-->
<!--                    <path-->
<!--                        d="M12.0001 10.6133C13.3993 10.6133 14.5335 9.47909 14.5335 8.07996C14.5335 6.68084 13.3993 5.54663 12.0001 5.54663C10.601 5.54663 9.4668 6.68084 9.4668 8.07996C9.4668 9.47909 10.601 10.6133 12.0001 10.6133Z"-->
<!--                        stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />-->
<!--                    <path-->
<!--                        d="M12.0002 12.5466C15.5869 12.5466 17.7603 16.1266 17.7603 16.98C17.7603 17.8333 16.4269 18.58 12.0002 18.58C7.57358 18.58 6.24023 17.8333 6.24023 16.98C6.24023 16.1266 8.42024 12.5466 12.0002 12.5466Z"-->
<!--                        stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />-->
<!--                  </svg>-->
<!--                </span>-->
<!--                  <span class="card-event-footer-container-title">対象年齢：</span>-->
<!--                  <span class="card-event-footer-container-content">大人のみ</span>-->
<!--                </div>-->
<!--              </li>-->
            </ul>
          </div>
        </div>
      </a>
      <div
        class="bottom-actions"
        :class="{'justify-content-end': !joined}"
      >
        <div v-if="joined" class="card-event-footer-container">
          <a :href="path">
            <span class="card-event-footer-container-icon">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#2DBA87" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M7.97998 13.16L11.13 15.5L16.02 8.5" stroke="#2DBA87" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </span>
            <span class="card-event-footer-container-title">参加済み</span>
            <span class="card-event-footer-container-content"></span>
          </a>
        </div>
        <div
          class="event-actions-favories"
          @click="bookmark"
        >
          <span>
            <svg v-if="isBookmark" width="16" height="22" viewBox="0 0 16 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12.9999 19.97L8.63989 16.2C8.46096 16.0529 8.23651 15.9725 8.00488 15.9725C7.77326 15.9725 7.54887 16.0529 7.36993 16.2L2.99994 19.97C2.85613 20.0785 2.68562 20.1462 2.50653 20.1658C2.32744 20.1855 2.14639 20.1563 1.98248 20.0815C1.81858 20.0068 1.67793 19.8891 1.57538 19.741C1.47283 19.5928 1.4122 19.4198 1.3999 19.2401V2.76001C1.3999 2.49479 1.50527 2.24045 1.69281 2.05292C1.88035 1.86538 2.13469 1.76001 2.3999 1.76001H13.6699C13.9351 1.76001 14.1895 1.86538 14.377 2.05292C14.5646 2.24045 14.6699 2.49479 14.6699 2.76001V19.23C14.6695 19.4233 14.613 19.6122 14.5074 19.774C14.4019 19.9359 14.2517 20.0636 14.075 20.1419C13.8984 20.2202 13.7028 20.2457 13.512 20.2151C13.3212 20.1846 13.1434 20.0995 12.9999 19.97Z" fill="#F78F54" stroke="#F78F54" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <svg v-else width="16" height="22" viewBox="0 0 16 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M13.0004 19.97L8.64038 16.2C8.46145 16.0529 8.237 15.9725 8.00537 15.9725C7.77375 15.9725 7.54936 16.0529 7.37042 16.2L3.00043 19.97C2.85661 20.0785 2.68611 20.1462 2.50702 20.1658C2.32793 20.1855 2.14688 20.1563 1.98297 20.0815C1.81906 20.0068 1.67841 19.8891 1.57587 19.741C1.47332 19.5928 1.41269 19.4198 1.40039 19.2401V2.76001C1.40039 2.49479 1.50576 2.24045 1.6933 2.05292C1.88083 1.86538 2.13517 1.76001 2.40039 1.76001H13.6704C13.9356 1.76001 14.19 1.86538 14.3775 2.05292C14.565 2.24045 14.6704 2.49479 14.6704 2.76001V19.23C14.67 19.4233 14.6135 19.6122 14.5079 19.774C14.4024 19.9359 14.2522 20.0636 14.0755 20.1419C13.8988 20.2202 13.7033 20.2457 13.5125 20.2151C13.3217 20.1846 13.1439 20.0995 13.0004 19.97Z"
                stroke="#CCCCCC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'

export default {
  components: {},
  props: {
    path: {
      type: String,
      required: true
    },
    event: {
      type: Object,
      required: true
    }
  },
  data () {
    return { iconColor: 'white', isBookmark: this.event.bookmark }
  },
  computed: {
    image () {
      if (this.event.images[0] !== undefined) {
        return this.event.images[0].url
      } else {
        return this.event.default_image
      }
    },
    eventStatus () {
      if (this.event) {
        if (this.event.isPublished) {
          if (this.event.status === 'finished') {
            return 'closed'
          } else if (this.event.user_request_status === 'applied') {
            return 'joined'
          } else if (this.event.user_request_status === 'waiting') {
            return 'waiting-join'
          } else if (this.event.status === 'expired') {
            return 'expired'
          } else if (this.event.status === 'over-capacity') {
            return 'waiting'
          } else {
            return 'may-join'
          }
        } else {
          return 'unpublished'
        }
      }
    },
    mayJoin () {
      return !this.event.is_request_expired && this.event.user_request_status === 'no_joined' && this.event.status !== 'finished'
    },
    join () {
      return this.event.user_request_status === 'neutral' && this.event.status !== 'finished'
    },
    joined () {
      return (this.event.user_request_status === 'waiting' || this.event.user_request_status === 'neutral') && this.event.status === 'finished'
    },
    noJoined () {
      return (this.event.user_request_status === 'applied' || this.event.user_request_status === 'no_joined')
    },
    seeMore () {
      return this.event.short_body.length === 100
    },
    ...mapState('mycommu', ['currentCommunity'])
  },
  mounted () {

  },
  methods: {
    bookmark () {
      if (this.isBookmark) {
        axios.delete(`/communities/${this.currentCommunity.community_id}/events/${this.event.id}/un_bookmark`).then((res) => {
          if (res.status) {
            this.isBookmark = !this.isBookmark
          }
        })
      } else {
        axios.post(`/communities/${this.currentCommunity.community_id}/events/${this.event.id}/bookmark`).then((res) => {
          if (res.status) {
            this.isBookmark = !this.isBookmark
          }
        })
      }
    }
  }
}
</script>
<style scoped lang="sass">
@import '~stylesheets/resources'
.card-event
  position: relative
  width: 92%
  margin: auto
  border-radius: 12px
  background: #FFFFFF
  margin-bottom: 24px
  .bottom-actions
    display: flex
    justify-content: space-between
    padding: 0px 20px 26px 20px
    align-items: center
    .card-event-footer-container
      width: 95%
      a
        display: flex
        align-items: center
        color: #2DBA87
        .card-event-footer-container-title
          font-size: 15px
          line-height: 15px
          margin-left: 8px
        .card-event-footer-container-icon
          display: flex
    .event-actions-favories
      span
        display: flex
  .justify-content-end
    justify-content: end
  .image-preview
    width: 100%
    line-height: 0
    img
      width: 100%
      border-radius: 12px 12px 0px 0px
      aspect-ratio: 1/1
      object-fit: cover

  .card-container
    margin-left: 20px
    margin-right: 20px
    margin-bottom: 8px

    .thumnnail-name
      margin-top: 24px
      display: flex
      align-items: center
      margin-bottom: 15px
      font-size: 12px

      .thumnnail-type
        display: flex
        align-items: center
        span
          margin-left: 8px
          color: #273D35

        svg
          stroke: #2DBA87
          fill: #D5F1E7

      .thumnnail-time
        margin-left: auto
        color: #AAAAAA
    .title
      color: #273D35
      font-size: 16px
      font-weight: bold
    .raw-body
      font-weight: 300
      font-size: 14px
      color: #273D35
      margin-top: 24px
      margin-bottom: 16px
      word-break: break-word
      .see-more
        font-weight: bold
    .card-event-footer
      color: #273D35
      display: flex
      font-weight: bold
      font-size: 12px
      .card-event-footer-container-title
        font-size: 14px
      .card-event-footer-container
        align-items: flex-start
        display: flex
        margin-top: 8px
      .card-event-time-container
        display: flex
        white-space: nowrap
        margin-top: 8px
      .card-event-footer-container-title
        color: #2DBA87
      .card-event-footer-container-content
        color: #273D35
        font-size: 14px
      .card-event-footer-container-icon
        margin-right: 8px
        svg
          fill: none
          stroke: #2DBA87
      .coin-icon
        margin-right: 8px
        padding: 0 4px
.events-info-header-element-label
  color: #2DBA87
  font-weight: bold
  font-size: 14px
  .flex-column
    display: flex
    flex-direction: column
  .events-info-header-element-value
    color: #273D35
    font-weight: bold
    font-size: 14px
.event-status
  .may-join
    padding: 8px
    background: #2DBA87
    width: 100%
    color: white
    display: flex
    justify-content: space-between
    align-items: center
    svg
      margin-right: 4px
    .deadline-text
      font-size: 12px
      margin-right: 4px
    .deadline-time
      font-size: 12px
      font-weight: bold
    .ev-status
      color: #2DBA87
      background: #FFFFFF
      padding: 1px 11px
      font-size: 12px
      font-weight: bold
  .waiting
    padding: 8px
    background: #2DBA87
    width: 100%
    color: white
    display: flex
    justify-content: space-between
    align-items: center
    svg
      margin-right: 4px
    .deadline-text
      font-size: 12px
      margin-right: 4px
    .deadline-time
      font-size: 12px
      font-weight: bold
    .ev-status
      color: #2DBA87
      background: #FFFFFF
      padding: 1px 11px
      font-size: 12px
      font-weight: bold
  .expired
    padding: 8px
    background: #EEEEEE
    width: 100%
    color: white
    display: flex
    justify-content: right
    align-items: center
    .ev-status
      color: #7C7C7C
      background: #F3F3F3
      padding: 0px 10px
      font-size: 12px
      font-weight: bold
      border: 1px solid #7C7C7C
  .closed
    padding: 8px
    background: #CCCCCC
    width: 100%
    color: white
    display: flex
    justify-content: right
    align-items: center
    .ev-status
      color: #7C7C7C
      background: #F3F3F3
      padding: 0px 10px
      font-size: 12px
      font-weight: bold
      border: 1px solid #7C7C7C
  .joined
    padding: 8px
    background: #FFFFFF
    width: 100%
    color: #2DBA87
    display: flex
    align-items: center
    font-size: 15px
    svg
      margin-right: 8px
  .waiting-join
    padding: 8px
    background: #FFFFFF
    width: 100%
    color: #2DBA87
    display: flex
    align-items: center
    font-size: 15px
    svg
      margin-right: 8px
.white-space-normal
  display: flex
  white-space: normal !important
</style>
