<template>
  <div>
    <MyCommuLoading/>
    <!-- <CardDetailsNews /> -->
    <CardDetailsTopic
      v-if="sharingType == 'topic'"
      :topic="topic"
    />
    <CardDetailsNews
      v-if="sharingType == 'news'"
      :news="news"
    />
    <CardDetailsEvent
      v-if="sharingType == 'events'"
      :event="event"
      :cancel-path="cancelPath"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import CardDetailsNews from '../components/my-commu/details/News'
import CardDetailsTopic from '../components/my-commu/details/Topic'
import CardDetailsEvent from '../components/my-commu/details/Event'

export default {
  components: {
    CardDetailsNews,
    CardDetailsTopic,
    CardDetailsEvent
  },
  props: {
    sharingType: {
      type: String,
      required: false
    },
    id: {
      type: String,
      required: true
    },
    cancelPath: {
      type: String,
      require: false,
      default: '#'
    }
  },
  computed: {
    ...mapState('home', ['topic', 'event', 'news']),
    ...mapState('session', ['communityId'])
  },
  created () {
    this.toggerHeader(true)
    var url_communityId = location.pathname.match(/communities\/(\d+?)\//)[1]
    if (this.sharingType === 'topic') {
      this.fetchTopic({ community: url_communityId, id: this.id })
    }
    if (this.sharingType === 'events') {
      this.fetchEvent({ community: url_communityId, id: this.id })
    }
  },
  mounted () {
    this.toggleIsForcedShowingFooter(true)
  },
  methods: {
    ...mapActions('mycommu', [
      'toggerHeader',
      'toggleIsForcedShowingFooter'
    ]),
    ...mapActions('home', [
      'fetchTopic',
      'fetchEvent'
    ])
  }
}
</script>
<style scoped lang="sass">
</style>
