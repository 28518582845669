<template>
  <div class="base-checkbox">
    <input
      v-model="inputValue"
      type="checkbox"
      :text="label"
      :name="name"
      @input="changeCheck"
    >
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: 'テキストテキスト'
    },
    name: {
      type: String,
      default: 'check-box'
    },
    value: {
      type: [Boolean],
      default: false
    }
  },
  data () {
    return {
      inputValue: this.value
    }
  },
  methods: {
    changeCheck () {
      this.$emit('input', !this.inputValue)
    }
  }

}
</script>

<style lang="sass" scoped>
input
  appearance: none
  position: relative
  width: 18.5px !important
  height: 18.5px !important
  background: white
  border: 1px solid #7C7C7C
  border-radius: 7px
  &:checked
    background: #D5F1E7
  &::before
    content: ""
    position: absolute
    width: 16px
    height: 16px
    border-radius: 7px
  &:checked
    &::before
      background-image: url("../../images/for_community/checked.png")
      background-position: center
      background-size: 10px
      background-repeat: no-repeat
input::after
  content: attr(text)
  position: absolute
  width: 100%
  margin-left: 29px
  margin-top: -3px
  font-size: 15px
  color: #273D35
  white-space: nowrap
</style>
