<template>
  <div >
    <div
      v-if="!isMyCommuDomain"
      class="topic-form-container"
    >
      <slot />
      <FaceIcon
        class="is-small"
        :src="user.avatarPath"
      />
      <form
        class="form"
        :action="url"
        method="post"
        enctype="multipart/form-data"
      >
        <input
          type="hidden"
          :name="csrfParam"
          :value="csrfToken"
        >
        <div
          v-if="nicknameAvailable"
          class="nickname-container"
        >
          <BRadio
            v-model="useNickname"
            :name="useNicknameProps.name"
            :native-value="false"
          >
            実名
          </BRadio>
          <BRadio
            v-model="useNickname"
            :name="useNicknameProps.name"
            :native-value="true"
          >
            ニックネーム
          </BRadio>
          <BField
            v-if="useNickname && !!nickname"
            class="nickname-input"
          >
            <BInput
              :name="nicknameProps.name"
              :disabled="true"
              :value="nickname"
              placeholder="投稿者名"
              expanded
            />
            <p class="control">
              <button
                type="button"
                class="button is-primary"
                title="投稿者名を変更する"
                @click="openEditNicknameModal"
              >
                <i class="fas fa-pencil" />
              </button>
              <Portal to="modal">
                <BModal
                  :active.sync="isEditNicknameModalActive"
                  class="edit-nickname-modal"
                  has-modal-card
                >
                  <EditNicknameForm
                    :name="writerName"
                    @nickname-updated="onNicknameUpdated"
                  />
                </BModal>
              </Portal>
            </p>
          </BField>
          <BInput
            v-else
            class="nickname-input"
            :name="nicknameProps.name"
            :disabled="writerNameDisabled"
            placeholder="投稿者名"
          />
        </div>
        <BSelect
          v-if="labelProps && labelProps.isRequired"
          v-model="label"
          :name="labelProps.name"
          class="select-label"
          placeholder="ラベル"
        >
          <option />
          <option
            v-for="labelOpt in labelProps.collection"
            :key="labelOpt[1]"
            :value="labelOpt[1]"
            v-text="labelOpt[0]"
          />
        </BSelect>
        <BInput
          v-if="titleProps && titleProps.isRequired"
          :name="titleProps.name"
          class="title-topic"
          placeholder="タイトル"
        />
        <textarea
          :name="bodyProps.name"
          :placeholder="placeholder"
          rows="4"
        />
        <div class="form-photos">
          <div
            v-for="(image, idx) in images"
            :key="idx"
            class="form-photo"
            :style="{ backgroundImage: `url(${images[idx]})` }"
          />
        </div>
        <div class="form-buttons">
          <input
            type="submit"
            class="button is-primary is-rounded"
            :value="submitLabel"
          >
          <button
            v-for="(props, idx) in imageProps"
            :key="idx"
            type="button"
            class="button is-rounded file-choose-button"
            :class="imageChooseButtonClass(idx)"
          >
          </button>
        </div>
        <button
          v-if="!canChooseMorePhoto"
          type="button"
          class="button is-rounded file-choose-button disabled visible"
        >
          <i class="far fa-camera is-mr-2" />
          写真
        </button>
      </form>
    </div>
    <div
      v-else
      class="topic-form-container commu-domain"
    >
      <!-- <slot /> -->
      <form
        class="form"
        :action="url"
        method="post"
        enctype="multipart/form-data"
      >
        <input
          type="hidden"
          :name="csrfParam"
          :value="csrfToken"
        >
        <div class="oneline-comment">
          <Mentionable
            :keys="[]"
            :items="mentionableList"
            offset="6"
          >
            <textarea
              class="comment-field"
              :name="bodyProps.name"
              :placeholder="placeholder"
              rows="1"
              :value="mentionName"
            />
          </Mentionable>
          <div class="form-buttons">
            <input
              type="submit"
              class="button is-primary"
              :value="submitLabel"
            >
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Rails from 'rails-ujs'
import EditNicknameForm from 'components/EditNicknameForm'
import { Mentionable } from 'vue-mention'

export default {
  components: { EditNicknameForm, Mentionable },
  props: {
    user: {
      type: Object,
      required: true
    },
    mentionName: {
      type: String,
      default: ''
    },
    url: {
      type: String,
      required: true
    },
    bodyProps: {
      type: Object,
      required: true
    },
    titleProps: {
      type: Object,
      default: null
    },
    labelProps: {
      type: Object,
      default: null
    },
    imageProps: {
      type: Array,
      required: true
    },
    useNicknameProps: {
      type: Object,
      default: null
    },
    nicknameProps: {
      type: Object,
      required: true
    },
    nicknameAvailable: {
      type: Boolean,
      required: true
    },
    placeholder: {
      type: String,
      required: true
    },
    submitLabel: {
      type: String,
      default: '投稿'
    },
    toggleViewEdit: {
      type: Function,
      default: () => {}
    },
    isMyCommuDomain: {
      type: Boolean,
      default: false
    },
    mentionableList: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      text: '',
      images: this.imageProps.map(img => img.value),
      removedImages: this.imageProps.map(_ => false),
      label: this.labelProps && this.labelProps.value,
      useNickname: false,
      isEditNicknameModalActive: false,
      nickname: this.user.nickname
    }
  },
  computed: {
    csrfToken () {
      return Rails.csrfToken()
    },
    csrfParam () {
      return Rails.csrfParam()
    },
    imageChooseButtonClass () {
      return idx => ({ visible: this.isNextImage(idx) })
    },
    canChooseMorePhoto () {
      return this.imageProps.length > this.images.length
    },
    writerName () {
      return this.useNickname ? this.nickname : this.user.fullName
    },
    writerNameDisabled () {
      return !!this.writerName
    }
  },
  methods: {
    isNextImage (idx) {
      return this.images.length === idx
    },
    fileChoosed (ev) {
      const file = ev.target.files[0]
      this.images.push(URL.createObjectURL(file))
    },
    openEditNicknameModal () {
      this.isEditNicknameModalActive = true
    },
    onNicknameUpdated (name) {
      this.nickname = name
    }
  }
}
</script>

<style scoped lang="sass">
@import '~stylesheets/resources'

.mention-item
  padding: 4px 10px
  border-radius: 4px

.mention-selected
  background: rgb(192, 250, 153)

.commu-domain
  display: block
  .oneline-comment
    display: flex
    justify-content: space-between
    min-width: 340px
    margin-right: 10px
    .form-buttons
      margin-left: 5px
      margin-top: 0px !important
      .button.is-primary
        background: #2DBA87 !important
        box-shadow: 0px 2px 8px #F2EFDE
        border-radius: 6px !important
        min-height: 35px
    .comment-field
      min-height: 35px
      min-width: 290px
      width: 100%

.topic-form-container
  display: flex
  flex-wrap: wrap
  .face-icon
    min-width: 37px
  .form
    flex: 1
    display: flex
    flex-direction: column
    margin-left: 10px
    .select-label,.title-topic
      margin-bottom: 8px
    textarea
      border-radius: 3px
      border: 1px solid #d7d7d7
      padding: 6px 12px
      resize: none
      font-size: 14px
    .form-buttons
      display: flex
      margin-top: 8px
      .file-choose-button
        position: relative
        border-color: #363636
        display: none
        margin-left: 9px
        &.disabled
          color: #999
          border-color: #999
        &.visible
          display: block
        .file-choose-input
          position: absolute
          top: 0
          left: 0
          width: 100%
          height: 100%
          appearance: none
          opacity: 0
          cursor: pointer
    .form-photos
      display: flex
      flex-wrap: wrap
    .form-photo
      width: 70px
      height: 70px
      margin: 6px
      border-radius: 6px
      background-repeat: no-repeat
      background-position: center
      background-size: cover
  .nickname-container
    margin-bottom: 8px
    label
      margin-bottom: 8px
</style>
