<template>
  <div
    v-if="myCommuFooterMenu"
    class="main-nav my-commu-domain"
    :class="{'d-none': !display }"
  >
    <ul>
      <li :class="{ 'is-current': !navPaths.schedule.isCurrent && (navPaths.home.isCurrent || navPaths.group.isCurrent || navPaths.history.isCurrent || navPaths.share.isCurrent )}">
        <a v-bind:href="home_path" class="left-icon">
          <span class="icon-home">
            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_2679_8589)">
                <path
                  d="M20.4292 10.6201L19.0227 21.6201C18.9808 22.0064 18.7974 22.3633 18.5081 22.6219C18.2189 22.8805 17.8442 23.0224 17.4566 23.0201H6.42421C6.03663 23.0224 5.66198 22.8805 5.3727 22.6219C5.08342 22.3633 4.90008 22.0064 4.85812 21.6201L3.45166 10.6201" />
                <path
                  d="M20.4292 10.6201L19.0227 21.6201C18.9808 22.0064 18.7974 22.3633 18.5081 22.6219C18.2189 22.8805 17.8442 23.0224 17.4566 23.0201H6.42421C6.03663 23.0224 5.66198 22.8805 5.3727 22.6219C5.08342 22.3633 4.90008 22.0064 4.85812 21.6201L3.45166 10.6201"
                  stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path
                  d="M14.7034 21.7401V18.8601C14.7034 18.4198 14.5289 17.9976 14.2184 17.6863C13.9078 17.375 13.4867 17.2001 13.0475 17.2001H10.8131C10.374 17.2001 9.95275 17.375 9.64222 17.6863C9.33169 17.9976 9.15723 18.4198 9.15723 18.8601V21.7401" />
                <path
                  d="M14.7034 21.7401V18.8601C14.7034 18.4198 14.5289 17.9976 14.2184 17.6863C13.9078 17.375 13.4867 17.2001 13.0475 17.2001H10.8131C10.374 17.2001 9.95275 17.375 9.64222 17.6863C9.33169 17.9976 9.15723 18.4198 9.15723 18.8601V21.7401"
                  stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path
                  d="M21.9452 10.0701L14.5238 3.07013C13.8367 2.41004 12.9218 2.0415 11.9702 2.0415C11.0185 2.0415 10.1037 2.41004 9.41657 3.07013L1.99512 10.0701H21.9452Z"
                  stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </g>
              <defs>
                <clipPath id="clip0_2679_8589">
                  <rect width="24" height="24" />
                </clipPath>
              </defs>
            </svg>

          </span>
          <span class="nav-label" v-text="navPaths.home.label" />
        </a>
      </li>
      <li :class="{ 'is-current': navPaths.schedule.isCurrent}">
        <a :href="navPaths.schedule.path">
          <span class="icon-bell">
            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_10318_20202)">
                <g clip-path="url(#clip1_10318_20202)">
                <path d="M20.4401 2.02002H4.56006V21.98H20.4401V2.02002Z" stroke="#AAAAAA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M8.25 9.56982H16.75" stroke="#AAAAAA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M8.25 13.77H16.75" stroke="#AAAAAA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M8.58008 17.9702H13.0801" stroke="#AAAAAA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M8.58008 2.74023V4.51023C8.58008 5.01023 9.22008 5.42023 10.0201 5.42023H14.9901C15.7801 5.42023 16.4301 5.01023 16.4301 4.51023V2.74023" stroke="#AAAAAA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
                </g>
                <defs>
                <clipPath id="clip0_10318_20202">
                <rect width="24" height="24" fill="white" transform="translate(0.5)"/>
                </clipPath>
                <clipPath id="clip1_10318_20202">
                <rect width="24" height="24" fill="white" transform="translate(0.5)"/>
                </clipPath>
                </defs>
            </svg>
          </span>
          <span class="nav-label">{{ navPaths.schedule.label }}</span>
        </a>
      </li>
      <li :class="{ 'is-current': navPaths.usersMap.isCurrent}">
        <a :href="navPaths.usersMap.path">
          <span class="icon-bell">
            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_18412_33217)">
                <g clip-path="url(#clip1_18412_33217)">
                  <path d="M15.7913 9.87988C16.5913 8.84988 16.9513 7.49988 16.6313 6.22988C16.2013 4.49988 14.8013 3.06988 12.8713 2.89988C12.7313 2.88988 12.5913 2.87988 12.4513 2.87988C12.3113 2.87988 12.1713 2.87988 12.0313 2.89988C9.97135 3.08988 8.50135 4.70988 8.20135 6.59988C8.02135 7.75988 8.39135 8.92988 9.11135 9.85988L12.4613 14.1599L15.7913 9.86988V9.87988Z" stroke="#AAAAAA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M17.5462 13.4102H21.2062L23.0863 21.7102H1.90625L3.78625 13.4102H7.47625" stroke="#AAAAAA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M2.92188 17.27H22.0219" stroke="#AAAAAA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M12.4531 18V21.71" stroke="#AAAAAA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
              </g>
              <defs>
                <clipPath id="clip0_18412_33217">
                  <rect width="24" height="24" fill="white" transform="translate(0.5)"/>
                </clipPath>
                <clipPath id="clip1_18412_33217">
                  <rect width="24" height="24" fill="white" transform="translate(0.5)"/>
                </clipPath>
              </defs>
            </svg>
          </span>
          <span class="nav-label">地図で見る</span>
        </a>
      </li>
      <li :class="{ 'is-current': navPaths.profile.isCurrent }">
        <a :href="`/communities/profile`">
          <span class="icon-profile">
            <svg v-if="navPaths.profile.isCurrent" width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_10671_15692)">
                <path d="M12.5 22C18.0228 22 22.5 17.5228 22.5 12C22.5 6.47715 18.0228 2 12.5 2C6.97715 2 2.5 6.47715 2.5 12C2.5 17.5228 6.97715 22 12.5 22Z" fill="#D5F1E7" stroke="#2DBA87" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
                <path d="M9.36997 9.47998C9.5246 9.47998 9.64997 9.60534 9.64997 9.75998V10.44C9.64997 10.5946 9.5246 10.72 9.36997 10.72C9.21533 10.72 9.08997 10.5946 9.08997 10.44V9.75998C9.08997 9.60534 9.21533 9.47998 9.36997 9.47998Z" fill="#273D35" stroke="#2DBA87"/>
                <path d="M15.64 9.47998C15.7946 9.47998 15.92 9.60534 15.92 9.75998V10.44C15.92 10.5946 15.7946 10.72 15.64 10.72C15.4853 10.72 15.36 10.5946 15.36 10.44V9.75998C15.36 9.60534 15.4853 9.47998 15.64 9.47998Z" fill="#273D35" stroke="#2DBA87"/>
                <path d="M10.23 15.21C10.4836 15.5792 10.8232 15.8811 11.2196 16.0897C11.616 16.2983 12.0571 16.4073 12.505 16.4073C12.9529 16.4073 13.3941 16.2983 13.7904 16.0897C14.1868 15.8811 14.5264 15.5792 14.78 15.21" stroke="#2DBA87" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
              </g>
              <defs>
                <clipPath id="clip0_10671_15692">
                  <rect width="24" height="24" fill="white" transform="translate(0.5)"/>
                </clipPath>
              </defs>
            </svg>
            <svg v-else width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style="fill: none">
              <path
                d="M9.64984 9.75998C9.64984 9.3292 9.30063 8.97998 8.86984 8.97998C8.43906 8.97998 8.08984 9.3292 8.08984 9.75998V10.44C8.08984 10.8708 8.43906 11.22 8.86984 11.22C9.30063 11.22 9.64984 10.8708 9.64984 10.44V9.75998Z"
              />
              <path
                d="M15.9199 9.75998C15.9199 9.3292 15.5706 8.97998 15.1399 8.97998C14.7091 8.97998 14.3599 9.3292 14.3599 9.75998V10.44C14.3599 10.8708 14.7091 11.22 15.1399 11.22C15.5706 11.22 15.9199 10.8708 15.9199 10.44V9.75998Z"
              />
              <path
                d="M9.72998 15.21C9.98357 15.5792 10.3232 15.8811 10.7196 16.0897C11.116 16.2983 11.5571 16.4073 12.005 16.4073C12.4529 16.4073 12.8941 16.2983 13.2904 16.0897C13.6868 15.8811 14.0264 15.5792 14.28 15.21"
                stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"
              />
              <path
                d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"
              />
            </svg>
          </span>
          <span class="nav-label">マイページ</span>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  components: {},
  props: {
    navPaths: {
      type: Object,
      required: true
    }
  },
  data () {
    return {}
  },
  computed: {
    ...mapState('mycommu', ['myCommuFooterMenu', 'isShowSchedule']),
    ...mapState('mycommu', ['search']),
    ...mapState('session', ['communityId']),
    display () {
      return true
    },
    home_path () {
      return this.navPaths.home.path
    },
  },
  mounted () {

  },
  methods: {
    ...mapActions('mycommu', ['toggleShowSchedule']),
    isActiveTabs (tab) {
      if (tab === 'community_groups') {
        return window.location.pathname.match(/^\/communities\/.*\/community_groups/)
      } else {
        return false
      }
    },
    action () {
      this.toggleShowSchedule(true)
    }
  }
}
</script>
<style scoped lang="sass">
  @import '~stylesheets/resources'

  $mobile-header-z-index: $over-leaflet-z-index
  $mobile-sidenav-z-index: $over-leaflet-z-index + 2
  $mobile-sidenav-bg-z-index: $over-leaflet-z-index + 1
  $app-mobile-footer-height: 81px
  $primary: #2DBA87
  $gray: #AAAAAA

  svg
    stroke: #AAAAAA
    fill: #ffffff
  .is-current
    svg
      stroke: #2DBA87
      fill: #D5F1E7
      path
        stroke: #2DBA87

  .main-nav ul, .header-notifications ul
    display: inline-flex
    justify-content: space-between
    align-items: stretch
  .main-nav ul li a, .header-notifications ul li a
    position: relative
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    color: $grey
  .main-nav ul li a span, .header-notifications ul li a span
    font-size: 9px
  .main-nav ul li a:hover, .header-notifications ul li a:hover
    background-color: #f9f9f9
    border-radius: 4px
  .main-nav
    display: none
    flex-grow: 1
    > ul
      justify-content: flex-start
      li
        a
          padding: 10px 20px
          &.left-icon
            float: left
            padding-left: 25.5px !important
            padding-right: 25.5px !important
          &.right-icon
            float: right
            padding-left: 25.5px !important
            padding-right: 25.5px !important
        &.is-current a
          color: $primary
  .header-notifications > ul
    justify-content: flex-end
    align-items: stretch
    li a
      padding: 10px 20px
  .logo
    width: 240px
    img
      width: 140px
  .logo.community
    img
      width: 100px
  .icon
    font-size: 22px
  .menu-nav, .back-nav, .user, .header-title
    display: none
  .main-nav ul, .header-notifications ul
    display: flex

  .app-header > .inner .main-nav > ul li a
    padding: 10px 0px

  .main-nav
    position: fixed
    bottom: 0
    left: 0
    width: 100vw
    background-color: #fff
    height: $app-mobile-footer-height
    border-top: 1px solid #eee
    z-index: 10
    padding: 0 0px

    &.my-commu-domain
      &.d-none
        display: none
    ul
      display: flex
      justify-content: space-between !important
      align-items: stretch !important
      height: 50px
    li
      margin: 0 !important
      flex-grow: 1
      flex-basis: 1px
      a
        height: 100%
        display: flex
        justify-content: center
        align-items: center
        flex-direction: column
        line-height: 1
        span
          margin-top: 3px
          font-size: 11px !important
    .user
      .face-icon img
        height: 36px
        width: 36px
      .nav-label
        display: none
</style>
