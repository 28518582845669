<template>
  <div class="corporate-user">
    <div class="detail">
      <div class="cover-container">
        <div
          class="cover"
          :style="coverStyle"
        />
      </div>
      <div class="content">
        <a
          :href="url"
          class="is-primary-link"
          target="_blank"
          v-if="url != ''"
        >
          <img
            :src="logoImageUrl"
            class="logo-image"
          >
        </a>
        <a
          :href="url"
          class="is-primary-link"
          v-else
        >
          <img
            :src="logoImageUrl"
            class="logo-image"
          >
        </a>
        <HeaderH3
          class="is-mb-0"
          v-text="fullName"
        />
        <a
          :href="url"
          class="is-primary-link"
          target="_blank"
          v-text="url"
        />

        <HeaderH3
          class="is-mb-0"
          v-text="introductionTitle"
        />
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div class="introduction" v-html="introduction"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    fullName: {
      type: String,
      required: true
    },
    coverStyle: {
      type: String,
      required: true
    },
    url: {
      type: String,
      default: ''
    },
    introductionTitle: {
      type: String,
      default: ''
    },
    introduction: {
      type: String,
      default: ''
    },
    logoImageUrl: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped lang="sass">
@import '~stylesheets/resources'
.corporate-user
  margin: 0 20px
  border-radius: 20px
  overflow: hidden
  height: 100%
  .detail
    overflow: scroll
    max-height: 100%

    .cover-container
      position: relative
      .cover
        display: flex
        justify-content: center
        align-items: center
        height: 185px
        background-size: cover
        background-position: center
        background-repeat: no-repeat

    .content
      padding: 20px
      background: #fff

    .logo-image
      width: 100px
      height: 100px
      border: 1px solid #ccc
</style>
