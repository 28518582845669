<template>
  <div class="event-item">
    <div class="event-header">
      <div class="event-item-detail">
        <div class="event-item-content">
          <div
            class="event-item-title"
            v-text="title"
          />
          <div class="event-detail">
            <div class="event-info">
              <img src="../images/icons/calendar.svg" alt="calendar">
              <span class="is-ml-2">申し込み期限</span>
              <span class="has-text-weight-bold is-ml-2">2020年12月12日まで</span>
            </div>
            <div
              v-if="statusLabel"
              class="event-item-status"
              v-text="statusLabel"
            />
          </div>
        </div>
      </div>
      <div
        class="event-item-thumb-container"
        :style="{ 'backgroundImage': `url(${primaryImageUrl})` }"
      >
      </div>
      <div class="event-item-info">
        <div class="date">2021.11.10</div>
        <img src="../images/icons/favorite.svg" alt="favorite">
      </div>
    </div>
    <div class="event-body">
      <h2 class="is-mb-4">みそのファミリー運動会！in浦和美園4丁目公園～Misono Family …</h2>
      <div class="event-info">
        <img src="../images/icons/area.svg" alt="area">
        <span class="text-green">開催場所</span>
        <span>：浦和美園4丁目公園</span>
      </div>
      <div class="event-info">
        <img src="../images/icons/time-circle.svg" alt="time-circle">
        <span class="text-green">開催日時</span>
        <span>：10月24日 (日) 11:00〜12:00</span>
      </div>
      <div class="event-info">
        <img src="../images/icons/people-circle.svg" alt="people-circle">
        <span class="text-green">対象年齢</span>
        <span>：大人のみ</span>
      </div>
      <div class="event-info">
        <img src="../images/icons/coin.svg" alt="coin">
        <span>参加費</span>
        <span>：1,000円</span>
      </div>
      <p class="is-mt-4">お知らせです！「小路とつながる集合住宅」品川区二葉建物の周囲の小路やテラスからはみ出す植栽がなんとも楽しげ。小路を抜けて、自分だけの秘密基地へ帰ってくるような気分が味わえる forRENT。</p>
      <p class="is-mt-4">ダミーテキストダミーダミーテキストダミーダミーテキストダミーダミーテキストダミーダミーテキスミーテキストダミーダミーテキストダミーダミーテキスミーテキストダミーダミーテキストダミーダミーテキスト</p>
      <p class="vacuum-cleaner is-mt-4">#掃除機</p>
      <div class="cancel-request">
        <a href="#" class="btn btn-cancel">交流イベント予約をキャンセルする</a>
      </div>
      <div class="event-detail">
        <h2 class="is-pt-4 is-pb-4">交流イベント詳細</h2>
        <div class="more-info">
          <h3 class="is-pt-3 is-pb-3">何かの項目名</h3>
          <p class="is-pb-3">ダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストミーテキストダミーテキストダミーテキストダミー</p>
        </div>
        <div class="more-info">
          <h3 class="is-pt-3 is-pb-3">何かの項目名</h3>
          <p class="is-pb-3">ダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストミーテキストダミーテキストダミーテキストダミー</p>
        </div>
      </div>
      <div class="sns-share">
        <a class="share-button" href="#">
          <img src="../images/icons/facebook.svg" alt="facebook">
        </a>
        <a class="share-button" href="#">
          <img src="../images/icons/twitter.svg" alt="twitter">
        </a>
        <a class="share-button" href="#">
          <img src="../images/icons/line.svg" alt="line">
        </a>
      </div>
      <div class="create-request">
        <a href="#" class="btn btn-join">交流イベントに参加する</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    primaryImageUrl: {
      type: String,
      required: true
    },
    status: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      default: ''
    },
    month: {
      type: String,
      required: true
    },
    day: {
      type: String,
      required: true
    },
    dayOfTheWeek: {
      type: String,
      required: true
    },
    times: {
      type: Array,
      required: true
    },
    address: {
      type: String,
      default: ''
    },
    placeName: {
      type: String,
      default: ''
    },
    eventPath: {
      type: String,
      required: true
    },
    ticketCount: {
      type: Number,
      default: null
    },
    generationFilters: {
      type: Array,
      default: () => []
    },
    isMyCommuDomain: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    statusLabel () {
      switch (this.status) {
        case 'accepting':
          return this.ticketCount > 0 ? `参加者募集中（${this.ticketCount}人が参加予定）` : '参加者募集中'
        case 'expired':
          return '募集締切'
        case 'over-capacity':
          return 'キャンセル待ち'
        case 'in-session':
          return '開催中'
        case 'finished':
          return '開催済み'
        case 'unpublished':
          return '非公開'
        default:
          return ''
      }
    },
    isAccepting () {
      return this.status === 'accepting' || this.status === 'over-capacity'
    },
    scheduleProps () {
      return {
        month: this.month,
        day: this.day,
        dayOfTheWeek: this.dayOfTheWeek,
        times: this.times
      }
    }
  }
}
</script>

<style scoped lang="sass">
@import '~stylesheets/resources'

.event-item
  display: flex
  flex-direction: column
  padding: 0
  border: 1px solid #d7d7d7
  margin-bottom: 20px
  border-radius: $radius-size
  box-shadow: 0 2.5px 2.5px 1.25px #bbb
  background-color: #fff

  .event-item-thumb-container
    position: relative
    background-size: cover
    background-position: center
    background-repeat: no-repeat
    padding-top: 300px
    +app-mobile
      padding-top: 200px

  .event-item-generation-filters
    position: absolute
    top: 10px
    left: 10px
    display: flex
    flex-direction: column
    +app-mobile
      top: 5px
      left: 5px
    span
      margin-bottom: 0.25rem
      background-color: rgba(255, 123, 0, .7)
      font-weight: 700

  .event-item-detail
    display: flex
    padding: 0.5rem 1rem
    background: #2DBA87
    color: white
    font-size: 12px
    +app-mobile
      padding: 0.5rem 0.5rem

    .event-item-content
      flex: 1
      overflow-x: hidden

      .event-item-title
        font-size: 18px
        font-weight: 700
        line-height: 1.5
        max-height: 3rem /* line-height * 2 */
        overflow-y: hidden

      .event-item-status
        font-size: 0.75rem
        color: #b01f24

      .event-item-body
        font-size: 0.85rem
        line-height: 1.2
        margin: 4px 0

      .event-item-foot
        display: flex
        justify-content: space-between

      .event-item-address
        font-size: 0.75rem
        line-height: 1.2

      .event-item-place
        font-size: 0.75rem
        color: #7a7a7a

      .event-item-entry-button
        align-self: flex-end

      .event-detail
        display: flex
        justify-content: space-between
        .event-info
          display: flex

  .event-item-info
    display: flex
    align-items: center
    justify-content: space-between
    margin: 15px
    .schedule
      display: flex
      span
        align-self: center
.event-body
  margin: 15px
  font-size: 15px
  h2
    font-size: 16px
    font-weight: 600
  h3
    font-size: 15px
    font-weight: 700

  .event-info
    font-size: 14px
    font-weight: 700
    margin: 5px 0px

  .vacuum-cleaner
    font-size: 12px
    color: #7C7C7C

  .event-detail
    background: #F8F7F2
    margin: 0px -15px
    padding: 15px
    .more-info
      border-top: 1px solid #CCCCCC

  .sns-share
    margin: 20px 0px
    display: flex
    justify-content: space-between
    .share-button
      display: flex
      align-items: center
      justify-content: center
      width: 30%
      height: 45px
      border-radius: 6px
      box-shadow: 0px 2px 8px #f2efde

  .cancel-request, .create-request
    margin: 20px 0px
    display: flex
    justify-content: center

  .btn
    padding: 10px 20px
    font-size: 15px
    font-weight: 600
    background: #FFFFFF
    box-shadow: 0px 2px 8px #f2efde
    border-radius: 6px
    border: none

  .btn-cancel
    color: #EE5353

  .btn-join
    color: #2DBA87

</style>
