<template>
  <div class="gg-map">
    <Map2
      :lat="lat"
      :lng="lng"
      :mark-community="false"
      :mark-event="false"
      :mark-organization="false"
      :mark-user="false"
      :mark-supporter="false"
      :mark-event-for-my-commu="true"
      :mark-organization-for-my-commu="true"
      :mark-user-for-my-commu="true"
      :mark-supporter-for-my-commu="true"
      :zoom="13"
      @click-marker="onClickMarker"
    />
    <BModal :active.sync="isModalActive">
      <div
        v-if="hasSelectedCard"
        class="modal-body"
      >
        <MapModalCard
          v-for="selectedCard in selectedCards"
          :key="selectedCard.key"
          v-bind="selectedCard"
          @close-modal="closeModal"
        />
      </div>
    </BModal>
    <button class="see-more" @click="onClickViewMore">もっと見る</button>
  </div>
</template>

<script>
import Map2 from 'components/Map2'
import MapModalCard from 'components/MapModalCard'
import MapAction from 'mixins/MapAction'

export default {
  components: { Map2, MapModalCard },
  mixins: [MapAction],
  props: {
    lat: {
      type: Number,
      default: 35.65858
    },
    lng: {
      type: Number,
      default: 139.745433
    }
  }
}
</script>

<style scoped lang="sass">
@import '~stylesheets/resources'

.modal-body
  max-width: 500px
  max-height: 90%
  width: 90%
  margin: auto
  z-index: 1102
  overflow-y: scroll
  -webkit-overflow-scrolling: touch
.gg-map
  position: relative
  margin-bottom: 35px
.see-more
  font-size: 14px
  border: 0
  padding: 9px
  background-color: white
  color: rgb(45, 186, 135)
  border-radius: 6px
  position: absolute
  bottom: 20px
  right: 28px
  z-index: 500
  font-weight: 600

  /deep/
  .the-map
    height: 240px !important
    margin: 0 16px
    border-radius: 12px
  .leaflet-control-zoom
    display: none
</style>
