const store = {
  namespaced: true,
  state: {
    unsetMargin: {
      mainContainer: false,
      appMain: false
    },
    unsetPadding: {
      mainContainer: false,
    }
  },
  mutations: {
    unsetMargin (state, value) {
      state.unsetMargin = value
    },
    unsetPadding (state, value) {
      state.unsetPadding = value
    }
  },
  actions: {
    setUnsetMargin ({ commit }, value) {
      commit('unsetMargin', value)
    },
    setUnsetPadding ({ commit }, value) {
      commit('unsetPadding', value)
    }
  }
}
export default store
