<template>
  <a
    v-if="src !== ''"
    class="my-commu-domain btn"
    :href="src"
    :style="style"
    @click="$emit('click')"
  >
    <slot/>
    <span
      class="nav-label"
      v-text="label"
    />
  </a>
  <a
    v-else
    class="my-commu-domain btn"
    @click="$emit('click')"
  >
    <slot/>
    <span
      class="nav-label"
      v-text="label"
    />
  </a>
</template>

<script>

export default {
  components: { },
  props: {
    label: {
      type: String,
      required: true
    },
    src: {
      type: String,
      default: ''
    },
    style: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {

  }
}
</script>
<style scoped lang="sass">
  @import '~stylesheets/resources'

  $mobile-header-z-index: $over-leaflet-z-index
  $mobile-sidenav-z-index: $over-leaflet-z-index + 2
  $mobile-sidenav-bg-z-index: $over-leaflet-z-index + 1
  $app-mobile-footer-height: 50px
  $primary: #2DBA87
  $gray: #AAAAAA

  .my-commu-domain
    height: 100%
    color: white
    background: $primary
    box-shadow: 0px 2px 8px #F2EFDE
    border-radius: 6px
    text-align: center
    width: 48%
    margin: 2.5px
    display: inline-block !important
    @media screen and (max-width: 350px)
      width: 45%
    span
      font-weight: bold
      font-size: 16px
  .nav-label
    margin-left: 5px
</style>
