<template>
  <div
    :class="{ 'is-loading': loading }"
    class="my-commu-domain loading "
  >
    <img src="../../images/for_community/spinner.svg">
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  components: {},
  props: {
  },
  data() {
    return {}
  },
  computed: {
    ...mapState('mycommu', ['loading']),
  },
  mounted() {
    this.setLoading(false)
  },
  methods: {
    ...mapActions('mycommu', ['setLoading'])
  }
}
</script>
<style scoped lang="sass">
  .my-commu-domain

    &.loading
      display: none
    &.is-loading
      width: 100%
      position: fixed
      height: 844px
      display: flex
      align-items: center
      justify-content: center
      z-index: 100
      bottom: 0
      left: 0
  .lds-spinner
    color: official
    display: inline-block
    position: relative
    width: 80px
    height: 80px

</style>
