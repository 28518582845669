import axios from '../utils/axios'
import queryString from 'query-string'
import { commuError } from 'lib/app-toast'
import session from '../store/modules/session'

export const fetchGroups = async (community_id, params = null) => {
  let str = ''
  if (params !== null) {
    str += `${queryString.stringify(params)}`
  }
  return await axios.get(`/communities/${session.state.communityId}/community_groups?${str}`)
}

export const getAreaPartners = async (community_id, params = null) => {
  return axios.get(`/api/v1/co/area_partners?id=${session.state.communityId}`);
}

export const fetchTopic = async (community_id, args) => {
  const { group_id, topic_id } = args
  return axios.get(`/communities/${session.state.communityId}/community_groups/${group_id}/community_group_topics/${topic_id}/fetch_show`)
}

export const createComment = (community_id, args) => {
  const { group_id, topic_id } = args
  const body = args['body'] == null ? '' : args['body']
  var formData = new FormData()
  formData.append('comments[body]', body)
  args['file'].forEach((file, index) => {
    formData.append('comments[community_group_topic_comment_images_attributes][' + index + '][image]', file)
  })
  return axios.post(`/communities/${session.state.communityId}/community_groups/${group_id}/community_group_topics/${topic_id}/community_group_topic_comments`, formData).catch((res) => {
    commuError(res)
  })
}

export const likeTopic = (community_id, args) => {
  const {group_id, topic_id} = args
  const data = {}
  return axios.post(`/communities/${session.state.communityId}/community_groups/${group_id}/community_group_topics/${topic_id}/community_group_topic_likes`, data)
}
