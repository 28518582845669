<template>
  <b-modal
    :active.sync="isOpenModal"
    has-modal-card
    trap-focus
    :destroy-on-hide="false"
    aria-role="dialog"
    aria-label="Example Modal"
    close-button-aria-label="Close"
    aria-modal
    class="delete-modal"
  >
    <div class="modal-card">
      <header class="modal-card-head">
        <p>本当に削除しますか？</p>
        <p>この作業は元に戻せません</p>
      </header>
      <div class="modal-card-body">
        <div
          class="card-left"
          @click="closeModal"
        >
          <a href="#">キャンセル</a>
        </div>
        <div class="card-right">
          <a
            :href="deletePath"
            data-method="delete"
          >削除する</a>
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>

export default {
  props: {
    deletePath: {
      type: String,
      required: true
    },
    openModal: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return { isOpenModal: this.openModal }
  },
  watch: {
    openModal () {
      this.isOpenModal = this.openModal
    }
  },
  methods: {
    closeModal () {
      this.isOpenModal = false
      this.$emit('close', true)
    }
  }
}
</script>

<style scoped lang="sass">
  .delete-modal
    /deep/
    .animation-content
      width: 270px
      margin: auto
      .modal-card
        border-radius: 20px
        .modal-card-head
          background-color: #FFFFFF
          display: flex
          flex-direction: column
          justify-content: center
          align-items: center
          padding: 32px 0
          p
            color: #273D35
            font-weight: bold
            font-size: 14px
        .modal-card-body
          display: flex
          padding: unset
        .card-left, .card-right
          width: 50%
          padding: 20px 0
          display: flex
          justify-content: center
          a
            font-size: 15px
        .card-left
          border-right: 1px solid #cccccc
          a
            color: #2DBA87
        .card-right
          a
            color: #EE5353
            font-weight: bold
      .modal-close
        display: none !important
</style>
