<template>
  <div class="status-container" :class="{'gray': isGray, 'special': isSpecial}">
    <div class="status-container-content" v-html="value"></div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    isGray: {
      type: Boolean,
      default: false
    },
    isSpecial: {
      type: Boolean,
      default: false
    }
  }
}

</script>

<style scoped lang="sass">
  .status-container
    max-width: 52px
    height: 18px
    border: 1px solid #F68F54
    color: #F68F54
    font-style: normal
    font-weight: bold
    font-size: 12px
    line-height: 12px
    margin: auto
    display: flex
    justify-content: center
    align-items: center
    &.gray
      background: #F3F3F3
      border: 1px solid #7C7C7C
      color: #7C7C7C
      padding: 0 8px
      max-width: unset
    &.special
      max-width: unset
      height: unset
      line-height: unset
      border: 1px solid #2DBA87
      color: #2DBA87
</style>
