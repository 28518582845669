<template>
  <div>
    <div class="search-content" v-if="!noResult">
      <img src="../../../images/search_icon.png" width="56" height="56">
      <p class="search-content-text" v-html ="text">
      </p>
    </div>
    <div class="search-content" v-if="noResult">
      <img src="../../../images/search_no_result.png" width="56" height="56">
      <p class="search-content-text" v-html ="notFound">
      </p>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    noResult: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      required: true
    },
    notFound: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="sass">
.search-content
  text-align: center
  font-style: normal
  font-weight: 300
  font-size: 15px
  color: #273D35
  margin-top: 50%
  &-text
    margin-top: 32px
</style>
