<template>
  <div class="">
    <div class="is-mt-1">
      <BaseTextInput
        v-bind="emailProps" :is-my-commu-domain="true" :placeholder="this.emailProps.placeholder"
      />
    </div>
    <!-- <div class="is-mt-4">
      <BaseInputPassword
        v-bind="passwordProps" :is-my-commu-domain="true" :placeholder="this.passwordProps.placeholder"
      />
    </div> -->
    <div class="columns is-mobile is-mt-4">
      <div class="column">
        <BField
          :message="zipCodeErrorMessage"
          :type="zipCodeStatus"
          class="is-mb-3"
          label='郵便番号'
        >
          <BInput
            v-model="dirtyZipCode"
            name="profile[zip_code]"
            placeholder="1000013"
            class="is-fullwidth"
            customClass=" input-green"
          />
        </BField>
      </div>
      <div class="column handle-zip-code">
        <BButton
          class="is-fullwidth is-mt-1 bg-white"
          type='is-success'
          outlined
          @click="handleZipCodeKeypress"
        >
          住所検索
        </BButton>
      </div>
    </div>
    <div class="columns is-mobile">
      <div class="column is-fullwidth select-prefecture">
        <BField
          label="都道府県"
          label-for="profile_prefecture_"
          class="is-mb-2"
        >
          <BSelect
            id="profile_prefecture_"
            :class="{'dark-gray-color' : isPlaceholder}"
            v-model="dirtyPrefecture"
            name="profile[prefecture]"
            class="js-geocoding-region is-fullwidth input-green"
          >
            <option value="" disabled selected hidden>選択する</option>
            <option
              v-for="opt in prefectureOptions"
              :key="opt"
              :value="opt"
              v-text="opt"
            />
          </BSelect>
        </BField>
      </div>
      <div class="column"> </div>
    </div>
    <div class="sign-up-address-input-address__form">
      <BField
        label="市区町村"
        label-for="profile_address1_"
      >
        <BInput
          id="profile_address1_"
          v-model="dirtyAddress1"
          name="profile[address1]"
          placeholder="横浜市中区山下町"
          class="js-geocoding-address1"
          customClass="input-green"
        />
      </BField>
    </div>

    <label class="sign-up-label is-mt-4">
      <div class="primary">お名前</div>
    </label>
    <div class="columns is-mobile is-mt-1">
      <div class="column">
        <BField>
          <BInput
            v-model="lastNameModel"
            :name="this.lastNameProps.name"
            :placeholder="this.lastNameProps.placeholder"
            class="is-fullwidth"
            custom-class="input-green"
          />
        </BField>
      </div>
      <div class="column">
        <BField>
          <BInput
            v-model="firstNameModel"
            :name="this.firstNameProps.name"
            :placeholder="this.firstNameProps.placeholder"
            class="is-fullwidth"
            custom-class="input-green"
          />
        </BField>
      </div>
    </div>

    <label class="sign-up-label">
      <div class="primary">フリガナ</div>
    </label>
    <div class="columns is-mobile is-mt-1">
      <div class="column">
        <BField
          :type="{ 'is-danger': this.lastNameKanaProps.errors.length > 0 }"
          :message="this.lastNameKanaProps.errors[0]"
        >
          <BInput
            v-model="lastNameKanaModel"
            :name="this.lastNameKanaProps.name"
            :placeholder="this.lastNameKanaProps.placeholder"
            class="is-fullwidth input-my-commu"
            custom-class="input-green"
            icon-pack="fas"
          />
        </BField>
      </div>
      <div class="column">
        <BField
          :type="{ 'is-danger': this.firstNameKanaProps.errors.length > 0 }"
          :message="this.firstNameKanaProps.errors[0]"
        >
          <BInput
            v-model="firstNameKanaModel"
            :name="this.firstNameKanaProps.name"
            :placeholder="this.firstNameKanaProps.placeholder"
            class="is-fullwidth input-my-commu"
            custom-class="input-green"
            icon-pack="fas"
          />
        </BField>
      </div>
    </div>

    <label class="sign-up-label">
      <div class="primary">性別</div>
    </label>
    <div class="columns is-mt-1 is-flex">
      <div class="column">
        <BaseSelectInput
          v-bind="sexProps"
          placeholder="選択する"
          @validation="sexValidation"
        />
      </div>
      <div class="column">
      </div>
    </div>

    <label class="sign-up-label is-mt-3">
      <div class="primary">生年月（非公開）</div>
    </label>
    <div class="is-mt-1">
      <MyCommuBirthdayDatePicker
        v-bind="dateOfBirthProps"
        :discard-day="true"
        label=""
        year-placeholder="1980"
        month-placeholder="12"
        :validate-day="false"
        @date-validation="dateOfBirthValidate"
      />
    </div>

    <label class="sign-up-label is-mt-4">
      <div class="primary" v-text="mobilePhoneProps.label"/>
      <!-- <div class="primary" v-text="mobilePhoneProps.label" :class="classForLabelByValue(mobilePhoneModel)"/> -->
    </label>
    <div class="is-mt-1">
      <BField
        :type="{ 'is-danger': this.mobilePhoneProps.errors.length > 0 }"
        :message="this.mobilePhoneProps.errors[0]"
      >
        <!-- <BInput
          v-model="mobilePhoneModel"
          :name="this.mobilePhoneProps.name"
          :placeholder="this.mobilePhoneProps.placeholder"
          class="is-fullwidth input-my-commu"
          custom-class="input-green"
          icon-pack="fas"
        /> -->
        <BInput
          v-model="mobilePhoneModel"
          :name="this.mobilePhoneProps.name"
          :placeholder="this.mobilePhoneProps.placeholder"
          class="is-fullwidth input-my-commu"
          custom-class="input-green"
          icon-pack="fas"
        />
      </BField>
    </div>

    <div>
      <p class="f-size-14 color-base font-weight-bold is-mt-4">子どもの情報</p>
      <p class="f-size-12 color-gray">送迎・託児OKの友達にだけ表示されます</p>
      <MyCommuUsersChildren
        v-if="check_present(userChildren)"
        class="mt-16"
        :users-children="userChildren"
        :myself="myself"
      />
      <MyCommuBaseButton
        v-if="myself"
        class="mt-16"
        :is-white-button="isWhiteButton"
        text-content="子どもを追加する"
        @clickButton="redirect('/communities/child_profiles/new')"
      />
    </div>

    <div class="mt-16">
      <!-- <p>支援提供後の謝礼受取用の銀行口座</p> -->
      <p class="f-size-14 color-base font-weight-bold is-mt-4">子育てシェアリング</p>
      <p class="f-size-12 color-gray">謝礼受取口座の情報（非公開）</p>
      <div class="is-flex center-items justify-between">
        <div class="is-flex center-items">
          <i
            v-if="!bankAccountExist"
            class="fal fa-exclamation-triangle"
            :class="!bankAccountExist ? 'error-label' : ''"
          />
          <p
            v-text="bankAccountText"
            :class="!bankAccountExist ? 'error-label' : ''"
          />
        </div>
        <a class="edit-bank-account" @click="redirect('/communities/profile/edit_bank_account')">編集</a>
      </div>
    </div>

    <div class="columns is-mobile is-centered is-mt-5 is-mb-5">
      <div class="column">
        <BButton
          type='submit'
          @click='submit'
          :disabled="checkInput"
          class="is-fullwidth is-success"
          v-text="submitProps.label"
        >
        </BButton>
      </div>
    </div>

  </div>
</template>

<script>
import { findAddressFromPostal } from 'lib/auto_input_address'
import MyCommuBirthdayDatePicker from '../../components/my-commu/BirthdayDatePicker'
import BaseSelectInput from '../../components/BaseSelectInput'
import BaseTextInput from '../../components/BaseTextInput'
import BaseInputPassword from '../../components/my-commu/BaseInputPassword'

export default {
  components: { MyCommuBirthdayDatePicker, BaseSelectInput, BaseTextInput, BaseInputPassword},
  props: {
    myself: {
      type: Boolean,
      default: true
    },
    lastNameProps: {
      type: Object,
      required: true
    },
    firstNameProps: {
      type: Object,
      required: true
    },
    lastNameKanaProps: {
      type: Object,
      required: true
    },
    firstNameKanaProps: {
      type: Object,
      required: true
    },
    nicknameSelected: {
      type: Boolean
    },
    nickname: {
      type: String,
      default: ''
    },
    sexProps: {
      type: Object,
      required: true
    },
    dateOfBirthProps: {
      type: Object,
      required: true
    },
    mobilePhoneProps: {
      type: Object,
      required: true
    },
    emailProps: {
      type: Object,
      required: true
    },
    passwordProps: {
      type: Object,
      required: true
    },
    submitProps: {
      type: Object,
      required: true
    },
    zipCode: {
      type: String,
      default: ''
    },
    prefecture: {
      type: String,
      default: ''
    },
    address1: {
      type: String,
      default: ''
    },
    prefectureOptions: {
      type: Array,
      required: true
    },
    userChildren: {
      type: Array,
      default: () => []
    },
    lackedProfile: {
      type: Boolean,
      default: false
    },
    bankAccountExist: {
      type: Boolean,
      require: true
    }
  },
  data () {
    return {
      lastNameModel: this.lastNameProps.value,
      firstNameModel: this.firstNameProps.value,
      lastNameKanaModel: this.lastNameKanaProps.value,
      firstNameKanaModel: this.firstNameKanaProps.value,
      dateOfBirth: this.dateOfBirthProps.value !== null,
      sexModel: this.dateOfBirthProps.value !== null || this.submitProps.value !== null,
      mobilePhoneModel: this.mobilePhoneProps.value,
      passwordModel: this.passwordProps.value,
      emailModel: this.emailProps.value,
      dirtyZipCode: this.zipCode,
      dirtyPrefecture: this.prefecture,
      dirtyAddress1: this.address1,
      zipCodeErrorMessage: '',
      lackedProfileMessage: '必要な情報を入力して、コミュニティ参加を再開しましょう！',
      isWhiteButton: true,
      bankAccountText: this.bankAccountExist ? '設定しています' : '未設定です',
    }
  },
  computed: {
    checkInput () {
      return (this.lastNameModel === '' || this.firstNameModel === '' || this.lastNameKanaModel === '' || this.firstNameKanaModel === '' || !this.dateOfBirth || !this.sexModel || this.dirtyAddress1 === '' || this.dirtyZipCode === '' || this.dirtyPrefecture === '' || this.emailModel === '' || this.mobilePhoneModel === '' || this.mobilePhoneModel === null)
    },
    zipCodeStatus () {
      return this.zipCodeErrorMessage.length > 0 ? 'is-danger' : null
    },
    isPlaceholder () {
      return this.dirtyPrefecture === ''
    },
  },
  methods: {
    redirect (url) {
      window.location.href = url
    },
    check_present (array) {
      return array.length > 0
    },
    submit () {
      document.querySelector('form').submit()
    },
    dateOfBirthValidate (value) {
      this.dateOfBirth = value
    },
    sexValidation (value) {
      this.sexModel = value
    },
    async fillAddressByZipCode (zipCode) {
      const { address, error } = await findAddressFromPostal(zipCode)
      if (address) {
        this.dirtyPrefecture = address.region
        this.dirtyAddress1 = address.locality + address.street
        this.zipCodeErrorMessage = ''
      } else {
        this.dirtyPrefecture = ''
        this.dirtyAddress1 = ''
        this.zipCodeErrorMessage = error
      }
    },
    handleZipCodeBlur ({ currentTarget }) {
      this.fillAddressByZipCode(currentTarget.value)
    },
    handleZipCodeKeypress (ev) {
      this.fillAddressByZipCode(this.dirtyZipCode)
    },
    submit() {
      document.querySelector('form').submit()
    }
  }
}
</script>
<style lang="sass" scoped>
.edit-bank-account
  align-items: center
  font-style: normal
  font-weight: bold
  font-size: 14px
  line-height: 14px
  text-align: center
  color: #2DBA87
  padding: 9px
  background: #FFFFFF
  box-shadow: 0 2px 8px #F2EFDE
  border-radius: 6px
  border: 0
.center-items
  align-items: center
.justify-between
  justify-content: space-between
.error-label
  color: red
/deep/ .lacked-info
  input, select
    border-color: red
.error-border
  padding: 10px 10px 10px 10px
  border: solid #EE5353
  border-radius: 20px
  background-color: #FFEEF0
  margin-bottom: 20px
.error-message
  color: #EE5353
  font-weight: bold
  text-align: center
.mt-16
  margin-top: 16px
/deep/
  @import '~stylesheets/components/required-label'
  @import '~stylesheets/components/published-label'
  .dark-gray-color
    /deep/
      span
        select
          color: #AAAAAA
  .title-page
    font-size: 20px
  .column
    padding-bottom: unset
    /deep/ label
      font-weight: 300
      font-size: 14px
      line-height: 14px
      margin-bottom: 8px
  .handle-zip-code
    margin-top: 24px
    /deep/ 
      button.is-outlined
        border: unset !important
        box-shadow: 0px 2px 8px #F2EFDE
        border-radius: 6px
    /deep/ &:hover
      color: #2DBA87 !important
      background-color: #D5F1E7
  .sign-up-address-input-address__form
    border-radius: 6px
    /deep/ label
      font-weight: 300
      font-size: 14px
      line-height: 14px
      margin-bottom: 8px
    /deep/
      .field
        margin-bottom: 40px
  .select-prefecture
    /deep/ span
      width: 100%
    /deep/ select
      width: 100%
      border-radius: 6px
      caret-color: #2DBA87
      &:focus
        border: 1px solid #2DBA87
        border-radius: 6px
        box-shadow: 0 0 0 0
  .bg-white
    background: white !important
    border-radius: 6px
  /deep/ .input-green
    border-radius: 6px
    caret-color: #2DBA87
    &:focus
      border: 1px solid #2DBA87
      border-radius: 6px
      box-shadow: 0 0 0 0
  /deep/
    .border-radius-6
      border-radius: 6px
    input
      height: 48px
      font-family: "Hiragino Kaku Gothic ProN"
    .select
      height: 48px !important
      select
        height: 48px
        font-family: "Hiragino Kaku Gothic ProN"
    button
      height: 48px
      font-family: "Hiragino Kaku Gothic ProN"
  .is-success
    /deep/ span
      font-weight: bold
  /deep/ .input-green
    border-radius: 6px
    caret-color: #2DBA87
    &:focus
      border: 1px solid #2DBA87
      border-radius: 6px
      box-shadow: 0 0 0 0

  /deep/ .input-my-commu > span
    color: #2DBA87 !important

  /deep/
    input
      height: 48px
      font-family: "Hiragino Kaku Gothic ProN"
    span
      height: 48px !important
      select
        height: 48px
        font-family: "Hiragino Kaku Gothic ProN"
    button
      height: 48px
      border-radius: 6px
      font-family: "Hiragino Kaku Gothic ProN"
  .columns
    margin-bottom: 0 !important
  .column
    padding: 5px 11px 24px 11px
  .guide
    padding-bottom: 16px
  .sub-guide
    color: #2DBA87
    padding-bottom: 16px
    font-size: 14px
  .is-success
    font-weight: bold
</style>
