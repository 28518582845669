<template>
  <div :class="{ 'is-nav-open': topbar }" class="side-nav">
    <div
      class="background"
      @click="toggerTopbar(false)"/>
    <div class="inner">
      <div class="side-nav-header">
        <div class="profile">
          <div class="profile-name">
            <a>
              <span class="full-name">コミュニティ変更</span>
            </a>
          </div>
        </div>
      </div>

      <div class="side-nav-scroll-area">
        <div class="side-nav-main">
          <ul>
            <li
              v-for="community in communities"
              :key="community.community_id"
              :class="{'active': communityId === community.community_id}"
            >
            <a
              @click="onClick(community.community_id)"
            >
              <img
                :src="community.community_url"
                :alt="community.community_name"
              />
              <span class="full-name-kana">
                {{ community.community_name }}
              </span>
            </a>
            </li>
          </ul>
          <div class="other-community" v-if="otherCommunities.length > 0">未参加コミュニティ</div>
          <ul>
            <li
              v-for="community in otherCommunities"
              :key="community.community_id"
            >
              <div class="flex-between">
                <a>
                  <img
                    :src="community.community_url"
                    :alt="community.community_name"
                  />
                  <span class="full-name-kana">
                    {{ community.community_name }}
                  </span>
                </a>
                <button
                  type="button"
                  class="button is-mb-3 btn-join"
                  @click="redirectToCreateCommuProfile(community.community_id)"
                >
                  参加する
                </button>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  components: {},
  props: {
  },
  data () {
    return {}
  },
  computed: {
    ...mapState('mycommu', ['topbar', 'communities', 'otherCommunities', 'currentCommunity']),
    ...mapState('session', ['communityId'])
  },
  created () {
    // this.getCommunities()
  },
  methods: {
    ...mapActions('mycommu', [
      'toggerTopbar',
      'getCommunities',
      'joinCommunity'
    ]),
    ...mapActions('home', [
      'fetchRecents',
      'changeCommu'
    ]),
    onClick (e) {
      if (window.location.href === '/communities/profile') {
        this.changeCommu({ params: { id: e } })
      } else {
        this.changeCommu({ params: { id: e }, redirect: true })
      }
      this.toggerTopbar(false)
      // window.location.href = '/home'
    },
    // onJoinCommunity (community) {
    //   this.joinCommunity(community)
    // },
    redirectToCreateCommuProfile (community) {
      window.location.href = `/communities/${community}/join_communities/new`
    }
  }
}
</script>
<style scoped lang="sass">
  @import '~stylesheets/resources'

  $mobile-header-z-index: $over-leaflet-z-index
  $mobile-sidenav-z-index: $over-leaflet-z-index + 2
  $mobile-sidenav-bg-z-index: $over-leaflet-z-index + 1
  $app-mobile-footer-height: 50px
  $primary: #2DBA87
  $gray: #AAAAAA

  .side-nav
    width: auto
    z-index: $mobile-sidenav-z-index

    .inner
      width: 100vw
      height: 50vh
      padding: 0 20px 20px
      z-index: $mobile-sidenav-z-index
      background-color: #fff
      box-shadow: 2px 0 10px #333
      position: fixed
      left: 0
      bottom: 0
      transform: translateY(100vh)
      transition: 0.4s
      display: flex
      flex-direction: column
      border-radius: 10px 10px 0px 0px
      z-index: 9999

      .side-nav-header
        padding-top: 27px
        text-align: center

      .side-nav-main
        ul
          li
            padding: 5px 0px
            font-weight: normal
            font-size: 15px
            &.active
              a
                color: #2DBA87
                font-weight: bold
                .full-name-kana
                  color: #2DBA87 !important
                  padding-right: 16px
                &:after
                  content: url("data:image/svg+xml,%3Csvg width='14' height='13' viewBox='0 0 14 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 8.32286L5.70149 12L13 1' stroke='%232DBA87' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A")
                  margin-left: auto
            a
              display: flex
              align-items: center
              img
                width: 64px
                height: 64px
                border-radius: 4px
                margin-right: 16px

    .side-nav-scroll-area
      overflow-y: scroll
      -webkit-overflow-scrolling: touch
    &.is-nav-open
      .background
        width: 100vw
        height: 100vh
        background: #273D35
        opacity: 0.9
        position: absolute
        z-index: 9999
        top: 0
        left: 0
        transition: 0.4s 0.2s
      .inner
        transition: 0.4s 0.2s
        // transform: translate(100vh, 50vh);
        transform: translateY(0vh)
    .side-back
      display: inline-flex
      align-items: center
      justify-content: flex-start
      padding: 20px 0 15px
      .icon
        display: inline-block
        font-size: 24px
      span
        display: inline-block
        font-size: 14px
        margin-left: 5px
        line-height: 14px
  .full-name
    font-weight: bold
  .other-community
    font-weight: bold
    margin: 24px 0
  .button
    color: #2DBA87
    border-radius: 6px
    font-weight: bold
    font-size: 14px
    padding: 9px
    &.btn-join
      border: unset
      box-shadow: 0 2px 8px #F2EFDE
      font-family: "Hiragino Kaku Gothic ProN"
  .flex-between
    display: flex
    align-items: center
    justify-content: space-between
  .full-name, .full-name-kana
    color: #273D35 !important

</style>
