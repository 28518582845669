<template>
  <div class="">
    <MyCommuHiddenButtonFooter :is-my-commu-domain="true"/>
    <MyCommuHiddenFooterMenu :is-my-commu-domain="true"/>
    <p class="guide">依頼者がクレジットカードでの支払いを<br>
      選択できるようになります。<br>
      普通預金口座のみ登録できます。<br>
    </p>

    <label class="sign-up-label is-mt-2">
      <div class="primary">銀行種別</div>
    </label>

    <div class="columns is-mobile mb-24px is-mt-1">
      <div class="column is-fullwidth select-prefecture">
        <MyCommuBaseRadio
          id="bank_type"
          v-model="bankType"
          :label="this.bankTypeProps.label"
          name="bank_account[bank_type]"
          placeholder="選択する"
          :options="bankTypeOptions"
          @click="syncRadioButton"
        />
      </div>
      <div class="column"></div>
    </div>

    <div :class="postAccount ? '' : 'd-none'">
      <label class="sign-up-label is-mt-4">
        <div class="primary" v-text="postBranchCodeProps.label"/>
      </label>
      
      <div class="is-mt-1">
        <BField
          :type="{ 'is-danger': this.postBranchCodeProps.errors.length > 0 }"
          :message="this.postBranchCodeProps.errors[0]"
        >
          <BInput
            type="Number"
            v-model="postBranchCodeModel"
            :name="this.postBranchCodeProps.name"
            :placeholder="this.postBranchCodeProps.placeholder"
            class="is-fullwidth input-my-commu"
            custom-class="input-green"
            icon-pack="fas"
          />
        </BField>
      </div>
  
      <label class="sign-up-label is-mt-4">
        <div class="primary" v-text="postAccountNumberProps.label"/>
      </label>
      
      <div class="is-mt-1">
        <BField
          :type="{ 'is-danger': this.postAccountNumberProps.errors.length > 0 }"
          :message="this.postAccountNumberProps.errors[0]"
        >
          <BInput
            v-model="postAccountNumberModel"
            :name="this.postAccountNumberProps.name"
            type="Number"
            :placeholder="this.postAccountNumberProps.placeholder"
            class="is-fullwidth input-my-commu"
            custom-class="input-green"
            icon-pack="fas"
          />
        </BField>
      </div>
  
      <label class="sign-up-label is-mt-4">
        <div class="primary" v-text="postAccountNameProps.label"/>
      </label>
      
      <div class="is-mt-1">
        <BField
          :type="{ 'is-danger': this.postAccountNameProps.errors.length > 0 }"
          :message="this.postAccountNameProps.errors[0]"
        >
          <BInput
            v-model="postAccountNameModel"
            :name="this.postAccountNameProps.name"
            :placeholder="this.postAccountNameProps.placeholder"
            class="is-fullwidth input-my-commu"
            custom-class="input-green"
            icon-pack="fas"
          />
        </BField>
      </div>
    </div>

    <div :class="postAccount ? 'd-none' : ''">
      <label class="sign-up-label is-mt-4">
        <div class="primary" v-text="bankNameProps.label"/>
      </label>
      
      <div class="is-mt-1">
        <BField
          :type="{ 'is-danger': this.bankNameProps.errors.length > 0 }"
          :message="this.bankNameProps.errors[0]"
        >
          <BInput
            v-model="bankNameModel"
            :name="this.bankNameProps.name"
            :placeholder="this.bankNameProps.placeholder"
            class="is-fullwidth input-my-commu"
            custom-class="input-green"
            icon-pack="fas"
          />
        </BField>
      </div>

      <div class="mt-8" v-if="checkBankName">
        <p class="sub-guide">よくある振込先候補（クリックで銀行名が入力されます）</p>
        <div class="is-flex mb-8">
          <div v-for="example in bankTypeExamples">
            <BaseCheckboxButton
              class="mr-8 mt-8"
              :label="example"
              :value="false"
              :name="example"
              @changeValueByLabel="changeBankName"
            />
          </div>
        </div>
      </div>
  
      <label class="sign-up-label is-mt-4">
        <div class="primary" v-text="bankBranchNameProps.label"/>
      </label>
      
      <div class="is-mt-1">
        <BField
          :type="{ 'is-danger': this.bankBranchNameProps.errors.length > 0 }"
          :message="this.bankBranchNameProps.errors[0]"
        >
          <BInput
            v-model="bankBranchNameModel"
            :name="this.bankBranchNameProps.name"
            :placeholder="this.bankBranchNameProps.placeholder"
            class="is-fullwidth input-my-commu"
            custom-class="input-green"
            icon-pack="fas"
          />
        </BField>
      </div>
  
      <label class="sign-up-label is-mt-4">
        <div class="primary" v-text="bankBranchCodeProps.label"/>
      </label>
      
      <div class="is-mt-1">
        <BField
          :type="{ 'is-danger': this.bankBranchCodeProps.errors.length > 0 }"
          :message="this.bankBranchCodeProps.errors[0]"
        >
          <BInput
            type="Number"
            v-model="bankBranchCodeModel"
            :name="this.bankBranchCodeProps.name"
            :placeholder="this.bankBranchCodeProps.placeholder"
            class="is-fullwidth input-my-commu"
            custom-class="input-green"
            icon-pack="fas"
          />
        </BField>
      </div>

      <label class="sign-up-label is-mt-4">
        <div class="primary" v-text="bankAccountNumberProps.label"/>
      </label>
      
      <div class="is-mt-1">
        <BField
          :type="{ 'is-danger': this.bankAccountNumberProps.errors.length > 0 }"
          :message="this.bankAccountNumberProps.errors[0]"
        >
          <BInput
            v-model="bankAccountNumberModel"
            type="Number"
            :name="this.bankAccountNumberProps.name"
            :placeholder="this.bankAccountNumberProps.placeholder"
            class="is-fullwidth input-my-commu"
            custom-class="input-green"
            icon-pack="fas"
          />
        </BField>
      </div>

      <label class="sign-up-label is-mt-4">
        <div class="primary" v-text="bankAccountNameProps.label"/>
      </label>
      
      <div class="is-mt-1">
        <BField
          :type="{ 'is-danger': this.bankAccountNameProps.errors.length > 0 }"
          :message="this.bankAccountNameProps.errors[0]"
        >
          <BInput
            v-model="bankAccountNameModel"
            :name="this.bankAccountNameProps.name"
            :placeholder="this.bankAccountNameProps.placeholder"
            class="is-fullwidth input-my-commu"
            custom-class="input-green"
            icon-pack="fas"
          />
        </BField>
      </div>
    </div>

    <label class="sign-up-label">
      <div class="primary" v-text="bankAccountNameNote"/>
    </label>

    <div class="columns is-mobile is-centered is-mt-5 is-mb-5">
      <div class="column">
        <BButton
          type='submit'
          @click='submit'
          :disabled="checkInput()"
          class="is-fullwidth is-success"
          v-text="submitLabel"
        >
        </BButton>
      </div>
    </div>

    <div v-if="skipUrl !== '/communities/profile/edit'" class="columns is-mobile is-centered is-mb-5">
      <div class="column text-center">
        <a
          :href="skipUrl"
          class="has-text-success"
        >スキップ</a>
      </div>
    </div>
  </div>
</template>

<script>
import BaseSelectInput from '../../components/BaseSelectInput'
import BaseTextInput from '../../components/BaseTextInput'
import { commuError } from 'lib/app-toast'
import axios from 'axios'

export default {
  components: { BaseSelectInput, BaseTextInput},
  props: {
    postBranchCodeProps: {
      type: Object,
      required: true
    },
    postAccountNameProps: {
      type: Object,
      required: true
    },
    postAccountNumberProps: {
      type: Object,
      required: true
    },
    bankAccountNumberProps: {
      type: Object,
      required: true
    },
    bankAccountNameProps: {
      type: Object,
      required: true
    },
    bankBranchCodeProps: {
      type: Object,
      required: true
    },
    bankBranchNameProps: {
      type: Object,
      required: true
    },
    bankNameProps: {
      type: Object,
      required: true
    },
    bankTypeProps: {
      type: Object,
      required: true
    },
    bankTypeOptions: {
      type: Array,
      default: () => [
        ['ゆうちょ銀行', 'post'],
        ['銀行(ゆうちょ銀行以外)・信用金庫など', 'bank'],
      ]
    },
    bankTypeExamples: {
      type: Array,
      default: () => [
        '三井住友銀行',
        'みずほ銀行',
        '三菱UFJ銀行',
        '三井住友信託銀行',
        '楽天銀行',
        'PayPay銀行',
        'りそな銀行',
      ]
    },
    skipUrl: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      submitLabel: '登録する',
      bankType: this.bankTypeProps.value,
      postBranchCodeModel: this.postBranchCodeProps.value,
      postAccountNameModel: this.postAccountNameProps.value,
      postAccountNumberModel: this.postAccountNumberProps.value,
      bankAccountNameModel: this.bankAccountNameProps.value,
      bankAccountNumberModel: this.bankAccountNumberProps.value,
      bankBranchCodeModel: this.bankBranchCodeProps.value,
      bankNameModel: this.bankNameProps.value,
      bankBranchNameModel: this.bankBranchNameProps.value,
      postAccount: this.bankTypeProps.value === 'post' ? true : false,
      bankAccountNameNote: '全角カナでご入力ください'
    }
  },
  computed: {
    checkBankName () {
      return this.bankNameModel === '' || this.bankNameModel === null
    },
  },
  methods: {
    submit () {
      document.querySelector('form').submit()
    },
    redirectToHome () {
      window.location.href = '/'
    },
    syncRadioButton (value) {
      if (value === 'post') {
        return this.postAccount = true
      } else {
        return this.postAccount = false
      }
    },
    changeBankName (value) {
      this.bankNameModel = value
    },
    checkInput () {
      if (this.postAccount) {
        return this.postBranchCodeModel === null || this.postBranchCodeModel === '' || this.postAccountNameModel === '' || this.postAccountNameModel === null || this.postAccountNumberModel === null || this.postAccountNumberModel === ''
      } else {
        return this.bankAccountNameModel === null || this.bankAccountNameModel === '' || this.bankAccountNumberModel === null || this.bankAccountNumberModel === '' || this.bankBranchCodeModel === null || this.bankBranchCodeModel === '' || this.bankNameModel === null || this.bankNameModel === '' || this.bankBranchNameModel === null || this.bankBranchNameModel === ''
      }
    },
  }
}
</script>
<style lang="sass" scoped>
.d-none
  display: none
.text-center
  text-align: center
.mt-8
  margin-top: 8px
.mr-8
  margin-right: 8px
.is-flex
  display: flex
  flex-wrap: wrap
/deep/ .control-label
  width: 300px
/deep/ .input-my-commu > span
  color: #2DBA87 !important

.columns
  margin-bottom: 0 !important
.column
  padding: 5px 11px 24px 11px
.guide
  padding-bottom: 50px
  text-align: center
.is-success
  font-weight: bold
.sub-guide
  font-size: 12px
</style>
