<template>
  <div>
    <div class="location-container">
      <div class="title-content title-top">公開範囲</div>

      <GroupList
        v-for="community in communities"
        :key="community.id"
        v-bind="community"
        :checked-commu="checkSelectCommu(community.id)"
        :open-commu="(communityId == community.id) && groupId !== 0"
        :group-id="groupId"
        :is-my-commu-domain="isMyCommuDomain"
        :event-commu-group-ids="eventCommuGroupIds"
        @change-selected-community="onCommunityChange"
        @change-selected-group="onCommunityUserChange"
      />

      <div class="btn-area">
        <button @click="back" class="btn btn-white">戻る</button>
        <button @click="nextConFirm"
                class="btn btn-success is-success-my-commu"
                :class="{'disabled': checkDisabled}"
                :disabled="checkDisabled">入力内容確認</button>
      </div>
    </div>
  </div>
</template>

<script>
import GroupList from './GroupList'
import { mapState } from 'vuex'

export default {
  components: { GroupList },
  props: {
    communities: {
      type: Array,
      default: () => []
    },
    eventCommuIds: {
      type: Array,
      default: () => []
    },
    eventCommuGroupIds: {
      type: Array,
      default: () => []
    },
    groupId: {
      type: Number,
      default: 0
    }
  },
  data () {
    const regex = /\/copy$/
    return {
      isMyCommuDomain: true,
      selectedCommunities: [],
      selectedUsers: [],
      firstTimeLoading: true,
      isCopy: regex.test(window.location.href)
    }
  },
  computed: {
    ...mapState('session', ['communityId']),
    checkDisabled () {
      return this.selectedCommunities.length === 0 && this.selectedUsers.length === 0 && !this.firstTimeLoading
    }
  },
  created () {
    this.selectedCommunities = [...this.eventCommuIds]
    this.selectedUsers = [...this.eventCommuGroupIds]
    if ((this.firstTimeLoading === true) && this.groupId === 0 && !this.isCopy) {
      this.selectedCommunities.push(this.communityId)
    }
  },
  mounted () {
    if (this.groupId !== 0) {
      const elem = document.getElementById(`select-${this.groupId}`)
      const input = elem.querySelector("input[type='checkbox']")
      input.click()
    }
  },
  methods: {
    onCommunityChange ({ id, checked }) {
      if (checked) {
        if (this.selectedCommunities.indexOf(id) === -1) {
          this.selectedCommunities.push(id)
        }
      } else {
        this.selectedCommunities = this.selectedCommunities.filter(_ => _ !== id)
      }
      this.firstTimeLoading = false
    },
    onCommunityUserChange ({ groupId, checked }) {
      if (checked) {
        if (this.selectedUsers.indexOf(groupId) === -1) {
          this.selectedUsers.push(groupId)
        }
      } else {
        this.selectedUsers = this.selectedUsers.filter(_ => _ !== groupId)
      }
      this.firstTimeLoading = false
    },

    back () {
      const data = {
        isStep1: false,
        isStep2: true,
        isStep3: false
      }
      this.$emit('backStep2', data)
    },
    nextConFirm () {
      const data = {
        event: { group: this.selectedUsers, communities: this.selectedCommunities },
        isStep1: false,
        isStep2: false,
        isStep3: false
      }
      this.$emit('nextConFirm', data)
    },
    checkSelectCommu (id) {
      if (this.groupId !== 0) {
        return false
      }
      return this.eventCommuIds.includes(id) || (!this.isCopy && this.communityId === id)
    }
  }
}
</script>

<style scoped lang="sass">
  .location-container
    padding: 32px 32px 58px
    .title-content
      font-style: normal
      font-weight: 300
      font-size: 14px
      line-height: 14px
      color: #273D35
      &.title-top
        font-weight: 700
        font-size: 17px
        line-height: 17px
    .title-field-optional, .title-field-switch
      display: flex
      justify-content: space-between
    .title-field-switch
      align-items: center
      /deep/
        .switch
          cursor: unset
          .control-label
            padding-left: unset
        label
          font-weight: 300
          font-size: 14px
          line-height: 14px
          color: #273D35
          display: flex
          flex-direction: row-reverse
        span.check
          height: 31px
          width: 51px
          padding: 2px
          background: rgba(120, 120, 128, 0.16)
          &:before
            width: 27px
            height: 27px
            background: #FFFFFF
            border: 0.5px solid rgba(0, 0, 0, 0.04)
            box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15), 0px 3px 1px rgba(0, 0, 0, 0.06)
        .switch input[type=checkbox]:checked + .check:before
          transform: translate3d(80%, 0, 0)
        input[type=checkbox]:checked + .check
          background: rgba(45, 186, 135, 0.9) !important
    .title-field
      margin-top: 24px
    .entry-fee
      display: flex
      align-items: center
      .yen
        color: #000000
        margin-left: 8px
    .optional
      background-color: #FFFFFF
      color: #2DBA87
      font-style: normal
      font-weight: 700
      font-size: 10px
      line-height: 9px
      border: 1px solid #2DBA87
      padding: 2px
    .input-green
      margin-top: 8px
      input, textarea, select
        border-radius: 6px
        height: 48px
        padding: 16px
        font-size: 15px
        color: #273D35
        border: 1px solid #CCCCCC
        caret-color: #2DBA87
        outline: none
        background: white
        font-family: 'Hiragino Kaku Gothic ProN'
        &::placeholder
          font-weight: 300
          font-size: 15px
          line-height: 20px
          color: #AAAAAA
        &:focus
          border: 1px solid #2DBA87
        &::-webkit-scrollbar
          display: none
      input, select
        width: 50%
        &.w-100
          width: 100%
      textarea
        width: 100%
        resize: none
        height: unset
      select
        padding: 13px 16px
    .memo
      font-size: 12px
      margin-top: 8px
    .btn-area
      display: inline-flex
      width: 100%
      .btn
        font-family: 'Hiragino Kaku Gothic ProN'
        padding: 16px
        border-radius: 6px
        margin-top: 40px
        font-weight: 700
        font-size: 15px
        line-height: 15px
        background: white
        color: #2DBA87
        border: none
        width: 100%
        text-align: center
        box-shadow: 0 2px 8px #F2EFDE
        &.btn-success
          color: white
          background: #2DBA87
          margin-left: 5px
        &.btn-white
          margin-right: 5px
    /deep/
      .field
        height: 48px
        display: flex
        .select
          select
            height: 48px
            font-family: 'Hiragino Kaku Gothic ProN'
        .select:not(.is-multiple):not(.is-loading)::after
          border: 2px solid #273D35
          border-right: 0
          border-top: 0
          margin-top: -0.1375em
  .disabled
    opacity: .3
    cursor: default
    outline: none
</style>
