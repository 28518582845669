<template>
  <a
    class="event-item"
    :href="eventPath"
  >
    <div
      class="event-item-thumb-container"
      :style="{ 'backgroundImage': `url(${primaryImageUrl})` }"
    >
    </div>
    <div class="event-item-detail">
      <div class="event-item-content">
        <div
          class="event-item-title"
          v-text="title"
        />
        <div class="event-detail">
          <div class="event-info">
            <img src="../images/icons/calendar.svg" alt="calendar">
            <span class="is-ml-2">申し込み期限</span>
            <span class="has-text-weight-bold is-ml-2">2020年12月12日まで</span>
          </div>
          <div
            v-if="statusLabel"
            class="event-item-status"
            v-text="statusLabel"
          />
        </div>
      </div>
    </div>
    <div class="event-item-info">
      <div class="schedule">
        <img src="../images/icons/flag.svg" alt="flag">
        <span>交流イベント</span>
      </div>
      <div class="date">2021.11.10</div>
    </div>
  </a>
</template>

<script>
export default {
  props: {
    primaryImageUrl: {
      type: String,
      required: true
    },
    status: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      default: ''
    },
    month: {
      type: String,
      required: true
    },
    day: {
      type: String,
      required: true
    },
    dayOfTheWeek: {
      type: String,
      required: true
    },
    times: {
      type: Array,
      required: true
    },
    address: {
      type: String,
      default: ''
    },
    placeName: {
      type: String,
      default: ''
    },
    eventPath: {
      type: String,
      required: true
    },
    ticketCount: {
      type: Number,
      default: null
    },
    generationFilters: {
      type: Array,
      default: () => []
    },
    isMyCommuDomain: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    statusLabel () {
      switch (this.status) {
        case 'accepting':
          return this.ticketCount > 0 ? `参加者募集中（${this.ticketCount}人が参加予定）` : '参加者募集中'
        case 'expired':
          return '募集締切'
        case 'over-capacity':
          return 'キャンセル待ち'
        case 'in-session':
          return '開催中'
        case 'finished':
          return '開催済み'
        case 'unpublished':
          return '非公開'
        default:
          return ''
      }
    },
    isAccepting () {
      return this.status === 'accepting' || this.status === 'over-capacity'
    },
    scheduleProps () {
      return {
        month: this.month,
        day: this.day,
        dayOfTheWeek: this.dayOfTheWeek,
        times: this.times
      }
    }
  }
}
</script>

<style scoped lang="sass">
@import '~stylesheets/resources'

.event-item
  display: flex
  flex-direction: column
  padding: 0
  border: 1px solid #d7d7d7
  margin-bottom: 20px
  border-radius: $radius-size
  box-shadow: 0 2.5px 2.5px 1.25px #bbb
  background-color: #fff

  .event-item-thumb-container
    border-top-left-radius: $radius-size
    border-top-right-radius: $radius-size
    position: relative
    background-size: cover
    background-position: center
    background-repeat: no-repeat
    padding-top: 300px
    +app-mobile
      padding-top: 200px

  .event-item-generation-filters
    position: absolute
    top: 10px
    left: 10px
    display: flex
    flex-direction: column
    +app-mobile
      top: 5px
      left: 5px
    span
      margin-bottom: 0.25rem
      background-color: rgba(255, 123, 0, .7)
      font-weight: 700

  .event-item-detail
    display: flex
    padding: 0.5rem 1rem
    background: #2DBA87
    color: white
    font-size: 12px
    +app-mobile
      padding: 0.5rem 0.5rem

    .event-item-content
      flex: 1
      overflow-x: hidden

      .event-item-title
        font-size: 18px
        font-weight: 700
        line-height: 1.5
        max-height: 3rem /* line-height * 2 */
        overflow-y: hidden

      .event-item-status
        font-size: 0.75rem
        color: #b01f24

      .event-item-body
        font-size: 0.85rem
        line-height: 1.2
        margin: 4px 0

      .event-item-foot
        display: flex
        justify-content: space-between

      .event-item-address
        font-size: 0.75rem
        line-height: 1.2

      .event-item-place
        font-size: 0.75rem
        color: #7a7a7a

      .event-item-entry-button
        align-self: flex-end

      .event-detail
        display: flex
        justify-content: space-between
        .event-info
          display: flex

  .event-item-info
    display: flex
    align-items: center
    justify-content: space-between
    margin: 15px
    .schedule
      display: flex
      span
        align-self: center
</style>
