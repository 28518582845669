<template>
  <div>
    <div :class="{'is-flex justify-space-between': optionalOrRequire} ">
      <label
        :for="id"
        class="base-label"
        v-text="label"
      />
      <MyCommuOptional v-if="isOptional && !isRequire"/>
      <MyCommuRequire v-if="isRequire && !isOptional"/>
    </div>
    <span
      class="select"
      :class="{'full-width': fullWidth}"
    >
      <select
        :id="id"
        v-model="value"
        :name="name"
        class="base-select"
        :class="{'dark-gray-color' : isPlaceholder}"
        @input="$emit('input', $event.target.value)"
      >
        <option value="" disabled selected hidden>{{ placeholder }}</option>
        <option
          v-for="(opt, key) in options"
          :key="key"
          :value="opt[1]"
          v-text="opt[0]"
        />
      </select>
    </span>
  </div>
</template>

<script>

export default {
  props: {
    name: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: ''
    },
    value: {
      type: [Number, String, Boolean],
      default: ''
    },
    isOptional: {
      type: Boolean,
      default: false
    },
    isRequire: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      default: () => []
    },
    fullWidth: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isPlaceholder () {
      return this.value === ''
    },
    optionalOrRequire () {
      return (this.isOptional && !this.isRequire) || (this.isRequire && !this.isOptional)
    }
  },
  methods: {
  }
}
</script>

<style scoped lang="sass">
.base-label
  display: flex
  margin-bottom: 0.25em
  font-size: 14px
.base-select
  border-radius: 6px
  border: 1px solid #CCCCCC
  width: 100%
  height: 48px
  padding-left: 16px
  font-size: 15px
  line-height: 15px
  &:focus-visible
    outline: none
    border: 1px solid #2DBA87
    border-radius: 6px
    box-shadow: 0 0 0 0
    caret-color: #2DBA87
.select
  width: 100%
  height: unset !important
  &::after
    margin-top: -6!important
.dark-gray-color
  color: #AAAAAA
.justify-space-between
  justify-content: space-between
.full-width
  width: 100%
</style>
