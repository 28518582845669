import axios from 'axios'
import { showSuccess, showError, commuSuccess } from 'lib/app-toast'

/* eslint-disable no-param-reassign */
const store = {
  namespaced: true,
  state: {
    notifications: [],
    openedNotifications: [],
    openedNotificationsCount: 0,
    unopenedNotificationsCount: 0,
    fetched: false,
    friendRequests: [],
    fetchNotificationConfig: { page: 1, filter: 'all' }
  },
  getters: {},
  mutations: {
    setNofications (state, notifications) {
      state.notifications = notifications
    },
    commuSetOpenedNotifications (state, opened) {
      state.openedNotifications = opened
    },
    commuSetUnopenedNotifications (state, unopened) {
      state.unopenedNotifications = unopened
    },
    commuSetOpenedNotificationsCount (state, count) {
      state.openedNotificationsCount = count
    },
    commuSetUnopenedNotificationsCount (state, count) {
      state.unopenedNotificationsCount = count
    },
    setFetched (state, value) {
      state.fetched = value
    },
    setFriendRequests (state, requests) {
      state.friendRequests = requests
    },
    setFetchNotificationConfig (state, params) {
      state.fetchNotificationConfig = { ...params }
    }
  },
  actions: {
    async fetchNotifications ({ commit }) {
      try {
        const { data: notifications = [] } = await axios.get('/api/v1/notifications')
        commit('setNofications', notifications)
      } catch (e) {
        showError(e.message)
      }
    },
    async openAll ({ dispatch, commit }) {
      try {
        await axios.post('/api/v1/all_notifications_open')
        showSuccess('すべて既読にしました。')
        dispatch('fetchNotifications')
      } catch (e) {
        showError(e.message)
      }
    },
    commuFetchNotifications ({ commit, state }, filter = 'all') {
      const str = `?community_filter=${filter}&page=1`
      commit('setFetched', false)
      commit('setFetchNotificationConfig', { page: 1, filter })
      axios.get(`/communities/notifications/list_notify${str}`).then((res) => {
        commit('commuSetOpenedNotifications', res.data.notifications)
        commit('setFetched', true)

        if (res.data.notifications.length === 0) {
          commit('setFetchNotificationConfig', { page: -1, filter })
        }
      })
    },
    async commuFetchNotificationsMore ({ commit, state }) {
      const { filter, page } = state.fetchNotificationConfig

      if (page !== -1) {
        const str = `?community_filter=${filter}&page=${page + 1}`
        const { data } = await axios.get(`/communities/notifications/list_notify${str}`)

        if (data.notifications.length > 0) {
          commit('commuSetOpenedNotifications', [...state.openedNotifications, ...data.notifications])
          commit('setFetchNotificationConfig', { page: page + 1, filter })
          return true
        } else {
          commit('setFetchNotificationConfig', { page: -1, filter })
        }
      }

      return false
    },
    commuFetchNotificationCounts ({ commit }, filter = 'all') {
      const str = `?community_filter=${filter}`
      axios.get(`/communities/notifications/list_notify_count${str}`).then((res) => {
        commit('commuSetOpenedNotificationsCount', res.data.notifications)
        commit('commuSetUnopenedNotificationsCount', res.data.unopenedNotifications)
      })
    },
    commuFetchFriendRequests ({ commit }, filter = 'all') {
      const str = `?community_filter=${filter}`
      axios.get(`/communities/notifications/friend_request${str}`).then((res) => {
        commit('setFriendRequests', res.data.friendRequests)
      })
    },
    acceptFriendRequests ({ state, commit }, notification) {
      const params = `request_id=${notification.id}`
      axios.post('/communities/notifications/accept_friend_request', params).then((res) => {
        state.friendRequests.splice(notification, 1)
        if (res.data.status === 'success') {
          commuSuccess(res.data.message)
        } else {
          window.location.href = '/communities/notifications'
        }
      })
    },
    rejectFriendRequests ({ state, commit }, notification) {
      const params = `?request_id=${notification.id}`
      axios.delete(`/communities/notifications/reject_friend_request${params}`).then((res) => {
        state.friendRequests.splice(notification, 1)
      })
    }
  }
}
export default store
