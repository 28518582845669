<template>
  <div>
    <Back title="特技・資格編集" :showCommu="false" :showCancel="true"/>
    <div class="description">
      あなたの得意な事や、特技・資格などがあれば、
      <br>
      是非教えてください。たくさんの人達があなたの
      <br>
      手助けを待っています。
    </div>
    <div class="space">
      <form>
        <HiddenHeader/>
        <MyCommuHiddenButtonFooter :is-my-commu-domain="true"/>
        <MyCommuHiddenFooterMenu :is-my-commu-domain="true"/>
        <MyCommuBaseCheckBox v-bind="hasChildcareLicence" v-model="dataHasChildcareLicence"/>
        <MyCommuBaseCheckBox v-bind="hasKindergartenTeacherLicence" v-model="dataHasKindergartenTeacherLicence"/>
        <MyCommuBaseCheckBox v-bind="hasPrimarySchoolTeacherLicence" v-model="dataHasPrimarySchoolTeacherLicence"/>
        <MyCommuBaseCheckBox v-bind="hasJuniorHighSchoolTeacherLicence" v-model="dataHasJuniorHighSchoolTeacherLicence"/>
        <MyCommuBaseCheckBox v-bind="hasHighSchoolTeacherLicence" v-model="dataHasHighSchoolTeacherLicence"/>
        <MyCommuBaseCheckBox v-bind="hasSchoolNurseLicence" v-model="dataHasSchoolNurseLicence"/>
        <MyCommuBaseCheckBox v-bind="hasDoctorLicence" v-model="dataHasDoctorLicence"/>
        <MyCommuBaseCheckBox v-bind="hasNurseLicence" v-model="dataHasNurseLicence"/>
        <MyCommuBaseCheckBox v-bind="hasChildminderLicence" v-model="dataHasChildminderLicence"/>
        <MyCommuBaseButton
          text-content="保存する"
          @clickButton="update()"
        />
      </form>
    </div>
  </div>
</template>

<script>
import Back from '../../components/my-commu/Back'
import axios from 'axios'

export default {
  components: {
    Back
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    profile: {
      type: Object,
      require: true
    },
    hasChildcareLicence: {
      type: Object,
      required: true
    },
    hasKindergartenTeacherLicence: {
      type: Object,
      required: true
    },
    hasPrimarySchoolTeacherLicence: {
      type: Object,
      required: true
    },
    hasJuniorHighSchoolTeacherLicence: {
      type: Object,
      required: true
    },
    hasHighSchoolTeacherLicence: {
      type: Object,
      required: true
    },
    hasSchoolNurseLicence: {
      type: Object,
      required: true
    },
    hasDoctorLicence: {
      type: Object,
      required: true
    },
    hasNurseLicence: {
      type: Object,
      required: true
    },
    hasChildminderLicence: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      dataHasChildcareLicence: this.hasChildcareLicence.value,
      dataHasKindergartenTeacherLicence: this.hasKindergartenTeacherLicence.value,
      dataHasPrimarySchoolTeacherLicence: this.hasPrimarySchoolTeacherLicence.value,
      dataHasJuniorHighSchoolTeacherLicence: this.hasJuniorHighSchoolTeacherLicence.value,
      dataHasHighSchoolTeacherLicence: this.hasHighSchoolTeacherLicence.value,
      dataHasSchoolNurseLicence: this.hasSchoolNurseLicence.value,
      dataHasDoctorLicence: this.hasDoctorLicence.value,
      dataHasNurseLicence: this.hasNurseLicence.value,
      dataHasChildminderLicence: this.hasChildminderLicence.value
    }
  },
  computed: {
  },
  methods: {
    update () {
      const params = new FormData()
      params.append('profile[has_childcare_licence]', this.dataHasChildcareLicence)
      params.append('profile[has_kindergarten_teacher_licence]', this.dataHasKindergartenTeacherLicence)
      params.append('profile[has_primary_school_teacher_licence]', this.dataHasPrimarySchoolTeacherLicence)
      params.append('profile[has_junior_high_school_teacher_licence]', this.dataHasJuniorHighSchoolTeacherLicence)
      params.append('profile[has_high_school_teacher_licence]', this.dataHasHighSchoolTeacherLicence)
      params.append('profile[has_school_nurse_licence]', this.dataHasSchoolNurseLicence)
      params.append('profile[has_doctor_licence]', this.dataHasDoctorLicence)
      params.append('profile[has_nurse_licence]', this.dataHasNurseLicence)
      params.append('profile[has_childminder_licence]', this.dataHasChildminderLicence)
      axios.patch('/communities/profile/update_licence', params).then((res) => {
        window.location.href = '/communities/profile'
      })
    }
  }
}
</script>

<style scoped lang="sass">
.description
  margin-top: 40px
  margin-bottom: 50px
  display: flex
  justify-content: center
  text-align: center
  font-size: 15px
  line-height: 25px
.space
  margin: 0 25px
  /deep/
    .base-checkbox
      margin: 10px 0
    .base-button
      margin-top: 45px
      display: flex
      align-items: center
      button
        width: 320px
</style>
