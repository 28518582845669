<template>
  <div>
    <Map2
      :lat="lat"
      :lng="lng"
      :map-height="600"
      :zoom="zoom"
      :min-zoom="minZoom"
      :mark-event-for-my-commu="true"
      :mark-organization-for-my-commu="true"
      :mark-user-for-my-commu="true"
      :mark-supporter-for-my-commu="true"
      @click-marker="onClickMarker"
    />
    <BModal :active.sync="isModalActive">
      <div
        v-if="hasSelectedCard"
        class="modal-body"
      >
        <MapModalCard
          v-for="selectedCard in selectedCards"
          :key="selectedCard.key"
          v-bind="selectedCard"
          @close-modal="closeModal"
        />
      </div>
    </BModal>
  </div>
</template>

<script>
import Map2 from 'components/Map2'
import MapModalCard from 'components/MapModalCard'
import MapAction from 'mixins/MapAction'
import { mapActions } from 'vuex'

export default {
  components: { Map2, MapModalCard },
  mixins: [MapAction],
  props: {
    lat: {
      type: Number,
      default: 35.65858
    },
    lng: {
      type: Number,
      default: 139.745433
    },
    allOverJapan: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    zoom () {
      return this.allOverJapan ? 5 : 12
    },
    minZoom () {
      return this.allOverJapan ? 5 : 8
    }
  },
  methods: {
    ...mapActions('mycommu', [
      'toggleShowChangeCommu',
      'toggleIsForcedShowingFooter',
    ]),
  },
  mounted () {
    this.toggleShowChangeCommu(true)
    this.toggleIsForcedShowingFooter(true)
  },
}
</script>

<style scoped lang="sass">
@import '~stylesheets/resources'

.modal-body
  max-width: 500px
  margin: auto

/deep/
  .the-map
    position: relative

+app-mobile
  /deep/
    .the-map
      position: relative
      top: 0px
      left: 0
      right: 0
      bottom: 0
</style>
