import axios from 'axios';
const baseURL = '/';

const instance = axios.create({
  baseURL,
  headers: {'Accept': 'application/json'}
});

instance.interceptors.request.use(
  (config) => {
    config.headers['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').content;
    return config;
  },
  (err) => err
);

export default instance;
