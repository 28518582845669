<template>
  <div v-if="!hiddenHeader.topBar">
    <div v-if="isMamaSupport"
         class="my-commu-domain topbar community mb-2"
         :class="{'after-my-commu': !isChange, 'mb-10': !isChange}"
         @click="toggerTopbar(true)"
    >
      <div class="img community-img">
        <img
          :src=currentCommunity.community_url
          alt="マイコミュ"
        />
      </div>
      <MyCommuTopBarSideBar />
    </div>
    <div v-else>
      <div
        class="my-commu-domain topbar community mb-2"
        :class="{'after-my-commu': !isChange, 'mb-10': !isChange, 'margin-unset': showForSearch }"
        @click="toggerTopbar(true)"
      >
        <div class="img community-img">
          <img
            :src=currentCommunity.community_url
            alt="マイコミュ"
          />
        </div>
        <div
          v-if="!isChange"
          class="community-label"
        >
          {{currentCommunity.community_name}}
        </div>
      </div>
      <MyCommuTopBarSideBar />
    </div>

  </div>
  <div v-else class="magin-15">

  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import MyCommuTopBarSideBar from 'components/my-commu/topbar/SideBar.vue'

export default {
  components: {
    MyCommuTopBarSideBar
  },
  props: {
    navPaths: {
      type: Object,
      required: true
    },
    isChange: {
      type: Boolean,
      default: false
    },
    isMamaSupport: {
      type: Boolean,
      default: false
    },
    hideTopBar: {
      type: Boolean,
      default: false
    },
    showForSearch: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return { }
  },
  computed: {
    ...mapState('mycommu', ['currentCommunity', 'communities', 'hiddenHeader']),
    ...mapState('session', ['currentUser']),
    checkCount () {
      return this.communities.length > 1
    }
  },
  mounted () {
    // if (this.communities.length === 0) {
    //   this.getCommunities()
    // }
  },
  methods: {
    ...mapActions('mycommu', [
      'toggerTopbar', 'getCommunities'
    ])
  }
}
</script>
<style scoped lang="sass">
  @import '~stylesheets/resources'

  $mobile-header-z-index: $over-leaflet-z-index
  $mobile-sidenav-z-index: $over-leaflet-z-index + 2
  $mobile-sidenav-bg-z-index: $over-leaflet-z-index + 1
  $app-mobile-footer-height: 50px
  $primary: #2DBA87
  $gray: #AAAAAA

  .mb-10
    margin-bottom: 1px

  .my-commu-domain
    background: #ffffff
    font-size: 14px
    display: flex
    height: 48px
    flex-direction: row
    /* justify-content: center */
    justify-items: center
    align-items: center
    padding: 0 16px
    border-top: 1px solid #EEEEEE
    border-bottom: 1px solid #EEEEEE

    .community-img
      border-radius: 4px
      align-items: center
      justify-content: center
      display: flex
      margin-right: 8px
      img
        border-radius: 5px
        width: 24px
        height: 24px
        object-fit: cover
  .after-my-commu
    &:after
      content: url("data:image/svg+xml,%3Csvg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 1L5 5L1 1' stroke='%232DBA87' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A")
      margin-left: 12px
  .magin-15
    margin-top: 15px
  .side-nav
    width: 200px
    .side-back
      display: none
    .profile
      margin: 16px 0
      display: flex
      justify-content: space-between
      +app-mobile
        margin-top: 0
      .face-icon
        width: 50px
    .profile-name
      flex-grow: 1
      width: calc(100% - 50px)
      padding-left: 8px
      display: flex
      flex-direction: column
      justify-content: center
      .full-name
        margin-bottom: 3px
        display: block
        font-size: 16px
        font-weight: 600
      .full-name-kana
        display: block
        font-size: 9px
        margin-top: 3px
        font-weight: normal
    .side-nav-main
      ul
      li a
        color: #333
        font-weight: 600

        display: block
        padding: 4px 0
        font-size: 14px
        cursor: pointer
        transition: 0.2s
        &:hover
          background-color: #f9f9f9
          text-decoration: none
          padding-left: 5px
    .side-nav-sub
      margin-top: 10px
      li a
        color: #333
        padding: 4px 0
        font-size: 12px
      &.side-nav-banner
        border-top: 1px solid #eee
        border-bottom: 1px solid #eee
        padding: 10px 0
        li a
          color: $primary
          line-height: 1.3
          display: block
    .side-login
      margin: 10px 0
      a
        font-size: 12px
        color: #333
        font-weight: 600
  .margin-unset
    margin-bottom: unset !important
    border-bottom: unset !important

</style>
