<template>
  <div>
    <div class="title-welcome is-pt-4">
      <p>
        最初にあなたの情報を教えてください。</br>
        登録した内容は後で編集できます。
      </p>
    </div>
    <div class="body-welcome">
      <ul class="list-group">
        <li class="list-group-item active">
          <span>住所</span>
        </li>
        <li class="list-group-item active">
          <span>近くのコミュニティ</span>
        </li>
        <li class="list-group-item active">
          <span>お名前・生年月</span>
        </li>
        <li class="list-group-item active">
          <span>自己紹介</span>
        </li>
      </ul>
    </div>
    <div class="next">
      <BButton
        type="is-success"
        size="is-medium"
        class="is-fullwidth"
        @click.prevent="next"
      >
        次へ
      </BButton>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    next () {
      window.location = '/sign_ups/step1'
    }
  }
}
</script>

<style scoped lang="sass" >
  .title-welcome
    text-align: center
    font-size: 15px
  .body-welcome
    padding: 30px
  .list-group
    padding-left: 10px
    .list-group-item
      border: none
      border-left: 2px solid #273D35
      box-sizing: border-box
      border-radius: 0
      counter-increment: step-counter
      padding: 0 0 25px 10px
      display: flex
      align-items: center
      span
        margin-left: 1em
        font-weight: bold
        font-size: 16px
      &:last-child
        border-left: 3px solid transparent
        padding-bottom: 0
      &::before
        background-color: #273D35
        color: #555
        content: counter(step-counter)
        display: inline-block
        float: left
        height: 48px
        width: 48px
        line-height: 48px
        margin-left: -36px
        text-align: center
        font-weight: 700
      span,a
        display: block
        overflow: hidden
        padding-top: 2px
    .active
      background-color: transparent
      color: inherit
      &::before
        background-color: #273D35
        color: #fff
  .list-group-item.completed::before
    background-color: #273D35
    color: #fff
  .list-group-item.completed:last-child
    border-left: 3px solid transparent
  .is-fullwidth
    /deep/ span
      font-weight: bold
  button.button
    background: #2DBA87
    box-shadow: 0 2px 8px #F2EFDE
    border-radius: 6px
    font-weight: 700
    padding: 16px
    font-size: 15px
    line-height: 15px
    text-align: center
    color: #FFFFFF
    height: 48px
    font-family: "Hiragino Kaku Gothic ProN"
</style>
