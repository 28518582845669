import axios from '../utils/axios';
import queryString from 'query-string'
// import session from './session'
import session from '../store/modules/session'

export const getCommunities = async (params) => {
  return await axios.get('/api/v1/co/communities', { params });
}

export const fetchRecents = async (currentCommunity, params = null, type = '') => {
  const paramsx = type !== '' ? {type: type} : queryString.parse(location.search)
  let str = `?type=${paramsx['type']}`
  // let str = `?format=json`
  // if (paramsx['type'] !== undefined) {
  //   str = `&type=${paramsx['type']}`
  // }

  if (params !== null) {
    str += `&${queryString.stringify(params)}`
  }

  let path = `/communities/${session.state.communityId}/home${str}`
  // console.log(path)
  return await axios.get(path)
}

export const fetchUsers = async (params) => {
  let str = queryString.stringify({'q': params})
  return await axios.get(`/api/v1/users/mentions?${str}`);
}

export const fetchTopic = (params) => {
  if ('community' in params) {
    return axios.get(`/communities/${params['community']}/topics/${params['id']}`);
  }
  return axios.get(`/communities/${session.state.communityId}/topics/${params['id']}`);
}

export const fetchEvent = (params) => {
  if ('community' in params) {
    return axios.get(`/communities/${params['community']}/event/${params['id']}`);
  }
  return axios.get(`/communities/${session.state.communityId}/events/${params['id']}`);
}

export const createTopicComment = (topicId, body) => {
  const data = {
    topic_comment: {
      body: body
    }
  }
  return axios.post(`/communities/${session.state.communityId}/topics/${topicId}/topic_comments`, data)
}
export const createShareComment = (shareId, shareType, body) => {
  const data = {
    share_comment: {
      id: shareId,
      type: shareType,
      body: body
    }
  }
  return axios.post(`/communities/${session.state.communityId}/share_posts/handle_comment`, data)
}

export const searchUsers = (params) => {
  return axios.get('/users/my_commu_search', { params })
}

export const searchSupporters = (currentCommunity, params = null, withUsers = false) => {
  let str = ''
  if (params !== null) {
    str = `?supporters_filter=${params}&with_user=${withUsers}`
  }
  return axios.get(`/communities/${session.state.communityId}/community_supporters/search_supporters${str}`)
}
export const searchFriends = (community) => {
  const str = `?community_filter=${session.state.communityId}`
  return axios.get(`/communities/chats/search_friends${str}`)
}
export const chatIndex = (community = 'all') => {
  const str = `?community_filter=${community}`
  return axios.get(`/communities/chats/get_chats${str}`)
}

export const fetchUnseenChatCount = (community = 'all') => {
  const str = `?community_filter=${community}`
  return axios.get(`/communities/chats/get_unseen_chat_count${str}`)
}

export const fetchAvatar = async (communityId, userId) => {
  console.log("fetched")
  return await axios.get(`/communities/${communityId}/users/${userId}`);
}
