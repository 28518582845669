<template>
  <div>
    <Back title="参加グループ編集" :showCommu="false"/>
    <HiddenHeader/>
    <MyCommuHiddenButtonFooter :is-my-commu-domain="true"/>
    <MyCommuHiddenFooterMenu :is-my-commu-domain="true"/>
    <MyCommuChatCommunityTags class="list-communities" @filterCommu="filterCommu"/>
    <div
      v-for="(community, index) in communities"
      :key="index"
    >
      <div
        v-if="community.community_id === filter || filter === 'all'"
        :class="{'has-groups': community.community_groups.length > 0}"
      >
        <div class="community-name">{{ community.community_name }}</div>
        <div v-if="community.community_groups.length > 0" >
          <div
            v-for="(groups, index1) in community.community_groups"
            :key="`${index}_${index1}`"
          >
            <MyCommuCommunityCard
              :id="groups.id"
              :name="groups.name"
              :avatar-path="groups.image1.url"
              :community="[community.community_id, groups.id]"
              button-type="error"
              button-text="退出する"
              @clickButton="outGroup"
              @clickContent="goToDetail"
            />
          </div>
        </div>
        <div v-else class="no-groups">グループに参加していません。</div>
      </div>
    </div>
    <div class="title-community"></div>
  </div>
</template>

<script>
import Back from '../../components/my-commu/Back'
import {mapActions, mapState} from 'vuex'

export default {
  components: {
    Back
  },
  props: {
  },
  data () {
    return {
      filter: 'all'
    }
  },
  computed: {
    ...mapState('mycommu', ['communities'])
  },
  methods: {
    ...mapActions('mycommu', ['communityOutGroups']),
    goToDetail (url) {
      window.location.href = `/communities/${url[0]}/community_groups/${url[1]}`
    },
    outGroup (url) {
      this.communityOutGroups({ communityId: url[0], groupId: url[1] })
    },
    filterCommu (id) {
      this.filter = id
    }
  }
}
</script>

<style scoped lang="sass">
.list-communities
  margin-top: 16px
  margin-bottom: 8px
.community-name
  margin: 0 16px 16px 16px
  color: #273D35
  font-weight: bold
.no-groups
  text-align: center
  margin-bottom: 40px
.has-groups
  border-bottom: 1px solid #cccccc
  margin-bottom: 40px
</style>
