<template>
  <div class="managements-events">
    <Back title="経費申請入力" />
    <div class="title">
      <span>アサインされたイベントで掛かった交通費や、</span>
      <span>建て替えなどの経費申請ができます。</span>
    </div>
    <NotFound
      v-if="isNotFound"
      content="まだアサインされた"
      subContent="イベントがありません。"
    />
    <div
      v-else
      class="managements-events-container"
    >
      <div class="list-title">
        <span>担当イベント</span>
      </div>

      <TopicDetail
        v-for="active_assigned_staff in active_assigned" :key="active_assigned_staff.id"
        :event="active_assigned_staff.event"
        :assigned_staffs="active_assigned_staff"
        :expenses="active_assigned_staff.expenses"
        :url="pageUrl(active_assigned_staff)"
        :urlNoExpense="NoExpenseUrl"
        @event-updated="updateListEvent"
      />

      <div class="list-title" id="finishedAssigned">
        <span>経費申請済みのイベント</span>
      </div>

      <TopicDetail
        v-for="finished_assigned_staff in finished_assigned" :key="finished_assigned_staff.id"
        :event="finished_assigned_staff.event"
        :assigned_staffs="finished_assigned_staff"
        :expenses="finished_assigned_staff.expenses"
        :accept="true"
        :url="url"
      />
    </div>

  </div>
</template>
<script>

import { mapActions, mapState } from 'vuex'
import Back from '../../components/my-commu/Back'
import NotFound from '../../components/my-commu/mama_supporter/NotFound'
import TopicDetail from '../../components/my-commu/mama_supporter/TopicDetail'

export default {
  components: { Back, NotFound, TopicDetail },
  props: {
    community_id: {
      type: Number,
      require: true
    },
    finished_assigned_staffs: {
      type: Array,
      require: true
    },
    active_assigned_staffs: {
      type: Array,
      require: true
    }
  },
  data () {
    return {
      url: `/communities/${this.community_id}/managements/expenses/events/new`,
      active_assigned: this.active_assigned_staffs,
      finished_assigned: this.finished_assigned_staffs
    }
  },
  computed: {
    ...mapState('mycommu', ['titleHeader', 'hiddenHeader', 'currentCommunity']),
    isNotFound () {
      return this.finished_assigned_staffs.length === 0 && this.active_assigned_staffs.length === 0
    },
    NoExpenseUrl () {
      return `/communities/${this.community_id}/managements/expenses/events/no_expense`
    }
  },

  created () {
  },
  mounted () {
    this.toggerMyCommuButton(false)
    this.toggerHeader(true)
  },
  methods: {
    ...mapActions('mycommu', [
      'toggerMyCommuButton',
      'toggerHeader',
      'setTitleHeader',
      'setHiddenHeader',
      'currentCommunity'
    ]),
    ...mapActions('events', ['setEvents']),
    redirectEventNew () {
      window.location.href = `/communities/${this.community_id}/managements/events/new`
    },
    pageUrl (activeAssignedStaff) {
      return `/communities/${this.community_id}/managements/expenses/events/${activeAssignedStaff.event_id}/edit`
    },
    updateListEvent (param) {
      this.active_assigned = this.active_assigned.filter(item => item !== param)
      this.finished_assigned.unshift(param)
      document.getElementById('finishedAssigned').scrollIntoView()
    }
  }
}
</script>

<style scoped lang="sass">
  .managements-events
    min-height: 100vh
    background: #f8f7f2

    .title
      margin-top: 40px
      font-size: 15px
      font-weight: 300
      flex-direction: column
      display: flex
      align-items: center
      color: #273D35
      span:last-child
        margin-top: 8px

    .managements-events-container
      padding: 16px 16px 58px

      .list-title
        font-weight: 600
        font-size: 17px
        color: #273D35
        margin-bottom: 20px

</style>
