<template>
  <div class="sharing-candidate-user">
    <FaceIcon
      :src="avatarPath"
      class="user-icon"
    />
    <div class="user-text">
      <p class="user-name" v-if="isMyCommuDomain">
        <span v-if="!supporter" v-text="fullName" />
        <span v-else>{{ `シェア・コンシェルジュ${fullName}さん` }}</span>
      </p>
      <p class="user-name" v-else>
        <span v-text="fullName" />
        <span
          v-if="supporter"
          class="tag is-primary is-ml-1"
        >
          ママサポ
        </span>
      </p>
      <p
        class="user-address is-size-7"
        v-text="addressAndDistance"
      />
    </div>
    <FriendGauge
      v-if="!isMine && !isFriendGaugeHidden"
      class="user-friend-gauge"
      :friend-level="friendshipLevel"
      :is-small="true"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import FaceIcon from 'components/FaceIcon.vue'
import FriendGauge from 'components/FriendGauge.vue'

export default {
  components: {
    FaceIcon,
    FriendGauge
  },
  props: {
    id: {
      type: Number,
      required: true
    },
    avatarPath: {
      type: String,
      required: true
    },
    fullName: {
      type: String,
      required: true
    },
    addressAndDistance: {
      type: String,
      required: true
    },
    friendshipLevel: {
      type: Number,
      required: true
    },
    supporter: {
      type: Boolean,
      required: true
    },
    isMyCommuDomain: {
      type: Boolean,
      default: false
    },
    isFriendGaugeHidden: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('session', ['currentUser']),
    isMine () {
      return this.currentUser && this.id === this.currentUser.id
    }
  }
}
</script>

<style scoped lang="sass">
@import '~stylesheets/resources'

$left-padding: 64px
$left-padding-sp: 48px

.sharing-candidate-user
  display: flex
  justify-content: space-between
  width: 100%
  padding-left: $left-padding
  align-items: center
  position: relative
  min-height: 62px
  +app-mobile
    min-height: 52px
    padding-left: $left-padding-sp
  .user-text
    flex-grow: 1
    margin-left: 8px
  .user-name
    font-weight: bold
  .user-address
    color: #777
  .user-icon
    position: absolute
    top: 50%
    transform: translateY(-50%)
    left: 0
    display: block
    width: $left-padding
    +app-mobile
      width: $left-padding-sp
  .user-friend-gauge
    white-space: nowrap
/deep/ .face-icon
  display: block
/deep/ .face-icon > .icon
  display: block
  margin: 0
  &:first-child:not(:last-child)
    margin: 0
+app-mobile
  /deep/ .face-icon > .icon
    width: 48px
    height: auto
</style>
