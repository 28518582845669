import mutations from './mutations'
import actions from './actions'

export default {
  namespaced: true,
  state: {
    sidebar: false,
    topbar: false,
    loading: false,
    communities: [],
    otherCommunities: [],
    headerInActive: false,
    myCommuButton: true,
    myCommuFooterMenu: true,
    users: [],
    currentCommunity: {
      community_id: null,
      community_name: null,
      community_url: null
    },
    search: '',
    isSearch: false,
    isShowIconHeader: false,
    isShowChangeCommu: false,
    isShowSchedule: false,
    isTutorial: false,
    isShowingSearchResult: false,
    isForcedShowingFooter: false,
    isFindUser: false,
    searchUsersResult: {
      users: [],
      count_supporter: null,
      count_user: null
    },
    searched: false,
    showSearch: false,
    supporters: [],
    isChat: false,
    hiddenHeader: {
      tabGroup: false,
      searchTop: false,
      searchBottom: false,
      topBar: false,
      plus: true,
      mainContainerUnsetMargin: false,
      notificationAndMessage: false
    },
    titleHeader: {
      display: false,
      content: '',
      setHeight: 100,
      rightText: '',
      isClick: false
    },
    isNewMessage: false,
    isChatIndex: false,
    friends: [],
    chats: [],
    unseenChatCount: 0,
    isCommuChatDetail: false,
    currentTab: 'first-tab',
    loadingChat: false
  },
  actions,
  mutations
}
