<template>
  <div>
    <HiddenHeader/>
    <MyCommuHiddenButtonFooter :is-my-commu-domain="true"/>
    <Back title='イベント作成'/>
    <div class="event-container">
      <BButton
        outlined type='submit'
        class="is-medium is-fullwidth is-success btn-create"
        icon-left="plus"
        @click="redirectEventNew()"
      >
        新しいイベントを作成する
      </BButton>
      <div v-if="events.length > 0" class="title-top">作成したイベント</div>
      <MyCommuCardEvent v-for="event in events" :key="event.id" :path="event.organizationPath" :event="event"/>
    </div>
  </div>
</template>
<script>

import { mapActions, mapState } from 'vuex'
import Back from '../../components/my-commu/Back'

export default {
  components: {
    Back
  },
  props: {
    events: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapState('mycommu', ['titleHeader', 'hiddenHeader', 'currentCommunity', 'isForcedShowingFooter']),
    ...mapState('space', ['unsetMargin']),
    ...mapState('session', ['communityId'])
  },
  mounted () {
    this.toggleIsForcedShowingFooter(true)
  },
  created () {
    this.unsetMargin.mainContainer = true
  },
  methods: {
    ...mapActions('mycommu', [
      'toggerMyCommuButton',
      'toggerHeader',
      'setTitleHeader',
      'setHiddenHeader',
      'toggleIsForcedShowingFooter'
    ]),
    redirectEventNew () {
      window.location.href = `/communities/${this.communityId}/managements/events/new`
    }
  }
}
</script>

<style scoped lang="sass">
  .topbar
    height: 41px
    background: #FFFFFF
    display: grid
    grid-template-columns: auto auto auto
    align-items: center
    .topbar-icon-back
      margin-left: 16px
    .topbar-title
      color: #273D35
      font-weight: 700
      font-size: 16px
      text-align: center
    .topbar-avatar
      margin-left: auto
      margin-right: 23px
      margin-top: 5px
      img
        width: 32px
        height: 32px
        border-radius: 4px
  .event-container
    padding: 32px 16px
    .button.btn-create
      display: flex
      padding: 24px 16px
      margin-bottom: 16px
      font-weight: bolder
      font-size: 15px
      /deep/
        span.icon
          i
            font-size: 24px !important
        span:last-child
          width: 90%
      &.is-success
        border: none
        background: white
        box-shadow: 0 2px 8px #F2EFDE
        border-radius: 6px
    .title-top
      padding: 16px 0 24px
      font-weight: 700
      font-size: 17px
      line-height: 17px
      color: #273D35
    /deep/
      .card-event
        width: 100% !important

</style>
