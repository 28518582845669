<template>
  <div
    :class="{ 'is-loading': loading || loadingChat }"
    class="my-commu-domain loading "
  >
    <img src="../../images/for_community/spinner.svg">
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  components: {},
  props: {
    loadingChat: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {}
  },
  computed: {
    ...mapState('mycommu', ['loading'])
  },
  mounted () {

  },
  methods: {

  }
}
</script>
<style scoped lang="sass">
  .my-commu-domain

    &.loading
      display: none
    &.is-loading
      height: 5vh
      margin: auto
      display: flex
      text-align: center
      align-items: center
      justify-content: center
  .lds-spinner
    color: official
    display: inline-block
    position: relative
    width: 80px
    height: 80px

</style>
