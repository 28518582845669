<template>
  <BField
    :label="label"
    :message="errorMessage"
    :type="status"
    :label-for="id"
    :addons="false"
    :class="{'is-marginless': isMarginless, 'is-my-commu': isMyCommuDomain}"
    :custom-class="customClass"
  >
    <BInput
      :id="id"
      v-model="inputValue"
      :class="{'input-my-commu': isMyCommuDomain}"
      :custom-class="myCommuClass"
      :name="name"
      :type="type"
      :placeholder="placeholder"
      :min="min"
      :max="max"
      :password-reveal="inputReveal"
      @blur="handleBlur"
      icon-pack="fas"
      :size="inputSize"
    />
    <slot />
  </BField>
</template>

<script>
import * as inputs from './inputs'

export default {
  props: {
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: null
    },
    value: {
      type: [String, Number],
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    errors: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String,
      default: null
    },
    onBlur: {
      type: Function,
      default: () => {}
    },
    isMarginless: {
      type: Boolean,
      default: false
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    isPublished: {
      type: Boolean,
      default: false
    },
    isHidden: {
      type: Boolean,
      default: false
    },
    isProtected: {
      type: Boolean,
      default: false
    },
    addInputtedClass: {
      type: Boolean,
      default: false
    },
    min: {
      type: Number,
      default: null
    },
    max: {
      type: Number,
      default: null
    },
    passwordReveal: {
      type: Boolean,
      default: false
    },
    isLarge: {
      type: Boolean,
      default: false
    },
    isMedium: {
      type: Boolean,
      default: false
    },
    isMyCommuDomain: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return { inputValue: this.value, displayErrors: this.errors, isInputted: false, inputReveal: this.type == 'password' && this.passwordReveal }
  },
  computed: {
    status () {
      return inputs.status(this.displayErrors, this.isInputted)
    },
    id () {
      return inputs.idForName(this.name)
    },
    customClass () {
      return inputs.customClass(this.$props)
    },
    errorMessage () {
      if (this.isInputted) {
        // NOTE: ふつうに表示したいエラーメッセージも隠してしまう場合があるので要注意。制御を変えたほうがいい
        return this.displayErrors.filter(message => !message.match(/を入力してください$/i))
      }
      return this.displayErrors
    },
    inputSize () {
      if (this.isLarge){
        return 'is-large'
      } else if (this.isMedium) {
        return 'is-medium'
      }
    },
    myCommuClass () {
      return this.isMyCommuDomain ? 'input-password' : ''
    }
  },
  methods: {
    handleBlur (e) {
      this.isInputted = this.addInputtedClass && e.target.value.length > 0
      // inputのvalueをjsで入れた場合にstoreに入ってないので入れとく
      if (this.inputValue !== e.target.value) {
        this.inputValue = e.target.value
      }
      const result = this.onBlur(e)
      // 郵便番号自動入力時のエラーをトースト表示させないための苦肉の対応の模様
      // 郵便番号用のコンポーネントをいずれちゃんと作って分離しないとメンテが不安
      if (result instanceof Promise) {
        result.then((errorMessage) => {
          if (errorMessage) {
            this.displayErrors = [errorMessage]
            this.isInputted = false
          } else {
            this.displayErrors = []
          }
        })
      }
    }
  }
}
</script>

<style scoped lang="sass">
/deep/
  @import '~stylesheets/components/required-label'
  @import '~stylesheets/components/published-label'
.is-short
  .control
    width: 33%
.is-my-commu
  /deep/ > label
    font-size: 14px
    font-weight: 300
    color: #273D35
  /deep/ .input-my-commu
    caret-color: #2DBA87;
  /deep/ .input-my-commu:focus
    border-color: #2DBA87
    box-shadow: unset
/deep/ .input-my-commu > span
  color: #2DBA87 !important
.is-my-commu:not(:last-child)
  margin-bottom: 0px !important
/deep/ .input-password
  border-radius: 6px
  height: 48px
  caret-color: #2DBA87
  &:focus
    border: 1px solid #2DBA87
    border-radius: 6px
    box-shadow: 0 0 0 0
/deep/ input
  font-family: "Hiragino Kaku Gothic ProN"

</style>
