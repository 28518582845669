<template>
  <div class="groups-topic-show">
    <Back :title="topicTitle" :back-url="backUrl"/>

    <Slider
      v-if="slider_images.length !== 0"
      :images="slider_images"
    />
    <div class="topic-detail columns is-mobile">
      <div class="column is-12">

        <div class="topic-author columns is-mobile ">
          <div>
            <div class="topic-users-avatar is-pulled-left">
              <img
                :src="avatarPath"
                width="24"
                height="24"
              >
            </div>
            <div class="topic-user-name">
              <span>{{ writerName }}</span>
            </div>
          </div>

          <div class="topic-actions">
            <div
              class="topic-actions-times"
              :class="{'unset-magin': !topic.canEditAndDeleteTopic}"
            >
              <span>{{topic.f_created_at}}</span>
            </div>
            <div
              v-if="topic.canEditAndDeleteTopic" class="topic-actions-favories"
            >

              <BDropdown :mobile-modal="false" position="is-bottom-left">
                <button slot="trigger" class="option-button option-button-horizontal" type="button">
                  <BIcon pack="far" icon="ellipsis-v" />
                </button>
                <div class="co-dropdown-item edit" @click="goToEdit">
                  <span class="icon-edit">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M2.37012 8.93L14.1401 20.69L21.6301 21.63L20.6901 14.14L8.93012 2.37L2.37012 8.93Z"
                            stroke="#273D35" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M13.5098 20.07L20.0698 13.51" stroke="#273D35" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round" />
                    </svg>

                  </span>
                  <span>編集する</span>
                </div>
                <div
                  class="co-dropdown-item remove"
                  @click.prevent="isHelpModalActive = true"
                >
                  <span class="icon-remove">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M12.0002 6.40994C16.5621 6.40994 20.2602 5.46526 20.2602 4.29994C20.2602 3.13462 16.5621 2.18994 12.0002 2.18994C7.43836 2.18994 3.74023 3.13462 3.74023 4.29994C3.74023 5.46526 7.43836 6.40994 12.0002 6.40994Z"
                        stroke="#EE5353" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                      <path
                        d="M20.2603 4.53003L19.5703 17.8801C19.4703 20.2801 17.0202 21.8101 12.0002 21.8101C6.98024 21.8101 4.53024 20.2801 4.43024 17.8801L3.74023 4.53003"
                        stroke="#EE5353" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M12 10.4V17.54" stroke="#EE5353" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round" />
                      <path d="M7.66992 10.7L7.94992 17.25" stroke="#EE5353" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round" />
                      <path d="M16.3298 10.7L16.0498 17.25" stroke="#EE5353" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round" />
                    </svg>

                  </span>
                  <span>削除する</span>
                </div>
              </BDropdown>
            </div>
          </div>
        </div>

        <div class="topic-description columns is-mobile ">
          <div class="column is-12">
            <span v-html="topic.fullBody"></span>
          </div>
        </div>

        <div class="topic-actions columns is-mobile ">
          <div class="column is-12">
            <div class="d-flex heart active is-pulled-left is-mr-3">
              <img
                src="../../images/for_community/heart_active_icon.png"
                height="16"
              >
              <span @click="goToListLike()">{{ topicLikes }}</span>
            </div>
            <div class="d-flex comments active is-pulled-left">
              <span class="d-flex d-flex-align-center card-coments-icon is-mr-1">
                <img src="../../images/for_community/comment_green.png">
              </span>
              <span>{{ commentCount }}</span>
            </div>
          </div>
        </div>

        <div class="topic-footer-actions columns is-mobile ">
          <div class="column is-6">
            <div
              class="d-flex d-flex-center action-items active-comment"
              @click="a_likeTopic"
            >
              <div class="d-flex d-flex-center action-items active-heart">
                <span class="action-icon icon-heart is-mr-1">
                  <img
                    v-if="!isLike"
                    src="../../images/for_community/heart_in_active_icon.png"
                  >
                  <img
                    v-if="isLike"
                    src="../../images/for_community/heart_active_icon.png"
                  >
                </span>
                <span>いいね</span>
              </div>
            </div>
          </div>
          <div class="column is-6">
            <div
              class="d-flex d-flex-center action-items active-comment"
              @click="toggleCommentInput()"
            >
              <span class="action-icon icon-comment is-mr-1">
                <img src="../../images/for_community/comment_icon.png">
              </span>
              <span>コメント</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <CommentList
      :comments="comments"
      :comments-count="commentCount"
      :handle-keydown="handleKeydown"
      :do-create-chat-message="doCreateChatMessage"
      :do-focus-input="toggleCommentInput"
      :reply-click="toggleCommentInput"
      :is-topic="true"
      :fetch-comments="fetchComments"
    />

    <CommuInput
      ref="CommuInput"
      :handle-keydown="handleKeydown"
      :do-create-chat-message="doCreateChatMessage"
      :focus-comment="focusComment"
      placeholder="コメントする"
      :textarea="true"
    />

    <BModal
      :active.sync="isHelpModalActive"
      has-modal-card
    >
      <div class="modal-card">
        <section class="modal-card-body">
          <div class="content">
            <span>投稿を本当に削除してよろしいでしょうか</span>
          </div>
        </section>
        <section class="modal-card-footer">
          <button @click="closeModal" class="button cancel">キャンセル</button>
          <a :href="`${path}`" data-method="delete" class="button ok">はい</a>
        </section>
      </div>
    </BModal>
    <div class="modal-list-like">
      <BModal
        :active.sync="isListLikeModalActive"
        has-modal-card
      >
        <div class="modal-card">
          <MyCommuListLike
            :users="user_likes"
          />
        </div>
      </BModal>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Back from '../../components/my-commu/Back'
import CommentList from '../../components/my-commu/comments/List'
import CommuInput from '../../components/my-commu/comments/Input'
import Slider from '../../components/my-commu/Slider'
import queryString from 'query-string'
import axios from 'axios'

export default {
  components: {
    Back,
    CommentList,
    CommuInput,
    Slider
  },
  props: {
    communityGroup: {
      type: Object,
      require: true
    },
    topic_id: {
      type: Number,
      require: true
    },
    focusComment: {
      type: Number,
      require: true
    },
    path: {
      type: String,
      default: '#'
    },
    topic: {
      type: Object,
      require: true
    }
  },
  computed: {
    ...mapState('groups', ['localUsers']),
    ...mapState('mycommu', ['currentCommunity']),
    ...mapState('space', ['unsetMargin', 'unsetPadding']),
    ...mapState('session', ['communityId']),
    checkInput () {
      return (this.name === null || this.name === '')
    },
    avatarPath () {
      if (this.topic.writer !== undefined) {
        return this.topic.writer.communityAvatarPath
      } else {
        return ''
      }
    },
    fullName () {
      if (this.topic.writer !== undefined) {
        return this.topic.writer.fullName
      } else {
        return ''
      }
    },
    slider_images () {
      const images = []
      if (this.topic.images !== undefined) {
        this.topic.images.forEach((e) => {
          images.push(e.original)
        })
        return images
      } else {
        return []
      }
    },
    writerName () {
      if (this.topic.writer !== undefined) {
        if (this.topic.writer.commuNickname !== null && this.topic.writer.commuNickname !== '') {
          return this.topic.writer.commuNickname
        } else {
          return this.topic.writer.fullName
        }
      } else {
        return ''
      }
    },
    topicTitle () {
      if (this.topic.writer !== undefined) {
        if (this.topic.writer.commuNickname !== null && this.topic.writer.commuNickname !== '') {
          return this.topic.writer.commuNickname + 'の投稿'
        } else {
          return this.topic.writer.fullName + 'の投稿'
        }
      } else {
        return 'の投稿'
      }
    },
    backUrl () {
      const paramsx = queryString.parse(location.search)
      return paramsx.from === 'edit' ? `/communities/${this.communityId}/community_groups/${this.topic.organizationGroupId}?backTo=groups_list` : undefined
    }
  },
  data () {
    return {
      isComponentModalActive: false,
      isDisplayed: true,
      file: null,
      leftLabel: true,
      name: null,
      description: null,
      privateMode: false,
      allowOnlyPeople: false,
      m_topic: {},
      isHelpModalActive: false,
      isListLikeModalActive: false,
      user_likes: [],
      topicLikes: this.topic.likesCount,
      isLike: this.topic.liked,
      comments: this.topic.comments,
      commentCount: this.topic.commentsCount
    }
  },
  mounted () {
    // this.unsetMargin.mainContainer = true
    this.unsetMargin.appMain = true
    this.unsetPadding.mainContainer = true
    this.fetchTopic({ group_id: this.communityGroup.id, topic_id: this.topic_id })
    this.toggerMyCommuButton(false)
    this.toggerHeader(true)
    const params = queryString.parse(location.search)
    if (params && params.comment === '1') {
      this.$nextTick(function () {
        this.$refs.CommuInput.$refs.comment.$refs.input.focus()
      })
    }
  },
  methods: {
    ...mapActions('mycommu', [
      'toggerMyCommuButton',
      'toggerHeader',
      'toggerMyCommuFooterMenu'
    ]),
    ...mapActions('groups', [
      'createComment',
      'fetchTopic',
      'likeTopic',
      'disLike',
      'setMessage',
      'setLocalUser'
    ]),
    async a_likeTopic () {
      if (this.communityGroup.joined) {
        await this.likeTopic({ group_id: this.topic.community_group_id, topic_id: this.topic_id })
        await axios.get(`/communities/${this.currentCommunity.community_id}/community_groups/${this.topic.community_group_id}/community_group_topics/${this.topic.id}/fetch_show`).then((res) => {
          this.topicLikes = res.data.topic.likesCount
          this.isLike = res.data.topic.liked
        })
      }
    },
    async doCreateChatMessage (message, file) {
      this.toggerMyCommuFooterMenu(true)
      if (this.communityGroup.joined && ((message !== null && message !== '') || file.length > 0)) {
        this.localUsers.forEach((e) => {
          message = message.replace(`@${e.label}`, `[[@${e.label}]]`)
        })

        await this.createComment({
          group_id: this.topic.community_group_id,
          topic_id: this.topic.id,
          body: message,
          file: file
        }).then(() => {
          this.fetchComments()
        })
        await this.fetchTopic({ group_id: this.topic.community_group_id, topic_id: this.topic.id })
      }
    },
    handleKeydown () {

    },
    toggleCommentInput (name = null, userId = null) {
      if (name && userId) {
        this.setMessage(`@${name}`)
        this.setLocalUser([{ id: userId, label: name, value: name }])
      }
      this.toggerMyCommuFooterMenu(false)
      if (this.communityGroup.joined) {
        this.$refs.CommuInput.$refs.comment.focus()
      }
    },
    closeModal () {
      this.isHelpModalActive = false
    },
    goToEdit () {
      const paramsx = queryString.parse(location.search)
      window.location.href = paramsx.from === 'notification' ? `${this.path}/edit?detailFrom=notification` : `${this.path}/edit`
    },
    goToListLike () {
      axios.post(`/communities/${this.currentCommunity.community_id}/community_groups/${this.topic.community_group_id}/community_group_topics/${this.topic.id}/list_likes`).then((res) => {
        this.user_likes = res.data.data
        this.isListLikeModalActive = true
      })
    },
    fetchComments () {
      axios.get(`/communities/${this.currentCommunity.community_id}/community_groups/${this.topic.community_group_id}/community_group_topics/${this.topic.id}/fetch_show`).then((res) => {
        this.comments = res.data.topic.comments
        this.commentCount = res.data.topic.commentsCount
      })
    }
  }
}
</script>
<style scoped lang="sass">
  .topic-actions
    display: flex
    margin-left: auto
    align-items: center
    .topic-actions-times
      margin-right: 20px
      color: #AAAAAA
      font-weight: 400
      font-size: 12px
    .unset-magin
      margin-right: unset
    .option-button
      background-color: transparent
      border: none
      cursor: pointer
      padding: 0
      transform: rotate(90deg)
      .option-text
        font-size: 0.7rem
        margin-top: -5px
    .topic-actions-favories
      /deep/
        .dropdown-menu
          min-width: 9.5rem
          .dropdown-content
            padding: 3px 0

    .option-button-horizontal
      .icon
        margin-top: 10px
        color: #2DBA87

    /deep/ a.dropdown-item
      padding-right: 1rem

    .co-dropdown-item
      margin: 15px 0px 0px 18px
      align-items: center
      display: flex
      .icon-edit,
      .icon-remove
        padding-right: 15px
      .remove
        color: red
    .remove
      margin-bottom: 15px !important
    /deep/
      .dropdown-content
        width: unset
        padding: 0px
        div
          color: #273D35
          font-size: 14px
          font-weight: 400
  /deep/
    .animation-content
      margin: 0 52px !important
      width: unset !important
      .modal-card-body
        padding: 32px 20px
        text-align: center
        .content
          padding: 0px 50px
    .modal-close
      display: none
    .modal-background
      background: #273D35
      opacity: 0.9
  .animation-content
    width: 80%
    background: #FFFFFF
    .modal-card
      border-radius: 20px
      .modal-card-footer
        line-height: 0
        display: inline-flex
        grid-template-columns: auto auto
        .button
          height: 48px
          border-top: 1px solid #CCCCCC
          background: #fff
          color: #2DBA87
          border-radius: 0
          font-size: 15px
          min-width: 50%
          &:focus-visible
            border: none
          &.cancel
            border-right: unset
        .ok
          font-weight: bold
          border-left: 1px solid #CCCCCC

  /deep/
    .columns
      margin: 0
    .groups-topic-show
      position: relative
    .topic-detail
      padding: 16px 24px 32px !important
      background: #ffffff
      .column.is-12
        padding: 0
      .d-flex
        display: flex
      .d-flex-center
        justify-content: center
      .d-flex-align-center
        align-items: center
      .topic-title
        font-weight: 700
        font-size: 16px

      .topic-description
        font-weight: 300
        font-size: 15px
        margin-bottom: 35px

      .topic-author
        margin-bottom: 18px
        .topic-users-avatar
          margin-right: 8px
          img
            width: 48px
            height: 48px
            border-radius: 50%
            object-fit: cover
            flex-shrink: 0
        .topic-user-name
          display: flex
          flex-direction: column
          justify-content: center
          color: #273D35
          font-size: 12px
          font-weight: 300
          margin-top: 5px
          height: 40px
      .topic-footer-actions
        border-top: 1px solid #CCCCCC
        border-bottom: 1px solid #CCCCCC
        margin-right: 0
        margin-left: 0
        font-size: 15px
        color: #7C7C7C
        font-weight: bold
        .active-comment
          align-items: center
        .icon-comment, .icon-heart
          display: flex
          img
            width: 20px
      .heart, .comments
        font-size: 12px
        img
          width: 16px
          margin-right: 4px
      .heart img
        height: 16px
  .modal-list-like
    /deep/
      .animation-content
        width: 90%!important
        .modal-card
          overflow: scroll
          border-radius: 8px
          max-height: calc(812px - 190px)
  .groups-topic-show
    position: relative
  .groups-topic-show
    /deep/
      .slides
        .swiper-container
          border-radius: 12px 12px 0 0
          background-color: #F3F3F3
          .swiper-wrapper
            .swiper-slide
              width: 100% !important
              img
                object-fit: unset
                aspect-ratio: unset
</style>
