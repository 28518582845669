<template>
  <div>
    <HiddenHeader/>
    <div data-v-76263b5a="" class="topbar">
      <div data-v-76263b5a="" class="topbar-icon-back">
        <svg data-v-76263b5a="" width="21" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" @click="toIndex">
          <path data-v-76263b5a="" d="M16.0697 3.8501L7.92969 12.0001L16.0697 20.1501" stroke="#2DBA87" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
          </path>
        </svg>
      </div>
      <div data-v-76263b5a="" class="topbar-title">
        <span data-v-76263b5a="">メッセージ送信先選択</span>
      </div>
    </div>
    <MyCommuChatCommunityTags
      :has-message="true"
      @filterCommu="filterCommu"
    />
    <MyCommuUserCard
      v-for="(friend, index) in friends"
      :id="friend.id"
      :key="friend.path"
      :avatar-path="friend.avatarPath"
      :name="friend.fullName"
      :content="commuName(friend)"
      :is-first="index === 0"
      :is-last="index === (friends.length - 1)"
      :navigate-url="friend.toMessage"
    />
  </div>
</template>

<script>

import { mapActions, mapState } from 'vuex'

export default {
  components: {
  },
  props: {
  },
  computed: {
    ...mapState('mycommu', [
      'friends',
      'titleHeader',
      'hiddenHeader'
    ])
  },
  created () {
  },
  methods: {
    ...mapActions('mycommu', [
      'toggleIsNewMessage',
      'setTitleHeader',
      'setHiddenHeader',
      'toggerHeader',
      'toggleIsChatDetail',
      'searchFriends'
    ]),
    toIndex () {
      this.titleHeader.display = true
      this.titleHeader.content = 'メッセージ'
      this.hiddenHeader.tabGroup = true
      this.hiddenHeader.searchTop = true
      this.hiddenHeader.searchBottom = true
      this.hiddenHeader.topBar = true
      this.hiddenHeader.plus = false
      this.setHiddenHeader(this.hiddenHeader)
      this.setTitleHeader(this.titleHeader)
      this.toggleIsNewMessage(false)
      this.toggerHeader(false)
    },
    commuName (friend) {
      var communities = []

      friend.sameCommunities.map((community) => {
        communities.push(community.name)
      })
      return communities.join(', ')
    },
    filterCommu (e) {
      this.searchFriends(e)
    }
  },
}
</script>
<style scoped lang="sass">

.topbar
  display: flex
  padding: 13px 15px 4px 15px
  background-color: white
  .topbar-title
    color: #273D35
    font-weight: bold
    font-size: 16px
    text-align: center
    width: 100%
    justify-content: center
    margin-right: 21px
</style>
