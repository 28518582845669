<template>
  <div>
    <Map2
      :lat="lat"
      :lng="lng"
      :mark-community="false"
      :mark-event="true"
      :mark-organization="false"
      :mark-user="true"
      :mark-supporter="false"
      :zoom="13"
      @click-marker="onClickMarker"
    />
    <BModal :active.sync="isModalActive">
      <div
        v-if="hasSelectedCard"
        class="modal-body"
      >
        <MapModalCard
          v-for="selectedCard in selectedCards"
          :key="selectedCard.key"
          v-bind="selectedCard"
        />
      </div>
    </BModal>
  </div>
</template>

<script>
import Map2 from 'components/Map2'
import MapModalCard from 'components/MapModalCard'
import MapAction from 'mixins/MapAction'

export default {
  components: { Map2, MapModalCard },
  mixins: [MapAction],
  props: {
    lat: {
      type: Number,
      default: 35.65858
    },
    lng: {
      type: Number,
      default: 139.745433
    }
  }
}
</script>

<style scoped lang="sass">
@import '~stylesheets/resources'

.modal-body
  max-width: 500px
  margin: auto
</style>
