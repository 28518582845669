<template>
  <div>
    <MyCommuSchedule v-if="isShowSchedule"/>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
export default {
  components: {
  },
  props: {
  },
  computed: {
    ...mapState('mycommu', ['isShowIconHeader', 'isShowChangeCommu', 'hiddenHeader', 'isShowSchedule'])
  },
  data () {
    return {
    }
  },
  created () {
    this.hiddenHeader.mainContainerUnsetMargin = true
    this.toggleShowIconHeader(true)
    this.toggleShowChangeCommu(true)
  },
  mounted () {
  },
  methods: {
    ...mapActions('mycommu', [
      'toggleShowIconHeader',
      'toggleShowChangeCommu'
    ])
  }
}
</script>
