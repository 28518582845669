<template>
  <div>
    <div class="share-header-area" :class="sharingBgColorGradient">
      <div class="share-detail-header">
        <img src="../../images/icons/arr_left_white.svg" alt="" class="ml-16" @click="redirectBack()">
        <img src="../../images/icons/header_icon_share.svg" alt="">
        <img :src="currentCommunity.community_url"
             @click="toggerTopbar(true)"
             class="change-commu"
        >
      </div>
      <div class="share-detail-bottom" style="position: relative">
        <div class="share-status">
          <div class="share-status-content" :class="sharingBgColor">{{ sharingType }}</div>
          <BDropdown v-if="share.isAuthor" :mobile-modal="false" position="is-bottom-right">
            <button slot="trigger" class="option-button option-button-horizontal share-option" type="button">
              <div class="share-option-dot" :class="sharingBgColor"></div>
              <div class="share-option-dot" :class="sharingBgColor"></div>
              <div class="share-option-dot" :class="sharingBgColor"></div>
            </button>
            <div class="co-dropdown-item edit" v-if="!this.share.isClosed">
              <a :href="share.pathEdit" >編集</a>
            </div>
            <div class="co-dropdown-item edit" v-if="this.share.isClosed" @click="openSharing">
              <span>募集再開</span>
            </div>
            <div
              v-if="!this.share.isClosed"
              class="co-dropdown-item edit"
              @click="closeSharing"
            >
              <span>募集終了</span>
            </div>
            <div class="co-dropdown-item edit" >
              <a :href="share.path" data-method="delete">削除</a>
            </div>
          </BDropdown>
        </div>
        <div class="share-title-area">
          <div class="share-title">{{ shareTitle }}</div>
          <div class="like-bookmark-area">
            <div class="share-like">
              <a @click="like">
                <div class="action-items active-heart">
                  <span class="action-icon icon-heart">
                    <img src="../../images/for_community/heart_in_active_icon.png" v-if="!isLiked">
                    <img src="../../images/for_community/heart_active_icon.png" v-if="isLiked">
                  </span>
                </div>
              </a>
              <span @click="goToListLike()" class="like-count">{{ share.likesCount }}</span>
            </div>
          </div>
        </div>
        <div class="share-created-date">{{ share.createdAtFormatDot }}</div>
      </div>
    </div>
    <div class="share-content-area" :class="sharingBgColorGradient">
      <div class="share-author-area">
        <div class="share-author-name">{{share.user.commuNickname || share.user.fullName}}</div>
        <div class="share-author-avatar">
          <img :src="share.user.communityAvatarPath" alt="">
        </div>
      </div>
      <div class="share-content">
        <div class="share-content-status">
          <MyCommuGroupStatus
            v-if="expired || this.share.isClosed"
            value="募集終了"
            :is-gray="expired || this.share.isClosed"/>
          <!-- <MyCommuGroupStatus
            v-else
            value="募集中"
            :is-special="!expired"/> -->
        </div>
        <div class="share-content-description mt-24" v-html="share.bodyLong"></div>
        <div
          v-if="image_paths"
          class="share-content-images mt-24"
        >
          <div
            v-for="image in image_paths"
            :key="image.id"
          >
            <img
              :key="image.id"
              :src="image.original"
              class="image-modal"
              @click.prevent.stop="handleClick(image.original)"
            >
          </div>
        </div>
        <BModal
          :active.sync="activeImage"
          width=""
        >
          <swiper class="swiper" id="swiper" :options="swiperOptions" ref="mySwiper">
            <swiper-slide v-for="(image, index) in image_paths" :key="index">
              <img :src="image.original" class="image-size"/>
            </swiper-slide>
            <div class="swiper-pagination" slot="pagination" ref="pagination"></div>
          </swiper>
        </BModal>
        <hr>
        <div v-if="share.place" class="share-content-address-delivery">
          <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" :class="sharingSvgColor">
            <g clip-path="url(#clip0_12916_19839)">
              <circle cx="16" cy="16" r="16" fill="#D5F1E7"/>
              <path d="M20.8534 17.7454C21.5675 16.856 22.0282 15.7905 22.1869 14.6612C22.3457 13.5319 22.1965 12.3808 21.7553 11.3291C21.3141 10.2775 20.5971 9.36439 19.68 8.6861C18.7629 8.00781 17.6797 7.58949 16.5446 7.47527C16.1308 7.4337 15.7139 7.4337 15.3001 7.47527C13.8869 7.62394 12.5638 8.24013 11.5409 9.22606C10.5179 10.212 9.85362 11.5112 9.65347 12.9174C9.53758 13.7659 9.59645 14.6291 9.82651 15.454C10.0566 16.2789 10.4529 17.0481 10.9912 17.7143L15.9224 24.0584L20.8534 17.7454Z" stroke="#2DBA87" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M15.9455 16.4238C17.1353 16.4238 18.0999 15.4596 18.0999 14.2702C18.0999 13.0809 17.1353 12.1167 15.9455 12.1167C14.7556 12.1167 13.791 13.0809 13.791 14.2702C13.791 15.4596 14.7556 16.4238 15.9455 16.4238Z" stroke="#2DBA87" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
              <clipPath id="clip0_12916_19839">
                <rect width="32" height="32" fill="white"/>
              </clipPath>
            </defs>
          </svg>
          <div class="share-content-address-delivery-text" :class="sharingTextColor">
            <div>{{ placeTitle }}</div>
            <div>{{ share.place }}</div>
            <div class="share-content-address-delivery-text-notice mt-8">できるだけ公共スポットでの受け渡しがオススメ！</div>
          </div>
        </div>
        <div v-if="share.startedAtLong || share.finishedAtLong" class="share-content-address-delivery mt-16">
          <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" :class="sharingSvgColor">
            <g clip-path="url(#clip0_12916_19848)">
              <circle cx="16" cy="16" r="16" fill="#D5F1E7"/>
              <g clip-path="url(#clip1_12916_19848)">
                <path d="M16.0002 24.889C20.9094 24.889 24.8891 20.9093 24.8891 16.0001C24.8891 11.0909 20.9094 7.11121 16.0002 7.11121C11.091 7.11121 7.11133 11.0909 7.11133 16.0001C7.11133 20.9093 11.091 24.889 16.0002 24.889Z" stroke="#2DBA87" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M16 10.7201V16.0001L18.8444 18.3201" stroke="#2DBA87" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round"/>
              </g>
            </g>
            <defs>
              <clipPath id="clip0_12916_19848">
                <rect width="32" height="32" fill="white"/>
              </clipPath>
              <clipPath id="clip1_12916_19848">
                <rect width="21.3333" height="21.3333" fill="white" transform="translate(5.33398 5.33337)"/>
              </clipPath>
            </defs>
          </svg>

          <div class="share-content-address-delivery-text" :class="sharingTextColor">
            <div>{{ scheduleTitle }}</div>
            <div v-if="share.startedAtLong">{{ share.startedAtLong }}〜</div>
            <div v-if="share.finishedAtLong">{{ share.finishedAtLong }}</div>
          </div>
        </div>
        <div class="share-content-coin mt-40" :class="sharingBgColorOpacity01">
          <img src="../../images/icons/icon-gratitude.svg" alt="" class="icon-thank">
          <div class="ml-50">希望謝礼額</div>
          <div class="share-content--coin">
            <img src="../../images/icons/coin.svg" alt="">
            <div>{{ share.priceText }}</div>
          </div>
        </div>
        <div class="share-content-address-delivery-text-notice mt-16 text-center">受け渡し時に現金で授受してください</div>
        <div v-if="isShowQuestion" class="share-content-question mt-32">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#2DBA87" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M9.32922 9.89996C9.30709 9.54162 9.35855 9.18255 9.48046 8.84485C9.60238 8.50715 9.79216 8.19798 10.0381 7.9364C10.284 7.67482 10.5809 7.46634 10.9104 7.32385C11.2399 7.18136 11.5952 7.10791 11.9542 7.10791C12.3132 7.10791 12.6684 7.18136 12.998 7.32385C13.3275 7.46634 13.6244 7.67482 13.8704 7.9364C14.1163 8.19798 14.306 8.50715 14.4279 8.84485C14.5498 9.18255 14.6013 9.54162 14.5792 9.89996C14.5792 11.9 11.9492 11.7 11.9492 13.78" stroke="#2DBA87" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12.0001 17.84C12.5082 17.84 12.9201 17.4281 12.9201 16.92C12.9201 16.4119 12.5082 16 12.0001 16C11.492 16 11.0801 16.4119 11.0801 16.92C11.0801 17.4281 11.492 17.84 12.0001 17.84Z" fill="#2DBA87"/>
          </svg>
          <div class="ml-8">保険について</div>
        </div>
      </div>
    </div>
    <div :class="{' share-chat-area': share.isClosed} ">
      <CommentList
        :comments="share.comments"
        :comments-count="share.commentsCount"
        :handle-keydown="handleKeydown"
        :do-create-chat-message="doCreateChatMessage"
        :do-focus-input="toggleCommentInput"
        :is-show-mention="false"
      />
    </div>
    <div
      v-if="!share.isClosed"
      class="card-detail-btn-comments-input"
      :class="{ 'active': commentsInputStatus }"
    >
      <Mentionable
        :keys="[]"
        :items="users"
        offset="6"
        @open="loadIssues()"
        @search="loadIssues($event)"
      >
        <input
          id="focus-input"
          ref="comment"
          v-model="comment"
          class="comment-textfield"
          placeholder="コメントする"
        />
        <template #no-result>
          {{ null }}
        </template>
      </Mentionable>
      <button
        type="button"
        class="button is-rounded is-mb-3"
        :disabled="isDisable"
        @click="toggerSubmit()"
      >
        送信
      </button>
    </div>
    <MyCommuTopBarSideBar />
    <div class="create-ticket" v-if="!share.isCandidate && !share.isAuthor && !share.isClosed && !expired">
      <MyCommuBaseButton
        text-content="立候補する"
        class="w-100"
        @clickButton="beCandidate(share.sharingType)"
      />
    </div>
    <div class="modal-list-like">
      <BModal
        :active.sync="isListLikeModalActive"
        has-modal-card
      >
        <div class="modal-card">
          <MyCommuListLike
            :users="user_likes"
          />
        </div>
      </BModal>
    </div>
    <b-modal
      :active.sync="beCandidateSuccess"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-label="Example Modal"
      close-button-aria-label="Close"
      aria-modal
      :on-cancel="reloadPage"
      class="success-modal"
    >
      <div class="modal-card">
        <div class="modal-card-body">
          <img src="../../images/icons/success.svg" class="success-img">
          <p class="success-message">立候補しました。</p>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import CommentList from '../../components/my-commu/comments/List'
import MyCommuTopBarSideBar from '../../components/my-commu/topbar/SideBar.vue'
import axios from 'axios'
import queryString from 'query-string'
import { Mentionable } from 'vue-mention'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import Borrow from '../../components/my-commu/card/Borrow.vue'
import { commuSuccess, commuError } from '../../lib/app-toast'

export default {
  // eslint-disable-next-line vue/no-unused-components
  components: { Borrow, CommentList, MyCommuTopBarSideBar, Mentionable, Swiper, SwiperSlide },
  props: {
    share: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      beCandidateSuccess: false,
      sharingType: '',
      sharingBgColor: '',
      sharingBgColorOpacity01: '',
      sharingBgColorGradient: '',
      sharingTextColor: '',
      sharingSvgColor: '',
      isBookmark: false,
      expired: this.share.isExpired,
      shareTitle: '',
      placeTitle: '',
      scheduleTitle: '',
      isShowQuestion: false,
      isLiked: this.share.liked,
      image_paths: this.share.imagePaths,
      comment: '',
      commentsInputStatus: true,
      localUsers: [],
      isListLikeModalActive: false,
      price: this.share.priceText || false,
      user_likes: [],
      activeImage: false,
      activeImageUrl: '',
      activeImageIndex: null,
      swiperOptions: {
        pagination: {
          el: '.swiper-pagination'
        },
        initialSlide: 0
      },
    }
  },
  mounted () {
    switch (this.share.sharingType) {
      case 'borrowing':
        this.sharingType = '貸して'
        this.sharingBgColor = 'bg-green'
        this.sharingBgColorOpacity01 = 'bg-green-opacity-01'
        this.sharingTextColor = 'green'
        this.sharingSvgColor = 'svg-green'
        this.sharingBgColorGradient = 'bg-green-gradient'
        this.shareTitle = this.share.subject + 'を借してください'
        this.scheduleTitle = '借りたい期間'
        this.isShowQuestion = true
        break
      case 'entry':
        this.sharingType = '預かって'
        this.sharingBgColor = 'bg-blue'
        this.sharingBgColorOpacity01 = 'bg-blue-opacity-01'
        this.sharingTextColor = 'blue'
        this.sharingSvgColor = 'svg-blue'
        this.sharingBgColorGradient = 'bg-blue-gradient'
        break
      case 'lending':
        this.sharingType = '貸すよ'
        this.sharingBgColor = 'bg-sky-blue'
        this.sharingBgColorOpacity01 = 'bg-sky-blue-opacity-01'
        this.sharingTextColor = 'sky-blue'
        this.sharingSvgColor = 'svg-sky-blue'
        this.sharingBgColorGradient = 'bg-sky-blue-gradient'
        this.shareTitle = this.share.subject + 'を貸します'
        this.placeTitle = '受け渡し場所'
        this.scheduleTitle = 'お願いしたい返却日'
        this.isShowQuestion = true
        break
      case 'giving':
        this.sharingType = '譲るよ'
        this.sharingBgColor = 'bg-blue'
        this.sharingBgColorOpacity01 = 'bg-blue-opacity-01'
        this.sharingTextColor = 'blue'
        this.sharingSvgColor = 'svg-blue'
        this.sharingBgColorGradient = 'bg-blue-gradient'
        this.shareTitle = this.share.subject + 'を譲ります'
        this.placeTitle = '譲るよ'
        break
      case 'taking':
        this.sharingType = '譲って'
        this.sharingBgColor = 'bg-pink'
        this.sharingBgColorOpacity01 = 'bg-pink-opacity-01'
        this.sharingTextColor = 'pink'
        this.sharingSvgColor = 'svg-pink'
        this.sharingBgColorGradient = 'bg-pink-gradient'
        this.shareTitle = this.share.subject + 'を譲ってください'
        break
    }
    this.unsetMargin.appMain = true
    this.unsetMargin.mainContainer = true
    this.unsetPadding.mainContainer = true
    this.fetchUsers()
    const params = queryString.parse(location.search)
    if (params && params.action === 'comment') {
      this.$nextTick(() => {
        window.setTimeout(function () {
          window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: 'smooth' })
          document.getElementById('focus-input').focus()
        }, 300)
      })
    }
  },
  // eslint-disable-next-line vue/order-in-components
  computed: {
    ...mapState('space', ['unsetMargin', 'unsetPadding']),
    ...mapState('mycommu', ['users', 'currentCommunity']),
    ...mapState('session', ['communityId']),
    isDisable () {
      return this.comment === ''
    },
    closed () {
      return (this.share.isDecided || this.share.isClosed)
    },
    hasImage () {
      return this.imagePaths.length > 0
    }
  },
  methods: {
    ...mapActions('mycommu', [
      'toggerTopbar', 'fetchUsers'
    ]),
    ...mapActions('comments', [
      'createCommentShare'
    ]),
    bookmark () {
      this.isBookmark = !this.isBookmark
    },
    handleClick (imageUrl) {
      this.activeImageIndex = this.image_paths.findIndex(image => image.original === imageUrl)
      this.swiperOptions = {
        pagination: {
          el: '.swiper-pagination'
        },
        initialSlide: this.activeImageIndex
      }
      this.activeImage = true
    },
    handleKeydown () {},
    doCreateChatMessage () {},
    toggleCommentInput () {},
    reloadPage () {
      window.location.reload()
    },
    like () {
      const data = {
        share: {
          type: this.share.sharingType,
          id: this.share.id
        }
      }
      axios.post(`/communities/${this.communityId}/share_posts/handle_like`, data).then((response) => {
        if (response.data.action === 'like') {
          this.isLiked = true
          this.share.likesCount++
        } else if (response.data.action === 'unlike') {
          this.isLiked = false
          this.share.likesCount--
        } else {
          commuError(response.data.message)
        }
      })
    },
    async toggerSubmit () {
      this.localUsers.forEach((e) => {
        this.comment = this.comment.replace(`@${e.label}`, `[[@${e.label}]]`)
      })
      await this.createCommentShare({ shareId: this.share.id, shareType: this.share.sharingType, comment: this.comment }).then((res) => {
        this.share = res.data.data
      })
      this.comment = ''
    },
    async loadIssues (searchText = null) {
      await this.fetchUsers(searchText)
      this.localUsers = [...new Map([...this.localUsers, ...this.users].map((item) => [item['label'], item])).values()]
    },
    closeSharing () {
      axios.put(`${this.share.pathClose}`).then((res) => {
        if (res.data.status) {
          commuSuccess(res.data.notice)
          window.location.href = `${this.share.path}`
        }
      })
    },
    openSharing () {
      axios.put(`${this.share.pathReOpen}`).then((res) => {
        if (res.data.status) {
          commuSuccess(res.data.notice)
          window.location.href = this.share.path
        }
      })
    },
    beCandidate (type) {
      const data = {
        share: {
          type: this.share.sharingType,
          id: this.share.id
        }
      }
      axios.post(`/communities/${this.communityId}/share_posts/handle_candidacy`, data).then((res) => {
        this.beCandidateSuccess = true
      })
    },
    goToListLike () {
      const data = {
        share: {
          type: this.share.sharingType,
          id: this.share.id
        }
      }
      axios.post(`/communities/${this.communityId}/share_posts/list_likes`, data).then((res) => {
        this.user_likes = res.data.data
        this.isListLikeModalActive = true
      })
    },
    redirectBack () {
      const paramsx = queryString.parse(location.search)
      if (paramsx.from === 'notification') {
        window.location.href = '/communities/notifications'
      } else if (paramsx.from === 'group') {
        window.location.href = `/communities/${this.communityId}/community_groups/${paramsx.group_id}`
      } else {
        window.history.back()
      }
    }
  }
}
</script>
<style scoped lang="sass">
  /deep/ .success-modal
    .animation-content
      width: 60%
      .modal-card
        .modal-card-body
          border-radius: 10px
          text-align: center
          .success-img
            margin-top: 15px
          .success-message
            padding: 15px 0px
            text-wrap: wrap
  body
    font-family: 'Hiragino Kaku Gothic ProN'
  .share-header-area
    .share-detail-header
      display: flex
      justify-content: space-between
      .change-commu
        width: 32px
        height: 32px
        position: absolute
        right: 16px
        top: 50px
        border-radius: 4px
        border: 1px solid #ccc
    .share-detail-bottom
      position: relative
      padding: 0 16px 30px 32px
      .share-status
        display: flex
        position: absolute
        top: -24px
        &-content
          padding: 17px 24px
          background: #6F8FE3
          border-radius: 16px 16px 16px 0
          font-weight: bold
          font-size: 14px
          line-height: 14px
          text-align: center
          color: #FFFFFF
        .share-option
          margin-left: 16px
          width: 48px
          height: 48px
          border-radius: 50%
          background: white
          opacity: 0.8
          display: flex
          justify-content: center
          align-items: center
          border: none
          &-dot
            width: 4px
            height: 4px
            border-radius: 50%
            margin: 0 2px
        /deep/
          .dropdown-menu
            min-width: 10rem
            left: 15px
            .dropdown-content
              padding-left: 1rem
              .co-dropdown-item edit
                cursor: pointer
      .share-title-area
        padding-top: 27px
        display: flex
        justify-content: space-between
        align-items: flex-start
        .share-title
          font-style: normal
          font-weight: 700
          font-size: 20px
          color: #FFFFFF
          word-break: break-word
          width: 85%
        .like-bookmark-area
          display: flex
          align-items: center
        .share-like
          display: flex
      .share-created-date
        margin-top: 5px
        font-style: normal
        font-weight: 400
        font-size: 12px
        line-height: 12px
        color: #FFFFFF
  .share-content-area
    background: linear-gradient(90deg, #496DCC 0%, rgba(111, 143, 227, 0.4) 83.33%), #6F8FE3
    position: relative
    .share-author-area
      position: absolute
      display: grid
      grid-template-columns: auto auto
      right: 24px
      top: -32px
      .share-author-name
        margin-right: 8px
        margin-top: 5px
        padding-bottom: 30px
        align-self: flex-start
        font-style: normal
        font-weight: 400
        font-size: 12px
        line-height: 12px
        display: flex
        align-items: center
        color: #FFFFFF
        word-break: break-all
      .share-author-avatar
        img
          width: 64px
          height: 64px
          border-radius: 50%
          object-fit: cover
          flex-shrink: 0
    .share-content
      padding: 24px 24px 10px
      background: white
      border-radius: 16px 16px 0 0
      .share-content-status
        max-width: 70px
      .share-content-description
        margin-top: 9px
        word-break: break-word
      .share-content-images
        margin-top: 9px
        display: grid
        grid-template-columns: 1fr 1fr
        grid-column-gap: 8px
        grid-row-gap: 8px
        img
          width: 100%
          aspect-ratio: 1 / 1
          object-fit: cover
      hr
        margin: 10px 0
        background: #CCCCCC
        height: 1px
      .share-content-address-delivery
        display: flex
      .share-content-address-delivery-text
        margin-left: 8px
        font-style: normal
        font-weight: 600
        &-notice
          font-weight: 700
          font-size: 12px
          line-height: 12px
          color: #F78F54
      .share-content-coin
        margin-top: 9px
        position: relative
        display: flex
        justify-content: space-between
        align-items: center
        padding: 16px 24px 16px 0
        font-style: normal
        font-weight: 400
        font-size: 12px
        line-height: 12px
        color: #273D35
        .icon-thank
          position: absolute
          left: 4px
        .share-content--coin
          display: flex
          align-items: center
          font-weight: 700
          font-size: 12px
          img
            margin-right: 4px
      .share-content-question
        margin-top: 7px
        display: flex
        align-items: center
        font-style: normal
        font-weight: 700
        font-size: 12px
        line-height: 12px
        color: #2DBA87
      /deep/
        .modal.is-active
          z-index: 1997
          .modal-close
            z-index: 1998
  .share-chat-area
    margin-bottom: 81px
  .ml-50
    margin-left: 50px
  .ml-16
    margin-left: 16px
  .ml-8
    margin-left: 8px
  .mt-40
    margin-top: 40px
  .mt-32
    margin-top: 32px
  .mt-16
    margin-top: 16px
  .mt-8
    margin-top: 8px
  .mt-24
    margin-top: 24px
  .green
    color: #2DBA87 !important
  .svg-green
    circle
      fill: #D5F1E7
    path
      stroke: #2DBA87
  .bg-green
    background: #2DBA87 !important
    opacity: 0.9
  .bg-green-opacity-01
    background: rgba(45, 186, 135, 0.1)
  .bg-green-gradient
    background: linear-gradient(90deg, #129E6C 0%, rgba(60, 200, 150, 0.5) 83.33%), #3BC593
  .pink
    color: #F9A6BF !important
  .svg-pink
    circle
      fill: rgba(249, 166, 191, 0.2)
    path
      stroke: #F9A6BF
  .bg-pink
    background: #F9A6BF !important
    opacity: 0.9
  .bg-pink-opacity-01
    background: rgba(249, 166, 191, 0.1)
  .bg-pink-gradient
    background: linear-gradient(90deg, #E87295 0%, rgba(249, 166, 191, 0.4) 83.33%), #F9A6BF
  .yellow
    color: #E8B85D !important
  .svg-yellow
    circle
      fill: rgba(232, 184, 93, 0.2)
    path
      stroke: #E8B85D
  .bg-yellow
    background: #E8B85D !important
    opacity: 0.9
  .bg-yellow-opacity-01
    background: rgba(232, 184, 93, 0.1)
  .bg-yellow-gradient
    background: linear-gradient(90deg, #C79027 0%, #E8B85D 83.33%), #E8B85D
  .blue
    color: #6F8FE3 !important
  .svg-blue
    circle
      fill: rgba(111, 143, 227, 0.2)
    path
      stroke: #6F8FE3
  .bg-blue
    background: #6F8FE3 !important
    opacity: 0.9
  .bg-blue-opacity-01
    background: rgba(111, 143, 227, 0.1)
  .bg-blue-gradient
    background: linear-gradient(90deg, #496DCC 0%, rgba(111, 143, 227, 0.4) 83.33%), #6F8FE3
  .sky-blue
    color: #7ECAE9 !important
  .svg-sky-blue
    circle
      fill: rgba(126, 202, 233, 0.2)
    path
      stroke: #7ECAE9
  .bg-sky-blue
    background: #7ECAE9 !important
    opacity: 0.9
  .bg-sky-blue-opacity-01
    background: rgba(126, 202, 233, 0.1)
  .bg-sky-blue-gradient
    background: linear-gradient(90deg, #41ABD6 0%, rgba(126, 202, 233, 0.4) 83.33%), #7ECAE9
  .create-ticket
    background: linear-gradient(360deg, #CBC8AB 0%, rgba(196, 196, 196, 0) 100%)
    padding: 9px 16px 8px 16px
    position: sticky
    bottom: 81px
    left: 0
    display: flex
    align-items: self-end
    height: 85px
    z-index: 100
  .w-100
    width: 100%
  .action-items
    display: flex
    justify-content: center
    color: #FFFFFF
    .action-icon
      margin-right: 8px
      align-items: center
    .action-items:nth-child(1)
      padding: 17px 27px 10px
      border-right: 1px solid white
    .action-items:nth-child(2)
      padding: 17px 27px 10px
  .taking-position
    position: fixed !important
    bottom: 0px !important
  .card-detail-btn-comments-input
    bottom: 0
    width: 100%
    background: #ffffff
    padding: 8px 8px 0 8px
    display: grid
    grid-template-columns: 4fr 1fr
    grid-column-gap: 8px
    margin-bottom: 81px
    .mentionable
    .comment-textfield
      width: 100%
      border: 1px solid #CCCCCC
      border-radius: 6px
      height: 48px
      padding: 16px
      caret-color: #2DBA87
      &:focus
        outline: none !important
        border: 1px solid #2DBA87
    .button
      height: 48px
      background: #2DBA87
      border-radius: 6px
      color: #ffffff
      margin-left: 10px
  .animation-content
    width: 80%
    background: #FFFFFF
    .modal-card
      border-radius: 20px
      .modal-card-footer
        line-height: 0
        display: inline-flex
        grid-template-columns: auto auto
        .button
          height: 48px
          border-top: 1px solid #CCCCCC
          background: #fff
          color: #2DBA87
          border-radius: 0
          font-size: 15px
          min-width: 50%
          &:focus-visible
            border: none
          &.cancel
            border-right: unset
        .ok
          font-weight: bold
          border-left: 1px solid #CCCCCC
  .modal-list-like
    /deep/
      .modal-close
        display: none
      .modal-background
        background: #273D35
        opacity: 0.9
      .animation-content
        width: 90%
        .modal-card
          overflow: scroll
          border-radius: 8px
          max-height: calc(812px - 190px)
  .sharing-card-img
    margin: 5px 0
    line-height: 0
  .slides
    .swiper
      &.swiper-container
        padding-bottom: 36px
  .image-size
    width: 100%
</style>
