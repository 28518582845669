<template>
  <div>
    <div class="step-container">
      <div class="step">
        <div
          class="step-content"
          :class="{ 'active': isStep1, 'completed': isStep2 || isStep3 }"
        >
          <div class="step--content">
            <div class="step-number">
              <div class="step--number">1</div>
            </div>
            <div class="step-name">基本情報</div>
          </div>
        </div>
        <div
          class="step-content"
          :class="{ 'active': isStep2, 'completed': isStep3 }"
        >
          <div class="step--content">
            <div class="step-number">
              <div class="step--number">2</div>
            </div>
            <div class="step-name">場所</div>
          </div>
          <div class="line"></div>
        </div>
        <div
          class="step-content"
          :class="{ 'active': isStep3 }"
        >
          <div class="step--content">
            <div class="step-number">
              <div class="step--number">3</div>
            </div>
            <div class="step-name">公開範囲</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  props: {
    isStep1: {
      type: Boolean,
      default: false
    },
    isStep2: {
      type: Boolean,
      default: false
    },
    isStep3: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="sass">
  .step-container
    background: white
    padding: 16px 23px 16px 24px
    .step
      display: flex
      position: relative
      .step-content
        display: flex
        flex-direction: column
        align-items: center
        flex: 1
        .step--content
          z-index: 2
        .line
          height: 1px
          background: #ccc
          position: absolute
          top: 20px
          width: 100%
          z-index: 1
        &:first-child
          align-items: flex-start
        &:last-child
          align-items: flex-end
        .step-number
          margin: auto
          width: 40px
          height: 40px
          background: #F3F3F3
          border: 1px solid #AAAAAA
          border-radius: 20px
          display: flex
          &.completed
            color: #273D35
            background: white
            border: 1px solid #273D35
            .step--number
              color: #273D35
          .step--number
            margin: auto
            font-weight: 700
            font-size: 12px
            line-height: 12px
            align-items: center
            text-align: center
            color: #AAAAAA
        .step-name
          font-style: normal
          font-weight: 600
          font-size: 10px
          line-height: 10px
          text-align: center
          color: #AAAAAA
          margin-top: 8px
        &.completed
          .step--content
            .step-number
              color: #273D35
              background: white
              border: 1px solid #273D35
              .step--number
                color: #273D35
          .step-name
            color: #273D35
        &.active
          .step--content
            .step-number
              color: #2DBA87
              background: #EAF8F3
              border: 1px solid #2DBA87
              .step--number
                color: #2DBA87
          .step-name
            color: #2DBA87
</style>
