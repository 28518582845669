<template>
  <div class="otp-inputs" style="display: flex">
    <input
      class="base-input"
      ref="input"
      :type="inputType"
      :inputmode="inputMode"
      min="0"
      max="9"
      maxlength="1"
      pattern="[0-9]*"
      v-model="model"
      :class="inputClasses"
      @input="handleOnChange"
      @keydown="handleOnKeyDown"
      @paste="handleOnPaste"
      @focus="handleOnFocus"
      @blur="handleOnBlur"
      :value="value"
    />
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String
    },
    separator: {
      type: String
    },
    focus: {
      type: Boolean
    },
    inputClasses: {
      type: String
    },
    shouldAutoFocus: {
      type: Boolean
    },
    inputType: {
      type: String,
      default () {
        return 'number'
      }
    },
    inputMode: {
      type: String,
      default () {
        return 'numeric'
      }
    },
    isLastChild: {
      type: Boolean
    }
  },
  data () {
    return {
      model: this.value || ''
    }
  },
  mounted () {
    if (this.$refs.input && this.focus && this.shouldAutoFocus) {
      this.$refs.input.focus()
    }
  },
  watch: {
    value (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.model = newValue
      }
    },
    focus (newFocusValue, oldFocusValue) {
      if (oldFocusValue !== newFocusValue && (this.$refs.input && this.focus)) {
        this.$refs.input.focus()
        this.$refs.input.select()
      }
    }
  },
  methods: {
    handleOnChange () {
      if (this.model.length > 1) {
        this.model = this.model.slice(0, 1)
      }
      return this.$emit('on-change', this.model)
    },
    handleOnKeyDown (event) {
      // Only allow characters 0-9, DEL, Backspace, Enter, Right and Left Arrows, and Pasting
      const keyEvent = (event) || window.event
      const charCode = (keyEvent.which) ? keyEvent.which : keyEvent.keyCode
      if (this.isCodeNumeric(charCode) || [8, 9, 13, 37, 39, 46, 86].includes(charCode)) {
        this.$emit('on-keydown', event)
      } else {
        keyEvent.preventDefault()
      }
    },
    isCodeNumeric (charCode) {
      // numeric keys and numpad keys
      return (charCode >= 48 && charCode <= 57) || (charCode >= 96 && charCode <= 105)
    },
    handleOnPaste (event) {
      return this.$emit('on-paste', event)
    },
    handleOnFocus () {
      this.$refs.input.select()
      return this.$emit('on-focus')
    },
    handleOnBlur () {
      return this.$emit('on-blur')
    }
  }
}
</script>

<style scoped lang="sass">
  .otp-inputs
    margin-left: 12px
    margin-right: 12px
    width: 50px
    height: 72px
    margin-bottom: 40px
    .base-input
      border-radius: 6px
      border: 1px solid #CCCCCC
      width: 100%
      height: 72px
      text-align: center
      padding: 0px !important
      font-size: 36px
      line-height: 15px
      &:focus-visible
        outline: none
        border: 1px solid #2DBA87
        border-radius: 6px
        box-shadow: 0 0 0 0
        caret-color: #2DBA87
</style>
