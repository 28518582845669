<template>
  <div>
    <Processing />
    <div class="confirm-container" :class="loading ? 'is-processing' : ''">
      <div class="title-field title-top">基本情報</div>
      <div class="title-content">
        <div class="title-field">交流イベント種別</div>
        <div class="title--content">{{ showText(this.event.event_type, this.collection) }}</div>
      </div>
      <div class="title-content">
        <div class="title-field">交流イベントタイトル</div>
        <div class="title--content">{{this.event.title}}</div>
      </div>
      <div class="title-content">
        <div class="title-field">交流イベント内容紹介</div>
        <div class="title--content white-space-pre-wrap line-height-20" v-html="this.event.body"></div>
      </div>
      <div v-if="files.length > 0" class="title-content images-preview">
        <div class="title-field">画像</div>
        <div class="is-flex">
          <div
            v-for="(file, index) in files"
            :key="index"
            class="title--content image"
          >
            <img :src="images_url(file)" alt="" width="48" height="48">
          </div>
        </div>
      </div>
      <div class="title-content">
        <div class="title-field">交流イベント開催日</div>
        <div class="title--content">{{ formatDate(this.event.scheduled_date) }}</div>
      </div>
      <div class="title-content">
        <div class="title-field">申し込み期限</div>
        <div class="title--content">{{ formatDate(this.event.request_expired_at) }}</div>
      </div>
      <div class="title-content">
        <div class="title-field">各種条件設定</div>
        <div class="title--content" v-text="checkSetting"></div>
      </div>
      <div class="title-content">
        <div class="title-field">参加者人数</div>
        <div class="title--content">募集人数：{{this.realCapacity}}人</div>
      </div>
      <div class="title-content">
        <div class="title-field">スタッフ集合～解散時間</div>
        <div class="title--content">{{this.event.base_work_started_at}}〜{{this.event.base_work_finished_at}}</div>
      </div>
      <hr>
      <div class="title-field title-top">タイムスケジュール</div>
      <div v-for="(schedule,index) in schedules" :key="index">
        <div v-if ="schedule.id === null || ( schedule.id && !schedule.delete)">
          <div class="title-content">
            <div class="title-field">ラベル{{index + 1}}</div>
            <div class="title--content">{{ schedule.subject }}</div>
          </div>
          <div class="title-content">
            <div class="title-field">ラベル{{index + 1}}の開始〜終了時間</div>
            <div class="title--content">{{ schedule.startedAt }}〜{{ schedule.finishedAt }}</div>
          </div>
          <hr>
        </div>
      </div>
      <div class="title-field title-top">開催場所</div>

      <div v-if="!this.event.online">
        <div class="title-content">
          <div class="title-field">都道府県</div>
          <div class="title--content">{{this.event.prefecture}}</div>
        </div>
        <div class="title-content">
          <div class="title-field">市区町村番地</div>
          <div class="title--content">{{this.event.address1 + this.event.address2}}</div>
        </div>
        <div class="title-content">
          <div class="title-field">会場・建物の名称</div>
          <div class="title--content">{{this.event.place_name}}</div>
        </div>
        <div v-if="this.event.access !== null" class="title-content">
          <div class="title-field">会場へのアクセス方法</div>
          <div class="title--content">{{this.event.access}}</div>
        </div>
        <div v-if="this.event.place_url !== null" class="title-content">
          <div class="title-field">会場のURL</div>
          <div class="title--content">{{this.event.place_url}}</div>
        </div>
      </div>
      <div v-if="this.event.price_description !== null" class="title-content">
        <div class="title-field">参加費の補足説明</div>
        <div class="title--content white-space-pre-wrap">{{this.event.price_description}}</div>
      </div>
      <div v-if="this.event.free_label1 !== null" class="title-content">
        <div class="title-field">自由入力欄</div>
        <div class="title--content white-space-pre-wrap">{{this.event.free_label1}}</div>
      </div>
      <div class="title-content">
        <div class="title-field">参加費</div>
        <div class="title--content">{{this.event.price}}円</div>
      </div>

      <hr>
      <div class="title-field title-top">公開範囲</div>
      <div class="title-content">
        <div class="title-field" v-for="community in communities" :key="community.id">
          <div v-if="activeCommunities(community.id)" class="title--content">{{ community.name }}</div>
          <div class="title--sub-title-content" v-for="group in community.groups" :key="group.id">
            <span v-if="activeGroups(group.id) && activeCommunities(community.id)">{{ group.name }}</span>
            <span v-else-if="activeGroups(group.id)">{{ group.name }}（{{ community.name }}）</span>
          </div>
        </div>
      </div>
      <button @click="save(true)" class="btn btn-success" :disabled="disabled">{{ textBtnSave }}</button>
      <button @click="save(false)" class="btn btn-white" :disabled="disabled">保存する</button>
      <div class="back">
        <span @click="back" :disabled="disabled">戻る</span>
      </div>
    </div>
    <BModal
      :active.sync="openModal"
      has-modal-card
      :can-cancel="true"
      :on-cancel="redirectTopPage"
      class="event-create"
    >
      <div class="modal-card" style="width: auto">
        <div class="modal-card-body">
          <img src="../../../images/for_community/circle-check.png">
          <div v-if="!isEdit && !savePublish" class="text-default">
            イベントを保存しました
          </div>
          <div v-else-if="!isEdit && savePublish" class="text-default">
            イベントを公開しました
          </div>
          <div v-else-if="isEdit && savePublish" class="text-default">
            イベントを編集して
            <br>
            公開しました
          </div>
          <div v-else-if="isEdit && !savePublish" class="text-default">
            イベントを編集
            <br>
            されました
          </div>
        </div>
      </div>
    </BModal>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import { format as dateFormat } from 'date-fns'
import axios from '../../../utils/axios'

export default {
  props: {
    event: {
      type: Object,
      require: true
    },
    url: {
      type: String,
      default: ''
    },
    url1: {
      type: String,
      default: ''
    },
    collection: {
      type: Array,
      default: () => []
    },
    prefectureOptions: {
      type: Array,
      default: () => []
    },
    realCapacity: {
      type: Number,
      default: 0
    },
    schedules: {
      type: Array,
      default: () => []
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    isCopy: {
      type: Boolean,
      default: false
    },
    communities: {
      type: Array,
      default: () => []
    },
    selectedCommunities: {
      type: Array,
      default: () => []
    },
    selectedGroups: {
      type: Array,
      default: () => []
    },
    files: {
      type: Array,
      default: () => []
    },
    imageDefault: {
      type: String,
      default: ''
    },
    eventCommuIds: {
      type: Array,
      default: () => []
    },
    eventCommuGroupIds: {
      type: Array,
      default: () => []
    },
    eventForCommuAndGroup: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      openModal: false,
      settingOption: [],
      disabled: false,
      savePublish: false,
      removeEventCommu: [],
      removeEventCommuGroup: [],
      removEventAndGroup: [],
      addEventCommu: [],
      addEventCommuGroup: []
    }
  },
  computed: {
    ...mapState('mycommu', ['currentCommunity', 'loading']),
    checkSetting () {
      if (this.event.acceptance_on_the_day) {
        this.settingOption.push('当日参加可能')
      }
      return this.settingOption.join()
    },
    textBtnSave () {
      if (this.isEdit) {
        return 'イベントを編集して公開する'
      } else {
        return '交流イベントを作成して公開する'
      }
    },

  },
  methods: {
    ...mapActions('mycommu', ['setLoading']),
    images_url (image) {
      if (image.url !== undefined) {
        return image.url
      } else {
        const url = URL.createObjectURL(image)
        return url
      }
    },
    activeCommunities (id) {
      return this.selectedCommunities.includes(id)
    },
    activeGroups (id) {
      return this.selectedGroups.includes(id)
    },
    redirectTopPage () {
      window.location.href = '/home?type=event'
    },
    showText (val, options) {
      for (let i = 0; i < options.length; i++) {
        if (options[i][1] === val) {
          return `${options[i][0]}`
        }
      }
      return ''
    },
    formatDate (date) {
      return dateFormat(date, 'YYYY/M/D')
    },
    back () {
      const data = {
        isStep1: false,
        isStep2: false,
        isStep3: true
      }
      this.$emit('backStep3', data)
    },
    uniq (array) {
      return array.filter(function (item, index, self) {
        return self.indexOf(item) === index
      })
    },
    findId (arrayOld, arrayNew) {
      const oldUniq = this.uniq(arrayOld)
      const newUniq = this.uniq(arrayNew)
      return oldUniq.filter(function (item) { return newUniq.indexOf(item) < 0 })
    },
    async save (isPublic = false) {
      this.setLoading(true)
      this.disabled = true
      this.removeEventCommu = this.findId(this.eventCommuIds, this.selectedCommunities)
      this.removeEventCommuGroup = this.findId(this.eventCommuGroupIds, this.selectedGroups)
      this.addEventCommu = this.isEdit ? this.findId(this.selectedCommunities, this.eventCommuIds) : this.selectedCommunities
      this.addEventCommuGroup = this.isEdit ? this.findId(this.selectedGroups, this.eventCommuGroupIds) : this.selectedGroups
      this.eventForCommuAndGroup.forEach((array) => {
        if (array[2] === 'Organization' && this.removeEventCommu.includes(array[1])) {
          this.removEventAndGroup.push(array[0])
        } else if (array[2] === 'OrganizationGroup' && this.removeEventCommuGroup.includes(array[1])) {
          this.removEventAndGroup.push(array[0])
        }
      })
      const form = new FormData()

      let countAdd = this.addEventCommu.length
      this.addEventCommu.forEach((id) => {
        countAdd += 1
        form.append(`event[organization_events_attributes][${countAdd}][event_board_type]`, 'Organization')
        form.append(`event[organization_events_attributes][${countAdd}][event_board_id]`, id)
      })
      this.addEventCommuGroup.forEach((id) => {
        countAdd += 1
        form.append(`event[organization_events_attributes][${countAdd}][event_board_type]`, 'OrganizationGroup')
        form.append(`event[organization_events_attributes][${countAdd}][event_board_id]`, id)
      })
      if (this.removEventAndGroup.length > 0 && !this.isCopy) {
        this.removEventAndGroup.forEach((id) => {
          countAdd += 1
          form.append(`event[organization_events_attributes][${countAdd}][_destroy]`, '1')
          form.append(`event[organization_events_attributes][${countAdd}][id]`, id)
        })
      }
      form.append('event[event_type]', this.event.event_type)
      form.append('event[title]', this.event.title)
      form.append('event[body]', this.event.body)
      form.append('event[without_generation_filtering]', this.event.without_generation_filtering)
      this.files.forEach((file, index) => {
        if (file.url === undefined) {
          form.append(`event[image${index + 1}`, file)
        }
      })
      for (let i = this.files.length + 1; i <= 4; i++) {
        if (this.event[`image${i}`].url !== this.imageDefault) {
          form.append(`event[remove_image${i}]`, '1')
        }
      }
      form.append('event[scheduled_date]', this.event.scheduled_date)
      form.append('event[request_expired_at]', this.event.request_expired_at)
      this.schedules.forEach((schedule, index) => {
        if (schedule.id && !this.isCopy) {
          form.append(`event[schedules_attributes][${index}][id]`, schedule.id)
        }
        if (schedule.id && schedule.delete) {
          form.append(`event[schedules_attributes][${index}][_destroy]`, '1')
        }
        form.append(`event[schedules_attributes][${index}][subject]`, schedule.subject)
        form.append(`event[schedules_attributes][${index}][started_at]`, schedule.startedAt)
        form.append(`event[schedules_attributes][${index}][finished_at]`, schedule.finishedAt)
        form.append(`event[schedules_attributes][${index}][real_capacity]`, `${schedule.real}`)
        form.append(`event[schedules_attributes][${index}][fake_capacity]`, '0')
      })
      form.append('event[base_work_started_at]', this.event.base_work_started_at)
      form.append('event[base_work_finished_at]', this.event.base_work_finished_at)
      form.append('event[online]', this.event.online)
      if (!this.event.online) {
        form.append('event[prefecture]', this.event.prefecture)
        form.append('event[address1]', this.event.address1)
        form.append('event[address2]', this.event.address2)
        form.append('event[place_name]', this.event.place_name)
        if (this.event.access) {
          form.append('event[access]', this.event.access)
        }
        if (this.event.place_url) {
          form.append('event[place_url]', this.event.place_url)
        }
      }
      if (this.event.price_description) {
        form.append('event[price_description]', this.event.price_description)
      }
      if (this.event.free_label1) {
        form.append('event[free_label1]', this.event.free_label1)
      }
      form.append('event[price]', this.event.price)
      if (isPublic) {
        this.savePublish = true
        form.append('event[public_event]', '1')
      } else {
        form.append('event[public_event]', '0')
      }
      const method = this.isEdit ? 'PATCH' : 'POST'
      const url = this.isEdit ? `/communities/${this.currentCommunity.community_id}/managements/events/${this.event.id}` : `/communities/${this.currentCommunity.community_id}/managements/events`
      await axios({
        method: method,
        url: url,
        data: form
      }).then((res) => {
        if (res.data.status) {
          this.setLoading(false)
          this.openModal = true
        } else {
          window.location.reload()
        }
      })
    }
  }
}
</script>
<style scoped lang="sass">
  .is-processing
    opacity: 0.5
  .confirm-container
    padding: 32px 32px 58px
    font-style: normal
    font-weight: 300
    font-size: 14px
    line-height: 14px
    color: #273D35
    .title-content
      margin-top: 24px
    .title-field
      font-weight: bold
    .title-top
      margin-top: 32px
      font-size: 17px
      line-height: 17px
    .title--content
      margin-top: 8px
      img
        width: 48px
        height: 48px
        object-fit: cover
        border-radius: 6px
    hr
      background: #cccccc
      height: 1px
      margin: 32px 0 0
    .btn
      font-family: 'Hiragino Kaku Gothic ProN'
      padding: 16px
      border-radius: 6px
      margin-top: 16px
      font-weight: bold
      font-size: 15px
      line-height: 15px
      background: white
      color: #2DBA87
      border: none
      width: 100%
      text-align: center
      box-shadow: 0 2px 8px #F2EFDE
      &.btn-success
        margin-top: 40px
        color: white
        background: #2DBA87
    .back
      text-align: center
      margin-top: 25px
      font-weight: bold
      color: #2DBA87
  .event-create
    /deep/
      .modal-background
        background: #273D35
        opacity: 0.9
      .animation-content
        .modal-card
          flex-direction: unset
          margin: 0px 73px
          .modal-card-body
            color: #273D35
            font-size: 15px
            border-radius: 20px
            text-align: center
            padding: 40px 16px
            width: 230px
            img
              margin-bottom: 20px
              height: 66px
              width: 66px
      .modal-close
        display: none
  .white-space-pre-wrap
    white-space: pre-wrap
  .line-height-20
    line-height: 20px
  .title--sub-title-content
    margin: 15px
  .images-preview
    .is-flex
      display: flex
      .image
        margin-right: 16px
</style>
